import EntityNodeTree from './EntityNodeTree';

class CargoesNodeTree extends EntityNodeTree {
    initializeNodes(customCargoes, knownCargoes, placeholderCargoes) {
        super.initializeNodes(customCargoes, knownCargoes, placeholderCargoes);
        this.initializeCustomCargoNodes(customCargoes, knownCargoes);
        this.initializeKnownCargoNodes(knownCargoes);
        this.initializePlaceholderCargoNodes(placeholderCargoes);
    }

    initializeCustomCargoNodes(customCargoes, knownCargoes) {
        if (customCargoes) {
            const isKnownCargoesExists = knownCargoes.length !== 0;
            let customParentNodeKey = CargoesNodeTree.RootNodeKey;

            if (isKnownCargoesExists) {
                const customCargoParentName = 'UNKNOWN';
                const customCargoParentKey =
                    CargoesNodeTree.CustomCargoPrefix + customCargoParentName;
                this.pushParentNode(
                    customCargoParentName,
                    customCargoParentKey
                );
                customParentNodeKey = customCargoParentKey;
            }

            const parent = this.getNode(customParentNodeKey);

            const uniqueCustomCargoes = [...new Set(customCargoes)].sort();
            uniqueCustomCargoes.forEach((cargo) =>
                this.pushLeafNode(
                    CargoesNodeTree.CustomCargoPrefix + cargo,
                    cargo,
                    parent
                )
            );
        }
    }

    initializeKnownCargoNodes(knownCargoes) {
        if (knownCargoes) {
            for (const cargo of knownCargoes) {
                if (cargo.parentId === null) {
                    this.pushParentNode(cargo.name, cargo.id);
                } else {
                    const parent = this.getNode(cargo.parentId);
                    this.pushLeafNode(cargo.id, cargo.name, parent);
                }
            }
        }
    }

    initializePlaceholderCargoNodes(placeholderCargoes) {
        if (placeholderCargoes) {
            this.pushParentNode(PLACEHOLDER_CARGO_NODE);
            const parent = this.getNode(PLACEHOLDER_CARGO_NODE);

            const uniquePlaceholderCargoes = [
                ...new Set(placeholderCargoes),
            ].sort();
            uniquePlaceholderCargoes.forEach((cargo) =>
                this.pushLeafNode(
                    CargoesNodeTree.PlaceholderCargoPrefix + cargo,
                    cargo,
                    parent
                )
            );
        }
    }
}

const PLACEHOLDER_CARGO_NODE = 'PLACEHOLDER';

CargoesNodeTree.States = EntityNodeTree.States;

CargoesNodeTree.RootNodeKey = EntityNodeTree.RootNodeKey;
CargoesNodeTree.CustomCargoPrefix = EntityNodeTree.CustomEntityPrefix;
CargoesNodeTree.PlaceholderCargoNode = PLACEHOLDER_CARGO_NODE;
CargoesNodeTree.PlaceholderCargoPrefix = EntityNodeTree.PlaceholderEntityPrefix;

export default CargoesNodeTree;
