export default function laycanFilterCompare(
    filterLocalDateAtMidnight,
    cellValue
) {
    if (!cellValue) {
        return 1;
    }
    //laycans are dates, so we need to cut down to just the date part of an ISO string
    const formattedDate = formatDateForCompare(filterLocalDateAtMidnight);

    //we only want to compare against the start dates
    let { startDate: compareValue } = cellValue;
    if (!compareValue && cellValue.shorthand) {
        switch (cellValue.shorthand) {
            case 'PPT':
            case 'PROMPT':
            case 'PPT ON':
            case 'P':
                //prompt are special cases that are 'rolling' dates, we need to handle these directly in the filter
                const currentDate = new Date(Date.now());
                compareValue = formatDateForCompare(currentDate);
                break;

            default:
                break;
        }
    }
    if (formattedDate < compareValue || !compareValue) {
        return 1;
    } else if (formattedDate > compareValue) {
        return -1;
    } else {
        return 0;
    }
}

const formatDateForCompare = (date) =>
    `${date.getFullYear()}-${to2Digits(date.getMonth() + 1)}-${to2Digits(
        date.getDate()
    )}`;

const to2Digits = (digit) => ('0' + digit).slice(-2);
