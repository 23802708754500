import React, { Component } from 'react';
import { FreightPortBasisParser } from '../../../tools/freightPortBasisParser';
import {
    KEY_BACKSPACE,
    KEY_TAB,
    KEY_ENTER,
    KEY_LEFT,
    KEY_RIGHT,
    KEY_DELETE,
    KEY_FORWARD_SLASH,
} from '../../../constants/keyboardCodes';
import {
    DIRECTION_FORWARD,
    DIRECTION_BACKWARD,
} from '../../../models/common/EntityPart';

export default class FreightPortBasisInput extends Component {
    constructor(props) {
        super(props);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
        this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
        this.inputRef = React.createRef();
        this.parser = new FreightPortBasisParser();

        this.state = {
            inputValue: '',
            isDeleting: false,
        };
    }

    componentDidMount() {
        if (this.props.initialChar) {
            this.handleTextboxValue(this.props.initialChar);
        }
    }

    handleChange = (event) => {
        this.handleTextboxValue(event.target.value);
    };

    handleTextboxValue = (incomingValue) => {
        const cleanValue = this.parser.clean(incomingValue);
        this.setState({ inputValue: cleanValue });

        if (this.isSeparatorCharacter(cleanValue)) {
            this.props.onSeparatorEntered(cleanValue);
            this.reset();
        }
    };

    reset() {
        this.setState({
            inputValue: '',
        });
    }

    handleInputKeyUp(e) {
        if (e.keyCode === KEY_BACKSPACE) {
            this.setState({ isDeleting: false });
        }
    }

    handleInputKeyDown(e) {
        switch (e.keyCode) {
            case KEY_BACKSPACE:
                this.keyBackspaceHandler();
                break;
            case KEY_DELETE:
                this.keyDeleteHandler();
                break;
            case KEY_TAB:
                this.keyTabHandler(e);
                break;
            case KEY_ENTER:
                this.reset();
                this.props.onEnter();
                break;
            case KEY_LEFT:
                this.keyLeftHandler();
                break;
            case KEY_RIGHT:
                this.keyRightHandler();
                break;
            case KEY_FORWARD_SLASH:
                this.reset();
                this.props.onForwardSlash();
                break;
            default:
                if (e.key && this.isSeparatorCharacterNotAtBeginning(e.key)) {
                    e.preventDefault();
                    e.stopPropagation();
                }
        }
    }

    keyBackspaceHandler() {
        this.removePart(DIRECTION_BACKWARD);
    }

    keyDeleteHandler() {
        this.removePart(DIRECTION_FORWARD);
    }

    removePart(direction) {
        const { isDeleting, inputValue } = this.state;
        if (isDeleting === false && inputValue === '' && this.props.onDelete) {
            this.props.onDelete(direction);
        }
        this.setState({ isDeleting: true });
    }

    keyTabHandler(event) {
        if (!event.altKey && !event.ctrlKey) {
            event.preventDefault();
            event.stopPropagation();

            if (!event.shiftKey) {
                this.props.onTab();
            } else {
                this.props.onTabBack();
            }
        }
    }

    keyLeftHandler() {
        this.changeCursorPosition(this.props.onMoveLeft);
    }

    keyRightHandler() {
        this.changeCursorPosition(this.props.onMoveRight);
    }

    changeCursorPosition(onMove) {
        const { isDeleting, inputValue } = this.state;

        if (isDeleting === false && inputValue === '' && onMove) {
            onMove();
        }
    }

    isSeparatorCharacter(character) {
        return this.props.seperatorCharacters
            ? this.props.seperatorCharacters.indexOf(character) > -1
            : false;
    }

    isSeparatorCharacterNotAtBeginning(key) {
        const { inputValue } = this.state;
        return inputValue !== '' && this.isSeparatorCharacter(key);
    }

    get partValue() {
        const { inputValue } = this.state;
        const parseResult = this.parser.parse(inputValue);

        return parseResult.isSuccess
            ? {
                  loadCount: parseResult.loadCount,
                  dischargeCount: parseResult.dischargeCount,
              }
            : null;
    }

    focus() {
        this.inputRef.current.focus();
        if (!this.props.initialChar) {
            this.inputRef.current.select();
        }
    }

    render() {
        return (
            <input
                id={this.props.id}
                name={this.props.name}
                ref={this.inputRef}
                type="text"
                onChange={this.handleChange}
                value={this.state.inputValue}
                disabled={this.props.disabled}
                placeholder="value or separator"
                onKeyDown={this.handleInputKeyDown}
                onKeyUp={this.handleInputKeyUp}
            />
        );
    }
}
