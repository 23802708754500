import React from 'react';
import isEqual from 'lodash/isEqual';
import FreightPortBasisInput from './FreightPortBasisInput';
import MultiSelectSeparator from './MultiSelectSeparator';
import DraggableMultiSelectItem from './DraggableMultiSelectItem';
import cloneDeep from 'lodash/cloneDeep';
import {
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_SEPARATOR,
    SEPARATOR_FORWARD_SLASH,
    DIRECTION_FORWARD,
    DIRECTION_BACKWARD,
    addPart,
    deleteItemAtPosition,
    normalizeParts,
    dragAndDropPart,
} from '../../../models/common/EntityPart';
import {
    createSeparatorFreightPortPart,
    createCustomFreightPortPart,
} from '../../../models/FreightPortBasisPart';

class MultipleFreightPortBasisSelect extends React.Component {
    constructor(props) {
        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.separatorEntered = this.separatorEntered.bind(this);
        this.multipleInput = React.createRef();
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.handleOnForwardSlash = this.handleOnForwardSlash.bind(this);
        this.handleRemovedItem = this.handleRemovedItem.bind(this);
        this.moveCursorLeft = this.moveCursorLeft.bind(this);
        this.moveCursorRight = this.moveCursorRight.bind(this);
        this.onSetCursorPosition = this.onSetCursorPosition.bind(this);
        this.onDraggedItem = this.onDraggedItem.bind(this);
        this.defaultSeperatorCharacter = SEPARATOR_FORWARD_SLASH;

        const parts = this.initializeParts();

        this.state = {
            parts,
            initialValue: this.props.initialChar,
            cursorPos: parts.length,
        };
    }

    initializeParts() {
        const preparedParts = this.prepareParts();
        let parts = [];

        if (preparedParts) {
            for (const part of preparedParts) {
                const result = this.addPart(parts, part, undefined);
                parts = result.parts;
            }
        }

        return parts;
    }

    addPart(parts, part, cursorPosition) {
        return addPart(
            parts,
            part,
            cursorPosition,
            createSeparatorFreightPortPart,
            this.defaultSeperatorCharacter
        );
    }

    prepareParts() {
        const parts = [];

        if (this.props.value && this.props.value.parts) {
            for (const part of this.props.value.parts) {
                part.partType = ENTITY_PART_TYPE_CUSTOM;
                parts.push(part);
                parts.push({
                    value: '/',
                    partType: ENTITY_PART_TYPE_SEPARATOR,
                });
            }
            parts.pop();
        }

        return parts;
    }

    separatorEntered(character) {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            const part = createSeparatorFreightPortPart(character);
            const newState = this.addPart(parts, part, prevState.cursorPos);
            return newState;
        }, this.focus);
    }

    handleOnTab() {
        if (this.multipleInput.current.partValue) {
            this.partEntered(this.multipleInput.current.partValue, () => {
                this.props.onTab();
            });
        } else {
            this.props.onTab();
        }
    }

    handleOnTabBack() {
        if (this.multipleInput.current.partValue) {
            this.partEntered(this.multipleInput.current.partValue, () => {
                this.props.onTabBack();
            });
        } else {
            this.props.onTabBack();
        }
    }

    handleOnEnter() {
        if (this.multipleInput.current.partValue) {
            this.partEntered(this.multipleInput.current.partValue);
        } else {
            this.props.onEnter();
        }
    }

    handleOnForwardSlash() {
        if (this.multipleInput.current.partValue) {
            this.partEntered(this.multipleInput.current.partValue);
        }
    }

    partEntered(value, callback) {
        this.setState(
            (prevState) => {
                const parts = cloneDeep(prevState.parts);
                const part = createCustomFreightPortPart({
                    loadCount: value.loadCount,
                    dischargeCount: value.dischargeCount,
                });
                const newParts = this.addPart(parts, part, prevState.cursorPos);
                return {
                    parts: newParts.parts,
                    cursorPos: newParts.cursorPos,
                    initialValue: null,
                };
            },
            () => {
                callback && callback();
                //Whenever a quantity is added explicitely by the user (by pressing Enter or Tab), clear the textbox.
                this.multipleInput.current.reset();
            }
        );
    }

    normalizeParts(prevState) {
        const parts = cloneDeep(prevState.parts);
        return {
            parts: normalizeParts(
                parts,
                createSeparatorFreightPortPart,
                this.defaultSeperatorCharacter
            ),
        };
    }

    deleteAtPosition(parts, position, direction) {
        let nextPosition =
            direction === DIRECTION_BACKWARD ? position - 1 : position;

        ({ parts } = deleteItemAtPosition(parts, nextPosition, direction));

        nextPosition =
            direction === DIRECTION_BACKWARD
                ? Math.max(position - 1, 0)
                : position;

        return { parts, cursorPos: nextPosition };
    }

    moveCursorLeft() {
        const newPosition = this.state.cursorPos - 1;
        const nextPosition = newPosition >= 0 ? newPosition : 0;
        this.setState({ cursorPos: nextPosition }, this.focus);
    }

    moveCursorRight() {
        const newPosition =
            this.state.cursorPos === 0 ? 1 : this.state.cursorPos + 1;
        const nextPosition =
            newPosition <= this.state.parts.length
                ? newPosition
                : this.state.parts.length;
        this.setState({ cursorPos: nextPosition }, this.focus);
    }

    handleDelete(direction) {
        let parts = cloneDeep(this.state.parts);
        let position = this.state.cursorPos;

        const newState = this.deleteAtPosition(parts, position, direction);

        this.setState(
            {
                parts: newState.parts,
                cursorPos: newState.cursorPos,
            },
            this.focus
        );
    }

    handleRemovedItem(index) {
        let parts = cloneDeep(this.state.parts);
        let position = index;

        const newState = this.deleteAtPosition(
            parts,
            position,
            DIRECTION_FORWARD
        );

        this.setState(
            {
                parts: newState.parts,
                cursorPos: newState.cursorPos,
            },
            this.focus
        );
    }

    get formattedValue() {
        const parts = this.state.parts.filter(
            (p) => p.partType === ENTITY_PART_TYPE_CUSTOM
        );

        const shouldBeUpdatedAutomatically =
            this.props.value && !this.props.value.shouldBeUpdatedAutomatically
                ? false
                : isEqual(
                      this.props.value ? this.props.value.parts : [],
                      parts
                  );

        return { shouldBeUpdatedAutomatically, parts };
    }

    focus() {
        if (this.multipleInput.current != null) {
            this.multipleInput.current.focus();
        }
    }

    hasFocus() {
        return this.multipleInput.current.hasFocus();
    }

    onSetCursorPosition(index) {
        this.setState({ cursorPos: index }, this.focus);
    }

    onDraggedItem(sourceIndex, targetIndex) {
        this.setState((prevState) => {
            const newParts = dragAndDropPart(
                prevState.parts,
                sourceIndex,
                targetIndex
            );

            const normalized = normalizeParts(
                newParts,
                createSeparatorFreightPortPart,
                this.defaultSeperatorCharacter
            );

            return { parts: normalized, cursorPos: normalized.length };
        });
    }

    render() {
        const { className } = this.props;
        const isLastPosition = this.state.parts.length === this.state.cursorPos;

        const items = this.state.parts.map((part, index) => {
            switch (part.partType) {
                case ENTITY_PART_TYPE_CUSTOM:
                    return (
                        <DraggableMultiSelectItem
                            key={index}
                            index={index}
                            value={`${part.loadCount}:${part.dischargeCount}`}
                            onRemovedItem={this.handleRemovedItem}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            pill
                            tabIndex="-1"
                        />
                    );
                case ENTITY_PART_TYPE_SEPARATOR:
                    return (
                        <MultiSelectSeparator
                            key={index}
                            index={index}
                            value={SEPARATOR_FORWARD_SLASH}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            onRemovedItem={this.handleRemovedItem}
                            pill
                        />
                    );
                default:
                    throw new Error(
                        `Unknown part type specified: ${part.partType}`
                    );
            }
        });

        const cursor = (
            <div className="multipleLocationSelect-input-container" key="input">
                <div className="multipleLocationSelect-input">
                    <FreightPortBasisInput
                        ref={this.multipleInput}
                        context={this.props.context}
                        field={this.props.field}
                        seperatorCharacters={SEPARATOR_FORWARD_SLASH}
                        onSeparatorEntered={this.separatorEntered}
                        onChange={this.props.onChange}
                        onDelete={this.handleDelete}
                        inputClass={this.props.inputClass}
                        onTab={this.handleOnTab}
                        onTabBack={this.handleOnTabBack}
                        onEnter={this.handleOnEnter}
                        onForwardSlash={this.handleOnForwardSlash}
                        initialChar={this.state.initialValue}
                        cursorPos={this.state.cursorPos}
                        onMoveLeft={this.moveCursorLeft}
                        onMoveRight={this.moveCursorRight}
                        isLastPosition={isLastPosition}
                    />
                </div>
            </div>
        );

        items.splice(this.state.cursorPos, 0, cursor);

        return (
            <div className={`multiple-location-select ${className}`}>
                <div className="multiple-location-select-items">{items}</div>
            </div>
        );
    }
}

export default MultipleFreightPortBasisSelect;
