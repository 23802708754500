import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';
import { AUDIT_HISTORY_QUERY } from '../../api/queries/AuditQueries';
import AuditHistory, { AuditHistoryTypes } from './AuditHistory';

export class AuditActivity extends Component {
    render() {
        const entityType = this.props.selectedFixtures
            ? AuditHistoryTypes.Fixture
            : AuditHistoryTypes.Order;
        const selectedEntities =
            this.props.selectedFixtures || this.props.selectedOrders;

        let selectedEntity = null;

        if (selectedEntities.length === 1) {
            selectedEntity = selectedEntities[0];
        } else {
            return <span>You must select a single {entityType}</span>;
        }

        return (
            <div>
                <Query
                    query={AUDIT_HISTORY_QUERY}
                    variables={{
                        id: selectedEntity.id,
                        entityType,
                    }}
                    fetchPolicy="no-cache"
                >
                    {({ loading, error, data }) => {
                        if (loading) {
                            return <span>Loading audit history...</span>;
                        } else if (error) {
                            return (
                                <span>
                                    An error occurred loading audit history{' '}
                                </span>
                            );
                        } else {
                            return (
                                <div>
                                    <h4>
                                        {entityType} ID: {selectedEntity.id}
                                    </h4>
                                    <AuditHistory
                                        items={data.auditHistory}
                                        entityType={entityType}
                                        datasetId={this.props.datasetId}
                                    />
                                </div>
                            );
                        }
                    }}
                </Query>
            </div>
        );
    }
}

export default AuditActivity;
