import React from 'react';
import ChevronRightSvg from './ChevronRight.svg';

const ChevronRightIcon = (props) => (
    <img
        src={ChevronRightSvg}
        {...props}
        style={{ opacity: '0.7' }}
        alt="Chevron Right Icon"
    />
);

export default ChevronRightIcon;
