import CapeDirectionLogicService from './CapeDirectionLogicService';
import SupramaxDirectionLogicService from './SupramaxDirectionLogicService';
import PanamaxDirectionLogicService from './PanamaxDirectionLogicService';

export const defaultDirection = '';
export const PANAMAX_DIRECTION_LOGIC = 'Panamax';
export const CAPE_DIRECTION_LOGIC = 'Cape';
export const SUPRAMAX_DIRECTION_LOGIC = 'Supramax';

export const getDirectionService = (directionLogic) => {
    switch (directionLogic) {
        case PANAMAX_DIRECTION_LOGIC:
            return new PanamaxDirectionLogicService();
        case CAPE_DIRECTION_LOGIC:
            return new CapeDirectionLogicService();
        case SUPRAMAX_DIRECTION_LOGIC:
            return new SupramaxDirectionLogicService();
        default:
            return null;
    }
};
