import Logger from '../../../diagnostics/logging/logger';
import { cloneDeep } from 'lodash';

export const createCellRumouredMenuItem = (props, params, gridId) => {
    let cellRumouredMenuItem = null;

    if (
        params.column &&
        params.column.colDef &&
        params.column.colDef.type &&
        params.column.colDef.type.includes('rumoured')
    ) {
        let isRumoured = false;

        if (
            params.node &&
            params.node.data &&
            params.node.data.rumouredFields
        ) {
            isRumoured = params.node.data.rumouredFields.includes(
                params.column.colId
            );
        }

        if (isRumoured) {
            cellRumouredMenuItem = {
                name: 'Un-Rumoured Cell',
                action: () => {
                    if (params.node) {
                        const { group, data } = params.node;
                        if (!group && data) {
                            const fields = [...data.rumouredFields];
                            const index = fields.indexOf(params.column.colId);

                            if (index > -1) {
                                fields.splice(index, 1);
                            }

                            Logger.trackEvent(
                                'grid.context.madeCellUnRumoured',
                                gridId
                            );

                            const clonedData = cloneDeep(data);
                            props.onUpdateRumouredFields(clonedData, fields);
                        }
                    }
                },
                tooltip: 'Mark this cell as un-rumoured',
            };
        } else {
            cellRumouredMenuItem = {
                name: 'Rumoured Cell',
                action: () => {
                    if (params.node) {
                        const { group, data } = params.node;
                        if (!group && data) {
                            const fields = [...data.rumouredFields];
                            fields.push(params.column.colId);

                            Logger.trackEvent(
                                'grid.context.madeCellRumoured',
                                gridId
                            );

                            const clonedData = cloneDeep(data);
                            props.onUpdateRumouredFields(clonedData, fields);
                        }
                    }
                },
                tooltip: 'Mark this cell as rumoured',
            };
        }
    }

    return cellRumouredMenuItem;
};
