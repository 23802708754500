import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';
import { isEditableRow } from '../../../shared/columns/tools/headingTools';
import allowUpAndDownOnEdit from '_legacy/components/grid/columnDefaults/allowUpAndDownOnEdit';
import OrderStatusEditor from '../editors/OrderStatusEditor';

export const orderStatusHeading = {
    colId: 'orderStatus',
    headerName: 'Status',
    field: 'status',
    editable: isEditableRow,
    cellEditorFramework: OrderStatusEditor,
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    width: 80,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
};
