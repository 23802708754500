import React from 'react';
import ReactTooltip from 'react-tooltip';
import * as DateTimeFormatUtils from '../../common/DateTimeFormatUtils';
import { connect } from 'react-redux';

const GroupOnlyExpiryTooltip = (props) => {
    const formatDate = (date) =>
        DateTimeFormatUtils.formatDefaultDateTime(date, props.userTimezone);

    const generateTooltip = (data) => {
        if (!(data && data.groupOnlyExpiryDate)) {
            return null;
        }

        const date = formatDate(data.groupOnlyExpiryDate);
        const documentName = data.orderId === undefined ? 'Order' : 'Fixture';

        return `<div style="text-align: center">${documentName} will become visible to <br> all groups ${date} <br> automatically</div>`;
    };

    return (
        <ReactTooltip
            id={`${props.context.gridId}-group-only-expiry`}
            className="fort-grid-tooltip"
            border={true}
            place="right"
            type="light"
            multiline={true}
            html={true}
            getContent={(rowIndex) => {
                if (props.getRowDataCallback) {
                    const rowNode = props.getRowDataCallback(Number(rowIndex));

                    if (rowNode) {
                        return generateTooltip(rowNode.data);
                    }
                }
                return null;
            }}
        />
    );
};

const mapStateToProps = (state, props) => {
    const { userTimezone } = state.user;
    return {
        ...props,
        userTimezone,
    };
};

export default connect(mapStateToProps)(GroupOnlyExpiryTooltip);
