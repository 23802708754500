import React from 'react';
import StarFilledSvg from './StarFilled.svg';

const StarFilledIcon = (props) => (
    <img
        src={StarFilledSvg}
        style={{ width: '26px' }}
        alt="Star Filled"
        {...props}
    />
);

export default StarFilledIcon;
