import isString from 'lodash/isString';

const LaycanFormatter = ({ node, value }) => {
    if (node && node.group) {
        return '';
    }

    if (isString(value)) {
        //formatted already due to keyCreator on the column definitions
        return value;
    }

    if (!value || !value.shorthand) {
        return '';
    }

    return value.shorthand;
};

export default LaycanFormatter;
