import { blankKeyText } from '../../../../constants/gridText';
import { MultipleDurationFormatter } from '../formatters/MultipleDurationFormatter';

const DurationPartsKeyCreator = ({ value }) => {
    if (!value || value.length === 0) {
        return blankKeyText;
    }
    return MultipleDurationFormatter({ value });
};

export default DurationPartsKeyCreator;
