import { unassignedQtyText } from '../../../../constants/gridText';
import { MultipleCargoQuantityFormatter } from '../formatters/MultipleCargoQuantityFormatter';

const CargoQuantityPartsKeyCreator = ({ value, node }) => {
    if (!value || value.length === 0) {
        return unassignedQtyText;
    }
    return MultipleCargoQuantityFormatter({ value });
};

export default CargoQuantityPartsKeyCreator;
