import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import ListGroupPage from './ListGroupPage';
import AddGroupPage from './AddGroupPage';
import EditGroupPage from './EditGroupPage';
import UserSearchPage from './UserSearchPage';

class GroupPage extends Component {
    render() {
        const { match } = this.props;

        return (
            <div
                style={{
                    display: 'inline-block',
                    textAlign: 'initial',
                    width: '70%',
                    paddingBottom: '50px',
                }}
            >
                <Switch>
                    <Route
                        exact
                        path={`${match.url}`}
                        component={ListGroupPage}
                    />
                    <Route path={`${match.url}/add`} component={AddGroupPage} />
                    <Route
                        path={`${match.url}/userSearch`}
                        component={UserSearchPage}
                    />
                    <Route
                        path={`${match.url}/:groupId`}
                        component={(props) => {
                            return (
                                <EditGroupPage
                                    onGroupsChanged={this.props.onGroupsChanged}
                                    groupId={props.match.params.groupId}
                                />
                            );
                        }}
                    />
                </Switch>
            </div>
        );
    }
}

export default withRouter(GroupPage);
