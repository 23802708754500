import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./cargoPartFragment.gql');

export const CARGO_PART_TYPE_NAME = 'CargoPart';
export const CARGO_PART_FRAGMENT_NAME = 'CargoPartFragment';
export const CARGO_PART_FRAGMENT = gql`
    ${FRAGMENT}
`;
