import { getDataset } from '_legacy/models/Datasets';

export const DAYS = 'DAYS';
export const LEGS_VALUE = 'LL';
export const LEGS_LABEL = 'LEGS';
export const MONTHS = 'MONTHS';
export const YEARS = 'YEARS';
export const PNR = 'PNR';

export const durationUnits = [
    {
        id: 1,
        value: DAYS,
        label: DAYS,
        shouldDisableAmount: false,
        maxAmountValue: 99999,
        canBeMultipleInParts: true,
        isApplicableForRates: true,
        isDefaultRateUnit: false,
        lengthInDays: 1,
        validationErrorMessage: 'Max value is 99,999 days',
        isApplicableForMinMax: true,
    },
    {
        id: 2,
        value: LEGS_VALUE,
        label: LEGS_LABEL,
        shouldDisableAmount: false,
        maxAmountValue: 99,
        canBeMultipleInParts: true,
        isApplicableForRates: false,
        isDefaultRateUnit: false,
        lengthInDays: 25,
        validationErrorMessage: 'Max value is 99 laden legs',
        isApplicableForMinMax: false,
    },
    {
        id: 3,
        value: MONTHS,
        label: MONTHS,
        shouldDisableAmount: false,
        maxAmountValue: 999,
        canBeMultipleInParts: true,
        isApplicableForRates: true,
        isDefaultRateUnit: true,
        lengthInDays: 30,
        validationErrorMessage: 'Max value is 999 months',
        isApplicableForMinMax: true,
    },
    {
        id: 4,
        value: YEARS,
        label: YEARS,
        shouldDisableAmount: false,
        maxAmountValue: 99,
        canBeMultipleInParts: true,
        isApplicableForRates: true,
        isDefaultRateUnit: false,
        lengthInDays: 360,
        validationErrorMessage: 'Max value is 99 years',
        isApplicableForMinMax: false,
    },
    {
        id: 5,
        value: PNR,
        label: PNR,
        shouldDisableAmount: true,
        maxAmountValue: 0,
        canBeMultipleInParts: false,
        isApplicableForRates: false,
        isDefaultRateUnit: false,
        lengthInDays: 0,
        validationErrorMessage: null,
        isApplicableForMinMax: false,
    },
];

export const getAvailableUnitsForDataset = (datasetId) =>
    durationUnits.filter(
        (unit) =>
            !getDataset(datasetId).nonAvailableDurationUnits.includes(
                unit.value
            )
    );

export const getDurationUnitByValue = (value) =>
    durationUnits.find((unit) => unit.value === value);

export const getAvailableMinMaxUnits = () => {
    return durationUnits.filter((unit) => unit.isApplicableForMinMax);
};
