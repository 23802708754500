import { gql } from '@apollo/client';
import {
    GROUP_FRAGMENT,
    GroupFragment,
    SAVED_LAYOUT_FRAGMENT,
    SavedLayoutFragment,
} from 'api/fragments';
import graphQlClient from './graphQlClient';

export module UserApi {
    export interface UserInfoResult {
        id: string;
        userId: number;
        groups: GroupFragment[];
        permissions: { groupId: string; canWrite: boolean }[];
        userLayouts: SavedLayoutFragment[];
        sharedLayouts: SavedLayoutFragment[];
        themeSettings: {
            newThemeEnabled: boolean;
            useCondensedView: boolean;
            useCompactDensityView: boolean;
        };
    }

    const USER_INFO_QUERY = gql`
        ${GROUP_FRAGMENT}
        ${SAVED_LAYOUT_FRAGMENT}

        query UserInfo {
            me {
                id
                userId
                groups {
                    ...GroupFragment
                }
                permissions {
                    groupId
                    canWrite
                }
                userLayouts {
                    ...SavedLayoutFragment
                }
                sharedLayouts {
                    ...SavedLayoutFragment
                }
                themeSettings {
                    newThemeEnabled
                    useCondensedView
                    useCompactDensityView
                }
            }
        }
    `;

    export async function fetchUserInfo(): Promise<UserInfoResult> {
        const result = await graphQlClient().query<{ me: UserInfoResult }>({
            query: USER_INFO_QUERY,
        });
        return result.data.me;
    }
}
