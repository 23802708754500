import unicodeSortComparator from '../../../../components/grid/comparators/UnicodeSortComparator';
import CloudUserFormatter from '../formatters/CloudUserFormatter';

const CloudUserComparator = (valueA, valueB) => {
    const a = CloudUserFormatter({ value: valueA }).toLowerCase();
    const b = CloudUserFormatter({ value: valueB }).toLowerCase();

    return unicodeSortComparator(a, b);
};

export default CloudUserComparator;
