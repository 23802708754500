import Logger from '../../../diagnostics/logging/logger';
import { cloneDeep } from 'lodash';

export const createCellPrivacyMenuItem = (props, params, gridId) => {
    let cellPrivacyMenuItem = null;

    if (
        params.column &&
        params.column.colDef &&
        params.column.colDef.type &&
        params.column.colDef.type.includes('privatable')
    ) {
        let isPrivate = false;

        if (params.node && params.node.data && params.node.data.privateFields) {
            isPrivate = params.node.data.privateFields.includes(
                params.column.colId
            );
        }

        if (isPrivate) {
            cellPrivacyMenuItem = {
                name: 'Public Cell',
                action: () => {
                    if (params.node) {
                        const { group, data } = params.node;
                        if (!group && data) {
                            const fields = [...data.privateFields];
                            const index = fields.indexOf(params.column.colId);

                            if (index > -1) {
                                fields.splice(index, 1);
                            }

                            Logger.trackEvent(
                                'grid.context.madeCellPublic',
                                gridId
                            );

                            const clonedData = cloneDeep(data);
                            props.onUpdatePrivateFields(clonedData, fields);
                        }
                    }
                },
                tooltip: 'Mark this cell as public',
            };
        } else {
            cellPrivacyMenuItem = {
                name: 'Private Cell',
                action: () => {
                    if (params.node) {
                        const { group, data } = params.node;
                        if (!group && data) {
                            const fields = [...data.privateFields];
                            fields.push(params.column.colId);

                            Logger.trackEvent(
                                'grid.context.madeCellPrivate',
                                gridId
                            );

                            const clonedData = cloneDeep(data);
                            props.onUpdatePrivateFields(clonedData, fields);
                        }
                    }
                },
                tooltip: 'Mark this cell as private',
            };
        }
    }

    return cellPrivacyMenuItem;
};
