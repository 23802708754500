import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    styled,
} from '@mui/material';
import React from 'react';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    border: 'none',
    '&.Mui-expanded': { padding: theme.spacing(0), margin: theme.spacing(0) },
    '&::before': { opacity: 0 },
    '& .MuiAccordionSummary-root': {
        padding: theme.spacing(0, 1),
        margin: theme.spacing(0),
        minHeight: 'inherit',
        '&.Mui-expanded': { minHeight: 'inherit' },
        '& .MuiAccordionSummary-content': {
            margin: theme.spacing(0),
            fontWeight: 'bold',
            fontSize: theme.typography.pxToRem(12),
            textTransform: 'uppercase',
            padding: theme.spacing(1, 1),
            letterSpacing: '.2rem',
            userSelect: 'none',
        },
    },
    '& .MuiAccordionDetails-root': {
        padding: theme.spacing(0),
    },
}));

export type TinyExpandableAreaProps = React.PropsWithChildren<{
    idx: string;
    title: React.ReactElement | string;
    defaultExpanded?: boolean;
}>;

const TinyExpandableArea: React.FC<TinyExpandableAreaProps> = ({
    idx,
    title,
    defaultExpanded,
    children,
}) => {
    return (
        <StyledAccordion
            elevation={0}
            square={true}
            defaultExpanded={defaultExpanded}
        >
            <AccordionSummary
                aria-controls={idx + '-content'}
                id={idx + '-header'}
                expandIcon={<ExpandMore />}
            >
                {title}
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </StyledAccordion>
    );
};

export default TinyExpandableArea;
