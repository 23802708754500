import {
    getQuantityService,
    LONG_HAND_QUANTITY_FORMAT,
} from '../services/QuatityServiceFactory';
import {
    ENTITY_PART_TYPE_QUANTITY,
    ENTIYT_PART_TYPE_STEMSIZE,
} from '../../../../models/common/EntityPart';

class QuantityFormatter {
    numberFormat = new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
    });

    format(part, quantityFormat) {
        if (!part) {
            return '';
        }
        if (part.partType === ENTITY_PART_TYPE_QUANTITY) {
            const quantityService = getQuantityService(
                quantityFormat ?? LONG_HAND_QUANTITY_FORMAT
            );
            part = quantityService.formatQuantityAccordingToFormat(part);
            const amountAndToleranceString = this.formatAmountAndTolerance(
                part.quantityValue
            );

            return `${amountAndToleranceString} ${part.quantityValue.unit}`;
        } else if (part.partType === ENTIYT_PART_TYPE_STEMSIZE) {
            return part.stemSizeValue;
        } else {
            return part.separatorValue;
        }
    }

    formatAmountAndTolerance(quantityValue) {
        const amount = quantityValue.amount;
        const tolerance = quantityValue.tolerance;

        const amountString = this.formatAmount(amount);
        const toleranceString = this.formatTolerance(tolerance);

        return `${amountString}${toleranceString}`;
    }

    formatAmount(amount) {
        return !amount
            ? ''
            : amount.from === amount.to
            ? this.formatNumber(amount.from)
            : `${this.formatNumber(amount.from)}-${this.formatNumber(
                  amount.to
              )}`;
    }

    formatTolerance(tolerance) {
        return !tolerance ? '' : `/${this.formatNumber(tolerance)}`;
    }

    formatNumber(value) {
        return isNaN(value) ? '' : this.numberFormat.format(value);
    }
}

const quantityFormatterInstance = new QuantityFormatter();

export { QuantityFormatter, quantityFormatterInstance };
