import { isEditableRow } from '../../../shared/columns/tools/headingTools';
import VesselEditor from '../editors/VesselEditor';
import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import BlanksFilterRenderer from '_legacy/components/grid/renderers/BlanksFilterRenderer';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';
import NamePropertyComparator from '_legacy/components/grid/comparators/NamePropertyComparator';
import defaultObjEquals from '_legacy/components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import { VesselFormatter } from '../formatters/VesselFormatter';
import VesselKeyCreator from '../keyCreators/VesselKeyCreator';
import TooltipRenderer from '_legacy/components/grid/renderers/TooltipRenderer';
import suppressOnEdit from '_legacy/components/grid/columnDefaults/suppressOnEdit';

export const vesselNameHeading = {
    colId: 'vessel',
    headerName: 'Vessel',
    field: 'vessel',
    editable: isEditableRow,
    valueFormatter: VesselFormatter,
    cellEditorFramework: VesselEditor,
    cellRenderer: (vessel) => TooltipRenderer({ value: vessel.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    keyCreator: VesselKeyCreator,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
        cellRenderer: BlanksFilterRenderer,
    },
    width: 120,
};
