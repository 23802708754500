export const FIXTURE_LOAD_FIXTURES_SUCCESS = 'fixtures/LOAD_FIXTURES_SUCCESS';
export const FIXTURE_LOAD_FIXTURES_STARTING = 'fixtures/LOAD_FIXTURES_STARTING';
export const FIXTURE_INSERT_STARTING = 'fixtures/INSERT_STARTING';
export const FIXTURE_INSERT_SUCCESS = 'fixtures/INSERT_SUCCESS';
export const FIXTURE_INSERT_FAILURE = 'fixtures/INSERT_FAILURE';
export const FIXTURE_EDITED = 'fixtures/FIXTURE_EDITED';

export const USER_GROUP_SET = 'user/GROUP_SET';
export const USER_LOGGED_ON = 'user/LOGGED_ON';
export const USER_CHANGED_GRID_OPTIONS = 'user/USER_CHANGED_GRID_OPTIONS';
export const USER_CREATION_INDEX_SET = 'user/USER_CREATION_USER_SET';
export const USER_DATASIZE_SET = 'user/DATASIZE_SET';
export const USER_REPORT_TITLES_SET = 'user/REPORT_TITLES_SET';
export const TOGGLE_NEW_UI = 'user/TOGGLE_NEW_UI';
export const TOGGLE_DENSITY_VIEW = 'user/TOGGLE_DENSITY_VIEW';
export const TOGGLE_CONDENSED_VIEW = 'user/TOGGLE_CONDENSED_VIEW';

export const ADMIN_GROUP_ADD_STARTING = 'group/GROUP_ADD_STARTING';
export const ADMIN_GROUP_ADD_SUCCESS = 'group/GROUP_ADD_SUCCESS';
export const ADMIN_GROUP_ADD_FAILURE = 'group/GROUP_ADD_FAILURE';
export const ADMIN_FETCH_GROUP_STARTING = 'group/GROUP_FETCH_STARTING';
export const ADMIN_FETCH_GROUP_SUCCESS = 'group/GROUP_FETCH_SUCCESS';
export const ADMIN_FETCH_GROUP_FAILURE = 'group/GROUP_FETCH_FAILURE';

export const SOCKET_CONNECTED = 'status/SOCKET_CONNECTED';
export const SOCKET_DISCONNECTED = 'status/SOCKET_DISCONNECTED';
export const SOCKET_RECONNECTED = 'status/SOCKET_RECONNECTED';
export const NOTIFICATION_MESSAGE_ADDED = 'status/NOTIFICATION_MESSAGE_ADDED';
export const USER_DISMISSED_NOTIFICATION_MESSAGE =
    'status/USER_DISMISSED_NOTIFICATION_MESSAGE';

export const VERSION_RECEIVED = 'version/VERSION_RECEIVED';

export const LAYOUT_ON_LOAD = 'layout/ON_LOAD';
export const LAYOUT_ON_SELECT = 'layout/ON_SELECT';
export const LAYOUT_ON_UPDATE = 'layout/ON_UPDATE';
export const LAYOUT_ON_SAVE = 'layout/ON_SAVE';
export const LAYOUT_ON_DIRECTION_LOGIC_CHANGED =
    'layout/LAYOUT_ON_DIRECTION_LOGIC_CHANGED';
export const LAYOUT_ON_QUANTITY_FORMAT_CHANGED =
    'layout/LAYOUT_ON_QUANTITY_FORMAT_CHANGED';
export const LAYOUT_ON_DEFAULT_TYPE_CHANGED =
    'layout/LAYOUT_ON_DEFAULT_TYPE_CHANGED';
