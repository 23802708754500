import React from 'react';
import Select from 'react-select';
import { useRateStore } from '../store/RateStore';
import { durationUnits } from '../../../shared/columns/models/DurationUnits';
import {
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
} from '../../../../models/common/EntityPart';
import { QuantityParser } from '../../../shared/columns/tools/quantityParser';

const Duration = (props) => {
    const store = useRateStore();

    const parser = new QuantityParser();

    const getDurationUnits = () => {
        return durationUnits.filter((unit) => unit.isApplicableForRates);
    };

    const handleDurationUnitChange = (event) => {
        const durationUnit = event.value ? event : null;
        store.setDurationUnit(durationUnit);
    };

    const handleDurationAmountChange = (event) => {
        store.setSubmitted(false);

        if (event.target) {
            const parsedAmountObj = durationAmountValidation(
                event.target.value
            );

            if (parsedAmountObj) {
                const isAnyValue =
                    parsedAmountObj.parsedValue &&
                    parsedAmountObj.parsedValue !== '';

                store.setDurationAmount(
                    isAnyValue ? parsedAmountObj.parsedValue : ''
                );
                store.setDurationAmountObj(isAnyValue ? parsedAmountObj : null);
                store.setIsDurationAmountValid(true);
            }
        }
    };

    const durationAmountValidation = (value) => {
        if (value === '') {
            return { parsedValue: '' };
        }

        if (value === SEPARATOR_PLUS || value === SEPARATOR_FORWARD_SLASH) {
            return null;
        }

        const cleanValue = parser.cleanInteger(value);
        return parser.parse(cleanValue);
    };

    return (
        <div className="inline vertical-divider">
            <div className="inline-title">Duration</div>
            <div
                className="inline"
                style={{
                    marginBottom: '0px',
                }}
            >
                <input
                    id="DurationInput"
                    ref={props.durationInputRef}
                    type="text"
                    className="duration-amount-input"
                    onChange={handleDurationAmountChange}
                    value={store.durationAmount}
                    placeholder="Range"
                    style={
                        !store.isDurationAmountValid && store.submitted
                            ? {
                                  boxShadow: '0 0 0 1px red',
                                  color: 'red',
                              }
                            : {}
                    }
                    disabled={store.isDurationDisabled}
                />
            </div>
            <div
                className="inline"
                style={{
                    width: '115px',
                    marginBottom: '0px',
                }}
            >
                <Select
                    className="basic-single"
                    classNamePrefix="list"
                    isSearchable={false}
                    menuPlacement="auto"
                    options={getDurationUnits()}
                    onChange={handleDurationUnitChange}
                    value={store.durationUnit}
                    openMenuOnFocus={true}
                    isDisabled={store.isDurationDisabled}
                />
            </div>
            <div
                style={{
                    marginLeft: '5px',
                }}
            >
                {!store.isDurationAmountValid && store.submitted && (
                    <p style={{ color: 'red' }}>
                        {store.durationAmountValidationErrorMessage}
                    </p>
                )}
                {(props.isSeparatorException ||
                    (!store.arePartsValid &&
                        store.isDurationAmountValid &&
                        store.submitted)) && (
                    <p style={{ color: 'red' }}>Durations may not overlap</p>
                )}
            </div>
        </div>
    );
};

export default Duration;
