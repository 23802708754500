import React from 'react';

const VisibilityIcon = () => (
    <svg
        className="visibility"
        viewBox="0 2 24 24"
        fill="currentColor"
        width="24"
        height="24"
    >
        <g>
            <path d="M8.4,12.7c1.5,0,2.7-1.2,2.7-2.7S9.9,7.2,8.4,7.2S5.6,8.5,5.6,10S6.8,12.7,8.4,12.7z" />
            <path
                d="M16.7,8.7c0.5,0,1,0.1,1.4,0.3c-0.4-1-1.4-1.7-2.5-1.7c-1.5,0-2.7,1.2-2.7,2.7c0,0.4,0.1,0.9,0.3,1.2
		C13.7,9.8,15.1,8.7,16.7,8.7z"
            />
            <path d="M11.4,15c-1.1-0.3-2.2-0.5-3-0.5c-2.2,0-6.4,1.1-6.4,3.2V20h9.4V15z" />
        </g>
        <path
            d="M21,14.7c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.4-0.8-0.4h-0.1c-0.1,0-0.3,0-0.4,0v-0.8v-0.3c0-1.5-1.2-2.7-2.7-2.7
	S14,10.9,14,12.4v0.3v0.8h-0.5c-0.6,0-1.1,0.5-1.1,1.1v0.1c0,0,0,0,0,0v4.9c0,0,0,0,0,0V20c0,0.6,0.5,1.1,1.1,1.1h6.4
	c0.6,0,1.1-0.5,1.1-1.1v-0.1c0-0.1,0-0.2,0-0.3V14.7z M16.7,18.3c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.2-0.6-0.6-0.6-1
	c0-0.4,0.2-0.8,0.6-1c0.1-0.1,0.3-0.1,0.5-0.1c0.6,0,1.1,0.5,1.1,1.1C17.8,17.8,17.3,18.3,16.7,18.3z M16.7,11.2
	c0.7,0,1.3,0.5,1.3,1.4v0.9h-2.7v-0.9C15.4,11.9,15.9,11.2,16.7,11.2z"
        />
    </svg>
);

export default VisibilityIcon;
