import DateComparator from '../comparators/DateComparator';
import LoadZoneComparator from '../comparators/LoadZoneComparator';
import CategoryComparator from '_legacy/modules/columns/vessel/comparators/CategoryComparator';
import { firstBy } from 'thenby';
import {
    DATASET_SUEZMAX_LONDON,
    DATASET_SUEZMAX_HOUSTON,
    DATASET_AFRAMAX_LONDON,
    DATASET_CLEAN_LONDON,
    DATASET_FUEL_OIL_LONDON,
} from '../../../models/Datasets';

const defaultPostSort = ({
    itemAddedSessionIndex,
    rowNodes,
    sortingIsActive = false,
    datasetId,
}) => {
    if (rowNodes.length === 0) return;

    //if sorting has been applied by the user,
    //other mechanisms ensure the sorting is consistent via created date time comparators
    if (!sortingIsActive) {
        //if no sorting has been applied by the user, we should reorder the rows to be ordered by created date time, load zone and
        //reported date descending leaving the most recently inserted records data at the top of the grid, as expected by the users.
        rowNodes.sort(
            firstBy(function (n1, n2) {
                if (n1.data && n2.data) {
                    return DateComparator(
                        n1.data.reportedDate,
                        n2.data.reportedDate
                    );
                }
                return 0;
            }, -1)
                .thenBy(function (n1, n2) {
                    if (
                        datasetId === DATASET_SUEZMAX_LONDON ||
                        datasetId === DATASET_SUEZMAX_HOUSTON ||
                        datasetId === DATASET_AFRAMAX_LONDON ||
                        datasetId === DATASET_FUEL_OIL_LONDON
                    ) {
                        if (n1.data && n2.data) {
                            return LoadZoneComparator(
                                n1.data.loadZone,
                                n2.data.loadZone,
                                n1,
                                n2
                            );
                        }
                    } else if (datasetId === DATASET_CLEAN_LONDON) {
                        if (n1.data && n2.data) {
                            return CategoryComparator(
                                n1.data.vesselCategory,
                                n2.data.vesselCategory,
                                n1,
                                n2
                            );
                        }
                    }
                })
                .thenBy(function (n1, n2) {
                    if (n1.data && n2.data) {
                        return DateComparator(
                            n1.data.createdDateTime,
                            n2.data.createdDateTime
                        );
                    }
                }, -1)
        );
    }

    if (itemAddedSessionIndex === null) return;

    rowNodes.sort((n1, n2) => {
        if (!n1.data && !n2.data) {
            return 0;
        }

        const indexA = computeIndex(n1, itemAddedSessionIndex);
        const indexB = computeIndex(n2, itemAddedSessionIndex);

        if (indexA > indexB) {
            return -1;
        } else if (indexA < indexB) {
            return 1;
        }
        return 0;
    });
};

const computeIndex = (node, itemAddedSessionIndex) => {
    return node.data && node.data.createdIndex >= itemAddedSessionIndex
        ? node.data.createdIndex
        : -1;
};

export default defaultPostSort;
