import React, { Component } from 'react';
import ToolbarButton from './ToolbarButton';
import { Dropdown } from 'semantic-ui-react';
import { isWithinMenu } from '../common/Menu';

export const ANALYTICS = 1;
export const ADVANCED_ANALYTICS = 2;

export default class ReportsButton extends Component {
    constructor(props) {
        super(props);
        this.state = { dropdownValue: -1 };
    }

    render = () => {
        const options = [
            {
                key: 'AnalyticsMenuOption',
                text: (
                    <div className="reportsButton-item" name="Analytics">
                        <span>Analytics</span>
                        <span>Ctrl + Alt + R</span>
                    </div>
                ),
                value: ANALYTICS,
            },
            {
                key: 'AdvancedAnalyticsMenuOption',
                text: (
                    <div className="reportsButton-item">
                        <span>Advanced Analytics</span>
                        <span>Ctrl + Alt + E</span>
                    </div>
                ),
                value: ADVANCED_ANALYTICS,
            },
        ];

        return (
            <Dropdown
                className="reportsButton"
                trigger={
                    <ToolbarButton
                        id={this.props.id}
                        isActive={this.props.isActive}
                    >
                        Reports
                    </ToolbarButton>
                }
                options={options}
                value={this.state.dropdownValue}
                onOpen={this.props.onShow}
                onClose={this.props.onHide}
                onChange={(e, data) => {
                    this.setState({ dropdownValue: -1 });
                    if (isWithinMenu(e.target) || e.key === 'Enter') {
                        if (data.value === ANALYTICS) {
                            this.props.onToggleAnalytics &&
                                this.props.onToggleAnalytics(ANALYTICS);
                        } else if (data.value === ADVANCED_ANALYTICS) {
                            // Not using a query string parameter as the cloud login redirect seems to strip them off.
                            this.props.openAdvancedAnalytics();
                        }
                    }
                }}
            />
        );
    };
}
