import VesselPostProcessingService from './VesselPostProcessingService';

class SpecialisedVesselPostProcessingService extends VesselPostProcessingService {
    updateOwnerIfRequired(newValue, data, variables, tabType) {
        if (!newValue || newValue?.shouldUpdateOwner) {
            const owner =
                newValue && newValue.bestOperator
                    ? { id: null, name: newValue.bestOperator.toUpperCase() }
                    : null;
            data['owner'] = owner;
            variables[tabType]['owner'] = owner;
        }
    }

    getOldOwner(props) {
        return props.data.owner ? props.data.owner.name.toUpperCase() : null;
    }

    getNewOwner(vessel) {
        return vessel.bestOperator ? vessel.bestOperator.toUpperCase() : null;
    }

    getOldOwnerName(props) {
        return this.getOldOwner(props);
    }

    getNewOwnerName(vessel) {
        return this.getNewOwner(vessel);
    }
}

export default SpecialisedVesselPostProcessingService;
