import client from './graphQlClient';
import {
    FIXTURE_RECENT_CHANGES_FOR_DATASET_QUERY,
    GET_MAINTAINANCE_MESSAGE_QUERY,
    MAKE_FIXTURE_RUMOURED_MUTATION,
    MAKE_FIXTURE_UNRUMOURED_MUTATION,
    UPDATE_FIXTURE_RUMOURED_FIELDS_MUTATION,
} from './queries/fixtures/FixtureQueries';
import { apiBaseUrl } from '../config';
import { sessionId } from './session';
import { authenticationService } from '_legacy/auth/AuthenticationService';
import logger from '_legacy/diagnostics/logging/logger';
import { displayOnlyRumouredHandler } from './apiHelpers';

export class FixtureApi {
    static CONTEXT = {
        errorHandling: {
            unknownResult: {
                resolution: {
                    isSafeToRetry: true,
                },
            },
        },
    };
    static FETCH_POLICY = 'no-cache';

    static fetchFixtures = async (
        groupId,
        numberOfYears,
        cancelToken,
        includeDeleted,
        displayOnlyRumoured
    ) => {
        const changesSinceSnapshotPromise = (async () => {
            try {
                const resultPromise = client().query({
                    query: FIXTURE_RECENT_CHANGES_FOR_DATASET_QUERY,
                    variables: { groupId, numberOfYears },
                    fetchPolicy: this.FETCH_POLICY,
                });

                const result = await Promise.race([
                    resultPromise,
                    cancelToken.promise,
                ]);

                if (result.reason) {
                    logger.error(
                        'Result changesSinceSnapshotPromise: ',
                        result
                    );
                    throw result;
                }

                return result.data;
            } catch (error) {
                logger.error('Error changesSinceSnapshotPromise: ', error);
                throw error;
            }
        })();

        const snapshotPromise = (async () => {
            const result = await fetch(
                `https://${apiBaseUrl}/Fixtures/Snapshot?` +
                    new URLSearchParams({
                        groupId: groupId,
                        numberOfYears: numberOfYears,
                    }),
                {
                    headers: {
                        authorization: encodeURIComponent(
                            authenticationService.getAccessToken()
                        ),
                        //so we know which session makes the call
                        fortSessionId: sessionId,
                    },
                }
            );

            if (result.status === 204)
                // 204=No Content, e.g. there's no snashot data generated yet.
                return [];

            var resultJson = await result.json();
            if (!resultJson) throw resultJson;
            return resultJson;
        })();

        const {
            sharedFixturesRecentlyChanged = [],
            groupOnlyFixtures = [],
            fixturesWithPrivateComments = [],
            individualFixtures = [],
        } = (await changesSinceSnapshotPromise) || {};

        let fixtures = await snapshotPromise;

        const m = new Map();

        fixtures.forEach((i) => m.set(i.id, i));
        sharedFixturesRecentlyChanged.forEach((f) => m.set(f.id, f));
        groupOnlyFixtures.forEach((f) => m.set(f.id, f));
        fixturesWithPrivateComments.forEach((f) => m.set(f.id, f));
        individualFixtures.forEach((f) => m.set(f.id, f));

        displayOnlyRumouredHandler(m, displayOnlyRumoured);

        if (!includeDeleted) {
            m.forEach((f) => {
                if (f.state === 'deleted') {
                    m.delete(f.id);
                }
            });
        }

        fixtures = Array.from(m.values());

        return fixtures;
    };

    static getMaintainanceMessage = (reportName) => {
        return new Promise((resolve, reject) => {
            client()
                .query({
                    query: GET_MAINTAINANCE_MESSAGE_QUERY,
                    fetchPolicy: this.FETCH_POLICY,
                    variables: {
                        reportName,
                    },
                })
                .then((result) => {
                    resolve(result.data.getAnalyticsMaintainanceMessage);
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    };

    static makeUnRumoured = (client, variables) =>
        client
            .mutate({
                mutation: MAKE_FIXTURE_UNRUMOURED_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.makeFixtureUnRumoured);

    static makeRumoured = (client, variables) =>
        client
            .mutate({
                mutation: MAKE_FIXTURE_RUMOURED_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.makeFixtureRumoured);

    static updateRumouredFields = (client, variables) =>
        client
            .mutate({
                mutation: UPDATE_FIXTURE_RUMOURED_FIELDS_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.updateFixtureRumouredFields);
}
