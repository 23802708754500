import initialState from './initialState';
import * as types from '../actions/actionTypes';

const groupReducer = (state = initialState.group, action) => {
    switch (action.type) {
        case types.ADMIN_FETCH_GROUP_STARTING:
            return { ...state, isLoading: true, hadLoadingError: false };
        case types.ADMIN_FETCH_GROUP_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                data: action.data,
            };
        case types.ADMIN_FETCH_GROUP_FAILURE:
            return { ...state, hadLoadingError: true };
        case types.ADMIN_GROUP_ADD_STARTING:
            return { ...state, isAdding: true, addError: undefined };
        case types.ADMIN_GROUP_ADD_SUCCESS:
            return {
                ...state,
                isAdding: false,
                data: [...state.data, action.group],
            };
        case types.ADMIN_GROUP_ADD_FAILURE:
            return { ...state, isAdding: false, addError: action.error };
        default:
            return state;
    }
};

export default groupReducer;
