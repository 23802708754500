import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./vesselFragment.gql');
const ORDER_FRAGMENT = loader('./orderVesselFragment.gql');

export const VESSEL_TYPE_NAME = 'Vessel';
export const VESSEL_FRAGMENT_NAME = 'VesselFragment';
export const ORDER_VESSEL_FRAGMENT_NAME = 'OrderVesselFragment';
export const VESSEL_FRAGMENT = gql`
    ${FRAGMENT}
`;
export const ORDER_VESSEL_FRAGMENT = gql`
    ${ORDER_FRAGMENT}
`;
