import { ListenerMiddlewareInstance } from '@reduxjs/toolkit';
import { refreshUserInfo } from '../feature/userSlice';
import {
    ensureSavedGroupIsPresentInUsersCurrentGroups,
    saveSelectedLayoutId,
} from '_legacy/localStorage';
import { selectLayout } from '../feature/layoutsSlice';
import { RootState } from 'store';

function configureSessionPersistenceEvents(
    listener: ListenerMiddlewareInstance<RootState>
): void {
    listener.startListening({
        actionCreator: refreshUserInfo.fulfilled,
        effect: ({ payload: { groups } }) => {
            ensureSavedGroupIsPresentInUsersCurrentGroups(groups);
        },
    });

    listener.startListening({
        actionCreator: selectLayout,
        effect: ({ payload: { id } }) => {
            saveSelectedLayoutId(id);
        },
    });
}

export default configureSessionPersistenceEvents;
