const isRumouredField = (colDef, data) => {
    if (colDef && data && data.rumouredFields) {
        return (
            data.rumouredFields &&
            (data.rumouredFields.includes(colDef.field) ||
                data.rumouredFields.includes(colDef.colId))
        );
    } else {
        return false;
    }
};

export default isRumouredField;
