import CompanyEditor from './CompanyEditor';
import { OwnersApi } from '../../../api/ownersApi';

class OwnerEditor extends CompanyEditor {
    render() {
        return super.render(OwnersApi.search);
    }
}

export default OwnerEditor;
