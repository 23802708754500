import {
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_SEPARATOR,
} from './common/EntityPart';

export const createCustomFreightPortPart = (freightPort) => {
    return createPart(
        freightPort.loadCount,
        freightPort.dischargeCount,
        ENTITY_PART_TYPE_CUSTOM
    );
};

export const createSeparatorFreightPortPart = (character) => {
    return createPart(0, 0, ENTITY_PART_TYPE_SEPARATOR);
};

const createPart = (loadCount, dischargeCount, partType) => {
    return { loadCount, dischargeCount, partType };
};
