import React from 'react';
import ReactTooltip from 'react-tooltip';

const LastUpdatedByTooltip = (props) => {
    const generateTooltip = (data) => {
        if (!data || !data.lastUpdatedByUser) {
            return null;
        }

        const fullName =
            data.lastUpdatedByUser.fullName ?? data.lastUpdatedByUser.username;

        return `
    <table>
      <tr>
        <th>Full Name:</th>
        <td>${fullName}</td>
      </tr>
    </table>
  `;
    };

    return (
        <ReactTooltip
            id={`${props.context.gridId}-last-updated-by`}
            className="fort-grid-tooltip"
            border={true}
            place="right"
            type="light"
            multiline={true}
            html={true}
            getContent={(rowIndex) => {
                if (props.getRowDataCallback) {
                    const rowNode = props.getRowDataCallback(Number(rowIndex));

                    if (rowNode) {
                        return generateTooltip(rowNode.data);
                    }
                }
                return null;
            }}
        />
    );
};

export default LastUpdatedByTooltip;
