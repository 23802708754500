import BooleanFormatter from '../formatters/BooleanFormatter';

const BooleanComparator = (valueA, valueB) => {
    if (valueA === null && valueB === null) {
        return 0;
    }

    if (valueA === null) {
        return -1;
    }

    if (valueB === null) {
        return 1;
    }

    const fValueA = BooleanFormatter({ value: valueA });
    const fValueB = BooleanFormatter({ value: valueB });

    if (fValueA < fValueB) {
        return -1;
    }
    if (fValueA > fValueB) {
        return 1;
    }

    return 0;
};

export default BooleanComparator;
