import Logger from '../../../diagnostics/logging/logger';
import { modalService } from 'common';
import { cloneDeep } from 'lodash';
import MixpanelLogger from '../../../diagnostics/logging/mixpanelLogger';
import ShareConfirmation from '../../../components/ui/ShareConfirmation';

export const createShareMenuItem = (props, node, gridId) => {
    const { group, data } = node;

    if (!group && data) {
        let items = [];
        items.push(createShareToAllMenuItem(props, group, data, gridId));

        if (data.isIndividual) {
            items.push(createShareToGroupMenuItem(props, group, data));
        }

        return items;
    }

    return null;
};

const createShareToAllMenuItem = (props, group, data, gridId) => {
    const title = 'Make Visible To All Groups';
    const tooltip = 'The action CANNOT be undone';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.share', gridId);

                const clonedData = cloneDeep(data);
                handleOnShare(props, clonedData, false);
            }
        },
        tooltip: tooltip,
    };
};

const createShareToGroupMenuItem = (props, group, data) => {
    const title = 'Make Visible To Group Only';
    const tooltip = 'The action CANNOT be undone';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                const clonedData = cloneDeep(data);
                handleOnShare(props, clonedData, true);
            }
        },
        tooltip: tooltip,
    };
};

const handleOnShare = (props, entity, isShareToGroupOnly) => {
    const { onShare, onShareToGroup } = props;
    const {
        id,
        groupOnly,
        isIndividual,
        privacy,
        __typename: typeName,
    } = entity;
    const { selectedGroup, token } = props;

    const content = (
        <ShareConfirmation
            groupOnlyExpiryDate={entity.groupOnlyExpiryDate}
            isShareToGroupOnly={isShareToGroupOnly}
            onConfirm={() => {
                isShareToGroupOnly ? onShareToGroup(entity) : onShare(entity);
                modalService.close();

                const mixpanelProps = {
                    itemId: id,
                    itemName: typeName,
                    groupOnly: isShareToGroupOnly ? true : false,
                    privacy,
                    isIndividual: false,
                    fortGroup: selectedGroup.name,
                    datasetId: token,
                    visibilityStatus: isShareToGroupOnly
                        ? 'Group Only'
                        : 'Shared',
                    previousVisibilityStatus: isIndividual
                        ? 'Individual'
                        : groupOnly
                        ? 'Group Only'
                        : 'Shared',
                };

                MixpanelLogger.trackChangeVisibilityEvent(mixpanelProps);
            }}
            onClose={modalService.close}
        />
    );

    modalService.open({ content });
};
