import React, { Component } from 'react';
import classNames from 'classnames';
import * as keyboardCodes from '../../../../constants/keyboardCodes';
import ChevronDown from '../../../../components/icons/ChevronDown';

export default class SelectEditor extends Component {
    constructor(props) {
        super(props);

        this.containerRef = React.createRef();
        this.state = {
            initialValue: props.value,
            value: props.value,
            lastCharacter: null,
            offsetIndex: 0,
        };
    }

    isPopup = () => true;

    componentDidMount() {
        if (this.props.charPress) {
            this.searchByFirstCharacter(this.props.charPress);
        }
    }

    componentDidUpdate() {
        this.focus();
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.containerRef.current) {
            this.containerRef.current.focus();
        }
    }

    getValue() {
        return this.state.value;
    }

    handleKeyDown = (e) => {
        if (e.keyCode === keyboardCodes.KEY_DOWN) {
            e.preventDefault();
            e.stopPropagation();

            this.moveSelectionDown();
        } else if (e.keyCode === keyboardCodes.KEY_UP) {
            e.preventDefault();
            e.stopPropagation();

            this.moveSelectionUp();
        } else if (this.isAlpha(e.keyCode)) {
            e.preventDefault();
            e.stopPropagation();

            this.searchByFirstCharacter(e.key);
        }
    };

    isAlpha(keyCode) {
        return keyCode > 64 && keyCode < 91;
    }

    getValues() {
        if (this.props.values) {
            return this.props.values;
        } else if (this.props.valuesByDataset) {
            return this.props.valuesByDataset(this.props.context.datasetId);
        }
    }

    searchByFirstCharacter = (character) => {
        // Find all items with the same first character as the one just been entered
        var datasetId = this.props.context && this.props.context.datasetId;

        var matchingValues = this.getValues().filter((value) => {
            return this.props
                .formatValue(value, datasetId)
                .toLowerCase()
                .startsWith(character.toLowerCase());
        });

        // If there are no matches, reset the state, but leave the current selected value
        if (matchingValues.length === 0) {
            this.setState({
                offsetIndex: 0,
                lastCharacter: character.toLowerCase(),
            });

            return;
        }

        let currentOffsetIndex = this.state.offsetIndex;

        if (character.toLowerCase() === this.state.lastCharacter) {
            // Character keyed again
            currentOffsetIndex++;

            // Exceeded the bounds of available items, so loop around to the first one
            if (currentOffsetIndex >= matchingValues.length) {
                currentOffsetIndex = 0;
            }
        } else {
            // different character keyed
            currentOffsetIndex = 0;
        }

        const chosenValue = matchingValues[currentOffsetIndex];

        this.setState({
            value: chosenValue,
            offsetIndex: currentOffsetIndex,
            lastCharacter: character.toLowerCase(),
        });
    };

    moveSelectionDown = () => {
        const currentIndex = this.getValues().indexOf(this.state.value);

        if (currentIndex < this.getValues().length - 1) {
            const nextValue = this.getValues()[currentIndex + 1];
            this.setState({ value: nextValue });
        }
    };

    moveSelectionUp = () => {
        const currentIndex = this.getValues().indexOf(this.state.value);

        if (currentIndex > 0) {
            const nextValue = this.getValues()[currentIndex - 1];
            this.setState({ value: nextValue });
        }
    };

    handleClick = (e) => {
        this.setState({ value: e.target.dataset.value }, () =>
            this.props.stopEditing()
        );
    };

    handleMouseOver = (e) => {
        this.setState({ value: e.target.dataset.value });
    };

    render() {
        const datasetId = this.props.context && this.props.context.datasetId;
        const items = this.getValues().map((v, i) => {
            const classes = classNames({
                'selectEditor-row': true,
                'selectEditor-row-selected': v === this.state.value,
            });

            let displayValue = '\u00A0';

            if (v.length > 0 || typeof v === 'boolean') {
                displayValue = this.props.formatValue(v, datasetId);
            }

            return (
                <div
                    data-value={v}
                    className={classes}
                    onClick={this.handleClick}
                    onMouseOver={this.handleMouseOver}
                    key={v}
                >
                    {displayValue}
                </div>
            );
        });

        return (
            <>
                <div
                    className="selectEditor-overlay"
                    onClick={() => {
                        // we use overlay to intercept and prevent outside clicks saving the selected value by removing the ability for grid to get the value
                        this.getValue = () => this.state.initialValue;

                        this.props.stopEditing();
                    }}
                />
                <div
                    ref={this.containerRef}
                    className="selectEditor"
                    tabIndex={1}
                    onKeyDown={this.handleKeyDown}
                >
                    <div className="selectEditor-row selectEditor-value">
                        {this.props.formatValue(
                            this.state.initialValue,
                            datasetId
                        )}
                        <ChevronDown />
                    </div>
                    <div className="selectEditor-list">{items}</div>
                </div>
            </>
        );
    }
}
