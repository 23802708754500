const initialState = {
    group: {
        isAdding: false,
        data: [],
        isLoading: false,
        isLoaded: false,
        hadLoadingError: false,
    },
    status: {
        hasSocketDisconnected: false,
        hasSocketReconnected: false,
    },
    notificationMessages: [],
    version: null,
};

export default initialState;
