class DurationParseResult {
    constructor(inObj) {
        this.parsedValue = '';
        this.isSuccess = false;
        this.from = NaN;
        this.to = NaN;

        if (inObj) {
            Object.assign(this, inObj);
        }
    }
}

class DurationParser {
    getFailingParseResult(inputString) {
        return new DurationParseResult({
            parsedValue: inputString,
        });
    }

    parsers = [
        {
            name: 'range parser',
            isMatch: (value) => /^[0-9]+-[0-9]+$/.test(value),
            isGreedy: true,
            formatValue: this.parseRangeValue.bind(this),
        },
        {
            name: 'exact number parser',
            isMatch: (value) => /^[0-9]+$/.test(value),
            isGreedy: false,
            formatValue: this.parseExactValue.bind(this),
        },
    ];

    parseExactValue(numberString) {
        const amount = this.parseNumberString(numberString);

        return new DurationParseResult({
            parsedValue: numberString,
            isSuccess: !isNaN(amount),
            from: amount,
            to: amount,
        });
    }

    parseNumberString(value) {
        const cleanValue = value.replace(/[\s,]/g, '');
        return +parseFloat(cleanValue).toFixed(2);
    }

    parseRangeValue(value) {
        const splitedStrings = value.split(/[-/]/g);

        const from = this.parseNumberString(splitedStrings[0]);
        const to = this.parseNumberString(splitedStrings[1]);

        return new DurationParseResult({
            parsedValue: value,
            isSuccess: true,
            from,
            to,
        });
    }

    parse(inputString) {
        for (const parser of this.parsers) {
            if (parser.isMatch(inputString)) {
                const parseResult = parser.formatValue(inputString);
                if (parseResult.isSuccess || parser.isGreedy) {
                    return parseResult;
                }
            }
        }

        return this.getFailingParseResult(inputString);
    }

    clean(inputString) {
        if (!inputString) {
            return inputString;
        }

        return inputString.replace(/[^0-9-]*/gi, '');
    }
}

export { DurationParseResult, DurationParser };
