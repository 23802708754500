import ShortHandQuantityFormatService from './ShortHandQuantityFormatService';
import LongHandQuantityFormatService from './LongHandQuantityFormatService';

export const SHORT_HAND_QUANTITY_FORMAT = 'ShortHand';
export const LONG_HAND_QUANTITY_FORMAT = 'LongHand';

export const getQuantityService = (quantityFormat) => {
    switch (quantityFormat) {
        case SHORT_HAND_QUANTITY_FORMAT:
            return new ShortHandQuantityFormatService();
        case LONG_HAND_QUANTITY_FORMAT:
            return new LongHandQuantityFormatService();
        default:
            return null;
    }
};
