import Logger from '../../../diagnostics/logging/logger';
import MixpanelLogger from '../../../diagnostics/logging/mixpanelLogger';

export const createAuditTrailMenuItem = (props, gridApi, node, gridId) => {
    const { group, data } = node;
    const { id, privacy, groupOnly, __typename: typeName } = data;
    const { selectedGroup } = props;

    const title = 'Audit History';
    const tooltip = 'View Audit History';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.openAuditHistory', gridId);
                MixpanelLogger.trackOpenAuditHistoryEvent(
                    id,
                    typeName,
                    groupOnly,
                    privacy,
                    selectedGroup.name
                );

                gridApi.deselectAll();
                node.setSelected(true);
                props.onOpenAuditActivity();
            }
        },
        tooltip: tooltip,
    };
};
