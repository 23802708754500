import React from 'react';
import Select from 'react-select';
import { useRateStore } from '../store/RateStore';
import { getRateIndicesByDatasetId } from '../models/RateIndices';

const selectStyles = {
    indicatorSeparator: (provided) => {
        return { ...provided, height: '20px !important' };
    },
    dropdownIndicator: (provided) => {
        return { ...provided, paddingTop: '6px !important' };
    },
    input: (provided) => {
        return {
            ...provided,
            marginLeft: '5px !important',
        };
    },
    placeholder: (provided) => {
        return {
            ...provided,
            paddingLeft: '5px',
        };
    },
    singleValue: (provided) => {
        return {
            ...provided,
            paddingLeft: '10px',
        };
    },
    control: (provided) => {
        return {
            ...provided,
            marginTop: '5px',
            height: '38px !important',
            width: '130px',
        };
    },
    valueContainer: (provided) => {
        return {
            ...provided,
            padding: '0 !important',
        };
    },
};

const selectErrorStyles = {
    ...selectStyles,
    container: (provided) => {
        return {
            ...provided,
            boxShadow: '0 0 0 1px red',
            borderRadius: '5px',
        };
    },
};

const RateIndex = (props) => {
    const store = useRateStore();

    const handleIndexChange = (event) => {
        const inputIndex = event.value ? event : null;
        store.setIndex(inputIndex);
        store.setIsIndexValid(true);
    };

    return (
        <div className="inline" style={{ marginTop: '0' }}>
            <Select
                styles={
                    !store.isIndexValid && store.submitted
                        ? selectErrorStyles
                        : selectStyles
                }
                classNamePrefix="list"
                options={getRateIndicesByDatasetId(props.context.datasetId)}
                menuPlacement="auto"
                onChange={handleIndexChange}
                value={store.index}
                openMenuOnFocus={true}
                placeholder="Rate index"
            />
            {!store.isIndexValid && store.submitted && (
                <p style={{ color: 'red' }}>Rate index is invalid</p>
            )}
        </div>
    );
};

export default RateIndex;
