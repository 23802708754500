import naturalSortComparator from '../../../../components/grid/comparators/NaturalSortComparator';
import { calculateDurationAverageDays } from '../tools/DurationPartsConverter';

const DurationPartsComparator = (valueA, valueB) => {
    const formattedA = calculateDurationAverageDays(valueA);
    const formattedB = calculateDurationAverageDays(valueB);

    return naturalSortComparator(formattedA, formattedB);
};

export default DurationPartsComparator;
