import VesselPostProcessingService from './VesselPostProcessingService';

class ThirdPartyOwnerVesselPostProcessingService extends VesselPostProcessingService {
    updateOwnerIfRequired(newValue, data, variables, tabType) {
        if (!newValue || newValue?.shouldUpdateOwner) {
            const owner =
                newValue && newValue.gainCompany
                    ? {
                          value: newValue.gainCompany.value,
                          partType: newValue.gainCompany.partType,
                          name: newValue.gainCompany.name,
                      }
                    : null;
            data['thirdPartyOwner'] = owner;
            variables[tabType]['thirdPartyOwner'] = owner;
        }
    }

    getOldOwner(props) {
        return props.data.thirdPartyOwner
            ? props.data.thirdPartyOwner.value +
                  props.data.thirdPartyOwner.partType
            : null;
    }

    getNewOwner(vessel) {
        return vessel.gainCompany
            ? vessel.gainCompany.value + vessel.gainCompany.partType
            : null;
    }

    getOldOwnerName(props) {
        return props.data.thirdPartyOwner
            ? props.data.thirdPartyOwner.name
            : null;
    }

    getNewOwnerName(vessel) {
        return vessel.gainCompany ? vessel.gainCompany.name.toUpperCase() : '';
    }
}

export default ThirdPartyOwnerVesselPostProcessingService;
