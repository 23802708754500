import currencies from '../models/Currencies';
import { getIndexByValue } from '../models/RateIndices';
import { ENTITY_PART_TYPE_RATE } from '_legacy/models/common/EntityPart';
import { flatRateValue } from '../models/RateTypes';

class RateFormatter {
    numberFormat = new Intl.NumberFormat('en-GB');

    numberFormatForDecimalValues = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
    });

    startValueForHidingDecimals = 1000;

    format(part) {
        if (!part) {
            return '';
        }

        if (part.partType === ENTITY_PART_TYPE_RATE) {
            const formattedDuration = part.rateValue.duration
                ? this.formatDuration(part.rateValue.duration)
                : '';

            const formattedRateValue =
                part.rateValue.type === flatRateValue
                    ? this.formatFlatRate(part.rateValue.flatRate)
                    : this.formatBalticIndex(part.rateValue.balticIndex);

            return formattedDuration === ''
                ? `${formattedRateValue}`
                : `${formattedRateValue} ${formattedDuration}`;
        } else {
            return part.separatorValue;
        }
    }

    formatFlatRate(flatRate) {
        if (flatRate.amount && flatRate.amount.from && flatRate.amount.to) {
            const amountString = this.formatAmount(flatRate.amount);

            if (flatRate.currency && flatRate.currency !== 'Unknown') {
                const boundString = flatRate.bound ? `${flatRate.bound} ` : '';

                const currency = currencies.find(
                    (ccy) => ccy.value === flatRate.currency
                ).label;

                return `${boundString}${currency}${amountString} ${flatRate.unit}`;
            }

            return `${flatRate.unit} ${amountString}`;
        }

        return flatRate.unit;
    }

    formatBalticIndex(balticIndex) {
        const amountString = this.formatAmount(balticIndex.amount, true);
        const rateIndex = getIndexByValue(balticIndex.index);

        return `${amountString}% ${rateIndex.label}`;
    }

    formatAmount(amount, withoutNumberFormatting) {
        if (!amount) return '';

        return amount.from === amount.to
            ? this.formatNumber(amount.from, withoutNumberFormatting)
            : `${this.formatNumber(
                  amount.from,
                  withoutNumberFormatting
              )}-${this.formatNumber(amount.to, withoutNumberFormatting)}`;
    }

    formatDuration(duration) {
        const { amount, unit } = duration;

        const formattedAmount = this.formatAmount(amount, true);
        const formattedAmountEnding = this.formatAmountEnding(amount);
        const formattedUnit =
            amount.from === amount.to ? unit.slice(0, -1) : unit;

        return `(${formattedAmount}${formattedAmountEnding} ${formattedUnit})`;
    }

    formatAmountEnding(amount) {
        if (!amount.from || amount.from !== amount.to) {
            return '';
        }

        const tenths = amount.from % 10;
        const hundredths = amount.from % 100;

        return tenths === 1 && hundredths !== 11
            ? 'ST'
            : tenths === 2 && hundredths !== 12
            ? 'ND'
            : tenths === 3 && hundredths !== 13
            ? 'RD'
            : 'TH';
    }

    formatNumber(value, withoutNumberFormatting) {
        if (isNaN(value)) {
            return '';
        }

        return withoutNumberFormatting
            ? value
            : value >= this.startValueForHidingDecimals
            ? this.numberFormat.format(value)
            : this.numberFormatForDecimalValues.format(value);
    }
}

const rateFormatterInstance = new RateFormatter();

export { RateFormatter, rateFormatterInstance };
