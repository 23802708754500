import React from 'react';
import {
    PANAMAX_DIRECTION_LOGIC,
    CAPE_DIRECTION_LOGIC,
    SUPRAMAX_DIRECTION_LOGIC,
} from '../../../services/directions/DirectionServiceFactory';
import { Radio } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import * as layoutActions from '../../../actions/layoutActions';
import { groupSortingByDirection } from '../../../models/Direction';
import update from 'immutability-helper';
import { changeGridSettings } from 'store/feature/layoutsSlice';

const panelContainerStyle = () => {
    return {
        textAlign: 'left',
        display: 'inline-grid',
        lineHeight: '1.5',
        marginTop: '5px',
        marginLeft: '50px',
    };
};
const directionColId = 'direction';

const DirectionLogicItems = (props) => {
    const handleOnChangeDirectionLogic = (event, { label }) => {
        updateLayout(label);
    };

    const isChecked = (value) => {
        return value === props.currentDirectionLogic;
    };

    const updateLayout = (directionLogic) => {
        const orderOptionsWithNewRowGroupsSorting = {
            ...props.currentOrderOptions,
            rowGroupsSorting: getNewRowGroupsSorting(directionLogic),
        };
        const fixtureOptionsWithNewRowGroupsSorting = {
            ...props.currentFixtureOptions,
            rowGroupsSorting: getNewRowGroupsSorting(directionLogic),
        };
        const cleanCurrentOrderOptions = getCleanCurrentItemsOptions(
            orderOptionsWithNewRowGroupsSorting
        );
        const cleanCurrentFixtureOptions = getCleanCurrentItemsOptions(
            fixtureOptionsWithNewRowGroupsSorting
        );
        clearRowGrouping();

        props.actions.onDirectionLogicChanged(directionLogic);
        // temp solution until direction logic has been refactored
        // this part of code MUST NOT dispatch 3 actions
        props.dispatch(
            changeGridSettings({
                gridType: 'orders',
                gridSettings: cleanCurrentOrderOptions,
            })
        );
        props.dispatch(
            changeGridSettings({
                gridType: 'fixtures',
                gridSettings: cleanCurrentFixtureOptions,
            })
        );
    };

    const getNewRowGroupsSorting = (currentDirectionLogic) => {
        const directionGroupSorting = groupSortingByDirection(
            props.datasetId,
            currentDirectionLogic
        );
        const rowGroupsSorting = update(
            props.currentOrderOptions.rowGroupsSorting,
            {
                [getRowGroupSortingIndex()]: {
                    $apply: function (x) {
                        return { ...x, sorting: directionGroupSorting };
                    },
                },
            }
        );

        return rowGroupsSorting;
    };

    const getRowGroupSortingIndex = () =>
        props.currentOrderOptions.rowGroupsSorting.findIndex(
            (c) => c.colId === directionColId
        );

    const getCleanCurrentItemsOptions = (currentOptions) => {
        const cleanCurrentFilterOptions = clearFilters(currentOptions);
        const cleanCurrentColumnOptions = clearSortings(currentOptions);

        return {
            ...currentOptions,
            columnOptions: cleanCurrentColumnOptions,
            filterOptions: cleanCurrentFilterOptions,
        };
    };

    const clearFilters = (currentOptions) => {
        const currentFilterOptions = {
            ...currentOptions.filterOptions,
        };

        delete currentFilterOptions.direction;

        return currentFilterOptions;
    };

    const clearSortings = (currentOptions) => {
        const currentColumnOptions = cloneDeep(currentOptions.columnOptions);

        currentColumnOptions.forEach((element, index) => {
            if (element.colId === directionColId) {
                element.sort = null;
            }
        });

        return currentColumnOptions;
    };

    const clearRowGrouping = () => {
        const currentRowGroups = props.columnApi.getRowGroupColumns();
        const isDirectionRowGroupPresent = currentRowGroups.filter(
            (rowGroup) => rowGroup.colId === directionColId
        )[0];

        if (isDirectionRowGroupPresent) {
            props.onColumnRowGroupChanged(props);
        }
    };

    return (
        <div style={panelContainerStyle()}>
            <Radio
                checked={isChecked(CAPE_DIRECTION_LOGIC)}
                onChange={handleOnChangeDirectionLogic}
                label={CAPE_DIRECTION_LOGIC}
            />
            <Radio
                checked={isChecked(PANAMAX_DIRECTION_LOGIC)}
                onChange={handleOnChangeDirectionLogic}
                label={PANAMAX_DIRECTION_LOGIC}
            />
            <Radio
                checked={isChecked(SUPRAMAX_DIRECTION_LOGIC)}
                onChange={handleOnChangeDirectionLogic}
                label={SUPRAMAX_DIRECTION_LOGIC}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    const props = {
        currentOrderOptions: state.layouts.selectedLayout.orders.currentOptions,
        currentFixtureOptions:
            state.layouts.selectedLayout.fixtures.currentOptions,
        currentDirectionLogic:
            state.layouts.selectedLayout.commonSettings.directionLogic
                .currentState,
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...layoutActions }, dispatch),
        dispatch,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DirectionLogicItems);
