import { rateUnits } from '_legacy/modules/columns/rate/models/RateUnits';
import getDatasetSpecificHeadingsTitles from '../services/headingsServices/getDatasetSpecificHeadingsTitles';
import {
    SHORT_HAND_QUANTITY_FORMAT,
    LONG_HAND_QUANTITY_FORMAT,
} from '_legacy/modules/columns/quantity/services/QuatityServiceFactory';
import {
    TCT,
    VOY,
} from '_legacy/components/toolPanels/commonSettings/OrderOrFixtureTypes';

interface IDataset {
    label: string;
    value: number;
    allowFreeTextCargo: boolean;
    allowedPlaceholderCargoes: string[];
    allowFreeTextZone: boolean;
    allowFreeTextLocation: boolean;
    allowSearchCountries: boolean;
    searchVesselsWithImoOnly: boolean;
    nonAvailableFields: string[];
    nonAvailableOrderFields?: string[];
    defaultOrderColumnsToShow: string[];
    defaultFixtureColumnsToShow: string[];
    defaultQuantityFormat: string;
    defaultType?: string;
    isStemSizeAvailable: boolean;
    autoPopulateDirectionEnabled: boolean;
    shouldHighlightNewOrders: boolean;
    shouldShowConvertedOrders: boolean;
    shouldResetCargoIfPeriodType: boolean;
    maxLocationsSearchResult: number;
    vesselOptions: {
        minDWT: number;
        maxDWT: number;
    };
    numberForQuantityFormating: number;
    headingsMapping: {
        areas: string;
    };
    allowSearchVesselsByEniNumber: boolean;
    nonAvailableDurationUnits: string[];
    resolveRateUnit?: (amountFrom: number) => any;
}

export const DATASET_SUEZMAX_LONDON = 1;
export const DATASET_VLCC_HOUSTON = 2;
export const DATASET_AFRAMAX_LONDON = 3;
export const DATASET_CLEAN_HOUSTON = 4;
export const DATASET_SUEZMAX_HOUSTON = 5;
export const DATASET_VLCC_LONDON = 6;
export const DATASET_AFRAMAX_HOUSTON = 7;
export const DATASET_CLEAN_LONDON = 8;
export const DATASET_DIRTY_HOUSTON_25_85 = 9;
export const DATASET_FUEL_OIL_LONDON = 10;
export const DATASET_DIRTY_DUBAI_25_130 = 11;
export const DATASET_SHELL = 12;
export const DATASET_SPECIALISED = 13;
export const DATASET_DRY_CARGO = 14;
export const DATASET_TANKERS = 15;

export const DATASET_TEST = 99;

export const dataSets: IDataset[] = [
    {
        label: 'SUEZMAX LONDON',
        value: DATASET_SUEZMAX_LONDON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(
            DATASET_SUEZMAX_LONDON
        ),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'SUEZMAX HOUSTON',
        value: DATASET_SUEZMAX_HOUSTON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(
            DATASET_SUEZMAX_HOUSTON
        ),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'VLCC LONDON',
        value: DATASET_VLCC_LONDON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(DATASET_VLCC_LONDON),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'VLCC HOUSTON',
        value: DATASET_VLCC_HOUSTON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(DATASET_VLCC_HOUSTON),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'AFRAMAX LONDON ',
        value: DATASET_AFRAMAX_LONDON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(
            DATASET_AFRAMAX_LONDON
        ),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'AFRAMAX HOUSTON ',
        value: DATASET_AFRAMAX_HOUSTON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(
            DATASET_AFRAMAX_HOUSTON
        ),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'CLEAN HOUSTON',
        value: DATASET_CLEAN_HOUSTON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(
            DATASET_CLEAN_HOUSTON
        ),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'CLEAN LONDON',
        value: DATASET_CLEAN_LONDON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(DATASET_CLEAN_LONDON),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: '25-85 DTY HOU',
        value: DATASET_DIRTY_HOUSTON_25_85,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(
            DATASET_DIRTY_HOUSTON_25_85
        ),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'FUEL OIL LONDON',
        value: DATASET_FUEL_OIL_LONDON,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(
            DATASET_FUEL_OIL_LONDON
        ),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: '25-130 DIRTY DUBAI',
        value: DATASET_DIRTY_DUBAI_25_130,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(
            DATASET_DIRTY_DUBAI_25_130
        ),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'SHELL',
        value: DATASET_SHELL,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: ['PC'],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(DATASET_SHELL),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'SPECIALISED',
        value: DATASET_SPECIALISED,
        allowFreeTextCargo: false,
        allowedPlaceholderCargoes: [],
        allowFreeTextZone: false,
        allowFreeTextLocation: true,
        allowSearchCountries: true,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rate',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
        ],
        nonAvailableOrderFields: ['vesselCategory'],
        resolveRateUnit: (amountFrom) =>
            amountFrom >= 1000 ? rateUnits[0] : rateUnits[1],
        defaultOrderColumnsToShow: [
            'isSelected',
            'privacy',
            'groupName',
            'reportedDate',
            'charterer',
            'cargoQuantity',
            'cargoParts',
            'deliveryTerm',
            'deliveryLocationParts',
            'loadLocationParts',
            'dischargeLocationParts',
            'redeliveryLocationParts',
            'laycan',
            'durationParts',
            'brokers',
            'parcels',
            'comments',
            'privateComments',
        ],
        defaultFixtureColumnsToShow: [
            'isSelected',
            'privacy',
            'groupName',
            'updatedDateTime',
            'reportedDate',
            'status',
            'type',
            'charterer',
            'vessel',
            'dwt',
            'owner',
            'cargoQuantity',
            'cargoParts',
            'deliveryTerm',
            'deliveryLocationParts',
            'loadLocationParts',
            'dischargeLocationParts',
            'redeliveryLocationParts',
            'laycan',
            'rates',
            'freightPortBasis',
            'brokers',
            'clarksonsBroker',
            'parcels',
            'comments',
            'privateComments',
            'channel',
        ],
        defaultQuantityFormat: LONG_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: false,
        maxLocationsSearchResult: 30,
        vesselOptions: { minDWT: 0, maxDWT: 60 },
        numberForQuantityFormating: 100,
        headingsMapping: getDatasetSpecificHeadingsTitles(DATASET_SPECIALISED),
        allowSearchVesselsByEniNumber: true,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'DRY CARGO',
        value: DATASET_DRY_CARGO,
        allowFreeTextCargo: false,
        allowFreeTextZone: false,
        allowFreeTextLocation: false,
        allowSearchCountries: true,
        searchVesselsWithImoOnly: true,
        allowedPlaceholderCargoes: ['2LL', 'LLEGS', 'PERIOD'],
        nonAvailableFields: [
            'rate',
            'charterer',
            'brokers',
            'owner',
            'eniNumber',
        ],
        resolveRateUnit: (amountFrom) =>
            amountFrom >= 1000 ? rateUnits[3] : rateUnits[1],
        defaultOrderColumnsToShow: [
            'isSelected',
            'privacy',
            'groupName',
            'reportedDate',
            'type',
            'thirdPartyCharterer',
            'laycan',
            'durationParts',
            'deliveryTerm',
            'deliveryLocationParts',
            'loadLocationParts',
            'dischargeLocationParts',
            'redeliveryLocationParts',
            'cargoParts',
            'cargoQuantity',
            'comments',
            'commission',
            'loadZone',
            'areas',
            'thirdPartyBrokers',
            'state',
            'orderStatus',
            'orderVessel',
            'vesselCategory',
            'orderThirdPartyOwner',
            'orderRates',
        ],
        defaultFixtureColumnsToShow: [
            'isSelected',
            'privacy',
            'groupName',
            'reportedDate',
            'vessel',
            'dwt',
            'yob',
            'deliveryTerm',
            'deliveryLocationParts',
            'loadLocationParts',
            'laycan',
            'dischargeLocationParts',
            'redeliveryLocationParts',
            'cargoParts',
            'cargoQuantity',
            'rates',
            'status',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'comments',
            'channel',
        ],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: TCT,
        isStemSizeAvailable: true,
        autoPopulateDirectionEnabled: true,
        shouldHighlightNewOrders: true,
        shouldShowConvertedOrders: true,
        shouldResetCargoIfPeriodType: false,
        maxLocationsSearchResult: 30,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(DATASET_DRY_CARGO),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: [],
    },
    {
        label: 'TANKERS',
        value: DATASET_TANKERS,
        allowFreeTextCargo: false,
        allowFreeTextZone: false,
        allowFreeTextLocation: false,
        allowSearchCountries: true,
        searchVesselsWithImoOnly: false,
        allowedPlaceholderCargoes: ['PC'],
        nonAvailableFields: [
            'rate',
            'charterer',
            'brokers',
            'owner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        resolveRateUnit: (amountFrom) =>
            amountFrom >= 1000 ? rateUnits[3] : rateUnits[1],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        defaultType: VOY,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: true,
        shouldShowConvertedOrders: true,
        shouldResetCargoIfPeriodType: true,
        maxLocationsSearchResult: 30,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(DATASET_TANKERS),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
    {
        label: 'Test',
        value: DATASET_TEST,
        allowFreeTextCargo: true,
        allowedPlaceholderCargoes: [],
        allowFreeTextZone: true,
        allowFreeTextLocation: true,
        allowSearchCountries: false,
        searchVesselsWithImoOnly: false,
        nonAvailableFields: [
            'rates',
            'thirdPartyCharterer',
            'thirdPartyBrokers',
            'thirdPartyOwner',
            'direction',
            'areasGeared',
            'eniNumber',
            'orderStatus',
            'orderVessel',
            'orderThirdPartyOwner',
            'orderRates',
            'salePrice',
            'saleDate',
            'hireExpiry',
        ],
        defaultOrderColumnsToShow: [],
        defaultFixtureColumnsToShow: [],
        defaultQuantityFormat: SHORT_HAND_QUANTITY_FORMAT,
        isStemSizeAvailable: false,
        autoPopulateDirectionEnabled: false,
        shouldHighlightNewOrders: false,
        shouldShowConvertedOrders: false,
        shouldResetCargoIfPeriodType: false,
        maxLocationsSearchResult: 15,
        vesselOptions: {
            minDWT: 0,
            maxDWT: 750,
        },
        numberForQuantityFormating: 1000,
        headingsMapping: getDatasetSpecificHeadingsTitles(DATASET_TEST),
        allowSearchVesselsByEniNumber: false,
        nonAvailableDurationUnits: ['LL'],
    },
];

export const getDataset = (id) =>
    dataSets.find((dataset) => dataset.value === id);
