import { SavedLayoutModel } from 'store/feature/layoutsSlice/models';
import cn from 'classnames';
import './LayoutManagerPanel.scss';
import StarFilledIcon from '_legacy/components/icons/StarFilledIcon';
import SlideoutButton from '_legacy/components/SlideoutButton';
import { Trash } from '_legacy/components/icons/Trash';
import StarOutlineIcon from '_legacy/components/icons/StarOutlineIcon';
import React, { useContext, useState } from 'react';
import LayoutManagerItemLabel from './LayoutManagerItemLabel';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userSelectors } from 'store/feature/userSlice';
import {
    deleteTemplateLayout,
    deleteUserLayout,
    layoutsSelectors,
    setUserLayoutAsPreferred,
} from 'store/feature/layoutsSlice';
import LayoutIcons from 'components/common/LayoutIcons';
import styles from './LayoutManagerPanel.module.scss';
import GridTypeContext from 'hooks/common/GridTypeContext';
import DeleteTemplateLayoutConfirmationDialog from '../dialogs/DeleteTemplateLayoutConfirmationDialog';

const LayoutManagerDeleteHandle: React.FC<{ model: SavedLayoutModel }> = ({
    model,
}) => {
    const dispatch = useAppDispatch();
    const gridType = useContext(GridTypeContext);
    const loading = useAppSelector(layoutsSelectors.loading);
    const disabled = loading || model.isPreferred;

    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleDeleteTemplateLayout = () => {
        dispatch(deleteTemplateLayout({ id: model.id, gridType }));
        setShowConfirmation(false);
    };

    const handleDeleteLayout = () => {
        if (disabled) {
            return;
        }

        if (model.isTemplate) {
            setShowConfirmation(true);
        } else {
            dispatch(deleteUserLayout({ id: model.id, gridType }));
        }
    };

    return (
        <>
            <SlideoutButton
                data-cy="layout-delete-prefferd"
                className={cn('layoutManager-delete-layout', {
                    [styles['disabled-icon']]: disabled,
                })}
                onClick={handleDeleteLayout}
                icon={Trash}
            />
            <DeleteTemplateLayoutConfirmationDialog
                open={showConfirmation}
                onCancel={() => setShowConfirmation(false)}
                onDelete={handleDeleteTemplateLayout}
            />
        </>
    );
};

const LayoutManagerPreferredHandle: React.FC<{ model: SavedLayoutModel }> = ({
    model,
}) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(layoutsSelectors.loading);
    const gridType = useContext(GridTypeContext);
    const handleSetPreferred = () => {
        if (model.isTemplate || loading) {
            return;
        }
        dispatch(setUserLayoutAsPreferred({ id: model.id, gridType }));
    };

    return model.isPreferred ? (
        <StarFilledIcon
            className={cn({ [styles['disabled-icon']]: loading })}
        />
    ) : (
        <StarOutlineIcon
            className={cn({ [styles['disabled-icon']]: loading })}
            data-cy="layout-set-preferred"
            onClick={handleSetPreferred}
        />
    );
};

export interface LayoutManagerItemProps {
    model: SavedLayoutModel;
}

const LayoutManagerItem: React.FC<LayoutManagerItemProps> = ({ model }) => {
    const isAdmin = useAppSelector(userSelectors.isAdmin);

    const [showEditHandle, setShowEditHandle] = useState(false);

    const canEdit = !model.isTemplate || isAdmin;
    const icon = model.isTemplate ? (
        <LayoutIcons.Template />
    ) : (
        <LayoutIcons.User />
    );

    return (
        <tr
            key={model.id}
            className={cn(styles['layoutManager-item'], {
                [styles['layoutManager-item--preferred']]: model.isPreferred,
                'layoutManager-preferred': model.isPreferred,
            })}
        >
            <td>
                <div className="layoutManager-table-cell">{icon}</div>
            </td>
            <td
                onMouseOver={() => setShowEditHandle(canEdit && true)}
                onMouseLeave={() => setShowEditHandle(canEdit && false)}
            >
                <LayoutManagerItemLabel
                    model={model}
                    showControls={showEditHandle}
                />
            </td>
            <td>
                <div className="layoutManager-table-cell">
                    <span></span>
                </div>
            </td>
            <td>
                <div className="layoutManager-table-cell layoutManager-actions">
                    {!model.isTemplate ? (
                        <LayoutManagerPreferredHandle model={model} />
                    ) : (
                        <div></div>
                    )}
                    {canEdit ? (
                        <LayoutManagerDeleteHandle model={model} />
                    ) : (
                        <div></div>
                    )}
                </div>
            </td>
        </tr>
    );
};

export default LayoutManagerItem;
