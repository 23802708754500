import EntityNodeTree from './EntityNodeTree';
import {
    ENTITY_PART_TYPE_PLACEHOLDER,
    ENTITY_PART_TYPE_GAIN_ACCOUNT,
    ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP,
} from '../models/common/EntityPart';

class CompanyNodeTree extends EntityNodeTree {
    initializeNodes(placeholderCompanies, knownCompanies) {
        super.initializeNodes();
        this.initializePlaceholderCompanyNodes(placeholderCompanies);
        this.initializeKnownCompanyNodes(knownCompanies);
    }

    initializePlaceholderCompanyNodes(placeholderCompanies) {
        if (placeholderCompanies) {
            this.pushParentNode(PLACEHOLDER_COMPANY_NODE);
            const parent = this.getNode(PLACEHOLDER_COMPANY_NODE);

            const uniquePlaceholderCompanies = [
                ...new Set(placeholderCompanies),
            ].sort();

            uniquePlaceholderCompanies.forEach((company) =>
                this.pushLeafNode(
                    ENTITY_PART_TYPE_PLACEHOLDER + company,
                    company,
                    parent
                )
            );
        }
    }

    initializeKnownCompanyNodes(knownCompanies) {
        if (knownCompanies) {
            this.sortCompanies(knownCompanies);

            for (const company of knownCompanies) {
                if (company.type === ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP) {
                    this.pushParentNode(
                        company.name,
                        ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP + company.id
                    );
                } else if (company.type === ENTITY_PART_TYPE_GAIN_ACCOUNT) {
                    let parent =
                        this.getNode(
                            ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP +
                                company.parentId
                        ) ??
                        this.pushParentNode(
                            company.parentName,
                            ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP +
                                company.parentId
                        );

                    this.pushLeafNode(
                        ENTITY_PART_TYPE_GAIN_ACCOUNT + company.id,
                        company.name,
                        parent,
                        company.countryCode
                    );
                }
            }
        }
    }

    sortCompanies(companies) {
        companies.sort(function (a, b) {
            const getCompanyFullName = (company) => {
                if (company.type === ENTITY_PART_TYPE_GAIN_ACCOUNT_GROUP) {
                    return company.name;
                } else if (company.type === ENTITY_PART_TYPE_GAIN_ACCOUNT) {
                    return company.parentName + company.name;
                }
            };

            return getCompanyFullName(a) > getCompanyFullName(b) ? 1 : -1;
        });
    }
}

const PLACEHOLDER_COMPANY_NODE = 'PLACEHOLDER';

CompanyNodeTree.States = EntityNodeTree.States;

CompanyNodeTree.RootNodeKey = EntityNodeTree.RootNodeKey;
CompanyNodeTree.PlaceholderCompanyNode = PLACEHOLDER_COMPANY_NODE;
CompanyNodeTree.PlaceholderCompanyPrefix =
    EntityNodeTree.PlaceholderEntityPrefix;

export default CompanyNodeTree;
