import { gql } from '@apollo/client';
import client from './graphQlClient';

class userSettingsApi {
    togglePreferredTheme() {
        return client()
            .mutate({
                mutation: gql`
                    mutation togglePreferredTheme {
                        togglePreferredTheme {
                            newThemeEnabled
                            userId
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                return result.data.newThemeEnabled;
            });
    }

    toggleCondensedView() {
        return client()
            .mutate({
                mutation: gql`
                    mutation toggleCondensedView {
                        toggleCondensedView {
                            useCondensedView
                            userId
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                return result.data.useCondensedView;
            });
    }

    toggleCompactDensityView() {
        return client()
            .mutate({
                mutation: gql`
                    mutation toggleCompactDensityView {
                        toggleCompactDensityView {
                            useCompactDensityView
                            userId
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                return result.data.useCompactDensityView;
            });
    }
    getUserSettings() {
        return client()
            .query({
                query: gql`
                    {
                        getUserSettings {
                            newThemeEnabled
                            useCondensedView
                            useCompactDensityView
                            createdDateTime
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                return result.data.getUserSettings;
            });
    }
}

export default new userSettingsApi();
