import Logger from '../../../diagnostics/logging/logger';
import { copyEntityToClipboard } from '_legacy/services/CopyEntityToClipboardService';
import { ORDER_GRID_TYPE } from './../../../constants/gridTypes';

export const createCopyEntityToClipboardMenuItem = (node, context, gridId) => {
    const { group, data } = node;

    const title = gridId === ORDER_GRID_TYPE ? 'Copy Order' : 'Copy Fixture';
    const shortcut = 'Ctrl+Alt+C';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.copyToClipboard', gridId);

                copyEntityToClipboard(node, context, gridId);
            }
        },
        shortcut: shortcut,
        icon: '<span class="ag-icon ag-icon-copy" ag-column-drop-icon ag-column-drop-vertical-icon></span>',
    };
};
