import React from 'react';
import { connect } from 'react-redux';
import { LightTooltip } from '../../ui/LightTooltip';

const GroupOnlyLabelRenderer = (props) => {
    const getPeriod = (period) =>
        period > 1 ? `${period}-hours` : `${period}-hour`;

    let tooltip = `${
        props.documentName
    } will become visible to all groups ${getPeriod(
        props.currentGroup?.groupOnlyExpiryPeriod
    )} from created date`;

    const dropDownElement = (
        <div>{`${props.currentGroup?.name} - Group Only (Visibility)`}</div>
    );

    return props.currentGroup?.groupOnlyExpiryPeriod > 0 ? (
        <LightTooltip title={tooltip} placement="right" arrow>
            {dropDownElement}
        </LightTooltip>
    ) : (
        dropDownElement
    );
};

const mapStateToProps = (state) => {
    return {
        currentGroup: state.user && state.user.group ? state.user.group : null,
    };
};

export default connect(mapStateToProps)(GroupOnlyLabelRenderer);
