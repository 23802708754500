import React from 'react';
import moment from 'moment';
import { firstBy } from 'thenby';
import defaultSortComparator from '../components/grid/comparators/DefaultSortComparator';
import DailyReportHeaderLogo from '../components/images/DailyReportHeaderLogo';
import NewDailyReportHeaderLogo from '../components/images/NewDailyReportHeaderLogo';
import ReactDOMServer from 'react-dom/server';
import { oldLogoExpirationDate } from '../config';
import { unassignedAreaText } from '../constants/gridText';

const css = `html, body { text-size-adjust: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; } table, tr, td { font-family: Consolas; font-size: 9.0pt;  font-weight: 400; border: 0; border-collapse: collapse; }`;

export const getReport = (data, currentDate, formatters) => {
    const reportContent = generateReport(data, currentDate, formatters);
    const html = ReactDOMServer.renderToStaticMarkup(reportContent);

    return {
        content: html,
        subject: `CLARKSONS PLATOU - ${data.title.toUpperCase()} ${renderTitleDate(
            currentDate
        )}`,
        images: [
            {
                id: 'Report.Header',
                type: 'image/png',
                content: isOldLogoShouldBeReplacedWithNew(currentDate)
                    ? NewDailyReportHeaderLogo
                    : DailyReportHeaderLogo,
            },
        ],
    };
};

const generateReport = (data, currentDate, formatters) => (
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml">
        <head>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
            />
            <title>{data.title}</title>
            <style type="text/css">{css}</style>
        </head>
        <body
            style={{
                width: '100%',
                margin: 0,
                padding: 0,
            }}
        >
            <table
                width="100%"
                height="100%"
                cellPadding="0"
                cellSpacing="0"
                valign="top"
            >
                <tbody>
                    <tr>
                        <td valign="top">
                            <table
                                width="800"
                                cellPadding="0"
                                cellSpacing="0"
                                valign="top"
                            >
                                <tbody>
                                    <tr>
                                        <td align="center">
                                            <img
                                                alt="Report Header"
                                                src="cid:Report.Header"
                                                width="800"
                                                style={{
                                                    display: 'block',
                                                    outline: 'none',
                                                    border: 'none',
                                                    textDecoration: 'none',
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            align="center"
                                            style={{
                                                paddingTop: '25px',
                                                paddingBottom: '15px',
                                            }}
                                        >
                                            <span
                                                style={{
                                                    fontWeight: 800,
                                                }}
                                            >
                                                {data.title.toUpperCase()}
                                            </span>
                                            <br />
                                            <span
                                                style={{
                                                    fontWeight: 800,
                                                }}
                                            >
                                                {renderTitleDate(currentDate)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <table
                                                cellSpacing="0"
                                                cellPadding="0"
                                                valign="top"
                                                width="100%"
                                            >
                                                <tbody>
                                                    {renderFixtures(
                                                        data.fixtures,
                                                        formatters
                                                    )}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr style={{ paddingBottom: '25px' }}>
                                        <td align="center">
                                            <table
                                                cellSpacing="0"
                                                cellPadding="0"
                                                valign="top"
                                                width="100%"
                                            >
                                                <tbody>
                                                    {renderPositions(
                                                        data.fixtures,
                                                        formatters
                                                    )}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center">
                                            <table
                                                cellSpacing="0"
                                                cellPadding="0"
                                                valign="top"
                                                width="100%"
                                            >
                                                <tbody>
                                                    {renderOrders(
                                                        data.orders,
                                                        formatters
                                                    )}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td
                            valign="top"
                            style={{ paddingTop: '20px', height: '100px' }}
                        >
                            Additional text here
                        </td>
                    </tr>
                </tbody>
            </table>
        </body>
    </html>
);

const isOldLogoShouldBeReplacedWithNew = (currentDate) => {
    return moment(currentDate).isAfter(oldLogoExpirationDate);
};

const renderTitleDate = (currentDate) =>
    moment(currentDate).format('DD MMM YY').toUpperCase();

const sortOrders = (orders) =>
    orders.sort(
        firstBy((a, b) => sortByArea(a, b))
            .thenBy(sortByLaycanStartDate)
            .thenBy(sortByLaycanEndDate)
    );

const renderOrders = (items, formatters) => {
    if (items.length === 0) {
        return null;
    }

    const sortedOrders = sortOrders(items);

    const orders = [];

    const sectionHeader = renderSectionHeader('ORDERS');
    orders.push(sectionHeader);

    let previousKey = null;

    previousKey = items[0].areas;

    sortedOrders.forEach((order, index) => {
        if (order.areas !== previousKey) {
            const emptyOrderRow = renderOrder(null, index, formatters);
            orders.push(emptyOrderRow);
        }

        const element = renderOrder(order, index, formatters);
        orders.push(element);

        previousKey = order.areas;
    });

    return orders.map((value) => value);
};

const renderOrder = (order, index, formatters) => {
    return (
        <tr key={index}>
            <td height="15" width="145">
                <span>
                    {getFormattedValueFromNode(order, 'charterer', formatters)}
                </span>
            </td>
            <td height="15" width="30" style={{ paddingLeft: '10px' }}>
                <span>
                    {getFormattedValueFromNode(
                        order,
                        'cargoQuantity',
                        formatters,
                        'cargoQuantityParts'
                    )}
                </span>
            </td>
            <td height="15" width="150" style={{ paddingLeft: '10px' }}>
                <span>
                    {getFormattedValueFromNode(order, 'cargoParts', formatters)}
                </span>
            </td>
            <td height="15" width="135" style={{ paddingLeft: '10px' }}>
                <span>{getLoadDischarge(order, formatters)}</span>
            </td>
            <td
                height="15"
                width="110"
                style={{ paddingRight: '10px', textAlign: 'right' }}
            >
                <span>{getFormattedValueOfLaycan(order)}</span>
            </td>
            <td height="15" width="310" style={{ paddingLeft: '10px' }}>
                <span>{getHyphen(order)}</span>
            </td>
        </tr>
    );
};

const filterAndSortFixtures = (fixtures) =>
    fixtures
        .filter((node) => !isPosition(node))
        .sort(
            firstBy((a, b) => sortByArea(a, b))
                .thenBy(sortByLaycanStartDate)
                .thenBy(sortByLaycanEndDate)
        );

const getHyphen = (node) => {
    return node !== null ? '-' : '';
};

const renderFixtures = (items, formatters) => {
    if (items.length === 0) {
        return null;
    }

    const fixtures = [];
    const sortedFixtures = filterAndSortFixtures(items);

    const sectionHeader = renderSectionHeader('FIXTURES');
    fixtures.push(sectionHeader);

    let previousKey = sortedFixtures[0].areas;

    sortedFixtures.forEach((fixture, index) => {
        if (fixture.areas !== previousKey) {
            const emptyFixtureRow = renderFixture(null, index, formatters);
            fixtures.push(emptyFixtureRow);
        }

        const element = renderFixture(fixture, index, formatters);
        fixtures.push(element);

        previousKey = fixture.areas;
    });

    return fixtures.map((value) => value);
};

const renderFixture = (fixture, index, formatters) => {
    return (
        <tr key={index}>
            <td height="15" width="145">
                <span>
                    {getFormattedValueFromNode(fixture, 'vessel', formatters)}
                </span>
            </td>
            <td height="15" width="30" style={{ paddingLeft: '10px' }}>
                <span>
                    {getFormattedValueFromNode(
                        fixture,
                        'cargoQuantity',
                        formatters,
                        'cargoQuantityParts'
                    )}
                </span>
            </td>
            <td height="15" width="150" style={{ paddingLeft: '10px' }}>
                <span>
                    {getFormattedValueFromNode(
                        fixture,
                        'cargoParts',
                        formatters
                    )}
                </span>
            </td>
            <td height="15" width="135" style={{ paddingLeft: '10px' }}>
                <span>{getLoadDischarge(fixture, formatters)}</span>
            </td>
            <td
                height="15"
                width="120"
                style={{ paddingRight: '10px', textAlign: 'right' }}
            >
                <span>
                    {getFormattedValueOfLaycan(fixture, 'laycan', formatters)}
                </span>
            </td>
            <td height="15" width="110" style={{ paddingLeft: '10px' }}>
                <span>
                    {getFormattedValueFromNode(fixture, 'rate', formatters)}
                </span>
            </td>
            <td height="15" width="150" style={{ paddingLeft: '10px' }}>
                <span>
                    {getFormattedValueFromNode(fixture, 'rates', formatters)}
                </span>
            </td>
            <td height="15" width="108" style={{ paddingLeft: '10px' }}>
                <span>
                    {getFormattedValueFromNode(
                        fixture,
                        'charterer',
                        formatters
                    )}
                </span>
            </td>
            <td height="15" width="90" style={{ paddingLeft: '10px' }}>
                <span>{getFormattedStatusValue(fixture)}</span>
            </td>
        </tr>
    );
};

const filterAndSortPositions = (items) =>
    items
        .filter((node) => isPosition(node))
        .sort((a, b) =>
            /* sort by date ascending */
            defaultSortComparator(
                a.laycan ? a.laycan.startDate : null,
                b.laycan ? b.laycan.startDate : null
            )
        );

const renderPositions = (items, formatters) => {
    if (items.length === 0) {
        return null;
    }

    const positions = [];
    const sortedPositions = filterAndSortPositions(items);

    sortedPositions.forEach((item, index) => {
        const position = renderPosition(item, index, formatters);
        positions.push(position);
    });

    return positions.map((value) => value);
};

const renderPosition = (item, index, formatters) => {
    return (
        <tr key={index}>
            <td height="15" width="145">
                <span>
                    {getFormattedValueFromNode(item, 'vessel', formatters)}
                </span>
            </td>
            <td height="15" width="210" style={{ paddingLeft: '10px' }}>
                <span>{getFormattedLoadLocationLaycan(item, formatters)}</span>
            </td>
            <td height="15" width="440" style={{ paddingLeft: '10px' }}>
                <span>{getFormattedStatusValue(item)}</span>
            </td>
        </tr>
    );
};

const renderSectionHeader = (title) => {
    return (
        <tr style={{ paddingBottom: '15px' }} key={title}>
            <td>
                <span
                    style={{
                        textDecoration: 'underline',
                        fontWeight: '700',
                    }}
                >
                    {title}
                </span>
            </td>
        </tr>
    );
};

const getFormattedValueOfLaycan = (item) => {
    if (item === null || item.laycan === null) {
        return null;
    }

    var startDate = moment.utc(item.laycan.startDate, 'YYYY-MM-DD', true);
    var endDate = moment.utc(item.laycan.endDate, 'YYYY-MM-DD', true);

    if (!startDate.isValid()) {
        return item.laycan.shorthand;
    }

    var uncertain = item.laycan.shorthand.indexOf('?') !== -1;
    var formattedValue = null;

    if (endDate.isValid()) {
        if (startDate.month() === endDate.month()) {
            if (startDate.date() === endDate.date()) {
                formattedValue = startDate.format('DD MMM').toUpperCase();
            } else {
                formattedValue =
                    startDate.format('DD').toUpperCase() +
                    '-' +
                    endDate.format('DD').toUpperCase() +
                    ' ' +
                    startDate.format('MMM').toUpperCase();
            }
        } else {
            formattedValue =
                startDate.format('DD MMM').toUpperCase() +
                '-' +
                endDate.format('DD MMM').toUpperCase();
        }
    } else {
        formattedValue = startDate.format('DD MMM').toUpperCase();
    }

    if (uncertain) {
        formattedValue = formattedValue + ' ?';
    }

    return formattedValue;
};

const getFormattedStatusValue = (item) => {
    if (item) {
        const value = item.status;
        if (value && value.length > 0) {
            if (item.rumoured) {
                return `-RPTD ${value}`;
            } else {
                return `-${value}`;
            }
        }
    }

    return null;
};

const getFormattedValueFromNode = (item, colKey, formatters, field) => {
    if (item) {
        var formatter = formatters[colKey];

        const columnFieldName = field || colKey;

        if (formatter) {
            return formatter({
                value: item[columnFieldName],
                datasetId: item.datasetId,
            });
        } else {
            return item[columnFieldName];
        }
    } else {
        return '';
    }
};

const getFormattedLoadLocationLaycan = (item, formatters) => {
    let loadLocationParts = getFormattedValueFromNode(
        item,
        'loadLocationParts',
        formatters
    );
    let laycan = getFormattedValueOfLaycan(item);

    if (loadLocationParts || laycan) {
        return '(' + loadLocationParts + ' ' + laycan + ')';
    }

    return '';
};

const getLoadDischarge = (item, formatters) => {
    const loadLocationParts = getFormattedValueFromNode(
        item,
        'loadLocationParts',
        formatters
    );
    const dischLocationParts = getFormattedValueFromNode(
        item,
        'dischargeLocationParts',
        formatters
    );

    if (loadLocationParts && dischLocationParts) {
        return loadLocationParts + '/' + dischLocationParts;
    }

    if (loadLocationParts && !dischLocationParts) {
        return loadLocationParts;
    }

    if (dischLocationParts && !loadLocationParts) {
        return dischLocationParts;
    }
};

const sortByArea = (a, b) => {
    const left = orderAreaSorting[a.areas];
    const right = orderAreaSorting[b.areas];
    return defaultSortComparator(left, right);
};

const sortByLaycanStartDate = (a, b) => {
    if (a.laycan && b.laycan) {
        return defaultSortComparator(a.laycan.startDate, b.laycan.startDate);
    }
    return 0;
};

const sortByLaycanEndDate = (a, b) => {
    if (a.laycan && b.laycan) {
        return defaultSortComparator(
            a.laycan.endDate ? a.laycan.endDate : null,
            b.laycan.endDate ? b.laycan.endDate : null
        );
    }
    return 0;
};

const isPosition = (fixture) => {
    if (fixture.loadZone) {
        const loadZone = fixture.loadZone.trim().toUpperCase();
        if (loadZone === 'ON SUBS' || loadZone === 'TC') {
            return true;
        }
    }
    return false;
};

const orderAreaSorting = {
    WAFR: 1,
    MED: 2,
    NORTH: 3,
    STATES: 4,
    EAST: 5,
    [unassignedAreaText]: 6,
};
