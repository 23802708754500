import { defaultDirection } from './DirectionServiceFactory';

class PanamaxDirectionLogicService {
    atlanticZones = [
        'USEC',
        'LAKES',
        'USG',
        'CARIBS',
        'NCSA',
        'NBRAZ',
        'SBRAZ',
        'PLATE',
        'WAFR',
        'CANARY',
        'UKCONT',
        'BALT',
        'WMED',
        'EMED',
        'BSEA',
    ];

    pacificZones = [
        'BENGAL',
        'CCHINA',
        'EAFR',
        'EAUS',
        'MEG',
        'NASIA',
        'NCHINA',
        'RSEA',
        'SAFR',
        'SCHINA',
        'SEASIA',
        'WAUS',
        'WCCA',
        'WCIND',
        'WCNA',
        'WCSAM',
        'WW',
    ];

    calculateDirection = (loadZone, dischargeZone) => {
        if (!this.validateZones(loadZone, dischargeZone)) {
            return defaultDirection;
        }

        if (this.isConsistWorldwideZone(loadZone, dischargeZone)) {
            return 'PACIFIC';
        }

        const { isPacificLoadZone, isPacificDischargeZone } =
            this.determineZones(loadZone, dischargeZone);

        return isPacificLoadZone
            ? 'PACIFIC'
            : isPacificDischargeZone
            ? 'FRONTHAUL'
            : 'TRANSATLANTIC';
    };

    validateZones = (loadZone, dischargeZone) => {
        return (
            this.isAllowedZone(loadZone) && this.isAllowedZone(dischargeZone)
        );
    };

    isAllowedZone = (zone) => {
        return (
            zone &&
            (this.pacificZones.includes(zone) ||
                this.atlanticZones.includes(zone))
        );
    };

    determineZones = (loadZone, dischargeZone) => {
        return {
            isPacificLoadZone: this.pacificZones.includes(loadZone),
            isPacificDischargeZone: this.pacificZones.includes(dischargeZone),
        };
    };

    isConsistWorldwideZone = (loadZone, dischargeZone) => {
        return loadZone === 'WW' || dischargeZone === 'WW';
    };

    getAllDirections = () => {
        return ['FRONTHAUL', 'PACIFIC', 'TRANSATLANTIC'];
    };
}

export default PanamaxDirectionLogicService;
