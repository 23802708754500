import { blankKeyText } from '../../../../constants/gridText';
import { MultipleRateFormatter } from '../formatters/MultipleRateFormatter';

const RatesKeyCreator = ({ value }) => {
    if (!value || value.rateParts.length === 0) {
        return blankKeyText;
    }
    return MultipleRateFormatter({ value });
};

export default RatesKeyCreator;
