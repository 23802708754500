import { modalService } from 'common';
import { useImperativeHandle } from 'react';
import { forwardRef, useRef } from 'react';
import SelectEditor from '../../../shared/columns/editors/SelectEditor';
import FailedConfirmation from '../confirmationModal/FailedConfirmation';
import { FAILED, getAvailableStatuses, ON_SUBS } from '../models/Statuses';
import { cloneDeep } from 'lodash';
import failOrder from './../api/OrderStatusApi';

const OrderStatusEditor = forwardRef((props, ref) => {
    const selectRef = useRef();

    const focus = () => {
        selectRef.current.focus();
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                const { rates, vessel } = props.data;
                const status = selectRef.current.state.value;

                if (status) {
                    if (status === FAILED) {
                        if (
                            (rates &&
                                rates.rateParts &&
                                rates.rateParts.length !== 0) ||
                            vessel
                        ) {
                            handleOnFailing();
                            return ON_SUBS; // The status will be changed during the query if the user confirms.
                        }
                        return FAILED;
                    }
                    return status;
                } else {
                    return null;
                }
            },

            isPopup() {
                return true;
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    const handleOnFailing = () => {
        const content = (
            <FailedConfirmation
                onConfirm={() => {
                    const clonedData = cloneDeep(props.data);
                    failOrder(props, clonedData);
                    modalService.close();
                    props.stopEditing();
                }}
                onClose={() => {
                    modalService.close();
                    props.stopEditing();
                }}
            />
        );

        setTimeout(() => modalService.open({ content })); // Timeout is needed to avoid unnecessary call handleKeydown.
    };

    return (
        <SelectEditor
            {...props}
            ref={selectRef}
            values={getAvailableStatuses(props.value)}
        />
    );
});

export default OrderStatusEditor;
