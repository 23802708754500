import React, { Component } from 'react';
import ThirdPartyCompanyInput from '../inputs/ThirdPartyCompanyInputs';
import { KEY_TAB } from '_legacy/constants/keyboardCodes';

class ThirdPartyCompanyEditor extends Component {
    constructor(props) {
        super(props);

        this.companyInputRef = React.createRef();

        if (this.props.value) {
            this.state = {
                selectedCompany: { ...this.props.value },
            };
        } else {
            this.state = {};
        }
    }

    afterGuiAttached() {
        this.focus();
    }

    focus = () => {
        if (this.companyInputRef.current) {
            this.companyInputRef.current.focus();
        }
    };

    handleOnTab = () => {
        this.props.api.tabToNextCell();
    };

    handleOnTabBack = () => {
        this.props.api.tabToPreviousCell();
    };

    handleOnEnter = () => {
        this.props.stopEditing();
    };

    getValue = () => {
        return this.state.selectedCompany;
    };

    isPopup() {
        return true;
    }

    onCompanySelected = (company, keyCode, shift) => {
        this.setState(
            {
                selectedCompany: company,
            },
            () => {
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;
                    default:
                        this.handleOnEnter();
                        break;
                }
            }
        );
    };

    handleInputCleared = () => {
        this.setState({ selectedCompany: null });
    };

    render(placeholderName) {
        return (
            <ThirdPartyCompanyInput
                ref={this.companyInputRef}
                value={this.state.selectedCompany}
                onTab={this.handleOnTab}
                onTabBack={this.handleOnTabBack}
                onEnter={this.handleOnEnter}
                onCompanySelected={this.onCompanySelected}
                shouldSelectItemOnTab={true}
                inputClass="ag-react-container"
                initialChar={this.props.charPress}
                onInputCleared={this.handleInputCleared}
                placeholderName={placeholderName}
            />
        );
    }
}

export default ThirdPartyCompanyEditor;
