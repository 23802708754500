import suppressOnEdit from '_legacy/components/grid/columnDefaults/suppressOnEdit';
import defaultObjEquals from '_legacy/components/grid/columnDefaults/defaultObjEquals';
import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';

import { isEditableRow } from '../../../shared/columns/tools/headingTools';
import ClarksonsBrokerEditor from '../editors/ClarksonsBrokerEditor';
import ClarksonsBrokerRenderer from '../renderers/ClarksonsBrokerRenderer';
import MultipleCloudUserFormatter from '../../../shared/columns/formatters/MultipleCloudUserFormatter';
import MultipleCloudUserComparator from '../../../shared/columns/comparators/MultipleCloudUserComparator';

export const clarksonsBrokerHeading = {
    colId: 'clarksonsBrokers',
    headerName: 'CLK Broker',
    field: 'clarksonsBrokers',
    editable: isEditableRow,
    valueFormatter: MultipleCloudUserFormatter,
    cellEditorFramework: ClarksonsBrokerEditor,
    cellRendererFramework: ClarksonsBrokerRenderer,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: MultipleCloudUserComparator,
    equals: defaultObjEquals,
    keyCreator: MultipleCloudUserFormatter,
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    hide: true,
    width: 120,
};
