import React, { Component } from 'react';
import NotFoundPage from './NotFoundPage';
import GroupPage from './groups/GroupPage';
import { Switch, Route, withRouter } from 'react-router-dom';
import logger from '../../ ../../diagnostics/logging/logger';

export class AdminPage extends Component {
    render() {
        const { match } = this.props;

        logger.trackPageView('Admin');

        return (
            <div
                className="ui container"
                style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    height: '92%',
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                <Switch>
                    <Route
                        path={`${match.path}/groups`}
                        component={(props) => (
                            <GroupPage
                                onGroupsChanged={this.props.onGroupsChanged}
                            />
                        )}
                    />
                    <Route component={NotFoundPage} />
                </Switch>
            </div>
        );
    }
}

export default withRouter(AdminPage);
