import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { convertQuantityPartsToNumbers } from '../tools/QuantityPartsConverter';
import { getDataset } from '../../../../models/Datasets';
import { NumberForMultiplyingQty } from '_legacy/constants/CommonConstants';
import './QuantityFilter.scss';

class QuantityFilter extends Component {
    constructor(props) {
        super(props);
        this.fromInputRef = React.createRef();
        this.toInputRef = React.createRef();

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.updateGrid = this.updateGrid.bind(this);
        this.updateGrid = debounce(this.updateGrid, 1000);

        this.clearFilter = this.clearFilter.bind(this);

        this.state = {
            from: '',
            to: '',
        };
    }

    doesFilterPass(params) {
        const value = this.valueGetter(this.props.valueGetter(params.node));
        const numbers = convertQuantityPartsToNumbers(value);

        const from = this.formatNumber(this.state.from);
        const to = this.formatNumber(this.state.to);

        const minValue = Math.min(...numbers);
        const maxValue = Math.max(...numbers);

        if (from && to) {
            return from <= maxValue && to >= minValue;
        }

        if (from) {
            return from <= maxValue;
        }

        if (to) {
            return to >= minValue;
        }

        return true;
    }

    isFilterActive() {
        return (
            this.formatNumber(this.state.from) > 0 ||
            this.formatNumber(this.state.to) > 0
        );
    }

    getModel() {
        return this.isFilterActive()
            ? { to: this.state.to, from: this.state.from }
            : null;
    }

    setModel(model) {
        if (model) {
            this.setState(
                {
                    to: model.to,
                    from: model.from,
                },
                () => this.props.filterChangedCallback()
            );
        } else {
            this.clearFilter();
        }
    }

    formatNumber(value) {
        const numberForFormating = getDataset(
            this.props.context.datasetId
        ).numberForQuantityFormating;
        const cleanValue = Number(value.replace(/,/g, ''));

        return cleanValue <= numberForFormating
            ? cleanValue * NumberForMultiplyingQty
            : cleanValue;
    }

    valueGetter(rowValue) {
        return this.props.fieldName && rowValue
            ? rowValue[this.props.fieldName]
            : rowValue;
    }

    afterGuiAttached() {
        const multiFilter = this.props.api.getFilterInstance('cargoQuantity');
        const setFilter = multiFilter.getChildFilterInstance(1);

        const clearButton = setFilter.eGui.querySelector(
            '.ag-filter-apply-panel-button'
        );

        clearButton.addEventListener('click', () => {
            this.clearFilter();
            setTimeout(() => {
                this.refreshValues();
            }, 2000);
        });
    }

    refreshValues() {
        const multiFilter = this.props.api.getFilterInstance('cargoQuantity');
        const setFilter = multiFilter.getChildFilterInstance(1);
        setFilter.resetFilterValues();
    }

    handleFromChange(event) {
        this.handleInputChange(event, 'from');
    }

    handleToChange(event) {
        this.handleInputChange(event, 'to');
    }

    handleInputChange(event, fieldName) {
        var validationRegex = /^[\d,]*\.?\d*$/;
        const value = event.target.value;

        if (validationRegex.test(value)) {
            this.setState({ [fieldName]: value }, () => {
                this.updateGrid();
            });
        }
    }

    updateGrid() {
        this.props.filterChangedCallback();
    }

    clearFilter() {
        this.setState({ from: '', to: '' }, () => {
            this.props.filterChangedCallback();
        });
    }

    numberWithCommas(number) {
        return number
            .replace(/,/g, '')
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                <input
                    type="text"
                    className="dwt-filter-input"
                    value={this.numberWithCommas(this.state.from)}
                    onChange={this.handleFromChange}
                    placeholder="From"
                    min={0}
                />
                <input
                    type="text"
                    className="dwt-filter-input"
                    value={this.numberWithCommas(this.state.to)}
                    onChange={this.handleToChange}
                    placeholder="To"
                />
            </div>
        );
    }
}

export default QuantityFilter;
