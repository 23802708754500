import { gql } from '@apollo/client';

export const AllGroupsQuery = gql`
    query Groups {
        groups {
            id
            name
            dataset {
                id
                users
            }
            createdBy {
                username
            }
            createdDate
            groupOnlyExpiryPeriod
        }
    }
`;

export const UserGroupsQuery = gql`
    query UserGroups($userId: Int!) {
        userGroups(userId: $userId) {
            id
            name
            datasetName
            groupUser {
                fullName
                canWrite
                createdBy {
                    username
                }
                createdDate
            }
        }
    }
`;
