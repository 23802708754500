import { defaultDirection } from './DirectionServiceFactory';
import {
    zoneCapeDirectionMapping,
    allDirections,
    indonesiaSpecificDirectionMapping,
    indonesiaSpecificDischargeZones,
    indonesiaCountryId,
    specificZoneCapeDirectionMapping,
} from '../../models/ZonesCapeDirectionsMapping';

class CapeDirectionLogicService {
    calculateDirection = (loadZone, dischargeZone, loadLocationParts) => {
        if (!loadZone || !dischargeZone) {
            return defaultDirection;
        }

        const loadLocation = loadLocationParts && loadLocationParts[0];
        if (
            loadLocation?.countryId === indonesiaCountryId &&
            indonesiaSpecificDischargeZones.includes(dischargeZone)
        ) {
            return indonesiaSpecificDirectionMapping.toUpperCase();
        }

        if (this.isConsistWorldwideZone(loadZone, dischargeZone)) {
            return 'PACIFIC';
        }

        const specificFilteredDirection = this.getFilteredDirection(
            loadZone,
            dischargeZone,
            specificZoneCapeDirectionMapping
        );

        if (specificFilteredDirection) {
            return specificFilteredDirection;
        }

        return this.getFilteredDirection(
            loadZone,
            dischargeZone,
            zoneCapeDirectionMapping
        );
    };

    getAllDirections = () => {
        return allDirections;
    };

    getFilteredDirection = (loadZone, dischargeZone, zoneMapping) => {
        const loadZoneMap = zoneMapping.filter(
            (mapping) => mapping.zone === loadZone
        )[0];

        const dischargeZoneMap = zoneMapping.filter(
            (mapping) => mapping.zone === dischargeZone
        )[0];

        if (!loadZoneMap || !dischargeZoneMap) {
            return '';
        }

        const filteredArray = loadZoneMap.loadDirections.filter((direction) =>
            dischargeZoneMap.dischargeDirections.includes(direction)
        );

        if (filteredArray.length === 0) {
            return '';
        }

        return filteredArray[0].toUpperCase();
    };

    isConsistWorldwideZone = (loadZone, dischargeZone) => {
        return loadZone === 'WW' || dischargeZone === 'WW';
    };
}

export default CapeDirectionLogicService;
