import React from 'react';

const MultiSelectFixedOption = ({ value }) => {
    return (
        <div className="multi-select-item-pill">
            <span className="multi-select-space-pill" />
            <span className="ui image label">{value}</span>
        </div>
    );
};

export default MultiSelectFixedOption;
