import client from './graphQlClient';
import { SEARCH_CLARKSONS_USERS } from './queries/ClarksonsUserQueries';

export default class ClarksonsUserApi {
    static search = (search, datasetId, cancelToken) => {
        return new Promise((resolve, reject) => {
            client()
                .query({
                    query: SEARCH_CLARKSONS_USERS,
                    fetchPolicy: 'no-cache',
                    variables: {
                        search,
                        datasetId,
                    },
                })
                .then((result) => {
                    resolve(result.data.clarksonsUsers);
                });

            cancelToken.promise.then((reason) => {
                reject(reason);
            });
        });
    };
}
