import { create } from 'zustand';

import types from '../models/RateTypes';
import currencies from '../models/Currencies';
import { rateUnits } from '../models/RateUnits';
import { durationUnits } from '../../../shared/columns/models/DurationUnits';
import { QuantityParser } from '../../../shared/columns/tools/quantityParser';

const parser = new QuantityParser();

const getDefaultDurationUnit = () => {
    return durationUnits.find((unit) => unit.isDefaultRateUnit);
};

const boundValidation = (amount) => {
    return amount ? parser.checkIsInteger(amount) : true;
};

const getInitialState = () => {
    return {
        type: types[0],

        bound: null,
        isBoundDisabled: false,
        isBoundValid: true,

        currency: currencies[0],
        isCurrencyDisabled: false,

        amount: '',
        amountObj: null,
        isAmountValid: false,
        isAmountDisabled: false,

        unit: rateUnits[1],

        durationAmount: '',
        durationAmountObj: null,
        durationUnit: getDefaultDurationUnit(),
        isDurationDisabled: false,
        isDurationAmountValid: true,
        durationAmountValidationErrorMessage: '',

        index: null,
        isIndexValid: false,
        selectedIndex: null,

        submitted: false,
        isEditMode: false,
        partsValidated: false,
    };
};

export const useRateStore = create((set, get) => ({
    ...getInitialState(),

    setInitialState: () => set(getInitialState()),
    setCustomState: (valueObject) => set(valueObject),

    setBound: (value) => set({ bound: value }),
    setType: (value) => set({ type: value }),
    setCurrency: (value) => set({ currency: value }),
    setAmount: (value) => set({ amount: value }),
    setAmountObj: (value) => set({ amountObj: value }),
    setIsAmountValid: (value) => set({ isAmountValid: value }),
    setIsBoundValid: (value) => set({ isBoundValid: value }),

    setDurationAmount: (value) => set({ durationAmount: value }),
    setDurationAmountObj: (value) => set({ durationAmountObj: value }),
    setIsDurationAmountValid: (value) => set({ isDurationAmountValid: value }),
    setDurationUnit: (value) => set({ durationUnit: value }),

    setSubmitted: (value) => set({ submitted: value }),
    setIndex: (value) => set({ index: value }),
    setIsIndexValid: (value) => set({ isIndexValid: value }),

    resetRateValueInputs: () =>
        set({
            bound: null,
            index: null,
            currency: currencies[0],
            unit: rateUnits[1],
            amount: '',
            isAmountDisabled: false,
            isCurrencyDisabled: false,
            isBoundDisabled: false,
            isBoundValid: true,
            isAmountValid: false,
            isIndexValid: false,
            submitted: false,
            amountObj: null,
            isDurationDisabled: false,
        }),

    onChangeUnit: (newUnit) =>
        set((state) => ({
            isAmountDisabled: newUnit.shouldDisableAmount,
            isCurrencyDisabled: newUnit.shouldDisableCurrency,
            isBoundDisabled: newUnit.shouldDisableBound,
            isAmountValid: true,
            currency: newUnit.shouldDisableCurrency
                ? null
                : state.currency ?? currencies[0],
            bound: newUnit.shouldDisableBound ? null : state.bound,
            amount: newUnit.shouldDisableAmount ? '' : state.amount,
            amountObj: newUnit.shouldDisableAmount ? null : state.amountObj,
            isBoundValid: boundValidation(state.amount),
            unit: newUnit,
            isDurationDisabled: newUnit.shouldDisableDuration,
            durationAmount: newUnit.shouldDisableDuration
                ? ''
                : state.durationAmount,
            durationUnit: newUnit.shouldDisableDuration
                ? getDefaultDurationUnit()
                : state.durationUnit,
            isDurationAmountValid: newUnit.shouldDisableDuration
                ? true
                : state.isDurationAmountValid,
            durationAmountObj: newUnit.shouldDisableDuration
                ? null
                : state.durationAmountObj,
            durationAmountValidationErrorMessage: newUnit.shouldDisableDuration
                ? ''
                : state.durationAmountValidationErrorMessage,
        })),

    onSubmit: (validationResult) =>
        set({
            isAmountValid: validationResult.isAmountValid,
            isIndexValid: validationResult.isIndexValid,
            isDurationAmountValid: validationResult.isDurationAmountValid,
            arePartsValid: validationResult.arePartsValid,
            durationAmountValidationErrorMessage:
                validationResult.durationAmountValidationErrorMessage,
            submitted: true,
        }),
}));
