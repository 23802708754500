/* eslint-disable no-undef */
import constants from './constants';

/*
  This file contains abstractions for the functions added to the global scope,
  by #ClarksonsNavbarScript script element in /public/index.html.
*/
const configureHeader = (config) => {
    if (Sea !== undefined) {
        return new Sea.HeaderComponent.Header(config);
    }
};

export { configureHeader, constants };
