import React from 'react';
import ClassNames from 'classnames';

const ToolbarButton = ({
    onClick,
    isActive,
    children,
    borderRight,
    borderLeft,
    isNarrow,
    isLowercase,
    isItalic,
    isWide,
    id,
}) => {
    const buttonClassNames = ClassNames({
        'toolbar-button': true,
        'border-right': borderRight,
        'border-left': borderLeft,
        narrow: isNarrow,
        active: isActive,
        lowercase: isLowercase,
        italic: isItalic,
        wide: isWide,
        [id]: true,
    });
    return (
        <div className={buttonClassNames} onClick={onClick} data-cy={id}>
            {children}
        </div>
    );
};

export default ToolbarButton;
