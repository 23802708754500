import { FetchMappingGridDisplayValue } from '../../../data/mappings';

const StatusComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }

    const compareValueA = FetchMappingGridDisplayValue(valueA);
    const compareValueB = FetchMappingGridDisplayValue(valueB);

    if (compareValueA > compareValueB) {
        return 1;
    }
    if (compareValueA < compareValueB) {
        return -1;
    }
    return 0;
};

export default StatusComparator;
