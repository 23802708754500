import { loadZones } from '../models/Location';
import { groupSortingByAreas, getAreasGeared } from '../models/Areas';
import { groupSortingByDirection } from '../models/Direction';
import { groupSortingByType } from '../models/Type';
import { DATASET_DRY_CARGO, DATASET_SPECIALISED } from '../models/Datasets';
import { groupSortingByVesselCategories } from '_legacy/modules/columns/vessel/models/VesselCategory';

export const getDefaultOrdersRowGroupsSorting = (datasetId, directionLogic) => {
    if (!datasetId) return [];

    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return [
                { colId: 'areasGeared', sorting: getAreasGeared() },
                { colId: 'areas', sorting: groupSortingByAreas(datasetId) },
                { colId: 'loadZone', sorting: loadZones(datasetId) },
                {
                    colId: 'direction',
                    sorting: groupSortingByDirection(datasetId, directionLogic),
                },
                { colId: 'type', sorting: groupSortingByType() },
                {
                    colId: 'vesselCategory',
                    sorting: groupSortingByVesselCategories(datasetId),
                },
            ];
        case DATASET_SPECIALISED:
            return [
                { colId: 'areas', sorting: groupSortingByAreas(datasetId) },
                { colId: 'loadZone', sorting: loadZones(datasetId) },
                { colId: 'type', sorting: groupSortingByType() },
            ];
        default:
            return [
                { colId: 'areas', sorting: groupSortingByAreas(datasetId) },
                { colId: 'loadZone', sorting: loadZones(datasetId) },
                { colId: 'type', sorting: groupSortingByType() },
                {
                    colId: 'vesselCategory',
                    sorting: groupSortingByVesselCategories(datasetId),
                },
            ];
    }
};

export const getDefaultFixturesRowGroupsSorting = (
    datasetId,
    directionLogic
) => {
    if (!datasetId) return [];

    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return [
                { colId: 'areasGeared', sorting: getAreasGeared() },
                { colId: 'areas', sorting: groupSortingByAreas(datasetId) },
                { colId: 'loadZone', sorting: loadZones(datasetId) },
                {
                    colId: 'direction',
                    sorting: groupSortingByDirection(datasetId, directionLogic),
                },
                {
                    colId: 'vesselCategory',
                    sorting: groupSortingByVesselCategories(datasetId),
                },
                { colId: 'type', sorting: groupSortingByType() },
            ];
        default:
            return [
                { colId: 'areas', sorting: groupSortingByAreas(datasetId) },
                { colId: 'loadZone', sorting: loadZones(datasetId) },
                {
                    colId: 'vesselCategory',
                    sorting: groupSortingByVesselCategories(datasetId),
                },
                { colId: 'type', sorting: groupSortingByType() },
            ];
    }
};
