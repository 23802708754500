import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import FortCellEditor from '../../../shared/columns/editors/FortCellEditor';
import RateInput from '../inputs/RateInput';
import MultipleSelect from '../../../shared/columns/inputs/MultipleSelect';
import PopupWindow from '../../../shared/columns/popupWindows/PopupWindow';
import {
    normalizeParts,
    trimTrailingSeparators,
    SEPARATOR_FORWARD_SLASH,
    SEPARATOR_PLUS,
    ENTITY_PART_TYPE_SEPARATOR,
    trimSeparators,
} from '../../../../models/common/EntityPart';
import { createSeparatorRatePart } from '../models/RatePart';
import { ENTITY_PART_TYPE_RATE } from '../../../../models/common/EntityPart';
import { rateFormatterInstance } from '../formatters/RateFormatter';
import { durationUnits } from '../../../shared/columns/models/DurationUnits';
import { NET_RATE_FIXED_OPTION_NAME } from './../models/RatePart';
import { useState } from 'react';
import { useCallback } from 'react';

const RateEditor = forwardRef((props, ref) => {
    const selectRef = useRef();
    const [isNetRate, setIsNetRate] = useState(
        props.data.rates?.isNetRate ?? false
    );

    const handleChangeNetRate = useCallback((isNetRate) => {
        setIsNetRate(isNetRate);
    }, []);

    const focus = () => {
        selectRef.current.focus();
    };

    const handleOnTab = () => props.api.tabToNextCell();
    const handleOnTabBack = () => props.api.tabToPreviousCell();
    const handleOnEnter = () => props.stopEditing();

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                if (selectRef.current.formattedValue().length > 0) {
                    var parts = normalizeParts(
                        selectRef.current.formattedValue().map((part) => {
                            return {
                                rateValue: part.rateValue,
                                separatorValue: part.separatorValue,
                                partType: part.partType,
                            };
                        }),
                        createSeparatorRatePart,
                        getDefaultSeparator()
                    );

                    return createRatesObject(parts);
                } else {
                    return null;
                }
            },

            isPopup() {
                return true;
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    const partsValidation = (params) => {
        const { parts, partType, isEditMode, selectedIndex, duration } = params;
        const defaultSeparator = getDefaultSeparator();

        if (parts && parts.length > 0) {
            return partType === ENTITY_PART_TYPE_SEPARATOR
                ? separatorTypeValidation(parts, selectedIndex)
                : rateTypeValidation(
                      parts,
                      isEditMode,
                      selectedIndex,
                      defaultSeparator,
                      duration
                  );
        }

        return true;
    };

    const rateTypeValidation = (
        parts,
        isEditMode,
        selectedIndex,
        defaultSeparator,
        duration
    ) => {
        const separatorBeforeCurrentPart = isEditMode
            ? selectedIndex === 0
                ? SEPARATOR_PLUS
                : parts[selectedIndex - 1].separatorValue
            : parts.slice(-1)[0].partType === ENTITY_PART_TYPE_SEPARATOR
            ? parts.slice(-1)[0].separatorValue
            : defaultSeparator;

        return separatorBeforeCurrentPart === SEPARATOR_PLUS
            ? !checkIsDurationOverlap(parts, selectedIndex, duration)
            : true;
    };

    const separatorTypeValidation = (parts, selectedIndex) => {
        if (
            parts[selectedIndex].separatorValue === SEPARATOR_PLUS ||
            parts.length === selectedIndex + 1
        ) {
            return true;
        }

        const rateValueAfterCurrentSeparator =
            parts[selectedIndex + 1].rateValue;

        return rateValueAfterCurrentSeparator.duration
            ? !checkIsDurationOverlap(
                  parts,
                  null,
                  rateValueAfterCurrentSeparator.duration
              )
            : true;
    };

    const checkIsDurationOverlap = (parts, selectedIndex, selectedDuration) => {
        if (
            selectedDuration &&
            parts.some((part) => part.rateValue && part.rateValue.duration)
        ) {
            const plusParts = getPlusPartsIndices(parts, selectedIndex);

            if (plusParts.length > 0) {
                const selectedDurationAmountInDays =
                    mapDurationAmountIntoDays(selectedDuration);

                const durationAmountsInDaysFromParts = parts
                    .filter((part, index) => plusParts.includes(index))
                    .map((part) =>
                        mapDurationAmountIntoDays(part.rateValue.duration)
                    );

                const isDurationOverlapped =
                    durationAmountsInDaysFromParts.some((amount) =>
                        checkAmountIntersection(
                            amount,
                            selectedDurationAmountInDays
                        )
                    );

                return isDurationOverlapped;
            }
        }

        return false;
    };

    const checkAmountIntersection = (amount, durationAmountInDays) => {
        return (
            amount &&
            // this is valid if new duration is EQUAL to any existing
            !(
                amount.from === durationAmountInDays.from &&
                amount.to === durationAmountInDays.to
            ) &&
            // not valid if new duration is partial intersects with any of existing
            ((amount.from <= durationAmountInDays.from &&
                amount.to >= durationAmountInDays.from) ||
                (amount.from <= durationAmountInDays.to &&
                    amount.to >= durationAmountInDays.to) ||
                (amount.from < durationAmountInDays.from &&
                    amount.to > durationAmountInDays.to) ||
                (amount.from > durationAmountInDays.from &&
                    amount.to < durationAmountInDays.to))
        );
    };

    const mapDurationAmountIntoDays = (duration) => {
        if (!duration) {
            return null;
        }

        const durationUnitObj = getDurationUnits().find(
            (unit) => unit.value === duration.unit
        );
        const lengthUnitInDays = durationUnitObj.lengthInDays;
        const { amount } = duration;

        return {
            from: (amount.from - 1) * lengthUnitInDays + 1,
            to: amount.to * lengthUnitInDays,
        };
    };

    const getPlusPartsIndices = (parts, selectedIndex) => {
        const result = [0];

        parts.forEach((part, index) => {
            if (part.separatorValue === SEPARATOR_PLUS) {
                result.push(index + 1);
            }
        });

        return isNaN(selectedIndex)
            ? result
            : result.filter((index) => index !== selectedIndex);
    };

    const getDurationUnits = () => {
        return durationUnits.filter((unit) => unit.isApplicableForRates);
    };

    const checkIsExtendedMode = () => {
        return props.node.data.type === 'PERIOD';
    };

    const getContext = () => {
        return {
            ...props.context,
            isExtendedMode: checkIsExtendedMode(),
        };
    };

    const getDefaultSeparator = () => {
        return checkIsExtendedMode() ? SEPARATOR_PLUS : SEPARATOR_FORWARD_SLASH;
    };

    const createRatesObject = (parts) => {
        return {
            rateParts: trimTrailingSeparators(trimSeparators(parts)),
            isNetRate: parts.length > 0 && isNetRate,
        };
    };

    const getMultiselectStyles = () => {
        return { maxWidth: '800px' };
    };

    return (
        <FortCellEditor blockNavigation={false} {...props}>
            <PopupWindow
                ref={selectRef}
                context={getContext()}
                value={props.value?.rateParts}
                data={props.node.data}
                onTab={handleOnTab}
                onTabBack={handleOnTabBack}
                onEnter={handleOnEnter}
                shouldSelectItemOnTab={true}
                inputClass="ag-react-container"
                createSeparatorPart={createSeparatorRatePart}
                entityPartTypesOfValuePart={[ENTITY_PART_TYPE_RATE]}
                partsFormatterInstance={rateFormatterInstance}
                separatorsValidation={partsValidation}
                defaultSeparator={getDefaultSeparator()}
                partsValidation={partsValidation}
                multiselectStyles={getMultiselectStyles()}
                multipleSelect={
                    <MultipleSelect
                        fixedOption={
                            isNetRate ? NET_RATE_FIXED_OPTION_NAME : null
                        }
                    />
                }
                input={
                    <RateInput
                        handleNetRate={handleChangeNetRate}
                        isNetRate={isNetRate}
                    />
                }
            ></PopupWindow>
        </FortCellEditor>
    );
});

export default RateEditor;
