import React, { Component } from 'react';
import WarningIcon from './icons/WarningIconRed';

class ErrorPage extends Component {
    reloadPage() {
        window.location.reload(true);
    }

    render() {
        return (
            <div className="error-page">
                <div className="details-container">
                    <div className="warning-icon-container">
                        <div className="warning-icon">
                            <WarningIcon />
                        </div>
                    </div>
                    <div>
                        <h1>Error</h1>
                        <p>
                            An unknown error has occured.
                            <br />
                            Please contact support should this error persist.
                        </p>
                        <div>
                            <div>
                                <button
                                    className="ui primary button"
                                    onClick={this.reloadPage}
                                >
                                    Reload Application
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPage;
