import { gql } from '@apollo/client';

import { FIXTURE_FRAGMENT } from '../fragments/fixtureFragment';

export const FIXTURE_ADDED_SUBSCRIPTION = gql`
    subscription onFixtureAdded($groupId: ID!) {
        fixtureAdded(groupId: $groupId) {
            ...FixtureFragment
        }
    }

    ${FIXTURE_FRAGMENT}
`;

export const FIXTURE_UPDATED_SUBSCRIPTION = gql`
    subscription onFixtureUpdated($groupId: ID!) {
        fixtureUpdated(groupId: $groupId) {
            ...FixtureFragment
        }
    }

    ${FIXTURE_FRAGMENT}
`;

export const FIXTURE_SHARED_SUBSCRIPTION = gql`
    subscription onFixtureShared($groupId: ID!) {
        fixtureShared(groupId: $groupId) {
            ...FixtureFragment
        }
    }

    ${FIXTURE_FRAGMENT}
`;
