import React, { Component } from 'react';
import ToolbarButton from './ToolbarButton';
import MoreVertIcon from '../icons/MoreVertIcon';
import { Dropdown } from 'semantic-ui-react';
import { isWithinMenu } from '../common/Menu';
import { withRouter } from 'react-router-dom';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import SwitchWithSubLabel from './SwitchWithSubLabel';
import { connect } from 'react-redux';
import userSettingsApi from '_legacy/api/userSettingsApi';
import { toggleDensityView, toggleNewUI } from '_legacy/actions/userActions';
import RadioGroupWithImages from './RadioGroupWithImages';
import MixpanelLogger from '_legacy/diagnostics/logging/mixpanelLogger';

export const NEW_UI_SETTING = 1;
export const SINGLE_SCREEN_VIEW = 2;
export const MULTI_SCREEN_VIEW = 3;
export const DENSITY_SETTINGS = 4;
export const GRID_SETTINGS = 5;
export const ROW_HEIGHT = 6;
export const SHORTCUT_BUTTON = 7;
class MoreButton extends Component {
    constructor(props) {
        super(props);
        this.state = { shortcutsOpen: false };
    }
    handleNewUIToggleChange = async () => {
        try {
            this.props.toggleNewUI();
            await userSettingsApi.togglePreferredTheme();
            MixpanelLogger.trackNewUIToggle(this.props.newThemeEnabled);
        } catch (error) {
            console.error('Error setting preferred theme:', error);
        }
    };

    onDensityViewChanged = async () => {
        try {
            this.props.toggleDensityView();
            await userSettingsApi.toggleCompactDensityView();
            MixpanelLogger.trackNewUIDensityChange(
                this.props.useCompactDensityView
            );
        } catch (error) {
            console.error('Error setting compact density theme:', error);
        }
    };
    toggleShortcuts = (e) => {
        e.stopPropagation();

        this.setState({ shortcutsOpen: !this.state.shortcutsOpen });
    };

    render = () => {
        const {
            ordersPage,
            fixturesPage,
            newThemeEnabled,
            useCompactDensityView,
        } = this.props;
        const options = [];

        const containerClassName = newThemeEnabled
            ? 'more-button-container new-theme-enabled'
            : 'more-button-container';

        options.push({
            key: 1,
            content: (
                <SwitchWithSubLabel
                    id="NewUI"
                    label="New UI"
                    subLabel="Enable the new FORT grid styles."
                    isChecked={newThemeEnabled}
                    onChange={this.handleNewUIToggleChange}
                />
            ),
            selected: false,
        });

        options.push({
            key: 2,
            value: this.props.singleView
                ? MULTI_SCREEN_VIEW
                : SINGLE_SCREEN_VIEW,
            text: (
                <>
                    <SwitchWithSubLabel
                        id="MultiScreenView"
                        label="Multi Screen View"
                        subLabel="See Orders and Fixtures in one view."
                        isChecked={!this.props.singleView}
                    />
                </>
            ),
        });

        if (!newThemeEnabled) {
            options.push(
                this.props.showCondensed
                    ? {
                          key: 3,
                          text: (
                              <span style={{ display: 'flex' }}>
                                  Row Height (Increase)
                              </span>
                          ),
                          value: ROW_HEIGHT,
                      }
                    : {
                          key: 3,
                          text: (
                              <span style={{ display: 'flex' }}>
                                  Row Height (Decrease)
                              </span>
                          ),
                          value: ROW_HEIGHT,
                      }
            );
        } else {
            options.push({
                key: 3,
                text: (
                    <>
                        <RadioGroupWithImages
                            isChecked={useCompactDensityView}
                            onDensityViewChanged={this.onDensityViewChanged}
                        />
                    </>
                ),
                value: DENSITY_SETTINGS,
            });
        }
        options.push({
            key: 4,
            text: (
                <div style={{ width: '100%', display: 'flex' }}>
                    <span className="grid-setting-label ">Grid Settings</span>
                    <div className="grid-settings-icon">
                        <ChevronRightIcon style={{ marginRight: '15px' }} />
                    </div>
                </div>
            ),
            value: GRID_SETTINGS,
        });

        options.push({
            key: 5,
            value: SHORTCUT_BUTTON,
            text: (
                <>
                    <div
                        className="moreButton-shortcuts-overlay"
                        onMouseEnter={this.toggleShortcuts}
                        onMouseLeave={this.toggleShortcuts}
                    />
                    <div className="moreButton-shortcuts">
                        Keyboard Shortcuts
                        {this.state.shortcutsOpen && (
                            <div
                                className="moreButton-shortcuts-list"
                                onClick={(e) => e.stopPropagation()}
                            >
                                {ordersPage && (
                                    <div>
                                        <span>New Public Order</span>
                                        <span>Ctrl + Alt + O</span>
                                    </div>
                                )}
                                {fixturesPage && (
                                    <>
                                        <div>
                                            <span>New Public Fixture</span>
                                            <span>Ctrl + Alt + F</span>
                                        </div>
                                        <div>
                                            <span>Analytics</span>
                                            <span>Ctrl + Alt + R</span>
                                        </div>
                                        <div>
                                            <span>Advanced Analytics</span>
                                            <span>Ctrl + Alt + D</span>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </>
            ),
        });

        return (
            <div className={containerClassName}>
                <Dropdown
                    trigger={
                        <ToolbarButton
                            id={this.props.id}
                            isActive={this.props.isActive}
                            isNarrow={true}
                        >
                            <MoreVertIcon />
                        </ToolbarButton>
                    }
                    value={null}
                    options={options}
                    onOpen={this.props.onShow}
                    onClose={this.props.onHide}
                    onChange={(e, option) => {
                        const { value } = option;

                        if (value === SHORTCUT_BUTTON) {
                            return;
                        }

                        if (isWithinMenu(e.target) || e.key === 'Enter') {
                            if (value === ROW_HEIGHT) {
                                this.props.onToggleCondensed &&
                                    this.props.onToggleCondensed();
                            } else if (value === GRID_SETTINGS) {
                                this.props.onOpenSideBar &&
                                    this.props.onOpenSideBar();
                            } else if (value === MULTI_SCREEN_VIEW) {
                                this.props.history.push('/multi');
                            } else if (value === SINGLE_SCREEN_VIEW) {
                                this.props.history.push(
                                    this.props.ordersPage
                                        ? '/orders'
                                        : '/fixtures'
                                );
                            }
                        }
                    }}
                />
            </div>
        );
    };
}

const mapDispatchToProps = (dispatch) => ({
    toggleNewUI: () => dispatch(toggleNewUI()),
    toggleDensityView: () => dispatch(toggleDensityView()),
});
const mapStateToProps = (state) => ({
    newThemeEnabled: state.user.themeSettings.newThemeEnabled,
    useCompactDensityView: state.user.themeSettings.useCompactDensityView,
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(MoreButton)
);
