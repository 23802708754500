import {
    ICellEditor,
    ICellEditorParams,
} from '@ag-grid-enterprise/all-modules';
import { Checkbox } from '@material-ui/core';
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
} from 'react';
import '../../../../components/grid/BasicGrid.scss';
import classNames from 'classnames';
import ChevronDown from '_legacy/components/icons/ChevronDown';
import * as keyboardCodes from '../../../../constants/keyboardCodes';

interface ICellEditorReactComp
    extends Partial<ICellEditor>,
        Partial<ICellEditorParams> {
    valuesByDataset: (datasetId: number) => string[];
}

const MultiSelect = forwardRef((props: ICellEditorReactComp, ref) => {
    const {
        valuesByDataset,
        context,
        value: editorValue,
        charPress,
        formatValue,
        stopEditing,
    } = props;
    const initialValues = (): string[] => {
        if (!editorValue) {
            return [];
        }
        return editorValue.indexOf(',') > -1
            ? editorValue.split(', ')
            : [editorValue];
    };

    const dropdownValues = valuesByDataset(context.datasetId);
    const [selectedValues, setSelectedValues] =
        React.useState<string[]>(initialValues);
    const [focusedValue, setFocusedValue] = React.useState<string>(
        selectedValues[0]
    );
    const [lastCharacter, setlastCharacter] = React.useState<
        string | undefined
    >(undefined);
    const [offsetIndex, setOffsetIndex] = React.useState<number>(0);
    const refInput = useRef(null);

    useEffect(() => {
        (refInput?.current as any)?.focus();
        if (charPress) {
            searchByFirstCharacter(charPress);
        }
    }, []);

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return selectedValues.length === 1
                    ? selectedValues[0]
                    : selectedValues.join(', ');
            },
            isPopup: () => true,
        };
    });

    const searchByFirstCharacter = (character) => {
        // Find all items with the same first character as the one just been entered
        var matchingValues = dropdownValues.filter((value) => {
            return formatValue?.(value)
                .toLowerCase()
                .startsWith(character.toLowerCase());
        });

        // If there are no matches, reset the state, but leave the current selected value
        if (matchingValues.length === 0) {
            setOffsetIndex(0);
            setlastCharacter(character.toLowerCase());
            return;
        }

        let currentOffsetIndex = offsetIndex;
        if (character.toLowerCase() === lastCharacter) {
            // Character keyed again
            currentOffsetIndex++;

            // Exceeded the bounds of available items, so loop around to the first one
            if (currentOffsetIndex >= matchingValues.length) {
                currentOffsetIndex = 0;
            }
        } else {
            // different character keyed
            currentOffsetIndex = 0;
        }

        const chosenValue = matchingValues[currentOffsetIndex];
        setFocusedValue(chosenValue);
        setOffsetIndex(currentOffsetIndex);
        setlastCharacter(character.toLowerCase());
    };

    const isAlpha = (keyCode) => {
        return keyCode > 64 && keyCode < 91;
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === keyboardCodes.KEY_ENTER) {
            e.preventDefault();
            e.stopPropagation();

            handleChange(focusedValue);
        } else if (e.keyCode === keyboardCodes.KEY_DOWN) {
            e.preventDefault();
            e.stopPropagation();

            moveSelectionDown();
        } else if (e.keyCode === keyboardCodes.KEY_UP) {
            e.preventDefault();
            e.stopPropagation();

            moveSelectionUp();
        } else if (isAlpha(e.keyCode)) {
            e.preventDefault();
            e.stopPropagation();

            searchByFirstCharacter(e.key);
        }
    };

    const moveSelectionDown = () => {
        const currentIndex = dropdownValues.indexOf(focusedValue);

        if (currentIndex < dropdownValues.length - 1) {
            const nextValue = dropdownValues[currentIndex + 1];
            setFocusedValue(nextValue);
        }
    };

    const moveSelectionUp = () => {
        const currentIndex = dropdownValues.indexOf(focusedValue);

        if (currentIndex > 0) {
            const nextValue = dropdownValues[currentIndex - 1];
            setFocusedValue(nextValue);
        }
    };

    const handleClick = (e: any) => {
        const newValue = e.currentTarget.dataset.value;
        setFocusedValue(newValue);
        handleChange(newValue);
    };

    const handleChange = (newValue: string) => {
        if (newValue === '') {
            setSelectedValues([]);
            return;
        }
        if (selectedValues.includes(newValue)) {
            setSelectedValues(
                selectedValues.filter((value) => value !== newValue)
            );
        } else {
            setSelectedValues([...selectedValues, newValue]);
        }
    };

    const handleMouseOver = (e) => {
        setFocusedValue(e.currentTarget.dataset.value);
    };

    const items = dropdownValues.map((v: string) => {
        const classes = classNames({
            'selectEditor-row': true,
            'selectEditor-row-selected':
                selectedValues.includes(v) || v === focusedValue,
        });

        let displayValue = '\u00A0';

        if (v.length > 0 || typeof v === 'boolean') {
            displayValue = formatValue?.(v);
        }

        return (
            <div
                data-value={v}
                className={classes}
                onClick={handleClick}
                onMouseOver={handleMouseOver}
                key={v}
            >
                {v !== '' && (
                    <Checkbox
                        color="primary"
                        checked={selectedValues.includes(v)}
                        className="multiSelect-checkbox"
                    />
                )}
                {displayValue}
            </div>
        );
    });

    return (
        <>
            <div
                className="selectEditor-overlay"
                onClick={() => {
                    // we use overlay to intercept and prevent outside clicks saving the selected value by removing the ability for grid to get the value
                    setSelectedValues(initialValues);
                    stopEditing?.();
                }}
            />
            <div
                ref={refInput}
                className="selectEditor"
                tabIndex={1}
                onKeyDown={handleKeyDown}
            >
                <div className="selectEditor-row selectEditor-value">
                    {selectedValues.length === 1
                        ? selectedValues[0]
                        : selectedValues.join(', ')}
                    <ChevronDown />
                </div>
                <div className="selectEditor-list">{items}</div>
            </div>
        </>
    );
});

export default MultiSelect;
