class EntityNodeTree {
    constructor(customEntities, knownEntities, placeholderEntities, countries) {
        this.nodesMap = new Map();

        this.filterState = { entityFilterSet: [ROOT_NODE_KEY] };

        this.initializeNodes(
            customEntities,
            knownEntities,
            placeholderEntities,
            countries
        );

        this.nodes = Array.from(this.nodesMap.entries()).map(
            ([key, node]) => node
        );
    }

    initializeNodes() {
        this.root = this.createNode(ROOT_NODE_KEY, ROOT_NODE_KEY, null);
    }

    createNode = (key, value, parent, countryCode) => {
        const node = {
            key,
            value,
            parent,
            countryCode,
            children: [],
            state: States.CHECKED,
        };
        this.nodesMap.set(key, node);
        return node;
    };

    pushParentNode(nodeValue, nodeKey) {
        const parentNode = this.createNode(
            nodeKey ?? nodeValue,
            nodeValue,
            this.root
        );
        this.root.children.push(parentNode);
        return parentNode;
    }

    pushLeafNode(key, value, parent, countryCode) {
        const leaf = this.createNode(key, value, parent, countryCode);
        parent.children.push(leaf);
        return leaf;
    }

    setCheckbox(key, state) {
        if (state !== States.CHECKED && state !== States.UNCHECKED) return;
        this.trickleDown(key, state);
        this.bubbleUp(key);
        this.filterState = this.getFilterState();
    }

    trickleDown(key, state) {
        let node = this.nodesMap.get(key);
        if (node) {
            node.state = state;
            node.children.forEach((n) => this.trickleDown(n.key, state));
        }
    }

    bubbleUp(id) {
        let node = this.nodesMap.get(id);
        if (node) {
            node = node.parent;
            while (node !== null) {
                this.updateNodeDerivedState(node);
                node = node.parent;
            }
        }
    }

    updateNodeDerivedState(node) {
        const anyChecked = node.children.some(
            (n) => n.state === States.CHECKED
        );
        const anyUnchecked = node.children.some(
            (n) => n.state === States.UNCHECKED
        );
        const anyIndeterminate = node.children.some(
            (n) => n.state === States.INDETERMINATE
        );
        node.state =
            (anyChecked && anyUnchecked) || anyIndeterminate
                ? States.INDETERMINATE
                : anyChecked
                ? States.CHECKED
                : States.UNCHECKED;
    }

    isChecked(id) {
        const node = this.nodesMap.get(id);
        return node ? node.state === States.CHECKED : true;
    }

    getNodeAncestors(id) {
        const hierarchy = [];
        let node = this.nodesMap.get(id);
        while (node) {
            hierarchy.push(node.key);
            node = node.parent;
        }
        return hierarchy;
    }

    getNodesMap() {
        return this.nodesMap;
    }

    getNode(id) {
        return this.nodesMap.get(id);
    }

    getYears() {
        const root = this.nodesMap.get(ROOT_NODE_KEY);
        return root ? root.children : [];
    }

    unwrapHierarchy(node, hierarchy) {
        if (node.state === States.CHECKED) {
            hierarchy.push(node.key);
        } else {
            node.children.forEach((n) => this.unwrapHierarchy(n, hierarchy));
        }
    }

    getFilterState() {
        let model = { entityFilterSet: [] };
        let root = this.nodesMap.get(ROOT_NODE_KEY);
        this.unwrapHierarchy(root, model.entityFilterSet);
        return model;
    }

    setFilterState(model) {
        this.setCheckbox(ROOT_NODE_KEY, States.UNCHECKED);
        if (model) {
            model.entityFilterSet.forEach((key) =>
                this.setCheckbox(key, States.CHECKED)
            );
        } else {
            this.setCheckbox(ROOT_NODE_KEY, States.CHECKED);
        }
    }

    clearFilter = () => this.setCheckbox(ROOT_NODE_KEY, States.CHECKED);

    filterByVisibleNodes(fullTree, visibleNodes) {
        fullTree.nodes.forEach((node) => {
            if (visibleNodes && visibleNodes.hasOwnProperty(node.key)) {
                this.setCheckbox(node.key, States.CHECKED);
            } else {
                this.setCheckbox(node.key, States.UNCHECKED);
            }
        });
    }
}

const States = {
    CHECKED: 'checked',
    INDETERMINATE: 'indeterminate',
    UNCHECKED: 'unchecked',
};
EntityNodeTree.States = States;

const CUSTOM_ENTITY_PREFIX = 'CUSTOM_';
const PLACEHOLDER_ENTITY_PREFIX = 'PLACEHOLDER_';
const ROOT_NODE_KEY = '_';
EntityNodeTree.RootNodeKey = ROOT_NODE_KEY;
EntityNodeTree.CustomEntityPrefix = CUSTOM_ENTITY_PREFIX;
EntityNodeTree.PlaceholderEntityPrefix = PLACEHOLDER_ENTITY_PREFIX;

export default EntityNodeTree;
