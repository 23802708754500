import React from 'react';
import CheckedSvg from './Checked.svg';

const CheckedIcon = (props) => (
    <img
        src={CheckedSvg}
        style={{
            opacity: props.hide ? '0' : '0.7',
            width: '25px',
            marginRight: '10px',
            filter: 'invert(47%) sepia(38%) saturate(4639%) hue-rotate(189deg) brightness(104%) contrast(91%)',
        }}
        alt="Checked Icon"
    />
);

export default CheckedIcon;
