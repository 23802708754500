import { gql } from '@apollo/client';

export const KEEP_ALIVE_SUBSCRIPTION = gql`
    subscription onKeepAlive {
        keepAlive {
            type
            version
        }
    }
`;
