import * as DateTimeFormatUtils from '../../common/DateTimeFormatUtils';

const DefaultDateFormatter = ({ node, value }) => {
    if (node && node.group) {
        return '';
    }
    if (!value || (node && node.group)) {
        return '';
    }
    return DateTimeFormatUtils.formatDefaultDate(value);
};

export default DefaultDateFormatter;
