import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import './SlideoutButton.scss';

const SlideoutButton = (props) => {
    const {
        icon: Icon,
        slide = 'confirm',
        onClick: passedOnClick,
        className,
        ...rest
    } = props;
    let [open, setOpen] = useState(false);

    const toggle = useCallback(() => setOpen(!open), [open]);

    const onClick = (e) => {
        toggle();
        passedOnClick(e);
    };

    const style = {
        width: open ? '88px' : 0,
    };

    return (
        <div className={cn('slidebtn', className, { open })} {...rest}>
            {open && <div className="slidebtn-overlay" onClick={toggle} />}
            <div className="slidebtn-slide-wrapper">
                <div className="slidebtn-slide" style={style}>
                    <div className="slidebtn-slide-content" onClick={onClick}>
                        {slide}
                    </div>
                </div>
            </div>
            <div className="slidebtn-content" onClick={toggle}>
                <div className="slidebtn-icon">
                    <Icon />
                </div>
            </div>
        </div>
    );
};

export default SlideoutButton;
