import React from 'react';
import StripedLoader from '../../common/StripedLoader';

const DownshiftActivityIndicator = ({ text }) => {
    return (
        <div className="downshift-general-item downshift-general-loader">
            <StripedLoader size={'medium'} />
        </div>
    );
};

export default DownshiftActivityIndicator;
