import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';

export default class ExportButton extends Component {
    render = () => {
        return (
            <Button
                data-cy={this.props.id}
                className="primary exportButton"
                content={`EXPORT ${this.props.selectedCount}`}
                icon="download"
                labelPosition="left"
                onClick={this.props.onClick}
            />
        );
    };
}
