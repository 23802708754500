import moment from 'moment';
import LaycanValueGetter from './LaycanValueGetter';

const LaycanDecadeValueGetter = (params) => {
    var laycan = LaycanValueGetter(params);

    if (!laycan) {
        return null;
    }

    const startDate = moment(laycan.startDate);
    const day = startDate.date();

    if (startDate.isValid()) {
        if (day >= 1 && day <= 10) return 1;
        if (day >= 11 && day <= 20) return 2;
        if (day >= 21 && day <= 31) return 3;
    } else {
        return null;
    }
};

export default LaycanDecadeValueGetter;
