import React from 'react';
import MultiSelectSeparator from '../../../../components/grid/inputs/MultiSelectSeparator';
import DraggableMultiSelectItem from '../../../../components/grid/inputs/DraggableMultiSelectItem';
import { ENTITY_PART_TYPE_SEPARATOR } from '../../../../models/common/EntityPart';
import MultiSelectFixedOption from '_legacy/components/grid/inputs/MultiSelectFixedOption';

class MultipleSelect extends React.Component {
    constructor(props) {
        super(props);

        this.multipleSelect = React.createRef();
        this.handleRemovedItem = this.handleRemovedItem.bind(this);
        this.switchSeparator = this.switchSeparator.bind(this);
        this.onDraggedItem = this.onDraggedItem.bind(this);
    }

    handleRemovedItem(index) {
        this.props.onPartRemove(index);
    }

    handleOnValueEdit(index) {
        this.props.onValueEdit(index);
    }

    onDraggedItem(sourceIndex, targetIndex) {
        this.props.onDraggedItem(sourceIndex, targetIndex);
    }

    switchSeparator(index) {
        this.props.onSwitchSeparator(index);
    }

    focus() {
        if (this.multipleSelect.current != null) {
            this.multipleSelect.current.focus();
        }
    }

    render() {
        const { className } = this.props;
        let items = [];

        items = this.props.parts.map((part, index) => {
            const displayValue = this.props.partsFormatterInstance.format(part);

            if (this.props.entityPartTypesOfValuePart.includes(part.partType)) {
                return (
                    <DraggableMultiSelectItem
                        key={index}
                        index={index}
                        value={displayValue}
                        onDraggedItem={this.onDraggedItem}
                        onRemovedItem={this.handleRemovedItem}
                        onClickedItem={() => this.handleOnValueEdit(index)}
                        pill
                        isSelected={index === this.props.selectedIndex}
                        tabIndex="-1"
                    />
                );
            } else if (part.partType === ENTITY_PART_TYPE_SEPARATOR) {
                return (
                    <MultiSelectSeparator
                        key={index}
                        index={index}
                        value={displayValue}
                        onDraggedItem={this.onDraggedItem}
                        onClick={() => this.switchSeparator(index)}
                        onRemovedItem={this.handleRemovedItem}
                        pill
                        checkIsSeparatorException={
                            this.props.checkIsSeparatorException
                        }
                    />
                );
            } else {
                throw new Error(
                    `Unknown part type specified: ${part.partType}`
                );
            }
        });

        if (this.props.fixedOption) {
            items.unshift(
                <MultiSelectFixedOption
                    key={this.props.fixedOption}
                    value={this.props.fixedOption}
                />
            );
        }

        return (
            <div
                style={{
                    width: 'auto',
                    maxWidth: '600px',
                    ...this.props.styles,
                }}
                className={`multiple-location-select ${className}`}
            >
                <div className="multiple-location-select-items">{items}</div>
            </div>
        );
    }
}

export default MultipleSelect;
