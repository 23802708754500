export const MARKET = 'MARKET';
export const SUB_SALE = 'SUB SALE';
export const OFF_MARKET = 'OFF MARKET';
export const ON_SUBS = 'ON SUBS';
export const ON_HOLD = 'ON HOLD';
export const FAILED = 'FAILED';

export const getAvailableStatuses = (currentStatus) => {
    switch (currentStatus) {
        case ON_SUBS:
            return [ON_SUBS, FAILED];
        default:
            return [SUB_SALE, MARKET, OFF_MARKET, ON_SUBS, ON_HOLD];
    }
};
