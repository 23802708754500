import React, { Component } from 'react';
import generateId from '../../../tools/idGenerator';
import PropTypes from 'prop-types';
import FortDownshiftInput from './FortDownshiftInput';
import CompanyConfirmation from './CompanyConfirmation';
import { KEY_TAB } from '../../../constants/keyboardCodes';
import { modalService } from 'common';

class CompanyInput extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.handleItemSelected = this.handleItemSelected.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = { initialChar: props.initialChar };
    }

    itemToString = (company) => (company ? company.name : '');

    createCustomItem(value) {
        return {
            id: null,
            name: value.toUpperCase(),
            key: generateId(),
        };
    }

    handleItemSelected(selectedCompany, keyCode, shift) {
        if (selectedCompany.id === null) {
            this.setState({
                selectedCompany,
                initialChar: selectedCompany.name,
                keyCode,
                shift,
            });

            const content = (
                <CompanyConfirmation
                    companyName={selectedCompany.name}
                    onClose={() => {
                        this.handleClose();
                        setTimeout(modalService.close);
                    }}
                    onConfirm={() => {
                        this.handleConfirm();
                        setTimeout(modalService.close);
                    }}
                />
            );

            modalService.open({ content });
        } else {
            if (this.props.onCompanySelected) {
                let company = this.convertRetrievedCompany(selectedCompany);
                this.props.onCompanySelected(company, keyCode, shift);
            }
        }
    }

    handleDelete() {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

    convertRetrievedCompany(company) {
        return {
            id: company.id,
            name: company.name,
        };
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    reset() {
        if (this.inputRef.current) {
            this.inputRef.current.reset();
        }
    }

    renderCompany(getItemProps, highlightedIndex, item, index) {
        return (
            <tr
                {...getItemProps({
                    item,
                    key: item.id || item.key,
                    style: {
                        backgroundColor:
                            highlightedIndex === index
                                ? 'rgba(0,0,0,0.05)'
                                : 'white',
                    },
                })}
            >
                <td>{item.id ? item.name : <b>ADD "{item.name}"</b>}</td>
            </tr>
        );
    }

    handleConfirm() {
        this.reset();

        this.setState({ initialChar: null, showConfirmation: false });

        if (this.props.onCompanySelected) {
            let company = this.convertRetrievedCompany(
                this.state.selectedCompany
            );
            this.props.onCompanySelected(company, KEY_TAB, false);
        }
    }

    handleClose() {
        this.setState(
            {
                initialChar: this.state.selectedCompany.name,
                showConfirmation: false,
            },
            () => this.focus()
        );
    }

    render() {
        return (
            <FortDownshiftInput
                {...this.props}
                initialChar={this.state.initialChar}
                ref={this.inputRef}
                itemToString={this.itemToString}
                searchFunc={this.props.searchApi}
                renderItem={this.renderCompany}
                placeholder="Company name"
                createCustomItem={this.createCustomItem}
                onItemSelected={this.handleItemSelected}
                onDelete={this.handleDelete}
                onInputCleared={this.props.onInputCleared}
                onTab={this.props.onTab}
                onTabBack={this.props.onTabBack}
                blockedCharacters={this.props.blockedCharacters}
                downshiftContentClassname="company-downshift-content"
            />
        );
    }
}

CompanyInput.propTypes = {
    onCompanySelected: PropTypes.func.isRequired,
    shouldSelectItemOnTab: PropTypes.bool.isRequired,
    inputClass: PropTypes.string,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    initialChar: PropTypes.string,
    searchApi: PropTypes.func.isRequired,
    onInputCleared: PropTypes.func.isRequired,
};

export default CompanyInput;
