import React from 'react';
import './StripedLoader.scss';

const StripedLoader = ({ size }) => (
    <div className={'striped-loader ' + (size || 'large')}>
        <div className="stripe">
            <div className="stripe-inner" />
        </div>
        <div className="stripe">
            <div className="stripe-inner" />
        </div>
        <div className="stripe">
            <div className="stripe-inner" />
        </div>
        <div className="stripe">
            <div className="stripe-inner" />
        </div>
        <div className="stripe">
            <div className="stripe-inner" />
        </div>
    </div>
);

export default StripedLoader;
