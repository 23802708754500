import initialState from './initialState';
import * as types from '../actions/actionTypes';

let nextMessageId = 1;

const notificationMessagesReducer = (
    state = initialState.notificationMessages,
    action
) => {
    switch (action.type) {
        case types.NOTIFICATION_MESSAGE_ADDED:
            var messageViewModel = {
                id: nextMessageId++,
                message: action.message,
            };
            return [messageViewModel, ...state];
        case types.USER_DISMISSED_NOTIFICATION_MESSAGE:
            return state.filter((item) => item !== action.message);
        default:
            return state;
    }
};

export default notificationMessagesReducer;
