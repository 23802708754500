import React from 'react';
import { Query } from '@apollo/client/react/components';
import { AllGroupsQuery } from '../../../api/queries/GroupQueries';
import moment from 'moment';
import { Link, withRouter } from 'react-router-dom';
import { getDataset } from '../../../models/Datasets';

const dateFormat = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
});

const ListGroupPage = ({ match }) => {
    const getDatasetName = (datasetId) => {
        let dataset = getDataset(datasetId);
        return dataset ? dataset.label : '';
    };

    const formatHours = (value) => {
        return value === 0
            ? 'None'
            : value === 1
            ? value + ' hour'
            : value + ' hours';
    };

    return (
        <Query query={AllGroupsQuery}>
            {({ loading, error, data, refetch }) => {
                if (loading) {
                    return (
                        <div className="ui info message">Loading groups...</div>
                    );
                }

                if (error) {
                    return (
                        <div className="ui negative message">
                            {error.message}
                        </div>
                    );
                }

                let tableRows;

                if (data.groups.length === 0) {
                    tableRows = (
                        <tr>
                            <td colSpan="4">There are no groups</td>
                        </tr>
                    );
                } else {
                    tableRows = data.groups.map((g) => {
                        return (
                            <tr key={g.id}>
                                <td>{g.name}</td>
                                <td>{getDatasetName(g.dataset.id)}</td>
                                <td>{g.createdBy.username}</td>
                                <td>
                                    {dateFormat.format(moment(g.createdDate))}
                                </td>
                                <td>{formatHours(g.groupOnlyExpiryPeriod)}</td>
                                <td>
                                    <Link
                                        data-cy="edit-button"
                                        className="ui primary button"
                                        to={`${match.url}/${g.id}`}
                                    >
                                        Edit
                                    </Link>
                                </td>
                            </tr>
                        );
                    });
                }

                return (
                    <div>
                        <h2>Groups</h2>
                        <Link data-cy="add-group-btn" to="/admin/groups/add">
                            Add Group
                        </Link>
                        <Link
                            style={{ float: 'right' }}
                            to="/admin/groups/userSearch"
                        >
                            User Search
                        </Link>
                        <table data-cy="group-list" className="ui celled table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Dataset</th>
                                    <th>Created By</th>
                                    <th>Created Date</th>
                                    <th>Group Only Expiry Period</th>
                                    <th style={{ width: '100px' }} />
                                </tr>
                            </thead>
                            <tbody>{tableRows}</tbody>
                        </table>
                        <button
                            data-cy="refresh-btn"
                            className="ui button"
                            onClick={() => refetch()}
                        >
                            Refresh
                        </button>
                    </div>
                );
            }}
        </Query>
    );
};

export default withRouter(ListGroupPage);
