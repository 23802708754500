export const allDirections = [
    'Aussie RV',
    'Backhaul',
    'Fronthaul',
    'InterAmericas',
    'InterCont',
    'InterMed',
    'InterMEG',
    'NOPAC RV',
    'Other',
    'SE Asia RV',
    'Transatlantic',
    'West Coast',
];

export const indonesiaCountryId = '74a1cfc2-f962-483e-aabc-eb7f96c41eef';

export const indonesiaSpecificDirectionMapping = 'SE Asia RV';

export const indonesiaSpecificDischargeZones = [
    'SCHINA',
    'CCHINA',
    'NCHINA',
    'NASIA',
];

const directionMapping = [
    { Load: 'BALT', Discharge: 'BALT', Direction: 'InterCont' },
    { Load: 'BALT', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'BSEA', Direction: 'Other' },
    { Load: 'BALT', Discharge: 'CANARY', Direction: 'Other' },
    { Load: 'BALT', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'BALT', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'EMED', Direction: 'Other' },
    { Load: 'BALT', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'BALT', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'BALT', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'BALT', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'BALT', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'BALT', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'BALT', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'BALT', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'UKCONT', Direction: 'InterCont' },
    { Load: 'BALT', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'BALT', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'BALT', Discharge: 'WAFR', Direction: 'Other' },
    { Load: 'BALT', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'BALT', Discharge: 'WMED', Direction: 'Other' },
    { Load: 'BENGAL', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'BENGAL', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'BENGAL', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'BENGAL', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'BENGAL', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'MEG', Direction: 'InterMEG' },
    { Load: 'BENGAL', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'BENGAL', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'BENGAL', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'BENGAL', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'BENGAL', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'BENGAL', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'BENGAL', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'BENGAL', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'WCIND', Direction: 'InterMEG' },
    { Load: 'BENGAL', Discharge: 'WCNA', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'BENGAL', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'BALT', Direction: 'Other' },
    { Load: 'BSEA', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'BSEA', Direction: 'InterMed' },
    { Load: 'BSEA', Discharge: 'CANARY', Direction: 'Other' },
    { Load: 'BSEA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'EMED', Direction: 'InterMed' },
    { Load: 'BSEA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'BSEA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'BSEA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'UKCONT', Direction: 'Other' },
    { Load: 'BSEA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'BSEA', Discharge: 'WAFR', Direction: 'Other' },
    { Load: 'BSEA', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'BSEA', Discharge: 'WMED', Direction: 'InterMed' },
    { Load: 'CANARY', Discharge: 'BALT', Direction: 'Other' },
    { Load: 'CANARY', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'BSEA', Direction: 'Other' },
    { Load: 'CANARY', Discharge: 'CANARY', Direction: 'Other' },
    { Load: 'CANARY', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'CANARY', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'EMED', Direction: 'Other' },
    { Load: 'CANARY', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'CANARY', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'CANARY', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'CANARY', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'CANARY', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'CANARY', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'CANARY', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'CANARY', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'UKCONT', Direction: 'Other' },
    { Load: 'CANARY', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'CANARY', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'CANARY', Discharge: 'WAFR', Direction: 'Other' },
    { Load: 'CANARY', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'CANARY', Discharge: 'WMED', Direction: 'Other' },
    { Load: 'CARIBS', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'CARIBS', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'CARIBS', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'CARIBS', Discharge: 'CARIBS', Direction: 'InterAmericas' },
    { Load: 'CARIBS', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'CARIBS', Discharge: 'LAKES', Direction: 'InterAmericas' },
    { Load: 'CARIBS', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'NBRAZ', Direction: 'InterAmericas' },
    { Load: 'CARIBS', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'NCSA', Direction: 'InterAmericas' },
    { Load: 'CARIBS', Discharge: 'PLATE', Direction: 'InterAmericas' },
    { Load: 'CARIBS', Discharge: 'RSEA', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'SAFR', Direction: 'Transatlantic' },
    { Load: 'CARIBS', Discharge: 'SBRAZ', Direction: 'InterAmericas' },
    { Load: 'CARIBS', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'CARIBS', Discharge: 'USEC', Direction: 'InterAmericas' },
    { Load: 'CARIBS', Discharge: 'USG', Direction: 'InterAmericas' },
    { Load: 'CARIBS', Discharge: 'WAFR', Direction: 'Transatlantic' },
    { Load: 'CARIBS', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'WCCA', Direction: 'West Coast' },
    { Load: 'CARIBS', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'CARIBS', Discharge: 'WCNA', Direction: 'West Coast' },
    { Load: 'CARIBS', Discharge: 'WCSAM', Direction: 'West Coast' },
    { Load: 'CARIBS', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'CCHINA', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'CCHINA', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'MEG', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'NASIA', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'NCHINA', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'SCHINA', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'SEASIA', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'WCIND', Direction: 'Other' },
    { Load: 'CCHINA', Discharge: 'WCNA', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'CCHINA', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'EAFR', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'EAFR', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'EAFR', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'EAFR', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'CCHINA', Direction: 'Aussie RV' },
    { Load: 'EAUS', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'MEG', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'NASIA', Direction: 'Aussie RV' },
    { Load: 'EAUS', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'NCHINA', Direction: 'Aussie RV' },
    { Load: 'EAUS', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'SCHINA', Direction: 'Aussie RV' },
    { Load: 'EAUS', Discharge: 'SEASIA', Direction: 'Aussie RV' },
    { Load: 'EAUS', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'WCIND', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'WCNA', Direction: 'Other' },
    { Load: 'EAUS', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'EAUS', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'BALT', Direction: 'Other' },
    { Load: 'EMED', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'BSEA', Direction: 'InterMed' },
    { Load: 'EMED', Discharge: 'CANARY', Direction: 'Other' },
    { Load: 'EMED', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'EMED', Direction: 'InterMed' },
    { Load: 'EMED', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'EMED', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'EMED', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'UKCONT', Direction: 'Other' },
    { Load: 'EMED', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'EMED', Discharge: 'WAFR', Direction: 'Other' },
    { Load: 'EMED', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'EMED', Discharge: 'WMED', Direction: 'InterMed' },
    { Load: 'Indonesia', Discharge: 'SCHINA', Direction: 'SE Asia RV' },
    { Load: 'Indonesia', Discharge: 'CCHINA', Direction: 'SE Asia RV' },
    { Load: 'Indonesia', Discharge: 'NCHINA', Direction: 'SE Asia RV' },
    { Load: 'Indonesia', Discharge: 'NASIA', Direction: 'SE Asia RV' },
    { Load: 'LAKES', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'LAKES', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'LAKES', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'LAKES', Discharge: 'CARIBS', Direction: 'InterAmericas' },
    { Load: 'LAKES', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'LAKES', Discharge: 'LAKES', Direction: 'InterAmericas' },
    { Load: 'LAKES', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'NBRAZ', Direction: 'InterAmericas' },
    { Load: 'LAKES', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'NCSA', Direction: 'InterAmericas' },
    { Load: 'LAKES', Discharge: 'PLATE', Direction: 'InterAmericas' },
    { Load: 'LAKES', Discharge: 'RSEA', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'SAFR', Direction: 'Transatlantic' },
    { Load: 'LAKES', Discharge: 'SBRAZ', Direction: 'InterAmericas' },
    { Load: 'LAKES', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'LAKES', Discharge: 'USEC', Direction: 'InterAmericas' },
    { Load: 'LAKES', Discharge: 'USG', Direction: 'InterAmericas' },
    { Load: 'LAKES', Discharge: 'WAFR', Direction: 'Transatlantic' },
    { Load: 'LAKES', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'WCCA', Direction: 'West Coast' },
    { Load: 'LAKES', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'LAKES', Discharge: 'WCNA', Direction: 'West Coast' },
    { Load: 'LAKES', Discharge: 'WCSAM', Direction: 'West Coast' },
    { Load: 'LAKES', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'MEG', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'BENGAL', Direction: 'InterMEG' },
    { Load: 'MEG', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'MEG', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'MEG', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'MEG', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'MEG', Direction: 'InterMEG' },
    { Load: 'MEG', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'MEG', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'MEG', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'RSEA', Direction: 'InterMEG' },
    { Load: 'MEG', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'MEG', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'MEG', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'MEG', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'MEG', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'WCIND', Direction: 'InterMEG' },
    { Load: 'MEG', Discharge: 'WCNA', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'MEG', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'CCHINA', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'MEG', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'NASIA', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'NCHINA', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'SCHINA', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'SEASIA', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'WCIND', Direction: 'Other' },
    { Load: 'NASIA', Discharge: 'WCNA', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'NASIA', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'NBRAZ', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'NBRAZ', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'NBRAZ', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'NBRAZ', Discharge: 'CARIBS', Direction: 'InterAmericas' },
    { Load: 'NBRAZ', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'NBRAZ', Discharge: 'LAKES', Direction: 'InterAmericas' },
    { Load: 'NBRAZ', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'NBRAZ', Direction: 'InterAmericas' },
    { Load: 'NBRAZ', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'NCSA', Direction: 'InterAmericas' },
    { Load: 'NBRAZ', Discharge: 'PLATE', Direction: 'InterAmericas' },
    { Load: 'NBRAZ', Discharge: 'RSEA', Direction: 'Transatlantic' },
    { Load: 'NBRAZ', Discharge: 'SAFR', Direction: 'Transatlantic' },
    { Load: 'NBRAZ', Discharge: 'SBRAZ', Direction: 'InterAmericas' },
    { Load: 'NBRAZ', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'NBRAZ', Discharge: 'USEC', Direction: 'InterAmericas' },
    { Load: 'NBRAZ', Discharge: 'USG', Direction: 'InterAmericas' },
    { Load: 'NBRAZ', Discharge: 'WAFR', Direction: 'Transatlantic' },
    { Load: 'NBRAZ', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'NBRAZ', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'NCHINA', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'CCHINA', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'MEG', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'NASIA', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'NCHINA', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'SCHINA', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'SEASIA', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'WCIND', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'WCNA', Direction: 'Other' },
    { Load: 'NCHINA', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'NCHINA', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'NCSA', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'NCSA', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'NCSA', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'NCSA', Discharge: 'CARIBS', Direction: 'InterAmericas' },
    { Load: 'NCSA', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'NCSA', Discharge: 'LAKES', Direction: 'InterAmericas' },
    { Load: 'NCSA', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'NBRAZ', Direction: 'InterAmericas' },
    { Load: 'NCSA', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'NCSA', Direction: 'InterAmericas' },
    { Load: 'NCSA', Discharge: 'PLATE', Direction: 'InterAmericas' },
    { Load: 'NCSA', Discharge: 'RSEA', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'SAFR', Direction: 'Transatlantic' },
    { Load: 'NCSA', Discharge: 'SBRAZ', Direction: 'InterAmericas' },
    { Load: 'NCSA', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'NCSA', Discharge: 'USEC', Direction: 'InterAmericas' },
    { Load: 'NCSA', Discharge: 'USG', Direction: 'InterAmericas' },
    { Load: 'NCSA', Discharge: 'WAFR', Direction: 'Transatlantic' },
    { Load: 'NCSA', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'WCCA', Direction: 'West Coast' },
    { Load: 'NCSA', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'NCSA', Discharge: 'WCNA', Direction: 'West Coast' },
    { Load: 'NCSA', Discharge: 'WCSAM', Direction: 'West Coast' },
    { Load: 'NCSA', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'PLATE', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'PLATE', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'PLATE', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'PLATE', Discharge: 'CARIBS', Direction: 'InterAmericas' },
    { Load: 'PLATE', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'PLATE', Discharge: 'LAKES', Direction: 'InterAmericas' },
    { Load: 'PLATE', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'NBRAZ', Direction: 'InterAmericas' },
    { Load: 'PLATE', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'NCSA', Direction: 'InterAmericas' },
    { Load: 'PLATE', Discharge: 'PLATE', Direction: 'Other' },
    { Load: 'PLATE', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'PLATE', Discharge: 'SAFR', Direction: 'Transatlantic' },
    { Load: 'PLATE', Discharge: 'SBRAZ', Direction: 'Other' },
    { Load: 'PLATE', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'PLATE', Discharge: 'USEC', Direction: 'InterAmericas' },
    { Load: 'PLATE', Discharge: 'USG', Direction: 'InterAmericas' },
    { Load: 'PLATE', Discharge: 'WAFR', Direction: 'Transatlantic' },
    { Load: 'PLATE', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'PLATE', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'RSEA', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'BENGAL', Direction: 'InterMEG' },
    { Load: 'RSEA', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'RSEA', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'RSEA', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'RSEA', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'MEG', Direction: 'InterMEG' },
    { Load: 'RSEA', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'RSEA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'RSEA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'RSEA', Direction: 'InterMEG' },
    { Load: 'RSEA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'RSEA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'RSEA', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'RSEA', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'RSEA', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'WCIND', Direction: 'InterMEG' },
    { Load: 'RSEA', Discharge: 'WCNA', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'RSEA', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'SAFR', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'SAFR', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'SAFR', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'SAFR', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'SBRAZ', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'SBRAZ', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'SBRAZ', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'SBRAZ', Discharge: 'CARIBS', Direction: 'InterAmericas' },
    { Load: 'SBRAZ', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'SBRAZ', Discharge: 'LAKES', Direction: 'InterAmericas' },
    { Load: 'SBRAZ', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'NBRAZ', Direction: 'InterAmericas' },
    { Load: 'SBRAZ', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'NCSA', Direction: 'InterAmericas' },
    { Load: 'SBRAZ', Discharge: 'PLATE', Direction: 'InterAmericas' },
    { Load: 'SBRAZ', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'SBRAZ', Discharge: 'SAFR', Direction: 'Transatlantic' },
    { Load: 'SBRAZ', Discharge: 'SBRAZ', Direction: 'InterAmericas' },
    { Load: 'SBRAZ', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'SBRAZ', Discharge: 'USEC', Direction: 'InterAmericas' },
    { Load: 'SBRAZ', Discharge: 'USG', Direction: 'InterAmericas' },
    { Load: 'SBRAZ', Discharge: 'WAFR', Direction: 'Transatlantic' },
    { Load: 'SBRAZ', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'SBRAZ', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'SCHINA', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'CCHINA', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'MEG', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'NASIA', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'NCHINA', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'SCHINA', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'SEASIA', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'WCIND', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'WCNA', Direction: 'Other' },
    { Load: 'SCHINA', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'SCHINA', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'CCHINA', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'MEG', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'NASIA', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'NCHINA', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'SCHINA', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'SEASIA', Direction: 'SE Asia RV' },
    { Load: 'SEASIA', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'WCIND', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'WCNA', Direction: 'Other' },
    { Load: 'SEASIA', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'SEASIA', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'BALT', Direction: 'InterCont' },
    { Load: 'UKCONT', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'BSEA', Direction: 'Other' },
    { Load: 'UKCONT', Discharge: 'CANARY', Direction: 'Other' },
    { Load: 'UKCONT', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'EMED', Direction: 'Other' },
    { Load: 'UKCONT', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'UKCONT', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'UKCONT', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'UKCONT', Direction: 'InterCont' },
    { Load: 'UKCONT', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'UKCONT', Discharge: 'WAFR', Direction: 'Other' },
    { Load: 'UKCONT', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'UKCONT', Discharge: 'WMED', Direction: 'Other' },
    { Load: 'USEC', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'USEC', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'USEC', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'USEC', Discharge: 'CARIBS', Direction: 'InterAmericas' },
    { Load: 'USEC', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'USEC', Discharge: 'LAKES', Direction: 'InterAmericas' },
    { Load: 'USEC', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'NBRAZ', Direction: 'InterAmericas' },
    { Load: 'USEC', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'NCSA', Direction: 'InterAmericas' },
    { Load: 'USEC', Discharge: 'PLATE', Direction: 'InterAmericas' },
    { Load: 'USEC', Discharge: 'RSEA', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'SAFR', Direction: 'Transatlantic' },
    { Load: 'USEC', Discharge: 'SBRAZ', Direction: 'InterAmericas' },
    { Load: 'USEC', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'USEC', Discharge: 'USEC', Direction: 'InterAmericas' },
    { Load: 'USEC', Discharge: 'USG', Direction: 'InterAmericas' },
    { Load: 'USEC', Discharge: 'WAFR', Direction: 'Transatlantic' },
    { Load: 'USEC', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'WCCA', Direction: 'West Coast' },
    { Load: 'USEC', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'USEC', Discharge: 'WCNA', Direction: 'West Coast' },
    { Load: 'USEC', Discharge: 'WCSAM', Direction: 'West Coast' },
    { Load: 'USEC', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'USG', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'USG', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'USG', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'USG', Discharge: 'CARIBS', Direction: 'InterAmericas' },
    { Load: 'USG', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'USG', Discharge: 'LAKES', Direction: 'InterAmericas' },
    { Load: 'USG', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'NBRAZ', Direction: 'InterAmericas' },
    { Load: 'USG', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'NCSA', Direction: 'InterAmericas' },
    { Load: 'USG', Discharge: 'PLATE', Direction: 'InterAmericas' },
    { Load: 'USG', Discharge: 'RSEA', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'SAFR', Direction: 'Transatlantic' },
    { Load: 'USG', Discharge: 'SBRAZ', Direction: 'InterAmericas' },
    { Load: 'USG', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'USG', Discharge: 'USEC', Direction: 'InterAmericas' },
    { Load: 'USG', Discharge: 'USG', Direction: 'InterAmericas' },
    { Load: 'USG', Discharge: 'WAFR', Direction: 'Transatlantic' },
    { Load: 'USG', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'WCCA', Direction: 'West Coast' },
    { Load: 'USG', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'USG', Discharge: 'WCNA', Direction: 'West Coast' },
    { Load: 'USG', Discharge: 'WCSAM', Direction: 'West Coast' },
    { Load: 'USG', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'WAFR', Discharge: 'BALT', Direction: 'Transatlantic' },
    { Load: 'WAFR', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'BSEA', Direction: 'Transatlantic' },
    { Load: 'WAFR', Discharge: 'CANARY', Direction: 'Transatlantic' },
    { Load: 'WAFR', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'WAFR', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'EMED', Direction: 'Transatlantic' },
    { Load: 'WAFR', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'WAFR', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'WAFR', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'WAFR', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'WAFR', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'WAFR', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'WAFR', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'WAFR', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'UKCONT', Direction: 'Transatlantic' },
    { Load: 'WAFR', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'WAFR', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'WAFR', Discharge: 'WAFR', Direction: 'Other' },
    { Load: 'WAFR', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'WAFR', Discharge: 'WMED', Direction: 'Transatlantic' },
    { Load: 'WAUS', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'CCHINA', Direction: 'Aussie RV' },
    { Load: 'WAUS', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'MEG', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'NASIA', Direction: 'Aussie RV' },
    { Load: 'WAUS', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'NCHINA', Direction: 'Aussie RV' },
    { Load: 'WAUS', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'SCHINA', Direction: 'Aussie RV' },
    { Load: 'WAUS', Discharge: 'SEASIA', Direction: 'Aussie RV' },
    { Load: 'WAUS', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'WCIND', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'WCNA', Direction: 'Other' },
    { Load: 'WAUS', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'WAUS', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'WCCA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'WCCA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'WCCA', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'WCCA', Direction: 'InterAmericas' },
    { Load: 'WCCA', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'WCCA', Discharge: 'WCSAM', Direction: 'InterAmericas' },
    { Load: 'WCCA', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'WCIND', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'WCIND', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'WCIND', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'WCIND', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'MEG', Direction: 'InterMEG' },
    { Load: 'WCIND', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'WCIND', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'WCIND', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'RSEA', Direction: 'InterMEG' },
    { Load: 'WCIND', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'WCIND', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'WCIND', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'WCIND', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'WCIND', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'WCIND', Direction: 'InterMEG' },
    { Load: 'WCIND', Discharge: 'WCNA', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'WCIND', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'BENGAL', Direction: 'Other' },
    { Load: 'WCNA', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'CCHINA', Direction: 'NOPAC RV' },
    { Load: 'WCNA', Discharge: 'EAFR', Direction: 'Other' },
    { Load: 'WCNA', Discharge: 'EAUS', Direction: 'Other' },
    { Load: 'WCNA', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'MEG', Direction: 'Other' },
    { Load: 'WCNA', Discharge: 'NASIA', Direction: 'NOPAC RV' },
    { Load: 'WCNA', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'NCHINA', Direction: 'NOPAC RV' },
    { Load: 'WCNA', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'WCNA', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'WCNA', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'SCHINA', Direction: 'NOPAC RV' },
    { Load: 'WCNA', Discharge: 'SEASIA', Direction: 'NOPAC RV' },
    { Load: 'WCNA', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'WAUS', Direction: 'Other' },
    { Load: 'WCNA', Discharge: 'WCCA', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'WCIND', Direction: 'Other' },
    { Load: 'WCNA', Discharge: 'WCNA', Direction: 'InterAmericas' },
    { Load: 'WCNA', Discharge: 'WCSAM', Direction: 'Backhaul' },
    { Load: 'WCNA', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'BALT', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'BSEA', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'CANARY', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'EMED', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'WCSAM', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'WCSAM', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'UKCONT', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'WAFR', Direction: 'Backhaul' },
    { Load: 'WCSAM', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'WCCA', Direction: 'InterAmericas' },
    { Load: 'WCSAM', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'WCSAM', Discharge: 'WCSAM', Direction: 'InterAmericas' },
    { Load: 'WCSAM', Discharge: 'WMED', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'BALT', Direction: 'Other' },
    { Load: 'WMED', Discharge: 'BENGAL', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'BSEA', Direction: 'InterMed' },
    { Load: 'WMED', Discharge: 'CANARY', Direction: 'Other' },
    { Load: 'WMED', Discharge: 'CARIBS', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'CCHINA', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'EAFR', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'EAUS', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'EMED', Direction: 'InterMed' },
    { Load: 'WMED', Discharge: 'LAKES', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'MEG', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'NASIA', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'NBRAZ', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'NCHINA', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'NCSA', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'PLATE', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'RSEA', Direction: 'Other' },
    { Load: 'WMED', Discharge: 'SAFR', Direction: 'Other' },
    { Load: 'WMED', Discharge: 'SBRAZ', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'SCHINA', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'SEASIA', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'UKCONT', Direction: 'Other' },
    { Load: 'WMED', Discharge: 'USEC', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'USG', Direction: 'Backhaul' },
    { Load: 'WMED', Discharge: 'WAFR', Direction: 'Other' },
    { Load: 'WMED', Discharge: 'WAUS', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'WCCA', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'WCIND', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'WCNA', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'WCSAM', Direction: 'Fronthaul' },
    { Load: 'WMED', Discharge: 'WMED', Direction: 'InterMed' },
    { Load: 'WW', Discharge: 'WW', Direction: 'Other' },
    { Load: 'WW', Discharge: 'Blank', Direction: 'Blank' },
    { Load: 'Blank', Discharge: 'WW', Direction: 'Blank' },
];

export const mappingHashTable = new Map(
    directionMapping.map((mapping) => {
        return [`${mapping.Load}|${mapping.Discharge}`, mapping.Direction];
    })
);
