import React from 'react';

interface MenuItemProps {
    testId?: string;
    onClick?: () => void;
    borderSide?: 'left' | 'right';
    children: React.ReactNode;
}

const MenuItem = ({ testId, onClick, children, borderSide }: MenuItemProps) => {
    let menuItemClass = 'menu-item';

    if (borderSide === 'left') {
        menuItemClass = `${menuItemClass} border-left`;
    } else {
        menuItemClass = `${menuItemClass} border-right`;
    }

    return (
        <div className={menuItemClass} onClick={onClick} data-cy={testId}>
            {children}
        </div>
    );
};

export default MenuItem;
