import { vesselCategoriesCustomSort } from '../models/VesselCategory';
import PriorityListComparator from '../../../../components/grid/comparators/PriorityListComparator';
import defaultSortComparator from '../../../../components/grid/comparators/DefaultSortComparator';

const CategoryComparator = (valueA, valueB, nodeA, nodeB) => {
    //Ignore group nodes because they don't have a datasetId
    if (nodeA.group) {
        return defaultSortComparator(valueA, valueB);
    }

    const nodeADatasetId = nodeA ? nodeA.data.datasetId : null;
    const nodeBDatasetId = nodeB ? nodeB.data.datasetId : null;

    const datasetId = nodeADatasetId || nodeBDatasetId;

    return PriorityListComparator(
        valueA,
        valueB,
        vesselCategoriesCustomSort(datasetId)
    );
};

export default CategoryComparator;
