import isClarksonsEntity from '../../../data/isClarksonsEntity';
import isPrivateEntity from '../../../data/isPrivateEntity';
import isPrivateField from '../../../data/isPrivateField';
import isRumouredField from '../../../data/isRumouredField';
import isOwnProgramEntity from '../../../data/isOwnProgramEntity';
import isRumouredEntity from '../../../data/isRumouredEntity';
import isNewEntity from '../../../data/isNewEntity';
import isIndividualEntity from '../../../data/isIndividualEntity';
import { FetchMappingGridDisplayValue } from '../../../data/mappings';
import { isNonEmptyArray } from '../../../../common/helpers/arrayHelpers';

// This is common across orders & fixtures currently, though if this changes it should be split in two.
// Although they are the basis of cell/row styles, when customisation is needed (e.g - privacy or status),
// the classes need to be replaced with custom ones (rather than being added-to), because excel export
// does not allow control of cascading/overriding only of the indicated style properties.
// Additionally styles need to be very specific, any overlapping styles will cause Excel to attempt to merge the styles.
// Excel appears to have a problem merging styles which results in unintended consequences that causes undesired effects.
const commonRowAndCellClassRules = {
    'grid-row-converted': ({ data, context }) =>
        data && context.shouldShowConvertedOrders && isConverted(data),
    'grid-row-new': ({ data, context }) => {
        return (
            data &&
            context.shouldHighlightNewOrders &&
            isNewEntity(data) &&
            data.state === 'New'
        );
    },
    'grid-group-row-clarksons': ({ node }) => node && node.group,
    'grid-row-faded-out': ({ colDef, data }) =>
        data &&
        isFadedOut(data) &&
        !isOwnProgramEntity(data) &&
        !isPrivateField(colDef, data) &&
        (!isPrivateEntity(data) || isIndividualEntity(data)) &&
        !isClarksonsEntity(data),
    'grid-row-own-program': ({ colDef, data }) =>
        data &&
        isOwnProgramEntity(data) &&
        !isPrivateField(colDef, data) &&
        (!isPrivateEntity(data) || isIndividualEntity(data)) &&
        !isClarksonsEntity(data),
    'grid-row-market-public': ({ colDef, data, context }) =>
        data &&
        (!isPrivateEntity(data) || isIndividualEntity(data)) &&
        !isPrivateField(colDef, data) &&
        !isClarksonsEntity(data) &&
        (!isOrder(context) || isMarketStatus(data)),
    'grid-row-clarksons-private': ({ colDef, data }) =>
        data &&
        (isPrivateField(colDef, data) || isPrivateEntity(data)) &&
        isClarksonsEntity(data) &&
        !isIndividualEntity(data),
    'grid-row-clarksons-public': ({ colDef, data }) =>
        data &&
        (!isPrivateEntity(data) || isIndividualEntity(data)) &&
        !isPrivateField(colDef, data) &&
        isClarksonsEntity(data),
    'grid-row-market-private': ({ data, context }) =>
        data &&
        isPrivateEntity(data) &&
        !isClarksonsEntity(data) &&
        !isIndividualEntity(data) &&
        (!isOrder(context) || isOffMarketStatus(data)),
    'grid-cell-private': ({ colDef, data }) =>
        data && isPrivateField(colDef, data) && !isClarksonsEntity(data),
    'grid-cell-rumoured': ({ colDef, data }) =>
        data &&
        isRumouredField(colDef, data) &&
        !isClarksonsEntity(data) &&
        (!isPrivateEntity(data) || isIndividualEntity(data)) &&
        !isPrivateField(colDef, data),
    /* The preceeding properties might apply to both the grid and the excel export */
    /* The following properties apply only to the excel export */
    'excel-default': ({ colDef, data }) =>
        data &&
        !isPrivateField(colDef, data) &&
        (!isPrivateEntity(data) || isIndividualEntity(data)) &&
        !isClarksonsEntity(data) &&
        !isOwnProgramEntity(data) &&
        !isFadedOut(data),
    'grid-row-deleted': ({ data }) =>
        data &&
        (data.state?.toLowerCase() === 'deleted' || data.state === 'Withdrawn'),
    'grid-row-on-subs': ({ data, context }) =>
        data &&
        isOnSubs(data) &&
        isOrder(context) &&
        !isClarksonsEntity(data) &&
        !isConverted(data) &&
        !isNonEmptyArray(data.clarksonsBrokers),
    'grid-row-clarksons-on-subs': ({ data, context }) =>
        data &&
        isOnSubs(data) &&
        isOrder(context) &&
        !isConverted(data) &&
        (isClarksonsEntity(data) || isNonEmptyArray(data.clarksonsBrokers)),
};

const statusClassRules = {
    'grid-cell-excel-default': (params) =>
        params.value ===
            FetchMappingGridDisplayValue('H', params.data.datasetId) ||
        params.value ===
            FetchMappingGridDisplayValue('S', params.data.datasetId),
    'grid-cell-excel-green': (params) =>
        params.value ===
        FetchMappingGridDisplayValue('C', params.data.datasetId),
    'grid-cell-excel-red': (params) =>
        params.value ===
        FetchMappingGridDisplayValue('F', params.data.datasetId),
    'grid-cell-excel-black': (params) =>
        params.value ===
        FetchMappingGridDisplayValue('X', params.data.datasetId),
    'grid-cell-own-program': ({ data }) =>
        isOwnProgramEntity(data) &&
        !isHighlightedStatus(data.status, data.datasetId) &&
        (!isPrivateEntity(data) || isIndividualEntity(data)) &&
        !isClarksonsEntity(data),
    /* The preceeding properties might apply to both the grid and the excel export */
    /* The following properties apply only to the excel export */
    'excel-status': (params) =>
        !isHighlightedStatus(params.value, params.data.datasetId) &&
        (!isPrivateEntity(params.data) || isIndividualEntity(params.data)) &&
        !isClarksonsEntity(params.data) &&
        !isOwnProgramEntity(params.data) &&
        !isFadedOut(params.data),
    'excel-status-clarksons-private': (params) =>
        isClarksonsEntity(params.data) &&
        isPrivateEntity(params.data) &&
        !isIndividualEntity(params.data) &&
        !isHighlightedStatus(params.value, params.data.datasetId),
    'excel-status-market-private': (params) =>
        isPrivateEntity(params.data) &&
        !isClarksonsEntity(params.data) &&
        !isIndividualEntity(params.data) &&
        !isHighlightedStatus(params.value, params.data.datasetId),
    'excel-status-clarksons-public': (params) =>
        isClarksonsEntity(params.data) &&
        (!isPrivateEntity(params.data) || isIndividualEntity(params.data)) &&
        !isHighlightedStatus(params.value, params.data.datasetId),
};

function isHighlightedStatus(value, datasetId) {
    return (
        value === FetchMappingGridDisplayValue('C', datasetId) ||
        value === FetchMappingGridDisplayValue('F', datasetId) ||
        value === FetchMappingGridDisplayValue('X', datasetId)
    );
}

function isConverted(data) {
    return data.state === 'Converted';
}

function isFadedOut(data) {
    return data && isRumouredEntity(data);
}

function isOnSubs(data) {
    return data.status === 'ON SUBS' || data.status === 'S';
}

function isOrder(context) {
    return context.gridId === 'orders';
}

function isMarketStatus(data) {
    return data.status === 'MARKET';
}

function isOffMarketStatus(data) {
    return data.status === 'OFF MARKET';
}

export { commonRowAndCellClassRules, statusClassRules };
