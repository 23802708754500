import client from './graphQlClient';
import { LOG_EXPORT_HISTORY_MUTATION } from './queries/AuditQueries';

class AuditHistoryApi {
    static logExportHistory = (variables) => {
        return new Promise(() => {
            client().mutate({
                mutation: LOG_EXPORT_HISTORY_MUTATION,
                variables,
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            });
        });
    };
}

export default AuditHistoryApi;
