import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from '../../actions/userActions';
import * as layoutActions from '../../actions/layoutActions';
import BasicGrid, {
    ACTION_OPEN_AUDIT,
    ACTION_REINSTATE,
    ACTION_VOYAGE_ACTIVITY,
    ACTION_COPY_FIXTURE_WITH_POS_INFO,
    ACTION_COPY_FIXTURE_WITH_CARGO_INFO,
} from '../grid/BasicGrid';
import { bindActionCreators } from 'redux';
import createGridCellValueSetter from '../grid/setters/createGridCellValueSetter';
import { currentGridCreationIndex } from '../../reducers/userSelectors';
import {
    REINSTATE_FIXTURE_MUTATION,
    DELETE_FIXTURE_MUTATION,
    MAKE_FIXTURE_PUBLIC_MUTATION,
    MAKE_FIXTURE_PRIVATE_MUTATION,
    UPDATE_FIXTURE_PRIVATE_FIELDS_MUTATION,
    SHARE_FIXTURE_MUTATION,
    SHARE_FIXTURE_TO_GROUP_MUTATION,
    CLONE_FIXTURE_WITH_POS_INFO_MUTATION,
    CLONE_FIXTURE_WITH_CARGO_INFO_MUTATION,
    createFixtureFieldMutation,
} from '../../api/queries/fixtures/FixtureQueries';
import FixtureHeadings from './FixtureHeadings';
import StripedLoader from '../common/StripedLoader';
import AddFixture from './AddFixture';
import graphQlClient from '../../api/graphQlClient';
import {
    FIXTURE_ADDED_SUBSCRIPTION,
    FIXTURE_UPDATED_SUBSCRIPTION,
    FIXTURE_SHARED_SUBSCRIPTION,
} from '../../api/subscriptions/fixtureSubscriptions';
import { FIXTURE_GRID_TYPE } from '../../constants/gridTypes';
import { commonRowAndCellClassRules } from '../grid/classRules/classRules';
import logger from '../../diagnostics/logging/logger';
import Axios from 'axios';
import { FixtureApi } from '../../api/fixtureApi';
import layoutsApi from '../../api/layoutsApi';
import moment from 'moment';
import Activity, { ActivityTabs } from '../ui/Activity';
import { CSSTransition } from 'react-transition-group';
import {
    fetchShowDeletedFixtures,
    fetchShowDeletedOrders,
    saveShowDeletedFixtures,
} from '../../localStorage';
import Toolbar from '../toolbar/Toolbar';
import MoreButton from '../toolbar/MoreButton';
import ReportsButton from '../toolbar/ReportsButton';
import DataButton from '../toolbar/DataButton';
import ExportButton from '../toolbar/ExportButton';
import SyncButton from '../toolbar/SyncButton';
import SwitchGroup from '../group/SwitchGroup';
import { KEY_CODES } from 'constant';
import { encodeDataSetId } from '../analytics/AdvancedAnalyticsPage';
import { getDataset } from '../../models/Datasets';
import MixpanelLogger from '../../diagnostics/logging/mixpanelLogger';
import { withApollo } from '@apollo/client/react/hoc';
import Filters from '../toolbar/filters/Filters';
import {
    getCreatedLayoutPropertiesForMixpanel,
    sendSaveLayoutMixpanelEvent,
} from '../../tools/LayoutTools';
import defaultGroupSortComparator from '../grid/comparators/DefaultGroupSortComparator';
import GroupingFixturesToolPanel from '../toolPanels/grouping/GroupingFixturesToolPanel';
import { getDefaultFixturesRowGroupsSorting } from '../../services/RowGroupsSortingService';
import { getDefaultFixturesRowGroups } from '../../services/RowGroupsService';
import cloneDeep from 'lodash/cloneDeep';
import { ChipOutlined } from '../ui/ChipOutlined';
import { compareColumnPropertyState } from '../common/gridColumnsUtils';
import LayoutsDropdownMenu from 'components/toolbar/LayoutsDropdownMenu';
import {
    changeCollapsedRowGroups,
    changeGridSettings,
} from 'store/feature/layoutsSlice';

const CancelToken = Axios.CancelToken;

const { CTRL, KEY_1, KEY_2, KEY_R, KEY_D, ALT } = KEY_CODES;

export class FixturesPage extends Component {
    getFixtureId = (fixture) => fixture.id || fixture.shadowId;

    constructor(props) {
        super(props);
        this.basicGridRef = React.createRef();
        this.showCondensedToggleRef = React.createRef();
        this.openSidebar = this.openSidebar.bind(this);
        this.handleSidebarClickOutside =
            this.handleSidebarClickOutside.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.setFilters = this.setFilters.bind(this);
        this.handleNewFixture = this.handleNewFixture.bind(this);

        this.onLayoutAdded = this.onLayoutAdded.bind(this);
        this.handleNewFixtureAdded = this.handleNewFixtureAdded.bind(this);
        this.handleFixtureUpdated = this.handleFixtureUpdated.bind(this);

        this.removeSubscriptions = this.removeSubscriptions.bind(this);
        this.setupSubscriptions = this.setupSubscriptions.bind(this);
        this.handleGridReady = this.handleGridReady.bind(this);

        this.handleGridOptionsChanged =
            this.handleGridOptionsChanged.bind(this);
        this.refreshLayout = this.refreshLayout.bind(this);
        this.resetAddedSessionIndex = this.resetAddedSessionIndex.bind(this);
        this.loadFixtures = this.loadFixtures.bind(this);

        this.makePublic = this.makePublic.bind(this);
        this.makePrivate = this.makePrivate.bind(this);
        this.makeUnRumoured = this.makeUnRumoured.bind(this);
        this.makeRumoured = this.makeRumoured.bind(this);
        this.delete = this.delete.bind(this);
        this.share = this.share.bind(this);
        this.shareToGroup = this.shareToGroup.bind(this);

        this.handleFixtureShared = this.handleFixtureShared.bind(this);
        this.updatePrivateFields = this.updatePrivateFields.bind(this);
        this.updateRumouredFields = this.updateRumouredFields.bind(this);

        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.exportSelectedChanged = this.exportSelectedChanged.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.toggleShowCondensed = this.toggleShowCondensed.bind(this);
        this.toggleShowDeleted = this.toggleShowDeleted.bind(this);
        this.toggleOnlyDisplayRumouredFixtures =
            this.toggleOnlyDisplayRumouredFixtures.bind(this);

        this.reinstate = this.reinstate.bind(this);
        this.handleOpenVoyageActivity =
            this.handleOpenVoyageActivity.bind(this);
        this.copyFixture = this.copyFixture.bind(this);
        this.copyFixtureWithCargoInfo =
            this.copyFixtureWithCargoInfo.bind(this);
        this.handleCanPerformAction = this.handleCanPerformAction.bind(this);

        this.toggleAddLayoutDialog = this.toggleAddLayoutDialog.bind(this);
        this.onResetLayout = this.onResetLayout.bind(this);

        this.trackAuditHistoryEvent = this.trackAuditHistoryEvent.bind(this);
        this.trackAnalyticEvent = this.trackAnalyticEvent.bind(this);

        //hold initial grid info in state so we don't keep re-rendering
        //the grid everytime they change a column/filter/sort/group
        const { creationIndex: createdIndex, exportParams } = this.props;

        this.state = {
            canRestoreFilters: false,
            filters: {},
            sidebarOpen: false,
            queuedUpdates: [],
            queuedAdds: [],
            gridReady: false,
            itemAddedSessionIndex: null,
            createdIndex,
            fixturesLoaded: false,
            export: exportParams,
            exportColIdsToSkip: ['privacyWarning', 'isSelected', 'privacy'],
            activeSlide: null,
            showCondensed: false,
            showDeleted: fetchShowDeletedFixtures(),
            selectedFixtures: [],
            numberOfYears: this.props.numberOfYears,
            showAddLayout: false,
            layoutWasReset: false,
            activity: {},
        };

        this.setValueSetter();

        this.cancelSource = null;
    }

    handleGridReady() {
        this.setState({
            gridReady: true,
        });

        this.setState((state) => {
            for (let index = 0; index < state.queuedAdds.length; index++) {
                const fixture = state.queuedAdds[index];
                this.handleNewFixtureAdded(fixture);
            }

            for (let index = 0; index < state.queuedUpdates.length; index++) {
                const fixture = state.queuedUpdates[index];
                this.handleFixtureUpdated(fixture);
            }

            return {
                queuedUpdates: [],
                queuedAdds: [],
            };
        });
    }

    removeSubscriptions() {
        if (this.fixtureAddedSubscription) {
            this.fixtureAddedSubscription.unsubscribe();
        }
        if (this.fixtureUpdatedSubscription) {
            this.fixtureUpdatedSubscription.unsubscribe();
        }
        if (this.fixtureSharedSubscription) {
            this.fixtureSharedSubscription.unsubscribe();
        }
    }

    setupSubscriptions() {
        this.removeSubscriptions();
        if (this.props.groupId) {
            this.fixtureAddedSubscription = graphQlClient()
                .subscribe({
                    query: FIXTURE_ADDED_SUBSCRIPTION,
                    variables: {
                        groupId: this.props.groupId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleNewFixtureAdded,
                    error(err) {
                        logger.warn(
                            'SUBSCRIPTION FIXTURE ERROR ADDED' + err.message
                        );
                    },
                });

            this.fixtureUpdatedSubscription = graphQlClient()
                .subscribe({
                    query: FIXTURE_UPDATED_SUBSCRIPTION,
                    variables: {
                        groupId: this.props.groupId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleFixtureUpdated,
                    error(err) {
                        logger.warn(
                            'SUBSCRIPTION FIXTURE ERROR UPDATED' + err.message
                        );
                    },
                });

            this.fixtureSharedSubscription = graphQlClient()
                .subscribe({
                    query: FIXTURE_SHARED_SUBSCRIPTION,
                    variables: {
                        groupId: this.props.groupId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleFixtureShared,
                    error(err) {
                        logger.warn(
                            'SUBSCRIPTION FIXTURE ERROR SHARED' + err.message
                        );
                    },
                });
        }
    }

    componentDidMount() {
        if (this.props.groupId) {
            this.loadFixtures();
        }

        this.setupSubscriptions();
    }

    createDataResetState() {
        return {
            loadingFixtures: false,
            fixturesLoaded: false,
            fixturesLoadedDatasetId: null,
            fixtureLoadingError: null,
            fixtures: null,
        };
    }

    get reportedDateCutOff() {
        if (this.state.numberOfYears === 0) {
            return moment().subtract(100, 'years').startOf('day').toDate();
        } else {
            return moment()
                .subtract(this.state.numberOfYears, 'years')
                .startOf('day')
                .toDate();
        }
    }

    isFixtureOutsideDisplayedDataRange(fixture) {
        return moment(fixture.reportedDate).isBefore(this.reportedDateCutOff);
    }

    loadFixtures() {
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }

        if (this.props.gridData) {
            this.setState(this.createDataResetState());
            return;
        }

        this.cancelSource = CancelToken.source();

        this.setState((state, props) => {
            FixtureApi.fetchFixtures(
                this.props.groupId,
                this.state.numberOfYears,
                this.cancelSource.token,
                this.state.showDeleted,
                this.props.selectedLayout.fixtures.currentOptions
                    .shouldOnlyDisplayRumouredFixtures
            ).then(
                (results) => {
                    this.setState({
                        loadingFixtures: false,
                        fixturesLoaded: true,
                        fixturesLoadedDatasetId: props.datasetId,
                        fixtureLoadingError: null,
                        fixtures: results,
                    });
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    this.setState({
                        loadingFixtures: false,
                        fixturesLoaded: false,
                        fixturesLoadedDatasetId: null,
                        fixtureLoadingError: error,
                        fixtures: null,
                    });
                }
            );
            return {
                loadingFixtures: true,
                fixturesLoaded: false,
                fixturesLoadedDatasetId: null,
                fixtureLoadingError: null,
                fixtures: null,
            };
        });
    }

    componentWillUnmount() {
        this.removeSubscriptions();
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) return false;
        }
        return true;
    }

    handleNewFixture(data, startEditing = true) {
        const addRowFunction = () => {
            if (this.basicGridRef.current) {
                this.basicGridRef.current.addRow(data, startEditing);
            }
        };

        if (this.props.userId === data.createdBy.userId) {
            // Only apply sorting indexes if the fixture was created by the user
            data.createdIndex = this.state.createdIndex;
            const newCreatedIndex = this.state.createdIndex + 1;

            const newSessionIndex =
                this.state.itemAddedSessionIndex !== null
                    ? this.state.itemAddedSessionIndex
                    : data.createdIndex;

            this.setState(
                {
                    createdIndex: newCreatedIndex,
                    itemAddedSessionIndex: newSessionIndex,
                },
                () => {
                    addRowFunction();
                    this.props.actions.userCreationIndexSet(newCreatedIndex);
                }
            );
        } else {
            addRowFunction();
        }
    }

    handleNewFixtureAdded(data) {
        const {
            data: { fixtureAdded: fixture },
        } = data;

        if (fixture.datasetId !== this.props.datasetId) {
            //do nothing
            return;
        }

        //let the basic grid work out what to do with it
        if (this.state.gridReady) {
            this.handleNewFixture(fixture, false);
        } else {
            this.setState((state) => {
                return {
                    queuedAdds: [...state.queuedAdds, data],
                };
            });
        }
    }

    handleFixtureUpdated(data) {
        const {
            data: { fixtureUpdated: fixture },
        } = data;
        if (fixture.datasetId !== this.props.datasetId) {
            //do nothing
            return;
        }

        if (this.isFixtureOutsideDisplayedDataRange(fixture)) {
            this.basicGridRef.current.rowDeleted(fixture);
            return;
        }

        //let the basic grid work out what to do with it
        if (this.state.gridReady) {
            if (fixture.state === 'deleted' && !this.state.showDeleted) {
                this.basicGridRef.current.rowDeleted(fixture);
            } else {
                this.basicGridRef.current.rowUpdated(fixture);
            }
        } else {
            this.setState((state) => {
                return {
                    queuedUpdates: [...state.queuedUpdates, data],
                };
            });
        }
    }

    handleFixtureShared(data) {
        const {
            data: { fixtureShared: fixture },
        } = data;

        this.basicGridRef.current.rowUpdated(fixture);
    }

    handleOpenAuditActivity = () => {
        this.toggleActivity({ selectedTab: ActivityTabs.AUDIT });
    };

    handleOpenVoyageActivity() {
        this.toggleActivity({ selectedTab: ActivityTabs.VOYAGE_ACTIVITY });
    }

    setFilters(filters) {
        this.basicGridRef.current.setFilterOptions(filters);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.datsetId !== prevProps.datasetId ||
            this.props.selectedLayout.selectedLayoutId !==
                prevProps.selectedLayout.selectedLayoutId
        ) {
            this.resetRowGroups();
        }

        if (
            this.props.groupId !== prevProps.groupId ||
            this.props.numberOfYears !== prevProps.numberOfYears ||
            this.state.numberOfYears !== prevState.numberOfYears ||
            this.props.datasetId !== prevProps.datasetId ||
            this.state.layoutWasReset ||
            this.props.selectedLayout.fixtures.currentOptions
                .shouldOnlyDisplayRumouredFixtures !==
                prevProps.selectedLayout.fixtures.currentOptions
                    .shouldOnlyDisplayRumouredFixtures
        ) {
            const newState = {
                layoutWasReset: false,
                queuedAdds: [],
                queuedUpdates: [],
                ...this.createDataResetState(),
            };

            this.setState(newState);

            this.setupSubscriptions();

            this.setValueSetter();
            this.loadFixtures();
        } else if (this.exportSelectedChanged()) {
            this.setState({ export: this.props.exportParams });
        }
    }

    resetRowGroups = () => {
        const defaultRowGroups = getDefaultFixturesRowGroups(
            this.props.datasetId
        );
        const currentRowGroups =
            this.props.selectedLayout.fixtures.currentOptions.rowGroups;

        const rowGroupsToAdd = defaultRowGroups.filter(
            (defaultRowGroup) =>
                !currentRowGroups
                    .map((currentRowGroup) => currentRowGroup.colId)
                    .includes(defaultRowGroup.colId)
        );
        const rowGroupColdIdsToRemove = currentRowGroups
            .filter(
                (currentRowGroup) =>
                    !defaultRowGroups
                        .map((defaultRowGroup) => defaultRowGroup.colId)
                        .includes(currentRowGroup.colId)
            )
            .map((rowGroup) => rowGroup.colId);

        if (rowGroupsToAdd.length > 0 || rowGroupColdIdsToRemove.length > 0) {
            let resultRowGroups = currentRowGroups.concat(rowGroupsToAdd);

            const defaultRowGroupSorting = getDefaultFixturesRowGroupsSorting(
                this.props.datasetId,
                this.props.selectedLayout.commonSettings.directionLogic
                    .currentState
            );
            let resultRowGroupSorting = cloneDeep(
                this.props.selectedLayout.fixtures.currentOptions
                    .rowGroupsSorting
            );

            for (const rowGroup of rowGroupsToAdd) {
                const rowGroupSortingToAdd = defaultRowGroupSorting.find(
                    (groupSorting) => groupSorting.colId === rowGroup.colId
                );

                if (rowGroupSortingToAdd) {
                    resultRowGroupSorting.push(rowGroupSortingToAdd);
                }
            }

            resultRowGroups = resultRowGroups.filter(
                (rowGroup) => !rowGroupColdIdsToRemove.includes(rowGroup.colId)
            );
            resultRowGroupSorting = resultRowGroupSorting.filter(
                (rowGroupSorting) =>
                    !rowGroupColdIdsToRemove.includes(rowGroupSorting.colId)
            );

            this.handleGridOptionsChanged({
                rowGroupsSorting: resultRowGroupSorting,
                rowGroups: resultRowGroups,
            });
        }
    };

    exportSelectedChanged = () =>
        this.props.exportParams.fixtures.length !==
            this.state.export.fixtures.length ||
        this.props.exportParams.orders.length !==
            this.state.export.orders.length;

    setValueSetter() {
        const { userId, username, groupId } = this.props;
        this.fixtureValueSetter = createGridCellValueSetter({
            username,
            userId,
            groupId,
            createMutation: createFixtureFieldMutation,
        });
    }

    openSidebar() {
        this.setState({
            sidebarOpen: true,
        });
    }

    clearFilters() {
        if (this.basicGridRef.current) {
            this.basicGridRef.current.clearFilters();
        }
    }

    refreshLayout() {
        this.resetAddedSessionIndex(true);
    }

    onLayoutAdded(newLayout) {
        const orderGridOptions = this.getMixpanelOrdersGridOptions();
        this.sendCreateLayoutMixpanelEvent(
            newLayout.name,
            'Order',
            newLayout.orders,
            newLayout.commonSettings.directionLogic,
            newLayout.commonSettings.quantityFormat,
            orderGridOptions
        );

        const localGridOptions = this.getMixpanelGridOptions();
        this.sendCreateLayoutMixpanelEvent(
            newLayout.name,
            'Fixture',
            newLayout.fixtures,
            newLayout.commonSettings.directionLogic,
            newLayout.commonSettings.quantityFormat,
            localGridOptions
        );
    }

    getCurrentLayout = () => {
        return this.props.allLayouts.find(
            (l) => l.id === this.props.selectedLayout.selectedLayoutId
        );
    };

    toggleAddLayoutDialog() {
        this.setState((prevState) => ({
            showAddLayout: !prevState.showAddLayout,
        }));
    }

    resetAddedSessionIndex(forceRefreshGrid = false) {
        if (this.state.itemAddedSessionIndex !== null) {
            this.setState(
                {
                    itemAddedSessionIndex: null,
                },
                () => {
                    if (this.basicGridRef.current) {
                        this.basicGridRef.current.refreshRowModel();
                    }
                }
            );
        } else if (forceRefreshGrid && this.basicGridRef.current) {
            this.basicGridRef.current.refreshRowModel();
        }
    }

    handleSidebarClickOutside() {
        this.setState({
            sidebarOpen: false,
        });
    }

    makePublic(fixture) {
        this.props.client
            .mutate({
                mutation: MAKE_FIXTURE_PUBLIC_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: fixture.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const updatedFixture = result.data.makeFixturePublic;
                fixture.privacy = updatedFixture.privacy;
                fixture.privateFields = updatedFixture.privateFields;
                fixture.lastUpdatedDateTimeByUser =
                    updatedFixture.lastUpdatedDateTimeByUser;
                fixture.lastUpdatedByUser = updatedFixture.lastUpdatedByUser;
                this.basicGridRef.current.rowUpdated(fixture);
            });
    }

    makePrivate(fixture) {
        this.props.client
            .mutate({
                mutation: MAKE_FIXTURE_PRIVATE_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: fixture.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const updatedFixture = result.data.makeFixturePrivate;
                fixture.privacy = updatedFixture.privacy;
                fixture.privateFields = updatedFixture.privateFields;
                fixture.lastUpdatedDateTimeByUser =
                    updatedFixture.lastUpdatedDateTimeByUser;
                fixture.lastUpdatedByUser = updatedFixture.lastUpdatedByUser;
                this.basicGridRef.current.rowUpdated(fixture);
            });
    }

    makeUnRumoured(fixture) {
        const variables = {
            groupId: this.props.groupId,
            id: fixture.id,
        };

        FixtureApi.makeUnRumoured(this.props.client, variables).then(
            (updatedFixture) => {
                fixture.rumoured = updatedFixture.rumoured;
                fixture.rumouredFields = updatedFixture.rumouredFields;
                fixture.lastUpdatedDateTimeByUser =
                    updatedFixture.lastUpdatedDateTimeByUser;
                fixture.lastUpdatedByUser = updatedFixture.lastUpdatedByUser;

                if (
                    !this.props.selectedLayout.fixtures.currentOptions
                        .shouldOnlyDisplayRumouredFixtures
                ) {
                    this.basicGridRef.current.rowUpdated(fixture);
                } else {
                    this.basicGridRef.current.rowDeleted(fixture);
                }
            }
        );
    }

    makeRumoured(fixture) {
        const variables = {
            groupId: this.props.groupId,
            id: fixture.id,
        };

        FixtureApi.makeRumoured(this.props.client, variables).then(
            (updatedFixture) => {
                fixture.rumoured = updatedFixture.rumoured;
                fixture.rumouredFields = updatedFixture.rumouredFields;
                fixture.lastUpdatedDateTimeByUser =
                    updatedFixture.lastUpdatedDateTimeByUser;
                fixture.lastUpdatedByUser = updatedFixture.lastUpdatedByUser;
                this.basicGridRef.current.rowUpdated(fixture);
            }
        );
    }

    reinstate(fixture) {
        this.props.client
            .mutate({
                mutation: REINSTATE_FIXTURE_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: fixture.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const updatedFixture = result.data.reinstateFixture;
                fixture.state = updatedFixture.state;
                fixture.lastUpdatedDateTimeByUser =
                    updatedFixture.lastUpdatedDateTimeByUser;
                fixture.lastUpdatedByUser = updatedFixture.lastUpdatedByUser;
                this.basicGridRef.current.rowUpdated(fixture);
            });
    }

    copyFixture(fixture) {
        this.props.client
            .mutate({
                mutation: CLONE_FIXTURE_WITH_POS_INFO_MUTATION,
                variables: {
                    id: fixture.id,
                    groupId: this.props.groupId,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const newFixture = result.data.cloneFixtureWithPosInfo;

                this.logCopyFixture(newFixture, 'Copied with position info');
                this.basicGridRef.current.addRow(newFixture);
            });
    }

    copyFixtureWithCargoInfo(fixture) {
        this.props.client
            .mutate({
                mutation: CLONE_FIXTURE_WITH_CARGO_INFO_MUTATION,
                variables: {
                    id: fixture.id,
                    groupId: this.props.groupId,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const newFixture = result.data.cloneFixtureWithCargoInfo;

                this.logCopyFixture(newFixture, 'Copied with Cargo info');
                this.basicGridRef.current.addRow(newFixture);
            });
    }

    logCopyFixture(newFixture, actionName) {
        const { id, groupOnly, privacy, groupName } = newFixture;

        MixpanelLogger.trackNewFixtureEvent(
            id,
            groupOnly,
            privacy,
            groupName,
            actionName
        );
    }

    delete(fixture) {
        this.props.client
            .mutate({
                mutation: DELETE_FIXTURE_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: fixture.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                if (this.state.showDeleted) {
                    this.basicGridRef.current.rowUpdated(
                        result.data.deleteFixture
                    );
                } else {
                    this.basicGridRef.current.rowDeleted(
                        result.data.deleteFixture
                    );
                }
            });
    }

    share(fixture) {
        this.props.client
            .mutate({
                mutation: SHARE_FIXTURE_MUTATION,
                variables: {
                    id: fixture.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                this.basicGridRef.current.rowUpdated(result.data.shareFixture);
            });
    }

    shareToGroup(fixture) {
        this.props.client
            .mutate({
                mutation: SHARE_FIXTURE_TO_GROUP_MUTATION,
                variables: {
                    id: fixture.id,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                if (this.props.groupId !== fixture.groupId) {
                    this.basicGridRef.current.rowDeleted(
                        result.data.shareFixtureToGroup
                    );
                } else {
                    this.basicGridRef.current.rowUpdated(
                        result.data.shareFixtureToGroup
                    );
                }
            });
    }

    updatePrivateFields(fixture, fields) {
        this.props.client
            .mutate({
                mutation: UPDATE_FIXTURE_PRIVATE_FIELDS_MUTATION,
                variables: {
                    groupId: this.props.groupId,
                    id: fixture.id,
                    fields: fields,
                },
                context: {
                    errorHandling: {
                        unknownResult: {
                            resolution: {
                                isSafeToRetry: true,
                            },
                        },
                    },
                },
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                const updatedFixture = result.data.updateFixturePrivateFields;
                fixture.privateFields = updatedFixture.privateFields;
                fixture.privacy = updatedFixture.privacy;
                fixture.lastUpdatedDateTimeByUser =
                    updatedFixture.lastUpdatedDateTimeByUser;
                fixture.lastUpdatedByUser = updatedFixture.lastUpdatedByUser;
                this.basicGridRef.current.rowUpdated(fixture);
            });
    }

    updateRumouredFields(fixture, fields) {
        const variables = {
            groupId: this.props.groupId,
            id: fixture.id,
            fields: fields,
        };

        FixtureApi.updateRumouredFields(this.props.client, variables).then(
            (updatedFixture) => {
                fixture.rumoured = updatedFixture.ruomured;
                fixture.rumouredFields = updatedFixture.rumouredFields;
                fixture.lastUpdatedDateTimeByUser =
                    updatedFixture.lastUpdatedDateTimeByUser;
                fixture.lastUpdatedByUser = updatedFixture.lastUpdatedByUser;

                if (
                    !this.props.selectedLayout.fixtures.currentOptions
                        .shouldOnlyDisplayRumouredFixtures
                ) {
                    this.basicGridRef.current.rowUpdated(fixture);
                } else {
                    this.basicGridRef.current.rowDeleted(fixture);
                }
            }
        );
    }

    handleSelectionChanged() {
        setTimeout(() => {
            if (this.basicGridRef.current) {
                const selected = this.basicGridRef.current.getSelectedNodes();

                // WARNING: react-grid exports only visible nodes, and the only indication I found that a node is not rendered is rowTop === null, this is not documented behaviour
                const visibleSelected = selected.filter(
                    ({ rowTop }) => rowTop !== null
                );

                const containsPrivate = visibleSelected.some(
                    (node) => node.data.privacy
                );

                const containsPublic = visibleSelected.some(
                    (node) => !node.data.privacy
                );

                this.setState({
                    selectedFixtures: visibleSelected,
                });

                if (this.props.onSelectionChanged) {
                    this.props.onSelectionChanged({
                        ...this.state.export,
                        includesNonConfidentialFixtures: containsPublic,
                        includesConfidentialFixtures: containsPrivate,
                        fixtures: visibleSelected,
                    });
                }
            }
        });
    }

    exportToExcel(shouldIncludeRow, shouldExportGroupHeadings) {
        if (this.basicGridRef.current) {
            this.basicGridRef.current.export(
                shouldIncludeRow,
                shouldExportGroupHeadings
            );
        }
    }

    getAuditParams() {
        let displayedColumns = [];
        const { filterOptions } =
            this.props.selectedLayout.fixtures.currentOptions;
        const { groupId } = this.props;

        if (this.basicGridRef.current) {
            displayedColumns = this.basicGridRef.current.getDisplayedColumns();
        }

        return {
            groupId,
            filterOptions,
            displayedColumns,
        };
    }

    handleCanPerformAction(action) {
        switch (action) {
            case ACTION_OPEN_AUDIT:
            case ACTION_REINSTATE:
            case ACTION_VOYAGE_ACTIVITY:
                return true;
            case ACTION_COPY_FIXTURE_WITH_POS_INFO:
                return this.props.canEdit;
            case ACTION_COPY_FIXTURE_WITH_CARGO_INFO:
                return this.props.canEdit;
            default:
                return false;
        }
    }

    toggleSlide = (name) => {
        const isOpen = this.state.activeSlide === name;
        const { selectedTab } = this.state.activity;

        if (!isOpen && selectedTab === ActivityTabs.ANALYTICS) {
            this.trackAnalyticEvent();
        }

        this.setState({ activeSlide: isOpen ? null : name });
    };

    toggleActivity = ({ selectedTab = ActivityTabs.ANALYTICS } = {}) => {
        this.setState(
            {
                activity: {
                    selectedTab,
                },
            },
            () => this.toggleSlide('activity')
        );
    };

    onResetLayout() {
        this.setState({ layoutWasReset: true });
    }

    getSelectedCount() {
        let total =
            this.state.export.orders.length + this.state.export.fixtures.length;

        let bothText =
            this.state.export.orders.length !== 0 &&
            this.state.export.fixtures.length !== 0
                ? 'BOTH '
                : '';

        return total !== 0 ? bothText + '(' + total + ')' : '';
    }

    toggleShowCondensed(showCondensed) {
        if (this.props.onToggleCondensedView) {
            this.props.onToggleCondensedView(showCondensed);
        }
    }

    toggleShowDeleted(showDeleted) {
        const { shouldOnlyDisplayRumouredFixtures } =
            this.props.selectedLayout.fixtures.currentOptions;
        saveShowDeletedFixtures(showDeleted);

        this.setState({ showDeleted }, () => {
            shouldOnlyDisplayRumouredFixtures
                ? this.handleGridOptionsChanged({
                      shouldOnlyDisplayRumouredFixtures: false,
                  })
                : this.loadFixtures();
        });

        MixpanelLogger.trackDataFilterToggle(
            'Fixtures',
            this.props.datasetId,
            this.getSelectedGroup().name,
            'Show Deleted',
            showDeleted
        );
    }

    toggleOnlyDisplayRumouredFixtures() {
        const { shouldOnlyDisplayRumouredFixtures } =
            this.props.selectedLayout.fixtures.currentOptions;

        const newValue = !shouldOnlyDisplayRumouredFixtures;
        this.handleGridOptionsChanged({
            shouldOnlyDisplayRumouredFixtures: newValue,
        });

        if (newValue) {
            saveShowDeletedFixtures(false);
            this.setState({ showDeleted: false });
        }

        MixpanelLogger.trackDataFilterToggle(
            'Fixtures',
            this.props.datasetId,
            this.getSelectedGroup().name,
            'Show Rumoured Only',
            newValue
        );
    }

    openAdvancedAnalytics = () => {
        const selectedGroup = this.getSelectedGroup();
        MixpanelLogger.trackOpenAdvancedAnalyticsEvent(selectedGroup.name);

        const token = encodeDataSetId(this.props.datasetId);
        const advancedAnalyticsUrl = `/analytics/${token}`;
        window.open(advancedAnalyticsUrl, '_blank');
    };

    hotkeys = () => [
        {
            keys: [CTRL, KEY_1, KEY_2],
            sub: (e) => {
                e.preventDefault();
                this.toggleShowCondensed(!this.props.useCondensedView);
            },
        },
        {
            keys: [CTRL, ALT, KEY_R],
            sub: (e) => {
                e.preventDefault();
                this.toggleActivity();
            },
        },
        {
            keys: [CTRL, ALT, KEY_D],
            sub: (e) => {
                e.preventDefault();
                this.openAdvancedAnalytics();
            },
        },
    ];

    trackAuditHistoryEvent = () => {
        const selectedFixture = this.state.selectedFixtures[0].data;
        const { id, groupOnly, privacy } = selectedFixture;
        const selectedGroup = this.getSelectedGroup();

        MixpanelLogger.trackOpenAuditHistoryEvent(
            id,
            'Fixture',
            groupOnly,
            privacy,
            selectedGroup.name
        );
    };

    trackAnalyticEvent = () => {
        const selectedGroup = this.getSelectedGroup();
        MixpanelLogger.trackOpenAnalyticsEvent(selectedGroup.name);
    };

    insertDefaultLayoutIfNotExist = () => {
        if (
            this.props.selectedLayout.fixtures.initialOptions.columnOptions
                .length === 0
        ) {
            const initialOptions =
                this.props.selectedLayout.fixtures.initialOptions;
            const columnState = this.basicGridRef.current.getColumnState();

            const fixturesLayout = {
                filters: { ...initialOptions.filterOptions },
                columnState: [...columnState],
                shouldOnlyDisplayRumouredFixtures:
                    initialOptions.shouldOnlyDisplayRumouredFixtures,
            };

            layoutsApi.persistFixturesLayout(
                this.props.datasetId,
                this.props.groups,
                this.props.allLayouts,
                this.props.selectedLayout.selectedLayoutId,
                fixturesLayout,
                this.props.actions.onUpdateLayouts,
                this.props.actions.onSelectLayout
            );
        }
    };

    getSelectedGroup = () => {
        const { groupId, groups } = this.props;
        const groupName = groups.filter((group) => group.id === groupId)[0]
            .name;

        return {
            id: groupId,
            name: groupName,
        };
    };

    setDataSize = (newDataSizeOption) => {
        this.setState({
            numberOfYears: newDataSizeOption.key,
        });

        this.props.onNumberOfYearsChanged &&
            this.props.onNumberOfYearsChanged({
                value: newDataSizeOption.key,
                label: newDataSizeOption.content,
            });

        MixpanelLogger.trackDataFilterToggle(
            'Fixtures',
            this.props.datasetId,
            this.getSelectedGroup().name,
            'Displaying (by time)',
            newDataSizeOption.content
        );
    };

    trackIfDeliveryTermChanged = (params) => {
        if (params.column.colId !== 'deliveryTerm') {
            return;
        }

        MixpanelLogger.trackDeliveryTermValueChanged(params.newValue);
    };

    render() {
        logger.trackPageView('Fixtures');

        if (!this.props.groupId) {
            return (
                <SwitchGroup
                    key="SwitchGroup"
                    isAdmin={this.props.isAdmin}
                    onChange={this.props.handleGroupChange}
                />
            );
        }

        const {
            sidebarOpen,
            itemAddedSessionIndex,
            loadingFixtures: loading,
            fixtureLoadingError: error,
            fixturesLoadedDatasetId,
            fixturesLoaded,
            fixtures,
            showAddLayout,
            activeSlide,
        } = this.state;

        const { datasetId } = this.props;
        const dataset = getDataset(datasetId);
        const showActivityTransition = activeSlide === 'activity';
        const showLayoutsManagerTransition = activeSlide === 'layoutManager';

        const renderContent = () => {
            if (error) {
                return <div className="ui error message">{error.message}</div>;
            }

            if (
                (fixturesLoadedDatasetId !== datasetId ||
                    loading ||
                    !fixturesLoaded) &&
                (fixturesLoadedDatasetId !== datasetId || !fixtures)
            ) {
                return <StripedLoader />;
            }

            const { filterOptions, columnOptions } =
                this.props.selectedLayout.fixtures.currentOptions;

            return (
                <BasicGrid
                    ref={this.basicGridRef}
                    token={datasetId}
                    createdIndex={this.state.createdIndex}
                    gridId="fixtures"
                    selectedGroup={this.getSelectedGroup()}
                    headings={FixtureHeadings.filter(
                        (h) => !dataset.nonAvailableFields.includes(h.colId)
                    )}
                    insertDefaultLayoutIfNotExist={
                        this.insertDefaultLayoutIfNotExist
                    }
                    rowData={fixtures}
                    rerenderParent={this.forceUpdate.bind(this)}
                    onGridReady={this.handleGridReady}
                    defaultSetter={(params) => {
                        this.fixtureValueSetter(params);
                        this.trackIfDeliveryTermChanged(params);
                    }}
                    getDataId={this.getFixtureId}
                    initialFilterOptions={filterOptions}
                    initialColumnOptions={columnOptions}
                    userGridOptionsUpdated={this.handleGridOptionsChanged}
                    defaultGroupSortComparator={(nodeA, nodeB) =>
                        defaultGroupSortComparator(
                            nodeA,
                            nodeB,
                            this.props.selectedLayout.fixtures.currentOptions
                                .rowGroupsSorting
                        )
                    }
                    sidebarOpen={sidebarOpen}
                    onSidebarClickOutside={this.handleSidebarClickOutside}
                    rowClassRules={commonRowAndCellClassRules}
                    itemAddedSessionIndex={itemAddedSessionIndex}
                    exportColIdsToSkip={this.state.exportColIdsToSkip}
                    onMakePublic={this.makePublic}
                    onMakePrivate={this.makePrivate}
                    onMakeUnRumoured={this.makeUnRumoured}
                    onMakeRumoured={this.makeRumoured}
                    onDelete={this.delete}
                    onShare={this.share}
                    onShareToGroup={this.shareToGroup}
                    onUpdatePrivateFields={this.updatePrivateFields}
                    onUpdateRumouredFields={this.updateRumouredFields}
                    onSelectionChanged={this.handleSelectionChanged}
                    showCondensed={this.props.useCondensedView}
                    onReinstate={this.reinstate}
                    onCopyFixture={this.copyFixture}
                    onCopyFixtureWithCargoInfo={this.copyFixtureWithCargoInfo}
                    canPerformAction={this.handleCanPerformAction}
                    onOpenAuditActivity={this.handleOpenAuditActivity}
                    onOpenVoyageActivity={this.handleOpenVoyageActivity}
                    isAdmin={this.props.isAdmin}
                    showTotalsInStatusBar={true}
                    singleView={this.props.singleView}
                    canEdit={this.props.canEdit}
                    hotkeys={this.hotkeys}
                    userTimezone={this.props.userTimezone}
                    currentDirectionLogic={
                        this.props.selectedLayout.commonSettings.directionLogic
                            .currentState
                    }
                    currentQuantityFormat={
                        this.props.selectedLayout.commonSettings.quantityFormat
                            .currentState
                    }
                    selectedLayoutId={
                        this.props.selectedLayout.selectedLayoutId
                    }
                    groupingToolPanel={GroupingFixturesToolPanel}
                    onCollapsedRowGroupsChanged={
                        this.handleOnCollapsedRowGroupsChanged
                    }
                    currentCollapsedRowGroups={
                        this.props.selectedLayout.fixtures.collapsedRowGroups
                    }
                />
            );
        };

        return (
            <div
                className="flex-container"
                style={{
                    height: '100%',
                }}
            >
                <div className="flex-item-fill" style={{ width: '100%' }}>
                    <Toolbar
                        leftMenu={[
                            this.props.canEdit ? (
                                <AddFixture
                                    key="AddFixture"
                                    groupId={this.props.groupId}
                                    onCompleted={this.handleNewFixture}
                                    disabled={!this.state.gridReady}
                                />
                            ) : null,
                            this.props.singleView ? (
                                <SwitchGroup
                                    isAdmin={this.props.isAdmin}
                                    onChange={this.props.handleGroupChange}
                                    key="SwitchGroup"
                                />
                            ) : null,
                            this.props.singleView ? (
                                <LayoutsDropdownMenu
                                    gridType="fixtures"
                                    onRevert={
                                        this.resetRowGroupColumnsOnRevertLayout
                                    }
                                />
                            ) : null,
                            <Filters
                                key="Filters"
                                headings={FixtureHeadings}
                                datasetId={datasetId}
                                initialFilters={
                                    this.props.selectedLayout.fixtures
                                        .initialOptions.filterOptions
                                }
                                currentFilters={
                                    this.props.selectedLayout.fixtures
                                        .currentOptions.filterOptions
                                }
                                currentOptions={
                                    this.props.selectedLayout.fixtures
                                        .currentOptions
                                }
                                onGridOptionsChanged={(
                                    currentLayoutOptions
                                ) => {
                                    this.props.dispatch(
                                        changeGridSettings({
                                            gridType: 'fixtures',
                                            gridSettings: currentLayoutOptions,
                                        })
                                    );
                                }}
                            />,
                        ]}
                        chips={[
                            this.state.showDeleted && (
                                <ChipOutlined
                                    key="showingDeleted"
                                    label="SHOWING DELETED"
                                    onDelete={() => {
                                        this.toggleShowDeleted(false);
                                    }}
                                />
                            ),
                            this.props.selectedLayout.fixtures.currentOptions
                                .shouldOnlyDisplayRumouredFixtures && (
                                <ChipOutlined
                                    key="displayRumouredOnly"
                                    label="DISPLAY RUMOURED ONLY"
                                    onDelete={() => {
                                        this.toggleOnlyDisplayRumouredFixtures(
                                            false
                                        );
                                    }}
                                />
                            ),
                        ]}
                        rightMenuIcons={[
                            this.state.export.fixtures.length > 0 &&
                                this.state.export.orders.length === 0 && (
                                    <ExportButton
                                        key="ExportButton"
                                        id="ExportButton"
                                        selectedCount={this.getSelectedCount()}
                                        onClick={this.props.onExportClicked}
                                    />
                                ),
                            <SyncButton
                                key="SyncButton"
                                id="SyncButton"
                                onClick={this.refreshLayout}
                            />,
                        ]}
                        rightMenuButtons={[
                            <ReportsButton
                                key="ReportsButton"
                                id="ReportsButton"
                                datasetId={datasetId}
                                onToggleAnalytics={this.toggleActivity}
                                openAdvancedAnalytics={
                                    this.openAdvancedAnalytics
                                }
                            />,
                            <DataButton
                                key="DataButton"
                                id="DataButton"
                                forFixtures={true}
                                onToggleShowDeleted={this.toggleShowDeleted}
                                showDeleted={this.state.showDeleted}
                                dataSizeOption={this.state.numberOfYears}
                                onDataSizeChanged={this.setDataSize}
                                shouldOnlyDisplayRumouredFixtures={
                                    this.props.selectedLayout.fixtures
                                        .currentOptions
                                        .shouldOnlyDisplayRumouredFixtures
                                }
                                onToggleOnlyDisplayRumouredFixtures={
                                    this.toggleOnlyDisplayRumouredFixtures
                                }
                            />,
                            <MoreButton
                                key="MoreButton"
                                id="MoreButton"
                                singleView={this.props.singleView}
                                showCondensed={this.props.useCondensedView}
                                fixturesPage
                                onToggleCondensed={this.toggleShowCondensed}
                                onOpenSideBar={this.openSidebar}
                            />,
                        ]}
                    />
                    <div>
                        <CSSTransition
                            classNames="activity"
                            in={showActivityTransition}
                            timeout={300}
                            unmountOnExit
                        >
                            <Activity
                                onClose={this.toggleActivity}
                                datasetId={datasetId}
                                forFixtures={true}
                                context={this.props.context}
                                selectedFixtures={this.state.selectedFixtures}
                                selectedTab={this.state.activity.selectedTab}
                                trackAuditHistoryEvent={
                                    this.trackAuditHistoryEvent
                                }
                                trackAnalyticEvent={this.trackAnalyticEvent}
                            />
                        </CSSTransition>
                    </div>

                    {renderContent.bind(this)()}
                </div>
            </div>
        );
    }

    handleGridOptionsChanged(params) {
        const {
            filterOptions,
            columnOptions,
            shouldResetSessionIndex,
            rowGroupsSorting,
            rowGroups,
            shouldOnlyDisplayRumouredFixtures,
        } = params;
        const currentOptions =
            this.props.selectedLayout.fixtures.currentOptions;

        const deliveryTermHideState = compareColumnPropertyState(
            'deliveryTerm',
            currentOptions.columnOptions,
            columnOptions,
            'hide'
        );
        if (deliveryTermHideState.hasChanged) {
            MixpanelLogger.trackDeliveryTermVisibilityChange(
                !deliveryTermHideState.newValue
            );
        }

        if (shouldResetSessionIndex) {
            this.resetAddedSessionIndex();
        }

        const currentLayoutOptions = {
            filterOptions: filterOptions ?? currentOptions.filterOptions,
            columnOptions: columnOptions ?? currentOptions.columnOptions,
            vesselOptions: currentOptions.vesselOptions,
            rowGroups: rowGroups ?? currentOptions.rowGroups,
            rowGroupsSorting:
                rowGroupsSorting ?? currentOptions.rowGroupsSorting,
            shouldOnlyDisplayRumouredFixtures:
                shouldOnlyDisplayRumouredFixtures ??
                currentOptions.shouldOnlyDisplayRumouredFixtures,
        };

        this.props.dispatch(
            changeGridSettings({
                gridType: 'fixtures',
                gridSettings: currentLayoutOptions,
            })
        );
    }

    saveLayout = (
        fixturesOptions,
        ordersOptions,
        collapsedRowGroups,
        commonSettings
    ) => {
        const fixturesLayout = {
            filters: { ...fixturesOptions.filterOptions },
            columnState: [...fixturesOptions.columnOptions],
            vesselOptions: fixturesOptions.vesselOptions,
            rowGroupsSorting: fixturesOptions.rowGroupsSorting,
            rowGroups: fixturesOptions.rowGroups,
            shouldOnlyDisplayRumouredFixtures:
                fixturesOptions.shouldOnlyDisplayRumouredFixtures,
            collapsedRowGroups: collapsedRowGroups,
        };
        const ordersLayout = {
            filters: { ...ordersOptions.filterOptions },
            columnState: [...ordersOptions.columnOptions],
            shouldHighlightNewOrders: ordersOptions.shouldHighlightNewOrders,
            shouldShowConvertedOrders: ordersOptions.shouldShowConvertedOrders,
            shouldOnlyDisplayRumouredOrders:
                ordersOptions.shouldOnlyDisplayRumouredOrders,
            rowGroupsSorting: ordersOptions.rowGroupsSorting,
            rowGroups: ordersOptions.rowGroups,
            collapsedRowGroups:
                this.props.selectedLayout.orders.collapsedRowGroups,
        };

        layoutsApi.persistLayout(
            this.props.datasetId,
            this.props.allLayouts,
            this.props.selectedLayout.selectedLayoutId,
            ordersLayout,
            fixturesLayout,
            commonSettings,
            this.props.actions.onUpdateLayouts
        );
    };

    sendSaveLayoutMixpanelEvent = (
        layoutName,
        gridType,
        layoutItems,
        localGridOptions
    ) => {
        sendSaveLayoutMixpanelEvent(
            layoutName,
            gridType,
            layoutItems,
            localGridOptions,
            this.props.selectedLayout.commonSettings.directionLogic
                .currentState,
            this.props.selectedLayout.commonSettings.quantityFormat
                .currentState,
            this.state.exportColIdsToSkip,
            this.props.datasetId
        );
    };

    getMixpanelGridOptions = () => {
        const gridOptions = {
            showDeleted: this.state.showDeleted,
            selectedNumberOfYears: this.state.numberOfYears,
        };

        return gridOptions;
    };

    getMixpanelOrdersGridOptions = () => {
        const gridOptions = {
            showDeletedAndWithdrawn: fetchShowDeletedOrders(),
        };

        return gridOptions;
    };

    handleOnSaveLayout = () => {
        const fixturesOptions =
            this.props.selectedLayout.fixtures.currentOptions;
        const ordersOptions = this.props.selectedLayout.orders.currentOptions;
        const commonSettings = {
            directionLogic:
                this.props.selectedLayout.commonSettings.directionLogic
                    .currentState,
            quantityFormat:
                this.props.selectedLayout.commonSettings.quantityFormat
                    .currentState,
            defaultType:
                this.props.selectedLayout.commonSettings.defaultType
                    .currentState,
        };

        this.saveLayout(
            fixturesOptions,
            ordersOptions,
            this.props.selectedLayout.fixtures.collapsedRowGroups,
            commonSettings
        );

        const { wereOptionsChanged: wereFixturesOptionsChanged } =
            this.props.selectedLayout.fixtures;
        const { wereOptionsChanged: wereOrdersOptionsChanged } =
            this.props.selectedLayout.orders;
        const currentLayout = this.getCurrentLayout();
        const isDirectionLogicChanged =
            !layoutActions.isCurrentDirectionLogicEqualToInitialOne(
                this.props.selectedLayout.commonSettings.directionLogic
            );
        const isQuantityFormatChanged =
            !layoutActions.isCurrentQuantityFormatEqualToInitialOne(
                this.props.selectedLayout.commonSettings.quantityFormat
            );

        const gridOptions = this.getMixpanelGridOptions();

        if (
            wereOrdersOptionsChanged ||
            isDirectionLogicChanged ||
            isQuantityFormatChanged
        ) {
            this.sendSaveLayoutMixpanelEvent(
                currentLayout.name,
                'Order',
                this.props.selectedLayout.orders,
                gridOptions
            );
        }

        if (
            wereFixturesOptionsChanged ||
            isDirectionLogicChanged ||
            isQuantityFormatChanged
        ) {
            this.sendSaveLayoutMixpanelEvent(
                currentLayout.name,
                'Fixture',
                this.props.selectedLayout.fixtures,
                gridOptions
            );
        }

        this.props.actions.onSaveLayout();
    };

    resetRowGroupColumnsOnRevertLayout = () => {
        // changes below required just for group values update
        // setTimeout is used to run the function after state being updated
        const currentRowGroupsIds =
            this.props.selectedLayout.fixtures.initialOptions.rowGroups
                .filter((rg) => rg.checked)
                .map((rg) => rg.colId);
        setTimeout(() =>
            this.basicGridRef.current.resetRowGroupColumns(currentRowGroupsIds)
        );
    };

    // TODO: refactor now
    handleOnCollapsedRowGroupsChanged = (collapsedRowGroups) => {
        this.props.dispatch(
            changeCollapsedRowGroups({
                gridType: 'fixtures',
                collapsedRowGroups: collapsedRowGroups,
            })
        );

        // next method should be invoked to recalculate the count of selected rows
        this.handleSelectionChanged();
    };

    sendCreateLayoutMixpanelEvent = (
        layoutName,
        gridType,
        reduxGridOptions,
        directionLogic,
        quantityFormat,
        gridOptions
    ) => {
        const layoutProperties = getCreatedLayoutPropertiesForMixpanel(
            reduxGridOptions,
            this.state.exportColIdsToSkip,
            gridType,
            directionLogic,
            quantityFormat,
            this.props.datasetId,
            gridOptions
        );
        MixpanelLogger.trackCreateLayoutEvent(layoutName, layoutProperties);
    };
}

const mapStateToProps = (state) => {
    const {
        group,
        groups,
        username,
        userId,
        headingDefaults,
        permissions,
        userTimezone,
    } = state.user;

    const props = {
        selectedLayout: state.layouts.selectedLayout,
        allLayouts: state.layouts.allLayouts,
        headingDefaults: headingDefaults,
        username: username,
        userId: userId,
        userTimezone: userTimezone,
        groupId: group ? group.id : null,
        datasetId: group ? group.datasetId : null,
        groups,
        creationIndex: currentGridCreationIndex(state, FIXTURE_GRID_TYPE),
        canEdit: permissions.some((p) => p.groupId === group.id && p.canWrite),
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            { ...userActions, ...layoutActions },
            dispatch
        ),
        dispatch,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        actions: {
            ...dispatchProps.actions,
            userChangedGridOptions: (filterOptions, columnOptions) => {
                if (stateProps.groupId) {
                    dispatchProps.actions.userChangedGridOptions(
                        stateProps.groupId,
                        filterOptions,
                        columnOptions,
                        FIXTURE_GRID_TYPE
                    );
                }
            },
            userCreationIndexSet: (index) => {
                dispatchProps.actions.userCreationIndexSet(
                    index,
                    FIXTURE_GRID_TYPE
                );
            },
        },
    };
};

FixturesPage = withApollo(FixturesPage, { withRef: true });

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    forwardRef: true,
})(FixturesPage);
