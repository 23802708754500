import React, { Component } from 'react';
import MultipleClarksonsBrokerSelect from '../inputs/MultipleClarksonsBrokerSelect';

class ClarksonsBrokerEditor extends Component {
    constructor(props) {
        super(props);

        this.brokerInputRef = React.createRef();
        this.focus = this.focus.bind(this);
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.brokerInputRef.current) {
            this.brokerInputRef.current.focus();
        }
    }

    handleOnTab() {
        this.props.api.tabToNextCell();
    }

    handleOnTabBack() {
        this.props.api.tabToPreviousCell();
    }

    handleOnEnter() {
        this.props.stopEditing();
    }

    getValue() {
        if (this.brokerInputRef.current.formattedValue.length > 0) {
            return this.brokerInputRef.current.formattedValue;
        } else {
            return null;
        }
    }

    isPopup() {
        return true;
    }

    render() {
        return (
            <MultipleClarksonsBrokerSelect
                ref={this.brokerInputRef}
                datasetId={this.props.context.datasetId}
                onTab={this.handleOnTab}
                onTabBack={this.handleOnTabBack}
                onEnter={this.handleOnEnter}
                onBrokerSelected={this.onBrokerSelected}
                shouldSelectItemOnTab={true}
                inputClass="ag-react-container"
                initialChar={this.props.charPress}
                value={this.props.value}
            />
        );
    }
}

export default ClarksonsBrokerEditor;
