import { gql } from '@apollo/client';

export interface GroupFragment {
    id: number;
    datasetId: number;
    name: string;
    fixtures: string;
    orders: string;
    commonSettings: {
        directionLogic: string;
        quantityFormat: string;
        defaultType: string;
    };
}

export const GROUP_FRAGMENT = gql`
    fragment GroupFragment on GroupType {
        id
        name
        datasetId
        datasetName
        createdBy {
            username
        }
        createdDate
        groupOnlyExpiryPeriod
    }
`;
