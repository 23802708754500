import React, { Component } from 'react';

class StatusRenderer extends Component {
    constructor(props) {
        super(props);

        this.generateState = this.generateState.bind(this);
        this.state = this.generateState(this.props);
    }

    generateState(params) {
        const { value, valueFormatted } = params;
        return {
            value,
            valueFormatted,
        };
    }

    refresh(params) {
        if (
            params.value !== this.state.value ||
            params.valueFormatted !== this.state.valueFormatted
        ) {
            this.setState(this.generateState(params));
            return true;
        }

        return false;
    }

    render() {
        const { node } = this.props;
        const { value, valueFormatted } = this.state;

        if (node && node.group) {
            return null;
        }

        const baseClassName = 'grid-cell-pill';
        let className = 'grid-cell-pill-blank';

        switch (value) {
            case 'C':
                className = 'grid-cell-pill-green';
                break;
            case 'F':
                className = 'grid-cell-pill-red';
                break;
            case 'X':
                className = 'grid-cell-pill-black';
                break;
            default:
                break;
        }

        return (
            <span className={`${baseClassName} ${className}`}>
                {valueFormatted}
            </span>
        );
    }
}

export default StatusRenderer;
