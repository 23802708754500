import { FIXTURE_GRID_TYPE } from '_legacy/constants/gridTypes';
import { getDataset } from '_legacy/models/Datasets';
import { OrderHeadings } from '_legacy/components/orders/OrderHeadings';
import FixtureHeadings from '_legacy/components/fixtures/FixtureHeadings';
import { getFormattedValue } from './FormattedGridValueService';
import CargoFormatter from '_legacy/components/grid/formatters/SingleCargoFormatter';

export const copyEntityToClipboard = (node, context, gridId) => {
    const isForFixture = gridId === FIXTURE_GRID_TYPE;

    copyToClipboard(
        isForFixture
            ? createReport(
                  requiredFixtureFields,
                  node,
                  context,
                  FixtureHeadings
              )
            : createReport(requiredOrderFields, node, context, OrderHeadings)
    );
};

const copyToClipboard = (dataString) => {
    navigator.clipboard.writeText(dataString).catch((err) => {
        console.log('Something went wrong', err);
    });
};

const createReport = (requiredFields, node, context, headings) => {
    let report = '';

    requiredFields.forEach((field) => {
        const heading = headings.find((h) => h.colId === field.name);

        report = addNewField(
            report,
            node,
            heading ?? { colId: field.name },
            context,
            field.label
        );
    });

    return report;
};

const addNewField = (report, node, heading, context, label = null) => {
    const formattedValue = getFormatedReportValue(node, heading, context);

    if (!formattedValue) {
        return report;
    }

    return report.concat(`${label ? `${label}: ` : ''}${formattedValue}\n`);
};

const getFormatedReportValue = (node, heading, context) => {
    if (!node.data[heading.field] && heading.colId !== 'vesselStatusRate') {
        return null;
    }

    switch (heading.colId) {
        case 'charterer':
        case 'thirdPartyCharterer':
            return getChartererReportValue(node, heading, context);
        case 'commission':
            return getCommissionReportValue(node, heading);
        case 'cargoParts':
            return getCargoReportValue(node, heading);
        case 'vesselStatusRate':
            return getVesselStatusRateReportValue(node, context);
        default:
            return getFormattedValue(heading, node, context);
    }
};

const getChartererReportValue = (node, heading, context) => {
    const dataset = getDataset(node.data.datasetId);
    const shouldChartererBeSpecified = !dataset.nonAvailableFields.some(
        (field) => field === heading.field
    );
    return shouldChartererBeSpecified
        ? getFormattedValue(heading, node, context)
        : null;
};

const getCommissionReportValue = (node, heading) => {
    const commission = node.data[heading.field];
    if (
        commission &&
        (commission.commissionValue || commission.brokersCommissionValue)
    ) {
        return !commission.brokersCommissionValue
            ? `${commission.commissionValue}% COMMISSION`
            : !commission.commissionValue
            ? `${commission.brokersCommissionValue}% BROKERAGE`
            : `${commission.commissionValue}% ADD + ${commission.brokersCommissionValue}% BROKERAGE`;
    }
    return '';
};

const getVesselStatusRateReportValue = (node, context) => {
    const rateFieldName = 'rate';
    const ratesFieldName = 'rates';

    const dataset = getDataset(node.data.datasetId);
    const shouldRateBeSpecified = !dataset.nonAvailableFields.some(
        (field) => field === rateFieldName
    );
    const rate = shouldRateBeSpecified
        ? getFormattedValue(
              { colId: rateFieldName, field: rateFieldName },
              node,
              context
          )
        : getFormattedValue(
              { colId: ratesFieldName, field: ratesFieldName },
              node,
              context
          );
    const status = statusMappings[node.data['status']];
    const vesselName = node.data.vessel?.name;
    return `${vesselName ? `Vessel ${vesselName} ` : ''}${status} ${
        rate ?? ''
    }`;
};

const getCargoReportValue = (node, heading) => {
    const cargoParts = node.data[heading.field];
    const text = [];

    if (!Array.isArray(cargoParts)) {
        text.push(CargoFormatter({ value: cargoParts }));
    } else {
        cargoParts.forEach((cargoPart) => {
            text.push(CargoFormatter({ value: cargoPart }));
        });
    }

    return text.join(' ');
};

const requiredOrderFields = [
    { name: 'charterer', label: null },
    { name: 'thirdPartyCharterer', label: null },
    { name: 'type', label: null },
    { name: 'cargoQuantity', label: null },
    { name: 'cargoParts', label: null },
    { name: 'loadLocationParts', label: 'Load' },
    { name: 'dischargeLocationParts', label: 'Discharge' },
    { name: 'laycan', label: 'L/C' },
    { name: 'commission', label: null },
    { name: 'comments', label: null },
];

const requiredFixtureFields = [
    { name: 'charterer', label: null },
    { name: 'thirdPartyCharterer', label: null },
    { name: 'type', label: null },
    { name: 'cargoQuantity', label: null },
    { name: 'cargoParts', label: null },
    { name: 'deliveryTerm', label: 'Delivery Term' },
    { name: 'deliveryLocationParts', label: 'Delivery' },
    { name: 'loadLocationParts', label: 'Load' },
    { name: 'dischargeLocationParts', label: 'Discharge' },
    { name: 'redeliveryLocationParts', label: 'Redelivery' },
    { name: 'laycan', label: 'L/C' },
    { name: 'commission', label: null },
    { name: 'vesselStatusRate', label: null },
    { name: 'comments', label: null },
];

const statusMappings = {
    S: 'ON SUBS',
    C: 'FIXED',
    X: 'CANCELLED',
    F: 'FAILED',
    H: 'HOLD',
};
