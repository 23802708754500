import { modalService } from 'common';
import ChangeOwnerConfirmation from '_legacy/components/grid/inputs/ChangeOwnerConfirmation';

class VesselPostProcessingService {
    constructor() {
        if (new.target === VesselPostProcessingService) {
            throw new TypeError('Cannot construct Abstract instances directly');
        }
    }

    shouldShowOwnerConfirmationWindow(props, vessel) {
        const reportedDatePlus30DaysTimestamp =
            new Date(props.data.reportedDate).getTime() +
            30 * 24 * 60 * 60 * 1000;
        const currentDateTimestamp = new Date().getTime();

        const oldOwner = this.getOldOwner(props);
        const newOwner = this.getNewOwner(vessel);

        return (
            oldOwner !== newOwner &&
            currentDateTimestamp > reportedDatePlus30DaysTimestamp
        );
    }

    showOwnerConfirmationDialog(props, vessel, keyCode, shift) {
        const newOwnerName = this.getNewOwnerName(vessel);
        const oldOwnerName = this.getOldOwnerName(props);

        const text = oldOwnerName
            ? `Are you sure you want to replace "${oldOwnerName}" with "${newOwnerName}" in the owner column?`
            : `Are you sure you want to auto populate the owner column with ${newOwnerName}?`;

        const content = (
            <ChangeOwnerConfirmation
                text={text}
                onClose={() => {
                    this.handleClose(props, vessel, keyCode, shift);
                    setTimeout(modalService.close);
                }}
                onConfirm={() => {
                    this.handleConfirm(props, vessel, keyCode, shift);
                    setTimeout(modalService.close);
                }}
            />
        );
        modalService.open({ content });
    }

    handleConfirm(props, vessel, keyCode, shift) {
        vessel.shouldUpdateOwner = true;
        props.onVesselSelected(vessel, keyCode, shift);
    }

    handleClose(props, vessel, keyCode, shift) {
        vessel.shouldUpdateOwner = false;
        props.onVesselSelected(vessel, keyCode, shift);
    }

    //Must be implemented in inheritors
    getOldOwner(props) {}
    getNewOwner(vessel) {}
    getOldOwnerName(props) {}
    getNewOwnerName(vessel) {}
}

export default VesselPostProcessingService;
