import { Component } from 'react';

class GlobalShortcut extends Component {
    constructor(props) {
        super(props);

        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleKeyDown(event) {
        if (this.props.keyMatcher && this.props.onMatch) {
            const match = this.props.keyMatcher(event);

            if (match && !event.repeat) {
                this.props.onMatch();
            }
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    render() {
        return this.props.children || null;
    }
}

export default GlobalShortcut;
