import { Warning } from '@mui/icons-material';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
} from '@mui/material';

interface SwitchLayoutDialogProps {
    open: boolean;
    onCancel: () => void;
    onContinue: () => void;
    onSaveAs: () => void;
}

const SwitchLayoutDialog: React.FC<SwitchLayoutDialogProps> = ({
    open,
    onCancel,
    onContinue,
    onSaveAs,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="confirm_change_dialog_title"
            aria-describedby="confirm_change_dialog_description"
        >
            <DialogTitle
                component={Stack}
                direction="row"
                id="confirm_change_dialog_title"
                sx={{ p: 'auto', justifyContent: 'center' }}
            >
                <Warning
                    color="error"
                    sx={{
                        display: 'block',
                        marginY: 'auto',
                        marginRight: '10px',
                    }}
                />
                <Box>Warning</Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="confirm_change_dialog_description"
                    sx={{ fontWeight: 'bold' }}
                >
                    Your layout has unsaved changes, if you continue your
                    changes will be lost
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onCancel}>
                    CANCEL
                </Button>
                <Button variant="contained" onClick={onContinue}>
                    CONTINUE
                </Button>
                <Button variant="outlined" onClick={onSaveAs}>
                    SAVE AS
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SwitchLayoutDialog;
