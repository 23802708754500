import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const GroupColumnValueSorting = ({
    colId,
    id,
    index,
    moveColumnValue,
    updateLayout,
}) => {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: colId,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            //Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveColumnValue(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ opacity }, drag, dragPreview] = useDrag({
        type: colId,
        item: () => {
            return { id, index };
        },
        end: () => {
            updateLayout();
        },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.3 : 1,
        }),
    });

    drag(drop(ref));

    return (
        <span
            className={`ag-column-drop-cell ag-column-drop-vertical-cell`}
            style={{ opacity }}
            ref={dragPreview}
            data-handler-id={handlerId}
        >
            <span
                className="ag-drag-handle ag-column-drop-cell-drag-handle ag-column-drop-vertical-cell-drag-handle"
                style={{ width: '100%' }}
                unselectable="on"
                ref={ref}
            >
                <span
                    className={`ag-icon ag-icon-grip`}
                    style={{ float: 'left' }}
                ></span>
                <span
                    className={`ag-column-drop-cell-text ag-column-drop-vertical-cell-text`}
                    style={{ color: 'black' }}
                >
                    {id}
                </span>
            </span>
        </span>
    );
};

export default GroupColumnValueSorting;
