import React from 'react';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { isNewThemeEnabled } from '_legacy/reducers/userSelectors';
import { connect } from 'react-redux';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const SwitchWithSubLabel = ({
    isChecked,
    onChange,
    label,
    subLabel,
    newThemeEnabled,
}) => {
    const labelClassName = newThemeEnabled
        ? 'new-ui-moreButtons-toggle-label'
        : 'moreButtons-toggle-label';

    const subLabelClassName = newThemeEnabled
        ? 'new-ui-moreButtons-toggle-sub-label'
        : 'moreButtons-toggle-sub-label';

    const theme = createTheme({
        overrides: {
            MuiSwitch: {
                switchBase: {
                    // Controls default (unchecked) color for the thumb
                    color: '#A3A3A3A3',
                },
                colorSecondary: {
                    '&$checked': {
                        // Controls checked color for the thumb
                        color: '#E1315A',
                    },
                },
                track: {
                    // Controls default (unchecked) color for the track
                    backgroundColor: '#A3A3A3A3',
                    '$checked$checked + &': {
                        // Controls checked color for the track
                        backgroundColor: '#E1315A',
                        opacity: 0.5,
                    },
                },
            },
        },
    });

    const style = {
        label: {
            width: '100%',
            height: '24px',
            margin: 0,
            paddingTop: '22px',
            paddingBottom: '24px',
        },
    };

    return (
        <>
            <ThemeProvider theme={theme}>
                <FormControlLabel
                    style={style.label}
                    label={
                        <div>
                            <Typography className={labelClassName}>
                                {label}
                            </Typography>
                            <Typography className={subLabelClassName}>
                                {subLabel}
                            </Typography>
                        </div>
                    }
                    labelPlacement="start"
                    control={
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width="100%"
                            marginBottom="15px"
                        >
                            <Switch
                                checked={isChecked}
                                onChange={onChange}
                                name="checked"
                            />
                        </Box>
                    }
                />
            </ThemeProvider>
        </>
    );
};

const mapStateToProps = (state) => ({
    newThemeEnabled: isNewThemeEnabled(state),
});

export default connect(mapStateToProps)(SwitchWithSubLabel);
