import React, { useMemo, Fragment } from 'react';
import FilterCheckbox from './FilterCheckbox';

const types = {
    section: ({ children, ...rest }) => (
        <div className="filter-section" {...rest}>
            {children.map(Element)}
        </div>
    ),
    checkbox: FilterCheckbox,
};

function Element(element, i) {
    let { type, component: Component, reactElement } = element;

    if (reactElement) return <Fragment key={i}>{reactElement}</Fragment>;

    Component = Component || types[type];

    return <Component {...element} key={i} />;
}

const iterate = (element) => {
    if (Array.isArray(element)) {
        return {
            type: 'section',
            children: element,
            original: element,
        };
    }

    if (React.isValidElement(element)) {
        return { reactElement: element };
    }

    if (element.children) {
        return {
            ...element,
            original: element,
            children: element.children.map(iterate),
        };
    }

    return element;
};

export const FilterGenerator = ({ elements, className, clear, ...rest }) => {
    elements = useMemo(() => elements.map(iterate), [elements]);

    return (
        <div className={`filter ${className}`} {...rest}>
            {elements.map(Element)}
            <div className="filter-footer">
                <button className="filter-clear" onClick={clear}>
                    Clear
                </button>
            </div>
        </div>
    );
};
