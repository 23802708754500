import { colors } from '../constants/styles';

const excelStyleRules = [
    {
        id: 'grid-row-clarksons-private',
        font: {
            color: { argb: colors.pink3 },
        },
        fill: {
            fgColor: { argb: colors.black },
        },
    },
    {
        id: 'grid-row-market-private',
        fill: {
            fgColor: { argb: colors.pink2 },
        },
    },
    {
        id: 'grid-row-clarksons-public',
        font: {
            color: { argb: colors.pink3 },
        },
    },
    {
        id: 'grid-cell-private',
        fill: {
            fgColor: { argb: colors.pink2 },
        },
    },
    {
        id: 'grid-cell-rumoured',
        font: {
            color: { argb: colors.grey5 },
        },
    },
    {
        id: 'grid-row-own-program',
        font: {
            color: { argb: colors.lightBlue },
        },
    },
    {
        id: 'grid-row-faded-out',
        font: {
            color: { argb: colors.grey5 },
        },
    },
    {
        id: 'grid-row-new',
        font: {
            color: { argb: colors.green },
        },
    },
    {
        id: 'grid-row-converted',
        font: {
            color: { argb: colors.blue },
        },
    },
    {
        id: 'grid-row-deleted',
        font: {
            strike: true,
        },
    },
    {
        id: 'grid-cell-excel-default',
        alignment: {
            horizontal: 'center',
        },
    },
    {
        id: 'grid-cell-excel-green',
        alignment: {
            horizontal: 'center',
        },
        font: {
            color: { argb: colors.white },
        },
        fill: {
            fgColor: { argb: colors.green },
        },
    },
    {
        id: 'grid-cell-excel-red',
        alignment: {
            horizontal: 'center',
        },
        font: {
            color: { argb: colors.white },
        },
        fill: {
            fgColor: { argb: colors.burgundy },
        },
    },
    {
        id: 'grid-cell-excel-black',
        alignment: {
            horizontal: 'center',
        },
        font: {
            color: { argb: colors.white },
        },
        fill: {
            fgColor: { argb: colors.black },
        },
    },
    {
        id: 'grid-row-clarksons-on-subs',
        font: {
            color: { argb: colors.red2 },
        },
        fill: {
            fgColor: { argb: colors.pink4 },
        },
    },
    {
        id: 'grid-row-on-subs',
        font: {
            color: { argb: colors.black },
        },
        fill: {
            fgColor: { argb: colors.pink4 },
        },
    },
    {
        id: 'excel-status',
        alignment: {
            horizontal: 'center',
        },
    },
    {
        id: 'excel-status-clarksons-private',
        alignment: {
            horizontal: 'center',
        },
        font: {
            color: { argb: colors.pink3 },
        },
        fill: {
            fgColor: { argb: colors.black },
        },
    },
    {
        id: 'excel-status-clarksons-public',
        alignment: {
            horizontal: 'center',
        },
        font: {
            color: { argb: colors.pink3 },
        },
    },
    {
        id: 'excel-status-market-private',
        alignment: {
            horizontal: 'center',
        },
        fill: {
            fgColor: { argb: colors.pink2 },
        },
    },
    {
        id: 'grid-cell-own-program',
        alignment: {
            horizontal: 'center',
        },
        font: {
            color: { argb: colors.lightBlue },
        },
    },
];

export default excelStyleRules;
