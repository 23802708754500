import React, { Component } from 'react';
import '../../../shared/columns/styles/PopupWindowInput.scss';
import Select from 'react-select';
import {
    getAvailableUnitsForDataset,
    getDurationUnitByValue,
    LEGS_VALUE,
    MONTHS,
} from '../../../shared/columns/models/DurationUnits';
import { DurationParser } from '../tools/DurationParser';
import {
    createDurationPart,
    createSeparatorDurationPart,
} from '../models/DurationPart';
import {
    KEY_BACKSPACE,
    KEY_TAB,
    KEY_ENTER,
    KEY_LEFT,
    KEY_RIGHT,
    KEY_PLUS,
    KEY_FORWARD_SLASH,
    KEY_EQUAL,
} from '../../../../constants/keyboardCodes';
import {
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
} from '../../../../models/common/EntityPart';
import { durationFormatterInstance } from '../formatters/DurationFormatter';
import { changeFocusOnKeyDown } from '_legacy/services/TrapFocusService';
import MinMaxDuration from './MinMaxDuration';
import { validateMinMaxDuration } from '../tools/MinMaxDurationValidator';

class DurationInput extends Component {
    containerId = 'duration-elements';

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.amountInputRef = React.createRef();
        this.unitInputRef = React.createRef();

        this.parser = new DurationParser();

        this.state = this.initialState();
    }

    componentDidMount() {
        const { parts, initialChar } = this.props;

        this.validatePartsIfRequired(parts);
        this.handleInitialChar(initialChar);
    }

    componentDidUpdate() {
        const { isEditMode, selectedIndex, parts } = this.props;

        this.validatePartsIfRequired(parts);

        if (this.checkIfChangesAreFromUI(isEditMode, selectedIndex)) {
            return;
        }

        if (isEditMode === true && selectedIndex !== this.state.selectedIndex) {
            this.updateSelectedDuration();
        } else {
            // Shows that we deselected selected duration, so, set state to default
            const state = this.initialState();
            this.setState(state);
            this.focus();
        }
    }

    checkIfChangesAreFromUI = (isEditMode, selectedIndex) => {
        return (
            isEditMode === this.state.isEditMode &&
            selectedIndex === this.state.selectedIndex
        );
    };

    initialState = () => {
        return {
            unit: getDurationUnitByValue(MONTHS),
            units: getAvailableUnitsForDataset(this.props.context.datasetId),
            amount: '',
            isAmountDisabled: false,
            isAmountValid: false,
            isMinMaxValid: true,
            submitted: false,
            amountObj: null,
            selectedIndex: null,
            selectedDuration: null,
            isEditMode: false,
            disallowToEditParts: false,
            partsValidated: false,
            parts: [],
        };
    };

    handleInitialChar = (initialChar) => {
        if (!initialChar) {
            return;
        }

        if (
            initialChar === SEPARATOR_PLUS ||
            initialChar === SEPARATOR_FORWARD_SLASH
        ) {
            this.handleOnAddSeparator(initialChar);
        } else {
            const initialEvent = {
                target: {
                    value: initialChar,
                },
            };
            this.handleAmountChange(initialEvent);
        }
    };

    validatePartsIfRequired = (parts) => {
        if (
            !this.state.partsValidated ||
            this.state.parts.length !== parts.length
        ) {
            this.partsValidation(parts);
        }
    };

    partsValidation = (parts) => {
        if (parts && parts.length > 0) {
            const units = getAvailableUnitsForDataset(
                this.props.context.datasetId
            );
            const firstPart = parts[0];
            const currentUnit = units.find(
                (unit) => unit.value === firstPart.durationValue.unit
            );

            if (currentUnit.canBeMultipleInParts) {
                const newUnitSet = units.filter(
                    (unit) => unit.canBeMultipleInParts
                );
                this.setState({
                    units: newUnitSet,
                    disallowToEditParts: false,
                    partsValidated: true,
                    parts: parts,
                });
            } else {
                this.setState({
                    disallowToEditParts: true,
                    partsValidated: true,
                    parts: parts,
                });
            }
        } else {
            const state = this.initialState();
            this.setState({ ...state, partsValidated: true });
        }
    };

    reset = () => {
        this.setState(this.initialState());
    };

    focus = () => {
        if (this.inputRef.current) {
            this.amountInputRef.current.focus();
        }
    };

    handleAmountChange = (event) => {
        this.setState({ submitted: false });

        if (event.target) {
            const parsedAmountObj = this.amountValidation(event.target.value);
            if (parsedAmountObj) {
                if (
                    parsedAmountObj.parsedValue &&
                    parsedAmountObj.parsedValue !== ''
                ) {
                    this.setState({
                        amountObj: parsedAmountObj,
                        amount: parsedAmountObj.parsedValue,
                        isAmountValid: true,
                    });
                } else {
                    this.setState({
                        amount: '',
                        isAmountValid: false,
                    });
                }
            }
        }
    };

    handleInputKeyDown = (e) => {
        switch (e.keyCode) {
            case KEY_BACKSPACE:
                break;
            case KEY_TAB:
                if (this.props.onTab && this.props.onTabBack) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleOnAddClick(
                        e,
                        !e.shiftKey ? this.props.onTab : this.props.onTabBack
                    );
                }
                break;
            case KEY_LEFT:
            case KEY_RIGHT:
                changeFocusOnKeyDown(this.containerId, e);
                break;
            case KEY_ENTER:
                if (this.amountInputHasFocus()) {
                    this.handleOnAddClick(e);
                }
                break;
            case KEY_EQUAL:
                if (e.shiftKey) {
                    this.handleOnAddSeparator(SEPARATOR_PLUS);
                }
                break;
            case KEY_PLUS:
                this.handleOnAddSeparator(SEPARATOR_PLUS);
                break;
            case KEY_FORWARD_SLASH:
                this.handleOnAddSeparator(SEPARATOR_FORWARD_SLASH);
                break;
            default:
        }
    };

    amountValidation = (value) => {
        if (value === '') {
            return { parsedValue: '' };
        }

        if (value === SEPARATOR_PLUS || value === SEPARATOR_FORWARD_SLASH) {
            return null;
        }

        const cleanValue = this.parser.clean(value);
        return this.parser.parse(cleanValue);
    };

    validationOnSubmit = () => {
        const { amountObj, isAmountDisabled, unit } = this.state;

        return isAmountDisabled
            ? true
            : amountObj &&
                  amountObj.from > 0 &&
                  !isNaN(amountObj.to) &&
                  amountObj.to > 0 &&
                  amountObj.from <= amountObj.to &&
                  unit.maxAmountValue >= amountObj.to;
    };

    amountInputHasFocus = () => {
        return this.amountInputRef.current === document.activeElement;
    };

    handleUnitChange = (newUnit) => {
        const newUnitState = this.getNewUnitState(newUnit);
        this.setState({ ...newUnitState });
    };

    getNewUnitState = (newUnit) => {
        return {
            isAmountDisabled: newUnit.shouldDisableAmount,
            isAmountValid: true,
            isMinMaxValid: true,
            amount: newUnit.shouldDisableAmount ? '' : this.state.amount,
            amountObj: newUnit.shouldDisableAmount
                ? null
                : this.state.amountObj,
            unit: newUnit,
            minMaxDuration: null,
        };
    };

    handleOnAddClick = (e, tabOutAction) => {
        const isAmountValid = this.validationOnSubmit();
        const isMinMaxValid = validateMinMaxDuration(this.state.minMaxDuration);
        this.setState({
            isAmountValid: isAmountValid,
            submitted: true,
            isMinMaxValid: isMinMaxValid,
        });

        if (this.state.isAmountValid && isAmountValid && isMinMaxValid) {
            const part = createDurationPart(
                this.state.unit.value,
                this.state.amountObj && this.state.amountObj.from,
                this.state.amountObj && this.state.amountObj.to,
                this.state.minMaxDuration
            );
            this.props.onAddValue(part, tabOutAction);

            this.reset();
            return;
        }

        tabOutAction && tabOutAction();
    };

    handleOnAddSeparator = (character) => {
        const separatorPart = createSeparatorDurationPart(character);
        this.props.onAddSeparator(separatorPart);
        this.reset();
    };

    updateSelectedDuration = () => {
        const {
            isEditMode,
            selectedValue: selectedDuration,
            selectedIndex,
            parts,
        } = this.props;

        const units = getAvailableUnitsForDataset(this.props.context.datasetId);
        const amountString = durationFormatterInstance.formatAmount(
            selectedDuration.durationValue.amount,
            true
        );

        const amountObj = selectedDuration.durationValue.amount && {
            from: selectedDuration.durationValue.amount.from,
            to: selectedDuration.durationValue.amount.to,
        };

        const unitObj = units.find(
            (unit) => unit.value === selectedDuration.durationValue.unit
        );

        const availableUnits =
            parts.length === 1
                ? units
                : units.filter((unit) => unit.canBeMultipleInParts);

        this.setState(
            {
                selectedIndex: selectedIndex,
                isEditMode: isEditMode,
                unit: unitObj,
                amount: amountString,
                amountObj: amountObj,
                isAmountValid: true,
                isAmountDisabled: unitObj.shouldDisableAmount,
                disallowToEditParts: false,
                units: availableUnits,
                minMaxDuration: selectedDuration.durationValue.minMaxDuration,
            },
            () =>
                this.state.unit.shouldDisableAmount
                    ? this.unitInputRef.current.focus()
                    : this.focus()
        );
    };

    handleMinMaxChange = (minMaxDuration) => {
        this.setState({
            minMaxDuration: minMaxDuration,
            submitted: false,
            isMinMaxValid: true,
            isAmountValid: true,
        });
    };

    render() {
        return (
            <div
                id={this.containerId}
                ref={this.inputRef}
                onKeyDown={this.handleInputKeyDown}
                style={{ height: 'auto', display: 'block' }}
            >
                <div className="first-inline-item">
                    <input
                        type="text"
                        ref={this.amountInputRef}
                        className="amount-input"
                        onChange={this.handleAmountChange}
                        value={this.state.amount}
                        disabled={
                            this.state.isAmountDisabled ||
                            this.state.disallowToEditParts
                        }
                        placeholder="Number or separator"
                        style={
                            !this.state.isAmountValid && this.state.submitted
                                ? {
                                      boxShadow: '0 0 0 1px red',
                                      color: 'red',
                                  }
                                : {}
                        }
                    />
                    {!this.state.isAmountValid && this.state.submitted && (
                        <p style={{ color: 'red' }}>Duration is invalid</p>
                    )}
                </div>
                <div className="inline" style={{ width: '120px' }}>
                    <Select
                        ref={this.unitInputRef}
                        className="basic-single"
                        classNamePrefix="list"
                        isSearchable={false}
                        options={this.state.units}
                        onChange={this.handleUnitChange}
                        value={this.state.unit}
                        openMenuOnFocus={true}
                        isDisabled={this.state.disallowToEditParts}
                        menuPlacement="auto"
                    />
                </div>
                {this.state.unit === getDurationUnitByValue(LEGS_VALUE) && (
                    <MinMaxDuration
                        handleMinMaxChange={this.handleMinMaxChange}
                        isSubmitted={this.state.submitted}
                        isMinMaxValid={this.state.isMinMaxValid}
                        {...this.props}
                    />
                )}

                <div className="inline capture-button" title="Capture Duration">
                    <button
                        name="add-duration"
                        className="ui primary button"
                        onClick={this.handleOnAddClick}
                        disabled={
                            !this.state.isAmountValid ||
                            this.state.disallowToEditParts ||
                            !this.state.isMinMaxValid
                        }
                        style={{ height: '38px' }}
                    >
                        +
                    </button>
                </div>
            </div>
        );
    }
}

export default DurationInput;
