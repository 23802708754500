import isString from 'lodash/isString';
import { NET_RATE_FIXED_OPTION_NAME } from '../models/RatePart';
import { rateFormatterInstance } from './RateFormatter';

export const MultipleRateFormatter = ({ value }) => {
    if (!value || !value.rateParts || value.rateParts.length === 0) {
        return '';
    }

    if (isString(value)) {
        //formatted already
        return value;
    }

    const text = [];

    if (value.hasOwnProperty('partType')) {
        text.push(rateFormatterInstance.format(value));
    } else {
        if (value.isNetRate) text.push(NET_RATE_FIXED_OPTION_NAME);

        value.rateParts.forEach((part) => {
            text.push(rateFormatterInstance.format(part));
        });
    }

    return text.join(' ');
};
