import { loadZones } from '../../../models/Location';
import PriorityListComparator from './PriorityListComparator';
import defaultSortComparator from './DefaultSortComparator';

/*
 * As we are now showing different zone lists, based on DatasetId, we need to pass the
 * correct zone list. For the ZoneEditor, we are using the ag-grid "context" object to make
 * datasetId available. Comparators don't support this, so I've opted to grab the dataset
 * from the *underlying node*. This is horrific, but I don't see an easier way at present.
 * If we want ever do zone lists by group, this approach won't work.
 *
 * TMCG 28th May 19
 */
const LoadZoneComparator = (valueA, valueB, nodeA, nodeB) => {
    //Ignore group nodes because they don't have a datasetId
    if (nodeA.group) {
        return defaultSortComparator(valueA, valueB);
    }

    const nodeADatasetId = nodeA ? nodeA.data.datasetId : null;
    const nodeBDatasetId = nodeB ? nodeB.data.datasetId : null;

    const datasetId = nodeADatasetId || nodeBDatasetId;

    return PriorityListComparator(
        valueA,
        valueB,
        loadZones(datasetId) // ag-grid context is *not* availble in comparators.
    );
};

export default LoadZoneComparator;
