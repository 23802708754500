import React, { useRef, useEffect, forwardRef } from 'react';
import '../../../shared/columns/styles/PopupWindowInput.scss';
import { flatRateValue, balticIndexValue } from '../models/RateTypes';
import {
    createFlatRatePart,
    createBalticIndexPart,
    createSeparatorRatePart,
} from '../models/RatePart';
import {
    KEY_BACKSPACE,
    KEY_TAB,
    KEY_ENTER,
    KEY_LEFT,
    KEY_RIGHT,
    KEY_PLUS,
    KEY_FORWARD_SLASH,
    KEY_EQUAL,
} from '../../../../constants/keyboardCodes';
import {
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
    ENTITY_PART_TYPE_RATE,
} from '../../../../models/common/EntityPart';
import { changeFocusOnKeyDown } from '_legacy/services/TrapFocusService';
import { useRateStore } from '../store/RateStore';
import Duration from './Duration';
import RateType from './RateType';
import Currency from './Currency';
import Bound from './Bound';
import RateIndex from './RateIndex';
import Unit from './Unit';
import Amount from './Amount';
import { convertRateToInputStateModel } from '../tools/RateConverter';
import NetRate from './NetRate';
import {
    validateDurationAmount,
    validateAmount,
} from '../services/ValidationService';

const RateInput = forwardRef((props, ref) => {
    const containerId = 'rate-elements';

    const didMountRef = useRef(false);
    const amountInputRef = useRef(null);
    const durationInputRef = useRef(null);
    const unitInputRef = useRef(null);

    const store = useRateStore();

    useEffect(() => {
        if (didMountRef.current) {
            const { isEditMode, selectedIndex } = props;
            if (checkIfChangesAreFromUI(isEditMode, selectedIndex)) {
                return;
            }
            if (isEditMode === true && selectedIndex !== store.selectedIndex) {
                updateSelectedRate();
            } else {
                // Shows that we deselected selected rate, so, set state to default
                store.setInitialState();
                focusAmount();
            }
        } else {
            didMountRef.current = true;
            store.setInitialState();
            focusAmount();
        }
    });

    const checkIfChangesAreFromUI = (isEditMode, selectedIndex) => {
        return (
            isEditMode === store.isEditMode &&
            selectedIndex === store.selectedIndex
        );
    };

    const focusAmount = () => {
        if (amountInputRef.current) {
            setTimeout(() => {
                amountInputRef.current.focus();
            }, 0);
        }
    };

    const focusUnit = () => {
        if (unitInputRef.current) {
            setTimeout(() => {
                unitInputRef.current.focus();
            }, 0);
        }
    };

    const amountInputHasFocus = () => {
        return amountInputRef.current === document.activeElement;
    };

    const durationAmountInputHasFocus = () => {
        return durationInputRef.current === document.activeElement;
    };

    const handleInputKeyDown = (e) => {
        switch (e.keyCode) {
            case KEY_BACKSPACE:
                break;
            case KEY_TAB:
                if (props.onTab && props.onTabBack) {
                    e.preventDefault();
                    e.stopPropagation();
                    handleOnAddClick(
                        e,
                        !e.shiftKey ? props.onTab : props.onTabBack
                    );
                }
                break;
            case KEY_LEFT:
            case KEY_RIGHT:
                changeFocusOnKeyDown(containerId, e);
                break;
            case KEY_ENTER:
                if (amountInputHasFocus() || durationAmountInputHasFocus()) {
                    handleOnAddClick(e);
                }
                break;
            case KEY_EQUAL:
                if (e.shiftKey) {
                    handleOnAddSeparator(SEPARATOR_PLUS);
                }
                break;
            case KEY_PLUS:
                handleOnAddSeparator(SEPARATOR_PLUS);
                break;
            case KEY_FORWARD_SLASH:
                handleOnAddSeparator(SEPARATOR_FORWARD_SLASH);
                break;
            default:
        }
    };

    const handleOnAddClick = (e, tabOutAction) => {
        const validationResult = validationOnSubmit();
        store.onSubmit(validationResult);

        if (
            store.isAmountValid &&
            validationResult.isAmountValid &&
            (checkIsFlatRateMode() || validationResult.isIndexValid) &&
            (!props.context.isExtendedMode ||
                (validationResult.isDurationAmountValid &&
                    validationResult.arePartsValid))
        ) {
            const part = checkIsFlatRateMode()
                ? createFlatRatePart({
                      currency: store.currency && store.currency.value,
                      unit: store.unit.value,
                      amountFrom: store.amountObj && store.amountObj.from,
                      amountTo: store.amountObj && store.amountObj.to,
                      bound: store.bound && store.bound.value,
                      durationAmountFrom:
                          store.durationAmountObj &&
                          store.durationAmountObj.from,
                      durationAmountTo:
                          store.durationAmountObj && store.durationAmountObj.to,
                      durationUnit: store.durationUnit.value,
                  })
                : createBalticIndexPart({
                      amountFrom: store.amountObj && store.amountObj.from,
                      amountTo: store.amountObj && store.amountObj.to,
                      index: store.index && store.index.value,
                      durationAmountFrom:
                          store.durationAmountObj &&
                          store.durationAmountObj.from,
                      durationAmountTo:
                          store.durationAmountObj && store.durationAmountObj.to,
                      durationUnit: store.durationUnit.value,
                  });

            props.onAddValue(part, tabOutAction);
            store.setInitialState();
            focusAmount();

            return;
        }

        tabOutAction && tabOutAction();
    };

    const validationOnSubmit = () => {
        const {
            amountObj,
            isAmountDisabled,
            index,
            durationUnit,
            durationAmountObj,
            durationAmount,
            isEditMode,
            selectedIndex,
        } = store;

        const isAmountValid = isAmountDisabled
            ? true
            : validateAmount(amountObj);
        const isIndexValid = index ? true : false;
        const { isDurationAmountValid, durationAmountValidationErrorMessage } =
            validateDurationAmount(
                durationAmount,
                durationAmountObj,
                durationUnit
            );

        const arePartsValid =
            props.context.isExtendedMode && durationAmount !== ''
                ? props.partsValidation({
                      parts: props.parts,
                      partType: ENTITY_PART_TYPE_RATE,
                      isEditMode: isEditMode,
                      selectedIndex: selectedIndex,
                      duration: {
                          amount: durationAmountObj,
                          unit: durationUnit.value,
                      },
                  })
                : true;

        return {
            isAmountValid,
            isIndexValid,
            isDurationAmountValid,
            arePartsValid,
            durationAmountValidationErrorMessage,
        };
    };

    const handleOnAddSeparator = (character) => {
        const separatorPart = createSeparatorRatePart(character);
        store.setInitialState();
        props.onAddSeparator(separatorPart);
    };

    const checkIsFlatRateMode = () => {
        return store.type.value === flatRateValue;
    };

    const checkRatePartIsValid = () => {
        return checkIsFlatRateMode()
            ? store.isAmountValid
            : store.isAmountValid && store.index;
    };

    const updateSelectedRate = () => {
        const { selectedValue: selectedRate } = props;
        const newState = convertRateToInputStateModel(
            selectedRate,
            props.data.type,
            props.context.datasetId,
            props.selectedIndex
        );
        store.setCustomState(newState);
        newState.unit.shouldDisableAmount ? focusUnit() : focusAmount();
    };

    return (
        <div
            id={containerId}
            style={{ minWidth: 'auto' }}
            onKeyDown={handleInputKeyDown}
            onFocus={props.resetSeparatorValidation}
        >
            {props.context.isExtendedMode && (
                <RateType amountInputRef={amountInputRef} />
            )}

            <div
                className={
                    props.context.isExtendedMode
                        ? 'inline vertical-divider'
                        : 'first-inline-item'
                }
            >
                <div
                    className="inline-title"
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div style={{ float: 'left' }}>Rate values </div>
                    <NetRate {...props} />
                </div>

                {checkIsFlatRateMode() && <Bound {...props} />}
                {checkIsFlatRateMode() && <Currency />}
                <Amount {...props} amountInputRef={amountInputRef} />
                {store.type.value === balticIndexValue && (
                    <RateIndex {...props} />
                )}
                {checkIsFlatRateMode() && (
                    <Unit {...props} unitInputRef={unitInputRef} />
                )}
            </div>

            {props.context.isExtendedMode && (
                <Duration {...props} durationInputRef={durationInputRef} />
            )}

            <div className="inline" style={{ marginTop: '20px' }}>
                <div className="inline capture-button" title="Capture Rate">
                    <button
                        name="add-rate"
                        className="ui primary button"
                        onClick={handleOnAddClick}
                        disabled={!checkRatePartIsValid()}
                    >
                        +
                    </button>
                </div>
            </div>
        </div>
    );
});

export default RateInput;
