import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    useState,
    useEffect,
} from 'react';
import FortCellEditor from '_legacy/modules/shared/columns/editors/FortCellEditor';

const ParcelsEditor = forwardRef((props, ref) => {
    const inputRef = useRef();

    const [inputValue, setInputValue] = useState(props.value ?? '');

    useEffect(() => {
        const initialEvent = {
            target: {
                value: inputValue + props.charPress,
            },
        };
        handleChange(initialEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationRegex = new RegExp('^\\d{0,2}$');

    const focus = () => {
        inputRef.current.focus();
    };

    const handleChange = (event) => {
        const value = event.target.value;
        const isValid = validationRegex.test(value);

        setInputValue(isValid ? value : inputValue);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                const number = Number(inputValue);
                return number === 0 || Number.isNaN(number) ? null : number;
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    return (
        <FortCellEditor focus={focus} {...props}>
            <>
                <input
                    type="text"
                    noValidate
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleChange}
                />
            </>
        </FortCellEditor>
    );
});

export default ParcelsEditor;
