import React, { Component } from 'react';
import classNames from 'classnames';
import Menu from './Menu';
import CloseIcon from '../icons/CloseIcon';
import './Menu.scss';
import './Activity.scss';
import FixtureCountActivity from '../activity/FixtureCountActivity';
import VoyageActivity from '../activity/VoyageActivity';
import AuditActivity from '../activity/AuditActivity';
import { FixtureApi } from '../../api/fixtureApi';

export const ActivityTabs = {
    AUDIT: 'audit',
    VOYAGE_ACTIVITY: 'voyageActivity',
    ANALYTICS: 'analytics',
};

export class Activity extends Component {
    constructor(props, context) {
        super(props, context);

        //default tabs
        let selectedTab = props.selectedTab;
        if (!selectedTab) {
            selectedTab = this.props.forFixtures
                ? ActivityTabs.ANALYTICS
                : ActivityTabs.AUDIT;
        }

        this.state = {
            format: 'compact',
            selectedTab,
        };

        FixtureApi.getMaintainanceMessage('Fixture Analytics Maintenance')
            .then((results) => {
                if (results) {
                    this.setState({
                        advancedAnalyticsMaintainanceMessage: results,
                    });
                }
            })
            .catch((reason) => {});

        this.toggleFormat = this.toggleFormat.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.selectedTab !== nextProps.selectedTab) {
            this.setState({ selectedTab: nextProps.selectedTab });
        }
    }

    toggleFormat() {
        const format = this.state.format;
        this.setState({
            format: format === 'compact' ? 'expanded' : 'compact',
        });
    }

    onClose() {
        if (this.props.onClose) {
            this.props.onClose(this.state.format);
        }
    }

    handleOnAuditHistoryClick = () => {
        const { selectedTab } = this.state;

        if (
            this.props.trackAuditHistoryEvent &&
            selectedTab !== ActivityTabs.AUDIT
        ) {
            this.props.trackAuditHistoryEvent();
        }

        this.setState({ selectedTab: ActivityTabs.AUDIT });
    };

    handleOnVoyageActivityClick = () => {
        this.setState({
            selectedTab: ActivityTabs.VOYAGE_ACTIVITY,
        });
    };

    handleOnAnalyticClick = () => {
        const { selectedTab } = this.state;

        if (
            this.props.trackAnalyticEvent &&
            selectedTab !== ActivityTabs.ANALYTICS
        ) {
            this.props.trackAnalyticEvent();
        }

        this.setState({ selectedTab: ActivityTabs.ANALYTICS });
    };

    render() {
        const multiClassName = 'multi-'.concat(
            this.props.forFixtures ? 'fixtures' : 'orders'
        );
        const classes = classNames({
            [multiClassName]: this.props.context === 'multi',
            single: this.props.context === 'single',
            'activity-compact': this.state.format === 'compact',
            'activity-expanded': this.state.format === 'expanded',
        });

        const hasSelectedFixtures =
            this.props.selectedFixtures != null &&
            this.props.selectedFixtures.length > 0;

        const hasSelectedOrders =
            this.props.selectedOrders != null &&
            this.props.selectedOrders.length > 0;

        const somethingSelected = hasSelectedFixtures || hasSelectedOrders;

        const showAnalyticsTab =
            this.props.forFixtures &&
            this.state.selectedTab === ActivityTabs.ANALYTICS;

        const showVoyageActivityTab =
            this.props.forFixtures &&
            this.state.selectedTab === ActivityTabs.VOYAGE_ACTIVITY &&
            hasSelectedFixtures;

        const showAuditTab =
            (this.state.selectedTab === ActivityTabs.AUDIT &&
                somethingSelected) ||
            !this.props.forFixtures;

        const selectors = {
            audit:
                (this.props.selectedTab === ActivityTabs.AUDIT &&
                    somethingSelected) ||
                !this.props.forFixtures,
            voyageActivity:
                this.props.selectedTab === ActivityTabs.VOYAGE_ACTIVITY &&
                somethingSelected,
        };

        //if the active tab is hidden, show a default tab
        if (selectors[this.state.selectedTab] === false) {
            this.setState({
                selectedTab: this.props.forFixtures
                    ? ActivityTabs.ANALYTICS
                    : ActivityTabs.AUDIT,
            });
        }

        const analyticsTabClass = classNames({
            'menu-item': true,
            active: showAnalyticsTab,
        });

        const auditTabClass = classNames({
            'menu-item': true,
            active: showAuditTab,
        });

        const voyageActivityTabClass = classNames({
            'menu-item': true,
            active: showVoyageActivityTab,
        });

        return (
            <div id="activity" className={classes}>
                <div className="activity-container">
                    <div id="activity-menu">
                        <Menu>
                            <div
                                className="menu-slide-in"
                                title="Slide in"
                                onClick={this.props.onClose}
                            />
                            {selectors.audit && (
                                <span
                                    className={auditTabClass}
                                    aria-current="page"
                                    onClick={this.handleOnAuditHistoryClick}
                                >
                                    Audit History
                                </span>
                            )}
                            {this.props.forFixtures && (
                                <React.Fragment>
                                    {selectors.voyageActivity && (
                                        <span
                                            className={voyageActivityTabClass}
                                            aria-current="page"
                                            onClick={
                                                this.handleOnVoyageActivityClick
                                            }
                                        >
                                            Voyage History
                                        </span>
                                    )}

                                    <span
                                        className={analyticsTabClass}
                                        aria-current="page"
                                        onClick={this.handleOnAnalyticClick}
                                    >
                                        Analytics
                                    </span>
                                </React.Fragment>
                            )}
                            <div
                                className="menu-toggle-format"
                                title={
                                    this.state.format === 'compact'
                                        ? 'Expand'
                                        : 'Retract'
                                }
                                onClick={this.toggleFormat}
                                style={{ position: 'fixed', right: '25px' }}
                            />
                            <div
                                className="menu-close"
                                title="Close"
                                onClick={this.onClose}
                                style={{ position: 'fixed', right: '0' }}
                            >
                                <CloseIcon />
                            </div>
                        </Menu>
                    </div>
                    <div className="activity-content">
                        {showAnalyticsTab ? (
                            <FixtureCountActivity
                                datasetId={this.props.datasetId}
                            />
                        ) : null}
                        {showAuditTab ? (
                            <AuditActivity
                                selectedFixtures={this.props.selectedFixtures}
                                selectedOrders={this.props.selectedOrders}
                                datasetId={this.props.datasetId}
                            />
                        ) : null}
                        {showVoyageActivityTab ? (
                            <VoyageActivity
                                selectedFixtures={this.props.selectedFixtures}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

Activity.defaultProps = {
    forFixtures: true,
    selectedTab: ActivityTabs.ANALYTICS,
};

export default Activity;
