import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import ClarksonsCloudHeader from './components/clarksons/ClarksonsCloudHeader';
import GlobalSubscriptions from './components/GlobalSubscriptions';
import MultiPage from './components/multi/MultiPage';
import SinglePage from './components/single/SinglePage';
import NotificationMessages from './components/NotificationMessages';
import AdvancedAnalyticsPage from './components/analytics/AdvancedAnalyticsPage';
import { Modal } from 'common';

class routes extends Component {
    render() {
        return (
            <div>
                <ClarksonsCloudHeader />
                <Modal />
                <div className="ui fluid container flex-layout-container light-background">
                    <div
                        id="appContainer"
                        className="flex-item-fill"
                        style={{ width: '100%' }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}
                        >
                            <GlobalSubscriptions />
                            <NotificationMessages className="brand-header-part" />
                            <div
                                style={{
                                    position: 'relative',
                                    flexGrow: 1,
                                    height: '100%',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    <Switch>
                                        <Route
                                            exact
                                            path="/analytics/:token"
                                            component={AdvancedAnalyticsPage}
                                        />
                                        <Route
                                            exact
                                            path="/multi"
                                            component={MultiPage}
                                        />
                                        <Route component={SinglePage} />
                                    </Switch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default routes;
