import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./minMaxDurationFragment.gql');

export const MIN_MAX_DURATION_TYPE_NAME = 'MinMaxDuration';
export const MIN_MAX_DURATION_FRAGMENT_NAME = 'MinMaxDurationFragment';
export const MIN_MAX_DURATION_FRAGMENT = gql`
    ${FRAGMENT}
`;
