import React from 'react';
import countries from 'i18n-iso-countries';

const CountryImage = ({ countryCode }) => {
    if (!countryCode) return null;

    countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

    return (
        <img
            src={`https://ckncdnlive.azureedge.net/Data/PublicArtificats/flags/16/${countryCode.trim()}.png`}
            alt=""
            style={{ verticalAlign: 'sub' }}
            title={countries.getName(countryCode.trim(), 'en', {
                select: 'official',
            })}
        />
    );
};

export default CountryImage;
