import { gql } from '@apollo/client';
import { ORDER_FRAGMENT } from '../fragments/orderFragments';

export const ORDER_ADDED_SUBSCRIPTION = gql`
    subscription onOrderAdded($groupId: ID!) {
        orderAdded(groupId: $groupId) {
            ...OrderFragment
        }
    }

    ${ORDER_FRAGMENT}
`;

export const ORDER_UPDATED_SUBSCRIPTION = gql`
    subscription onOrderUpdated($groupId: ID!) {
        orderUpdated(groupId: $groupId) {
            ...OrderFragment
        }
    }

    ${ORDER_FRAGMENT}
`;

export const ORDER_SHARED_SUBSCRIPTION = gql`
    subscription onOrderShared($groupId: ID!) {
        orderShared(groupId: $groupId) {
            ...OrderFragment
        }
    }

    ${ORDER_FRAGMENT}
`;
