import React, { Component, Fragment } from 'react';
import { ApolloProvider } from '@apollo/client';
import GraphQLClient from '../api/graphQlClient';

export default class ApolloProviderLazyLoader extends Component {
    render() {
        return (
            <Fragment>
                <ApolloProvider client={GraphQLClient()}>
                    {this.props.children}
                </ApolloProvider>
            </Fragment>
        );
    }
}
