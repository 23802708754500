import React from 'react';
import { Button } from 'semantic-ui-react';
import WarningIconRed from '../../icons/WarningIconRed';

const ChangeOwnerConfirmation = (props) => (
    <div
        id="company-confirmation-modal"
        className="company-confirmation"
        style={{ textAlign: 'center' }}
    >
        <h3>
            {' '}
            <WarningIconRed
                style={{ marginBottom: '-4px', width: 25, height: 25 }}
            />{' '}
            Warning
        </h3>
        <div>
            Your fixture has a reported date which is more than 30 days ago
        </div>
        <div>{props.text}</div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button primary onClick={props.onClose}>
                CANCEL
            </Button>
            <Button basic color="blue" onClick={props.onConfirm}>
                SAVE
            </Button>
        </div>
    </div>
);

export default ChangeOwnerConfirmation;
