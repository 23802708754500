import React from 'react';
import Select from 'react-select';
import { useRateStore } from '../store/RateStore';
import { getAvailableUnits } from '../models/RateUnits';

const Unit = (props) => {
    const store = useRateStore();

    const handleUnitChange = (newUnit) => {
        store.onChangeUnit(newUnit);
    };

    return (
        <div
            className="inline"
            style={{
                width: '100px',
            }}
        >
            <Select
                id="UnitInputA"
                ref={props.unitInputRef}
                className="basic-single"
                classNamePrefix="list"
                isSearchable={false}
                options={getAvailableUnits(props.data.type)}
                menuPlacement="auto"
                onChange={handleUnitChange}
                value={store.unit}
                openMenuOnFocus={true}
            />
        </div>
    );
};

export default Unit;
