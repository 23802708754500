import React, { useState } from 'react';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import styles from './AccordionDropdownMenuStyles';
import CheckedIcon from '../icons/CheckedIcon';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import Box from '@material-ui/core/Box';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ClickAwayListener,
} from '@material-ui/core';
import ToolbarButton from './ToolbarButton';

const AccordionDropdownMenu = ({
    menuItems,
    classes,
    triggerButtonLabel,
    triggerButtonId,
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleTriggerButtonClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleClickAway = () => {
        setIsMenuOpen(false);
    };

    const handleItemClick = (event, menuItem) => {
        menuItem.onClick && menuItem.onClick(menuItem);
    };

    const renderMenuItem = (menuItem) => {
        const renderSimpleMenuItem = () => (
            <div key={menuItem.key}>
                <MenuItem
                    onClick={(e) => handleItemClick(e, menuItem)}
                    className={classes.menuItem}
                    key={menuItem.key}
                    component={'div'}
                >
                    <div key={menuItem.key} className={classes.caption}>
                        {menuItem.checked !== undefined && (
                            <CheckedIcon hide={!menuItem.checked} />
                        )}
                        {menuItem.content}
                    </div>
                </MenuItem>
                {menuItem.divider && <Divider />}
            </div>
        );
        const renderMenuItemWithSubmenu = () => (
            <div key={menuItem.key}>
                <Accordion
                    classes={{
                        root: classes.accordionRoot,
                        expanded: classes.accordionExpanded,
                    }}
                >
                    <MenuItem className={classes.menuItem}>
                        <AccordionSummary
                            classes={{
                                root: classes.accordionSummary,
                                content: classes.accordionSummaryContent,
                                expanded: classes.accordionSummary,
                            }}
                            expandIcon={<ChevronDownIcon />}
                        >
                            {menuItem.content}
                        </AccordionSummary>
                    </MenuItem>
                    {menuItem.subMenuItems?.map((subMenuItem) => (
                        <AccordionDetails
                            key={subMenuItem.key}
                            className={classes.accordionDetails}
                            data-testid="submenuItem"
                        >
                            <MenuItem
                                onClick={(e) => handleItemClick(e, subMenuItem)}
                                className={classes.subMenuItem}
                            >
                                {subMenuItem.checked !== undefined && (
                                    <CheckedIcon hide={!subMenuItem.checked} />
                                )}

                                {subMenuItem.content}
                            </MenuItem>
                        </AccordionDetails>
                    ))}
                </Accordion>
                {menuItem.divider && <Divider />}
            </div>
        );

        const hasSubmenu =
            menuItem.subMenuItems && menuItem.subMenuItems.length > 0;

        return hasSubmenu
            ? renderMenuItemWithSubmenu()
            : renderSimpleMenuItem();
    };

    return (
        <>
            {isMenuOpen && <div className={classes.overlay} />}
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box style={{ display: 'flex', position: 'relative' }}>
                    <ToolbarButton
                        onClick={handleTriggerButtonClick}
                        id={triggerButtonId}
                    >
                        {triggerButtonLabel}
                    </ToolbarButton>
                    <Box
                        className={classes.menu}
                        data-testid="dropdownMenu"
                        style={{
                            display: isMenuOpen ? 'block' : 'none',
                        }}
                    >
                        {menuItems.map((menuItem) => renderMenuItem(menuItem))}
                    </Box>
                </Box>
            </ClickAwayListener>
        </>
    );
};

export default withStyles(styles)(AccordionDropdownMenu);
