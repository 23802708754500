import React, { Component } from 'react';
import Svg from './RestoreFilter.svg';

export default class RestoreFilterIcon extends Component {
    render = () => {
        return (
            <img
                src={Svg}
                {...this.props}
                style={{ opacity: '0.7', width: '22px' }}
                alt="Restore Filter Icon"
            />
        );
    };
}
