import { commonRowAndCellClassRules } from '../../../../components/grid/classRules/classRules';
import BlanksFilterRenderer from '../../../../components/grid/renderers/BlanksFilterRenderer';
import naturalSortComparator from '../../../../components/grid/comparators/NaturalSortComparator';
import defaultObjEquals from '../../../../components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '../../../../components/grid/columnDefaults/defaultSetFilterParams';
import BooleanFormatter from '../../../shared/columns/formatters/BooleanFormatter';
import BooleanKeyCreator from '../../../shared/columns/keyCreators/BooleanKeyCreator';
import BooleanComparator from '../../../shared/columns/comparators/BooleanComparator';

export const vesselNitrogenPlantHeading = {
    colId: 'nitrogenPlant',
    headerName: 'N2',
    field: 'vessel.nitrogenPlant',
    editable: false,
    valueFormatter: BooleanFormatter,
    keyCreator: BooleanKeyCreator,
    comparator: BooleanComparator,
    equals: defaultObjEquals,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    width: 120,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
};
