import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { createSeparatorLocationPart } from '../../models/LocationPart';
import { ENTITY_PART_TYPE_SEPARATOR } from '../../models/common/EntityPart';

export const commonCompareStrategy = (oldValue, newValue) => {
    if (isNilOrEmpty(newValue) && isNilOrEmpty(oldValue)) {
        return true;
    } else if (isNilOrEmpty(newValue) || isNilOrEmpty(oldValue)) {
        return false; // one is null and other is not
    } else {
        return isEqual(newValue, oldValue);
    }
};

export const locationCompareStrategy = (oldValue, newValue) => {
    let newValueCopy = cloneDeep(newValue);
    let oldValueCopy = cloneDeep(oldValue);

    if (newValue && oldValue) {
        newValueCopy = newValueCopy.map((part) => handleLocationMapping(part));
        oldValueCopy = oldValueCopy.map((part) => handleLocationMapping(part));
    }

    return commonCompareStrategy(newValueCopy, oldValueCopy);
};

export const freightPortBasisCompareStrategy = (oldValue, newValue) => {
    if (
        newValue &&
        newValue.shouldBeUpdatedAutomatically &&
        !newValue.parts.length
    ) {
        return true;
    }

    return commonCompareStrategy(newValue, oldValue);
};

export const laycanCompareStrategy = (oldValue, newValue) => {
    if (
        newValue &&
        newValue.shorthand === '' &&
        !newValue.startDate &&
        !newValue.endDate &&
        !oldValue
    ) {
        return true;
    }

    return commonCompareStrategy(newValue, oldValue);
};

export const vesselFieldCompareStrategy = (oldValue, newValue) => {
    const fieldName = Object.keys(newValue)[0];
    const oldVesselValue = {};
    oldVesselValue[fieldName] = oldValue ?? undefined;

    return commonCompareStrategy(oldVesselValue, newValue);
};

export const cargoPartsCompareStrategy = (oldValue, newValue) => {
    let newValueCopy = cloneDeep(newValue);
    let oldValueCopy = cloneDeep(oldValue);

    if (newValue && oldValue) {
        newValueCopy = newValueCopy.map((part) => handleCargoMapping(part));
        oldValueCopy = oldValueCopy.map((part) => handleCargoMapping(part));
    }

    return commonCompareStrategy(newValueCopy, oldValueCopy);
};

const isNilOrEmpty = (value) => {
    return (
        isNil(value) ||
        value === '' ||
        (Array.isArray(value) && value.length === 0)
    );
};

//sets null for props which locations differ
const handleLocationMapping = (part) => {
    if (part.partType === ENTITY_PART_TYPE_SEPARATOR) {
        return createSeparatorLocationPart(part.value);
    }
    return {
        ...part,
        globalZone: null,
        countryId: null,
        deskArea: part.deskArea ?? undefined,
        deskZone: part.deskZone ?? undefined,
        deskAreaGeared: part.deskAreaGeared ?? undefined,
    };
};

const handleCargoMapping = (part) => {
    if (part.partType === ENTITY_PART_TYPE_SEPARATOR) {
        return createSeparatorLocationPart(part.value);
    }
    return {
        ...part,
        originalName: null,
    };
};
