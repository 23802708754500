import { gql } from '@apollo/client';

export const SEARCH_CARGOES = gql`
    query Cargoes($search: String!, $groupId: ID!) {
        cargoes(searchTerm: $search, groupId: $groupId) {
            id
            parentId
            fullName
            displayName
        }
    }
`;

export const GET_CARGOES_WITH_PARENTS_BY_IDS = gql`
    query CargoesWithParentsByIds($ids: [String]!) {
        cargoesWithParentsByIds(ids: $ids) {
            id
            parentId
            name
            fullName
            aliases
        }
    }
`;
