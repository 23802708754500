import React from 'react';
import DataSizeOptions from '../../data/dataSizeOptions';
import SwitchWithTooltip from './SwitchWithTooltip';
import Typography from '@material-ui/core/Typography';
import AccordionDropdownMenu from './AccordionDropdownMenu';

function DataButton({
    id,
    dataSizeOption,
    forFixtures,
    shouldHighlightNewOrders,
    onToggleHighlightNewOrders,
    shouldShowConvertedOrders,
    onToggleShowConvertedOrders,
    showDeleted,
    onToggleShowDeleted,
    shouldOnlyDisplayRumouredOrders,
    onToggleOnlyDisplayRumouredOrders,
    shouldOnlyDisplayRumouredFixtures,
    onToggleOnlyDisplayRumouredFixtures,
    onDataSizeChanged,
}) {
    const getDataMenuItems = () => {
        let selectedDataSizeOptionLabel = DataSizeOptions[0].label;
        const matchedDataSizeOption = DataSizeOptions.find(
            (item) => dataSizeOption === item.value
        );
        if (matchedDataSizeOption) {
            selectedDataSizeOptionLabel = matchedDataSizeOption.label;
        }

        const items = [];
        if (!forFixtures) {
            // items for Orders page Data cascading menu
            items.push(
                {
                    key: 'HighlightNewOrders',
                    divider: true,
                    content: (
                        <SwitchWithTooltip
                            id="HighlightNewOrders"
                            label="HIGHLIGHT NEW"
                            tooltip="Highlights orders which have been created in the last 24 hours"
                            isChecked={shouldHighlightNewOrders}
                            onChange={onToggleHighlightNewOrders}
                        />
                    ),
                },
                {
                    key: 'ShowConvertedOrders',
                    content: (
                        <SwitchWithTooltip
                            id="ShowConvertedOrders"
                            label="SHOW CONVERTED"
                            tooltip="Adds to the list of orders those that have been converted in the last 24 hours"
                            isChecked={shouldShowConvertedOrders}
                            onChange={onToggleShowConvertedOrders}
                        />
                    ),
                },
                {
                    key: 'ShowDeletedAndWithdrawnOrders',
                    divider: true,
                    content: (
                        <SwitchWithTooltip
                            id="ShowDeletedAndWithdrawnOrders"
                            label="SHOW DELETED & WITHDRAWN"
                            tooltip="Adds to the list of orders those that have been deleted or withdrawn"
                            isChecked={showDeleted}
                            onChange={(event) => {
                                onToggleShowDeleted(event.target.checked);
                            }}
                        />
                    ),
                },
                {
                    key: 'OnlyDisplayRumoured',
                    content: (
                        <SwitchWithTooltip
                            id="OnlyDisplayRumoured"
                            label="ONLY DISPLAY RUMOURED"
                            tooltip="Displays ONLY orders that are rumoured or contain rumoured cells"
                            isChecked={shouldOnlyDisplayRumouredOrders}
                            onChange={onToggleOnlyDisplayRumouredOrders}
                        />
                    ),
                }
            );
        }
        if (forFixtures) {
            // items for Fixtures page Data cascading menu
            items.push(
                {
                    key: 'Show Current Option',
                    content: (
                        <Typography
                            style={{
                                fontFamily: 'Cera GR Medium',
                                padding: '12px',
                                width: '292px',
                            }}
                        >
                            DISPLAYING: fixtures from{' '}
                            {selectedDataSizeOptionLabel}
                        </Typography>
                    ),
                    divider: true,
                    subMenuItems: DataSizeOptions.map((item) => {
                        return {
                            key: item.value,
                            parentKey: 'Show Current Option',
                            content: item.label,
                            checked: item.value === dataSizeOption,
                            onClick: (clickedItem) => {
                                if (onDataSizeChanged) {
                                    onDataSizeChanged(clickedItem);
                                }
                            },
                        };
                    }),
                },
                {
                    key: 'ShowDeleted',
                    divider: true,
                    content: (
                        <SwitchWithTooltip
                            id="ShowDeletedOrders"
                            label="SHOW DELETED"
                            tooltip="Adds to the list of fixtures those that have been deleted"
                            isChecked={showDeleted}
                            onChange={(event) => {
                                onToggleShowDeleted(event.target.checked);
                            }}
                        />
                    ),
                },
                {
                    key: 'OnlyDisplayRumoured',
                    content: (
                        <SwitchWithTooltip
                            id="OnlyDisplayRumoured"
                            label="ONLY DISPLAY RUMOURED"
                            tooltip="Displays ONLY fixtures that are rumoured or contain rumoured cells"
                            isChecked={shouldOnlyDisplayRumouredFixtures}
                            onChange={onToggleOnlyDisplayRumouredFixtures}
                        />
                    ),
                }
            );
        }

        return items;
    };

    return (
        <>
            <AccordionDropdownMenu
                id="DataCascadingMenu"
                key="DataCascadingMenu"
                menuItems={getDataMenuItems()}
                triggerButtonLabel={'Data'}
                triggerButtonId={id}
            />
        </>
    );
}

export default DataButton;
