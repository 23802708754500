import React, { Component } from 'react';
import FortCellEditor from '_legacy/modules/shared/columns/editors/FortCellEditor';
import isNil from 'lodash/isNil';

export default class NumericEditor extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        let value = '';
        if (this.props.charPress) {
            value = this.props.charPress;
        } else if (!isNil(this.props.value)) {
            value = this.props.value;
        }

        this.state = {
            value,
            shouldSelect: !this.props.charPress,
        };

        //three decimal places
        this.validationRegex = new RegExp('^\\d*(\\.\\d{0,3})?$');

        this.handleChange = this.handleChange.bind(this);
        this.focus = this.focus.bind(this);
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();

            if (this.state.shouldSelect) {
                this.inputRef.current.select();
            }
        }
    }

    getValue() {
        if (this.state.value === '') {
            return null;
        }

        return Number(this.state.value);
    }

    handleChange(event) {
        let inputValue = event.target.value;
        let errors = !this.validationRegex.test(inputValue);

        this.setState({
            value: errors ? this.state.value : inputValue,
            shouldSelect: false,
        });
    }

    render() {
        return (
            <FortCellEditor focus={this.focus} {...this.props}>
                <input
                    type="text"
                    noValidate
                    ref={this.inputRef}
                    value={this.state.value}
                    onChange={this.handleChange}
                />
            </FortCellEditor>
        );
    }
}
