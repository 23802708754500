import NamePropertyComparator from './NamePropertyComparator';

const BrokersComparator = (valueA, valueB, nodeA, nodeB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    const compareValueA = valueA || [];
    const compareValueB = valueB || [];

    if (compareValueA.length === 0 && compareValueB.length === 0) {
        return 0;
    }

    if (compareValueA.length === 0) {
        return -1;
    }

    if (compareValueB.length === 0) {
        return 1;
    }

    let result = NamePropertyComparator(compareValueA[0], compareValueB[0]);
    if (result !== 0) return result;

    let index = 0;
    while (
        (result === 0 && compareValueA[index + 1] !== undefined) ||
        compareValueB[index + 1] !== undefined
    ) {
        index++;
        result = NamePropertyComparator(
            compareValueA[index],
            compareValueB[index]
        );
    }
    return result;
};

export default BrokersComparator;
