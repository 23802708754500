import {
    ApplicationInsights,
    SeverityLevel,
} from '@microsoft/applicationinsights-web';

class Logger {
    static appInsights;
    static currentGroupId;
    static currentDatasetId;
    static currentNumberOfYears;
    static grid;

    constructor() {
        this.exception = this.exception.bind(this);
    }

    static initialize(instrumentationKey) {
        if (process.env.NODE_ENV === 'production' && !instrumentationKey) {
            console.error('ApplicationInsights are not working');
        }

        if (instrumentationKey) {
            Logger.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: instrumentationKey,

                    // Default 500 - controls how many ajax calls will be monitored per page view.
                    // Set to -1 to monitor all ajax calls on the page.
                    maxAjaxCallsPerView: -1,

                    // If true, the SDK will add two headers ('Request-Id' and 'Request-Context') to all
                    // CORS requests to correlate outgoing AJAX dependencies with corresponding requests on the server side.
                    // Default false.
                    enableCorsCorrelation: false,

                    // Logs internal Application Insights errors to console.
                    loggingLevelConsole: 0,

                    maxBatchInterval: 0,

                    // "enableDebug: false" keeps any errors in the logger from throwing and breaking execution, but they will rather be displayed as console warnings.
                    // `false` is especially needed because otherwise appinsights will break execution for overly long log messages.
                    // TODO: See if we can change to true on dev machines - monitor outcome of https://github.com/Microsoft/ApplicationInsights-JS/issues/750
                    enableDebug: false,

                    verboseLogging: true,
                },
            });

            Logger.appInsights.loadAppInsights();
        }
    }

    static log(message) {
        if (Logger.appInsights) {
            console.log(message);

            Logger.appInsights.trackTrace({
                message,
                severityLevel: SeverityLevel.Information,
                properties: {
                    groupId: Logger.currentGroupId,
                    datasetId: Logger.currentDatasetId,
                    numberOfYears: Logger.currentNumberOfYears,
                    grid: Logger.grid,
                },
            });
        }
    }

    static warn(message) {
        if (Logger.appInsights) {
            console.warn(message);

            Logger.appInsights.trackTrace({
                message,
                severityLevel: SeverityLevel.Warning,
                properties: {
                    groupId: Logger.currentGroupId,
                    datasetId: Logger.currentDatasetId,
                    numberOfYears: Logger.currentNumberOfYears,
                    grid: Logger.grid,
                },
            });
        }
    }

    /**
     * Alias to `error()`.
     * Simlarly how [console.exception() is an alias for console.error(); they are functionally identical.](https://developer.mozilla.org/en-US/docs/Web/API/console/error) )
     */
    static exception(...args) {
        this.error.apply(this, args);
    }

    /**
     * Logs the information passed, ensuring there's a stacktrace information
     * If `messageOrError` is an Error object, stack trace will point to the place of original `throw`, otherwise, it will only be a stacktrace of the code that called this logging method (this is the same behavior as `console.error`).
     */
    static error(messageOrError, props) {
        let additionalPropereties = {};
        if (messageOrError instanceof Event) {
            return Logger.warn(messageOrError);
        }

        let trackedError = new Error('Generic Error');

        if (typeof messageOrError === 'undefined' || !messageOrError)
            trackedError = new Error('Undefined Error');
        else if (messageOrError instanceof Error) {
            trackedError = messageOrError;
        } else if (messageOrError.hasOwnProperty('message'))
            trackedError = new Error(messageOrError.message);
        else if (typeof messageOrError === 'string') {
            trackedError = new Error(messageOrError);
        }

        if (typeof props === 'object' && Object.keys(props).length) {
            additionalPropereties = props;
        }

        if (Logger.appInsights) {
            Logger.appInsights.trackException({
                exception: trackedError,
                properties: {
                    ...additionalPropereties,
                    groupId: Logger.currentGroupId,
                    datasetId: Logger.currentDatasetId,
                    numberOfYears: Logger.currentNumberOfYears,
                    grid: Logger.grid,
                },
            });
        }
    }

    static trackPageView(pageName) {
        if (Logger.appInsights) {
            Logger.appInsights.trackPageView({
                name: pageName,
                properties: {
                    groupId: Logger.currentGroupId,
                    datasetId: Logger.currentDatasetId,
                    numberOfYears: Logger.currentNumberOfYears,
                    grid: Logger.grid,
                },
            });
        }
    }

    static trackDependency(
        id,
        method,
        name,
        absoluteUrl,
        duration,
        success,
        responseCode
    ) {
        if (Logger.appInsights) {
            Logger.appInsights.trackDependencyData({
                id,
                method,
                name,
                absoluteUrl,
                duration,
                success,
                responseCode,
                properties: {
                    groupId: Logger.currentGroupId,
                    datasetId: Logger.currentDatasetId,
                    numberOfYears: Logger.currentNumberOfYears,
                    grid: Logger.grid,
                },
            });
        }
    }

    static trackEvent(name, gridId) {
        if (Logger.appInsights) {
            Logger.appInsights.trackEvent({
                name,
                properties: {
                    groupId: Logger.currentGroupId,
                    datasetId: Logger.currentDatasetId,
                    numberOfYears: Logger.currentNumberOfYears,
                    gridId: gridId,
                },
            });
        }
    }

    static setGroup(groupId, datasetId) {
        Logger.currentGroupId = groupId;
        Logger.currentDatasetId = datasetId;
    }

    static setDataSize(numberOfYears) {
        Logger.currentNumberOfYears = numberOfYears;
    }

    static setAuthenticatedUserContext(username, userId) {
        if (Logger.appInsights) {
            Logger.appInsights.setAuthenticatedUserContext(username, userId);
        }
    }
}

export default Logger;
