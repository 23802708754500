import React from 'react';
import Select from 'react-select';
import { useRateStore } from '../store/RateStore';
import { getAvailableBounds } from '../models/RateBounds';

const Bound = (props) => {
    const store = useRateStore();

    const handleBoundChange = (event) => {
        const inputBound = event.value ? event : null;

        store.setBound(inputBound);
    };

    const getOptionLabel = (option) => {
        if (option.separator) {
            return (
                <div>
                    <hr />
                </div>
            );
        }
        return option.label;
    };

    return (
        <div
            className="inline"
            style={{
                width: '135px',
            }}
        >
            <Select
                className="basic-single"
                classNamePrefix="list"
                isSearchable={true}
                options={getAvailableBounds(props.data.type, props.parts)}
                getOptionLabel={getOptionLabel}
                onChange={handleBoundChange}
                isDisabled={store.isBoundDisabled || !store.isBoundValid}
                value={store.bound}
                openMenuOnFocus={true}
                menuPlacement="auto"
                placeholder="Rate bounds"
                styles={{
                    option: (styles) => ({
                        ...styles,
                        minHeight: 40,
                    }),
                    control: (styles) => ({
                        ...styles,
                        height: 38,
                    }),
                    input: (styles) => ({
                        ...styles,
                        margin: 0,
                    }),
                    menuList: (styles) => ({
                        ...styles,
                        maxHeight: 500,
                    }),
                }}
            />
        </div>
    );
};

export default Bound;
