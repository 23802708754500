import CommissionFormatter from '../formatters/CommissionFormatter';
import naturalSortComparator from './NaturalSortComparator';

const CommissionComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }

    const compareValueA = CommissionFormatter({ value: valueA });
    const compareValueB = CommissionFormatter({ value: valueB });

    return naturalSortComparator(compareValueA, compareValueB);
};

export default CommissionComparator;
