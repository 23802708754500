import React, { Component } from 'react';
import { modalService } from './modal-service';
import './Modal.scss';

export class Modal extends Component {
    state = {
        content: undefined,
    };

    componentDidMount() {
        modalService.setup(this);
    }

    open = ({ content }) => this.setState({ content });

    close = (e) => {
        this.stopEvent(e);

        this.setState({ content: undefined });
    };

    stopEvent(e) {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    stopNativeEvent = (ref) => {
        ref && ref.addEventListener('mousedown', this.stopEvent); // ag-grid listens to something like body.mousedown related events, probably focus, which kills cell editing and results in issues
    };

    render() {
        const { content } = this.state;

        if (content === undefined) {
            return null;
        }

        return (
            <div
                className="modal"
                onClick={this.stopEvent}
                ref={this.stopNativeEvent}
            >
                <div className="modal-overlay" onClick={this.close} />
                <div className="modal-content">{content}</div>
            </div>
        );
    }
}
