import {
    ENTITY_PART_TYPE_KNOWN,
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_COUNTRY,
    ENTITY_PART_TYPE_SEPARATOR,
    ENTITY_PART_TYPE_PLACEHOLDER,
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
    SEPARATOR_PLUS_FORWARD_SLASH,
} from './common/EntityPart';

export const LOCATIONS_ENTITY_SEPARATOR_CHARACTERS = [
    SEPARATOR_FORWARD_SLASH,
    SEPARATOR_PLUS,
    SEPARATOR_PLUS_FORWARD_SLASH,
];

export const createKnownLocationPart = (location) =>
    createPart(
        location.id,
        location.name,
        location.displayName,
        ENTITY_PART_TYPE_KNOWN,
        location.deskArea,
        location.deskAreaGeared,
        location.deskZone,
        location.globalZone,
        location.isSTS
    );

export const createCountryLocationPart = (location) =>
    createPart(
        location.id,
        location.name,
        null,
        ENTITY_PART_TYPE_COUNTRY,
        location.deskArea,
        location.deskAreaGeared,
        location.deskZone,
        null,
        false
    );

export const createCustomLocationPart = (location) =>
    createPart(
        location.name,
        null,
        null,
        ENTITY_PART_TYPE_CUSTOM,
        null,
        null,
        null,
        null,
        false
    );

export const createPlaceholderLocationPart = (character) =>
    createPart(
        character,
        null,
        null,
        ENTITY_PART_TYPE_PLACEHOLDER,
        null,
        null,
        null,
        null,
        false
    );

export const createSeparatorLocationPart = (character) =>
    createPart(
        character,
        null,
        null,
        ENTITY_PART_TYPE_SEPARATOR,
        null,
        null,
        null,
        null,
        false
    );

const createPart = (
    value,
    name,
    displayName,
    partType,
    deskArea,
    deskAreaGeared,
    deskZone,
    globalZone,
    isSTS
) => {
    return {
        value,
        name,
        displayName,
        partType,
        deskArea,
        deskAreaGeared,
        deskZone,
        globalZone,
        isSTS,
    };
};

export const isRangesLocation = (locationType) => {
    return locationType === 'Ranges';
};

export const isWorldwideZoneLocation = (locationType) => {
    return locationType === 'WorldwideZone';
};
