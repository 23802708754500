import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

interface UpdateLayoutConfirmationDialogProps {
    open: boolean;
    onCancel: () => void;
    onContinue: () => void;
    onSaveAs: () => void;
}

const UpdateTemplateLayoutConfirmationDialog: React.FC<
    UpdateLayoutConfirmationDialogProps
> = ({ open, onCancel, onContinue, onSaveAs }) => {
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            onClick={(e) => e.stopPropagation()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                component={Stack}
                direction="row"
                id="alert-dialog-title"
                sx={{ p: 'auto', justifyContent: 'center' }}
            >
                <WarningIcon
                    color="error"
                    sx={{
                        display: 'block',
                        marginY: 'auto',
                        marginRight: '10px',
                    }}
                />
                <Box>Warning</Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{ fontWeight: 'bold' }}
                >
                    Changes to the template layout will affect all users in this
                    dataset.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCancel}
                    variant="contained"
                    color="primary"
                    autoFocus
                >
                    {'Cancel'}
                </Button>
                <Button onClick={onContinue} variant="outlined" color="primary">
                    {'Continue'}
                </Button>
                <Button onClick={onSaveAs} variant="outlined" color="primary">
                    {'Save As'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateTemplateLayoutConfirmationDialog;
