import { Component, Fragment } from 'react';
import React from 'react';
import StripedLoader from './common/StripedLoader';

export default class Loader extends Component {
    constructor(props) {
        super(props);
        this.promise = props.promise;

        this.state = { dataLoaded: false };
    }
    async componentDidMount() {
        try {
            await this.promise;
            this.setState({ dataLoaded: true });
        } catch (e) {
            this.setState({ dataLoaded: false });
        }
    }
    render() {
        return (
            <Fragment>
                {this.state.dataLoaded ? (
                    this.props.children
                ) : (
                    <div style={{ marginTop: '270px' }}>
                        <StripedLoader />
                    </div>
                )}
            </Fragment>
        );
    }
}
