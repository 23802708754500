import FreightPortBasisComparator from './FreightPortBasisComparator';

const MultiFreightPortBasisComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }

    const compareValueA = valueA.parts || [];
    const compareValueB = valueB.parts || [];

    if (compareValueA.length === 0 && compareValueB.length === 0) {
        return 0;
    }

    if (compareValueA.length === 0) {
        return -1;
    }

    if (compareValueB.length === 0) {
        return 1;
    }

    let result = FreightPortBasisComparator(compareValueA[0], compareValueB[0]);
    if (result !== 0) return result;

    let index = 0;
    while (
        (result === 0 && compareValueA[index + 1] !== undefined) ||
        compareValueB[index + 1] !== undefined
    ) {
        index++;
        result = FreightPortBasisComparator(
            compareValueA[index],
            compareValueB[index]
        );
    }
    return result;
};

export default MultiFreightPortBasisComparator;
