import Logger from '../../../diagnostics/logging/logger';
import MixpanelLogger from '../../../diagnostics/logging/mixpanelLogger';
import { cloneDeep } from 'lodash';

export const createReinstateMenuItem = (props, node, gridId) => {
    const { group, data } = node;
    const { id, privacy, groupOnly, __typename: typeName } = data;
    const { selectedGroup } = props;

    const title = 'Reinstate';
    const tooltip = 'Reinstate this row';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.reinstate', gridId);
                MixpanelLogger.trackReInstateDeletedItemEvent(
                    id,
                    typeName,
                    groupOnly,
                    privacy,
                    selectedGroup.name
                );

                const clonedData = cloneDeep(data);
                props.onReinstate(clonedData);
            }
        },
        tooltip: tooltip,
    };
};
