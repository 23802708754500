import { gql } from '@apollo/client';
import graphQlClient from './graphQlClient';
import { UserGroupsQuery } from './queries/GroupQueries';

type GroupInput = {
    name: string;
    datasetId: number;
};

type Group = {
    id: number;
    name: string;
    datasetId: number;
    datasetName: string;
    createdBy: {
        username: string;
    };
    createdDate: string;
    groupOnlyExpiryPeriod?: string;
};

type UserGroup = {};

class GroupApi {
    static add = ({ name, datasetId }: GroupInput): Promise<Group> => {
        return graphQlClient()
            .mutate<{ createGroup: Group }>({
                mutation: gql`
                    mutation CreateGroup($group: GroupInput!) {
                        createGroup(group: $group) {
                            id
                            name
                            datasetId
                            datasetName
                            createdBy {
                                username
                            }
                            createdDate
                        }
                    }
                `,
                variables: {
                    group: {
                        name,
                        datasetId,
                    },
                },
            })
            .then((result) => result.data?.createGroup);
    };

    static getAll = (): Promise<Group[]> =>
        graphQlClient()
            .query<{ groups: Group[] }>({
                query: gql`
                    {
                        groups {
                            id
                            name
                            datasetId
                            datasetName
                            createdBy {
                                username
                            }
                            createdDate
                            groupOnlyExpiryPeriod
                        }
                    }
                `,
            })
            .then((result) => result.data.groups);

    static getUserGroups = (userId: string): Promise<UserGroup[]> => {
        return new Promise((resolve) => {
            graphQlClient()
                .query<{ userGroups: UserGroup[] }>({
                    query: UserGroupsQuery,
                    fetchPolicy: 'no-cache',
                    variables: {
                        userId,
                    },
                })
                .then((result) => {
                    resolve(result.data.userGroups);
                });
        });
    };
}

export default GroupApi;
