import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import ClearFilterIcon from '../../icons/EmptyFilterIcon';
import FilterIcon from '../../icons/FilterIcon';
import ToolbarButton from '../ToolbarButton';
import FiltersMenu from './filtersMenu/FiltersMenu';
import EmptyFiltersMenu from './EmptyFiltersMenu';
import Popover from '@material-ui/core/Popover';
import RestoreFilterIcon from '../../icons/RestoreFilterIcon';
import WithFilters from './filtersMenu/HOCs/WithFilters';
import { LightTooltip } from './../../ui/LightTooltip';

const Filters = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [savedFilters, setSavedFilters] = React.useState([]);
    const [unsavedFilters, setUnsavedFilters] = React.useState([]);

    useEffect(() => {
        setFiltersStates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentFilters, props.initialFilters]);

    const setFiltersStates = () => {
        const { currentFilters, initialFilters, headings } = props;

        const initialFiltersArray = Object.entries(initialFilters).map(
            ([key, value]) => ({
                key,
                label: getFilterLabel(headings, key),
                value,
                checked: key in currentFilters,
            })
        );

        const currentFiltersArray = Object.entries(currentFilters)
            .map(([key, value]) => ({
                key,
                label: getFilterLabel(headings, key),
                value,
                checked: true,
            }))
            .filter((o) => !(o.key in initialFilters));

        setSavedFilters(initialFiltersArray);
        setUnsavedFilters(currentFiltersArray);
    };

    const handleOnOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setFiltersStates();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const areAnyFiltersApplyed = () =>
        !isEmpty(props.initialFilters) || !isEmpty(props.currentFilters);

    const handleOnReset = () => {
        props.clearUnsavedFilters(unsavedFilters);
    };

    const getFilterLabel = (headings, key) => {
        const { datasetId } = props;
        const heading = headings.find(
            (h) => h.colId === key || h.field === key
        );

        return heading.headerValueGetter
            ? heading.headerValueGetter({ context: { datasetId } })
            : heading.headerName;
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <LightTooltip
                title="Manage both saved and unsaved filters"
                placement="bottom"
                arrow
            >
                <div style={{ display: 'flex' }}>
                    <ToolbarButton onClick={handleOnOpen} id={'Filters'}>
                        Filters
                        {areAnyFiltersApplyed() ? (
                            <FilterIcon />
                        ) : (
                            <ClearFilterIcon />
                        )}
                    </ToolbarButton>
                </div>
            </LightTooltip>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {savedFilters.length > 0 || unsavedFilters.length > 0 ? (
                    <FiltersMenu
                        {...props}
                        savedFilters={savedFilters}
                        setSavedFilters={setSavedFilters}
                        unsavedFilters={unsavedFilters}
                        setUnsavedFilters={setUnsavedFilters}
                        handleClose={handleClose}
                    />
                ) : (
                    <EmptyFiltersMenu />
                )}
            </Popover>
            {unsavedFilters.length > 0 && (
                <LightTooltip
                    title="Clear all unsaved filters"
                    placement="bottom"
                    arrow
                >
                    <div style={{ display: 'flex' }}>
                        <ToolbarButton onClick={handleOnReset} id={'Reset'}>
                            Reset
                            <RestoreFilterIcon />
                        </ToolbarButton>
                    </div>
                </LightTooltip>
            )}
        </React.Fragment>
    );
};

export default WithFilters(Filters);
