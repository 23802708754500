import { getDataset } from '_legacy/models/Datasets';
import { OrderHeadings } from '../../components/orders/OrderHeadings';
import FixtureHeadings from '../../components/fixtures/FixtureHeadings';

export default function getHeadingTitle(datasetId, field, isFixtureHeading) {
    var heading = isFixtureHeading
        ? FixtureHeadings.find((h) => h.field === field || h.colId === field)
        : OrderHeadings.find((h) => h.field === field || h.colId === field);

    if (heading) {
        return (
            heading.headerName ?? getDataset(datasetId).headingsMapping[field]
        );
    } else {
        return null;
    }
}
