import React, { Component } from 'react';
import classNames from 'classnames';

export class AuditActionPill extends Component {
    render() {
        let text = null;
        let colour = null;

        switch (this.props.action) {
            case 'created':
                text = 'CREATED';
                colour = 'green';
                break;
            case 'edited':
                text = 'EDITED';
                colour = 'grey';
                break;
            case 'action':
                text = 'ACTION';
                colour = 'orange';
                break;
            case 'confidentiality':
                text = 'CONFIDENTIALITY';
                colour = 'orange';
                break;
            case 'visibility':
                text = 'VISIBILITY';
                colour = 'orange';
                break;
            case 'deleted':
                text = 'DELETED';
                colour = 'red';
                break;
            case 'withdrawn':
                text = 'WITHDRAWN';
                colour = 'red';
                break;
            case 'converted':
                text = 'CONVERTED';
                colour = 'green';
                break;
            case 'reinstated':
                text = 'REINSTATED';
                colour = 'green';
                break;
            case 'copied':
                text = 'COPIED';
                colour = 'green';
                break;
            case 'failed':
                text = 'FAILED';
                colour = 'red';
                break;
            default:
        }

        let pillClass = classNames('ui', colour, 'label');

        return <span className={pillClass}>{text}</span>;
    }
}

export default AuditActionPill;
