import { isEditableForTbnRow } from '../tools/headingTools';
import { commonRowAndCellClassRules } from '../../../../components/grid/classRules/classRules';
import BlanksFilterRenderer from '../../../../components/grid/renderers/BlanksFilterRenderer';
import naturalSortComparator from '../../../../components/grid/comparators/NaturalSortComparator';
import defaultObjEquals from '../../../../components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '../../../../components/grid/columnDefaults/defaultSetFilterParams';
import SelectEditor from '../../../shared/columns/editors/SelectEditor';
import { DefaultFixtureImoMapping } from '../mappings/imoClassMappings';
import ImoClassFormatter from '../formatters/ImoClassFormatter';

export const vesselImoClassHeading = {
    colId: 'imoClass',
    headerName: 'IMO Class',
    field: 'vessel.imoClass',
    editable: isEditableForTbnRow,
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        values: DefaultFixtureImoMapping.map((m) => m.id),
    },
    valueGetter: (value) =>
        ImoClassFormatter({ value: value.data.vessel?.imoClass }),
    valueParser: (value) =>
        DefaultFixtureImoMapping.filter((m) => m.id === value)[0]?.data,
    comparator: naturalSortComparator,
    equals: defaultObjEquals,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    width: 120,
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
        cellRenderer: BlanksFilterRenderer,
    },
};
