import Logger from '../../../diagnostics/logging/logger';
import { cloneDeep } from 'lodash';

export const createCopyFixtureWithCargoInfoMenuItem = (props, node, gridId) => {
    const { group, data } = node;
    const title = 'Create new Fixture with Cargo Info';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent(
                    'grid.context.copyFixtureWithCargoInfo',
                    gridId
                );

                const clonedData = cloneDeep(data);
                props.onCopyFixtureWithCargoInfo(clonedData);
            }
        },
        tooltip: title,
    };
};
