import { DATASET_DRY_CARGO } from '../../../models/Datasets';

const TypeFormatter = (params) => {
    const { value, node, datasetId } = params;

    const currentDatasetId =
        node && node.data ? node.data.datasetId : datasetId;

    if (
        value === 'VOY' &&
        node !== null &&
        !node.group &&
        currentDatasetId !== DATASET_DRY_CARGO
    ) {
        return '';
    }

    return value;
};

export const AllTypeFormatter = (value) => {
    if (!value) {
        return '';
    }

    return value;
};

export default TypeFormatter;
