import AuditHistoryApi from '_legacy/api/auditHistoryApi';

export class AuditHistoryService {
    static async createExportHistory(
        format,
        itemsCount,
        recordType,
        auditParams
    ) {
        const { groupId, displayedColumns, initialFilterOptions } = auditParams;

        const actionData = {
            recordType,
            itemsCount,
            displayedColumns,
            initialFilterOptions,
        };

        const variables = {
            groupId,
            actionType: 'export' + format,
            actionData: JSON.stringify(actionData),
        };

        await AuditHistoryApi.logExportHistory(variables);
    }
}
