import React, { Component } from 'react';

class LaycanInput extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        const { value, initialChar } = this.props;

        let laycan = value;

        if (!value) {
            laycan = {
                shorthand: null,
                startDate: null,
                endDate: null,
            };
        } else {
            laycan = {
                ...laycan,
            };
        }

        let inputValue = '';
        if (initialChar) {
            inputValue = initialChar;
        } else {
            inputValue = laycan.shorthand || '';
        }

        this.state = {
            inputValue,
            startDate: laycan.startDate,
            endDate: laycan.endDate,
        };
    }

    get inputValue() {
        return {
            shorthand: this.state.inputValue,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
        };
    }

    reset = () => {
        this.setState({
            inputValue: '',
            startDate: null,
            endDate: null,
        });
    };

    handleOnChange = (event) => {
        this.setState({
            inputValue: event.target.value,
            startDate: null,
            endDate: null,
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
    };

    focus = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
            if (!this.props.initialChar) {
                this.inputRef.current.select();
            }
        }
    };

    hasFocus() {
        return this.inputRef.current === document.activeElement;
    }

    render() {
        return (
            <div className={this.props.inputClass}>
                <input
                    ref={this.inputRef}
                    type="text"
                    style={{ width: '100%' }}
                    value={this.state.inputValue}
                    onChange={this.handleOnChange}
                    disabled={this.props.disabled}
                />
            </div>
        );
    }
}

export default LaycanInput;
