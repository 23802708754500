import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import { CREATE_FIXTURE_MUTATION } from '../../api/queries/fixtures/FixtureQueries';
import { Dropdown } from 'semantic-ui-react';
import GlobalShortcut from '../GlobalShortcut';
import ToolbarButton from '../toolbar/ToolbarButton';
import { isWithinMenu } from '../common/Menu';
import VisibilityIcon from '../icons/VisibilityIcon';
import IndividualIcon from '../icons/IndividualIcon';
import GroupOnlyLabelRenderer from '../grid/renderers/GroupOnlyLabelRenderer';
import IndividualLabelRenderer from '../grid/renderers/IndividualLabelRenderer';
import MixpanelLogger from '../../diagnostics/logging/mixpanelLogger';
import { KEY_CODES } from 'constant';

const PUBLIC_FIXTURE_VALUE = 1;
const PRIVATE_FIXTURE_VALUE = 2;
const GROUP_ONLY_FIXTURE_VALUE = 3;
const INDIVIDUAL_FIXTURE_VALUE = 4;

const { KEY_F } = KEY_CODES;

export class AddFixture extends Component {
    constructor(props) {
        super(props);

        // State is used so that the value of the dropdown can be reset
        // each time a value is picked, otherwise the control doesn't always work
        this.state = { dropdownValue: -1 };

        this.handleCompleted = this.handleCompleted.bind(this);
        this.handleAddFixture = this.handleAddFixture.bind(this);
        this.isAddFixtureKeyPress = this.isAddFixtureKeyPress.bind(this);
    }

    handleAddFixture = (addFixture, groupOnly, privacy, isIndividual) => {
        const { groupId } = this.props;
        const type =
            this.props.selectedLayout.commonSettings.defaultType.currentState;

        const variables = {
            groupId,
            groupOnly,
            privacy,
            isIndividual,
            type,
        };

        addFixture({
            variables,
        });

        this.setState({ dropdownValue: -1 });
    };

    isAddFixtureKeyPress = (event) => {
        if (event.altKey && event.ctrlKey && event.keyCode === KEY_F) {
            event.preventDefault();
            event.stopPropagation();
            return true;
        }
        return false;
    };

    handleCompleted(data) {
        const { id, groupOnly, privacy, groupName, isIndividual, datasetId } =
            data.createFixture;

        this.setState({ dropdownValue: -1 }, () => {
            if (this.props.onCompleted) {
                this.props.onCompleted(data.createFixture);
            }
        });

        MixpanelLogger.trackNewFixtureEvent(
            id,
            groupOnly,
            privacy,
            isIndividual,
            groupName,
            'Created',
            datasetId
        );
    }

    getOptions = () => [
        { key: 1, text: 'Public Fixture', value: PUBLIC_FIXTURE_VALUE },
        { key: 2, text: 'Private Fixture', value: PRIVATE_FIXTURE_VALUE },
        {
            key: 3,
            text: <GroupOnlyLabelRenderer documentName={'Fixture'} />,
            value: GROUP_ONLY_FIXTURE_VALUE,
            icon: <VisibilityIcon />,
        },
        {
            key: 4,
            text: <IndividualLabelRenderer documentName={'Fixture'} />,
            value: INDIVIDUAL_FIXTURE_VALUE,
            icon: <IndividualIcon />,
        },
    ];

    render = () => {
        return (
            <Mutation
                mutation={CREATE_FIXTURE_MUTATION}
                onCompleted={this.handleCompleted}
                fetchPolicy={'no-cache'}
            >
                {(addFixture, { loading }) => {
                    return (
                        <Fragment>
                            {!this.props.disabled && !loading && (
                                <GlobalShortcut
                                    onMatch={() =>
                                        this.handleAddFixture(
                                            addFixture,
                                            false,
                                            false,
                                            false
                                        )
                                    }
                                    keyMatcher={this.isAddFixtureKeyPress}
                                />
                            )}
                            <Dropdown
                                trigger={
                                    <ToolbarButton id="NewFixtureButton">
                                        <i className="icons plus-icon" />
                                        New Fixture
                                    </ToolbarButton>
                                }
                                options={this.getOptions()}
                                onChange={(e, data) => {
                                    /*
                                     * This ensures the user has actually clicked on a menu item as the dropdown onChange
                                     * gets triggered by a blur event. Defect 25602
                                     * Same logic used in AddOrder.jsx
                                     */
                                    if (
                                        isWithinMenu(e.target) ||
                                        e.key === 'Enter'
                                    ) {
                                        const groupOnly =
                                            data.value ===
                                            GROUP_ONLY_FIXTURE_VALUE;
                                        const isIndividual =
                                            data.value ===
                                            INDIVIDUAL_FIXTURE_VALUE;
                                        const privacy =
                                            data.value ===
                                                PRIVATE_FIXTURE_VALUE ||
                                            groupOnly ||
                                            isIndividual;

                                        this.handleAddFixture(
                                            addFixture,
                                            groupOnly,
                                            privacy,
                                            isIndividual
                                        );
                                    }
                                }}
                                value={this.state.dropdownValue}
                                disabled={this.props.disabled || loading}
                            />
                        </Fragment>
                    );
                }}
            </Mutation>
        );
    };
}

AddFixture.propTypes = {
    onCompleted: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        currentGroup: state.user && state.user.group ? state.user.group : null,
        selectedLayout: state.layouts.selectedLayout,
    };
};

export default connect(mapStateToProps)(AddFixture);
