import { unassignedCategoryText } from '_legacy/constants/gridText';
import { isEditableRow } from '../../../shared/columns/tools/headingTools';
import { vesselCategoriesByDataset } from '../models/VesselCategory';
import { commonRowAndCellClassRules } from '../../../../components/grid/classRules/classRules';
import CategoryComparator from '../comparators/CategoryComparator';
import BlanksFilterRenderer from '../../../../components/grid/renderers/BlanksFilterRenderer';
import naturalSortComparator from '../../../../components/grid/comparators/NaturalSortComparator';
import defaultSetFilterParams from '../../../../components/grid/columnDefaults/defaultSetFilterParams';
import allowUpAndDownOnEdit from '../../../../components/grid/columnDefaults/allowUpAndDownOnEdit';
import MultiSelect from '_legacy/modules/shared/columns/editors/MultiSelect';

export const vesselCategoryHeading = {
    colId: 'vesselCategory',
    headerName: 'Category',
    field: 'vesselCategory',
    editable: isEditableRow,
    cellEditorFramework: MultiSelect,
    cellEditorParams: {
        valuesByDataset: vesselCategoriesByDataset,
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    type: ['privatable'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    keyCreator: ({ value }) => {
        if (!value) {
            return unassignedCategoryText;
        }
        return value;
    },
    enableRowGroup: true,
    comparator: CategoryComparator,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 67,
    hide: true,
};
