import { blankKeyText } from '_legacy/constants/gridText';
import CommissionFormatter from '../formatters/CommissionFormatter';

const CommissionKeyCreator = ({ value }) => {
    if (!value || value.length === 0) {
        return blankKeyText;
    }
    return CommissionFormatter({ value });
};

export default CommissionKeyCreator;
