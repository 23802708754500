import { isEditableForTbnRow } from '../tools/headingTools';
import YobEditor from '../editors/YobEditor';
import { commonRowAndCellClassRules } from '../../../../components/grid/classRules/classRules';
import NamePropertyComparator from '../../../../components/grid/comparators/NamePropertyComparator';
import defaultObjEquals from '../../../../components/grid/columnDefaults/defaultObjEquals';

export const vesselYobHeading = {
    colId: 'yob',
    headerName: 'YOB',
    field: 'vessel.yob',
    editable: isEditableForTbnRow,
    cellEditorFramework: YobEditor,
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    suppressMenu: true,
    filter: false,
    width: 120,
};
