import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./cargoQuantityPartFragment.gql');

export const CARGO_QTY_TYPE_NAME = 'QuantityPart';
export const CARGO_QTY_FRAGMENT_NAME = 'CargoQuantityPartFragment';
export const CARGO_QTY_FRAGMENT = gql`
    ${FRAGMENT}
`;
