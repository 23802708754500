import React, { Component } from 'react';
import { configureHeader, constants } from '../../auth/clarksons';
import { cloudHeaderCdnUrl, seaIdentityUrl } from '../../config';
import MixpanelLogger from '_legacy/diagnostics/logging/mixpanelLogger';
import { authenticationService } from '_legacy/auth/AuthenticationService';

export const pinnedCallback = () => {
    var container = document.getElementById('appContainer');
    if (container) {
        container.classList.add(constants.navbarPinnedClass);
    }
};

export const unpinnedCallback = () => {
    var container = document.getElementById('appContainer');
    if (container) {
        container.classList.remove(constants.navbarPinnedClass);
    }
};

export const getTokenCallback = () => authenticationService.getAccessToken();

export const logoutCallback = async () => await authenticationService.signOut();

export default class ClarksonsCloudHeader extends Component {
    static CloudNavBarId = 'navbar';
    constructor() {
        super();
        this.cloudNavbarRef = React.createRef();
        this.cloudNavBarLoad = this.cloudNavBarLoad.bind(this);
    }

    cloudNavBarLoad() {
        const seaHeader = configureHeader({
            container: this.cloudNavbarRef.current,
            authority: seaIdentityUrl,
            moduleId: constants.fortModuleId,
            getTokenCallback: getTokenCallback,
            pinnedCallback: pinnedCallback,
            unpinnedCallback: unpinnedCallback,
            logoutCallback: logoutCallback,
        });

        MixpanelLogger.initialize(seaHeader);
    }

    componentDidMount() {
        let navBarScript = document.getElementById('ClarksonsNavbarScript');
        if (navBarScript) {
            const now = new Date();
            const timestamp = `${now.getFullYear()}${
                now.getMonth() + 1
            }${now.getDate()}${now.getHours()}`;

            navBarScript.async = true;
            navBarScript.src = `${cloudHeaderCdnUrl}?v=${timestamp}`;
            navBarScript.onload = this.cloudNavBarLoad;
        } else {
            this.cloudNavBarLoad();
        }
    }

    render() {
        return (
            <div>
                <div
                    ref={this.cloudNavbarRef}
                    id={ClarksonsCloudHeader.CloudNavBarId}
                />
                <div id="wrapper" />
            </div>
        );
    }
}
