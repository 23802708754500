import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes.js';

const GroupColumn = ({
    id,
    header,
    index,
    moveGroupRow,
    checked,
    changeVisibility,
}) => {
    const ref = useRef(null);

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.ROW_GROUP,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            //Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveGroupRow(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ opacity }, drag, dragPreview] = useDrag({
        type: ItemTypes.ROW_GROUP,
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging() ? 0.3 : 1,
        }),
    });

    const visibilityIcon = checked ? 'ag-icon-eye' : 'ag-icon-eye-slash';
    const background = checked ? 'rgb(33, 150, 243)' : undefined;
    const color = checked ? 'white' : undefined;
    const fontColor = checked ? 'white' : 'black';

    drag(drop(ref));

    return (
        <span
            className={`ag-column-drop-cell ag-column-drop-vertical-cell`}
            style={{ background, opacity }}
            ref={dragPreview}
            data-handler-id={handlerId}
        >
            <span
                className="ag-drag-handle ag-column-drop-cell-drag-handle ag-column-drop-vertical-cell-drag-handle ag-column-drop-cell-text"
                style={{ marginRight: 0 }}
                ref={ref}
                unselectable="on"
            >
                <span>
                    <span
                        className={`ag-icon ag-icon-grip`}
                        style={{ color, float: 'left' }}
                    ></span>
                    <span
                        className={`ag-column-drop-cell-text ag-column-drop-vertical-cell-text`}
                        style={{ color: fontColor }}
                    >
                        {header}
                    </span>
                </span>
            </span>
            <span className="ag-column-drop-cell-button ag-column-drop-vertical-cell-button">
                <span
                    className={`ag-icon ${visibilityIcon}`}
                    style={{ color, fontSize: '25px' }}
                    onClick={() => changeVisibility(id)}
                ></span>
            </span>
        </span>
    );
};

export default GroupColumn;
