import React, { Component } from 'react';
import classNames from 'classnames';

class FilterCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = { checked: props.checked };
        this.handleOnClick = this.handleOnClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.checked !== prevProps.checked) {
            this.setState({ checked: this.props.checked });
        }
    }

    handleOnClick(sender) {
        this.setState({ checked: !this.state.checked }, () => {
            this.props.onChange(this.props.index, this.state.checked);
        });
    }

    get checked() {
        return this.state.checked;
    }

    render() {
        const iconClass = classNames('filterCheckbox-icon', {
            checked: this.state.checked,
            unchecked: !this.state.checked,
        });

        return (
            <div className="filterCheckbox" onClick={this.handleOnClick}>
                <span className={iconClass} />
                <span>{this.props.label.toUpperCase()}</span>
            </div>
        );
    }
}

// FilterCheckbox.defaultProps = {
//   checked: false,
// };

// FilterCheckbox.propTypes = {
//   checked: PropTypes.bool,
//   index: PropTypes.number.isRequired,
//   label: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
// };

export default FilterCheckbox;
