import TooltipRenderer from '_legacy/components/grid/renderers/TooltipRenderer';
import BlanksFilterRenderer from '_legacy/components/grid/renderers/BlanksFilterRenderer';
import suppressOnEdit from '_legacy/components/grid/columnDefaults/suppressOnEdit';
import defaultObjEquals from '_legacy/components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';

import DurationPartsComparator from '../comparators/DurationPartsComparator';
import DurationEditor from '../editors/DurationEditor';
import { MultipleDurationFormatter } from '../formatters/MultipleDurationFormatter';
import DurationPartsKeyCreator from '../keyCreators/DurationPartsKeyCreator';
import { isEditableRow } from '../../../shared/columns/tools/headingTools';

export const durationPartsHeading = {
    colId: 'durationParts',
    headerName: 'Duration',
    field: 'durationParts',
    editable: isEditableRow,
    valueFormatter: MultipleDurationFormatter,
    cellEditorFramework: DurationEditor,
    cellRenderer: (duration) =>
        TooltipRenderer({ value: duration.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    equals: defaultObjEquals,
    comparator: DurationPartsComparator,
    keyCreator: DurationPartsKeyCreator,
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filter: 'durationFilter',
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    hide: true,
};
