import { unassignedCgoText } from '../../../constants/gridText';
import MultipleCargoFormatter from '../formatters/MultipleCargoFormatter';

const CargoPartsKeyCreator = ({ value }) => {
    if (!value || value.length === 0) {
        return unassignedCgoText;
    }

    // should be used just the first value for grouping
    const keyValue = value[0];

    return MultipleCargoFormatter({ value: keyValue });
};

export default CargoPartsKeyCreator;
