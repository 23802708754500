const CommissionFormatter = ({ value }) => {
    if (value && (value.commissionValue || value.brokersCommissionValue)) {
        return !value.brokersCommissionValue
            ? `${value.commissionValue}`
            : !value.commissionValue
            ? `0/${value.brokersCommissionValue}`
            : `${value.commissionValue}/${value.brokersCommissionValue}`;
    }
    return '';
};

export default CommissionFormatter;
