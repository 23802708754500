import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Input, Radio } from 'semantic-ui-react';
import * as userActions from '../../actions/userActions';
import { Excel } from '../icons/Excel';
import { Email } from '../icons/Email';
import WarningIconOrange from '../icons/WarningIconOrange';

export class ExportOptions extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            format: null,
            reportTitle: null,
            includesNonConfidential: this.props.includesNonConfidential,
            includesConfidential: this.props.includesConfidential,
            exportConfidential: false,
            includesGroupHeadings: this.props.includesGroupHeadings,
            shouldExportGroupHeadings: false,
        };
        this.options = [
            { id: 'excel', label: 'Excel', Icon: Excel },
            { id: 'email', label: 'Email', Icon: Email },
        ];

        this.onClose = this.props.onClose;
        this.onExport = this.onExport.bind(this);
    }

    onExport() {
        if (this.state.format === 'email') {
            let titles =
                this.props.reportTitles.indexOf(this.state.reportTitle) === -1
                    ? [...this.props.reportTitles, this.state.reportTitle]
                    : this.props.reportTitles;
            this.props.actions.reportTitlesSet(titles);
        }
        this.props.onExport(this.state);
    }

    isExportDisabled = () => {
        const noFormatSelected = this.state.format === null;
        const isEmailAndHasNoTitle =
            this.state.format === 'email' &&
            (this.state.reportTitle === null ||
                this.state.reportTitle.trim() === '');
        const onlyConfidentialsAndSetToExclude =
            !this.state.includesNonConfidential &&
            !this.state.exportConfidential;

        return (
            noFormatSelected ||
            isEmailAndHasNoTitle ||
            onlyConfidentialsAndSetToExclude
        );
    };

    renderEmailOptions() {
        return (
            this.state.format === 'email' && (
                <div className="export-options-options">
                    <Input
                        list="titles"
                        ref={(ref) => ref && ref.focus()}
                        placeholder="Report title"
                        onChange={(e, data) =>
                            this.setState({ reportTitle: data.value })
                        }
                    />
                    {this.renderTitlesDataList()}
                </div>
            )
        );
    }

    toggleExcludeInclude = () =>
        this.setState({ exportConfidential: !this.state.exportConfidential });

    toggleExcludeIncludeGroupHeadings = () =>
        this.setState({
            shouldExportGroupHeadings: !this.state.shouldExportGroupHeadings,
        });

    shouldRenderConfidentialOptions() {
        return this.props.includesConfidential;
    }

    shouldRenderGroupHeadingsOptions() {
        return (
            this.props.includesGroupHeadings && this.state.format !== 'email'
        );
    }

    renderExportOptions() {
        if (
            this.shouldRenderConfidentialOptions() ||
            this.shouldRenderGroupHeadingsOptions()
        ) {
            return (
                <div>
                    <table>
                        <tbody>
                            {this.renderGroupHeadingsOptions()}
                            {this.renderConfidentialityOptions()}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return null;
        }
    }

    renderGroupHeadingsOptions() {
        if (this.shouldRenderGroupHeadingsOptions()) {
            return (
                <>
                    {this.renderBlankTableRow()}
                    {this.renderRadioOptions(
                        this.state.shouldExportGroupHeadings,
                        this.toggleExcludeIncludeGroupHeadings,
                        'group headings'
                    )}
                </>
            );
        } else {
            return null;
        }
    }

    renderConfidentialityOptions() {
        if (this.shouldRenderConfidentialOptions()) {
            return (
                <>
                    {this.renderBlankTableRow()}
                    {this.renderConfidentialityWarning()}
                    {this.renderBlankTableRow()}
                    {this.renderRadioOptions(
                        this.state.exportConfidential,
                        this.toggleExcludeInclude,
                        'confidential data'
                    )}
                </>
            );
        } else {
            return null;
        }
    }

    renderConfidentialityWarning() {
        return (
            <tr>
                <td colSpan="2" style={{ textAlign: 'center' }}>
                    {' '}
                    <span style={{ fontSize: 'smaller', fontWeight: 600 }}>
                        <WarningIconOrange width={15} height={15} />
                        &nbsp; Note: Exporting confidental data
                    </span>
                </td>
            </tr>
        );
    }

    renderBlankTableRow() {
        return <tr style={{ height: '15px' }}></tr>;
    }

    renderRadioOptions(isChecked, onChange, optionsName) {
        return (
            <tr>
                <td>
                    <Radio
                        checked={!isChecked}
                        onChange={onChange}
                        label={`Exclude ${optionsName}`}
                    />
                </td>
                <td>
                    <Radio
                        checked={isChecked}
                        onChange={onChange}
                        label={`Include ${optionsName}`}
                    />
                </td>
            </tr>
        );
    }

    renderTitlesDataList() {
        return (
            this.props.reportTitles &&
            this.props.reportTitles.length !== 0 && (
                <datalist id="titles">
                    {this.props.reportTitles.map((value) => (
                        <option key={value} value={value} />
                    ))}
                </datalist>
            )
        );
    }

    renderExportFormatOptions() {
        return (
            <div className="export-options-formats">
                {this.options.map(({ Icon, id, label }) => (
                    <div
                        className={`export-options-format ${
                            id === this.state.format ? 'selected' : ''
                        }`}
                        key={id}
                    >
                        <Icon
                            onClick={() =>
                                this.setState((prevState) => ({
                                    format: id,
                                    shouldExportGroupHeadings:
                                        prevState.shouldExportGroupHeadings &&
                                        id !== 'email',
                                }))
                            }
                        />
                        <span>{label}</span>
                    </div>
                ))}
            </div>
        );
    }

    renderConfirmationButtons() {
        let isExportDisabled = this.isExportDisabled();

        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                }}
            >
                <Button
                    basic
                    color="blue"
                    name="close-button"
                    onClick={this.onClose}
                >
                    CLOSE
                </Button>
                <Button
                    primary
                    name="export-button"
                    onClick={this.onExport}
                    disabled={isExportDisabled}
                >
                    EXPORT
                </Button>
            </div>
        );
    }

    render() {
        return (
            <div
                id="export-options"
                className="export-options"
                style={{ minWidth: 300 }}
            >
                <h3>Export</h3>
                <div>Choose export format</div>
                {this.renderExportFormatOptions()}
                {this.renderEmailOptions()}
                {this.renderExportOptions()}
                <br />
                {this.renderConfirmationButtons()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        reportTitles: state.user.reportTitles || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...userActions }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExportOptions);
