import React, { Component } from 'react';

export class NotFoundPage extends Component {
    render() {
        return (
            <div className="ui container">
                <h1>Page Not Found</h1>
            </div>
        );
    }
}

export default NotFoundPage;
