import { commonRowAndCellClassRules } from '../../../../components/grid/classRules/classRules';
import NamePropertyComparator from '../../../../components/grid/comparators/NamePropertyComparator';
import defaultObjEquals from '../../../../components/grid/columnDefaults/defaultObjEquals';

export const vesselImoHeading = {
    colId: 'imo',
    headerName: 'IMO',
    field: 'vessel.imo',
    editable: false,
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    suppressMenu: true,
    filter: false,
    width: 120,
};
