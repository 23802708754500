import React, { Component } from 'react';
import LaycanInput from '../inputs/LaycanInput';
import FortCellEditor from '_legacy/modules/shared/columns/editors/FortCellEditor';

class LaycanEditor extends Component {
    constructor(props) {
        super(props);
        this.laycan = React.createRef();
        this.focus = this.focus.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);

        this.state = {
            changed: false,
        };
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.laycan.current) {
            this.laycan.current.focus();
        }
    }

    getValue() {
        return this.laycan.current.inputValue;
    }

    isPopup() {
        return false;
    }

    isCancelAfterEnd() {
        return !this.state.changed;
    }

    handleOnChange() {
        this.setState({
            changed: true,
        });
    }

    render() {
        return (
            <FortCellEditor {...this.props}>
                <LaycanInput
                    ref={this.laycan}
                    value={this.props.value}
                    onChange={this.handleOnChange}
                    initialChar={this.props.charPress}
                />
            </FortCellEditor>
        );
    }
}

export default LaycanEditor;
