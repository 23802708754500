/**
 * Need a separate state manager for denoting application errors related to a
 * single cell, because there is no client-side store which survives dynamic
 * rendering (e.g. if we used rowNode, note how [docs](https://www.ag-grid.com/javascript-grid-accessing-data/#for-each-node) say
 * nodes only get created for rows "loaded into browser memory").
 */
export class CellErrorsStore {
    setError({ rowId, colId, error }) {
        const cellErrorsRow =
            this._cellErrorsData[rowId] ||
            (this._cellErrorsData[rowId] = { count: 0 });

        ++cellErrorsRow.count;
        cellErrorsRow[colId] = error;
    }

    getError({ rowId, colId }) {
        const cellErrorsRow = this._cellErrorsData[rowId];
        return cellErrorsRow && cellErrorsRow[colId];
    }

    ensureErrorDeleted({ rowId, colId, callbackIfErrorWasPresent }) {
        const cellErrorsRow = this._cellErrorsData[rowId];
        if (!cellErrorsRow) return;
        if (!cellErrorsRow[colId]) return;

        delete cellErrorsRow[colId];
        callbackIfErrorWasPresent();

        // cleanup the row object if it was the last cell to have an error in it
        if (--cellErrorsRow.count === 0) delete this._cellErrorsData[rowId];
    }

    _cellErrorsData = {};
}
