import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';
import { MIN_MAX_DURATION_FRAGMENT } from './minMaxDurationFragment';

const FRAGMENT = loader('./durationValueFragment.gql');

export const DURATION_VALUE_TYPE_NAME = 'DurationValue';
export const DURATION_VALUE_FRAGMENT_NAME = 'DurationValueFragment';
export const DURATION_VALUE_FRAGMENT = gql`
    ${FRAGMENT}
    ${MIN_MAX_DURATION_FRAGMENT}
`;
