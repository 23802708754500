import { gql } from '@apollo/client';

export const SEARCH_LOCATIONS = gql`
    query Locations(
        $search: String!
        $field: String!
        $datasetId: Int!
        $allowSearchCountries: Boolean!
    ) {
        locations(
            searchTerm: $search
            field: $field
            datasetId: $datasetId
            allowSearchCountries: $allowSearchCountries
        ) {
            id
            name
            displayName
            deskArea
            deskAreaGeared
            deskZone
            globalZone
            locationType
            locationTypeDisplayValue
            aliases
            country {
                displayName
                countryCode
            }
        }
    }
`;

export const GET_LOCATIONS_BY_IDS = gql`
    query LocationsByIds(
        $ids: [String]!
        $field: String!
        $datasetId: Int!
        $allowSearchCountries: Boolean!
    ) {
        locationsByIds(
            ids: $ids
            field: $field
            datasetId: $datasetId
            allowSearchCountries: $allowSearchCountries
        ) {
            id
            country {
                displayName
            }
            deskZone
            deskArea
            deskAreaGeared
            shortName
            locationType
        }
    }
`;

export const GET_LOCATIONS_AND_COUNTRIES_BY_IDS = gql`
    query LocationsAndCountriesByIds(
        $locationIds: [String]!
        $countryIds: [String]!
        $datasetId: Int!
        $field: String!
        $allowSearchCountries: Boolean!
    ) {
        locationsAndCountriesByIds(
            locationIds: $locationIds
            countryIds: $countryIds
            datasetId: $datasetId
            field: $field
            allowSearchCountries: $allowSearchCountries
        ) {
            locations {
                id
                country {
                    displayName
                }
                deskZone
                deskArea
                deskAreaGeared
                shortName
                locationType
            }
            countries {
                id
                displayName
                zone
            }
        }
    }
`;
