export const DefaultFixtureImoMapping = [
    {
        id: '',
        data: null,
    },
    {
        id: 'I',
        data: { imoGradeI: true, imoGradeII: false, imoGradeIII: false },
    },
    {
        id: 'II',
        data: { imoGradeI: false, imoGradeII: true, imoGradeIII: false },
    },
    {
        id: 'III',
        data: { imoGradeI: false, imoGradeII: false, imoGradeIII: true },
    },
    {
        id: 'I/II',
        data: { imoGradeI: true, imoGradeII: true, imoGradeIII: false },
    },
    {
        id: 'II/III',
        data: { imoGradeI: false, imoGradeII: true, imoGradeIII: true },
    },
];
