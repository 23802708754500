import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import MultipleFreightPortBasisSelect from '../inputs/MultipleFreightPortBasisSelect';

const FreightPortBasisEditor = forwardRef((props, ref) => {
    const selectRef = useRef();

    const focus = () => {
        selectRef.current.focus();
    };

    const handleOnTab = () => props.api.tabToNextCell();

    const handleOnTabBack = () => props.api.tabToPreviousCell();

    const handleOnEnter = () => props.stopEditing();

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return selectRef.current.formattedValue;
            },

            isPopup() {
                return true;
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    return (
        <MultipleFreightPortBasisSelect
            className="location-editor"
            ref={selectRef}
            context={props.context}
            field={props.column.colDef.field}
            initialChar={props.charPress}
            value={props.value}
            onTab={handleOnTab}
            onTabBack={handleOnTabBack}
            onEnter={handleOnEnter}
            seperatorCharacters={props.seperatorCharacters}
            blockedCharacters={props.blockedCharacters}
        />
    );
});

export default FreightPortBasisEditor;
