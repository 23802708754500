import React, { Component } from 'react';
import MultipleLocationSelect from '../../grid/inputs/MultipleLocationSelect';
import {
    normalizeParts,
    trimTrailingSeparators,
} from '../../../models/common/EntityPart';
import { createSeparatorLocationPart } from '../../../models/LocationPart';

class LocationEditor extends Component {
    constructor(props) {
        super(props);

        this.locationRef = React.createRef();
        this.focus = this.focus.bind(this);
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.locationRef.current) {
            this.locationRef.current.focus();
        }
    }

    handleOnTab() {
        this.props.api.tabToNextCell();
    }

    handleOnTabBack() {
        this.props.api.tabToPreviousCell();
    }

    handleOnEnter() {
        this.props.stopEditing();
    }

    getValue() {
        const { formattedValue } = this.locationRef.current;

        if (formattedValue.length > 0) {
            var parts = normalizeParts(
                formattedValue,
                createSeparatorLocationPart
            );
            return trimTrailingSeparators(parts);
        } else {
            return null;
        }
    }

    isPopup() {
        return true;
    }

    render() {
        return (
            <MultipleLocationSelect
                className="location-editor"
                ref={this.locationRef}
                context={this.props.context}
                field={this.props.column.colDef.field}
                initialChar={this.props.charPress}
                value={this.props.value}
                onTab={this.handleOnTab}
                onTabBack={this.handleOnTabBack}
                onEnter={this.handleOnEnter}
                seperatorCharacters={this.props.seperatorCharacters}
                blockedCharacters={this.props.blockedCharacters}
                defaultSeperatorCharacter={this.props.defaultSeperatorCharacter}
            />
        );
    }
}

export default LocationEditor;
