import { gql } from '@apollo/client';

export interface SavedLayoutFragment {
    id: string;
    datasetId: number;
    isPreferred: boolean;
    name: string;
    fixtures: string;
    orders: string;
    commonSettings: {
        directionLogic: string;
        quantityFormat: string;
        defaultType: string;
    };
}

export const SAVED_LAYOUT_FRAGMENT = gql`
    fragment SavedLayoutFragment on SavedLayout {
        id
        datasetId
        isPreferred
        name
        fixtures
        orders
        commonSettings {
            directionLogic
            quantityFormat
            defaultType
        }
    }
`;
