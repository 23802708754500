import { blankKeyText } from '../../../constants/gridText';

const ZoneKeyCreator = ({ value }) => {
    if (!value) {
        return blankKeyText;
    }

    return value;
};

export default ZoneKeyCreator;
