export default function getDatasetSpecificHeadingsTitles(datasetId) {
    switch (datasetId) {
        case 14:
            return {
                areas: 'Area Gearless',
            };
        default:
            return {
                areas: 'Area',
            };
    }
}
