import React from 'react';
import SavedFiltersForm from './SavedFiltersForm';
import UnsavedFiltersForm from './UnsavedFiltersForm';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: '20px',
    },
}));

const FiltersMenu = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FormControl component="fieldset" className={classes.formControl}>
                <SavedFiltersForm {...props} />
                {props.savedFilters.length > 0 &&
                props.unsavedFilters.length > 0 ? (
                    <Divider />
                ) : null}
                <UnsavedFiltersForm {...props} />
            </FormControl>
        </div>
    );
};

export default FiltersMenu;
