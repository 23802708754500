import React, { useEffect } from 'react';
import { Button } from 'semantic-ui-react';

const FailedConfirmation = (props) => {
    useEffect(() => {
        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('keydown', handleKeydown);
        };
    });

    const handleKeydown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === 'Esc') {
            props.onClose();
        }

        if (e.key === 'Enter') {
            props.onConfirm();
        }
    };

    return (
        <div
            id="failed-confirmation-modal"
            className="failed-confirmation"
            style={{ minWidth: 400 }}
        >
            <h3>Failing an order On Subs</h3>

            <div>This order includes vessel / rate information.</div>
            <div>FORT will clone this information onto a failed fixture.</div>
            <div>You can find this failed fixture in the Fixtures tab.</div>

            <br />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button basic onClick={props.onClose}>
                    CANCEL
                </Button>
                <Button primary onClick={props.onConfirm}>
                    CONFIRM
                </Button>
            </div>
        </div>
    );
};

export default FailedConfirmation;
