import { gql } from '@apollo/client';

export const SAVEDLAYOUT_DELETED_SUBSCRIPTION = gql`
    subscription onSavedLayoutDeleted($layoutId: ID!) {
        savedLayoutDeleted(layoutId: $layoutId) {
            id
            name
            fixtures
            orders
            isPreferred
            updatedDateTime
            createdDateTime
        }
    }
`;

export const SAVEDLAYOUT_ADDED_SUBSCRIPTION = gql`
    subscription onSavedLayoutAdded($layoutId: ID!) {
        savedLayoutAdded(layoutId: $layoutId) {
            id
            name
            fixtures
            orders
            isPreferred
            updatedDateTime
            createdDateTime
        }
    }
`;

export const SAVEDLAYOUT_RENAMED_SUBSCRIPTION = gql`
    subscription onSavedLayoutRenamed($layoutId: ID!) {
        savedLayoutRenamed(layoutId: $layoutId) {
            id
            name
            fixtures
            orders
            isPreferred
            updatedDateTime
            createdDateTime
        }
    }
`;
