import { getAvailableMinMaxUnits } from '_legacy/modules/shared/columns/models/DurationUnits';

export const validateMinMaxDuration = (minMaxDuration) => {
    if (!minMaxDuration) {
        return true;
    }

    const unit = getAvailableMinMaxUnits().find(
        (unit) => unit.value === minMaxDuration?.unit
    );
    const parsedMinValue = minMaxDuration.minValue
        ? Number(minMaxDuration.minValue)
        : undefined;
    const parsedMaxValue = minMaxDuration.maxValue
        ? Number(minMaxDuration.maxValue)
        : undefined;

    return !isNaN(parsedMinValue) && !isNaN(parsedMaxValue)
        ? parsedMinValue < parsedMaxValue &&
              unit.maxAmountValue >= parsedMaxValue
        : !isNaN(parsedMinValue)
        ? validateSingleValue(parsedMinValue, unit)
        : validateSingleValue(parsedMaxValue, unit);
};

export const validateSingleValue = (inputString, unit) => {
    if (!inputString) return true;

    const parsedValue = Number(inputString);

    return !isNaN(parsedValue) && unit.maxAmountValue >= parsedValue;
};

export const clearInput = (inputString) => {
    if (!inputString) {
        return inputString;
    }

    return inputString.replace(/[^0-9]*/gi, '');
};
