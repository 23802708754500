import isNil from 'lodash/isNil';

const FreightPortBasisComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA || isNil(valueA.loadCount)) {
        return -1;
    }

    if (!valueB || isNil(valueB.loadCount)) {
        return 1;
    }

    let diffFrom = valueA.loadCount - valueB.loadCount;
    if (diffFrom !== 0) {
        return diffFrom;
    }

    if (!valueA || isNil(valueA.dischargeCount)) {
        return -1;
    }

    if (!valueB || isNil(valueB.dischargeCount)) {
        return 1;
    }

    return valueA.dischargeCount - valueB.dischargeCount;
};

export default FreightPortBasisComparator;
