import { gql } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';
import cloneDeepWith from 'lodash/cloneDeepWith';
import {
    updateFreightPortBasisIfRequired,
    updateCargoIfRequired,
    cleanRatesIfRequired,
    clearDeliveryTermIfRequried,
} from '../../services/QueryPostProcessingService';
import {
    hasFirstZoneChanged,
    getAvailableZone,
    getAvailableArea,
    getAvailableAreaGeared,
} from '../../models/Location';
import { ORDER_FRAGMENT } from '../fragments/orderFragments';
import {
    LAYCAN_FRAGMENT_NAME,
    LAYCAN_FRAGMENT,
    LAYCAN_TYPE_NAME,
} from '../fragments/laycanFragment';
import {
    CARGO_QTY_FRAGMENT_NAME,
    CARGO_QTY_FRAGMENT,
    CARGO_QTY_TYPE_NAME,
} from '../fragments/cargoQuantityPartFragment';
import {
    LOCATION_PART_FRAGMENT_NAME,
    LOCATION_PART_FRAGMENT,
    LOCATION_PART_TYPE_NAME,
} from '../fragments/locationPartFragment';
import {
    COMPANY_FRAGMENT,
    COMPANY_FRAGMENT_NAME,
    COMPANY_TYPE_NAME,
} from '../fragments/companyFragment';
import {
    CARGO_PART_FRAGMENT_NAME,
    CARGO_PART_FRAGMENT,
    CARGO_PART_TYPE_NAME,
} from '../fragments/cargoPartFragment';
import {
    FREIGHT_PORT_BASIS_TYPE_NAME,
    FREIGHT_PORT_BASIS_FRAGMENT_NAME,
    FREIGHT_PORT_BASIS_FRAGMENT,
} from '../fragments/freightPortBasisFragment';
import {
    THIRD_PARTY_COMPANY_PART_FRAGMENT,
    THIRD_PARTY_COMPANY_PART_FRAGMENT_NAME,
    THIRD_PARTY_COMPANY_PART_TYPE_NAME,
} from '../fragments/thirdPartyCompanyPartFragment';
import {
    CLARKSONS_USER_FRAGMENT,
    CLARKSONS_USER_FRAGMENT_NAME,
    CLARKSONS_USER_TYPE_NAME,
} from '../fragments/clarksonsUserFragment';
import {
    COMMISSION_FRAGMENT,
    COMMISSION_FRAGMENT_NAME,
    COMMISSION_TYPE_NAME,
} from '../fragments/commissionFragment';
import {
    DURATION_PART_FRAGMENT_NAME,
    DURATION_PART_FRAGMENT,
    DURATION_PART_TYPE_NAME,
} from '../fragments/durationPartFragment';
import {
    ORDER_VESSEL_FRAGMENT,
    ORDER_VESSEL_FRAGMENT_NAME,
    VESSEL_TYPE_NAME,
} from '../fragments/vesselFragment';
import { getVesselPostProcessingService } from '_legacy/modules/columns/vessel';
import {
    RATES_FRAGMENT,
    RATES_FRAGMENT_NAME,
    RATES_TYPE_NAME,
} from '../fragments/ratesFragment';
import { vesselCategoriesByFleetTypeIds } from '_legacy/modules/columns/vessel/models/VesselCategory';

export const ORDER_RECENT_CHANGES_FOR_DATASET_QUERY = gql`
    query OrderChanges($groupId: ID!, $numberOfYears: Int!) {
        sharedOrdersRecentlyChanged(
            groupId: $groupId
            numberOfYears: $numberOfYears
        ) {
            ...OrderFragment
        }

        groupOnlyOrders(groupId: $groupId, numberOfYears: $numberOfYears) {
            ...OrderFragment
        }

        individualOrders(groupId: $groupId) {
            ...OrderFragment
        }

        ordersWithPrivateComments(
            groupId: $groupId
            numberOfYears: $numberOfYears
        ) {
            ...OrderFragment
        }
    }

    ${ORDER_FRAGMENT}
`;

export const CREATE_ORDER_MUTATION = gql`
    mutation CreateOrder(
        $groupId: ID!
        $groupOnly: Boolean!
        $privacy: Boolean!
        $isIndividual: Boolean!
        $type: String!
    ) {
        createOrder(
            groupId: $groupId
            groupOnly: $groupOnly
            privacy: $privacy
            isIndividual: $isIndividual
            type: $type
        ) {
            ...OrderFragment
        }
    }

    ${ORDER_FRAGMENT}
`;

export const SHARE_ORDER_MUTATION = gql`
    mutation ShareOrder($id: ID!) {
        shareOrder(id: $id) {
            id
            groupOnly
            isIndividual
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const SHARE_ORDER_TO_GROUP_MUTATION = gql`
    mutation ShareOrderToGroup($id: ID!) {
        shareOrderToGroup(id: $id) {
            id
            groupOnly
            isIndividual
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const CLONE_ORDER_MUTATION = gql`
    mutation CloneOrder($id: ID!, $groupId: ID!) {
        cloneOrder(id: $id, groupId: $groupId) {
            ...OrderFragment
        }
    }

    ${ORDER_FRAGMENT}
`;

export const DELETE_ORDER_MUTATION = gql`
    mutation DeleteOrder($groupId: ID!, $id: ID!) {
        deleteOrder(groupId: $groupId, id: $id) {
            id
            state
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_WITHDRAWN_MUTATION = gql`
    mutation MakeOrderWithdrawn($groupId: ID!, $id: ID!) {
        makeOrderWithdrawn(groupId: $groupId, id: $id) {
            id
            state
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_NEW_MUTATION = gql`
    mutation MakeOrderNew($groupId: ID!, $id: ID!) {
        makeOrderNew(groupId: $groupId, id: $id) {
            id
            state
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_ACTIVE_MUTATION = gql`
    mutation MakeOrderActive($groupId: ID!, $id: ID!) {
        makeOrderActive(groupId: $groupId, id: $id) {
            id
            state
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const REINSTATE_ORDER_MUTATION = gql`
    mutation ReinstateOrder($groupId: ID!, $id: ID!) {
        reinstateOrder(groupId: $groupId, id: $id) {
            id
            state
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_ORDER_PUBLIC_MUTATION = gql`
    mutation MakeOrderPublic($groupId: ID!, $id: ID!) {
        makeOrderPublic(groupId: $groupId, id: $id) {
            id
            privacy
            privateFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
            status
        }
    }
`;

export const MAKE_ORDER_PRIVATE_MUTATION = gql`
    mutation MakeOrderPrivate($groupId: ID!, $id: ID!) {
        makeOrderPrivate(groupId: $groupId, id: $id) {
            id
            privacy
            privateFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
            status
        }
    }
`;

export const UPDATE_ORDER_PRIVATE_FIELDS_MUTATION = gql`
    mutation UpdateOrderPrivateFields(
        $groupId: ID!
        $id: ID!
        $fields: [String]!
    ) {
        updateOrderPrivateFields(groupId: $groupId, id: $id, fields: $fields) {
            id
            privacy
            privateFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_ORDER_UNRUMOURED_MUTATION = gql`
    mutation MakeOrderUnRumoured($groupId: ID!, $id: ID!) {
        makeOrderUnRumoured(groupId: $groupId, id: $id) {
            id
            rumoured
            rumouredFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const MAKE_ORDER_RUMOURED_MUTATION = gql`
    mutation MakeOrderRumoured($groupId: ID!, $id: ID!) {
        makeOrderRumoured(groupId: $groupId, id: $id) {
            id
            rumoured
            rumouredFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const FAIL_ORDER_MUTATION = gql`
    mutation FailOrder($groupId: ID!, $id: ID!) {
        failOrder(groupId: $groupId, id: $id) {
            id
            status
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;

export const CONVERT_ORDER_MUTATION = gql`
    mutation ConvertOrderMutation($groupId: ID!, $id: ID!) {
        convertOrderToFixture(groupId: $groupId, id: $id) {
            fixtureId
            order {
                id
                state
                lastUpdatedDateTimeByUser
                lastUpdatedByUser {
                    username
                    fullName
                }
            }
        }
    }
`;

const addTypeName = (obj, type) => {
    if (Array.isArray(obj)) {
        for (var i = 0; i < obj.length; i++) {
            addTypeName(obj[i], type);
        }
    } else {
        obj['__typename'] = type;
    }
};

const omitDeep = (collection, excludeKeys) => {
    function omitFn(value) {
        if (value && typeof value === 'object') {
            excludeKeys.forEach((key) => {
                delete value[key];
            });
        }
    }
    return cloneDeepWith(collection, omitFn);
};

export const createOrderFieldMutation = (
    data,
    id,
    field,
    value,
    username,
    userId,
    groupId
) => {
    let mutationStructure = field;
    let fragment = '';
    let fragmentName = '';
    let type = '';
    let variableName = field;
    let mutationValue = cloneDeep(value);
    let gridValue = cloneDeep(value);
    let customUpdate = null;
    let filtersToBeRefreshed = null;
    let sanitizeVariable = null;

    const gridType = 'order';

    let variables = {
        groupId: groupId,
        order: {
            id: id,
        },
    };

    //TODO: use valueParser column definition instead
    switch (field) {
        case 'laycan':
            mutationStructure = `${field} { ...${LAYCAN_FRAGMENT_NAME} } minRedelivery maxRedelivery`;
            fragment = LAYCAN_FRAGMENT;
            variableName = 'laycanShorthand';
            type = LAYCAN_TYPE_NAME;
            if (mutationValue) {
                mutationValue = mutationValue.shorthand;
            }
            break;

        case 'charterer':
        case 'brokers':
            fragmentName = COMPANY_FRAGMENT_NAME;
            fragment = COMPANY_FRAGMENT;
            type = COMPANY_TYPE_NAME;
            break;

        case 'cargoQuantityParts':
            fragmentName = CARGO_QTY_FRAGMENT_NAME;
            fragment = CARGO_QTY_FRAGMENT;
            type = CARGO_QTY_TYPE_NAME;
            break;

        case 'cargoParts':
            fragmentName = CARGO_PART_FRAGMENT_NAME;
            fragment = CARGO_PART_FRAGMENT;
            type = CARGO_PART_TYPE_NAME;
            break;

        case 'commission':
            fragmentName = COMMISSION_FRAGMENT_NAME;
            fragment = COMMISSION_FRAGMENT;
            type = COMMISSION_TYPE_NAME;
            break;

        case 'freightPortBasis':
            fragmentName = FREIGHT_PORT_BASIS_FRAGMENT_NAME;
            fragment = FREIGHT_PORT_BASIS_FRAGMENT;
            type = FREIGHT_PORT_BASIS_TYPE_NAME;

            sanitizeVariable = (mutationValue) => {
                let freightPortBasis = {};

                freightPortBasis.shouldBeUpdatedAutomatically =
                    mutationValue.shouldBeUpdatedAutomatically;
                freightPortBasis.parts = mutationValue.parts.map((x) => {
                    return {
                        loadCount: +x.loadCount,
                        dischargeCount: +x.dischargeCount,
                    };
                });

                return freightPortBasis;
            };
            break;

        case 'thirdPartyBrokers':
        case 'thirdPartyCharterer':
        case 'thirdPartyOwner':
            fragmentName = THIRD_PARTY_COMPANY_PART_FRAGMENT_NAME;
            fragment = THIRD_PARTY_COMPANY_PART_FRAGMENT;
            type = THIRD_PARTY_COMPANY_PART_TYPE_NAME;
            break;

        case 'clarksonsBrokers':
            fragmentName = CLARKSONS_USER_FRAGMENT_NAME;
            fragment = CLARKSONS_USER_FRAGMENT;
            type = CLARKSONS_USER_TYPE_NAME;
            break;

        case 'status':
            mutationStructure = `${field} privacy`;
            break;

        case 'loadLocationParts':
            fragmentName = LOCATION_PART_FRAGMENT_NAME;
            fragment = LOCATION_PART_FRAGMENT;
            type = LOCATION_PART_TYPE_NAME;
            filtersToBeRefreshed = [
                'freightPortBasis',
                'loadZone',
                'areas',
                'areasGeared',
                'direction',
            ];

            customUpdate = (data, oldValue, newValue) => {
                updateFreightPortBasisIfRequired(
                    data,
                    variables,
                    mutationValue,
                    'dischargeLocationParts',
                    gridType
                );

                if (hasFirstZoneChanged(data.loadZone, newValue)) {
                    const zone = getAvailableZone(newValue);
                    const area = getAvailableArea(newValue);
                    const areaGeared = getAvailableAreaGeared(newValue);
                    data['loadZone'] = zone;
                    data['areas'] = area;
                    data['areasGeared'] = areaGeared;
                    variables.order['loadZone'] = zone;
                    variables.order['areas'] = area;
                    variables.order['areasGeared'] = areaGeared;
                }
            };

            // Don't send zone and area in the mutation to the graph.
            // They're only present for the purpose of setting area when changing load zone.
            sanitizeVariable = (mutationValue) => {
                if (mutationValue === null) {
                    return mutationValue;
                }
                let values = [];
                for (let i = 0; i < mutationValue.length; i++) {
                    values.push({
                        name: mutationValue[i].name,
                        partType: mutationValue[i].partType,
                        value: mutationValue[i].value,
                        isSTS: mutationValue[i].isSTS,
                    });
                }
                return values;
            };
            break;

        case 'dischargeLocationParts':
            fragmentName = LOCATION_PART_FRAGMENT_NAME;
            fragment = LOCATION_PART_FRAGMENT;
            type = LOCATION_PART_TYPE_NAME;
            filtersToBeRefreshed = [
                'freightPortBasis',
                'dischargeZone',
                'direction',
            ];

            customUpdate = (data, oldValue, newValue) => {
                const zoneHasChanged = hasFirstZoneChanged(
                    data.dischargeZone,
                    newValue
                );

                updateFreightPortBasisIfRequired(
                    data,
                    variables,
                    mutationValue,
                    'loadLocationParts',
                    gridType
                );

                if (zoneHasChanged) {
                    const zone = getAvailableZone(newValue);
                    data['dischargeZone'] = zone;
                    variables.order['dischargeZone'] = zone;
                }
            };

            // Don't send zone and area in the mutation to the graph.
            // They're only present for the purpose of setting area when changing load zone.
            sanitizeVariable = (mutationValue) => {
                if (mutationValue === null) {
                    return mutationValue;
                }
                let values = [];
                for (let i = 0; i < mutationValue.length; i++) {
                    values.push({
                        name: mutationValue[i].name,
                        partType: mutationValue[i].partType,
                        value: mutationValue[i].value,
                        isSTS: mutationValue[i].isSTS,
                    });
                }
                return values;
            };
            break;

        case 'deliveryLocationParts':
        case 'redeliveryLocationParts':
            fragmentName = LOCATION_PART_FRAGMENT_NAME;
            fragment = LOCATION_PART_FRAGMENT;
            type = LOCATION_PART_TYPE_NAME;
            sanitizeVariable = (mutationValue) => {
                if (mutationValue === null) {
                    return mutationValue;
                }

                let values = [];
                for (let i = 0; i < mutationValue.length; i++) {
                    values.push({
                        name: mutationValue[i].name,
                        partType: mutationValue[i].partType,
                        value: mutationValue[i].value,
                        isSTS: mutationValue[i].isSTS,
                    });
                }
                return values;
            };
            break;

        case 'loadZone':
            filtersToBeRefreshed = ['direction'];
            break;

        case 'type':
            cleanRatesIfRequired(data, variables, value, gridType);
            updateCargoIfRequired(data, variables, value, gridType);
            clearDeliveryTermIfRequried(data, variables, value, gridType);
            break;

        case 'durationParts':
            mutationStructure = `${field} { ...${DURATION_PART_FRAGMENT_NAME} } minRedelivery maxRedelivery`;
            fragment = DURATION_PART_FRAGMENT;
            type = DURATION_PART_TYPE_NAME;
            break;

        case 'rates':
            fragmentName = RATES_FRAGMENT_NAME;
            fragment = RATES_FRAGMENT;
            type = RATES_TYPE_NAME;
            break;

        case 'salePrice':
            if (mutationValue) {
                mutationValue = parseFloat(mutationValue);
            }
            break;

        case 'vessel':
            fragmentName = ORDER_VESSEL_FRAGMENT_NAME;
            fragment = ORDER_VESSEL_FRAGMENT;
            type = VESSEL_TYPE_NAME;
            filtersToBeRefreshed = [];

            customUpdate = (data, oldValue, newValue) => {
                const vesselCategory =
                    newValue && newValue.dwt
                        ? vesselCategoriesByFleetTypeIds(
                              data.datasetId,
                              newValue.fleetTypeIds
                          )
                        : null;
                data['vesselCategory'] = vesselCategory;
                variables.order['vesselCategory'] = vesselCategory;

                const vesselService = getVesselPostProcessingService(
                    data.datasetId
                );

                if (vesselService) {
                    vesselService.updateOwnerIfRequired(
                        newValue,
                        data,
                        variables,
                        'order'
                    );
                }
            };

            sanitizeVariable = (mutationValue) => {
                if (mutationValue === null) {
                    return mutationValue;
                }

                delete mutationValue['bestOperator'];
                delete mutationValue['gainCompany'];
                delete mutationValue['shouldUpdateOwner'];
                delete mutationValue['fleetTypeIds'];
                return mutationValue;
            };
            break;

        default:
            break;
    }

    if (fragmentName) {
        mutationStructure = `${field} { ...${fragmentName} }`;
    }

    if (type && gridValue) {
        addTypeName(gridValue, type);
    }

    const innerMutation = `
  updateOrder(groupId: $groupId, order: $order) {
    id
    ${mutationStructure}
    state
    lastUpdatedDateTimeByUser
    lastUpdatedByUser {
      username
      fullName
    }
  }`;

    const mutation = gql`
    mutation set_order_${field}($groupId: ID!, $order: OrderInput!) {
      ${innerMutation}
    }

    ${fragment}
  `;

    if (mutationValue && sanitizeVariable) {
        variables.order[variableName] = sanitizeVariable(mutationValue);
    } else {
        variables.order[variableName] = mutationValue;
    }

    //remove any __typename properties that may be in the value else GraphQL will reject the mutation as having
    //an extra field in the input properties
    variables = omitDeep(variables, ['__typename']);

    return {
        variables,
        mutation,
        innerMutation,
        mutationValue,
        gridValue,
        customUpdate,
        filtersToBeRefreshed,
    };
};

export const UPDATE_ORDER_RUMOURED_FIELDS_MUTATION = gql`
    mutation UpdateOrderRumouredFields(
        $groupId: ID!
        $id: ID!
        $fields: [String]!
    ) {
        updateOrderRumouredFields(groupId: $groupId, id: $id, fields: $fields) {
            id
            rumoured
            rumouredFields
            lastUpdatedDateTimeByUser
            lastUpdatedByUser {
                username
                fullName
            }
        }
    }
`;
