import React, { Component } from 'react';

class Toolbar extends Component {
    constructor(props) {
        super(props);
        this.state = { selectedButton: -1 };
    }

    render() {
        return (
            <div className="toolbar">
                <div className="toolbar-left">{this.props.leftMenu}</div>
                <div className="toolbar-right">
                    <div className="toolbar-chipWrapper">
                        {this.props.chips}
                    </div>
                    {this.props.rightMenuIcons}
                    {this.props.rightMenuButtons &&
                        this.props.rightMenuButtons
                            .filter((b) => b != null)
                            .map((button, index) => {
                                //there is a requirement for the right menu buttons to maintain their highlight while the dropdown
                                //menu is open, so the buttons need to be grouped together and a "selectedButton" to be maintained in
                                //the toolbar's state
                                const newProps = {
                                    isActive:
                                        this.state.selectedButton === index,
                                    onShow: () => {
                                        this.setState({
                                            selectedButton: index,
                                        });
                                    },
                                    onHide: () => {
                                        this.setState({ selectedButton: -1 });
                                    },
                                };
                                const buttonWithProps = React.cloneElement(
                                    button,
                                    newProps
                                );
                                return buttonWithProps;
                            })}
                </div>
            </div>
        );
    }
}

export default Toolbar;
