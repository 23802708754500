import {
    ENTITY_PART_TYPE_QUANTITY,
    ENTIYT_PART_TYPE_STEMSIZE,
} from '../../../../models/common/EntityPart';
import { getStemSizesCombinations } from '../models/StemSizes';

export const convertQuantityPartsToNumbers = (parts) => {
    const numbers = [];

    if (!parts) return numbers;

    const formattedParts = formatParts(parts);

    // merging all parts between PLUS separator ('2000 / 3000 + 5000 / 250' should be '200 / 8000 / 250')
    while (formattedParts.findIndex((fp) => fp.isPlusSeparator) !== -1) {
        const indexOfSeparator = formattedParts.findIndex(
            (fp) => fp.isPlusSeparator
        );

        const firstNumber = formattedParts[indexOfSeparator - 1];
        const secondNumber = formattedParts[indexOfSeparator + 1];

        const from = Math.min(firstNumber.from, secondNumber.from);
        const to = firstNumber.to + secondNumber.to;

        formattedParts.splice(indexOfSeparator - 1, 3, {
            from,
            to,
        });
    }

    for (const part of formattedParts) {
        numbers.push(part.from);
        numbers.push(part.to);
    }

    return Array.from(new Set(numbers));
};

const formatParts = (parts) => {
    const filteredParts = parts.filter((p) => p.separatorValue !== '/');

    return filteredParts.map((fp) =>
        fp.partType === ENTITY_PART_TYPE_QUANTITY
            ? countAmountIncludingTolerance(fp)
            : fp.partType === ENTIYT_PART_TYPE_STEMSIZE
            ? getStemSizeNumericValues(fp)
            : { isPlusSeparator: true }
    );
};

const countAmountIncludingTolerance = (part) => {
    const { from, to } = part.quantityValue.amount;
    const { tolerance } = part.quantityValue;

    if (tolerance) {
        const fromWithTolerance = from - from * (tolerance / 100);
        const toWithTolerance = to + to * (tolerance / 100);

        return {
            from: fromWithTolerance,
            to: toWithTolerance,
            isPlusSeparator: false,
        };
    }

    return {
        from,
        to,
        isPlusSeparator: false,
    };
};

const getStemSizeNumericValues = (part) => {
    const allStemSizes = getStemSizesCombinations();
    const stemSizeValue = allStemSizes.find(
        (stemSize) => stemSize.value === part.stemSizeValue
    );

    return {
        from: stemSizeValue.minDwt,
        to: stemSizeValue.maxDwt,
        isPlusSeparator: false,
    };
};
