import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./companyFragment.gql');

export const COMPANY_TYPE_NAME = 'Company';
export const COMPANY_FRAGMENT_NAME = 'CompanyFragment';
export const COMPANY_FRAGMENT = gql`
    ${FRAGMENT}
`;
