import React, { Component } from 'react';
import FortDownshiftInput from '../../../../components/grid/inputs/FortDownshiftInput';
import ClarksonsUserApi from '../../../../api/clarksonsUserApi';

class ClarksonsBrokerInput extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    itemToString = (broker) => (broker ? broker.username : '');

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    reset() {
        if (this.inputRef.current) {
            this.inputRef.current.reset();
        }
    }

    renderBroker(getItemProps, highlightedIndex, item, index) {
        return (
            <tr
                {...getItemProps({
                    item,
                    key: item.id || item.key,
                    style: {
                        backgroundColor:
                            highlightedIndex === index
                                ? 'rgba(0,0,0,0.05)'
                                : 'white',
                    },
                })}
            >
                <td>
                    {item.firstName} {item.lastName}
                </td>
            </tr>
        );
    }

    render() {
        return (
            <FortDownshiftInput
                {...this.props}
                initialChar={this.props.initialChar}
                ref={this.inputRef}
                itemToString={this.itemToString}
                searchFunc={ClarksonsUserApi.search}
                renderItem={this.renderBroker}
                placeholder="Broker name"
                onItemSelected={this.props.onBrokerSelected}
            />
        );
    }
}

export default ClarksonsBrokerInput;
