const styles = (theme) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 300,
        backgroundColor: 'transparent !important',
    },
    rootMenu: {
        overflow: 'visible',
    },
    menu: {
        position: 'absolute',
        top: '40px',
        right: 0,
        zIndex: 500,
        background: 'white',
        padding: 0,
        margin: 0,
        textTransform: 'none',
        borderRadius: '4px',
        border: '1px solid rgba(34, 36,38,.15)',
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        color: theme.palette.common.black,
    },
    menuItem: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        overflow: 'visible',
        position: 'relative',
    },

    subMenuItem: {
        extend: 'menuItem',
        paddingLeft: 0,
        width: '100%',
    },

    // Accordion

    accordionRoot: {
        border: 0,
        boxShadow: 'none',
    },
    accordionExpanded: {
        padding: 0,
        margin: '0 !important',
        border: 0,
        boxShadow: 'none',
    },
    accordionSummary: {
        margin: '0 !important',
        minHeight: '45px !important',
        padding: 0,
        '& .MuiIconButton-root': {
            marginRight: 0,
            padding: '12px !important',
        },
    },
    accordionSummaryContent: {
        margin: 0,
    },

    accordionDetails: {
        margin: 0,
        padding: 0,
        border: 0,
    },

    caption: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
    },
});

export default styles;
