import defaultSortComparator from './DefaultSortComparator';

const PriorityListComparator = (valueA, valueB, priorityList) => {
    if (!valueA && !valueB) {
        return 0;
    }

    const compareValueA = valueA || '';
    const compareValueB = valueB || '';

    let valueAIndex = priorityList.indexOf(compareValueA);
    let valueBIndex = priorityList.indexOf(compareValueB);

    if (valueAIndex === -1) {
        valueAIndex = 999;
    }

    if (valueBIndex === -1) {
        valueBIndex = 999;
    }

    // If we don't find a match in the list of zones, it means this is a custom zone. We need to sort custom zones
    // alphabetically after we sort by
    if (valueAIndex === 999 && valueBIndex === 999) {
        return defaultSortComparator(compareValueA, compareValueB);
    } else {
        return valueAIndex - valueBIndex;
    }
};

export default PriorityListComparator;
