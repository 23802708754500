import { cloudUrl } from '../../config';
import axios from 'axios';
import isNil from 'lodash/isNil';
import { authenticationService } from '_legacy/auth/AuthenticationService';

export default class Cloud {
    async getCurrentUser() {
        const token = authenticationService.getAccessToken();

        if (isNil(token)) {
            return undefined;
        }

        let encodedToken = encodeURIComponent(token);
        let url = `${cloudUrl}/api/1_3/Security/GetCurrentUser?token=${encodedToken}`;

        let response = await axios.get(url);

        return response.data;
    }
}
