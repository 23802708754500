import React from 'react';
import classNames from 'classnames';

const NavigationMenuItem = (props) => {
    const { id, to, children, isDefaultMenuItem, onTabSelect } = props;

    const classes = classNames({
        'menu-item': true,
        active:
            (window.location.pathname === '/' && isDefaultMenuItem) ||
            window.location.pathname.startsWith(to),
    });

    return (
        <span
            className={classes}
            data-id={id}
            onClick={() => {
                onTabSelect && onTabSelect(to);
            }}
        >
            {children}
        </span>
    );
};

export default NavigationMenuItem;
