import { Chip as MuiChip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';

const Chip = (props) => {
    const { classes, label, onDelete } = props;

    return (
        <MuiChip
            variant="outlined"
            size="small"
            label={label}
            onDelete={onDelete}
            className={classes.muiChip}
        />
    );
};

export const ChipOutlined = withStyles((theme) => ({
    muiChip: {
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.common.white,
        fontSize: '12px',
        lineHeight: '12px',
        color: '#757575',
        textAlign: 'right',
        alignItems: 'center',
        border: '1px solid #757575',
    },
}))(Chip);
