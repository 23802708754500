import { Button, ButtonOwnProps, styled } from '@mui/material';
import { SavedLayoutModel } from 'store/feature/layoutsSlice/models';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { userSelectors } from 'store/feature/userSlice';
import CreateLayoutDialog from 'components/modal/layouts/dialogs/CreateLayoutDialog';
import { useContext, useState } from 'react';
import GridTypeContext from '../../hooks/common/GridTypeContext';
import {
    layoutsSelectors,
    revertLayout,
    updateTemplateLayout,
    updateUserLayout,
} from 'store/feature/layoutsSlice';
import UpdateTemplateLayoutConfirmationDialog from 'components/modal/layouts/dialogs/UpdateTemplateLayoutConfirmationDialog';

const StyledButtonStack = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
}));

interface LayoutControlsProps {
    model: Pick<SavedLayoutModel, 'id' | 'name' | 'isTemplate'>;
    onRevert?: () => void;
    size?: 'small' | 'medium' | 'large';
    primaryColor?: ButtonOwnProps['color'];
    secondaryColor?: ButtonOwnProps['color'];
}

const UserLayoutControls: React.FC<LayoutControlsProps> = ({
    onRevert,
    size = 'small',
    primaryColor = 'primary',
    secondaryColor = 'primary',
}) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(layoutsSelectors.loading);

    const gridType = useContext(GridTypeContext);
    const [showDialog, setShowDialog] = useState(false);

    const handleRevert = () => {
        dispatch(revertLayout());
        if (onRevert) {
            onRevert();
        }
    };

    const handleSave = () => {
        dispatch(updateUserLayout({ gridType }));
    };

    return (
        <StyledButtonStack onClick={(e) => e.stopPropagation()}>
            <Button
                variant="outlined"
                color={secondaryColor}
                size={size}
                onClick={handleRevert}
                disabled={loading}
            >
                Revert
            </Button>
            <Button
                variant="outlined"
                color={secondaryColor}
                size={size}
                onClick={() => setShowDialog(true)}
            >
                Save As
            </Button>
            <Button
                variant="contained"
                color={primaryColor}
                size={size}
                onClick={handleSave}
                disabled={loading}
            >
                Save
            </Button>
            <CreateLayoutDialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
            />
        </StyledButtonStack>
    );
};

const TemplateLayoutControls: React.FC<LayoutControlsProps> = ({
    onRevert,
    size = 'small',
    primaryColor = 'primary',
    secondaryColor = 'primary',
}) => {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(layoutsSelectors.loading);
    const isAdmin = useAppSelector(userSelectors.isAdmin);

    const gridType = useContext(GridTypeContext);
    const [showDialog, setShowDialog] = useState<'saveAs' | 'save' | false>(
        false
    );

    const handleRevert = () => {
        dispatch(revertLayout());
        if (onRevert) {
            onRevert();
        }
    };

    const handleSave = () => {
        dispatch(updateTemplateLayout({ gridType }));
    };

    return (
        <StyledButtonStack onClick={(e) => e.stopPropagation()}>
            <Button
                variant="outlined"
                color={secondaryColor}
                size={size}
                onClick={handleRevert}
                disabled={loading}
            >
                Revert
            </Button>
            {isAdmin && (
                <Button
                    variant="outlined"
                    color={secondaryColor}
                    size={size}
                    onClick={() => setShowDialog('save')}
                    disabled={loading}
                >
                    Save
                </Button>
            )}
            <Button
                variant="contained"
                color={primaryColor}
                size={size}
                onClick={() => setShowDialog('saveAs')}
                disabled={loading}
            >
                Save As
            </Button>
            <CreateLayoutDialog
                open={showDialog === 'saveAs'}
                onClose={() => setShowDialog(false)}
            />
            <UpdateTemplateLayoutConfirmationDialog
                open={showDialog === 'save'}
                onCancel={() => setShowDialog(false)}
                onContinue={() => {
                    setShowDialog(false);
                    handleSave();
                }}
                onSaveAs={() => setShowDialog('saveAs')}
            />
        </StyledButtonStack>
    );
};

const LayoutControls: React.FC<LayoutControlsProps> = (props) => {
    return props.model.isTemplate ? (
        <TemplateLayoutControls {...props} />
    ) : (
        <UserLayoutControls {...props} />
    );
};

export default LayoutControls;
