import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
    query AllUsers($searchTerm: String!) {
        allUsers(searchTerm: $searchTerm) {
            id
            userId
            username
            firstName
            lastName
        }
    }
`;
