import React from 'react';

const MultiSelectSpace = React.forwardRef(
    ({ index, onSetCursorPosition, stretched }, ref) => {
        return (
            <span
                ref={ref}
                className="multi-select-space-pill"
                onClick={() => {
                    if (typeof onSetCursorPosition === 'function') {
                        onSetCursorPosition(index);
                    }
                }}
                style={stretched ? { width: '40px' } : null}
            />
        );
    }
);

export default MultiSelectSpace;
