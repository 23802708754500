export const downloadFile = ({ filename, data }) => {
    const downloadLink = document.createElement('a');
    downloadLink.style = 'display: none';

    document.body.appendChild(downloadLink);

    const blob = new Blob([data], {
        type: 'application/octet-stream',
    });

    const url = window.URL.createObjectURL(blob);

    downloadLink.href = url;
    downloadLink.download = filename;
    downloadLink.click();

    window.URL.revokeObjectURL(url);

    document.body.removeChild(downloadLink);
};
