const isPrivateField = (colDef, data) => {
    if (colDef && data && data.privateFields) {
        return (
            data.privateFields &&
            (data.privateFields.includes(colDef.field) ||
                data.privateFields.includes(colDef.colId))
        );
    } else {
        return false;
    }
};

export default isPrivateField;
