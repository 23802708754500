import CompanyFormatter from '../formatters/CompanyFormatter';

const CompanyKeyCreator = ({ value, defaultValue }) => {
    if (!value) {
        return defaultValue ?? '';
    }

    return CompanyFormatter({ value });
};

export default CompanyKeyCreator;
