import moment from 'moment-timezone';

export const DATE_FORMAT_STRING = 'DD MMM YY';
export const TIME_FORMAT_STRING = 'HH:mm';
export const DATE_TIME_FORMAT_STRING = 'DD MMM YY - HH:mm';

export const formatDefaultDateTime = (datetimeString, timezoneString) => {
    if (!datetimeString) {
        return '';
    }
    const momentData = getMomentData(datetimeString, timezoneString);
    return momentData.format(DATE_TIME_FORMAT_STRING).toUpperCase();
};

export const formatDefaultDate = (dateString) => {
    if (!dateString) {
        return '';
    }
    let momentData = moment.utc(dateString);
    return momentData.format(DATE_FORMAT_STRING).toUpperCase();
};

export const formatDate = (datetimeString, timezoneString) => {
    if (!datetimeString) {
        return '';
    }
    const momentData = getMomentData(datetimeString, timezoneString);
    return momentData.format(DATE_FORMAT_STRING);
};

export const formatTime = (datetimeString, timezoneString) => {
    if (!datetimeString) {
        return '';
    }
    const momentData = getMomentData(datetimeString, timezoneString);
    return momentData.format(TIME_FORMAT_STRING);
};

const getMomentData = (datetimeString, timezoneString) => {
    let momentData = moment.utc(datetimeString);
    if (timezoneString) {
        momentData = momentData.tz(timezoneString);
    }
    return momentData;
};
