import initialState from './initialState';
import * as types from '../actions/actionTypes';

const versionReducer = (state = initialState.version, action) => {
    switch (action.type) {
        case types.VERSION_RECEIVED:
            return action.version;
        default:
            return state;
    }
};

export default versionReducer;
