import isString from 'lodash/isString';

const QuotedFormatter = ({ value, node }) => {
    if ((!node && isString(value)) || node.group) {
        //display values in the filter and group heading
        return value;
    } else if (isString(value)) {
        //display values in the grid cell
        return value.replace('N', '');
    }
    return '';
};

export const QuotedFieldFormatter = (value) => {
    if (!value) {
        return 'N';
    }
    return value;
};

export default QuotedFormatter;
