import { MultipleRateFormatter } from '../formatters/MultipleRateFormatter';
import RatePartFilterComparator from './RatePartFilterComparator';

const RatesComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }

    const formattedA = MultipleRateFormatter({ value: valueA });
    const formattedB = MultipleRateFormatter({ value: valueB });

    return RatePartFilterComparator(formattedA, formattedB);
};

export default RatesComparator;
