import { getDataset } from './Datasets';

export const dirtyDataSets = [
    getDataset(1),
    getDataset(2),
    getDataset(3),
    getDataset(5),
    getDataset(6),
    getDataset(7),
    getDataset(9),
    getDataset(11),
];

export const checkDatasetIsDirty = (id) =>
    dirtyDataSets.find((dataset) => dataset.value === id);
