import client from './graphQlClient';
import {
    ORDER_RECENT_CHANGES_FOR_DATASET_QUERY,
    MAKE_ORDER_UNRUMOURED_MUTATION,
    MAKE_ORDER_RUMOURED_MUTATION,
    UPDATE_ORDER_RUMOURED_FIELDS_MUTATION,
    CLONE_ORDER_MUTATION,
    MAKE_WITHDRAWN_MUTATION,
    MAKE_NEW_MUTATION,
    MAKE_ACTIVE_MUTATION,
} from './queries/OrderQueries';
import { apiBaseUrl } from '../config';
import { sessionId } from './session';
import moment from 'moment';
import { authenticationService } from '_legacy/auth/AuthenticationService';
import logger from '_legacy/diagnostics/logging/logger';
import { displayOnlyRumouredHandler } from './apiHelpers';

const ConvertedOrdersToBeHideInHours = 24;

export class OrderApi {
    static CONTEXT = {
        errorHandling: {
            unknownResult: {
                resolution: {
                    isSafeToRetry: true,
                },
            },
        },
    };
    static FETCH_POLICY = 'no-cache';

    static fetchOrders = async (
        groupId,
        numberOfYears,
        cancelToken,
        includeDeleted,
        includeConverted,
        displayOnlyRumoured
    ) => {
        const changesSinceSnapshotPromise = (async () => {
            try {
                const resultPromise = client().query({
                    query: ORDER_RECENT_CHANGES_FOR_DATASET_QUERY,
                    variables: { groupId, numberOfYears },
                    fetchPolicy: this.FETCH_POLICY,
                });

                const result = await Promise.race([
                    resultPromise,
                    cancelToken.promise,
                ]);

                if (result.reason) {
                    logger.error(
                        'Result  changesSinceSnapshotPromise: ',
                        result
                    );
                    throw result;
                }

                return result.data;
            } catch (error) {
                logger.error('Error changesSinceSnapshotPromise: ', error);
                throw error;
            }
        })();

        const snapshotPromise = (async () => {
            const result = await fetch(
                `https://${apiBaseUrl}/Orders/Snapshot?` +
                    new URLSearchParams({
                        groupId: groupId,
                        numberOfYears: numberOfYears,
                    }),
                {
                    headers: {
                        authorization: encodeURIComponent(
                            authenticationService.getAccessToken()
                        ),
                        //so we know which session makes the call
                        fortSessionId: sessionId,
                    },
                }
            );

            if (result.status === 204)
                // 204=No Content, e.g. there's no snashot data generated yet.
                return [];

            var resultJson = await result.json();
            if (!resultJson) throw resultJson;
            return resultJson;
        })();

        const {
            sharedOrdersRecentlyChanged = [],
            groupOnlyOrders = [],
            ordersWithPrivateComments = [],
            individualOrders = [],
        } = (await changesSinceSnapshotPromise) || {};

        let orders = await snapshotPromise;

        const m = new Map();

        orders.forEach((i) => m.set(i.id, i));
        sharedOrdersRecentlyChanged.forEach((o) => m.set(o.id, o));
        groupOnlyOrders.forEach((o) => m.set(o.id, o));
        ordersWithPrivateComments.forEach((o) => m.set(o.id, o));
        individualOrders.forEach((o) => m.set(o.id, o));

        displayOnlyRumouredHandler(m, displayOnlyRumoured);

        if (!includeDeleted) {
            m.forEach((o) => {
                if (o.state === 'Deleted' || o.state === 'Withdrawn') {
                    m.delete(o.id);
                }
            });
        }
        if (!includeConverted) {
            m.forEach((o) => {
                if (o.state === 'Converted') {
                    m.delete(o.id);
                }
            });
        } else {
            const earliestDateToShowConverted = moment
                .utc()
                .add(-ConvertedOrdersToBeHideInHours, 'hours')
                .format('YYYY-MM-DD');

            m.forEach((o) => {
                if (
                    o.state === 'Converted' &&
                    o.updatedDateTime < earliestDateToShowConverted
                ) {
                    m.delete(o.id);
                }
            });
        }

        orders = Array.from(m.values());

        return orders;
    };

    static makeUnRumoured = (client, variables) =>
        client
            .mutate({
                mutation: MAKE_ORDER_UNRUMOURED_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.makeOrderUnRumoured);

    static makeRumoured = (client, variables) =>
        client
            .mutate({
                mutation: MAKE_ORDER_RUMOURED_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.makeOrderRumoured);

    static updateRumouredFields = (client, variables) =>
        client
            .mutate({
                mutation: UPDATE_ORDER_RUMOURED_FIELDS_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.updateOrderRumouredFields);

    static cloneOrder = (client, variables) =>
        client
            .mutate({
                mutation: CLONE_ORDER_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.cloneOrder);

    static makeWithdrawn = (client, variables) =>
        client
            .mutate({
                mutation: MAKE_WITHDRAWN_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.makeOrderWithdrawn);

    static makeNew = (client, variables) =>
        client
            .mutate({
                mutation: MAKE_NEW_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.makeOrderNew);

    static makeActive = (client, variables) =>
        client
            .mutate({
                mutation: MAKE_ACTIVE_MUTATION,
                variables,
                context: this.CONTEXT,
                fetchPolicy: this.FETCH_POLICY,
            })
            .then((result) => result.data.makeOrderActive);
}
