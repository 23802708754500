import client from './graphQlClient';
import {
    SEARCH_COUNTRIES,
    GET_COUNTRIES_BY_IDS,
} from './queries/CountryQueries';

class CountryApi {
    static search = (search, datasetId, cancelToken) => {
        return new Promise((resolve, reject) => {
            client()
                .query({
                    query: SEARCH_COUNTRIES,
                    fetchPolicy: 'no-cache',
                    variables: {
                        search,
                        datasetId,
                    },
                })
                .then((result) => {
                    resolve(result.data.countries);
                });

            cancelToken.promise.then((reason) => {
                reject(reason);
            });
        });
    };

    static getCountriesByIds = (ids, datasetId) =>
        client()
            .query({
                query: GET_COUNTRIES_BY_IDS,
                fetchPolicy: 'no-cache',
                variables: {
                    ids,
                    datasetId,
                },
            })
            .then((result) => result.data.countriesByIds);
}

export default CountryApi;
