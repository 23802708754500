import React, { Component, Fragment } from 'react';
import * as keyboardCodes from '../../../../constants/keyboardCodes';
import PropTypes from 'prop-types';

class FortCellEditor extends Component {
    constructor(props) {
        super(props);
        this.divRef = React.createRef();

        this.onKeyDown = this.onKeyDown.bind(this);
        this.focus = this.focus.bind(this);
    }

    componentDidMount() {
        if (this.props.eGridCell && this.props.blockNavigation) {
            this.props.eGridCell.addEventListener('keydown', this.onKeyDown);
        }

        this.focus();
    }

    componentDidUpdate() {
        this.focus();
    }

    componentWillUnmount() {
        if (this.props.eGridCell && this.props.blockNavigation) {
            this.props.eGridCell.removeEventListener('keydown', this.onKeyDown);
        }
    }

    onKeyDown(event) {
        //stop propagation of the event if it's a navigation key so the user doesn't navigate onto another cell midedit the previous/next cell
        this.checkIsNavigation(event);
    }

    checkIsNavigation(event) {
        var isNavigationKey =
            event.keyCode === keyboardCodes.KEY_LEFT ||
            event.keyCode === keyboardCodes.KEY_RIGHT ||
            event.keyCode === keyboardCodes.KEY_UP ||
            event.keyCode === keyboardCodes.KEY_DOWN ||
            event.keyCode === keyboardCodes.KEY_PAGE_DOWN ||
            event.keyCode === keyboardCodes.KEY_PAGE_UP ||
            event.keyCode === keyboardCodes.KEY_PAGE_HOME ||
            event.keyCode === keyboardCodes.KEY_PAGE_END;
        if (isNavigationKey) {
            // this stops the grid from executing keyboard navigation
            event.stopPropagation();
            // this stops the browser from scrolling up / down
            var pageUp = event.keyCode === keyboardCodes.KEY_PAGE_UP;
            var pageDown = event.keyCode === keyboardCodes.KEY_PAGE_DOWN;
            if (pageUp || pageDown) {
                event.preventDefault();
            }
        }
    }

    focus() {
        if (this.props.focus) {
            setTimeout(() => {
                this.props.focus();
            });
        }
    }

    render() {
        return <Fragment>{React.Children.only(this.props.children)}</Fragment>;
    }
}

export default FortCellEditor;

FortCellEditor.defaultProps = {
    blockNavigation: true,
};

FortCellEditor.propTypes = {
    children: PropTypes.element.isRequired,
    blockNavigation: PropTypes.bool.isRequired,
};
