import { blankKeyText } from '_legacy/constants/gridText';
import {
    FetchMappingGridDisplayValue,
    FetchMappingSelectDisplayValue,
} from '../../../data/mappings';

export const StatusFormatter = (params) => {
    const { value, node, context } = params;
    if (!value) {
        return '';
    }

    if (!node) {
        return FetchMappingSelectDisplayValue(value, context.datasetId);
    }

    return FetchMappingGridDisplayValue(value, context.datasetId);
};

export const StatusFilterFormatter = (params) => {
    const { value, context } = params;
    if (!value) {
        return blankKeyText;
    }

    return FetchMappingSelectDisplayValue(value, context.datasetId);
};
