import Logger from '../../../diagnostics/logging/logger';
import MixpanelLogger from '../../../diagnostics/logging/mixpanelLogger';
import { cloneDeep } from 'lodash';

export const createPrivacyMenuItem = (props, node, gridId) => {
    const { group, data } = node;
    const { id, groupOnly, isIndividual, __typename: typeName } = data;
    const { selectedGroup, token } = props;

    if (!group && data) {
        const value = data['privacy'];

        const title = value ? 'Public Row' : 'Private Row';
        const tooltip = 'Change this rows privacy';

        return {
            name: title,
            action: () => {
                if (!group && data) {
                    const clonedData = cloneDeep(data);
                    const mixpanelProps = {
                        itemId: id,
                        itemName: typeName,
                        groupOnly,
                        isIndividual,
                        fortGroup: selectedGroup.name,
                        datasetId: token,
                    };
                    if (value) {
                        Logger.trackEvent('grid.context.makePublic', gridId);

                        MixpanelLogger.trackChangeConfidentialityEvent({
                            ...mixpanelProps,
                            privacy: false,
                        });

                        props.onMakePublic(clonedData);
                    } else {
                        Logger.trackEvent('grid.context.makePrivate', gridId);
                        MixpanelLogger.trackChangeConfidentialityEvent({
                            ...mixpanelProps,
                            privacy: true,
                        });

                        props.onMakePrivate(clonedData);
                    }
                }
            },
            tooltip: tooltip,
        };
    } else {
        return null;
    }
};
