import React from 'react';
import { useDrag, DragPreviewImage } from 'react-dnd';
import MultiSelectItem from './MultiSelectItem';

const LOCATION_ITEM = 'LOCATION_ITEM';

const DraggableMultiSelectItem = (props) => {
    const [, drag, preview] = useDrag({
        type: LOCATION_ITEM,
        item: {
            id: props.index,
        },
    });

    return (
        <React.Fragment>
            <DragPreviewImage src="/empty.png" connect={preview} />
            <MultiSelectItem ref={drag} {...props} />
        </React.Fragment>
    );
};

export default DraggableMultiSelectItem;
