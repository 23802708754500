import { blankKeyText } from '../../../constants/gridText';
import MultipleFreightPortBasisFormatter from '../formatters/MultipleFreightPortBasisFormatter';

const FreightPortBasisKeyCreator = (params) => {
    const { value } = params;
    if (!value || !value.parts || value.parts.length === 0) {
        return blankKeyText;
    }

    return MultipleFreightPortBasisFormatter({ value });
};

export default FreightPortBasisKeyCreator;
