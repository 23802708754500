import {
    ENTITY_PART_TYPE_DURATION,
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
} from '../../../../models/common/EntityPart';
import {
    PNR,
    MONTHS,
    YEARS,
    LEGS_VALUE,
} from '../../../shared/columns/models/DurationUnits';

export const calculateDurationAverageDays = (parts) => {
    if (!parts || parts.length === 0) {
        return 0;
    }

    if (parts.some((p) => p.durationValue?.unit === PNR)) {
        return -1;
    }

    const convertedParts = convertPartsToDaysUnitFormat(parts);

    return mergePartsWithSeparators(convertedParts);
};

const convertPartsToDaysUnitFormat = (parts) => {
    const oneMonthInDays = 30;
    const oneYearInDays = 360;
    const legsCoefficient = 0.01;

    return parts.map((part) => {
        if (part.partType === ENTITY_PART_TYPE_DURATION) {
            const averageDurationValue =
                (part.durationValue.amount.from +
                    part.durationValue.amount.to) /
                2;

            const averageDurationValueInDays =
                part.durationValue.unit === MONTHS
                    ? averageDurationValue * oneMonthInDays
                    : part.durationValue.unit === YEARS
                    ? averageDurationValue * oneYearInDays
                    : part.durationValue.unit === LEGS_VALUE
                    ? averageDurationValue * legsCoefficient
                    : averageDurationValue;

            return {
                averageDurationValue: averageDurationValueInDays,
                partType: ENTITY_PART_TYPE_DURATION,
            };
        }

        return part;
    });
};

const mergePartsWithSeparators = (parts) => {
    const filteredParts = parts.filter(
        (p) => p.separatorValue !== SEPARATOR_FORWARD_SLASH
    );

    while (
        filteredParts.findIndex(
            (fp) => fp.separatorValue === SEPARATOR_PLUS
        ) !== -1
    ) {
        const indexOfSeparator = filteredParts.findIndex(
            (fp) => fp.separatorValue === SEPARATOR_PLUS
        );

        const firstValue =
            filteredParts[indexOfSeparator - 1].averageDurationValue;
        const secondValue =
            filteredParts[indexOfSeparator + 1].averageDurationValue;

        filteredParts.splice(indexOfSeparator - 1, 3, {
            averageDurationValue: firstValue + secondValue,
        });
    }

    const averageDurationSum = filteredParts.reduce((accumulator, part) => {
        return accumulator + part.averageDurationValue;
    }, 0);

    return averageDurationSum / filteredParts.length;
};
