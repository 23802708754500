import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReact } from '@ag-grid-community/react';
import './BasicGrid.scss';
import { isEqual, cloneDeep, assign } from 'lodash';
import PropTypes from 'prop-types';
import UpperFormatter from './formatters/UpperFormatter';
import defaultPostSort from './core/defaultPostSort';
import configureColumns from '../grid/core/configureColumns';
import UnicodeSortComparator from './comparators/UnicodeSortComparator';
import CreateCreatedDateDescendingSortComparatorWrapper from './comparators/CreateCreatedDateDescendingSortComparatorWrapper';
import Logger from '../../diagnostics/logging/logger';
import { CellErrorsStore } from './cellErrors/CellErrorsStore';
import defaultSetFilterParams from './columnDefaults/defaultSetFilterParams';
import { KEY_CODES } from 'constant';
import * as keyboardCodes from '../../constants/keyboardCodes';
import AuditTooltip from './toolTips/AuditTooltip';
import VoyageActivityTooltip from './toolTips/VoyageActivityTooltip';
import GroupOnlyExpiryTooltip from './toolTips/GroupOnlyExpiryTooltip';
import ClarksonsBrokerTooltip from '../../modules/columns/clarksonsBroker/tooltips/ClarksonsBrokerTooltip';
import LastUpdatedByTooltip from '../../modules/columns/updatedBy/tooltips/LastUpdatedByTooltip';
import ClassNames from 'classnames';
import LaycanMonthFilter from './filters/LaycanMonthFilter';
import DateFilter from './filters/DateFilter';
import CargoesFilter from './filters/CargoesFilter';
import LocationsFilter from './filters/LocationsFilter';
import DurationFilter from '_legacy/modules/columns/duration/filters/DurationFilter';
import CompaniesFilter from './filters/CompaniesFilter';
import VisibilityFilter from './filters/VisibilityFilter';
import LaycanFilter from './filters/LaycanFilter';
import DeadweightFilter from './filters/DeadweightFilter';
import QuantityFilter from '../../modules/columns/quantity/filters/QuantityFilter';
import StatusBarSheetSelectors from '../grid/StatusBarSheetSelectors';
import { withRouter } from 'react-router-dom';
import { hotkeyService } from 'common';
import { exportToExcel } from '_legacy/excelExport/services/ExportToExcelService';
import CommonSettingsToolPanel from '../toolPanels/commonSettings/CommonSettingsToolPanel';
import { FIXTURE_GRID_TYPE } from '_legacy/constants/gridTypes';
import { copyEntityToClipboard } from '_legacy/services/CopyEntityToClipboardService';
import {
    getContextMenuItems,
    getMainMenuItems,
} from '../../modules/contextMenuItems/services/MenuItemsService';
import { connect } from 'react-redux';
import SalePriceFilter from './filters/SalePriceFilter';

export const ACTION_CONVERT_TO_FIXTURE = 'convert-to-fixture';
export const ACTION_REINSTATE = 'reinstate';
export const ACTION_OPEN_AUDIT = 'audit-trail';
export const ACTION_VOYAGE_ACTIVITY = 'voyage-activity';
export const ACTION_COPY_FIXTURE_WITH_POS_INFO = 'copy-fixture';
export const ACTION_COPY_FIXTURE_WITH_CARGO_INFO =
    'copy-fixture-with-cargo-info';
export const ACTION_CLONE_ORDER = 'clone-order';
export const ACTION_MAKE_NEW = 'make_new';
export const ACTION_MAKE_ACTIVE = 'make_active';
export const ACTION_WITHDRAWN = 'withdrawn';
const { CTRL, KEY_C, ALT } = KEY_CODES;

const mapStateToProps = (state) => ({
    newThemeEnabled: state.user.themeSettings.newThemeEnabled,
    compactDensityViewEnabled: state.user.themeSettings.useCompactDensityView,
    datasetType: state.user.group.datasetName,
    datasetName: state.user.group.name,
    layouts: state.layouts,
});

export class BasicGridWithoutRouter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            refreshRequired: false,
            showShareConfirmation: false,
            intervalInstanceId: null,
            enchancedColumnDefs: this.props.headings.map(
                this.enchanceColumnDef
            ),
            sideBar: {
                toolPanels: this.getToolPanels(),
                defaultToolPanel: undefined,
            },
        };

        this.gridContainer = React.createRef();
        this.refreshTimeout = null;
        this.setFilterOptionsTimout = null;
        this.rowsEdited = [];
        this.lastGridOptionsChanged = null;
    }

    focusTimeout;
    lastFilterModel;
    lastColumnModel;
    rowData;
    sortOptions = {
        lastSortColId: null,
    };

    componentDidMount() {
        this.configureColumns();

        this.setState(
            {
                rowData: cloneDeep(this.props.rowData),
            },
            () => {
                if (!this.rowData) {
                    this.rowData = [];
                }
                this.rowData.length = 0;
                this.rowData.push(...this.state.rowData);

                if (this._gridApi) {
                    this._gridApi.setRowData(this.rowData);
                }
            }
        );

        if (this.gridContainer.current) {
            const gridContainerElement = ReactDOM.findDOMNode(
                this.gridContainer.current
            );

            if (gridContainerElement) {
                //find the side bar
                const sideBarElements =
                    gridContainerElement.getElementsByClassName('ag-side-bar');

                if (sideBarElements.length === 1) {
                    this.sideBarElement = sideBarElements[0];
                    document.addEventListener(
                        'mousedown',
                        this.handleSidebarClick
                    );
                }
            }
        }

        if (
            this.props.periodicUpdateGrid &&
            this.props.updateGridTimeInterval
        ) {
            const intervalId = setInterval(() => {
                this.props.periodicUpdateGrid(this.rowData);
            }, this.props.updateGridTimeInterval);
            this.setState({ intervalInstanceId: intervalId });
        }
    }

    componentWillUnmount() {
        this._gridApi = null;
        this._columnApi = null;

        if (this.setFilterOptionsTimout) {
            clearTimeout(this.setFilterOptionsTimout);
        }

        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        if (this.state.intervalInstanceId) {
            clearInterval(this.state.intervalInstanceId);
        }

        this.unsubHotkeys && this.unsubHotkeys();

        document.removeEventListener('mousedown', this.handleSidebarClick);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.token !== this.props.token) {
            //new token means we have a new grid
            this.setupNewGridData();
        }

        if (
            prevProps.showCondensed !== this.props.showCondensed ||
            prevProps.compactDensityViewEnabled !==
                this.props.compactDensityViewEnabled ||
            prevProps.newThemeEnabled !== this.props.newThemeEnabled
        ) {
            this.reloadRowGroupingIndentation();
            this._gridApi.resetRowHeights();
            this.setHeaderHeight();
        }

        if (this._columnApi && this.props.headings !== prevProps.headings) {
            this.configureColumns();
        }

        if (this._gridApi && this._columnApi) {
            //current options
            const {
                initialFilterOptions,
                initialColumnOptions,
                shouldHighlightNewOrders,
                selectedLayoutId,
                currentDirectionLogic,
            } = this.props;
            //old options
            const {
                initialFilterOptions: oldFilterOptions,
                initialColumnOptions: oldColumnOptions,
                shouldHighlightNewOrders: oldShouldHighlightNewOrders,
                selectedLayoutId: oldSelectedLayoutId,
                currentDirectionLogic: oldCurrentDirectionLogic,
            } = prevProps;

            if (
                initialFilterOptions !== oldFilterOptions &&
                initialFilterOptions !== this.lastFilterModel
            ) {
                this.setFilterOptions(initialFilterOptions, true);
            }

            if (
                initialColumnOptions !== oldColumnOptions &&
                initialColumnOptions !== this.lastColumnModel
            ) {
                this.setColumnOptions(initialColumnOptions);
            }

            if (this.props.sidebarOpen !== prevProps.sidebarOpen) {
                this.setSidebarOpen(this.props.sidebarOpen);
            }

            if (shouldHighlightNewOrders !== oldShouldHighlightNewOrders) {
                this.refresh();
            }

            if (currentDirectionLogic !== oldCurrentDirectionLogic) {
                this.refreshRowModel();
            }

            if (selectedLayoutId !== oldSelectedLayoutId) {
                this.processRowGroups();
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            sidebarOpen: nextSidebarOpen,
            token: nextToken,
            initialFilterOptions: nextInitialFilterOptions,
            initialColumnOptions: nextInitialColumnOptions,
            shouldHighlightNewOrders: nextShouldHighlightNewOrders,
            shouldShowConvertedOrders: nextShouldShowConvertedOrders,
            showCondensed: nextShowCondensed,
            createdIndex: nextCreatedIndex,
            currentDirectionLogic: nextCurrentDirectionLogic,
            currentQuantityFormat: nextCurrentQuantityFormat,
            newThemeEnabled: nextNewThemeEnabled,
            compactDensityViewEnabled: nextCompactDensityViewEnabled,
        } = nextProps;
        const {
            sidebarOpen: currentSidebarOpen,
            token: currentToken,
            initialFilterOptions: prevInitialFilterOptions,
            initialColumnOptions: prevInitialColumnOptions,
            shouldHighlightNewOrders: prevShouldHighlightNewOrders,
            shouldShowConvertedOrders: prevShouldShowConvertedOrders,
            showCondensed: prevShowCondensed,
            createdIndex: prevCreatedIndex,
            currentDirectionLogic: prevCurrentDirectionLogic,
            currentQuantityFormat: prevCurrentQuantityFormat,
            newThemeEnabled: prevNewThemeEnabled,
            compactDensityViewEnabled: prevCompactDensityViewEnabled,
        } = this.props;

        const { showShareConfirmation: nextShowShareConfirmation } = nextState;
        const { showShareConfirmation: prevShowShareConfirmation } = this.state;

        if (nextSidebarOpen !== currentSidebarOpen) {
            return true;
        }

        if (nextToken !== currentToken) {
            return true;
        }

        if (nextShowCondensed !== prevShowCondensed) {
            return true;
        }

        if (nextNewThemeEnabled !== prevNewThemeEnabled) {
            return true;
        }
        if (nextCompactDensityViewEnabled !== prevCompactDensityViewEnabled) {
            return true;
        }
        if (nextShowShareConfirmation !== prevShowShareConfirmation) {
            return true;
        }

        if (
            nextInitialColumnOptions !== prevInitialColumnOptions ||
            nextInitialFilterOptions !== prevInitialFilterOptions
        ) {
            return true;
        }

        if (nextCreatedIndex !== prevCreatedIndex) {
            return true;
        }

        if (nextShouldHighlightNewOrders !== prevShouldHighlightNewOrders) {
            return true;
        }

        if (nextShouldShowConvertedOrders !== prevShouldShowConvertedOrders) {
            return true;
        }

        if (nextCurrentDirectionLogic !== prevCurrentDirectionLogic) {
            return true;
        }

        if (nextCurrentQuantityFormat !== prevCurrentQuantityFormat) {
            return true;
        }

        return false;
    }

    /*
     * The idea here is to intercept the calls by ag-grid to the doesFilterPass methods.
     * This allows newly inserted fixtures and orders to pass any and all filters that the user
     * has applied. It leverages the same itemAddedSessionIndex mechanism that the defaultPostSort
     * system uses to keep new fixtures at the top of the grid, indepenant of any sorting.
     */
    handleGridReady = (params) => {
        this._gridApi = params.api;
        this._columnApi = params.columnApi;
        window._gridApi = this._gridApi;

        if (this.props.insertDefaultLayoutIfNotExist) {
            this.props.insertDefaultLayoutIfNotExist();
        }

        const { initialFilterOptions, initialColumnOptions, hotkeys } =
            this.props;

        //note: these set calls will end up triggering some actions indicating the sort/filters/etc have changed
        //this is technically accurate as AG Grid has been updated and may be doing more than just the state being
        //set currently.
        //the main impact of this is that 2 actions are triggered when the grid is loaded even though it normally
        //wouldn't impact the state data

        const allHotkeys = this.getAllHotkeys(hotkeys);

        if (allHotkeys) {
            this.unsubHotkeys = hotkeyService.subscribe(allHotkeys(this));
        }

        if (initialColumnOptions) {
            this.setColumnOptions(initialColumnOptions);
        }

        if (initialFilterOptions) {
            this.setFilterOptionsTimout = setTimeout(() => {
                //it seems the grid can't update columns, sorting and filters at the same time without causing issues in the filter
                //data available so this is postponed slightly
                this.setFilterOptions(initialFilterOptions, true);

                // we call wrapFiltersToEnsureNewRowVisible here because this is where filter potentially get created;
                this.wrapFiltersToEnsureNewRowVisible();

                // As part of the fix for 60385 following flag has been added to stop GridOptionsChangedEvent running updates
                // to save user layouts during setting grid options.
                setTimeout(() => {
                    this.bindEvents();
                });
            });
        } else {
            setTimeout(() => {
                this.bindEvents();
            });
        }

        if (this.rowData) {
            this._gridApi.applyTransaction({ add: this.rowData, addIndex: 0 });
        }

        this.configureColumns();

        this.setSidebarOpen(this.props.sidebarOpen);

        if (this.props.onGridReady) {
            this.props.onGridReady();
        }

        this.processRowGroups();
    };

    bindEvents = () => {
        this._gridApi.filterManager.gridOptionsWrapper.gridOptions.onFilterChanged =
            this.handleSortFilterChanged;
        this._gridApi.filterManager.gridOptionsWrapper.gridOptions.onSortChanged =
            this.handleSortFilterChanged;
        this._gridApi.filterManager.gridOptionsWrapper.gridOptions.onColumnMoved =
            this.handleUserGridOptionsChanged;
        this._gridApi.filterManager.gridOptionsWrapper.gridOptions.onColumnResized =
            this.handleUserGridOptionsChanged;
        this._gridApi.filterManager.gridOptionsWrapper.gridOptions.onColumnVisible =
            this.handleUserGridOptionsChanged;
        this._gridApi.filterManager.gridOptionsWrapper.gridOptions.onColumnPinned =
            this.handleUserGridOptionsChanged;
        this._gridApi.filterManager.gridOptionsWrapper.gridOptions.onColumnRowGroupChanged =
            this.onColumnRowGroupChanged;

        this.props.rerenderParent();
    };

    configureColumns = () => {
        const { initialColumnOptions } = this.props;
        configureColumns(this._columnApi, initialColumnOptions);
    };

    refreshRowModel = () => {
        if (this._gridApi) {
            this._gridApi.refreshClientSideRowModel();
            this.processRowGroups();
        }
    };

    rowUpdated = (data) => {
        const updatedData = cloneDeep(data);
        let updatingRowData;
        let addingRowData;

        this.setState(
            (state, props) => {
                const newState = {};
                let currentData;
                const dataSearch = state.rowData.filter(
                    (r) => r.id === data.id
                );
                if (dataSearch.length === 1) {
                    currentData = dataSearch[0];
                }

                if (!currentData) {
                    //we don't have it
                    Logger.warn(
                        `Item with ID ${updatedData.id} updated but not found in state`
                    );

                    addingRowData = data;

                    return newState;
                }

                if (currentData && !isEqual(currentData, updatedData)) {
                    newState.rowData = [...state.rowData];
                    assign(currentData, updatedData);
                    updatingRowData = currentData;
                }

                return newState;
            },
            () => {
                if (updatingRowData) {
                    this._gridApi.applyTransaction({
                        update: [updatingRowData],
                    });
                } else if (addingRowData) {
                    this.addRow(addingRowData);
                }
            }
        );
    };

    rowDeleted = (data, needStopEditing = true) => {
        let deletingRow;

        const cellsEdited = this._gridApi.getEditingCells();

        // This assumes that only one cell is being edited.
        // Get the cell, get the row, get the ID of the row.
        if (cellsEdited.length > 0) {
            const firstCellBeingEdited = cellsEdited[0];

            const rowBeingEdited = this._gridApi.getDisplayedRowAtIndex(
                firstCellBeingEdited.rowIndex
            );

            const idOfRowBeingEdited = rowBeingEdited.data.id;

            if (idOfRowBeingEdited === data.id) {
                this._gridApi.stopEditing(needStopEditing);
            }
        }

        this.setState(
            (state) => {
                const newState = {};
                let currentData;

                const dataSearch = state.rowData.filter(
                    (r) => r.id === data.id
                );
                if (dataSearch.length === 1) {
                    currentData = dataSearch[0];
                }

                if (!currentData) {
                    //we don't have it
                    Logger.warn(
                        `Item with ID ${data.id} deleted but not found in state`
                    );
                    return newState;
                }

                if (currentData) {
                    newState.rowData = [...state.rowData];
                    const index = newState.rowData.indexOf(currentData);
                    newState.rowData.splice(index, 1);

                    deletingRow = currentData;
                }

                return newState;
            },
            () => {
                if (deletingRow) {
                    const dataSearch = this.rowData.filter(
                        (r) => r.id === data.id
                    );
                    if (dataSearch.length === 1) {
                        const currentData = dataSearch[0];
                        const dIndex = this.rowData.indexOf(currentData);
                        this.rowData.splice(dIndex, 1);
                    }
                    this._gridApi.applyTransaction({ remove: [deletingRow] });
                }
            }
        );
    };

    addRow(data, startEditing) {
        const newData = cloneDeep(data);
        let addingRow = false;
        this.setState(
            (state, props) => {
                const newState = {};

                //only add if we don't have the id already
                if (
                    state.rowData.filter((r) => r.id === data.id).length === 0
                ) {
                    addingRow = true;
                    newState.rowData = [newData, ...state.rowData];
                }

                return newState;
            },
            () => {
                if (addingRow) {
                    this._gridApi.applyTransaction({
                        add: [newData],
                        addIndex: 0,
                    });
                    this.rowData.unshift(newData);

                    if (startEditing) {
                        this.startEditingNewRow(newData.id);
                    }
                }
            }
        );
    }

    startEditingNewRow(id) {
        const columns = this._columnApi.getAllDisplayedColumns();

        setTimeout(() => {
            if (!this._gridApi) {
                return null;
            }

            const rowNode = this._gridApi.getRowNode(id);

            const editableColumns = columns.filter(
                (c) => c.colDef && this.isColumnEditable(c.colDef, rowNode)
            );

            if (editableColumns.length > 0) {
                const colId =
                    this.props.newRowInitialColumnId ||
                    editableColumns[0].colId;

                this._gridApi.ensureColumnVisible(colId);
                this._gridApi.ensureIndexVisible(rowNode.rowIndex);

                setTimeout(() => {
                    this._gridApi.setFocusedCell(rowNode.rowIndex, colId, null);
                    this._gridApi.startEditingCell({
                        rowIndex: rowNode.rowIndex,
                        colKey: colId,
                    });
                });
            }
        });
    }

    isColumnEditable = (colDef, rowNode) => {
        let isEditable = false;

        if (typeof colDef.editable === 'function') {
            isEditable = colDef.editable({
                node: rowNode,
                data: rowNode.data,
                context: {
                    canEdit: this.props.canEdit,
                },
            });
        } else {
            isEditable = colDef.editable === true;
        }

        return isEditable;
    };

    // wraps every single active filter doesFilterPass method with a function that will prevent filtering of a newly added row
    wrapFiltersToEnsureNewRowVisible = () => {
        const filterModel = this._gridApi.getFilterModel();

        for (const colId in filterModel) {
            const filter = this._gridApi.getFilterInstance(colId);

            if (!filter) continue;

            if (filter.origDoesFilterPass) continue;

            filter.origDoesFilterPass = filter.doesFilterPass;

            filter.doesFilterPass = (params) => {
                const shouldPass =
                    this.props.itemAddedSessionIndex !== null &&
                    params.data.createdIndex >=
                        this.props.itemAddedSessionIndex;

                if (shouldPass) {
                    return true;
                }

                return filter.origDoesFilterPass(params);
            };
        }
    };

    refreshFilterValues = (filtersToBeRefreshed) => {
        if (filtersToBeRefreshed) {
            filtersToBeRefreshed.forEach((filterKey) => {
                const filter = this._gridApi.getFilterInstance(filterKey);
                if (filter) filter.refreshFilterValues();
            });
        }
    };

    export = async (shouldIncludeRow, shouldExportGroupHeadings) => {
        if (
            this._gridApi &&
            this._columnApi &&
            this._gridApi.getSelectedNodes().length !== 0
        ) {
            const gridContext = this.createGridContext();

            // Getting all selected nodes are marked to be shown in the page (including sorting and filtering).
            // Public Grid API method 'getSelectedNodes()' does not take into account sorting and filtering.
            const selectedNodes = this._gridApi.rowModel.rowsToDisplay.filter(
                (row) =>
                    row.isSelected() && !row.group && shouldIncludeRow(row.data)
            );

            // Compute which columns to include
            const currentColumnState = this._columnApi.getAllDisplayedColumns();

            // Filter out any columns which are marked to be skipped
            const columns = currentColumnState.filter(
                (column) =>
                    this.props.exportColIdsToSkip.indexOf(column.colId) === -1
            );

            const selectedLayoutName = this.props.layouts.allLayouts.find(
                (x) =>
                    x.id === this.props.layouts.selectedLayout.selectedLayoutId
            )?.name;

            await exportToExcel({
                context: gridContext,
                nodes: selectedNodes,
                columns,
                shouldExportGroupHeadings,
                newThemeEnabled: this.props.newThemeEnabled,
                datasetType: this.props.datasetType,
                datasetName: this.props.datasetName,
                layoutName: selectedLayoutName,
            });
        }
    };

    clearFilters = () => {
        this.setFilterOptions({}, true);
    };

    refresh = () => {
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }

        this.refreshTimeout = setTimeout(() => {
            if (this._gridApi && this.gridContainer.current) {
                const isEditing = this._gridApi.getEditingCells().length > 0;

                if (isEditing) {
                    this.setState({ refreshRequired: true });
                    return;
                }

                // redraw to force DOM updates to styling of rows - https://www.ag-grid.com/javascript-grid-refresh/#redraw-rows
                this.rowsEdited = [];

                this.setHeaderHeight(); // whatever happens inside the refresh function causes the the header to reinitiliase headerheight from grid.headerheight, which is null at this point of time and it defaults to its default value that does not suit us

                this._gridApi.redrawRows();
            }
        });
    };

    enchanceColumnDef = (col) => {
        col = this.wrapColumnSortingWithCreatedDateDescendingSortWrapper(col);
        // col = this.wrapColumnWithDefaultFilterSettings(col);
        col = this.addCommonStyles(col);

        return col;
    };

    wrapColumnWithDefaultFilterSettings = (col) => {
        const { filterParams, ...newColDef } = col;
        let newFilterParams = { ...filterParams };

        newColDef.filterParams = newFilterParams;

        return newColDef;
    };

    wrapColumnSortingWithCreatedDateDescendingSortWrapper = (col) => {
        const { comparator, ...result } = col;

        const colId = result.colId || result.field;

        //wrap every sorting comparator with a created date descending sort comparator
        //aim here is to ensure consistency across each instance of the app, everything becomes
        //additionally sorted by created date (descending), equivalent to adding an extra multisort
        //by CreatedDate into the grid.
        if (comparator) {
            result.comparator =
                CreateCreatedDateDescendingSortComparatorWrapper(
                    comparator,
                    colId,
                    this.sortOptions
                );
        } else {
            //even though there is no comparator explicitly defined on the column, we still need
            //to ensure consistency when sorting the column - so we add our own 'default' UnicodeSortComparator
            //on that is wrapped in the same way.
            //note: AG Grid uses similar default in their internal workings when no comparator is set.
            result.comparator =
                CreateCreatedDateDescendingSortComparatorWrapper(
                    UnicodeSortComparator,
                    colId,
                    this.sortOptions
                );
        }

        return result;
    };

    addCommonStyles = (col) => {
        return {
            ...col,
            cellClassRules: {
                errored: ({
                    data: { id: rowId },
                    colDef,
                    context: { cellErrors },
                }) =>
                    !!cellErrors.getError({
                        rowId: rowId,
                        colId: colDef.field,
                    }),
                ...col.cellClassRules,
            },
        };
    };

    postProcessPopup = (params) => {
        const { column, ePopup } = params;
        const { colId } = column || {};

        // we must not use hardcoded colIds or colIds at all in base components like BasicGrid, but the BasicGrid already does, and as refactor is incoming, this does not matter much
        if (colId === 'voyageActivity') {
            const voyagePopupWidth = 500;
            // const leftStr = ePopup.style.left;
            // const left = Number(leftStr.slice(0, leftStr.length - 2));
            // ePopup.style.left = `${left - voyagePopupWidth / 2}px`;
            ePopup.style['min-width'] = `${voyagePopupWidth}px`;
        }

        const onKeyDown = (e) => {
            if (
                e.keyCode === keyboardCodes.KEY_ENTER &&
                this._gridApi.hidePopupMenu
            ) {
                setTimeout(() => this._gridApi.hidePopupMenu());
            }
        };

        params.ePopup.addEventListener('keydown', onKeyDown.bind(this));
    };

    createGridContext() {
        return {
            cellErrors: new CellErrorsStore(),
            userTimezone: this.props.userTimezone,
            datasetId: this.props.token, // makes the dataset id available to editors and formatters in the grid
            groupId: this.props.selectedGroup.id, // makes the group id available to editors and formatters in the grid
            gridId: this.props.gridId,
            shouldHighlightNewOrders: this.props.shouldHighlightNewOrders,
            shouldShowConvertedOrders: this.props.shouldShowConvertedOrders,
            canEdit: this.props.canEdit,
            createdIndex: this.props.createdIndex,
            directionLogic: this.props.currentDirectionLogic,
            quantityFormat: this.props.currentQuantityFormat,
        };
    }

    resetRowGroupColumns = (currentRowGroupsIds) => {
        this._columnApi.setRowGroupColumns(currentRowGroupsIds);
    };

    createGroupNodePath = (node, path) => {
        if (node.level === -1) {
            path = 'root' + path;
            return path;
        } else {
            const coldId = this.getNodeColumnId(node);

            path = `_${coldId}-${node.key}` + path;
            node = node.parent;
            return this.createGroupNodePath(node, path);
        }
    };

    processRowGroups = () => {
        const { currentCollapsedRowGroups } = this.props;

        if (currentCollapsedRowGroups) {
            this._gridApi.expandAll();
            currentCollapsedRowGroups.forEach((groupPath) =>
                this.collapseRowGroup(groupPath)
            );
        }
    };

    collapseRowGroup = (groupPath) => {
        const rootNode = this._gridApi.rowModel.rootNode;
        const groupLevels = groupPath.split('_');
        groupLevels.shift(); // 'root' at the begginig is not needed

        let node = rootNode;
        groupLevels.forEach(
            (groupLevel) =>
                (node =
                    node && groupLevel in node.childrenMapped
                        ? node.childrenMapped[`${groupLevel}`]
                        : null)
        );

        if (node && node.expanded) {
            node.setExpanded(false);
        }
    };

    // getters
    getDisplayedColumns() {
        if (this._columnApi) {
            const currentColumnState = this._columnApi.getAllDisplayedColumns();
            return currentColumnState
                .map((c) => c.colId)
                .filter(
                    (id) => this.props.exportColIdsToSkip.indexOf(id) === -1
                );
        }
        return null;
    }

    getRowStyle = (params) => {
        if (!this._gridApi) {
            return null;
        }

        const currentFilterModel = this._gridApi.getFilterModel();
        const currentColumnState = this._columnApi.getColumnState();
        const isNotSortedByReportedDate =
            currentColumnState.filter(
                (s) => s.colId !== 'reportedDate' && s.sort !== null
            ).length !== 0;

        const isFilteringApplied = Object.keys(currentFilterModel).length !== 0;
        const rowHasBeenEdited =
            this.rowsEdited.indexOf(params.node.rowIndex) !== -1;
        const previousNode = this._gridApi.getDisplayedRowAtIndex(
            params.node.rowIndex - 1
        );

        if (
            params.group ||
            !params.data ||
            !previousNode ||
            isNotSortedByReportedDate ||
            isFilteringApplied ||
            rowHasBeenEdited ||
            (previousNode.data &&
                previousNode.data.reportedDate === params.data.reportedDate)
        ) {
            return null;
        }

        return { borderTop: '1px solid black' }; // the black line indicates sorted/grouped rows by date
    };

    getRowOrRowHeaderHeight = () => {
        if (this.props.newThemeEnabled) {
            return this.props.compactDensityViewEnabled ? 24 : 32;
        } else {
            return this.props.showCondensed ? 20 : 30;
        }
    };

    getNodes() {
        if (!this._gridApi) return;

        const res = {
            array: [],
            map: new Map(),
        };

        this._gridApi.forEachNode((node) => {
            res.array.push(node);
            res.map.set(node, true);
        });

        return res;
    }

    getSelectedNodes() {
        if (this._gridApi) {
            return this._gridApi.getSelectedNodes();
        } else {
            return null;
        }
    }

    getNodeColumnId = (node) => {
        const columnDefs = node.columnApi.columnController.getColumnDefs();
        const column = columnDefs.find((colDef) => colDef.field === node.field);

        return column.colId;
    };

    getContextMenuItems = (params) => {
        return getContextMenuItems(this.props, params);
    };

    getColumnState = () => {
        return this._columnApi.getColumnState();
    };

    getAllHotkeys(gridSpecificHotkeys) {
        const copyEntityHotKey = {
            keys: [CTRL, ALT, KEY_C],
            sub: (e) => {
                e.preventDefault();
                const node = this._gridApi.getDisplayedRowAtIndex(
                    this._gridApi.getFocusedCell().rowIndex
                );
                const context = this.createGridContext();
                copyEntityToClipboard(node, context, this.props.gridId);
            },
        };

        const hotkeys = (Grid) => {
            return [
                ...(gridSpecificHotkeys ? gridSpecificHotkeys(Grid) : []),
                copyEntityHotKey,
            ];
        };
        return hotkeys;
    }

    getToolPanels = () => {
        const defaultToolPanels = [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressPivots: true,
                    suppressValues: true,
                    suppressColumnExpandAll: true,
                    suppressColumnSelectAll: true,
                    suppressRowGroups: true,
                    suppressPivotMode: true,
                },
                checkDisplayConditions: () => true,
            },
            {
                id: 'groupingToolPanel',
                labelDefault:
                    this.props.gridId !== FIXTURE_GRID_TYPE
                        ? 'Order Grouping'
                        : 'Fixture Grouping',
                labelKey: 'groupingToolPanel',
                iconKey: 'columns',
                toolPanel: 'groupingToolPanel',
                toolPanelParams: {
                    datasetId: this.props.token,
                },
                checkDisplayConditions: () => true,
            },
            {
                id: 'commonSettingsToolPanel',
                labelDefault: 'Settings',
                labelKey: 'commonSettingsToolPanel',
                iconKey: 'columns',
                toolPanel: 'commonSettingsToolPanel',
                toolPanelParams: {
                    onColumnRowGroupChanged: this.onColumnRowGroupChanged,
                    datasetId: this.props.token,
                },
                checkDisplayConditions: () => true,
            },
        ];
        const toolPanelsToShow = defaultToolPanels.filter((panel) =>
            panel.checkDisplayConditions()
        );

        return toolPanelsToShow;
    };

    // setters
    setColumnOptions(columnOptions) {
        this.lastColumnModel = columnOptions;
        const currentColumnModel = this._columnApi.getColumnState();

        if (!isEqual(currentColumnModel, columnOptions)) {
            this._columnApi.setColumnState(columnOptions);
        }
    }

    setFilterOptions(filterOptions, suppressEvent = false) {
        if (!this._gridApi) {
            return;
        }

        this.lastFilterModel = filterOptions;
        const currentFilterModel = this._gridApi.getFilterModel();

        if (!isEqual(currentFilterModel, filterOptions)) {
            this._gridApi.setFilterModel(filterOptions);
            if (!suppressEvent) {
                this._gridApi.onFilterChanged();
            }
        }

        //next code is needed to refresh filters (like filtersToBeRefreshed in queryPostProcessingService) but if value in cells were changed not by the user (f.e. after changing Direction logic in Settings panel)
        this.refreshFilterValues(['direction']);
    }

    setSidebarOpen = (open) => {
        this._gridApi.setSideBarVisible(open);
        if (open) {
            const columnToolPanelId = 'columns';
            this._gridApi.openToolPanel(columnToolPanelId);
        }
    };

    setHeaderHeight() {
        this._gridApi.setHeaderHeight(this.getRowOrRowHeaderHeight());
    }

    setupNewGridData = () => {
        this.setState(
            {
                rowData: cloneDeep(this.props.rowData),
            },
            () => {
                this.rowData.length = 0;
                this.rowData.push(...this.state.rowData);
                if (this._gridApi) {
                    this._gridApi.setRowData(this.rowData);
                }
            }
        );
    };

    // handlers
    onColumnRowGroupChanged = (params) => {
        // any changes in Row Groups should reset array with paths to empty
        this.props.onCollapsedRowGroupsChanged([]);
        this.handleUserGridOptionsChanged(params);

        this.reloadRowGroupingIndentation();
        this._columnApi.setColumnState(params.columns);
    };

    onTabSelect = (to) => {
        this.props.history.push(to);
    };

    reloadRowGroupingIndentation = () => {
        const currentColumnModel = this._columnApi.getColumnState();
        const isSelectedColumn = currentColumnModel.find(
            (column) => column.colId === 'isSelected'
        );

        const rowGroupCount = currentColumnModel.filter(
            (column) => column.rowGroup
        ).length;
        if (isSelectedColumn) {
            if (this.props.newThemeEnabled) {
                const width = rowGroupCount > 1 ? 77 : 35;
                isSelectedColumn.width = width;
                isSelectedColumn.minWidth = width;

                this._columnApi.setColumnState(currentColumnModel);
            }
        }
    };

    onRowGroupOpened = (params) => {
        const { currentCollapsedRowGroups } = this.props;
        const { expanded } = params.node;
        const groupNodePath = this.createGroupNodePath(params.node, '');
        let newCollapsedRowGroups = currentCollapsedRowGroups.slice();

        if (!expanded) {
            if (!newCollapsedRowGroups.includes(groupNodePath)) {
                newCollapsedRowGroups.push(groupNodePath);
            }
        } else {
            newCollapsedRowGroups = currentCollapsedRowGroups.filter(
                (path) => path !== groupNodePath
            );
        }

        this.props.onCollapsedRowGroupsChanged(newCollapsedRowGroups);
    };

    handleBlur = () => {
        this.focusTimeout = setTimeout(() => {
            if (this._gridApi) {
                this._gridApi.stopEditing();
                this._gridApi.clearFocusedCell();
            }
        }, 0);
    };

    handleFocus = () => {
        clearTimeout(this.focusTimeout);
    };

    handleUserGridOptionsChanged = ({
        api,
        columnApi,
        shouldResetSessionIndex,
    }) => {
        if (this.props.userGridOptionsUpdated) {
            this.lastFilterModel = api.getFilterModel();
            this.lastColumnModel = columnApi.getColumnState();

            const gridOptionsChanged = {
                filterModel: this.lastFilterModel,
                columnModel: this.lastColumnModel,
            };

            if (!isEqual(gridOptionsChanged, this.lastGridOptionsChanged)) {
                //the grid seems to eagerly trigger this event even if nothing has actually been altered
                //this is aimed at reducing the number of redux actions being triggered.
                //It also stops other components from acting upon a non change.

                this.lastGridOptionsChanged = cloneDeep(gridOptionsChanged);
                this.props.userGridOptionsUpdated({
                    filterOptions: this.lastFilterModel,
                    columnOptions: this.lastColumnModel,
                    shouldResetSessionIndex: shouldResetSessionIndex,
                });
            }
        }
    };

    handleSidebarClick = (event) => {
        if (
            this.props.sidebarOpen &&
            this.props.onSidebarClickOutside &&
            this.sideBarElement &&
            !this.sideBarElement.contains(event.target)
        ) {
            this.props.onSidebarClickOutside();
        }
    };

    handleOnCellEditingStarted = (params) => {
        this.rowsEdited.push(params.rowIndex);
    };

    handleOnCellEditingStopped = (params) => {
        if (this.state.refreshRequired) {
            this.setState({ refreshRequired: false }, () => this.refresh());
        }
    };

    handlePostSort = (rowNodes) => {
        let userSortingIsActive = false;
        if (this._columnApi) {
            const columnState = this._columnApi.getColumnState();
            //check to see if the user has selected to sort anything in the grid
            userSortingIsActive =
                columnState.filter((cs) => cs.sort !== null).length > 0;
        }
        defaultPostSort({
            itemAddedSessionIndex: this.props.itemAddedSessionIndex,
            rowNodes,
            sortingIsActive: userSortingIsActive,
            datasetId: this.props.token,
        });
        this.refresh();
    };

    handleSortFilterChanged = (params) => {
        // we call wrapFiltersToEnsureNewRowVisible here because this is where filter potentially get created;
        this.wrapFiltersToEnsureNewRowVisible();

        this.sortOptions.lastSortColId = null;

        this.props.onSelectionChanged();

        //user has changed sorting or filtering setting in the grid, we no longer want to keep the newly added rows at the top
        params.shouldResetSessionIndex = true;

        this.handleUserGridOptionsChanged(params);
    };

    handleBodyScroll = (bodyScrollEvent) => {
        // To allow tabbing to work across an entire row, we will only cancel editing if the
        // user scrolls up and down.
        if (bodyScrollEvent.direction === 'vertical') {
            this._gridApi.stopEditing(true);
        }

        if (this.props.gridHasScrolled) {
            var firstRow = this._gridApi.getFirstDisplayedRow();
            var lastRow = this._gridApi.getLastDisplayedRow();

            var rowCount = this._gridApi.getDisplayedRowCount();

            var lastGridIndex = rowCount - 1;
            var currentPage = this._gridApi.paginationGetCurrentPage();
            var pageSize = this._gridApi.paginationGetPageSize();

            var endPageIndex = (currentPage + 1) * pageSize - 1;
            if (endPageIndex > lastGridIndex) {
                endPageIndex = lastGridIndex;
            }

            const visibleFixtures = [];

            for (var i = firstRow; i <= lastRow; i++) {
                var rowNode = this._gridApi.getDisplayedRowAtIndex(i);
                visibleFixtures.push(rowNode.data);
            }

            this.props.gridHasScrolled(visibleFixtures);
        }
    };

    render() {
        const wrapperClass = ClassNames('ag-theme-material grid', {
            'grid-condensed':
                this.props.showCondensed && !this.props.newThemeEnabled,
            'new-theme': this.props.newThemeEnabled,
            'compact-density':
                this.props.compactDensityViewEnabled &&
                this.props.newThemeEnabled,
            'standard-density':
                !this.props.compactDensityViewEnabled &&
                this.props.newThemeEnabled,
        });

        /* need a variable for gridContext, as unfortunately context is not passed to some events, e.g. `onCellFocused` */
        const gridContext = this.createGridContext();

        const getStatusBar = () => {
            const statusPanels = [];
            if (this.props.singleView) {
                statusPanels.push({
                    statusPanel: 'statusBarSheetSelectors',
                    key: 'statusBarSheetSelectors',
                    align: 'left',
                });
            }

            if (this.props.showTotalsInStatusBar) {
                statusPanels.push({
                    statusPanel: 'agTotalAndFilteredRowCountComponent',
                    align: 'right',
                });
            }
            return { statusPanels };
        };

        const rowHeight = this.getRowOrRowHeaderHeight();

        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                }}
            >
                <div
                    className={wrapperClass}
                    style={{
                        boxSizing: 'border-box',
                        height: '100%',
                        width: '100%',
                    }}
                    onBlur={this.handleBlur}
                    onFocus={this.handleFocus}
                    ref={this.gridContainer}
                >
                    <AgGridReact
                        onRowGroupOpened={this.onRowGroupOpened}
                        modules={AllModules}
                        suppressRowClickSelection={true}
                        enableRangeSelection={true}
                        suppressDragLeaveHidesColumns={true}
                        allowDragFromColumnsToolPanel={true}
                        groupSelectsChildren={true}
                        rowSelection="multiple"
                        columnTypes={{ privatable: {}, rumoured: {} }} // This is a placeholder for the private and rumoured cell control mechanism
                        headerHeight={rowHeight}
                        rowHeight={rowHeight}
                        columnDefs={this.state.enchancedColumnDefs}
                        defaultColDef={{
                            sortable: true,
                            filter: true,
                            resizable: true,
                            valueSetter: this.props.defaultSetter,
                            valueFormatter: UpperFormatter,
                            menuTabs: ['filterMenuTab'],
                            filterParams: { ...defaultSetFilterParams },
                            suppressPaste: true,
                            enableCellChangeFlash: true,
                        }}
                        tooltipShowDelay={500}
                        getRowNodeId={this.props.getDataId}
                        onRowUpdated={this.rowUpdated}
                        sideBar={this.state.sideBar}
                        onGridReady={this.handleGridReady}
                        rowClassRules={this.props.rowClassRules}
                        getContextMenuItems={this.getContextMenuItems}
                        processCellForClipboard={processCellCallback}
                        groupUseEntireRow={true}
                        groupDefaultExpanded={-1}
                        defaultGroupSortComparator={
                            this.props.defaultGroupSortComparator
                        }
                        getMainMenuItems={getMainMenuItems}
                        onCellEditingStarted={this.handleOnCellEditingStarted}
                        onCellEditingStopped={this.handleOnCellEditingStopped}
                        getRowHeight={this.getRowOrRowHeaderHeight}
                        postSort={this.handlePostSort}
                        statusBar={getStatusBar()}
                        onBodyScroll={this.handleBodyScroll}
                        onCellFocused={({ rowIndex, api, column }) => {
                            if (rowIndex == null) return; // For some reason we also get called on grid load without any cell focused.

                            if (api.getDisplayedRowAtIndex(rowIndex)) {
                                gridContext.cellErrors.ensureErrorDeleted({
                                    rowId: api.getDisplayedRowAtIndex(rowIndex)
                                        .id,
                                    colId: column.colDef.field,
                                    callbackIfErrorWasPresent: () => {
                                        this._gridApi.refreshCells();
                                    },
                                });
                            }
                        }}
                        context={gridContext}
                        getRowStyle={this.getRowStyle} // group related
                        postProcessPopup={this.postProcessPopup}
                        onSelectionChanged={this.props.onSelectionChanged}
                        frameworkComponents={{
                            laycanMonthFilter: LaycanMonthFilter,
                            visibilityFilter: VisibilityFilter,
                            dateFilter: DateFilter,
                            cargoesFilter: CargoesFilter,
                            locationsFilter: LocationsFilter,
                            companiesFilter: CompaniesFilter,
                            laycanFilter: LaycanFilter,
                            deadweightFilter: DeadweightFilter,
                            quantityFilter: QuantityFilter,
                            durationFilter: DurationFilter,
                            salePriceFilter: SalePriceFilter,
                            commonSettingsToolPanel: CommonSettingsToolPanel,
                            groupingToolPanel: this.props.groupingToolPanel,
                            statusBarSheetSelectors: () => {
                                return (
                                    <StatusBarSheetSelectors
                                        isAdmin={this.props.isAdmin}
                                        onTabSelect={this.onTabSelect}
                                    />
                                );
                            },
                        }}
                    />
                    <AuditTooltip
                        context={gridContext}
                        getRowDataCallback={(index) => {
                            if (this._gridApi) {
                                return this._gridApi.getDisplayedRowAtIndex(
                                    index
                                );
                            } else {
                                return null;
                            }
                        }}
                    />
                    <VoyageActivityTooltip
                        context={gridContext}
                        getRowDataCallback={(index) => {
                            if (this._gridApi) {
                                return this._gridApi.getDisplayedRowAtIndex(
                                    index
                                );
                            } else {
                                return null;
                            }
                        }}
                    />
                    <GroupOnlyExpiryTooltip
                        context={gridContext}
                        getRowDataCallback={(index) => {
                            if (this._gridApi) {
                                return this._gridApi.getDisplayedRowAtIndex(
                                    index
                                );
                            } else {
                                return null;
                            }
                        }}
                    />
                    <ClarksonsBrokerTooltip
                        context={gridContext}
                        getRowDataCallback={(index) => {
                            if (this._gridApi) {
                                return this._gridApi.getDisplayedRowAtIndex(
                                    index
                                );
                            } else {
                                return null;
                            }
                        }}
                    />
                    <LastUpdatedByTooltip
                        context={gridContext}
                        getRowDataCallback={(index) => {
                            if (this._gridApi) {
                                return this._gridApi.getDisplayedRowAtIndex(
                                    index
                                );
                            } else {
                                return null;
                            }
                        }}
                    />
                </div>
            </div>
        );
    }
}

const processCellCallback = (params) => {
    const {
        value,
        node,
        column: {
            colDef: { valueFormatter, showRowGroup },
        },
    } = params;

    if (node && node.group && showRowGroup) {
        return value;
    }

    if (value !== undefined) {
        // let nulls through so we can format them, e.g. 'UNKNOWN'
        if (valueFormatter) {
            return valueFormatter(params);
        }
    }

    return value;
};

BasicGridWithoutRouter.defaultProps = {
    suppressRowPivot: false,
    groupUseEntireRow: true,
    groupDefaultExpanded: false,
    skipGroupRowsInExport: false,
    exportColIdsToSkip: [],
    onSelectionChanged: () => {},
};

BasicGridWithoutRouter.propTypes = {
    getDataId: PropTypes.func.isRequired,
    rowData: PropTypes.array,
    token: PropTypes.number.isRequired,
    defaultSetter: PropTypes.func.isRequired,
    headings: PropTypes.array.isRequired,
    sidebarOpen: PropTypes.bool.isRequired,
    onSidebarClickOutside: PropTypes.func,
    suppressRowPivot: PropTypes.bool,
    groupDefaultExpanded: PropTypes.bool,
    defaultGroupSortComparator: PropTypes.func,
    onGridReady: PropTypes.func,
    skipGroupRowsInExport: PropTypes.bool,
    exportColIdsToSkip: PropTypes.array,
    itemAddedSessionIndex: PropTypes.number,
    showCondensed: PropTypes.bool,
    newThemeEnabled: PropTypes.bool,
    compactDensityViewEnabled: PropTypes.bool,
    canPerformAction: PropTypes.func.isRequired,
    onMakePublic: PropTypes.func.isRequired,
    onMakePrivate: PropTypes.func.isRequired,
    onMakeUnRumoured: PropTypes.func.isRequired,
    onMakeRumoured: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    onShareToGroup: PropTypes.func.isRequired,
    currentDirectionLogic: PropTypes.string.isRequired,
    periodicUpdateGrid: PropTypes.func,
    updateGridTimeInterval: PropTypes.number,
    groupingToolPanel: PropTypes.object,
};

//After moving the tab buttons inside the status bar of the grid, the grid needed to be
//wrapped with withRouter, to be able to change the route. But this created problems
//with the tests, and to avoid extensive refactoring I opted to make both versions
//available
const withRouterAndRef = (Wrapped) => {
    const WithRouter = withRouter(({ forwardRef, ...otherProps }) => (
        <Wrapped ref={forwardRef} {...otherProps} />
    ));
    const WithRouterAndRef = React.forwardRef((props, ref) => (
        <WithRouter {...props} forwardRef={ref} />
    ));

    return connect(mapStateToProps, null, null, { forwardRef: true })(
        WithRouterAndRef
    );
};

export default withRouterAndRef(BasicGridWithoutRouter);
