import Cloud from '../api/clarksons/cloud';
import isNil from 'lodash/isNil';

//values have been taken from internal nuget package
const cloudRights = {
    fort: 33,
    standard: 1,
    admin: 2,
};

const _unAuthorised = { isAuthorised: false, isAdmin: false, pending: false };

const hasSecurityRight = (moduleRights, securityRight) =>
    moduleRights.filter((r) => (r & securityRight) === securityRight).length >
    0;

const authorise = async () => {
    const cloud = new Cloud();
    const user = await cloud.getCurrentUser();

    if (!user || user.expired) {
        return _unAuthorised;
    }

    const securityRights = user.SecurityRights;

    if (isNil(securityRights)) {
        return _unAuthorised;
    }

    const fortModuleSecurityRights = securityRights[cloudRights.fort];

    if (isNil(fortModuleSecurityRights)) {
        return _unAuthorised;
    }

    const isStandard = hasSecurityRight(
        fortModuleSecurityRights,
        cloudRights.standard
    );

    const isAdmin = hasSecurityRight(
        fortModuleSecurityRights,
        cloudRights.admin
    );

    if (!isStandard && !isAdmin) {
        return _unAuthorised;
    }

    return {
        isAuthorised: true,
        isAdmin,
        pending: false,
        user: user,
    };
};

export { authorise };
