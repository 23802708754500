import React from 'react';
import { useDrop } from 'react-dnd';
import MultiSelectSpace from './MultiSelectSpace';

const LOCATION_ITEM = 'LOCATION_ITEM';

const DroppableMultiSelectSpace = (props) => {
    const [{ isOver }, drop] = useDrop({
        accept: LOCATION_ITEM,
        drop: (item) => {
            if (typeof props.onDraggedItem === 'function') {
                props.onDraggedItem(item.id, props.index);
            }
        },
        collect: (monitor) => {
            const draggedItem = monitor.getItem();
            const isOver =
                draggedItem &&
                draggedItem.id !== props.index &&
                monitor.isOver();
            return {
                isOver: isOver,
            };
        },
    });

    return <MultiSelectSpace ref={drop} stretched={isOver} {...props} />;
};

export default DroppableMultiSelectSpace;
