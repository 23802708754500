import './FortDownshiftInput.scss';
import React, { Component } from 'react';
import Downshift from 'downshift';
import { CYPRESS_DATA_ATTRIBUTES } from 'constant';
import DownshiftActivityIndicator from './DownshiftActivityIndicator';
import axios from 'axios';
import {
    KEY_BACKSPACE,
    KEY_TAB,
    KEY_ENTER,
} from '../../../constants/keyboardCodes';
import generateId from '../../../tools/idGenerator';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { defaultDebounceMs } from '../../../constants/inputBehaviours';
import trim from 'lodash/trim';
import { ENTITY_PART_TYPE_CUSTOM } from '../../../models/common/EntityPart';
import {
    OrdersPaneId,
    FixturesPaneId,
} from '_legacy/constants/CommonConstants';

const { GRID_CELL_INPUT } = CYPRESS_DATA_ATTRIBUTES;

const CancelToken = axios.CancelToken;

class FortDownshiftInput extends Component {
    constructor(props) {
        super(props);
        this.reset = this.reset.bind(this);
        this.state = this.initialState(props);
        this.downshiftRef = React.createRef();
        this.inputRef = React.createRef();
        this.stateReducer = this.stateReducer.bind(this);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
        this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
        this.hasFocus = this.hasFocus.bind(this);
        this.search = this.search.bind(this);
        this.search = debounce(this.search, defaultDebounceMs);
        this.cancelSource = null;
        this.cancelSearch = this.cancelSearch.bind(this);
        this.cancelSearchRequests = this.cancelSearchRequests.bind(this);

        this.onFilterChanged = this.onFilterChanged.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.state.inputValue && this.state.initialIsOpen) {
            this.setState({ isLoading: true });
            this.cancelSearchRequests();
            this.search(this.state.inputValue);
        }
    }

    componentDidMount() {
        if (this.state.initialSeparator) {
            if (this.props.onSeparatorEntered) {
                this.props.onSeparatorEntered(this.props.initialChar);
            }
        }
    }

    componentWillUnmount() {
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }
    }

    initialState = (props) => {
        let inputValue = '';
        let initialIsOpen = false;
        let initialSeparator = false;

        if (props.initialChar) {
            if (this.isSeparatorCharacter(props.initialChar)) {
                initialSeparator = true;
            } else {
                inputValue = props.initialChar;
                initialIsOpen = true;
            }
        } else {
            inputValue = props.itemToString(this.props.value);
        }

        return {
            searchResults: [],
            error: null,
            isDeleting: false,
            isLoading: false,
            inputValue,
            initialIsOpen,
            initialSeparator,
        };
    };

    reset() {
        this.setState((state, props) => this.initialState(props));
    }

    search(value) {
        const trimmedValue = trim(value);

        if (
            this.props.minCharectersForSearch &&
            trimmedValue.length < this.props.minCharectersForSearch
        ) {
            this.setState({
                isLoading: false,
                searchResults: [],
                error: null,
            });
            return;
        }

        this.setState({ isLoading: true });

        const searchFunc = this.props.datasetId
            ? this.props.searchFunc(
                  trimmedValue,
                  this.props.datasetId,
                  this.cancelSource.token
              )
            : this.props.searchFunc(trimmedValue, this.cancelSource.token);

        searchFunc
            .then((results) => {
                const values = this.props.shouldItemBeShown
                    ? results.filter((item) =>
                          this.props.shouldItemBeShown(item)
                      )
                    : [...results];

                if (
                    results.filter(
                        (c) =>
                            c.name?.toLowerCase() === trimmedValue.toLowerCase()
                    ).length < 1 &&
                    this.props.createCustomItem
                ) {
                    const customItem = this.props.createCustomItem(
                        trimmedValue.toUpperCase()
                    );
                    customItem.key = generateId();
                    values.push(customItem);
                }

                if (
                    this.props.customItemName &&
                    trimmedValue.toLowerCase() ===
                        this.props.customItemName.toLowerCase()
                ) {
                    const customItem = {
                        id: this.props.customItemName,
                        name: this.props.customItemName,
                        type: ENTITY_PART_TYPE_CUSTOM,
                    };
                    values.unshift(customItem);
                }

                this.setState({
                    isLoading: false,
                    searchResults: values,
                    error: null,
                });
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    this.setState({ error });
                }
            });
    }

    cancelSearchRequests() {
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }

        this.cancelSource = CancelToken.source();
    }

    cancelSearch() {
        if (this.search.cancel) {
            this.search.cancel();
        }

        this.cancelSearchRequests();
    }

    isPlaceholderCharacter(character) {
        if (this.props.placeholderTerms) {
            return (
                this.props.placeholderTerms.indexOf(character.toUpperCase()) >
                -1
            );
        }

        return false;
    }

    stateReducer(state, changes) {
        switch (changes.type) {
            case Downshift.stateChangeTypes.changeInput:
                this.setState({ inputValue: changes.inputValue, error: null });

                const valueToSearch = changes.inputValue.trim();

                if (valueToSearch === '') {
                    this.cancelSearch();
                    changes.isOpen = false;
                    //cleared items
                    this.props.onInputCleared();
                } else if (this.isSeparatorCharacter(valueToSearch)) {
                    this.cancelSearch();
                    changes.isOpen = false;
                    changes.inputValue = '';

                    if (this.props.onSeparatorEntered) {
                        this.props.onSeparatorEntered(
                            valueToSearch.toUpperCase()
                        );
                    }

                    this.props.onInputCleared();
                } else if (this.isPlaceholderCharacter(valueToSearch)) {
                    this.cancelSearch();
                    changes.isOpen = false;
                    changes.inputValue = '';

                    this.props.onPlaceholderEntered(
                        valueToSearch.toUpperCase()
                    );
                    this.reset();
                } else {
                    this.cancelSearchRequests();

                    this.search(changes.inputValue);

                    changes.isOpen = true;
                }

                return {
                    ...changes,
                };
            case Downshift.stateChangeTypes.keyDownEnter:
                this.handleItemSelected(changes.selectedItem, KEY_ENTER, false);
                this.reset();
                changes.inputValue = '';
                changes.isOpen = false;

                return {
                    ...changes,
                };
            case Downshift.stateChangeTypes.clickItem:
                this.handleItemSelected(changes.selectedItem);
                this.reset();

                changes.inputValue = '';
                changes.isOpen = false;

                return {
                    ...changes,
                };
            default:
                return changes;
        }
    }

    isSeparatorCharacter(character) {
        if (this.props.seperatorCharacters) {
            return this.props.seperatorCharacters.indexOf(character) > -1;
        }

        return false;
    }

    isRestrictedKey(key) {
        return (
            this.isSeparatorCharacterNotAtBeginning(key) ||
            this.isBlockedCharacter(key)
        );
    }

    isSeparatorCharacterNotAtBeginning(key) {
        const { inputValue } = this.state;
        return inputValue !== '' && this.isSeparatorCharacter(key);
    }

    isBlockedCharacter(character) {
        if (this.props.blockedCharacters) {
            return this.props.blockedCharacters.indexOf(character) > -1;
        }

        return false;
    }

    handleItemSelected(selectedItem, keyCode, shift) {
        if (this.props.onItemSelected) {
            this.props.onItemSelected(selectedItem, keyCode, shift);
        }
    }

    handleInputKeyDown(e) {
        switch (e.keyCode) {
            case KEY_BACKSPACE:
                const { isDeleting, inputValue } = this.state;

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onDelete) {
                        this.props.onDelete();
                    }
                }

                this.setState({ isDeleting: true });
                break;
            case KEY_TAB:
                if (this.state.isLoading) {
                    e.preventDefault();
                    e.stopPropagation();
                    return true;
                }

                if (!e.altKey && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();

                    if (
                        this.props.shouldSelectItemOnTab &&
                        this.downshiftRef.current.items &&
                        this.downshiftRef.current.items.length > 0
                    ) {
                        const selectedItem =
                            this.downshiftRef.current.items[
                                this.downshiftRef.current.state.highlightedIndex
                            ];

                        this.handleItemSelected(
                            selectedItem,
                            e.keyCode,
                            e.shiftKey
                        );
                    } else {
                        if (!e.shiftKey) {
                            this.props.onTab();
                        } else {
                            this.props.onTabBack();
                        }
                    }
                }
                break;
            case KEY_ENTER:
                e.preventDefault();
                e.stopPropagation();

                if (this.state.isLoading) {
                    return true;
                }

                if (
                    this.downshiftRef.current.items &&
                    this.downshiftRef.current.items.length === 0
                ) {
                    this.props.onEnter();
                }
                break;
            default:
                if (this.isRestrictedKey(e.key)) {
                    e.preventDefault();
                    e.stopPropagation();
                }
        }
    }

    handleInputKeyUp(e) {
        if (e.keyCode === KEY_BACKSPACE) {
            this.setState({ isDeleting: false });
        }
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
            if (!this.props.initialChar) {
                this.inputRef.current.select();
            }
        }
    }

    onFilterChanged() {
        if (this.state.inputValue) {
            this.cancelSearchRequests();
            this.search(this.state.inputValue);
        }
    }

    hasFocus() {
        return this.inputRef.current === document.activeElement;
    }

    /** Returns the css properties for the 'item choice menu', that will result in the menu fitting iside the closes 'scrollable ancestor' content (regardless), without causing that ancestor's content to expand.
     * TODO: Consolidate copy-pasted code (have LocationSelect use this component) and make this private.
     */
    static getCssPositionPropInsideScrollAncestor(anchorElement, sizeProps) {
        if (!(anchorElement instanceof HTMLElement)) return {};

        // The extra point of height is needed so that focus on the right row is always visible within the box. Not 100% sure why, but taking it out causes undesirable behaviour.
        const menuItemHeight = 28 + 1;

        const preferAboveIfBelowCantFitItemsCount = 5;

        var scrollingAncestor =
            getElementsClosestScrollableAncestor(anchorElement);

        if (!scrollingAncestor) return {};

        let anchorRect = anchorElement.getBoundingClientRect();

        let availHeightBelow =
            scrollingAncestor.scrollHeight - (anchorRect.y + anchorRect.height);
        let availHeightAbove = anchorRect.y;

        const ordersPane = document.getElementById(OrdersPaneId);
        const fixturesPane = document.getElementById(FixturesPaneId);
        // if true - we are in multiscreen mode
        if (ordersPane && fixturesPane) {
            const heightOfPlateTopBorder = 7;
            const heightOfDropdownBorder = 2;
            const heightOfAgGridToolbar = 43;
            const heightOfSeaHeaderPlusAgGridToolbarPlusBorder = 97;

            let additionalComponentSize = 0;
            if (sizeProps) {
                additionalComponentSize = sizeProps.additionalComponentSize;
            }

            const { top, bottom } = fixturesPane.getBoundingClientRect();

            const fixturePaneHeight = bottom - top;

            // determine in which plate we are - true: Orders, false: Fixtures. Then re-calculate sizes
            if (top > availHeightAbove) {
                availHeightBelow -= fixturePaneHeight + heightOfPlateTopBorder;
                availHeightAbove -=
                    heightOfSeaHeaderPlusAgGridToolbarPlusBorder;
            } else {
                availHeightAbove -= top + heightOfAgGridToolbar;
                availHeightBelow -= heightOfDropdownBorder;
            }

            availHeightAbove -= additionalComponentSize;
            availHeightBelow -= additionalComponentSize;
        }

        let isAboveInput =
            availHeightBelow <=
                menuItemHeight * preferAboveIfBelowCantFitItemsCount &&
            availHeightBelow < availHeightAbove;

        return {
            maxHeight:
                Math.min(
                    355 /*UX decision*/,
                    isAboveInput ? availHeightAbove : availHeightBelow
                ) + `px`,
            bottom: isAboveInput ? anchorRect.height + 'px' : undefined,
        };

        /*prettier-ignore*/
        function getElementsClosestScrollableAncestor(element) {
      // Based on: https://stackoverflow.com/questions/35939886/find-first-scrollable-parent/42543908#42543908
      var style = getComputedStyle(element);
      var excludeStaticParent = style.position === "absolute";

      if (style.position === "fixed") return document.body;
      for (var parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static") {
          continue;
        }
        if (/(auto|scroll)/.test(style.overflow + style.overflowY + style.overflowX) || parent === document.body) return parent;
      }

      return null;
    }
    }

    /**
     * TODO: Consolidate copy-pasted code (have LocationSelect use this component) and inline this.
     */
    static getStandardClassForFortDownshiftItems() {
        return 'downshift-menu-item';
    }

    renderItems(inputValue, getItemProps, highlightedIndex) {
        return (
            <table
                className="ui compact table"
                style={{ width: '100%' }}
                border="0"
            >
                {this.props.renderTableHeader && this.props.renderTableHeader()}
                <tbody>
                    {this.state.searchResults.map((item, index) =>
                        this.props.renderItem(
                            /*getItemProps:*/ (callerProps) => ({
                                className:
                                    FortDownshiftInput.getStandardClassForFortDownshiftItems(),
                                ...getItemProps(callerProps),
                            }),
                            highlightedIndex,
                            item,
                            index,
                            this.state.searchResults.length
                        )
                    )}
                    {this.props.toClientCheck}
                </tbody>
            </table>
        );
    }

    render() {
        return (
            <Downshift
                ref={this.downshiftRef}
                itemToString={this.props.itemToString}
                initialInputValue={this.state.inputValue}
                initialIsOpen={this.state.initialChar}
                stateReducer={this.stateReducer}
                defaultHighlightedIndex={0}
            >
                {({
                    inputValue,
                    getInputProps,
                    getItemProps,
                    isOpen,
                    highlightedIndex,
                }) => (
                    <div
                        className={`downshift-container ${this.props.className}`}
                    >
                        <div className={this.props.inputClass}>
                            <input
                                type="text"
                                data-cy={GRID_CELL_INPUT}
                                {...getInputProps({
                                    ref: this.inputRef,
                                    onKeyDown: this.handleInputKeyDown,
                                    onKeyUp: this.handleInputKeyUp,
                                    className: 'downshift-input',
                                    placeholder: this.props.placeholder,
                                })}
                            />
                        </div>
                        {isOpen && (
                            <div
                                className={`fort-downshift-content ${this.props.downshiftContentClassname}`}
                                style={{
                                    ...FortDownshiftInput.getCssPositionPropInsideScrollAncestor(
                                        this.inputRef.current,
                                        this.props.sizeProps
                                    ),
                                    position: 'absolute',
                                    maxHeight: 'none',
                                }}
                            >
                                {this.props.children}
                                {this.state.error ? (
                                    <div
                                        className="downshift-menu-item"
                                        style={{ color: 'red' }}
                                    >
                                        Could not search
                                    </div>
                                ) : null}
                                {!this.state.error ? (
                                    <div
                                        className="downshift-menu"
                                        style={
                                            this.state.isLoading
                                                ? undefined
                                                : FortDownshiftInput.getCssPositionPropInsideScrollAncestor(
                                                      this.inputRef.current,
                                                      this.props.sizeProps
                                                  )
                                        }
                                    >
                                        {this.state.isLoading ? (
                                            <DownshiftActivityIndicator />
                                        ) : (
                                            this.renderItems(
                                                inputValue,
                                                getItemProps,
                                                highlightedIndex,
                                                this.state.displayItems
                                            )
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                )}
            </Downshift>
        );
    }
}

FortDownshiftInput.propTypes = {
    searchFunc: PropTypes.func.isRequired,
    itemToString: PropTypes.func.isRequired,
    renderItem: PropTypes.func.isRequired,
    shouldSelectItemOnTab: PropTypes.bool.isRequired,
    inputClass: PropTypes.string,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    initialChar: PropTypes.string,
    onInputCleared: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    onItemSelected: PropTypes.func.isRequired,
};

export default FortDownshiftInput;
