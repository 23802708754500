import React, { Component } from 'react';
import DateInput from '../inputs/DateInput';
import FortCellEditor from '_legacy/modules/shared/columns/editors/FortCellEditor';

export default class DateEditor extends Component {
    constructor(props) {
        super(props);
        this.dateInput = React.createRef();
        this.focus = this.focus.bind(this);
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.dateInput.current) {
            this.dateInput.current.focus();
        }
    }

    getValue() {
        const value = this.dateInput.current.date;
        if (value) {
            //need to convert to same format as returned by the server
            const isoFormat = value.toISOString();
            return isoFormat.split('.')[0] + 'Z';
        }
        return null;
    }

    render() {
        return (
            <FortCellEditor {...this.props}>
                <DateInput
                    ref={this.dateInput}
                    value={this.props.value}
                    currentDate={new Date()}
                    initialChar={this.props.charPress}
                    formatValue={this.props.formatValue}
                />
            </FortCellEditor>
        );
    }
}
