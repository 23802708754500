import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import update from 'immutability-helper';
import GroupColumnValueSorting from './GroupColumnValueSorting';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        margin: '10px',
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({}))(MuiAccordionDetails);

const GroupColumnSorting = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    const getRowGroupSortingIndex = () =>
        props.currentOptions.rowGroupsSorting.findIndex(
            (c) => c.colId === props.colId
        );

    const getRowGroupSorting = () =>
        props.currentOptions.rowGroupsSorting[getRowGroupSortingIndex()]
            .sorting;

    const currentRowGroupSorting = getRowGroupSorting();

    const [groupColumnValues, setGroupColumnValues] = React.useState(
        currentRowGroupSorting
    );

    React.useEffect(() => {
        setGroupColumnValues(currentRowGroupSorting);
    }, [currentRowGroupSorting]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const moveColumnValue = (dragIndex, hoverIndex) => {
        const newGroupColumnValues = update(groupColumnValues, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, groupColumnValues[dragIndex]],
            ],
        });

        setGroupColumnValues(newGroupColumnValues);
    };

    const updateLayout = () => {
        const rowGroupsSorting = update(props.currentOptions.rowGroupsSorting, {
            [getRowGroupSortingIndex()]: {
                $apply: function (x) {
                    return { ...x, sorting: groupColumnValues };
                },
            },
        });

        const currentLayoutOptions = {
            ...props.currentOptions,
            rowGroupsSorting,
        };

        props.onGridOptionsChanged(currentLayoutOptions);

        // changes below required just for group values update
        const currentRowGroupsIds = props.currentOptions.rowGroups
            .filter((rg) => rg.checked)
            .map((rg) => rg.colId);
        props.columnApi.setRowGroupColumns(currentRowGroupsIds);
    };

    const renderGroupColumnValues = () => {
        return groupColumnValues.map((id, index) => (
            <GroupColumnValueSorting
                key={id}
                colId={props.colId}
                id={id}
                index={index}
                moveColumnValue={moveColumnValue}
                updateLayout={updateLayout}
            />
        ));
    };

    return (
        <Accordion
            square
            expanded={expanded === props.colId}
            onChange={handleChange(props.colId)}
        >
            <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
                <Typography>{props.header}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="ag-unselectable ag-column-drop ag-column-drop-vertical ag-last-column-drop">
                    {renderGroupColumnValues()}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default GroupColumnSorting;
