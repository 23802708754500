import {
    DATASET_SUEZMAX_LONDON,
    DATASET_VLCC_HOUSTON,
    DATASET_AFRAMAX_LONDON,
    DATASET_CLEAN_HOUSTON,
    DATASET_SUEZMAX_HOUSTON,
    DATASET_VLCC_LONDON,
    DATASET_FUEL_OIL_LONDON,
    DATASET_SPECIALISED,
    DATASET_DRY_CARGO,
    DATASET_TANKERS,
} from './Datasets';

/*
 * The order of the zones in each array is used to determine sort order
 */
const suezmaxLoadZones = [
    'WAFR',
    'SAFR',
    'CANARY',
    'BSEA',
    'EMED',
    'CMED',
    'WMED',
    'S.O.M.', // Not present in mappings files
    'BALT',
    'BARENTS', // Not present in mappings files
    'NSEA',
    'ECMEX',
    'CARIBS',
    'USG',
    'NAMERICA',
    'ECSAMERICA',
    'WCSAMERICA',
    'USWC',
    'MEG',
    'RSEA',
    'WCI',
    'ECI',
    'EAST OF SUEZ',
    'OZ',
    'EAFR',
    'PACIFIC',
];

const suezmaxDischargeZones = [
    'CANARY',
    'EAFR',
    'EAST',
    'ECI',
    'ECSAMERICA',
    'MEG',
    'OZ',
    'PACIFIC',
    'RSEA',
    'SAFR',
    'STATES',
    'UKCM',
    'USWC',
    'WAFR',
    'WCSAMERICA',
    'WCI',
];

const vlccHoustonLoadZones = [
    'ALASKA',
    'BSEA',
    'CANARY',
    'CARIBS',
    'EAFR',
    'EAST',
    'EAUS',
    'ECMEX',
    'ECSA',
    'INDIA',
    'LAKES',
    'MED',
    'MEG',
    'NAUS',
    'NZ',
    'PACIFIC',
    'RUSSFE',
    'SAFR',
    'STLAW',
    'UKCONT',
    'USAC',
    'USG',
    'USNH',
    'USWC',
    'WAFR',
    'WAUS',
    'WCCAM',
    'WCSAM',
];

const vlccHoustonDischargeZones = [
    'ALASKA',
    'BSEA',
    'CANARY',
    'CARIBS',
    'CHINA',
    'EAFR',
    'EAUS',
    'ECI',
    'ECMEX',
    'JAPAN',
    'KOREA',
    'LAKES',
    'MED',
    'MEG',
    'NAUS',
    'NZ',
    'PACIFIC',
    'RUSSFE',
    'SAFR',
    'SAMERICA',
    'SEA',
    'STLAW',
    'UKCONT',
    'USAC',
    'USG',
    'USNH',
    'USWC',
    'WAFR',
    'WAUS',
    'WCI',
];

const vlccLondonLoadZones = [
    'ALASKA',
    'BSEA',
    'CANARY',
    'CARIBS',
    'EAFR',
    'EAST',
    'EAUS',
    'ECI',
    'ECMEX',
    'LAKES',
    'MED',
    'MEG',
    'NAUS',
    'NZ',
    'PACIFIC',
    'RSEA',
    'RUSSFE',
    'SAFR',
    'SAMERICA',
    'STLAW',
    'UKCONT',
    'USAC',
    'USG',
    'USNH',
    'USWC',
    'WAFR',
    'WAUS',
    'WCCAM',
    'WCI',
];

const aframaxLondonLoadZones = [
    'MED',
    'BSEA',
    'CONT',
    'BALT',
    'SPATL',
    'WAFR',
    'SAFR',
    'CANARY',
    'ECMEX',
    'CARIBS',
    'USG',
    'USAC',
    'USNH',
    'STLAW',
    'LAKES',
    'NBRAZIL',
    'SBRAZIL',
    'ARG',
    'WCCAM',
    'WCSAM',
    'CHILE',
    'ALASKA',
    'USWC',
    'MEG',
    'RSEA',
    'WCIND',
    'ECIND',
    'SPORE',
    'INDO',
    'THAIGULF',
    'VIETNAM',
    'PHIL',
    'SCHINA',
    'CCHINA',
    'NCHINA',
    'KOREA',
    'JAPAN',
    'RUSSFE',
    'WAUS',
    'NAUS',
    'EAUS',
    'NZ',
    'EAFR',
    'PACIFIC',
];

const fuelOilLondonLoadZones = [
    'MED',
    'CONT',
    'WAFR',
    'ECMEX',
    'CARIBS',
    'USG',
    'USAC',
    'USNH',
    'STLAW',
    'LAKES',
    'NBRAZIL',
    'SBRAZIL',
    'ARG',
    'WCCAM',
    'WCSAM',
    'CHILE',
    'ALASKA',
    'USWC',
    'MEG',
    'RSEA',
    'WCIND',
    'ECIND',
    'SPORE',
    'INDO',
    'THAIGULF',
    'VIETNAM',
    'PHIL',
    'SCHINA',
    'CCHINA',
    'NCHINA',
    'KOREA',
    'JAPAN',
    'RUSSFE',
    'WAUS',
    'NAUS',
    'EAUS',
    'NZ',
    'EAFR',
    'PACIFIC',
];

const vlccLondonDischargeZones = [
    'ALASKA',
    'BSEA',
    'CANARY',
    'CARIBS',
    'CHINA',
    'EAFR',
    'EAUS',
    'ECIND',
    'ECMEX',
    'INDO',
    'JAPAN',
    'KOREA',
    'LAKES',
    'MED',
    'MEG',
    'NAUS',
    'NZ',
    'PACIFIC',
    'PHIL',
    'RSEA',
    'RUSSFE',
    'SAFR',
    'SAMERICA',
    'SPORE',
    'STLAW',
    'THAIGULF',
    'UKCONT',
    'USAC',
    'USG',
    'USNH',
    'USWC',
    'VIETNAM',
    'WAFR',
    'WAUS',
    'WCIND',
];

const cleanHoustonLoadZones = [
    'ALASKA',
    'BALT',
    'BSEA',
    'CANARY',
    'CARIBS',
    'CCHINA',
    'EAFR',
    'ECC',
    'ECIND',
    'ECMEX',
    'ECSA',
    'JAPAN',
    'KOREA',
    'LAKES',
    'MED',
    'MEG',
    'NCHINA',
    'NZ',
    'OZ',
    'PACIFIC',
    'RUSSFE',
    'SAFR',
    'SCHINA',
    'SEA',
    'UKCONT',
    'USAC',
    'USG',
    'USWC',
    'WAFR',
    'WCCAM',
    'WCIND',
    'WCSAM',
];

const cleanHoustonDischargeZones = [
    'ALASKA',
    'BALT',
    'BSEA',
    'CANARY',
    'CARIBS',
    'CCHINA',
    'EAFR',
    'ECC',
    'ECIND',
    'ECMEX',
    'ECSA',
    'JAPAN',
    'KOREA',
    'LAKES',
    'MED',
    'MEG',
    'NCHINA',
    'NZ',
    'OZ',
    'PACIFIC',
    'RUSSFE',
    'SAFR',
    'SCHINA',
    'SEA',
    'UKCONT',
    'USAC',
    'USG',
    'USWC',
    'WAFR',
    'WCCAM',
    'WCIND',
    'WCSAM',
];

const specialisedLoadZones = [
    'ADRIATIC',
    'ANTR',
    'ARA',
    'ARG',
    'ATLANTIC',
    'BALT',
    'BSEA',
    'CARIBS/ECCA',
    'CEU',
    'CMED',
    'DIS',
    'EAF',
    'ECAUS',
    'ECCAN',
    'ECI',
    'ECMEX',
    'ECUK',
    'EMED',
    'EMSIA',
    'FEASIA',
    'FRATL',
    'GBS',
    'GNS',
    'ICELAND',
    'LAKES',
    'MCHINA',
    'MEG',
    'NAUS',
    'NBRAZIL',
    'NCHINA',
    'NZ',
    'PACIFIC',
    'RED SEA',
    'RPLATE',
    'RUSSFE',
    'SAF',
    'SBRAZIL',
    'SCHINA',
    'SEASIA',
    'SPATL',
    'STRAITS',
    'USEC',
    'USG',
    'USWC',
    'WAF',
    'WAUS',
    'WCCA',
    'WCCAN',
    'WCI',
    'WCMEX',
    'WCNORWAY',
    'WCSAM',
    'WCUK',
    'WMED',
    'WMSIA',
    'WSEA',
];

const specialisedDischargeZones = [
    'ADRIATIC',
    'ANTR',
    'ARA',
    'ARG',
    'ATLANTIC',
    'BALT',
    'BSEA',
    'CARIBS/ECCA',
    'CEU',
    'CMED',
    'DIS',
    'EAF',
    'ECAUS',
    'ECCAN',
    'ECI',
    'ECMEX',
    'ECUK',
    'EMED',
    'EMSIA',
    'FEASIA',
    'FRATL',
    'GBS',
    'GNS',
    'ICELAND',
    'LAKES',
    'MCHINA',
    'MEG',
    'NAUS',
    'NBRAZIL',
    'NCHINA',
    'NZ',
    'PACIFIC',
    'RED SEA',
    'RPLATE',
    'RUSSFE',
    'SAF',
    'SBRAZIL',
    'SCHINA',
    'SEASIA',
    'SPATL',
    'STRAITS',
    'USEC',
    'USG',
    'USWC',
    'WAF',
    'WAUS',
    'WCCA',
    'WCCAN',
    'WCI',
    'WCMEX',
    'WCNORWAY',
    'WCSAM',
    'WCUK',
    'WMED',
    'WMSIA',
    'WSEA',
];

const dryCargoZones = [
    'BALT',
    'BENGAL',
    'BSEA',
    'CANARY',
    'CARIBS',
    'CCHINA',
    'EAFR',
    'EAUS',
    'EMED',
    'LAKES',
    'MEG',
    'NASIA',
    'NBRAZ',
    'NCHINA',
    'NCSA',
    'PLATE',
    'RSEA',
    'SAFR',
    'SBRAZ',
    'SCHINA',
    'SEASIA',
    'UKCONT',
    'USEC',
    'USG',
    'WAFR',
    'WAUS',
    'WCCA',
    'WCIND',
    'WCNA',
    'WCSAM',
    'WMED',
];

const tankerZones = [
    'ALASKA',
    'ARG',
    'BALT',
    'BSEA',
    'CANARY',
    'CARIBS',
    'CCHINA',
    'CHILE',
    'CMED',
    'EAFR',
    'EAUS',
    'ECIND',
    'ECMEX',
    'EMED',
    'FRATL',
    'INDO',
    'JAPAN',
    'KOREA',
    'LAKES',
    'MEG',
    'NAUS',
    'NBRAZIL',
    'NCHINA',
    'NZ',
    'PACIFIC',
    'PHIL',
    'RSEA',
    'RUSSFE',
    'SAFR',
    'SBRAZIL',
    'SCHINA',
    'SPATL',
    'SPORE',
    'STLAW',
    'THAIGULF',
    'UKATL',
    'UKCONT',
    'USEC',
    'USG',
    'USWC',
    'VIETNAM',
    'WAUS',
    'WCCAM',
    'WCIND',
    'WCSAM',
    'WMED',
    'WAFR',
];

const globalZones = [
    'ALASKA',
    'ARG',
    'BALT',
    'BSEA',
    'CANARY',
    'CARIBS',
    'CCHINA',
    'CHILE',
    'CMED',
    'EAFR',
    'EAUS',
    'ECIND',
    'ECMEX',
    'EMED',
    'FRATL',
    'INDO',
    'JAPAN',
    'KOREA',
    'LAKES',
    'MEG',
    'NAUS',
    'NBRAZIL',
    'NCHINA',
    'NZ',
    'PACIFIC',
    'PHIL',
    'RSEA',
    'RUSSFE',
    'SAFR',
    'SBRAZIL',
    'SCHINA',
    'SPATL',
    'SPORE',
    'STLAW',
    'THAIGULF',
    'UKATL',
    'UKCONT',
    'USAC',
    'USG',
    'USNH',
    'USWC',
    'VIETNAM',
    'WAUS',
    'WCCAM',
    'WCIND',
    'WCSAM',
    'WMED',
    'WW',
    'WAFR',
];

export const loadZones = (datasetId) => {
    switch (datasetId) {
        case DATASET_SUEZMAX_LONDON:
        case DATASET_SUEZMAX_HOUSTON:
            return suezmaxLoadZones;
        case DATASET_VLCC_LONDON:
            return vlccLondonLoadZones;
        case DATASET_VLCC_HOUSTON:
            return vlccHoustonLoadZones;
        case DATASET_AFRAMAX_LONDON:
            return aframaxLondonLoadZones;
        case DATASET_FUEL_OIL_LONDON:
            return fuelOilLondonLoadZones;
        case DATASET_CLEAN_HOUSTON:
            return cleanHoustonLoadZones;
        case DATASET_SPECIALISED:
            return specialisedLoadZones;
        case DATASET_DRY_CARGO:
            return dryCargoZones;
        case DATASET_TANKERS:
            return tankerZones;
        default:
            return globalZones;
    }
};

export const dischargeZones = (datasetId) => {
    switch (datasetId) {
        case DATASET_SUEZMAX_LONDON:
        case DATASET_SUEZMAX_HOUSTON:
            return suezmaxDischargeZones;
        case DATASET_VLCC_LONDON:
            return vlccLondonDischargeZones;
        case DATASET_VLCC_HOUSTON:
            return vlccHoustonDischargeZones;
        case DATASET_CLEAN_HOUSTON:
            return cleanHoustonDischargeZones;
        case DATASET_SPECIALISED:
            return specialisedDischargeZones;
        case DATASET_DRY_CARGO:
            return dryCargoZones;
        case DATASET_TANKERS:
            return tankerZones;
        default:
            return globalZones;
    }
};

export const getAvailableZone = (parts) => {
    if (parts && parts.length > 0 && parts[0].deskZone) {
        return parts[0].deskZone;
    } else {
        return null;
    }
};

export const getAvailableArea = (parts) => {
    if (parts && parts.length > 0 && parts[0].deskArea) {
        return parts[0].deskArea;
    } else {
        return null;
    }
};

export const getAvailableAreaGeared = (parts) => {
    if (parts && parts.length > 0 && parts[0].deskAreaGeared) {
        return parts[0].deskAreaGeared;
    } else {
        return null;
    }
};

export const hasFirstZoneChanged = (currentZone, newLocationParts) => {
    if (currentZone && !newLocationParts) {
        return true;
    }

    const newZone = getAvailableZone(newLocationParts);
    const zonesAreDifferent = currentZone !== newZone;
    return zonesAreDifferent;
};
