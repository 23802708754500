import React, { Component } from 'react';
import CompanyInput from '../inputs/CompanyInput';
import { KEY_TAB, KEY_ENTER } from '../../../constants/keyboardCodes';

class CompanyEditor extends Component {
    constructor(props) {
        super(props);

        this.companyInputRef = React.createRef();
        this.focus = this.focus.bind(this);
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.onCompanySelected = this.onCompanySelected.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleInputCleared = this.handleInputCleared.bind(this);

        if (this.props.value) {
            this.state = {
                selectedCompany: { ...this.props.value },
            };
        } else {
            this.state = {};
        }
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.companyInputRef.current) {
            this.companyInputRef.current.focus();
        }
    }

    handleOnTab() {
        this.props.api.tabToNextCell();
    }

    handleOnTabBack() {
        this.props.api.tabToPreviousCell();
    }

    handleOnEnter() {
        this.props.stopEditing();
    }

    getValue() {
        return this.state.selectedCompany;
    }

    isPopup() {
        return true;
    }

    onCompanySelected(company, keyCode, shift) {
        this.setState(
            {
                selectedCompany: company,
            },
            () => {
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;
                    case KEY_ENTER:
                    default:
                        //assume treated as enter, such as mouse click selection
                        this.handleOnEnter();
                        break;
                }
            }
        );
    }

    handleInputCleared() {
        this.setState({ selectedCompany: null });
    }

    render(searchApi) {
        return (
            <CompanyInput
                ref={this.companyInputRef}
                value={this.state.selectedCompany}
                onTab={this.handleOnTab}
                onTabBack={this.handleOnTabBack}
                onEnter={this.handleOnEnter}
                onCompanySelected={this.onCompanySelected}
                shouldSelectItemOnTab={true}
                inputClass="ag-react-container"
                initialChar={this.props.charPress}
                searchApi={searchApi}
                onInputCleared={this.handleInputCleared}
            />
        );
    }
}

export default CompanyEditor;
