import CountryImage from '../../common/CountryImage';
import BoldTextRenderer from './BoldTextRenderer';
import './LocationSearchResultRenderer.scss';

const LocationSearchResultRenderer = (props) => {
    const sanitizeName = (name) => {
        if (name) {
            return name.replace(/\?/, '');
        } else {
            return null;
        }
    };

    const { item, shouldShowCountries, shouldBeBoldText } = props;
    const sanitizedName = sanitizeName(item.name);
    const country = item.country ? item.country.toUpperCase() : '\u2013'; //en dash
    const deskZone = item.deskZone ? item.deskZone.toUpperCase() : '\u2013'; //en dash
    const typeDisplayValue =
        item.typeDisplayValue && item.typeDisplayValue.toUpperCase();
    const aliasesString = item.aliases?.join(', ');

    return (
        <table id="location-search-results-table">
            <tbody>
                <tr>
                    {shouldShowCountries && (
                        <td style={{ width: '10px', minWidth: '0' }}>
                            <CountryImage countryCode={item.countryCode} />
                        </td>
                    )}
                    <td
                        style={{
                            width: shouldShowCountries ? '145px' : '220px',
                        }}
                        title={sanitizedName}
                    >
                        {sanitizedName}
                    </td>
                    {shouldShowCountries && <td title={country}>{country}</td>}
                    <td title={deskZone}>{deskZone}</td>
                    <td title={typeDisplayValue}>{typeDisplayValue}</td>
                </tr>

                {/* matching aliases */}
                {item.aliases?.length > 0 && (
                    <tr>
                        {shouldShowCountries && (
                            <td className="location-aliases-stub"></td>
                        )}

                        <td colSpan={5} className="location-aliases">
                            <BoldTextRenderer
                                text={aliasesString}
                                shouldBeBoldText={shouldBeBoldText}
                            />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default LocationSearchResultRenderer;
