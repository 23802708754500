import React, { Component } from 'react';
import SyncIcon from '../icons/SyncIcon';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

export default class SyncButton extends Component {
    render = () => {
        return (
            <div
                className="syncButton toolbar-button"
                onClick={this.props.onClick}
                data-cy={this.props.id}
            >
                <Tooltip placement="top" overlay="Re-sync">
                    <SyncIcon />
                </Tooltip>
            </div>
        );
    };
}
