import { compare } from 'natural-orderby';
import { isDefaultOrBlankValue } from '../../../constants/gridText';

let compareFn = compare();

const naturalSortComparator = (a, b) => {
    if (isDefaultOrBlankValue(a) && isDefaultOrBlankValue(b)) return 0;

    if (isDefaultOrBlankValue(a)) return -1;

    if (isDefaultOrBlankValue(b)) return 1;

    return compareFn(a, b);
};

export default naturalSortComparator;
