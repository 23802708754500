import logger from '../diagnostics/logging/logger';

const crashReporter = (store) => (next) => (action) => {
    try {
        next(action);
    } catch (error) {
        console.error('Caught an exception!', error);
        logger.exception(error);
        throw error;
        //TODO: add in error logging to backend store, perhaps something similar to ravenjs and sentry
        //should allow us to log the action being dispatched, current state, etc.
        //https://docs.sentry.io/clients/javascript/integrations/react/
        //https://sentry.io/welcome/
        //note: this is not a global handler for any js errors, just errors when dispatching actions
        //ravenjs (or similar) would also report these
    }
};

export default crashReporter;
