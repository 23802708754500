import logger from '../diagnostics/logging/logger';
import {
    ApolloClient,
    HttpLink,
    ApolloLink,
    Observable,
    split,
    from,
} from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { apiBaseUrl } from '../config/index';
import generateId from '../tools/idGenerator';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import * as types from '../actions/actionTypes';
import store from 'store';
import ReadErrorUnknownCauseMessage from '../components/ui/messages/wordage/errors/ReadErrorUnknownCauseMessage';
import WriteRejectedErrorSafeToRetryMessage from '../components/ui/messages/wordage/errors/WriteRejectedErrorSafeToRetryMessage';
import WriteUnknownResultErrorSafeToRetryMessage from '../components/ui/messages/wordage/errors/WriteUnknownResultErrorSafeToRetryMessage';
import WriteUnknownResultErrorCheckBeforeRetryMessage from '../components/ui/messages/wordage/errors/WriteUnknownResultErrorCheckBeforeRetryMessage';
import { sessionId } from './session';
import { authenticationService } from '_legacy/auth/AuthenticationService';

let _graphQlClient = undefined;

//code below is largely taken from https://www.apollographql.com/docs/react/advanced/boost-migration.html
const addSessionContextToRequest = (operation) => {
    const token = authenticationService.getAccessToken();
    if (token) {
        operation.setContext({
            headers: {
                authorization: encodeURIComponent(token),
                fortSessionId: sessionId,
            },
        });
    }
};

const sessionContextAddingMiddleware = new ApolloLink(
    (operation, forward) =>
        new Observable((observer) => {
            let handle;
            Promise.resolve(operation)
                .then((oper) => addSessionContextToRequest(oper))
                .then(() => {
                    handle = forward(operation).subscribe({
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    });
                })
                .catch(observer.error.bind(observer));

            return () => {
                if (handle) handle.unsubscribe();
            };
        })
);

const createHttpWithWebSocketUpdatesLink = ({
    httpResource,
    webSocketClient,
}) => {
    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    return split(
        // split based on operation type
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return (
                kind === 'OperationDefinition' && operation === 'subscription'
            );
        },
        new WebSocketLink(webSocketClient),
        new HttpLink(httpResource)
    );
};

function unwrapIfKeepAlive(message) {
    try {
        var obj = JSON.parse(message.data);

        var { data } = obj.payload;

        if (data && data.keepAlive) {
            //client is responding to a keepAlive, re-format it so that it correctly appears to the websocket client
            return { data: JSON.stringify(data.keepAlive) };
        }
    } catch (e) {
        return null;
    }

    return null;
}

function isAuthenticationRequired(message) {
    if (message.data) {
        var obj = JSON.parse(message.data);

        if (obj.payload) {
            return obj.payload.authenticationFailed;
        }
    } else {
        return false;
    }
}

function hasTokenExpired() {
    const user = authenticationService.getUser();

    return !user || user.expired;
}

/* This method is called by both onConnected and onReconnected. This is to ensure the keep-alive message form the server is always
 * processed (onmessage is set correctly). We had a scenario where the reconnections didn't handle the keep-alive,
 * which resulted in the client disconnecting and reconnecting every 30 seconds. This caused the user to see
 * the disconnected message, followed quickly by the reconnected message (see the comment within this method) (defect 8456)
 */
function handleConnectionChange(subscriptionClient) {
    var originalOnMessage = subscriptionClient.client.onmessage;

    /* #graphql-dotnet-unimplemented-keepalives
        Override the onmessage function of the web socket client to unwrap keep-alive subscription messages before processing them, as if the server had sent an actual keep-alive. 
        SubscriptionClient then proceeds to handle keep-alives as expected - https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L635 
        - and will close the connection if no keep-alive received within the timeout period (default 30 seconds), thereby triggering our component to display its connection warning.
      */
    subscriptionClient.client.onmessage = (message) => {
        if (isAuthenticationRequired(message)) {
            window.location.reload(true);
            return;
        }

        originalOnMessage.call(this, message); // represents the original call to onmessage, which will be picked up by the subscriptions

        var unwrappedMessage = unwrapIfKeepAlive(message);
        if (unwrappedMessage !== null) {
            if (hasTokenExpired()) {
                window.location.reload(true);
                return;
            }

            originalOnMessage.call(this, unwrappedMessage); // represents an additional call with the unwrapped keep-alive message that will be picked up by the websocket client
        }
    };
}

const graphQlClient = () => {
    if (!_graphQlClient) {
        const subscriptionClient = new SubscriptionClient(
            `wss://${apiBaseUrl}/graphql`,
            {
                reconnect: true,
                lazy: true,
                connectionParams: () => {
                    return {
                        authorization: encodeURIComponent(
                            authenticationService.getAccessToken()
                        ),
                        fortSessionId: sessionId,
                    };
                },
            }
        );
        subscriptionClient.maxConnectTimeGenerator.duration = () =>
            subscriptionClient.maxConnectTimeGenerator.max; // Prevents dropping first connection attempt if unsuccessful in 1s (which for us means we tell user that he missed updates and needs to refresh). TODO remove when they fix this issue: https://github.com/apollographql/subscriptions-transport-ws/issues/377#issuecomment-447838378

        /* TODO - remove when fixed https://github.com/apollographql/subscriptions-transport-ws/issues/505 */
        const originalConnect = subscriptionClient.connect;
        subscriptionClient.connect = function () {
            originalConnect.apply(this, arguments);
            const originalOnclose = subscriptionClient.client.onclose;
            subscriptionClient.client.onclose = function (event) {
                const isError =
                    !(event instanceof CloseEvent) &&
                    event.type &&
                    event.type === 'error';

                if (isError) {
                    logger.warn(event.message);
                } else
                    logger.log(
                        'WebSocket connection was closed, ' +
                            (event.reason.length === 0
                                ? 'no reason was provided'
                                : 'the reason provided was: ' + event.reason)
                    );
                return originalOnclose.apply(this, arguments);
            };
        };

        subscriptionClient.onConnected((e) => {
            handleConnectionChange(subscriptionClient);
            store.dispatch({ type: types.SOCKET_CONNECTED });
        });

        subscriptionClient.onDisconnected((e) => {
            store.dispatch({ type: types.SOCKET_DISCONNECTED });
        });

        subscriptionClient.onReconnected((e) => {
            handleConnectionChange(subscriptionClient);
            store.dispatch({ type: types.SOCKET_RECONNECTED });
        });

        subscriptionClient.onError((e) => {
            logger.warn(e.message);
        });

        const mainLink = ApolloLink.from([
            onError((error) => {
                if (error.graphQLErrors) {
                    error.graphQLErrors.forEach((e) =>
                        logger.error(
                            /* error: */ e,
                            /* props: */ {
                                operationName: error.operation.operationName,
                            }
                        )
                    );
                }

                if (error.networkError) {
                    logger.error(
                        /* error: */ error.networkError,
                        /* props: */ {
                            operationName: error.operation.operationName,
                            // See https://www.apollographql.com/docs/link/links/http.html#error
                            url: error.networkError?.response?.url,
                            statusCode: error.networkError.statusCode,
                            bodyText: error.networkError.bodyText,
                        }
                    );
                }

                store.dispatch({
                    type: types.NOTIFICATION_MESSAGE_ADDED,
                    message: _getUserNotificationMessageForError(error),
                });
            }),
            sessionContextAddingMiddleware,
            createHttpWithWebSocketUpdatesLink({
                httpResource: {
                    uri: `https://${apiBaseUrl}/graphql`,
                    credentials: 'same-origin',
                },
                webSocketClient: subscriptionClient,
            }),
        ]);

        function _getUserNotificationMessageForError({
            graphQLErrors,
            networkError,
            operation,
        }) {
            const operationTypes =
                operation &&
                operation.query &&
                operation.query.definitions.length &&
                operation.query.definitions
                    .filter(
                        (queryDef) => queryDef.kind === 'OperationDefinition'
                    )
                    .map((queryDef) => queryDef.operation);
            const isRead =
                operationTypes &&
                operationTypes.every(
                    (oT) => oT === 'query' || oT === 'subscription'
                );

            const context = operation.getContext();
            const errorHandlingArgs = context && context.errorHandling;

            if (isRead)
                return {
                    typeId: 'READ_ERROR_FOR_UNKNOWN_REASON',
                    getMessageRichContent: (props) => (
                        <ReadErrorUnknownCauseMessage
                            {...props}
                            resultDescription={
                                errorHandlingArgs &&
                                errorHandlingArgs.rejectedResult &&
                                errorHandlingArgs.rejectedResult
                                    .resultDescription &&
                                errorHandlingArgs.rejectedResult.resultDescription()
                            }
                            resolutionToTryBeforeContacting={
                                errorHandlingArgs &&
                                errorHandlingArgs.rejectedResult &&
                                errorHandlingArgs.rejectedResult.resolution &&
                                errorHandlingArgs.rejectedResult.resolution
                                    .tryBeforeContacting &&
                                errorHandlingArgs.rejectedResult.resolution.tryBeforeContacting()
                            }
                        />
                    ),
                };
            else {
                const responseStatus =
                    networkError &&
                    networkError.response &&
                    networkError.response.statusCode;

                const isHttpInvalidRequest =
                    responseStatus >= 400 && responseStatus <= 499;
                const isGraphQlInvalidRequest =
                    graphQLErrors &&
                    graphQLErrors.length &&
                    graphQLErrors.every(
                        (error) =>
                            error &&
                            error.extensions &&
                            error.extensions.code === 'INVALID_REQUEST'
                    );

                if (isHttpInvalidRequest || isGraphQlInvalidRequest)
                    return {
                        typeId: 'WRITE_REJECTED_FOR_UNKNOWN_REASON_RETRY_SAFE',
                        getMessageRichContent: (props) => (
                            <WriteRejectedErrorSafeToRetryMessage
                                {...props}
                                resultDescription={
                                    errorHandlingArgs.rejectedResult &&
                                    errorHandlingArgs.rejectedResult
                                        .resultDescription &&
                                    errorHandlingArgs.rejectedResult.resultDescription()
                                }
                                resolutionToTryBeforeContacting={
                                    errorHandlingArgs &&
                                    errorHandlingArgs.rejectedResult &&
                                    errorHandlingArgs.rejectedResult
                                        .resolution &&
                                    errorHandlingArgs.rejectedResult.resolution
                                        .tryBeforeContacting &&
                                    errorHandlingArgs.rejectedResult.resolution.tryBeforeContacting()
                                }
                            />
                        ),
                    };
                else {
                    const unknownResultErrorHandlingArgs =
                        errorHandlingArgs && errorHandlingArgs.unknownResult;
                    const isRetrySafe = !!(
                        unknownResultErrorHandlingArgs &&
                        unknownResultErrorHandlingArgs.resolution &&
                        unknownResultErrorHandlingArgs.resolution.isSafeToRetry
                    );
                    if (isRetrySafe)
                        return {
                            typeId: 'WRITE_UNKNOWN_RESULT_RETRY_SAFE',
                            getMessageRichContent: (props) => (
                                <WriteUnknownResultErrorSafeToRetryMessage
                                    {...props}
                                    resultDescription={
                                        unknownResultErrorHandlingArgs &&
                                        unknownResultErrorHandlingArgs.resultDescription &&
                                        unknownResultErrorHandlingArgs.resultDescription()
                                    }
                                    resolutionToTryBeforeContacting={
                                        unknownResultErrorHandlingArgs &&
                                        unknownResultErrorHandlingArgs.resolution &&
                                        unknownResultErrorHandlingArgs
                                            .resolution.tryBeforeContacting &&
                                        unknownResultErrorHandlingArgs.resolution.tryBeforeContacting()
                                    }
                                />
                            ),
                        };
                    else
                        return {
                            typeId: 'WRITE_UNKNOWN_RESULT_RETRY_UNSAFE',
                            getMessageRichContent: (props) => (
                                <WriteUnknownResultErrorCheckBeforeRetryMessage
                                    {...props}
                                    resultDescription={
                                        unknownResultErrorHandlingArgs &&
                                        unknownResultErrorHandlingArgs.resultDescription &&
                                        unknownResultErrorHandlingArgs.resultDescription()
                                    }
                                    resolutionToTryBeforeContacting={
                                        unknownResultErrorHandlingArgs &&
                                        unknownResultErrorHandlingArgs.resolution &&
                                        unknownResultErrorHandlingArgs
                                            .resolution.tryBeforeContacting &&
                                        unknownResultErrorHandlingArgs.resolution.tryBeforeContacting()
                                    }
                                />
                            ),
                        };
                }
            }
        }

        const dependencyStartTracker = setContext(() => ({
            requestStartTime: new Date().getTime(),
        }));

        const dependencySuccessTracker = new ApolloLink(
            (operation, forward) => {
                return forward(operation).map((response) => {
                    const currentTime = new Date().getTime();
                    const startTime = operation.getContext().requestStartTime;
                    const requestElapsedTime = currentTime - startTime;

                    // Apollo deals with the operation at a high level, so I've just put some place holders
                    // in for type and url and host.
                    logger.trackDependency(
                        generateId(),
                        'POST',
                        'graph',
                        '/graph',
                        requestElapsedTime,
                        true,
                        200
                    );

                    return response;
                });
            }
        );

        const dependencyErrorTracker = onError(({ operation }) => {
            const currentTime = new Date().getTime();
            const startTime = operation.getContext().requestStartTime;
            const requestElapsedTime = currentTime - startTime;
            // Apollo deals with the operation at a high level, so I've just put some place holders
            // in for type and url and host.
            logger.trackDependency(
                generateId(),
                'POST',
                'graph',
                '/graph',
                requestElapsedTime,
                false,
                0
            );
        });

        _graphQlClient = new ApolloClient({
            link: from([
                dependencySuccessTracker,
                dependencyErrorTracker,
                dependencyStartTracker,
                mainLink,
            ]),
            cache: new InMemoryCache(),
        });
    }
    return _graphQlClient;
};

export default graphQlClient;
