import React, { Component } from 'react';
import MultipleBrokerSelect from '../inputs/MultipleBrokerSelect';

class BrokerEditor extends Component {
    constructor(props) {
        super(props);

        this.selectRef = React.createRef();
        this.focus = this.focus.bind(this);
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.getValue = this.getValue.bind(this);

        if (this.props.value) {
            this.state = {
                selectedCompany: { ...this.props.value },
            };
        } else {
            this.state = {};
        }
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.selectRef.current) {
            this.selectRef.current.focus();
        }
    }

    handleOnTab() {
        this.props.api.tabToNextCell();
    }

    handleOnTabBack() {
        this.props.api.tabToPreviousCell();
    }

    handleOnEnter() {
        this.props.stopEditing();
    }

    getValue() {
        if (this.selectRef.current.formattedValue.length > 0) {
            return this.selectRef.current.formattedValue;
        } else {
            return null;
        }
    }

    isPopup() {
        return true;
    }

    render() {
        return (
            <MultipleBrokerSelect
                ref={this.selectRef}
                initialChar={this.props.charPress}
                value={this.props.value}
                onTab={this.handleOnTab}
                onTabBack={this.handleOnTabBack}
                onEnter={this.handleOnEnter}
            />
        );
    }
}

export default BrokerEditor;
