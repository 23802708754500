import { isEditableRow } from '../../../shared/columns/tools/headingTools';
import ThirdPartyChartererEditor from '../editors/ThirdPartyChartererEditor';
import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';
import NamePropertyComparator from '_legacy/components/grid/comparators/NamePropertyComparator';
import defaultObjEquals from '_legacy/components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import CompanyFormatter from '../../../shared/columns/formatters/CompanyFormatter';
import CompanyKeyCreator from '../../../shared/columns/keyCreators/CompanyKeyCreator';
import suppressOnEdit from '_legacy/components/grid/columnDefaults/suppressOnEdit';
import { unassignedChartererText } from '_legacy/constants/gridText';

export const thirdPartyChartererHeading = {
    colId: 'thirdPartyCharterer',
    headerName: 'Charterer',
    field: 'thirdPartyCharterer',
    editable: isEditableRow,
    valueFormatter: CompanyFormatter,
    cellEditorFramework: ThirdPartyChartererEditor,
    suppressKeyboardEvent: suppressOnEdit(),
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    keyCreator: (params) =>
        CompanyKeyCreator({ ...params, defaultValue: unassignedChartererText }),
    enableRowGroup: true,
    filter: 'companiesFilter',
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    width: 120,
};
