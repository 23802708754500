import { GridApi, MenuItemDef } from '@ag-grid-enterprise/all-modules';

export const createCheckSelectedRowsMenuItem = (
    gridApi: GridApi
): MenuItemDef => {
    const title = 'Check selected rows';

    return {
        name: title,
        action: () => {
            handleCheckSelectedRows(gridApi);
        },
    };
};

const handleCheckSelectedRows = (gridApi: GridApi): void => {
    const selectedCellRanges = gridApi.getCellRanges();
    const rowIndexesToBeChecked: string[] = [];

    selectedCellRanges?.forEach((range) => {
        if (range.startRow === undefined || range.endRow === undefined) {
            return;
        }
        // next comparison is needed to capture case when the user is selecting cells from bottom to top of the grid
        const startRowIndex =
            range.startRow.rowIndex <= range.endRow.rowIndex
                ? range.startRow.rowIndex
                : range.endRow.rowIndex;
        const endRowIndex =
            range.endRow.rowIndex >= range.startRow.rowIndex
                ? range.endRow.rowIndex
                : range.startRow.rowIndex;

        for (let i = startRowIndex; i <= endRowIndex; i++) {
            rowIndexesToBeChecked.push(i.toString());
        }
    });

    gridApi.forEachNodeAfterFilter((node) => {
        const index = node.getRowIndexString();
        if (rowIndexesToBeChecked.includes(index) && !node.group) {
            node.setSelected(true);
        }
    });
};
