import PersonIcon from '@mui/icons-material/Person';
import TableChartIcon from '@mui/icons-material/TableChart';

const User = PersonIcon;
const Template = TableChartIcon;

const LayoutIcons = {
    User,
    Template,
};

export default LayoutIcons;
