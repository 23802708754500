// AG Grid node don't store formatted value for the column.

import { formatSalePrice } from './SalePriceFormatterService';

// So we are trying to find our existing formatter to reuse logic here.
export const getFormattedValue = (colDef, node, context) => {
    const colId = colDef.colId;
    const field = colDef.field;

    const nodeColumnDef = node.columnController.columnDefs.find(
        (col) => col.colId === colId
    );

    const fieldValue = nodeColumnDef.valueGetter
        ? nodeColumnDef.valueGetter({ data: node.data, context })
        : getFieldValue(node.data, field);

    if (nodeColumnDef.valueFormatter) {
        return nodeColumnDef.valueFormatter({
            value: fieldValue,
            data: node.data,
            node: node,
            colDef: nodeColumnDef,
            context: context,
        });
    } else if (colId === 'salePrice') {
        return formatSalePrice(fieldValue);
    } else {
        return fieldValue;
    }
};

const getFieldValue = (obj, field) =>
    field
        .split('.')
        .reduce(
            (accumulator, currentValue) =>
                accumulator && accumulator[currentValue],
            obj
        );
