const LaycanValueGetter = (params) => {
    if (!params.data) {
        return null;
    }
    const { laycan } = params.data;
    if (!laycan) {
        return null;
    }
    return laycan;
};

export default LaycanValueGetter;
