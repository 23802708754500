const configureColumns = (columnApi, userColumnOptions) => {
    if (columnApi) {
        const columns = columnApi.getAllColumns();
        //show columns which cannot be hidden, in case they were previously and had been stored in column state
        const columnsCannotBeHidden = columns.filter(
            (c) => c.colDef.suppressColumnsToolPanel && c.colDef.suppressMovable
        );

        columnApi.setColumnsVisible(
            columnsCannotBeHidden.map((c) => c.colId),
            true
        );

        const existingColIds = (userColumnOptions || []).map((c) => c.colId);

        //find columns that have a width set but aren't contained in initial columns
        //as we don't want to override widths the user has already defined
        const columnsWithWidths = columns.filter(
            (c) => c.colDef.width && existingColIds.indexOf(c.colId) === -1
        );

        //set widths to those originally specified
        for (let index = 0; index < columnsWithWidths.length; index++) {
            const col = columnsWithWidths[index];
            //override anything that has been restored
            columnApi.setColumnWidth(col.colId, col.colDef.width);
        }
    }
};

export default configureColumns;
