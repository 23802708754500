import React from 'react';

const RadioGroupWithImages = ({ isChecked, onDensityViewChanged }) => {
    return (
        <>
            <div className="density-container">
                <div id="header">Density</div>
                <div className="radio-row">
                    <input
                        type="radio"
                        id="compact"
                        name="radioGroup"
                        value="compact"
                        checked={isChecked}
                        onChange={onDensityViewChanged}
                    />

                    <label htmlFor="compact">
                        Compact
                        <div className="svg-container">
                            <div className="svg-content-compact" />
                        </div>
                    </label>
                </div>

                <div className="radio-row">
                    <input
                        type="radio"
                        id="standard"
                        name="radioGroup"
                        value="standard"
                        checked={!isChecked}
                        onChange={onDensityViewChanged}
                    />
                    <label htmlFor="standard">
                        Standard
                        <div className="svg-container">
                            <div className="svg-content-standard" />
                        </div>
                    </label>
                </div>
            </div>
        </>
    );
};

export default RadioGroupWithImages;
