import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import FixturesPage from '../fixtures/FixturesPage';
import OrdersPage from '../orders/OrdersPage';
import '../ui/Menu.scss';
import '../ui/Toolbar.scss';
import logger from '../../diagnostics/logging/logger';
import DataSizeOptions from '../../data/dataSizeOptions';
import ExportOptions from '../ui/ExportOptions';
import { getReport } from '../../reports/SuezmaxDailyReport';
import { generateEmail } from '../common/EmailService';
import { downloadFile } from '../common/Downloader';
import CompanyFormatter from '_legacy/modules/shared/columns/formatters/CompanyFormatter';
import { MultipleCargoQuantityFormatter } from '_legacy/modules/columns/quantity';
import MultipleCargoFormatter from '../grid/formatters/MultipleCargoFormatter';
import LocationsFormatter from '../grid/formatters/LocationsFormatter';
import { VesselFormatter } from '_legacy/modules/columns/vessel';
import { MultipleRateFormatter } from '_legacy/modules/columns/rate';
import { modalService } from 'common';
import { AuditHistoryService } from '_legacy/services/AuditHistoryService';
import {
    ORDER_GRID_TYPE,
    FIXTURE_GRID_TYPE,
} from '_legacy/constants/gridTypes';
import MixpanelLogger from '_legacy/diagnostics/logging/mixpanelLogger';
import {
    OrdersPaneId,
    FixturesPaneId,
} from '_legacy/constants/CommonConstants';
import userSettingsApi from '_legacy/api/userSettingsApi';

const ordersPaneHeightStorageKey = 'multiPageOrdersPaneHeight';
const ordersPaneHeightElementId = OrdersPaneId;

class MultiPage extends Component {
    constructor(props) {
        super(props);

        this.cargoMenuRef = React.createRef();
        this.fixtureMenuRef = React.createRef();

        this.ordersPageRef = React.createRef();
        this.fixturesPageRef = React.createRef();

        this.handleResizeBarMouseDown =
            this.handleResizeBarMouseDown.bind(this);
        this.handleResizeBarMouseUp = this.handleResizeBarMouseUp.bind(this);
        this.handleResizeBarMouseMove =
            this.handleResizeBarMouseMove.bind(this);

        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
        this.openExportOptions = this.openExportOptions.bind(this);
        this.handleExport = this.handleExport.bind(this);

        this.handleOnToggleCondensedView =
            this.handleOnToggleCondensedView.bind(this);

        const ordersPaneHeight = localStorage.getItem(
            ordersPaneHeightStorageKey
        );

        this.state = {
            flexGrow: ordersPaneHeight ? 0 : 1,
            height: ordersPaneHeight,
            isResizing: false,
            resizingOriginClientY: 0,
            selectedOption: this.props.selectedOption || DataSizeOptions[0],
            export: {
                orders: [],
                fixtures: [],
            },
            context: 'multi',
            useCondensedView: this.props.useCondensedView,
        };
    }

    handleResizeBarMouseDown(e) {
        document.onmousemove = this.handleResizeBarMouseMove;
        document.onmouseup = this.handleResizeBarMouseUp;
        this.setState({ isResizing: true });
    }

    handleResizeBarMouseUp(e) {
        document.onmousemove = null;
        document.onmouseup = null;
        this.setState({ isResizing: false });
    }

    handleResizeBarMouseMove(e) {
        if (this.state.isResizing) {
            if (e.stopPropagation) e.stopPropagation();
            if (e.preventDefault) e.preventDefault();
            e.cancelBubble = true;

            const offset = document.getElementById(
                ordersPaneHeightElementId
            ).offsetTop;
            const newPaneHeight = e.clientY + offset - 100;

            if (newPaneHeight < 100) {
                return;
            }

            localStorage.setItem(ordersPaneHeightStorageKey, newPaneHeight);
            this.setState({ flexGrow: 0, height: newPaneHeight });
        }
    }

    handleGroupChange = () => {
        this.setState({
            export: {
                orders: [],
                fixtures: [],
            },
        });
    };

    handleOnToggleCondensedView = async () => {
        try {
            this.props.actions.toggleCondensedView();
            await userSettingsApi.toggleCondensedView();
        } catch (error) {
            console.error('Error setting condensed View:', error);
        }
    };

    handleSelectionChanged(_export) {
        this.setState({ export: _export });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.useCondensedView !== this.props.useCondensedView) {
            this.setState({
                useCondensedView: this.props.useCondensedView,
            });
        }
    }
    openExportOptions() {
        const content = (
            <ExportOptions
                onClose={modalService.close}
                onExport={this.handleExport}
                includesNonConfidential={
                    this.state.export.includesNonConfidentialOrders ||
                    this.state.export.includesNonConfidentialFixtures
                }
                includesConfidential={
                    this.state.export.includesConfidentialOrders ||
                    this.state.export.includesConfidentialFixtures
                }
                includesGroupHeadings={
                    this.includesGroupHeadings(this.state.export.orders) ||
                    this.includesGroupHeadings(this.state.export.fixtures)
                }
            />
        );

        modalService.open({ content });
    }

    includesGroupHeadings(nodes) {
        return nodes.length > 0 && nodes[0].level > 0;
    }

    handleExport(params) {
        var shouldIncludeRow = (data) => {
            if (params.includesConfidential && data.privacy) {
                return params.exportConfidential;
            } else {
                return true;
            }
        };

        const orders = this.state.export.orders
            .filter((row) => shouldIncludeRow(row.data))
            .map((row) => row.data);
        const fixtures = this.state.export.fixtures
            .filter((row) => shouldIncludeRow(row.data))
            .map((row) => row.data);

        const exportType =
            orders.length !== 0 && fixtures.length !== 0
                ? 'Both'
                : orders.length !== 0
                ? 'Orders'
                : 'Fixtures';
        const quantityItemsToExport = orders.length + fixtures.length;

        if (params.format === 'excel') {
            this.ordersPageRef.current
                .getWrappedInstance()
                .exportToExcel(
                    shouldIncludeRow,
                    params.shouldExportGroupHeadings
                );
            this.fixturesPageRef.current
                .getWrappedInstance()
                .exportToExcel(
                    shouldIncludeRow,
                    params.shouldExportGroupHeadings
                );
            MixpanelLogger.trackExportToExcelEvent(
                exportType,
                quantityItemsToExport,
                params.exportConfidential,
                params.shouldExportGroupHeadings
            );
        } else if (params.format === 'email') {
            const data = { orders, fixtures, title: params.reportTitle };

            const formatters = {
                charterer: CompanyFormatter,
                broker: CompanyFormatter,
                cargoQuantity: (value) => {
                    return MultipleCargoQuantityFormatter(
                        {
                            ...value,
                            context: {
                                quantityFormat: this.props.quantityFormat,
                            },
                        },
                        true
                    );
                },
                cargoParts: MultipleCargoFormatter,
                loadLocationParts: LocationsFormatter,
                dischargeLocationParts: LocationsFormatter,
                vessel: VesselFormatter,
                rates: MultipleRateFormatter,
            };
            const report = getReport(data, new Date(), formatters);
            const email = generateEmail(report);
            downloadFile(email);
            MixpanelLogger.trackExportToEmailEvent(
                exportType,
                quantityItemsToExport,
                params.exportConfidential
            );
        }

        this.LogExportHistory(
            params.format,
            this.ordersPageRef,
            orders,
            ORDER_GRID_TYPE
        );
        this.LogExportHistory(
            params.format,
            this.fixturesPageRef,
            fixtures,
            FIXTURE_GRID_TYPE
        );

        modalService.close();
    }

    LogExportHistory(format, pageRef, items, recordType) {
        if (items.length === 0) return;

        const auditParams = pageRef.current
            .getWrappedInstance()
            .getAuditParams();

        AuditHistoryService.createExportHistory(
            format,
            items.length,
            recordType,
            auditParams
        );
    }

    render() {
        logger.trackPageView('Multi');

        const style = {
            flexGrow: this.state.flexGrow,
            height: this.state.height + 'px',
            display: 'flex',
            flexDirection: 'column',
        };

        return (
            <div id="multiContainer" style={{ height: '100%' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <div id={ordersPaneHeightElementId} style={style}>
                        <div
                            style={{
                                position: 'relative',
                                flexGrow: 1,
                                height: '100%',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <OrdersPage
                                    {...this.props}
                                    ref={this.ordersPageRef}
                                    onSelectionChanged={
                                        this.handleSelectionChanged
                                    }
                                    exportParams={this.state.export}
                                    onExportClicked={this.openExportOptions}
                                    context={this.state.context}
                                    useCondensedView={
                                        this.state.useCondensedView
                                    }
                                    showCondensedViewToggle={true}
                                    onToggleCondensedView={
                                        this.handleOnToggleCondensedView
                                    }
                                    handleGroupChange={this.handleGroupChange}
                                    singleView={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'inlineBlock',
                            height: '5px',
                            width: '100%',
                            cursor: 'ns-resize',
                            backgroundColor: '#d4d4d5',
                        }}
                        onMouseDown={this.handleResizeBarMouseDown}
                        onMouseUp={this.handleResizeBarMouseUp}
                    >
                        &nbsp;
                    </div>
                    <div
                        id={FixturesPaneId}
                        style={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                flexGrow: 1,
                                height: '100%',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                                <FixturesPage
                                    {...this.props}
                                    ref={this.fixturesPageRef}
                                    numberOfYears={
                                        this.state.selectedOption.value
                                    }
                                    onSelectionChanged={
                                        this.handleSelectionChanged
                                    }
                                    exportParams={this.state.export}
                                    onExportClicked={this.openExportOptions}
                                    context={this.state.context}
                                    useCondensedView={
                                        this.state.useCondensedView
                                    }
                                    onToggleCondensedView={
                                        this.handleOnToggleCondensedView
                                    }
                                    handleGroupChange={this.handleGroupChange}
                                    singleView={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        useCondensedView: state.user.themeSettings.useCondensedView,
        selectedOption: state.user.dataSize,
        quantityFormat:
            state.layouts.selectedLayout.commonSettings.quantityFormat
                .currentState,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...userActions }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiPage);
