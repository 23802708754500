export const displayOnlyRumouredHandler = (itemsMap, displayOnlyRumoured) => {
    if (displayOnlyRumoured) {
        for (const [key, item] of itemsMap.entries()) {
            if (item.rumoured !== true && item.rumouredFields.length === 0) {
                itemsMap.delete(key);
            }
        }
    }
    return itemsMap;
};
