import unicodeSortComparator from '../../../../components/grid/comparators/UnicodeSortComparator';
import MultipleCloudUserFormatter from '../formatters/MultipleCloudUserFormatter';

const MultipleCloudUserComparator = (valueA, valueB) => {
    const a = MultipleCloudUserFormatter({ value: valueA }).toLowerCase();
    const b = MultipleCloudUserFormatter({ value: valueB }).toLowerCase();

    return unicodeSortComparator(a, b);
};

export default MultipleCloudUserComparator;
