const groupKey = 'selectedGroup';
const layoutKey = 'selectedLayout';
const dataSizeKey = 'dataSize';
const reportTitlesKey = 'reportTitles';
const useCondensedViewKey = 'useCondensedView';
const showDeletedFixturesKey = 'showDeletedFixtures';
const showDeletedOrdersKey = 'showDeletedOrders';

export const fetchGroup = () => fetchSessionData(groupKey);

export const saveGroup = (group) => saveSessionData(groupKey, group);

export const fetchSelectedLayoutId = () => fetchSessionData(layoutKey);

export const saveSelectedLayoutId = (selectedLayoutId) =>
    saveSessionData(layoutKey, selectedLayoutId);

export const ensureSavedGroupIsPresentInUsersCurrentGroups = (groups) => {
    const currentGroup = fetchGroup();

    if (!currentGroup) {
        return;
    }

    if (groups) {
        const matchingGroup = groups.find((e) => {
            return e.id === currentGroup.id;
        });

        if (!matchingGroup) {
            saveGroup(null);
        }
    } else {
        saveGroup(null);
    }
};

export const fetchDataSize = () => fetchData(dataSizeKey);

export const saveDataSize = (dataSize) => saveData(dataSizeKey, dataSize);

export const fetchReportTitles = () => fetchData(reportTitlesKey);

export const saveReportTitles = (titles) => saveData(reportTitlesKey, titles);

export const fetchShowDeletedFixtures = () =>
    fetchBool(showDeletedFixturesKey, false);

export const saveShowDeletedFixtures = (on) =>
    saveBool(showDeletedFixturesKey, on);

export const fetchShowDeletedOrders = () =>
    fetchBool(showDeletedOrdersKey, false);

export const saveShowDeletedOrders = (on) => saveBool(showDeletedOrdersKey, on);

const fetchData = (key, defaultValue) => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState) {
            return JSON.parse(serializedState);
        }
        return undefined;
    } catch (err) {
        //nothing - probably due to browser having local storage disabled
        return defaultValue;
    }
};

const saveData = (key, data) => {
    try {
        if (data == null) {
            localStorage.removeItem(key);
        } else {
            const serializedState = JSON.stringify(data);
            localStorage.setItem(key, serializedState);
        }
    } catch (err) {
        //nothing - failed, probably due to browser having local storage disabled
    }
};

const fetchSessionData = (key, defaultValue) => {
    try {
        const serializedState = sessionStorage.getItem(key);
        if (serializedState) {
            return JSON.parse(serializedState);
        }
        return undefined;
    } catch (err) {
        //nothing - probably due to browser having local storage disabled
        return defaultValue;
    }
};

const saveSessionData = (key, data) => {
    try {
        if (data == null) {
            sessionStorage.removeItem(key);
        } else {
            const serializedState = JSON.stringify(data);
            sessionStorage.setItem(key, serializedState);
        }
    } catch (err) {
        //nothing - failed, probably due to browser having local storage disabled
    }
};

const fetchBool = (key, defaultValue) => {
    try {
        const value = localStorage.getItem(key);
        return JSON.parse(value);
    } catch (err) {
        //nothing - probably due to browser having local storage disabled
        return defaultValue;
    }
};

const saveBool = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (err) {
        //nothing - failed, probably due to browser having local storage disabled
    }
};
