import React, { Component } from 'react';
import { connect } from 'react-redux';
import AuditActionPill from './AuditActionPill';
import * as DateTimeFormatUtils from '../common/DateTimeFormatUtils';
import FixtureHeadings from '../fixtures/FixtureHeadings';
import { OrderHeadings } from '../orders/OrderHeadings';
import { getDataset } from '_legacy/models/Datasets';
import { FetchMappingSelectDisplayValue } from '_legacy/data/mappings';

export const AuditHistoryTypes = {
    Fixture: 'Fixture',
    Order: 'Order',
};

export class AuditHistory extends Component {
    convertFieldToColumnHeading(field) {
        if (field === null || field === undefined) {
            return '-';
        }

        // Sometimes we deal with fields that don't exist as colDefs in their own right
        if (field === 'laycanShorthand') {
            return 'L/C';
        }
        if (field === 'dischargeZone') {
            return 'Disch Z';
        }

        var heading =
            this.props.entityType === AuditHistoryTypes.Fixture
                ? FixtureHeadings.find(
                      (h) =>
                          h.field?.toLowerCase() === field.toLowerCase() ||
                          h.colId?.toLowerCase() === field.toLowerCase()
                  )
                : OrderHeadings.find(
                      (h) =>
                          h.field?.toLowerCase() === field.toLowerCase() ||
                          h.colId?.toLowerCase() === field.toLowerCase()
                  );

        if (heading) {
            return (
                heading.headerName ??
                getDataset(this.props.datasetId).headingsMapping[field]
            );
        } else {
            return null;
        }
    }

    mapValue(field, value) {
        if (field === 'status') {
            return FetchMappingSelectDisplayValue(value, this.props.datasetId);
        }

        return value;
    }

    render() {
        if (!this.props.items || this.props.items.length === 0) {
            return (
                <div className="ui info message">
                    <span>There is no audit history for this item</span>
                </div>
            );
        }

        const tableRows = this.props.items.map((g) => {
            const actionDate = DateTimeFormatUtils.formatDate(
                g.actionDateTime,
                this.props.userTimezone
            );
            const actionTime = DateTimeFormatUtils.formatTime(
                g.actionDateTime,
                this.props.userTimezone
            );

            return (
                <tr key={g.id}>
                    <td
                        style={{
                            borderRight: 0,
                            paddingRight: 10,
                            width: '90px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {actionDate}
                    </td>
                    <td
                        style={{
                            borderLeft: 0,
                            paddingLeft: 0,
                            whiteSpace: 'nowrap',
                            width: '50px',
                        }}
                    >
                        {actionTime}
                    </td>
                    <td style={{ whiteSpace: 'nowrap', width: '50px' }}>
                        {g.actionBy.fullName} ({g.actionBy.username})
                    </td>
                    <td
                        style={{
                            textAlign: 'center',
                            width: '50px',
                        }}
                    >
                        <AuditActionPill action={g.action} />
                    </td>
                    <td
                        style={{
                            borderLeft: '1px solid rgba(34, 36, 38, 0.15)',
                            borderRight: '1px solid rgba(34, 36, 38, 0.15)',
                            textAlign: 'center',
                            width: '50px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {this.convertFieldToColumnHeading(g.field)}
                    </td>
                    <td
                        style={{
                            color: 'red',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {this.mapValue(g.field, g.before)}
                    </td>
                    <td style={{ color: 'green', whiteSpace: 'nowrap' }}>
                        {this.mapValue(g.field, g.after)}
                    </td>
                </tr>
            );
        });

        return (
            <div>
                <table className="ui table" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Date</th>
                            <th>User</th>
                            <th style={{ textAlign: 'center' }}>Status</th>
                            <th style={{ textAlign: 'center' }}>Cell</th>
                            <th>Before</th>
                            <th>After</th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            </div>
        );
    }
}

AuditHistory.defaultProps = {
    entityType: AuditHistoryTypes.Fixture,
};

const mapStateToProps = (state) => {
    return {
        userTimezone: state.user.userTimezone,
    };
};

export default connect(mapStateToProps)(AuditHistory);
