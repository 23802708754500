export const createDataForExport = (nodes) => {
    const treeStructuredNodes = createDataTree(nodes);
    const rootNode = treeStructuredNodes[0];

    rootNode.levelsCount = getLevelsCount(rootNode.childNodes);
    setDataRowsCountToGroupNodes(treeStructuredNodes[0].childNodes);

    return treeStructuredNodes;
};

// AG Grid API retrives data as array of nodes (node just have a link to parent node).
// Purpose of this method is to change array nodes structure to General Tree structure
// for easily recursive navigation during export.
const createDataTree = (nodes) => {
    const uniqueNodes = getAllUniqueNodes(nodes);
    const dataTree = [];
    const hashTable = Object.create(null);

    uniqueNodes.forEach(
        (node) => (hashTable[node.id] = { ...node, childNodes: [] })
    );

    uniqueNodes.forEach((node) => {
        if (node.parent && node.parent.id)
            hashTable[node.parent.id].childNodes.push(hashTable[node.id]);
        else dataTree.push(hashTable[node.id]);
    });

    return dataTree;
};

// Method retvives all unique nodes(including group nodes) for grid provided rows.
const getAllUniqueNodes = (nodes) => {
    const nodesMap = new Map();

    for (let node of nodes) {
        nodesMap.set(node.id, node);

        while (node.level !== -1) {
            node = node.parent;
            nodesMap.set(node.id, node);
        }
    }

    return Array.from(nodesMap.values());
};

const getLevelsCount = (nodes) => {
    let count = 0;

    while (nodes[0].childNodes.length !== 0) {
        nodes = nodes[0].childNodes;
        count++;
    }

    return count;
};

// During export we should print how much data rows exists for each group node.
const setDataRowsCountToGroupNodes = (nodes) => {
    for (const node of nodes) {
        if (node.group) {
            node.dataRowsCount = getDataRowsCount(node.childNodes);
            setDataRowsCountToGroupNodes(node.childNodes);
        }
    }
};

const getDataRowsCount = (nodes) => {
    let count = 0;

    if (!nodes[0].group) {
        return nodes.length;
    }

    for (const node of nodes) {
        count += getDataRowsCount(node.childNodes);
    }

    return count;
};
