import React from 'react';
import Lightbulb from '../../icons/Lightbulb';

const InfoMessage = (props) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: '3em',
        }}
        className="info-message"
        {...props}
    >
        <Lightbulb
            style={{
                marginRight: '1em',
            }}
        />
        <span style={{ width: '100%' }}>{props.children}</span>
    </div>
);

export default InfoMessage;
