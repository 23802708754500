import CompanyFormatter from '_legacy/modules/shared/columns/formatters/CompanyFormatter';
import isString from 'lodash/isString';

const MultipleThirdPartyCompanyFormatter = ({ value }) => {
    if (!value) {
        return null;
    }

    if (isString(value)) {
        return value;
    }

    return value.map((part) => CompanyFormatter({ value: part })).join(' ');
};

export default MultipleThirdPartyCompanyFormatter;
