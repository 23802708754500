import * as DateTimeFormatUtils from '../../common/DateTimeFormatUtils';

const DefaultDateTimeFormatter = ({ node, value, context }) => {
    return !value || (node && node.group)
        ? ''
        : DateTimeFormatUtils.formatDefaultDateTime(
              value,
              context.userTimezone
          );
};

export default DefaultDateTimeFormatter;
