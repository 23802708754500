import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./freightPortBasisFragment.gql');

export const FREIGHT_PORT_BASIS_TYPE_NAME = 'FreightPortBasis';
export const FREIGHT_PORT_BASIS_FRAGMENT_NAME = 'FreightPortBasisFragment';
export const FREIGHT_PORT_BASIS_FRAGMENT = gql`
    ${FRAGMENT}
`;
