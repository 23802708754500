import {
    ENTITY_PART_TYPE_KNOWN,
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_SEPARATOR,
    ENTITY_PART_TYPE_PLACEHOLDER,
} from './common/EntityPart';

export const createKnownCargoPart = (cargo) => {
    return createPart(cargo.id, ENTITY_PART_TYPE_KNOWN, cargo.name);
};

export const createCustomCargoPart = (cargo) => {
    return createPart(cargo.name, ENTITY_PART_TYPE_CUSTOM, null);
};

export const createSeparatorCargoPart = (character) => {
    return createPart(character, ENTITY_PART_TYPE_SEPARATOR, null);
};

export const createPlaceholderCargoPart = (character) => {
    return createPart(character, ENTITY_PART_TYPE_PLACEHOLDER, null);
};

const createPart = (value, partType, name) => {
    return {
        value,
        partType,
        name,
    };
};
