import Logger from '../../../diagnostics/logging/logger';
import { cloneDeep } from 'lodash';

export const createMarkActiveMenuItem = (props, node, gridId, isItemActive) => {
    const { group, data } = node;
    const title = isItemActive ? 'Refresh Active State' : 'Mark Active';
    const tooltip = 'Change Order State to Active';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.makeActive', gridId);

                const clonedData = cloneDeep(data);
                props.onMakeActive(clonedData);
            }
        },
        tooltip: tooltip,
    };
};
