import React, { Component } from 'react';
import { FilterGenerator } from './FilterGenerator';
import debounce from 'lodash/debounce';
import './DeadweightFilter.scss';

class DeadweightFilter extends Component {
    constructor(props) {
        super(props);
        this.fromInputRef = React.createRef();
        this.toInputRef = React.createRef();

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.updateGrid = this.updateGrid.bind(this);
        this.updateGrid = debounce(this.updateGrid, 1000);

        this.clearFilter = this.clearFilter.bind(this);

        this.state = {
            from: '',
            to: '',
        };
    }

    doesFilterPass(params) {
        const value = this.valueGetter(this.props.valueGetter(params.node));
        const from = this.formatNumber(this.state.from);
        const to = this.formatNumber(this.state.to);

        if (from && to) {
            return from <= value && to >= value;
        }

        if (from) {
            return from <= value;
        }

        if (to) {
            return value && to >= value;
        }

        return true;
    }

    isFilterActive() {
        return (
            this.formatNumber(this.state.from) > 0 ||
            this.formatNumber(this.state.to) > 0
        );
    }

    getModel() {
        return this.isFilterActive()
            ? { to: this.state.to, from: this.state.from }
            : null;
    }

    setModel(model) {
        if (model) {
            this.setState(
                {
                    to: model.to,
                    from: model.from,
                },
                () => this.props.filterChangedCallback()
            );
        } else {
            this.clearFilter();
        }
    }

    formatNumber(value) {
        return Number(value.replace(/,/g, ''));
    }

    valueGetter(rowValue) {
        return this.props.fieldName && rowValue
            ? rowValue[this.props.fieldName]
            : rowValue;
    }

    handleFromChange(event) {
        this.handleInputChange(event, 'from');
    }

    handleToChange(event) {
        this.handleInputChange(event, 'to');
    }

    handleInputChange(event, fieldName) {
        var validationRegex = /^[\d,]*$/;
        const value = event.target.value;

        if (validationRegex.test(value)) {
            this.setState({ [fieldName]: value }, () => {
                this.updateGrid();
            });
        }
    }

    updateGrid() {
        this.props.filterChangedCallback();
    }

    clearFilter() {
        this.setState({ from: '', to: '' }, () => {
            this.props.filterChangedCallback();
        });
    }

    numberWithCommas(number) {
        return number
            .replace(/,/g, '')
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }

    render() {
        const elements = [
            <div style={{ width: '100%' }}>
                <input
                    type="text"
                    className="dwt-filter-input"
                    value={this.numberWithCommas(this.state.from)}
                    onChange={this.handleFromChange}
                    placeholder="From"
                    min={0}
                />
                <input
                    type="text"
                    className="dwt-filter-input"
                    value={this.numberWithCommas(this.state.to)}
                    onChange={this.handleToChange}
                    placeholder="To"
                />
            </div>,
        ];

        return <FilterGenerator elements={elements} clear={this.clearFilter} />;
    }
}

export default DeadweightFilter;
