import { getDataset } from '../../../models/Datasets';
import { getDirectionService } from '../../../services/directions/DirectionServiceFactory';

const DirectionValueGetter = (params) => {
    if (params.data) {
        const { datasetId, loadZone, dischargeZone, loadLocationParts } =
            params.data;
        const { directionLogic } = params.context;

        const autoPopulateDirectionEnabled =
            getDataset(datasetId).autoPopulateDirectionEnabled;

        if (autoPopulateDirectionEnabled) {
            const directionService = getDirectionService(directionLogic);

            if (directionService) {
                const direction = directionService.calculateDirection(
                    loadZone,
                    dischargeZone,
                    loadLocationParts
                );

                return direction;
            }
        }
    }

    return '';
};

export default DirectionValueGetter;
