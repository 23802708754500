import React, { Component } from 'react';
import classNames from 'classnames';
import { Mutation } from '@apollo/client/react/components';
import Select from 'react-select';
import { AllGroupsQuery } from '../../../api/queries/GroupQueries';
import { Link } from 'react-router-dom';
import { gql } from '@apollo/client';
import { dataSets } from '../../../models/Datasets';

class AddGroupPage extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { name: '', dataSet: null };
    }

    handleSubmit = (event, mutation) => {
        event.preventDefault();
        if (this.validateName() && this.validateDataSet()) {
            mutation({
                variables: {
                    group: {
                        name: this.state.name,
                        datasetId: this.state.dataSet,
                    },
                },
            });
            this.setState({ name: '', dataSet: null });
        }
    };

    handleChange = (event) => {
        let state = this.state;
        let callback = null;
        if (event.target) {
            state.name = event.target.value;
            callback = this.validateName;
        } else {
            state.dataSet = event.value;
            callback = this.validateDataSet;
        }
        this.setState(state, callback);
    };

    validateName = () => {
        if (this.state.name !== '') {
            this.setState({
                nameError: undefined,
            });
            return true;
        } else {
            this.setState({
                nameError: 'Name is required',
            });
            return false;
        }
    };

    validateDataSet = () => {
        if (this.state.dataSet !== null) {
            this.setState({
                dataSetError: undefined,
            });
            return true;
        } else {
            this.setState({
                dataSetError: 'Dataset is required',
            });
            return false;
        }
    };

    handleGroupAdded = (groupId) => {
        this.props.history.push(`/admin/groups/${groupId}`);
    };

    render() {
        return (
            <div>
                <h2>Add Group</h2>
                <Mutation
                    mutation={gql`
                        mutation CreateGroup($group: GroupInput!) {
                            createGroup(group: $group) {
                                id
                                name
                                datasetId
                                createdBy {
                                    username
                                }
                                createdDate
                                groupOnlyExpiryPeriod
                            }
                        }
                    `}
                    onCompleted={(data) => {
                        if (data.createGroup) {
                            this.handleGroupAdded(data.createGroup.id);
                        }
                    }}
                    update={(cache, { data: { createGroup } }) => {
                        try {
                            const data = cache.readQuery({
                                query: AllGroupsQuery,
                            });
                            data.groups.push(createGroup);
                            cache.writeQuery({ query: AllGroupsQuery, data });
                        } catch (exp) {
                            // NO OP in the event the cache isn't working
                        }
                    }}
                    onError={() => {}}
                >
                    {(addGroup, { loading, error, data }) => {
                        return (
                            <div>
                                <form
                                    className="ui form"
                                    onSubmit={(e) =>
                                        this.handleSubmit(e, addGroup)
                                    }
                                >
                                    <div className="fields">
                                        <div
                                            className={classNames(
                                                'twelve wide required field',
                                                {
                                                    error: this.state.nameError,
                                                }
                                            )}
                                        >
                                            <p>
                                                <input
                                                    data-id="add-group-name-input"
                                                    className="ui fluid input"
                                                    type="text"
                                                    name="groupName"
                                                    onChange={this.handleChange}
                                                    value={this.state.name}
                                                    placeholder="Name"
                                                    disabled={loading}
                                                />
                                            </p>
                                            {this.state.nameError && (
                                                <div className="ui pointing label red">
                                                    {this.state.nameError}
                                                </div>
                                            )}
                                            <div data-cy="dataset-select">
                                                <Select
                                                    classNamePrefix="list"
                                                    options={dataSets}
                                                    onChange={this.handleChange}
                                                    placeholder="Dataset..."
                                                />
                                            </div>
                                            {this.state.dataSetError && (
                                                <div className="ui pointing label red">
                                                    {this.state.dataSetError}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <button
                                        data-id="add-group-save"
                                        type="submit"
                                        className={classNames(
                                            'ui primary button ',
                                            {
                                                loading: loading,
                                            }
                                        )}
                                        disabled={loading}
                                    >
                                        Save
                                    </button>
                                    <Link
                                        to="/admin/groups"
                                        className="ui default button"
                                        disabled={loading}
                                    >
                                        Cancel
                                    </Link>
                                </form>
                                {error && (
                                    <div className="ui negative message">
                                        {error.message}
                                    </div>
                                )}
                            </div>
                        );
                    }}
                </Mutation>
            </div>
        );
    }
}

export default AddGroupPage;
