class ParseResult {
    constructor(inObj) {
        this.parsedValue = '';
        this.isSuccess = false;
        this.loadCount = NaN;
        this.dischargeCount = NaN;

        if (inObj) {
            Object.assign(this, inObj);
        }
    }
}

class FreightPortBasisParser {
    parser = {
        isMatch: (value) => /^\d+[:-]\d+$/.test(value),
        formatValue: this.parseRangeValue.bind(this),
    };

    parseNumberString(value) {
        return value.replace(/[\s,]/g, '');
    }

    parseRangeValue(value) {
        const split = value.split(/[:-]/g);
        if (split.length === 2) {
            const loadCount = this.parseNumberString(split[0]);
            const dischargeCount = this.parseNumberString(split[1]);

            return new ParseResult({
                parsedValue: value,
                isSuccess: true,
                loadCount: loadCount,
                dischargeCount: dischargeCount,
            });
        }

        return undefined;
    }

    parse(inputString) {
        return this.parser.isMatch(inputString)
            ? this.parser.formatValue(inputString)
            : new ParseResult({
                  parsedValue: inputString,
              });
    }

    clean(inputString) {
        if (!inputString) {
            return inputString;
        }

        let allowCharsRegEx = /[^\d:-]+/gi;

        return inputString.replace(allowCharsRegEx, '');
    }
}
export { ParseResult, FreightPortBasisParser };
