import { blankKeyText } from '../../../constants/gridText';
import MultipleCompanyFormatter from '../formatters/MultipleCompanyFormatter';

const BrokersKeyCreator = ({ value }) => {
    if (!value || value.length === 0) {
        return blankKeyText;
    }

    return MultipleCompanyFormatter({ value });
};

export default BrokersKeyCreator;
