import { QuantityParser } from '../../../shared/columns/tools/quantityParser';

const parser = new QuantityParser();

export const validateAmount = (amountObj) => {
    return (
        amountObj &&
        amountObj.from > 0 &&
        !isNaN(amountObj.to) &&
        amountObj.to > 0
    );
};

export const validateDurationAmount = (
    durationAmount,
    durationAmountObj,
    durationUnit
) => {
    if (!durationAmount) {
        return {
            isDurationAmountValid: true,
            durationAmountValidationErrorMessage: '',
        };
    }
    if (!durationAmountObj.isSuccess) {
        return {
            isDurationAmountValid: false,
            durationAmountValidationErrorMessage: `Duration ${durationAmountObj.parsedValue} is invalid`,
        };
    }
    if (durationAmountObj.from > durationAmountObj.to) {
        return {
            isDurationAmountValid: false,
            durationAmountValidationErrorMessage: `Range ${durationAmountObj.from} - ${durationAmountObj.to} is invalid`,
        };
    }
    if (durationAmountObj.from <= 0) {
        return {
            isDurationAmountValid: false,
            durationAmountValidationErrorMessage: 'Min value is 1',
        };
    }
    if (durationUnit.maxAmountValue < durationAmountObj.to) {
        return {
            isDurationAmountValid: false,
            durationAmountValidationErrorMessage:
                durationUnit.validationErrorMessage,
        };
    }

    return {
        isDurationAmountValid: true,
        durationAmountValidationErrorMessage: '',
    };
};

export const validateBound = (amount) => {
    return amount ? parser.checkIsInteger(amount) : true;
};
