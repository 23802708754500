import client from '_legacy/api/graphQlClient';
import { FAIL_ORDER_MUTATION } from '_legacy/api/queries/OrderQueries';

const failOrder = (props, order) => {
    client()
        .mutate({
            mutation: FAIL_ORDER_MUTATION,
            variables: {
                groupId: props.context.groupId,
                id: order.id,
            },
            context: {
                errorHandling: {
                    unknownResult: {
                        resolution: {
                            isSafeToRetry: true,
                        },
                    },
                },
            },
            fetchPolicy: 'no-cache',
        })
        .then((result) => {
            props.agGridReact.props.onRowUpdated(result.data.failOrder);
        });
};

export default failOrder;
