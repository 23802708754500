import isString from 'lodash/isString';
import { durationFormatterInstance } from './DurationFormatter';
import { ENTITY_PART_TYPE_DURATION } from '_legacy/models/common/EntityPart';
import {
    getDurationUnitByValue,
    LEGS_VALUE,
} from '_legacy/modules/shared/columns/models/DurationUnits';

export const MultipleDurationFormatter = ({ value }) => {
    if (!value || value.length === 0) {
        return '';
    }

    if (isString(value)) {
        //formatted already
        return value;
    }

    const text = [];

    if (!Array.isArray(value)) {
        text.push(durationFormatterInstance.format(value));
    } else {
        const { excludeUnits, overallUnit } =
            checkIfUnitsShouldBeExcluded(value);

        value.forEach((part) => {
            text.push(durationFormatterInstance.format(part, excludeUnits));
        });

        if (excludeUnits) {
            const amountPart = text.join(' ');
            return `${amountPart} ${overallUnit}`;
        }
    }

    return text.join(' ');
};

const checkIfUnitsShouldBeExcluded = (parts) => {
    const durationValueParts = parts.filter(
        (part) => part.partType === ENTITY_PART_TYPE_DURATION
    );

    const { unit } = durationValueParts[0].durationValue;
    const countOfSameUnits = durationValueParts.filter(
        (part) => part.durationValue.unit === unit
    ).length;

    return {
        excludeUnits:
            countOfSameUnits === durationValueParts.length &&
            durationValueParts.length > 1 &&
            unit !== getDurationUnitByValue(LEGS_VALUE).value,
        overallUnit: unit,
    };
};
