import React, { Fragment } from 'react';
import CloseIcon from '../../../../icons/CloseIcon';
import ErrorMessage from '../../ErrorMessage';

/**
 * Action was a request for a state-change and it IS KNOWN that the
 * state-change was not performed, that is, a user agent code detected, that
 * the error result has a known error code, the server returned a 4XX error,
 * server hostname wasn't resolved, etc.
 */
const WriteRejectedErrorSafeToRetryMessage = ({
    resultDescription,
    resolutionToTryBeforeContacting,
    onDismiss,
}) => (
    <ErrorMessage>
        <div style={{ display: 'flex' }}>
            <span style={{ width: '100%' }}>
                <section>
                    <h1>An error occurred</h1>
                    <span>
                        {resultDescription || (
                            <Fragment>
                                Your recent change has not been fulfilled due to
                                an error.
                            </Fragment>
                        )}
                    </span>
                    <span className="resolution">
                        {' '}
                        {resolutionToTryBeforeContacting || (
                            <Fragment>
                                Please check your connection and try again. Also
                                try refreshing the page.
                            </Fragment>
                        )}{' '}
                        Contact support if the problem persists.
                    </span>
                </section>
            </span>
            <CloseIcon
                onClick={onDismiss}
                style={{ cursor: 'default', marginRight: '1em' }}
            />
        </div>
    </ErrorMessage>
);

export default WriteRejectedErrorSafeToRetryMessage;
