import { isEditableForTbnRow } from '../tools/headingTools';
import { commonRowAndCellClassRules } from '../../../../components/grid/classRules/classRules';
import BlanksFilterRenderer from '../../../../components/grid/renderers/BlanksFilterRenderer';
import naturalSortComparator from '../../../../components/grid/comparators/NaturalSortComparator';
import NamePropertyComparator from '../../../../components/grid/comparators/NamePropertyComparator';
import defaultObjEquals from '../../../../components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '../../../../components/grid/columnDefaults/defaultSetFilterParams';

export const vesselYardHeading = {
    colId: 'yard',
    headerName: 'Yard',
    field: 'vessel.yard',
    editable: isEditableForTbnRow,
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    width: 120,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    suppressPaste: false,
};
