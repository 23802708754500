import moment from 'moment';

const LaycanFromFormatter = ({ node, value }) => {
    if (node && node.group) {
        return '';
    }

    if (!value || !value.startDate) {
        return '';
    }

    const date = moment(value.startDate);
    return date.isValid() ? date.format('DD MMM YY').toUpperCase() : '';
};

export default LaycanFromFormatter;
