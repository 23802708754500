export const rateBounds = [
    {
        id: 0,
        value: null,
        unsupportedFixtureTypes: [],
        unsupportedWithBounds: [],
    },
    {
        id: 1,
        value: 'LOW',
        label: 'LOW',
        unsupportedFixtureTypes: [],
        unsupportedWithBounds: [],
    },
    {
        id: 2,
        value: 'LOW-MID',
        label: 'LOW-MID',
        unsupportedFixtureTypes: [],
        unsupportedWithBounds: [],
    },
    {
        id: 3,
        value: 'MID',
        label: 'MID',
        unsupportedFixtureTypes: [],
        unsupportedWithBounds: [],
    },
    {
        id: 4,
        value: 'MID-HIGH',
        label: 'MID-HIGH',
        unsupportedFixtureTypes: [],
        unsupportedWithBounds: [],
    },
    {
        id: 5,
        value: 'HIGH',
        label: 'HIGH',
        unsupportedFixtureTypes: [],
        unsupportedWithBounds: [],
    },
    {
        id: 6,
        value: 'CIRCA',
        label: 'CIRCA (ca.)',
        unsupportedFixtureTypes: [],
        unsupportedWithBounds: [],
    },
    {
        id: 7,
        separator: true,
        value: null,
        unsupportedFixtureTypes: ['COA', 'VOY', 'TCT', 'UNKNOWN'],
        unsupportedWithBounds: [],
    },
    {
        id: 8,
        value: 'FLOOR',
        label: 'FLOOR',
        unsupportedFixtureTypes: ['COA', 'VOY', 'TCT', 'UNKNOWN'],
        unsupportedWithBounds: ['NO-FLR/CLG'],
    },
    {
        id: 9,
        value: 'FLOOR-CA',
        label: 'FLOOR (ca.)',
        unsupportedFixtureTypes: ['COA', 'VOY', 'TCT', 'UNKNOWN'],
        unsupportedWithBounds: ['NO-FLR/CLG'],
    },
    {
        id: 10,
        value: 'CEILING',
        label: 'CEILING',
        unsupportedFixtureTypes: ['COA', 'VOY', 'TCT', 'UNKNOWN'],
        unsupportedWithBounds: ['NO-FLR/CLG'],
    },
    {
        id: 11,
        value: 'CEILING-CA',
        label: 'CEILING (ca.)',
        unsupportedFixtureTypes: ['COA', 'VOY', 'TCT', 'UNKNOWN'],
        unsupportedWithBounds: ['NO-FLR/CLG'],
    },
    {
        id: 12,
        value: 'NO-FLR/CLG',
        label: 'NO FLR/CLG',
        unsupportedFixtureTypes: ['COA', 'VOY', 'TCT', 'UNKNOWN'],
        unsupportedWithBounds: ['CEILING-CA', 'CEILING', 'FLOOR', 'FLOOR-CA'],
    },
];

export const getAvailableBounds = (type, parts) => {
    const boundsParts = parts
        ? parts.map((part) => part.rateValue?.flatRate?.bound)
        : null;

    return rateBounds.filter(
        (bound) =>
            !bound.unsupportedFixtureTypes.includes(type) &&
            !boundsParts?.some((part) =>
                bound.unsupportedWithBounds.includes(part)
            )
    );
};

export const isUnsupportedBoundForType = (ratePart, type) => {
    return rateBounds.some(
        (bound) =>
            bound.value === ratePart.rateValue?.flatRate?.bound &&
            bound.unsupportedFixtureTypes.includes(type)
    );
};
