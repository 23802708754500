import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./locationPartFragment.gql');

export const LOCATION_PART_TYPE_NAME = 'LocationPart';
export const LOCATION_PART_FRAGMENT_NAME = 'LocationPartFragment';
export const LOCATION_PART_FRAGMENT = gql`
    ${FRAGMENT}
`;
