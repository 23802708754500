import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    useState,
    useEffect,
} from 'react';
import FortCellEditor from '_legacy/modules/shared/columns/editors/FortCellEditor';
import {
    formatSalePrice,
    parseSalePrice,
} from '_legacy/services/SalePriceFormatterService';

const SalePriceEditor = forwardRef((props, ref) => {
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState(
        formatSalePrice(props.value || '', true)
    );

    useEffect(() => {
        const initialEvent = {
            target: { value: inputValue + (props.charPress || '') },
        };
        handleChange(initialEvent);
    }, []);

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        const regex = /^(\d*\.?\d{0,2}[kKmM]?)$/;

        if (regex.test(value.replace(/,/g, '')) || value === '') {
            setInputValue(value);
        }
    };

    useImperativeHandle(ref, () => ({
        getValue() {
            return parseSalePrice(inputValue);
        },
        afterGuiAttached() {
            focus();
        },
    }));

    const handleBlur = () => {
        const formattedValue = formatSalePrice(
            parseSalePrice(inputValue),
            true
        );
        setInputValue(formattedValue);
    };

    return (
        <FortCellEditor focus={focus} {...props}>
            <input
                type="text"
                ref={inputRef}
                value={inputValue}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        </FortCellEditor>
    );
});

export default SalePriceEditor;
