class DateNodeTree {
    constructor(stringArray) {
        // This is the structure that holds all data
        this.nodesMap = new Map();
        this.filterState = { dateFilterSet: [ROOT_NODE_KEY] };

        const createNode = (key, date, value, parent, state) => {
            const node = { key, date, value, parent, children: [], state };
            this.nodesMap.set(key, node);
            return node;
        };

        const root = createNode('_', null, '_', null, States.CHECKED);
        this.root = root;

        this.setCheckbox = this.setCheckbox.bind(this);
        this.trickleDown = this.trickleDown.bind(this);
        this.bubbleUp = this.bubbleUp.bind(this);
        this.updateNodeDerivedState = this.updateNodeDerivedState.bind(this);
        this.isChecked = this.isChecked.bind(this);
        this.getNodeAncestors = this.getNodeAncestors.bind(this);
        this.getYears = this.getYears.bind(this);
        this.unwrapHierarchy = this.unwrapHierarchy.bind(this);
        this.getFilterState = this.getFilterState.bind(this);
        this.setFilterState = this.setFilterState.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.getNode = this.getNode.bind(this);

        // Initial setup: it processes the array of strings provided
        // and builds the data structure
        // local data and functions used only to build the data structure
        const months = {
            '01': 'JANUARY',
            '02': 'FEBRUARY',
            '03': 'MARCH',
            '04': 'APRIL',
            '05': 'MAY',
            '06': 'JUNE',
            '07': 'JULY',
            '08': 'AUGUST',
            '09': 'SEPTEMBER',
            10: 'OCTOBER',
            11: 'NOVEMBER',
            12: 'DECEMBER',
        };

        const getYear = (date) => date.substring(0, 4);
        const getMonth = (date) => date.substring(5, 7);
        const getDay = (date) => date.substring(8, 10);

        const uniqueArray = [...new Set(stringArray)];
        const dateArray = uniqueArray
            .filter((x) => x)
            .sort((a, b) => {
                const ya = a.substring(0, 4);
                const yb = b.substring(0, 4);
                const sameYear = ya === yb;
                const yearReverseOrder = ya < yb ? 1 : -1;
                const cronologicalOrder = a > b ? 1 : a === b ? 0 : -1;
                return sameYear ? cronologicalOrder : yearReverseOrder;
            });
        let currentYear = '';
        let currentMonth = '';
        const nodesMap = this.nodesMap;

        dateArray.forEach((item) => {
            const yr = getYear(item);
            const mth = getMonth(item);
            const day = getDay(item);

            if (yr !== currentYear) {
                const node = createNode(yr, null, yr, root, States.CHECKED);
                currentMonth = '';
                root.children.push(node);
            }
            if (mth !== currentMonth) {
                const parent = nodesMap.get(yr);
                const node = createNode(
                    yr + '-' + mth,
                    null,
                    months[mth],
                    parent,
                    States.CHECKED
                );
                parent.children.push(node);
            }
            const parent = nodesMap.get(yr + '-' + mth);
            const leaf = createNode(
                item,
                new Date(Date.parse(item)),
                day,
                parent,
                States.CHECKED
            );

            parent.children.push(leaf);

            currentYear = yr;
            currentMonth = mth;
        });

        this.nodes = Array.from(this.nodesMap.entries()).map(
            ([key, node]) => node
        );
    }

    setCheckbox(key, state) {
        if (state !== States.CHECKED && state !== States.UNCHECKED) return;

        this.trickleDown(key, state);
        this.bubbleUp(key);

        this.filterState = this.getFilterState();
    }

    trickleDown(key, state) {
        let node = this.nodesMap.get(key);
        if (node) {
            node.state = state;
            node.children.forEach((n) => this.trickleDown(n.key, state));
        }
    }

    bubbleUp(id) {
        let node = this.nodesMap.get(id);
        if (node) {
            node = node.parent;
            while (node !== null) {
                this.updateNodeDerivedState(node);
                node = node.parent;
            }
        }
    }

    // Methods for tree management
    updateNodeDerivedState(node) {
        const anyChecked = node.children.some(
            (n) => n.state === States.CHECKED
        );
        const anyUnchecked = node.children.some(
            (n) => n.state === States.UNCHECKED
        );
        const anyIndeterminate = node.children.some(
            (n) => n.state === States.INDETERMINATE
        );
        node.state =
            (anyChecked && anyUnchecked) || anyIndeterminate
                ? States.INDETERMINATE
                : anyChecked
                ? States.CHECKED
                : States.UNCHECKED;
    }

    isChecked(id) {
        const node = this.nodesMap.get(id);
        return node ? node.state === States.CHECKED : true;
    }

    getNodeAncestors(id) {
        const hierarchy = [];
        let node = this.nodesMap.get(id);
        while (node) {
            hierarchy.push(node.key);
            node = node.parent;
        }
        return hierarchy;
    }

    getNode(id) {
        return this.nodesMap.get(id);
    }

    getYears() {
        const root = this.nodesMap.get(ROOT_NODE_KEY);
        return root ? root.children : [];
    }

    unwrapHierarchy(node, hierarchy) {
        if (node.state === States.CHECKED) {
            hierarchy.push(node.key);
        } else {
            node.children.forEach((n) => this.unwrapHierarchy(n, hierarchy));
        }
    }

    getFilterState() {
        let model = { dateFilterSet: [] };
        let root = this.nodesMap.get(ROOT_NODE_KEY);
        this.unwrapHierarchy(root, model.dateFilterSet);

        return model;
    }

    setFilterState(model) {
        this.setCheckbox(ROOT_NODE_KEY, States.UNCHECKED);
        if (model) {
            model.dateFilterSet.forEach((key) =>
                this.setCheckbox(key, States.CHECKED)
            );
        } else {
            this.setCheckbox(ROOT_NODE_KEY, States.CHECKED);
        }
    }

    clearFilter = () => this.setCheckbox(ROOT_NODE_KEY, States.CHECKED);
}

const States = {
    CHECKED: 'checked',
    INDETERMINATE: 'indeterminate',
    UNCHECKED: 'unchecked',
};
DateNodeTree.States = States;

const ROOT_NODE_KEY = '_';
DateNodeTree.RootNodeKey = ROOT_NODE_KEY;

export default DateNodeTree;
