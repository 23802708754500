import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./commissionFragment.gql');

export const COMMISSION_TYPE_NAME = 'Commission';
export const COMMISSION_FRAGMENT_NAME = 'CommissionFragment';
export const COMMISSION_FRAGMENT = gql`
    ${FRAGMENT}
`;
