import { ListenerMiddlewareInstance } from '@reduxjs/toolkit';
import MixpanelLogger from '_legacy/diagnostics/logging/mixpanelLogger';
import { RootState } from 'store';
import {
    createUserLayout,
    layoutsSelectors,
    revertLayout,
    updateUserLayout,
} from 'store/feature/layoutsSlice';
import * as LayoutTools from '_legacy/tools/LayoutTools';

function configureMixpanelEvents(
    listener: ListenerMiddlewareInstance<RootState>
): void {
    listener.startListening({
        actionCreator: createUserLayout.fulfilled,
        effect: ({ payload: { result } }, { getState }) => {
            const selectedLayout = layoutsSelectors.selectLayout(getState());
            const datasetId = layoutsSelectors.selectedDatasetId(getState());

            let layoutProperties =
                LayoutTools.getCreatedLayoutPropertiesForMixpanel(
                    result.orders,
                    ['privacyWarning', 'isSelected', 'privacy'],
                    'Order',
                    selectedLayout.commonSettings.directionLogic.currentState,
                    selectedLayout.commonSettings.quantityFormat.currentState,
                    datasetId,
                    {}
                );
            MixpanelLogger.trackCreateLayoutEvent(
                result.name,
                layoutProperties
            );

            layoutProperties =
                LayoutTools.getCreatedLayoutPropertiesForMixpanel(
                    result.fixtures,
                    ['privacyWarning', 'isSelected', 'privacy'],
                    'Fixture',
                    selectedLayout.commonSettings.directionLogic.currentState,
                    selectedLayout.commonSettings.quantityFormat.currentState,
                    datasetId,
                    {}
                );
            MixpanelLogger.trackCreateLayoutEvent(
                result.name,
                layoutProperties
            );
        },
    });

    listener.startListening({
        actionCreator: revertLayout,
        effect: (_, { getState }) => {
            const selectedLayout = layoutsSelectors.selectLayout(getState());
            MixpanelLogger.trackRevertLayoutEvent(selectedLayout.name);
        },
    });

    listener.startListening({
        actionCreator: updateUserLayout.fulfilled,
        effect: ({ payload: { gridType } }, { getState }) => {
            const selectedLayout = layoutsSelectors.selectLayout(getState());
            const datasetId = layoutsSelectors.selectedDatasetId(getState());

            if (gridType === 'orders') {
                LayoutTools.sendSaveLayoutMixpanelEvent(
                    selectedLayout.name,
                    'Order',
                    selectedLayout.orders,
                    {},
                    selectedLayout.commonSettings.directionLogic.currentState,
                    selectedLayout.commonSettings.quantityFormat.currentState,
                    ['privacyWarning', 'isSelected', 'privacy'],
                    datasetId
                );
            }

            if (gridType === 'fixtures') {
                LayoutTools.sendSaveLayoutMixpanelEvent(
                    selectedLayout.name,
                    'Fixture',
                    selectedLayout.fixtures,
                    {},
                    selectedLayout.commonSettings.directionLogic.currentState,
                    selectedLayout.commonSettings.quantityFormat.currentState,
                    ['privacyWarning', 'isSelected', 'privacy'],
                    datasetId
                );
            }
        },
    });
}

export default configureMixpanelEvents;
