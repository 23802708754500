import React, { Component } from 'react';
import SyncIconSvg from './Sync.svg';

export default class SyncIcon extends Component {
    render = () => {
        return (
            <img
                src={SyncIconSvg}
                {...this.props}
                style={{ opacity: '0.7' }}
                alt="Sync Icon"
            />
        );
    };
}
