import React from 'react';
import StarOutlineSvg from './StarOutline.svg';

const StarOutlineIcon = (props) => (
    <img
        src={StarOutlineSvg}
        style={{ width: '26px', cursor: 'pointer' }}
        alt="Star Outline"
        {...props}
    />
);

export default StarOutlineIcon;
