import {
    DATASET_CLEAN_LONDON,
    DATASET_SPECIALISED,
    DATASET_DRY_CARGO,
} from '_legacy/models/Datasets';

export const vesselCategoriesByDataset = (datasetId) => {
    switch (datasetId) {
        case DATASET_SPECIALISED:
            return [];
        case DATASET_DRY_CARGO:
            return getCategoryNames(dryCargoVesselCategories);
        default:
            return getCategoryNames(vesselCategories);
    }
};

export const vesselCategoriesByFleetTypeIds = (datasetId, fleetTypeIds) => {
    const categories = getCategories(datasetId);

    return fleetTypeIds
        ? categories
              .filter((vc) => fleetTypeIds.includes(vc.fleetTypeId))
              .map((vc) => vc.category)[0]
        : null;
};

export const vesselCategoriesCustomSort = (datasetId) => {
    switch (datasetId) {
        case DATASET_CLEAN_LONDON:
            return getExistingCategoryNames(vesselCategories);
        default:
            return [];
    }
};

export const groupSortingByVesselCategories = (datasetId) => {
    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return getExistingCategoryNames(dryCargoVesselCategories);
        default:
            return getExistingCategoryNames(vesselCategories);
    }
};

const getCategories = (datasetId) => {
    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return dryCargoVesselCategories;
        default:
            return vesselCategories;
    }
};

const getCategoryNames = (categories) => categories.map((vc) => vc.category);

const getExistingCategoryNames = (categories) =>
    getCategoryNames(categories.filter((vc) => vc.fleetTypeId));

const vesselCategories = [
    { category: '' },
    { fleetTypeId: 29000, category: 'VLCC' },
    { fleetTypeId: 29125, category: 'SUEZMAX' },
    { fleetTypeId: 29300, category: 'AFRAMAX' },
    { fleetTypeId: 29600, category: 'PANAMAX' },
    { fleetTypeId: 29150, category: 'LR3' },
    { fleetTypeId: 29400, category: 'LR2' },
    { fleetTypeId: 29700, category: 'LR1' },
    { fleetTypeId: 35100, category: 'MR' },
    { fleetTypeId: 35200, category: 'HANDY' },
    { fleetTypeId: 35300, category: 'SMALL TANKER' },
];

const dryCargoVesselCategories = [
    { category: '' },
    { fleetTypeId: 19300, category: 'VALEMAX' },
    { fleetTypeId: 19400, category: 'VLOC' },
    { fleetTypeId: 19500, category: 'NEWCASTLEMAX' },
    { fleetTypeId: 19600, category: 'CAPESIZE' },
    { fleetTypeId: 19700, category: 'MINICAPE' },
    { fleetTypeId: 19800, category: 'POST-PANAMAX' },
    { fleetTypeId: 19900, category: 'KAMSARMAX' },
    { fleetTypeId: 20000, category: 'PANAMAX' },
    { fleetTypeId: 20100, category: 'ULTRAMAX' },
    { fleetTypeId: 20200, category: 'SUPRAMAX' },
    { fleetTypeId: 20300, category: 'HANDYMAX' },
    { fleetTypeId: 20400, category: 'HANDYSIZE' },
    { fleetTypeId: 20500, category: 'SMALL HANDY' },
    { fleetTypeId: 8900, category: 'COMBINED CARRIER' },
    { fleetTypeId: 70, category: 'GENERAL CARGO' },
    { fleetTypeId: 50, category: 'MPP' },
];
