import { ENTITY_PART_TYPE_SEPARATOR } from './common/EntityPart';

export const createBrokerPart = (broker) => {
    return createPart(broker.id.toString(), broker.type, broker.name);
};

export const createSeparatorBrokerPart = (character) => {
    return createPart(character, ENTITY_PART_TYPE_SEPARATOR, null);
};

const createPart = (value, partType, name) => {
    return {
        value,
        partType,
        name,
    };
};
