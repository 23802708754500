import React, { Component } from 'react';
import DateNodeTree from '../../../tools/DateNodeTree';
import TreeView from '@material-ui/lab/TreeView';
import PlusIcon from '../../icons/PlusIcon';
import MinusIcon from '../../icons/MinusIcon';
import TreeItem from '@material-ui/lab/TreeItem';
import PropTypes from 'prop-types';
import CountryImage from '../../common/CountryImage';

class FilterTreeView extends Component {
    constructor(props) {
        super(props);
        this.handleNodeToggled = this.handleNodeToggled.bind(this);
    }

    renderNode(treeNode, visibleNodes) {
        const checked = treeNode.state === DateNodeTree.States.CHECKED;
        const indeterminate =
            treeNode.state === DateNodeTree.States.INDETERMINATE;

        const checkBox = (
            <div>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={(event) => {
                        this.props.onNodeClicked(treeNode, !checked);
                    }}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{
                        top: '1px',
                        position: 'relative',
                        marginRight: '5px',
                    }}
                    ref={(el) => el && (el.indeterminate = indeterminate)}
                />
                <span>
                    <CountryImage countryCode={treeNode.countryCode} />
                    {treeNode.value.toUpperCase()}
                </span>
            </div>
        );

        return (
            <TreeItem
                key={treeNode.key}
                nodeId={treeNode.key.toString()}
                label={checkBox}
            >
                {treeNode.children
                    .filter((n) => visibleNodes[n.key])
                    .map((n) => this.renderNode(n, visibleNodes))}
            </TreeItem>
        );
    }

    handleNodeToggled(event, nodes) {
        if (this.props.onNodeToggle) {
            this.props.onNodeToggle(nodes);
        }
    }

    render() {
        const years = this.props.tree.getYears();
        const visibleNodes = this.props.visibleNodes;

        return (
            <TreeView
                defaultCollapseIcon={<MinusIcon style={{ width: '12px' }} />}
                defaultExpandIcon={<PlusIcon style={{ width: '12px' }} />}
                expanded={this.props.expanded}
                onNodeToggle={this.handleNodeToggled}
                style={{ transitionDuration: 0 }}
            >
                {years
                    .filter((n) => visibleNodes[n.key])
                    .map((n) => this.renderNode(n, visibleNodes))}
            </TreeView>
        );
    }
}

FilterTreeView.defaultProps = {
    tree: [],
};

FilterTreeView.propTypes = {
    onNodeClicked: PropTypes.func.isRequired,
};

export default FilterTreeView;
