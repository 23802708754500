import { groupSet, dataSizeSet, reportTitlesSet } from './actions/userActions';
import {
    fetchGroup,
    fetchDataSize,
    fetchReportTitles,
    saveGroup,
    saveDataSize,
    saveReportTitles,
} from './localStorage';
import throttle from 'lodash/throttle';
import groupHeadingApi from './components/orders/OrderHeadings';

const DEFAULT_DATA_SIZE = 1;

export function configureGridGroupStorageWatch(store) {
    let lastGroupSaved;
    let lastDataSize;
    let lastReportTitles;

    store.subscribe(
        //throttle the save behaviour so we don't write to local storage too often
        //(avoids JSON.stringify overuse)
        throttle(() => {
            const {
                user: { group, dataSize, reportTitles },
            } = store.getState();

            //don't update local storage if the items haven't changed since the last save
            //(this subscription is triggered on any state change, not just when groupId is updated)

            if (group !== lastGroupSaved) {
                saveGroup(group);
                lastGroupSaved = group;
            }
            if (dataSize !== lastDataSize) {
                saveDataSize(dataSize);
                lastDataSize = dataSize;
            }
            if (reportTitles !== lastReportTitles) {
                saveReportTitles(reportTitles);
                lastReportTitles = reportTitles;
            }
        }, 1000)
    );
}

export function configureDefaultGroupsAndGrid(groups, dispatch) {
    try {
        if (groups && groups.length > 0) {
            //temporary until proper defaults are made - ensure all user groups have defaults
            groupHeadingApi.ensureGroupsHaveDefaults(groups.map((g) => g.id));
            const lastGroup = fetchGroup();

            let group = lastGroup ? lastGroup : groups[0];

            dispatch(groupSet(group));

            const lastDataSize = fetchDataSize();

            let dataSize = lastDataSize
                ? lastDataSize
                : { value: DEFAULT_DATA_SIZE };

            dispatch(dataSizeSet(dataSize));

            const reportTitles = fetchReportTitles();
            if (reportTitles) {
                dispatch(reportTitlesSet(reportTitles));
            }
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}
