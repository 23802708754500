import React, { Component } from 'react';
import VisibilityIcon from '../../icons/VisibilityIcon';
import IndividualIcon from '../../icons/IndividualIcon';
import ReactTooltip from 'react-tooltip';

class VisibilityRenderer extends Component {
    constructor(props) {
        super(props);
        this.elRef = React.createRef();
    }

    tooltipDelay = 400;

    clearTooltipTimer = () => {
        if (!this.tooltipTimer) {
            return;
        }
        window.clearTimeout(this.tooltipTimer);
        this.tooltipTimer = undefined;
    };

    render() {
        const { node } = this.props;

        let displayValue;

        if (node && node.group) {
            displayValue = null;
        } else if (node && node.data && node.data.groupOnly) {
            displayValue = (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <VisibilityIcon />
                </div>
            );
        } else if (node && node.data && node.data.isIndividual) {
            displayValue = (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <IndividualIcon />
                </div>
            );
        }

        const rowIndex = `${this.props.rowIndex}`;

        return (
            <div
                ref={this.elRef}
                style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
                data-tip={rowIndex}
                data-for={`${this.props.context.gridId}-group-only-expiry`}
                onMouseEnter={() => {
                    if (this.tooltipTimer) {
                        return; // prevent double triggering problem on some browsers
                    }

                    this.tooltipTimer = setTimeout(() => {
                        ReactTooltip.show(this.elRef.current);
                        this.clearTooltipTimer();
                    }, this.tooltipDelay);
                }}
                onMouseLeave={() => {
                    this.clearTooltipTimer();
                    ReactTooltip.hide(this.elRef.current);
                }}
            >
                {displayValue}
            </div>
        );
    }
}

export default VisibilityRenderer;
