import DateComparator from './DateComparator';

//aim here is to ensure consistency across each instance of the app, everything becomes
//additionally sorted by created date (descending), equivalent to adding an extra multisort
//by CreatedDate into the grid.
const CreateCreatedDateDescendingSortComparatorWrapper = (
    comparator,
    colId,
    sortOptions
) => {
    return (valueA, valueB, nodeA, nodeB, isInverted) => {
        const originalResult = comparator(
            valueA,
            valueB,
            nodeA,
            nodeB,
            isInverted
        );

        if (
            originalResult === 0 &&
            nodeA &&
            nodeA.data &&
            nodeB &&
            nodeB.data
        ) {
            if (!sortOptions.lastSortColId) {
                //lazy loaded as the basic grid cannot set it when sort changes, as it's events
                //are only triggered AFTER the sort has already occurred
                const columnState = nodeA.columnApi.getColumnState();
                const sortColumns = columnState.filter(
                    (cs) => cs.sort !== null
                );
                sortOptions.lastSortColId =
                    sortColumns[sortColumns.length - 1].colId;
            }

            //only sort by created date time when it's the last column being sorted
            if (sortOptions.lastSortColId !== colId) {
                return 0;
            }

            const dateComparatorResult = DateComparator(
                nodeA.data.createdDateTime,
                nodeB.data.createdDateTime
            );

            if (!isInverted) {
                return -1 * dateComparatorResult;
            }

            return dateComparatorResult;
        } else {
            return originalResult;
        }
    };
};

export default CreateCreatedDateDescendingSortComparatorWrapper;
