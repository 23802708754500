const DateComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }
    //already dates so we can use the inbuilt comparison without having to reparse it
    if (valueA > valueB) {
        return 1;
    } else if (valueA < valueB) {
        return -1;
    } else {
        return 0;
    }
};
export default DateComparator;
