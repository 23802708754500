import { dischargeZones } from '../../../models/Location';
import PriorityListComparator from './PriorityListComparator';

/*
 * See LoadZoneComparator for notes on the use of nodeA.datasetId
 */
const DischargeZoneComparator = (valueA, valueB, nodeA, nodeB) => {
    const nodeADatasetId = nodeA ? nodeA.data.datasetId : null;
    const nodeBDatasetId = nodeB ? nodeB.data.datasetId : null;

    const datasetId = nodeADatasetId || nodeBDatasetId;

    return PriorityListComparator(
        valueA,
        valueB,
        dischargeZones(datasetId) // ag-grid context is *not* availble in comparators.
    );
};

export default DischargeZoneComparator;
