import naturalSortComparator from '../../../../components/grid/comparators/NaturalSortComparator';

const RatePartFilterComparator = (a, b) => {
    a = a || '';
    b = b || '';

    return naturalSortComparator(
        a.replace(/[,$€£]/g, ''),
        b.replace(/[,$€£]/g, '')
    );
};

export default RatePartFilterComparator;
