import DryCargoVesselPostProcessingService from './DryCargoVesselPostProcessingService';
import SpecialisedVesselPostProcessingService from './SpecialisedVesselPostProcessingService';
import TankersVesselPostProcessingService from './TankersVesselPostProcessingService';
import {
    DATASET_SPECIALISED,
    DATASET_DRY_CARGO,
    DATASET_TANKERS,
} from '_legacy/models/Datasets';

export const getVesselPostProcessingService = (datasetId) => {
    switch (datasetId) {
        case DATASET_SPECIALISED:
            return new SpecialisedVesselPostProcessingService();
        case DATASET_DRY_CARGO:
            return new DryCargoVesselPostProcessingService();
        case DATASET_TANKERS:
            return new TankersVesselPostProcessingService();
        default:
            return null;
    }
};
