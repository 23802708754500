import Logger from '../../../diagnostics/logging/logger';
import { cloneDeep } from 'lodash';

export const createWithdrawnMenuItem = (props, node, gridId) => {
    const { group, data } = node;

    const title = 'Withdrawn';
    const tooltip = 'This order is withdrawn';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.makeWithdrawn', gridId);

                const clonedData = cloneDeep(data);
                props.onMakeWithdrawn(clonedData);
            }
        },
        tooltip: tooltip,
    };
};
