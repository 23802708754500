import _ from 'lodash';
import { createAppSlice } from 'store/utils';
import legacyUserReducer from '_legacy/reducers/userReducer';
import { UserApi } from 'api';

export interface UserGroup {
    id: number;
    datasetId: number;
    name: string;
    fixtures: string;
    orders: string;
    commonSettings: {
        directionLogic: string;
        quantityFormat: string;
        defaultType: string;
    };
}

export interface UserState {
    id: string;
    userId: number;
    username: string;
    isAdmin: boolean;
    groups: UserGroup[];
    permissions: { groupId: string; canWrite: boolean }[];
    groupId: any;
    group: UserGroup | undefined;
    gridOptions: any;
    gridData: any;
    headingDefaults: any[];
    creationIndex: any;
    dataSize: any;
    reportTitles: any[];
    themeSettings: {
        newThemeEnabled: boolean;
        useCondensedView: boolean;
        useCompactDensityView: boolean;
    };
    userTimezone: string;
    loading: boolean;
}

const initialState: UserState = {
    id: '',
    userId: -1,
    username: '',
    isAdmin: false,
    groups: [],
    permissions: [],
    groupId: undefined,
    group: undefined,
    gridOptions: {},
    gridData: {},
    headingDefaults: [],
    creationIndex: {},
    dataSize: undefined,
    reportTitles: [],
    themeSettings: {
        newThemeEnabled: false,
        useCondensedView: false,
        useCompactDensityView: false,
    },
    userTimezone: '',
    loading: false,
};

const userSlice = createAppSlice({
    name: 'user',
    initialState: (): UserState => ({ ...initialState }),
    reducers: (create) => ({
        signedIn: create.reducer<{
            username: string;
            isAdmin: boolean;
            userTimezone: string;
        }>((state, { payload }) => {
            _.assign(
                state,
                _.pick(payload, ['username', 'isAdmin', 'userTimezone'])
            );
        }),
        refreshInfo: create.asyncThunk(() => UserApi.fetchUserInfo(), {
            pending: (state) => {
                state.loading = true;
            },
            rejected: (state) => {
                state.loading = false;
            },
            fulfilled: (state, { payload }) => {
                _.assign(
                    state,
                    _.pick(payload, [
                        'id',
                        'userId',
                        'groups',
                        'permissions',
                        'themeSettings',
                    ])
                );
                state.loading = false;
            },
        }),
    }),
    extraReducers: (create) =>
        create.addDefaultCase(
            (state, action) => legacyUserReducer(state as any, action) as any
        ),
    selectors: {
        isAdmin: (state) => state.isAdmin,
        currentDatasetId: (state): number | undefined => state.group?.datasetId,
    },
});

export const userSelectors = userSlice.selectors;

export const { signedIn: userSignedIn, refreshInfo: refreshUserInfo } =
    userSlice.actions;

export default userSlice.reducer;
