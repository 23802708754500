import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'semantic-ui-react';
import GroupApi from '../../../api/groupApi';
import UserSelect from './UserSelect';

export default function UserSearchPage() {
    const [userGroupsData, setUserGroupsData] = useState(null);
    const [searchError, setSearchError] = useState(undefined);

    const handleUserSelect = (userId) => {
        if (userId) {
            getUserGroupsData(userId);
        }
        setSearchError(undefined);
    };

    async function getUserGroupsData(userId) {
        const userGroupsResult = await GroupApi.getUserGroups(userId);
        if (userGroupsResult && userGroupsResult.length !== 0) {
            setUserGroupsData(userGroupsResult);
        } else {
            setSearchError('User has not been added to any groups');
            setUserGroupsData(null);
        }
    }

    return (
        <div>
            <h2>User Search</h2>
            <div style={{ marginBottom: '15px' }}>
                <Link to="/admin/groups">back to groups</Link>
            </div>
            <SearchForm
                onUserSelect={(id) => handleUserSelect(id)}
                searchError={searchError}
            />
            <SearchResultTable userGroupsData={userGroupsData} />
        </div>
    );
}

function SearchForm(props) {
    const { onUserSelect, searchError } = props;

    return (
        <form id="searchUserGroupsForm" className="ui form">
            <div className="field">
                <label>Name or User ID</label>
            </div>
            <div className="one fields">
                <div
                    className={classNames('sixteen wide field', {
                        error: searchError,
                    })}
                >
                    <UserSelect
                        onUserSelected={(userId) => {
                            onUserSelect(userId);
                        }}
                    />
                </div>
            </div>
            {searchError && (
                <div className="ui pointing label red">{searchError}</div>
            )}
        </form>
    );
}

function SearchResultTable(props) {
    const { userGroupsData } = props;

    const dateFormat = new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'short',
        timeStyle: 'short',
    });

    if (userGroupsData && userGroupsData.length !== 0) {
        const tableRows = userGroupsData.map((g) => {
            return (
                <tr key={g.id}>
                    <td>
                        <Link to={`/admin/groups/${g.id}`}>{g.name}</Link>
                    </td>
                    <td>{g.datasetName}</td>
                    <td>
                        {dateFormat
                            .format(moment(g.groupUser.createdDate))
                            .replace(',', '')}
                    </td>
                    <td>{g.groupUser.createdBy.username}</td>
                    <td style={{ textAlign: 'center' }}>
                        <Checkbox
                            checked={g.groupUser.canWrite}
                            disabled={true}
                        />
                    </td>
                </tr>
            );
        });
        return (
            <>
                <p style={{ marginTop: '15px' }}>
                    {userGroupsData[0].groupUser.fullName}
                </p>
                <table className="ui celled table">
                    <thead>
                        <tr>
                            <th>Group Name</th>
                            <th>Dataset</th>
                            <th>Date Added to Group</th>
                            <th>Who Added</th>
                            <th style={{ width: '10px' }}>Write</th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            </>
        );
    }
    return <div />;
}
