import isString from 'lodash/isString';
import CargoFormatter from './SingleCargoFormatter';
import { checkDatasetIsDirty } from '../../../models/DirtyDatasets';
import { DEFAULT_CARGO_ORIGINAL_NAME } from '../../../constants/default.values';
import { unassignedCgoText } from '../../../constants/gridText';

const MultipleCargoFormatter = ({ node, value, datasetId }) => {
    if (node && node.group && (!value || value.length === 0)) {
        return unassignedCgoText;
    }

    if (!value || value.length === 0) {
        return '?';
    }

    const currentDatasetId =
        node && node.data ? node.data.datasetId : datasetId;

    if (
        currentDatasetId &&
        value.length === 1 &&
        value[0].originalName === DEFAULT_CARGO_ORIGINAL_NAME &&
        checkDatasetIsDirty(currentDatasetId)
    ) {
        return '';
    }

    if (isString(value)) {
        //formatted already
        return value;
    }

    const text = [];

    if (!Array.isArray(value)) {
        text.push(CargoFormatter({ value: value }));
    } else {
        value.forEach((locationPart) => {
            text.push(CargoFormatter({ value: locationPart }));
        });
    }

    return text.join(' ');
};

export default MultipleCargoFormatter;
