import React from 'react';
import ClarksonsBrokerInput from './ClarksonsBrokerInput';
import MultiSelectItem from '_legacy/components/grid/inputs/MultiSelectItem';
import MultiSelectSeparator from '_legacy/components/grid/inputs//MultiSelectSeparator';
import cloneDeep from 'lodash/cloneDeep';
import { KEY_TAB } from '../../../../constants/keyboardCodes';

const TYPE_BROKER = 'broker';
const TYPE_SEPARATOR = 'separator';
const SEPARATOR_PLUS = '+';

class MultipleClarksonsBrokerSelect extends React.Component {
    constructor(props) {
        super(props);
        this.brokerInputRef = React.createRef();
        const parts = [];

        if (this.props.value && this.props.value.length > 0) {
            for (var i = 0; i < this.props.value.length; i++) {
                const part = this.props.value[i];
                this.addPart(parts, part);
            }
        }

        this.state = { parts, initialValue: this.props.initialChar };
    }

    brokerSelected = (part, keyCode, shift) => {
        this.reset();

        this.setState(
            (prevState) => {
                const parts = cloneDeep(prevState.parts);
                const newParts = this.addPart(parts, part);
                return { parts: newParts, initialValue: null };
            },
            () => {
                this.focus();
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;
                    default:
                }
            }
        );
    };

    addPart = (parts, part) => {
        if (part.separator) {
            if (this.canAddSeparator(parts)) {
                parts.push({ type: TYPE_SEPARATOR, value: part });
            }
        } else {
            if (this.isEmpty(parts)) {
                parts.push({ type: this.getTypeFromPart(part), value: part });
            } else {
                this.addDefaultSeparatorIfRequired(parts);
                parts.push({ type: this.getTypeFromPart(part), value: part });
            }
        }
        return parts;
    };

    canAddSeparator = (parts) => {
        return !this.isEmpty(parts) && !this.isLastElementSeparator(parts);
    };

    getTypeFromPart = (part) => {
        if (part.username) {
            return TYPE_BROKER;
        } else {
            return TYPE_SEPARATOR;
        }
    };

    addDefaultSeparatorIfRequired = (parts) => {
        if (!this.isLastElementSeparator(parts)) {
            parts.push({
                type: TYPE_SEPARATOR,
                value: { separator: SEPARATOR_PLUS },
            });
        }
    };

    handleOnTab = () => {
        if (this.props.onTab) {
            this.props.onTab();
        }
    };

    handleOnTabBack = () => {
        if (this.props.onTabBack) {
            this.props.onTabBack();
        }
    };

    handleOnEnter = () => {
        this.props.onEnter();
    };

    isEmpty = (parts) => {
        return parts.length === 0;
    };

    isLastElementSeparator = (parts) => {
        const lastElement = parts.slice(-1)[0];
        if (lastElement) {
            return lastElement.type === TYPE_SEPARATOR;
        }
        return false;
    };

    separatorEntered = (character) => {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            this.addPart(parts, {
                separator: character,
            });
            return { parts };
        });
    };

    handleDelete = () => {
        this.state.parts.pop();

        if (this.isLastElementSeparator(this.state.parts)) {
            this.state.parts.pop();
        }

        this.setState({ parts: this.state.parts });
    };

    get formattedValue() {
        return this.state.parts
            .filter((part) => {
                return part.type === TYPE_BROKER;
            })
            .map((b) => b.value);
    }

    focus = () => {
        this.brokerInputRef.current.focus();
    };

    hasFocus = () => {
        return this.brokerInputRef.current.hasFocus();
    };

    reset = () => {
        if (this.brokerInputRef.current) {
            this.brokerInputRef.current.reset();
        }
    };

    shouldItemBeShown = (item) =>
        !this.state.parts.some((part) => part.value.userId === item.userId);

    render() {
        return (
            <div className="multiSelect">
                <div className="multiSelect-items">
                    {this.state.parts.map((part, index) => {
                        return part.type === TYPE_BROKER ? (
                            <MultiSelectItem
                                key={index}
                                value={part.value.username}
                            />
                        ) : (
                            <MultiSelectSeparator
                                key={index}
                                value={part.value.separator}
                            />
                        );
                    })}
                </div>
                <div className="multiSelect-input-container">
                    <div className="multiSelect-input">
                        <ClarksonsBrokerInput
                            ref={this.brokerInputRef}
                            datasetId={this.props.datasetId}
                            onTab={this.handleOnTab}
                            shouldItemBeShown={this.shouldItemBeShown}
                            onTabBack={this.handleOnTabBack}
                            onEnter={this.handleOnEnter}
                            onBrokerSelected={this.brokerSelected}
                            onSeparatorEntered={this.separatorEntered}
                            onDelete={this.handleDelete}
                            shouldSelectItemOnTab={true}
                            initialChar={this.props.initialChar}
                            onInputCleared={this.reset}
                            seperatorCharacters={[SEPARATOR_PLUS]}
                            blockedCharacters={['/', '?']}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default MultipleClarksonsBrokerSelect;
