import naturalSortComparator from './NaturalSortComparator';

const noTrackingSubstr = 'NO TRACKING INFO';

const voyageActivityComparator = (a, b) => {
    if (a.includes(noTrackingSubstr)) return -1;
    if (b.includes(noTrackingSubstr)) return 1;

    return naturalSortComparator(a, b);
};

export default voyageActivityComparator;
