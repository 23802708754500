import { MultipleCargoQuantityFormatter } from '../formatters/MultipleCargoQuantityFormatter';
import CargoQuantityPartFilterComparator from '../comparators/CargoQuantityPartFilterComparator';

export const CargoQuantityPartsComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }

    const formattedA = MultipleCargoQuantityFormatter({ value: valueA });
    const formattedB = MultipleCargoQuantityFormatter({ value: valueB });

    return CargoQuantityPartFilterComparator(formattedA, formattedB);
};
