import unicodeSortComparator from './UnicodeSortComparator';

const NamePropertyComparator = (valueA, valueB) => {
    const a =
        valueA && valueA.hasOwnProperty('name')
            ? valueA.name || ''
            : valueA || '';
    const b =
        valueB && valueB.hasOwnProperty('name')
            ? valueB.name || ''
            : valueB || '';

    return unicodeSortComparator(a, b);
};

export default NamePropertyComparator;
