import React, { Fragment } from 'react';
import CloseIcon from '../../../../icons/CloseIcon';
import ErrorMessage from '../../ErrorMessage';

/**
 * Action was a request for a non-idempotent*) state-change (or idempotence is
 * unknown) and it is UNKNOWN whether the state-change succeeded.
 *
 * This is the most conservative error message that should be used as a
 * fallback-default when all other checks failed to identify the action type or
 * the error type.
 *
 * *) Non-idempotent action is one where it is NOT KNOWN whether the request
 *    for it can be *repeated automatically* when the success response was not
 *    received (as per [rfc7231#section-4.2.2](https://tools.ietf.org/html/rfc7231#section-4.2.2), as well as [HTTP 1.1](https://tools.ietf.org/html/rfc2616#section-9.1.2))
 */
const WriteUnknownResultErrorCheckBeforeRetryMessage = ({
    resultDescription,
    resolutionToTryBeforeContacting,
    onDismiss,
}) => (
    <ErrorMessage>
        <div style={{ display: 'flex' }}>
            <span style={{ width: '100%' }}>
                <section>
                    <h1>An error occurred</h1>
                    <span>
                        {resultDescription || (
                            <Fragment>
                                Your recent change encountered an error. Either
                                it has not been fulfilled, or there was a
                                failure receiving the success response.
                            </Fragment>
                        )}
                    </span>
                    <span className="resolution">
                        {' '}
                        {resolutionToTryBeforeContacting || (
                            <Fragment>
                                Please check your connection, refresh the page
                                to see if your has taken effect and, if not, try
                                again.
                            </Fragment>
                        )}{' '}
                        Contact support if the problem persists.
                    </span>
                </section>
            </span>
            <CloseIcon
                onClick={onDismiss}
                style={{ cursor: 'default', marginRight: '1em' }}
            />
        </div>
    </ErrorMessage>
);

export default WriteUnknownResultErrorCheckBeforeRetryMessage;
