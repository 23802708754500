import parse from 'html-react-parser';

const BoldTextRenderer = (props) => {
    const boldedText = (originalText, textToBeBold) => {
        const boldRegEx = new RegExp('(' + textToBeBold + ')', 'ig');
        const boldedText = originalText.replace(boldRegEx, '<b>$1</b>');

        return <span>{parse(boldedText)}</span>;
    };

    return <>{boldedText(props.text, props.shouldBeBoldText.trim())}</>;
};

export default BoldTextRenderer;
