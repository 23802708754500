import React, { Component } from 'react';
import ZoneInput from '../inputs/ZoneInput';
import { KEY_TAB, KEY_ENTER } from '../../../constants/keyboardCodes';

class ZoneEditor extends Component {
    constructor(props) {
        super(props);

        this.zoneInputRef = React.createRef();
        this.focus = this.focus.bind(this);
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.onZoneSelected = this.onZoneSelected.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleInputCleared = this.handleInputCleared.bind(this);

        if (this.props.value) {
            this.state = {
                selectedZone: { key: this.props.value, name: this.props.value },
            };
        } else {
            this.state = {};
        }
    }

    afterGuiAttached() {
        this.focus();
    }

    focus() {
        if (this.zoneInputRef.current) {
            this.zoneInputRef.current.focus();
        }
    }

    handleOnTab() {
        this.props.api.tabToNextCell();
    }

    handleOnTabBack() {
        this.props.api.tabToPreviousCell();
    }

    handleOnEnter() {
        this.props.stopEditing();
    }

    getValue() {
        if (this.state.selectedZone) {
            return this.state.selectedZone.name;
        } else {
            return null;
        }
    }

    isPopup() {
        return true;
    }

    onZoneSelected(zone, keyCode, shift) {
        this.setState(
            {
                selectedZone: zone,
            },
            () => {
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;

                    case KEY_ENTER:
                    default:
                        //assume treated as enter, such as mouse click selection
                        this.handleOnEnter();
                        break;
                }
            }
        );
    }

    handleInputCleared() {
        this.setState({ selectedZone: null });
    }

    render() {
        return (
            <ZoneInput
                headerName={this.props.column.colDef.headerName}
                ref={this.zoneInputRef}
                context={this.props.context}
                field={this.props.column.colDef.field}
                value={this.state.selectedZone}
                onTab={this.handleOnTab}
                onTabBack={this.handleOnTabBack}
                onEnter={this.handleOnEnter}
                onZoneSelected={this.onZoneSelected}
                shouldSelectItemOnTab={true}
                inputClass="ag-react-container"
                initialChar={this.props.charPress}
                onInputCleared={this.handleInputCleared}
            />
        );
    }
}

export default ZoneEditor;
