import React, { Component } from 'react';
import '../../../shared/columns/styles/PopupWindowInput.scss';
import './CargoQuantityInput.scss';
import Select from 'react-select';
import units from '../models/CargoQuantityUnits';
import { QuantityParser } from '../../../shared/columns/tools/quantityParser';
import {
    createQuantityPart,
    createSeparatorQuantityPart,
    createStemSizePart,
} from '../models/CargoQuantityPart';
import {
    KEY_BACKSPACE,
    KEY_TAB,
    KEY_ENTER,
    KEY_LEFT,
    KEY_RIGHT,
    KEY_PLUS,
    KEY_FORWARD_SLASH,
    KEY_EQUAL,
    KEY_DOWN,
    KEY_UP,
} from '../../../../constants/keyboardCodes';
import {
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
    ENTITY_PART_TYPE_QUANTITY,
} from '../../../../models/common/EntityPart';
import { quantityFormatterInstance } from '../formatters/quantityFormatter';
import { getStemSizesCombinations } from '../models/StemSizes';
import { getDataset } from '../../../../models/Datasets';
import cloneDeep from 'lodash/cloneDeep';
import { NumberForMultiplyingQty } from '_legacy/constants/CommonConstants';
import { changeFocusOnKeyDown } from '_legacy/services/TrapFocusService';

class CargoQuantityInput extends Component {
    containerId = 'quantity-elements';

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.amountInputRef = React.createRef();

        this.initialStemSizes = getStemSizesCombinations();
        this.isStemSizeAvailable = getDataset(
            props.datasetId
        ).isStemSizeAvailable;
        this.amountInputText = this.isStemSizeAvailable
            ? 'Qty/tolerance, stem size or separator'
            : 'Quantity, tolerance or separator';
        this.initialStemSizeState = {
            stemSizesToDisplay: [],
            stemSizeSelectedIndex: 0,
            stemSizeSelectedRef: null,
        };

        this.parser = new QuantityParser();

        this.state = this.initialState(props);

        this.reset = this.reset.bind(this);

        this.handleAmountChange = this.handleAmountChange.bind(this);
        this.handleUnitChange = this.handleUnitChange.bind(this);

        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);

        this.focus = this.focus.bind(this);
    }

    componentDidMount() {
        const initialChar = this.props.initialChar;

        if (initialChar) {
            if (
                initialChar === SEPARATOR_PLUS ||
                initialChar === SEPARATOR_FORWARD_SLASH
            ) {
                this.handleOnAddSeparator(initialChar);
            } else {
                const initialEvent = {
                    target: {
                        value: initialChar,
                    },
                };
                this.handleAmountChange(initialEvent);
            }
        }
    }

    componentDidUpdate() {
        const { isEditMode, selectedIndex } = this.props;

        if (this.checkIfChangesAreFromUI(isEditMode, selectedIndex)) {
            this.scrollToSelectedStemSize();
            return;
        }
        if (isEditMode === true && selectedIndex !== this.state.selectedIndex) {
            this.updateSelectedQuantity();
        } else {
            // Shows that we deselected selected cargo quantity, so, set state to default
            const state = this.initialState();
            this.setState(state);
            this.focus();
        }
    }

    checkIfChangesAreFromUI = (isEditMode, selectedIndex) => {
        return (
            isEditMode === this.state.isEditMode &&
            selectedIndex === this.state.selectedIndex
        );
    };

    initialState = () => {
        return {
            unit: units[0],
            amount: '',
            isValid: false,
            submitted: false,
            amountObj: null,
            selectedIndex: null,
            selectedCargoQuantity: null,
            isEditMode: false,
            ...this.initialStemSizeState,
        };
    };

    reset() {
        this.setState((state, props) => this.initialState(props));
    }

    focus() {
        if (this.inputRef.current) {
            this.amountInputRef.current.focus();
        }
    }

    scrollToSelectedStemSize = () => {
        if (
            this.isStemSizeDropdownVisible() &&
            this.state.stemSizeSelectedRef &&
            this.state.stemSizeSelectedRef.current
        ) {
            this.state.stemSizeSelectedRef.current.scrollIntoView({
                block: 'center',
            });
        }
    };

    handleAmountChange = (event) => {
        this.setState({ submitted: false });

        if (event.target) {
            const inputValue = event.target.value;
            const parsedAmountObj = this.amountValidation(inputValue);

            if (parsedAmountObj) {
                const isParsedSuccessfull = this.isStemSizeAvailable
                    ? parsedAmountObj.isSuccess
                    : parsedAmountObj.parsedValue !== '';

                if (isParsedSuccessfull) {
                    this.setState({
                        amountObj: parsedAmountObj,
                        amount: parsedAmountObj.parsedValue,
                        isValid: true,
                    });
                } else {
                    if (this.isStemSizeAvailable && inputValue !== '') {
                        const stemSizesToDisplay = this.initialStemSizes
                            .filter((stemSize) =>
                                stemSize.value.startsWith(
                                    inputValue.toUpperCase()
                                )
                            )
                            .map((filtered) => {
                                return {
                                    ...filtered,
                                    isActive: false,
                                    ref: React.createRef(),
                                };
                            });

                        if (stemSizesToDisplay.length > 0) {
                            stemSizesToDisplay[0].isActive = true;
                        }

                        this.setState({
                            amountObj: null,
                            amount: inputValue,
                            isValid: false,
                            stemSizeSelectedIndex: 0,
                            stemSizesToDisplay,
                        });
                    } else {
                        this.setState({
                            amountObj: null,
                            amount: '',
                            isValid: false,
                            stemSizeSelectedIndex: 0,
                            stemSizesToDisplay: [],
                        });
                    }
                }
            }
        }
    };

    handleInputKeyDown = (e) => {
        switch (e.keyCode) {
            case KEY_BACKSPACE:
                break;
            case KEY_TAB:
                if (this.props.onTab && this.props.onTabBack) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleOnAddClick(
                        e,
                        !e.shiftKey ? this.props.onTab : this.props.onTabBack
                    );
                }
                break;
            case KEY_LEFT:
            case KEY_RIGHT:
                changeFocusOnKeyDown(this.containerId, e);
                break;
            case KEY_DOWN:
                if (this.isStemSizeDropdownVisible()) {
                    e.preventDefault();
                    e.stopPropagation();

                    const newSelectedIndex =
                        this.state.stemSizeSelectedIndex + 1;
                    this.stemSizeDropdownNavigation(newSelectedIndex);
                }
                break;
            case KEY_UP:
                if (this.isStemSizeDropdownVisible()) {
                    e.preventDefault();
                    e.stopPropagation();

                    const newSelectedIndex =
                        this.state.stemSizeSelectedIndex - 1;
                    this.stemSizeDropdownNavigation(newSelectedIndex);
                }
                break;
            case KEY_ENTER:
                if (this.amountInputHasFocus()) {
                    if (!this.isStemSizeDropdownVisible()) {
                        this.handleOnAddClick(e);
                    } else {
                        const selectedStemSize =
                            this.state.stemSizesToDisplay[
                                this.state.stemSizeSelectedIndex
                            ];
                        this.handleOnStemSizeItemClick(e, selectedStemSize);
                    }
                }
                break;
            case KEY_EQUAL:
                if (e.shiftKey) {
                    this.handleOnAddSeparator(SEPARATOR_PLUS);
                }
                break;
            case KEY_PLUS:
                this.handleOnAddSeparator(SEPARATOR_PLUS);
                break;
            case KEY_FORWARD_SLASH:
                this.handleOnAddSeparator(SEPARATOR_FORWARD_SLASH);
                break;
            default:
        }
    };

    stemSizeDropdownNavigation = (newSelectedIndex) => {
        const currentSelectedIndex = this.state.stemSizeSelectedIndex;

        if (
            newSelectedIndex >= 0 &&
            newSelectedIndex < this.state.stemSizesToDisplay.length
        ) {
            const newStemSizesToDisplay = cloneDeep(
                this.state.stemSizesToDisplay
            );
            newStemSizesToDisplay[currentSelectedIndex].isActive = false;
            newStemSizesToDisplay[newSelectedIndex].isActive = true;

            this.setState({
                stemSizeSelectedIndex: newSelectedIndex,
                stemSizeSelectedRef:
                    newStemSizesToDisplay[newSelectedIndex].ref,
                stemSizesToDisplay: newStemSizesToDisplay,
            });
        }
    };

    amountValidation = (value) => {
        if (value === '') {
            return { parsedValue: '' };
        }

        if (value === SEPARATOR_PLUS || value === SEPARATOR_FORWARD_SLASH) {
            return null;
        }

        const cleanValue = this.isStemSizeAvailable
            ? value
            : this.parser.clean(value);

        return this.parser.parse(cleanValue);
    };

    validationOnSubmit = (from, to) =>
        from > 0 && !isNaN(to) && to > 0 && to >= from;

    amountInputHasFocus = () =>
        this.amountInputRef.current === document.activeElement;

    handleUnitChange = (newUnit) => {
        this.setState({
            unit: newUnit,
        });
    };

    handleOnAddClick = (e, tabOutAction) => {
        const { from, to } = this.formatAmountValue();
        const isValid = this.validationOnSubmit(from, to);
        this.setState({ isValid: isValid, submitted: true });

        if (isValid) {
            const part = createQuantityPart(
                this.state.unit.value,
                from,
                to,
                this.state.amountObj.tolerance
            );

            this.props.onAddValue(part, tabOutAction);
            this.reset();

            return;
        }

        tabOutAction && tabOutAction();
    };

    formatAmountValue = () => {
        const { from, to } = { ...this.state.amountObj };

        return {
            from: !this.state.isEditMode
                ? this.getCastedNumericalValue(from)
                : from,
            to: !this.state.isEditMode ? this.getCastedNumericalValue(to) : to,
        };
    };

    getCastedNumericalValue = (value) => {
        const numberForFormating = getDataset(
            this.props.datasetId
        ).numberForQuantityFormating;

        const quantityNumber = Number(value);
        if (quantityNumber <= numberForFormating) {
            return quantityNumber * NumberForMultiplyingQty;
        }

        return quantityNumber;
    };

    handleOnStemSizeItemClick = (e, stemSize) => {
        const part = createStemSizePart(stemSize);
        this.props.onAddValue(part);

        this.reset();
    };

    handleOnAddSeparator = (character) => {
        if (this.state.amount === '') {
            const separatorPart = createSeparatorQuantityPart(character);

            const state = this.initialState();
            this.setState(state);

            this.props.onAddSeparator(separatorPart);
        }
    };

    updateSelectedQuantity = () => {
        const {
            isEditMode,
            selectedValue: selectedQuantity,
            selectedIndex,
        } = this.props;

        if (selectedQuantity.partType === ENTITY_PART_TYPE_QUANTITY) {
            const amountAndToleranceString =
                quantityFormatterInstance.formatAmountAndTolerance(
                    selectedQuantity.quantityValue
                );

            const amountObj = selectedQuantity.quantityValue.amount && {
                from: selectedQuantity.quantityValue.amount.from,
                to: selectedQuantity.quantityValue.amount.to,
                tolerance: selectedQuantity.quantityValue.tolerance,
            };

            const unitObj = units.find(
                (unit) => unit.value === selectedQuantity.quantityValue.unit
            );

            this.setState(
                {
                    selectedIndex: selectedIndex,
                    isEditMode: isEditMode,
                    unit: unitObj,
                    amount: amountAndToleranceString,
                    amountObj: amountObj,
                    isValid: true,
                    ...this.initialStemSizeState,
                },
                () => this.focus()
            );
        } else {
            this.setState(
                {
                    selectedIndex: selectedIndex,
                    isEditMode: isEditMode,
                    amount: selectedQuantity.stemSizeValue,
                    amountObj: null,
                    unit: units[0],
                    isValid: false,
                    ...this.initialStemSizeState,
                },
                () => this.focus()
            );
        }
    };

    isStemSizeDropdownVisible = () => {
        return (
            this.isStemSizeAvailable && this.state.stemSizesToDisplay.length > 0
        );
    };

    render() {
        const StemSizeDropdown = React.forwardRef(() => (
            <div className="stem-sizes-dropdown">
                {this.state.stemSizesToDisplay.map((stemSize) => (
                    <div
                        key={stemSize.id}
                        ref={stemSize.ref}
                        onClick={(e) =>
                            this.handleOnStemSizeItemClick(e, stemSize)
                        }
                        className={
                            stemSize.isActive
                                ? 'stem-sizes-dropdown-item-selected'
                                : ''
                        }
                    >
                        {stemSize.value}
                    </div>
                ))}
            </div>
        ));

        return (
            <div
                id={this.containerId}
                ref={this.inputRef}
                onKeyDown={this.handleInputKeyDown}
            >
                <div className="first-inline-item">
                    <input
                        type="text"
                        ref={this.amountInputRef}
                        className="quantity-amount-input"
                        onChange={this.handleAmountChange}
                        value={this.state.amount}
                        title={this.amountInputText}
                        placeholder={this.amountInputText}
                        style={
                            !this.state.isValid && this.state.submitted
                                ? {
                                      boxShadow: '0 0 0 1px red',
                                      color: 'red',
                                  }
                                : {}
                        }
                    />
                    {!this.state.isValid && this.state.submitted && (
                        <p style={{ color: 'red' }}>Quantity is invalid</p>
                    )}
                </div>
                <div className="inline" style={{ width: '100px' }}>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={false}
                        options={units}
                        onChange={this.handleUnitChange}
                        value={this.state.unit}
                        openMenuOnFocus={true}
                        menuPlacement="auto"
                    />
                </div>
                {this.isStemSizeDropdownVisible() ? <StemSizeDropdown /> : null}
                <div className="inline capture-button" title="Capture Quantity">
                    <button
                        name="add-quantity"
                        className="ui primary button"
                        onClick={this.handleOnAddClick}
                        disabled={!this.state.isValid}
                    >
                        +
                    </button>
                </div>
            </div>
        );
    }
}

export default CargoQuantityInput;
