import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';
import isString from 'lodash/isString';
import FortCellEditor from '../../../shared/columns/editors/FortCellEditor';

const YobEditor = forwardRef((props, ref) => {
    const defaultInpunLength = 4;
    const inputRef = useRef();

    const [inputValue, setInputValue] = useState(
        props.charPress ?? props.value ?? ''
    );

    const validationRegex = new RegExp(
        `^((?!(0))[0-9]{0,${defaultInpunLength}})$`
    );

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        const isConsistError = !validationRegex.test(value);

        setInputValue(isConsistError ? inputValue : value);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                if (
                    inputValue === '' ||
                    (isString(inputValue) &&
                        inputValue.length !== defaultInpunLength)
                ) {
                    return null;
                }

                return Number(inputValue);
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    return (
        <FortCellEditor focus={focus} {...props}>
            <>
                <input
                    type="text"
                    noValidate
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleChange}
                />
            </>
        </FortCellEditor>
    );
});

export default YobEditor;
