import { defaultDirection } from './DirectionServiceFactory';
import {
    allDirections,
    indonesiaCountryId,
    indonesiaSpecificDirectionMapping,
    indonesiaSpecificDischargeZones,
    mappingHashTable,
} from '_legacy/models/ZonesSupramaxDirectionsMapping';

class SupramaxDirectionLogicService {
    calculateDirection = (loadZone, dischargeZone, loadLocationParts) => {
        if (!loadZone || !dischargeZone) {
            return defaultDirection;
        }

        const loadLocation = loadLocationParts && loadLocationParts[0];
        if (
            loadLocation?.countryId === indonesiaCountryId &&
            indonesiaSpecificDischargeZones.includes(dischargeZone)
        ) {
            return indonesiaSpecificDirectionMapping.toUpperCase();
        }

        if (this.isConsistWorldwideZone(loadZone, dischargeZone)) {
            return 'OTHER';
        }

        return this.findDirection(loadZone, dischargeZone).toUpperCase();
    };

    getAllDirections = () => {
        return allDirections;
    };

    isConsistWorldwideZone = (loadZone, dischargeZone) => {
        return loadZone === 'WW' || dischargeZone === 'WW';
    };

    findDirection = (load, discharge) => {
        const key = `${load}|${discharge}`;
        return mappingHashTable.get(key) ?? '';
    };
}

export default SupramaxDirectionLogicService;
