import { DATASET_CLEAN_LONDON } from '_legacy/models/Datasets';

export const DefaultFixtureStatusMapping = [
    { id: 'S', gridDisplay: 'S', selectDisplay: 'S - ON SUBS' },
    { id: 'C', gridDisplay: 'C', selectDisplay: 'C - CONFIRMED' },
    { id: 'X', gridDisplay: 'X', selectDisplay: 'X - CANCELLED' },
    { id: 'F', gridDisplay: 'F', selectDisplay: 'F - FAILED' },
    { id: 'H', gridDisplay: 'H', selectDisplay: 'H - HOLD' },
];

const CustomFixtureStatusMapping = {
    [DATASET_CLEAN_LONDON]: [
        { id: 'S', gridDisplay: 'SUB', selectDisplay: 'SUB - ON SUBS' },
        { id: 'C', gridDisplay: 'FXD', selectDisplay: 'FXD - FIXED' },
        { id: 'X', gridDisplay: 'CAN', selectDisplay: 'CAN - CANCELLED' },
        { id: 'F', gridDisplay: 'FLD', selectDisplay: 'FLD - FAILED' },
        { id: 'H', gridDisplay: 'HLD', selectDisplay: 'HLD - HOLD' },
    ],
};

export const FetchMappingSelectDisplayValue = (value, datasetId) => {
    const mapping = FetchMapping(value, datasetId);

    if (mapping) {
        return mapping.selectDisplay;
    }
    return value;
};

export const FetchMappingGridDisplayValue = (value, datasetId) => {
    const mapping = FetchMapping(value, datasetId);

    if (mapping) {
        return mapping.gridDisplay;
    }
    return value;
};

const FetchMapping = (value, datasetId) => {
    const currentFixtureStatusMapping =
        CustomFixtureStatusMapping.hasOwnProperty(datasetId)
            ? CustomFixtureStatusMapping[datasetId]
            : DefaultFixtureStatusMapping;
    const searchMapping = currentFixtureStatusMapping.filter(
        (m) => m.id === value
    );
    if (searchMapping.length === 1) {
        return searchMapping[0];
    }
    return undefined;
};
