import { blankKeyText } from '_legacy/constants/gridText';
import BooleanFormatter from '../formatters/BooleanFormatter';

const BooleanKeyCreator = ({ value }) => {
    if (value === null) {
        return blankKeyText;
    }

    return BooleanFormatter({ value });
};

export default BooleanKeyCreator;
