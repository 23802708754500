import React from 'react';
import ChevronDownSvg from './ChevronDown.svg';

const ChevronDownIcon = (props) => (
    <img
        src={ChevronDownSvg}
        {...props}
        style={{ opacity: '0.7' }}
        alt="Chevron Right Icon"
    />
);

export default ChevronDownIcon;
