import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../actions/userActions';
import * as versionActions from '../actions/versionActions';
import graphQlClient from '../api/graphQlClient';
import { KEEP_ALIVE_SUBSCRIPTION } from '../api/subscriptions/keepAliveSubscription';
import InfoMessage from './ui/messages/InfoMessage';
import ErrorMessage from './ui/messages/ErrorMessage';
import logger from '../diagnostics/logging/logger';
import VersionUpdatedMessage from './ui/messages/VersionUpdatedMessage';

class GlobalSubscriptions extends Component {
    constructor(props) {
        super(props);

        this.currentVersion = this.props.version;
        this.handleKeepAlive = this.handleKeepAlive.bind(this);
    }

    componentDidMount() {
        this.keepAliveSubscription = graphQlClient()
            .subscribe({
                query: KEEP_ALIVE_SUBSCRIPTION,
                fetchPolicy: 'no-cache',
            })
            .subscribe({
                next: this.handleKeepAlive,
                error(err) {
                    logger.warn(
                        'GLOBAL KEEP ALIVE SUBSCRIPTION ERROR' + err.message
                    );
                },
            });
    }

    shouldComponentUpdate(props) {
        this.versionChanged =
            this.currentVersion !== null &&
            this.currentVersion !== props.version;
        this.currentVersion = props.version;
        return true;
    }

    handleKeepAlive(message) {
        const {
            actions: { versionReceived },
        } = this.props;

        versionReceived(message.data.keepAlive.version);
    }

    reloadPage() {
        window.location.reload(true);
    }

    render() {
        if (this.versionChanged) {
            console.log(
                'Version changed from ' +
                    this.currentVersion +
                    ' to ' +
                    this.props.version
            ); // We don't log to appInsights because this is per user, log to console for informational purposes.
            return <VersionUpdatedMessage version={this.currentVersion} />;
        } else {
            if (this.props.hasDisconnected) {
                return (
                    <ErrorMessage>
                        Live Updates: Connection Lost. You are not currently
                        able to receive live updates from your colleagues
                    </ErrorMessage>
                );
            } else if (this.props.hasReconnected) {
                return (
                    <InfoMessage>
                        Live Updates: Connection Restored. To ensure your orders
                        and fixtures are up to date please refresh your browser
                        by clicking{' '}
                        <span className="linkButton" onClick={this.reloadPage}>
                            here
                        </span>
                    </InfoMessage>
                );
            }
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        hasDisconnected: state.status.hasSocketDisconnected,
        hasReconnected: state.status.hasSocketReconnected,
        version: state.version,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            { ...userActions, ...versionActions },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalSubscriptions);
