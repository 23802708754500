const ImoClassFormatter = ({ value }) => {
    if (!value || (!value.imoGradeI && !value.imoGradeII && !value.imoGradeIII))
        return '';

    const values = [];

    if (value.imoGradeI) values.push('I');
    if (value.imoGradeII) values.push('II');
    if (value.imoGradeIII) values.push('III');

    return values.join('/');
};

export default ImoClassFormatter;
