import isString from 'lodash/isString';

const UpperFormatter = (params) => {
    const {
        node,
        value,
        column: { rowGroupActive },
    } = params;

    if (node && node.group && !rowGroupActive) {
        return '';
    }

    if (value === null || value === undefined) {
        return '';
    }

    return UpperValueFormatter(value);
};

export const UpperValueFormatter = (value) => {
    if (isString(value)) {
        return value.toUpperCase();
    }

    return value;
};

export default UpperFormatter;
