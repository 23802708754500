import React, { Component } from 'react';
import moment from 'moment';

class DateInput extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.inputRef = React.createRef();

        const { value, initialChar } = this.props;
        let inputValue = '';
        if (initialChar) {
            inputValue = initialChar;
        } else {
            inputValue = this.props.formatValue(value);
        }

        this.state = {
            inputValue,
        };
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
            if (!this.props.initialChar) {
                this.inputRef.current.select();
            }
        }
    }

    get date() {
        const { inputValue } = this.state;

        let data = inputValue;

        if (data) {
            data = data.trim();
            data = data.replace(/\//g, '-');
            data = data.replace(/ /g, '-');
        }

        const mom = this.parseDate(data);
        let formattedValue = null;

        let isValid = mom.isValid();

        if (isValid) {
            formattedValue = mom.toDate();
        }

        return formattedValue;
    }

    parseDate(value) {
        const mom = moment.utc(
            value,
            [
                'D/M',
                'D/MMM',
                'D-M',
                'D-MMM',
                'D-M-YY',
                'D-M-YYYY',
                'D-MMM-YY',
                'D-MMM-YYYY',
                'D-MMMM-YY',
                'D-MMMM-YYYY',
                'D-MM',
                'DD-MM-YY',
            ],
            'en',
            true
        );

        return mom;
    }

    handleChange(event) {
        let {
            target: { value },
        } = event;

        this.setState({
            inputValue: value,
        });
    }

    render() {
        const { inputValue } = this.state;
        return (
            <input
                type="text"
                style={{ width: '100%' }}
                data-test="input"
                onChange={this.handleChange}
                value={inputValue}
                ref={this.inputRef}
            />
        );
    }
}

export default DateInput;
