import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import MenuItem from '../ui/MenuItem';
import './SwitchGroup.scss';
import { KEY_ESCAPE } from '../../constants/keyboardCodes';
import GlobalShortcut from '../GlobalShortcut';
import ToolbarButton from '../../components/toolbar/ToolbarButton';

export class SwitchGroup extends Component {
    constructor(props, context) {
        super(props, context);
        this.onClick = this.onClick.bind(this);
        this.handleMenuOnClick = this.handleMenuOnClick.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.state = { menuOpen: false };
    }

    onClick(e, group) {
        e.preventDefault();
        e.stopPropagation();
        this.props.actions.groupSet(group);
        this.props.onChange();
        this.setState({ menuOpen: false });
    }

    handleMenuOnClick(e) {
        this.setState({ menuOpen: !this.state.menuOpen });
    }

    closeMenu() {
        this.setState({ menuOpen: false });
    }

    onEscape(event) {
        return event.keyCode === KEY_ESCAPE;
    }

    render() {
        const { groups } = this.props;

        if (groups && groups.length > 1) {
            const currentGroups = groups.filter(
                (g) => g.id === this.props.currentGroupId
            );

            if (currentGroups.length === 0) {
                return null;
            }

            const currentGroup = currentGroups[0];

            var submenu = null;

            if (this.state.menuOpen) {
                submenu = (
                    <GlobalShortcut
                        onMatch={this.closeMenu}
                        keyMatcher={this.onEscape}
                    >
                        <ul className="switchgroup-dropdown">
                            {groups.map((g) => (
                                <li
                                    key={g.id}
                                    onClick={(e) => this.onClick(e, g)}
                                >
                                    {g.name}
                                </li>
                            ))}
                        </ul>
                    </GlobalShortcut>
                );
            }

            return (
                <ToolbarButton
                    onClick={this.handleMenuOnClick}
                    style={{ padding: '10px' }}
                >
                    {currentGroup.name} {submenu}
                </ToolbarButton>
            );
        } else if (groups && groups.length === 0) {
            return (
                <MenuItem>
                    <div className="ui negative large fluid message">
                        <div className="header">
                            You have not been assigned a group, please contact{' '}
                            <a href="mailto:support@clarksons.com">
                                support@clarksons.com
                            </a>{' '}
                            to be assigned a group.
                            {this.props.isAdmin && (
                                <div style={{ float: 'right' }}>
                                    <a href="/admin/groups">
                                        Click here to navigate to the Admin page
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </MenuItem>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    return {
        currentGroupId:
            state.user && state.user.group ? state.user.group.id : null,
        groups: state.user && state.user.groups ? state.user.groups : null,
        permissions:
            state.user && state.user.permissions
                ? state.user.permissions
                : null,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchGroup);
