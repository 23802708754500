import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';
import { DURATION_VALUE_FRAGMENT } from './durationValueFragment';

const FRAGMENT = loader('./durationPartFragment.gql');

export const DURATION_PART_TYPE_NAME = 'DurationPart';
export const DURATION_PART_FRAGMENT_NAME = 'DurationPartFragment';
export const DURATION_PART_FRAGMENT = gql`
    ${FRAGMENT}
    ${DURATION_VALUE_FRAGMENT}
`;
