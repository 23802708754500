//TODO : need to create separate isolation component to reduce duplication with MultipleLocationSelect component

import React from 'react';
import CargoInput from './CargoInput';
import MultiSelectSeparator from './MultiSelectSeparator';
import DraggableMultiSelectItem from './DraggableMultiSelectItem';
import DroppableMultiSelectSpace from './DroppableMultiSelectSpace';
import cloneDeep from 'lodash/cloneDeep';
import { KEY_TAB, KEY_ENTER } from '../../../constants/keyboardCodes';
import {
    ENTITY_PART_TYPE_KNOWN,
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_SEPARATOR,
    ENTITY_PART_TYPE_PLACEHOLDER,
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
    ENTITY_SEPARATOR_CHARACTERS,
    DIRECTION_FORWARD,
    DIRECTION_BACKWARD,
    addPart,
    deleteItemAtPosition,
    normalizeParts,
    dragAndDropPart,
} from '../../../models/common/EntityPart';
import {
    createKnownCargoPart,
    createCustomCargoPart,
    createSeparatorCargoPart,
    createPlaceholderCargoPart,
} from '../../../models/CargoPart';
import { getDataset } from '../../../models/Datasets';

class MultipleCargoSelect extends React.Component {
    constructor(props) {
        super(props);

        this.knownCargoSelected = this.knownCargoSelected.bind(this);
        this.customCargoSelected = this.customCargoSelected.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.separatorEntered = this.separatorEntered.bind(this);
        this.cargoSelect = React.createRef();
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.handleRemovedItem = this.handleRemovedItem.bind(this);
        this.moveCursorLeft = this.moveCursorLeft.bind(this);
        this.moveCursorRight = this.moveCursorRight.bind(this);
        this.switchSeparator = this.switchSeparator.bind(this);
        this.onSetCursorPosition = this.onSetCursorPosition.bind(this);
        this.onDraggedItem = this.onDraggedItem.bind(this);

        this.defaultSeperatorCharacter =
            this.props.defaultSeperatorCharacter ||
            ENTITY_SEPARATOR_CHARACTERS[0];
        const parts = this.initializeParts();

        this.state = {
            parts,
            initialValue: this.props.initialChar,
            cursorPos: parts.length,
        };
    }

    initializeParts() {
        let parts = [];

        if (this.props.value) {
            for (const part of this.props.value) {
                const result = addPart(
                    parts,
                    part,
                    undefined,
                    createSeparatorCargoPart,
                    this.defaultSeperatorCharacter
                );
                parts = result.parts;
            }
        }

        return parts;
    }

    knownCargoSelected(cargo, keyCode, shift) {
        this.cargoSelected(cargo, keyCode, shift, createKnownCargoPart);
    }

    customCargoSelected(cargo, keyCode, shift) {
        this.cargoSelected(cargo, keyCode, shift, createCustomCargoPart);
    }

    cargoSelected(cargo, keyCode, shift, createCargoPart) {
        this.setState(
            (prevState) => {
                const parts = cloneDeep(prevState.parts);
                const part = createCargoPart(cargo);
                const { parts: newParts, cursorPos: newPosition } = addPart(
                    parts,
                    part,
                    prevState.cursorPos,
                    createSeparatorCargoPart,
                    this.defaultSeperatorCharacter
                );
                return {
                    parts: newParts,
                    cursorPos: newPosition,
                    initialValue: null,
                };
            },
            () => {
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;

                    case KEY_ENTER:
                        this.handleOnEnter();
                        break;
                    default:
                }
                this.focus();
            }
        );
    }

    separatorEntered(character) {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            const part = createSeparatorCargoPart(character);
            const newState = addPart(
                parts,
                part,
                prevState.cursorPos,
                createSeparatorCargoPart,
                this.defaultSeperatorCharacter
            );
            return newState;
        }, this.focus);
    }

    placeholderEntered = (character) => {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            const part = createPlaceholderCargoPart(character);
            const newState = addPart(
                parts,
                part,
                prevState.cursorPos,
                createSeparatorCargoPart
            );
            return newState;
        }, this.focus);
    };

    handleOnTab() {
        if (this.props.onTab) {
            this.setState((prevState) => {
                return this.normalizeParts(prevState);
            }, this.props.onTab);
        }
    }

    handleOnTabBack() {
        if (this.props.onTabBack) {
            this.setState((prevState) => {
                return this.normalizeParts(prevState);
            }, this.props.onTabBack);
        }
    }

    handleOnEnter() {
        this.setState((prevState) => {
            return this.normalizeParts(prevState);
        }, this.props.onEnter);
    }

    normalizeParts(prevState) {
        const parts = cloneDeep(prevState.parts);
        return {
            parts: normalizeParts(
                parts,
                createSeparatorCargoPart,
                this.defaultSeperatorCharacter
            ),
        };
    }

    deleteAtPosition(parts, position, direction) {
        let nextPosition =
            direction === DIRECTION_BACKWARD ? position - 1 : position;

        ({ parts } = deleteItemAtPosition(parts, nextPosition, direction));

        nextPosition =
            direction === DIRECTION_BACKWARD
                ? Math.max(position - 1, 0)
                : position;

        return { parts, cursorPos: nextPosition };
    }

    moveCursorLeft() {
        const newPosition = this.state.cursorPos - 1;
        const nextPosition = newPosition >= 0 ? newPosition : 0;
        this.setState({ cursorPos: nextPosition }, this.focus);
    }

    moveCursorRight() {
        const newPosition =
            this.state.cursorPos === 0 ? 1 : this.state.cursorPos + 1;
        const nextPosition =
            newPosition <= this.state.parts.length
                ? newPosition
                : this.state.parts.length;
        this.setState({ cursorPos: nextPosition }, this.focus);
    }

    handleDelete(direction) {
        let parts = cloneDeep(this.state.parts);
        let position = this.state.cursorPos;

        const newState = this.deleteAtPosition(parts, position, direction);

        this.setState(
            {
                parts: newState.parts,
                cursorPos: newState.cursorPos,
            },
            this.focus
        );
    }

    handleRemovedItem(index) {
        let parts = cloneDeep(this.state.parts);
        let position = index;

        const newState = this.deleteAtPosition(
            parts,
            position,
            DIRECTION_FORWARD
        );

        this.setState(
            {
                parts: newState.parts,
                cursorPos: newState.cursorPos,
            },
            this.focus
        );
    }

    get formattedValue() {
        return this.state.parts.map((part) => {
            return {
                value: part.value,
                name: part.name,
                partType: part.partType,
            };
        });
    }

    focus() {
        if (this.cargoSelect.current != null) {
            this.cargoSelect.current.focus();
        }
    }

    hasFocus() {
        return this.cargoSelect.current.hasFocus();
    }

    switchSeparator(index) {
        this.setState((prevState) => {
            let parts = cloneDeep(prevState.parts);
            const separator = parts[index];

            const newSeparator =
                separator.value === SEPARATOR_FORWARD_SLASH
                    ? createSeparatorCargoPart(SEPARATOR_PLUS)
                    : createSeparatorCargoPart(SEPARATOR_FORWARD_SLASH);

            parts.splice(index, 1, newSeparator);

            return { parts };
        });
    }

    onSetCursorPosition(index) {
        this.setState({ cursorPos: index }, this.focus);
    }

    onDraggedItem(sourceIndex, targetIndex) {
        this.setState((prevState) => {
            const newParts = dragAndDropPart(
                prevState.parts,
                sourceIndex,
                targetIndex
            );

            const normalized = normalizeParts(
                newParts,
                createSeparatorCargoPart,
                this.defaultSeperatorCharacter
            );

            return { parts: normalized, cursorPos: normalized.length };
        });
    }

    render() {
        const { className } = this.props;
        const isLastPosition = this.state.parts.length === this.state.cursorPos;

        const trailingSpace = (index) => (
            <DroppableMultiSelectSpace
                index={index}
                key="trailingSpace"
                onSetCursorPosition={this.onSetCursorPosition}
                onDraggedItem={this.onDraggedItem}
            />
        );

        const items = this.state.parts.map((part, index) => {
            const cargoName =
                part.partType === ENTITY_PART_TYPE_KNOWN
                    ? part.name
                    : part.value;

            switch (part.partType) {
                case ENTITY_PART_TYPE_KNOWN:
                    return (
                        <DraggableMultiSelectItem
                            key={index}
                            index={index}
                            value={cargoName}
                            onRemovedItem={this.handleRemovedItem}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            pill
                            tabIndex="-1"
                        />
                    );
                case ENTITY_PART_TYPE_CUSTOM:
                    return (
                        <DraggableMultiSelectItem
                            key={index}
                            index={index}
                            value={cargoName}
                            onRemovedItem={this.handleRemovedItem}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            pill
                            tabIndex="-1"
                        />
                    );
                case ENTITY_PART_TYPE_SEPARATOR:
                    return (
                        <MultiSelectSeparator
                            key={index}
                            index={index}
                            value={cargoName}
                            onClick={() => this.switchSeparator(index)}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            onRemovedItem={this.handleRemovedItem}
                            pill
                        />
                    );
                case ENTITY_PART_TYPE_PLACEHOLDER:
                    return (
                        <MultiSelectSeparator
                            key={index}
                            index={index}
                            value={cargoName}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            onRemovedItem={this.handleRemovedItem}
                            pill
                        />
                    );
                default:
                    throw new Error(
                        `Unknown part type specified: ${part.partType}`
                    );
            }
        });

        const cursor = (
            <div className="multipleLocationSelect-input-container" key="input">
                <div className="multipleLocationSelect-input">
                    <CargoInput
                        ref={this.cargoSelect}
                        context={this.props.context}
                        field={this.props.field}
                        onKnownCargoSelected={this.knownCargoSelected}
                        onCustomCargoSelected={this.customCargoSelected}
                        seperatorCharacters={ENTITY_SEPARATOR_CHARACTERS}
                        blockedCharacters={this.props.blockedCharacters}
                        placeholderTerms={
                            getDataset(this.props.context.datasetId)
                                .allowedPlaceholderCargoes
                        }
                        onSeparatorEntered={this.separatorEntered}
                        onPlaceholderEntered={this.placeholderEntered}
                        onChange={this.props.onChange}
                        onDelete={this.handleDelete}
                        inputClass={this.props.inputClass}
                        onTab={this.handleOnTab}
                        onTabBack={this.handleOnTabBack}
                        onEnter={this.handleOnEnter}
                        initialChar={this.state.initialValue}
                        cursorPos={this.state.cursorPos}
                        onMoveLeft={this.moveCursorLeft}
                        onMoveRight={this.moveCursorRight}
                        isLastPosition={isLastPosition}
                        groupId={this.props.groupId}
                    />
                </div>
            </div>
        );

        items.splice(this.state.cursorPos, 0, cursor);
        if (this.state.cursorPos !== this.state.parts.length) {
            items.push(trailingSpace(this.state.parts.length));
        }

        return (
            <div className={`multiple-location-select ${className}`}>
                <div className="multiple-location-select-items">{items}</div>
            </div>
        );
    }
}

export default MultipleCargoSelect;
