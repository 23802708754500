import Logger from '../../../diagnostics/logging/logger';

export const createVoyageActivityMenuItem = (props, gridApi, node, gridId) => {
    const { group, data } = node;

    const title = 'Voyage History';
    const tooltip = 'View Voyage History';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.openVoyageActivity', gridId);

                gridApi.deselectAll();
                node.setSelected(true);
                props.onOpenVoyageActivity();
            }
        },
        tooltip: tooltip,
    };
};
