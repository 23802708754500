import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';
import { RATE_VALUE_FRAGMENT } from './rateValueFragment';

const FRAGMENT = loader('./ratePartFragment.gql');

export const RATE_PART_TYPE_NAME = 'RatePart';
export const RATE_PART_FRAGMENT_NAME = 'RatePartFragment';
export const RATE_PART_FRAGMENT = gql`
    ${FRAGMENT}
    ${RATE_VALUE_FRAGMENT}
`;
