import { getDefaultOrdersRowGroupsSorting } from '../services/RowGroupsSortingService';
import { getDataset } from '../models/Datasets';
import isEqual from 'lodash/isEqual';
import DataSizeOptions from '../data/dataSizeOptions';
import MixpanelLogger from '../diagnostics/logging/mixpanelLogger';

export const sendSaveLayoutMixpanelEvent = (
    layoutName,
    gridType,
    layoutItems,
    localGridOptions,
    directionLogic,
    quantityFormat,
    exportColIdsToSkip,
    datasetId
) => {
    const layoutProperties = getSavedLayoutPropertiesForMixpanel(
        layoutItems,
        exportColIdsToSkip,
        gridType,
        directionLogic,
        quantityFormat,
        datasetId,
        localGridOptions
    );
    MixpanelLogger.trackSaveLayoutEvent(layoutName, layoutProperties);
};

export const getSavedLayoutPropertiesForMixpanel = (
    layoutItems,
    colIdsToSkip,
    layoutType,
    directionLogic,
    quantityFormat,
    datasetId,
    localGridOptions
) => {
    const originalLayoutItemsOptions = layoutItems.currentOptions;

    const layoutItemsObject = {
        ...originalLayoutItemsOptions,
        columnState: originalLayoutItemsOptions.columnOptions,
        filters: originalLayoutItemsOptions.filterOptions,
    };

    return getLayoutPropertiesForMixpanel(
        layoutItemsObject,
        colIdsToSkip,
        layoutType,
        directionLogic,
        quantityFormat,
        datasetId,
        localGridOptions
    );
};

export const getCreatedLayoutPropertiesForMixpanel = (
    layoutItems,
    colIdsToSkip,
    layoutType,
    directionLogic,
    quantityFormat,
    datasetId,
    gridOptions
) => {
    const layoutItemsObject = JSON.parse(layoutItems);

    return getLayoutPropertiesForMixpanel(
        layoutItemsObject,
        colIdsToSkip,
        layoutType,
        directionLogic,
        quantityFormat,
        datasetId,
        gridOptions
    );
};

const calculateNumberOfGroupsWithUserDefinedOrdering = (
    rowGroupsSorting,
    datasetId,
    layoutType,
    directionLogic
) => {
    if (layoutType === 'Fixture') return 'Not specified';

    const defaultRowGroupsSorting = getDefaultOrdersRowGroupsSorting(
        datasetId,
        directionLogic
    );
    let numberOfGroupsWithUserDefinedOrdering = 0;

    for (const rowGroupSorting of rowGroupsSorting) {
        const defaultRowGroupSorting = defaultRowGroupsSorting.find(
            (rgs) => rgs.colId === rowGroupSorting.colId
        );

        if (
            defaultRowGroupSorting &&
            !isEqual(defaultRowGroupSorting.sorting, rowGroupSorting.sorting)
        ) {
            numberOfGroupsWithUserDefinedOrdering++;
        }
    }

    return numberOfGroupsWithUserDefinedOrdering;
};

const getLayoutPropertiesForMixpanel = (
    layoutItemsObject,
    colIdsToSkip,
    layoutType,
    directionLogic,
    quantityFormat,
    datasetId,
    localGridOptions
) => {
    const {
        columnState,
        filters,
        shouldHighlightNewOrders,
        shouldShowConvertedOrders,
        rowGroupsSorting,
        shouldOnlyDisplayRumouredFixtures,
        shouldOnlyDisplayRumouredOrders,
    } = layoutItemsObject;

    const visibleColumns = columnState.filter(
        (cs) => cs.hide === false && !colIdsToSkip.includes(cs.colId)
    );
    const appliedSortings = columnState.filter((cs) => cs.sort !== null);
    const groupingsApplied = columnState.filter((cs) => cs.rowGroup === true);

    const dataset = getDataset(datasetId);
    const shouldDirectionBeSpecified = !dataset.nonAvailableFields.some(
        (field) => field === 'direction'
    );

    const { showDeleted, showDeletedAndWithdrawn, selectedNumberOfYears } =
        localGridOptions;

    const getShowRumouredOnly = () => {
        if (layoutType === 'Fixture') {
            return shouldOnlyDisplayRumouredFixtures ?? 'Not specified';
        }
        return shouldOnlyDisplayRumouredOrders ?? 'Not specified';
    };

    const getDisplayingPeriod = () => {
        if (selectedNumberOfYears !== undefined) {
            const foundItem = DataSizeOptions.find(
                (item) => item.value === selectedNumberOfYears
            );
            return foundItem.label;
        }
        return 'Not specified';
    };

    return {
        noOfFilters: Object.keys(filters).length,
        noOfSortsApplied: appliedSortings.length,
        noOfColumns: visibleColumns.length,
        noOfUserDefinedOrderingOnGroups:
            calculateNumberOfGroupsWithUserDefinedOrdering(
                rowGroupsSorting,
                datasetId,
                layoutType,
                directionLogic
            ),
        showDeletedAndWithdrawn: showDeletedAndWithdrawn ?? 'Not specified',
        showRumouredOnly: getShowRumouredOnly(),
        showDeleted: showDeleted ?? 'Not specified',
        displayingByTime: getDisplayingPeriod(),
        highlightNewOrders: shouldHighlightNewOrders ?? 'Not specified',
        showConvertedOrders: shouldShowConvertedOrders ?? 'Not specified',
        noOfGroupingsApplied: groupingsApplied.length,
        layoutType,
        directionLogicChoice: shouldDirectionBeSpecified
            ? directionLogic
            : 'Not specified',
        quantityFormatChoice: quantityFormat,
    };
};
