import EntityNodeTree from '_legacy/tools/EntityNodeTree';
import { PNR } from '../../../shared/columns/models/DurationUnits';

class DurationNodeTree extends EntityNodeTree {
    initializeNodes(durations) {
        super.initializeNodes(durations);
        this.initializeDurations(durations);
    }

    initializeDurations(durations) {
        if (durations) {
            const sortedDurations = durations.sort((a, b) => {
                if (a.key === PNR && b.key === PNR) return 0;
                if (a.key === PNR) return 1;
                if (b.key === PNR) return -1;

                return a.averageValue > b.averageValue ? 1 : -1;
            });

            for (const duration of sortedDurations) {
                let parent = this.getNode(duration.parentNodeKey);

                if (!parent) {
                    parent = this.pushParentNode(duration.parentNodeKey);
                }

                this.pushLeafNode(duration.key, duration.key, parent);
            }
        }
    }
}

DurationNodeTree.States = EntityNodeTree.States;
DurationNodeTree.RootNodeKey = EntityNodeTree.RootNodeKey;

export default DurationNodeTree;
