const isOwnProgramEntity = (entity) =>
    entity &&
    ((entity.rate && entity.rate.toUpperCase().indexOf('O/P') >= 0) ||
        (entity.rates &&
            entity.rates.rateParts &&
            entity.rates.rateParts.find(
                (part) => part.rateValue?.flatRate?.unit === 'O/P'
            )));

export default isOwnProgramEntity;
