import _ from 'lodash';
import {
    LayoutGridConfigMap,
    SavedLayoutModel,
    SelectedLayoutModel,
} from './models';
import { LayoutsState } from './models';
import { getSelectedLayoutOptions } from '_legacy/actions/utils';

export module LayoutGridUpdateUtils {
    /**
     *
     * @param sbj
     * @param input
     * @param prop
     * @returns undefined if setting was not changed, true if setting was changed and has diff, false otherwise
     */
    function updateSettingIfChanged<
        P extends keyof C,
        C extends Record<string, any>
    >(
        sbj: {
            currentOptions: C;
            initialOptions: C;
            hasRecentChanges: boolean;
        },
        input: Partial<C>,
        prop: P
    ): void {
        if (
            input[prop] !== undefined &&
            !_.isEqual(sbj.currentOptions[prop], input[prop])
        ) {
            sbj.currentOptions[prop] = input[prop]!!;
            sbj.hasRecentChanges = true;
        }
    }

    export function processFixturesChanges(
        current: SelectedLayoutModel,
        input: Partial<LayoutGridConfigMap['fixtures']>
    ): void {
        const currentOptions = current.fixtures;

        updateSettingIfChanged(currentOptions, input, 'columnOptions');
        updateSettingIfChanged(currentOptions, input, 'filterOptions');
        updateSettingIfChanged(currentOptions, input, 'rowGroups');
        updateSettingIfChanged(currentOptions, input, 'rowGroupsSorting');
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldOnlyDisplayRumouredFixtures'
        );
        updateSettingIfChanged(currentOptions, input, 'vesselOptions');

        if (currentOptions.hasRecentChanges) {
            currentOptions.hasDifferencies = !_.isEqual(
                currentOptions.currentOptions,
                currentOptions.initialOptions
            );
        }
    }

    export function processOrdersChanges(
        current: SelectedLayoutModel,
        input: Partial<LayoutGridConfigMap['orders']>
    ): void {
        const currentOptions = current.orders;

        updateSettingIfChanged(currentOptions, input, 'columnOptions');
        updateSettingIfChanged(currentOptions, input, 'filterOptions');
        updateSettingIfChanged(currentOptions, input, 'rowGroups');
        updateSettingIfChanged(currentOptions, input, 'rowGroupsSorting');
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldHighlightNewOrders'
        );
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldShowConvertedOrders'
        );
        updateSettingIfChanged(
            currentOptions,
            input,
            'shouldOnlyDisplayRumouredOrders'
        );

        if (currentOptions.hasRecentChanges) {
            currentOptions.hasDifferencies = !_.isEqual(
                currentOptions.currentOptions,
                currentOptions.initialOptions
            );
        }
    }
}

export function serializeLayoutData(
    data: SelectedLayoutModel
): Pick<SavedLayoutModel, 'fixtures' | 'orders' | 'commonSettings'> {
    const currentFixtures = data.fixtures.currentOptions;
    const fixtures = {
        filters: { ...currentFixtures.filterOptions },
        columnState: [...currentFixtures.columnOptions],
        vesselOptions: currentFixtures.vesselOptions,
        rowGroupsSorting: currentFixtures.rowGroupsSorting,
        rowGroups: currentFixtures.rowGroups,
        shouldOnlyDisplayRumouredFixtures:
            currentFixtures.shouldOnlyDisplayRumouredFixtures,
        collapsedRowGroups: data.fixtures.collapsedRowGroups,
    };

    const currentOrders = data.orders.currentOptions;
    const orders = {
        filters: { ...currentOrders.filterOptions },
        columnState: [...currentOrders.columnOptions],
        shouldHighlightNewOrders: currentOrders.shouldHighlightNewOrders,
        shouldShowConvertedOrders: currentOrders.shouldShowConvertedOrders,
        shouldOnlyDisplayRumouredOrders:
            currentOrders.shouldOnlyDisplayRumouredOrders,
        rowGroupsSorting: currentOrders.rowGroupsSorting,
        rowGroups: currentOrders.rowGroups,
        collapsedRowGroups: data.orders.collapsedRowGroups,
    };

    const commonSettings = {
        directionLogic: data.commonSettings.directionLogic.currentState,
        quantityFormat: data.commonSettings.quantityFormat.currentState,
        defaultType: data.commonSettings.defaultType.currentState,
    };

    return {
        fixtures: JSON.stringify(fixtures),
        orders: JSON.stringify(orders),
        commonSettings,
    };
}

export function normalizeLayout(
    data: SavedLayoutModel,
    datasetId: number | null
): SelectedLayoutModel {
    return {
        ...getSelectedLayoutOptions(data, datasetId),
        name: data.name,
        isTemplate: data.isTemplate,
    };
}

export function resolveCurrentLayout(
    state: LayoutsState
): SavedLayoutModel | undefined {
    const layoutsToSearch = !state.selectedDatasetId
        ? state.allLayouts
        : state.allLayouts.filter(
              (x) => x.datasetId < 1 || x.datasetId === state.selectedDatasetId
          );

    let selected: SavedLayoutModel | undefined;
    if (state.selectedLayout.selectedLayoutId) {
        selected = layoutsToSearch.find(
            (x) => x.id === state.selectedLayout.selectedLayoutId
        );
    }

    if (!selected) {
        selected = layoutsToSearch.find((x) => x.isPreferred);
    }
    if (!selected) {
        selected = layoutsToSearch[0];
    }

    return selected;
}
