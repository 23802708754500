import { gql } from '@apollo/client';

export const SEARCH_VESSELS = gql`
    query Vessels(
        $searchTerm: String!
        $datasetId: Int!
        $minDwt: Int!
        $maxDwt: Int!
        $searchVesselsWithImoOnly: Boolean!
    ) {
        vessels(
            searchTerm: $searchTerm
            datasetId: $datasetId
            minDwt: $minDwt
            maxDwt: $maxDwt
            searchVesselsWithImoOnly: $searchVesselsWithImoOnly
        ) {
            vesselId
            imo
            shipsBaseTankerVesselId
            name
            dwt
            yob
            scrubber
            yard
            design
            imoClass {
                imoGradeI
                imoGradeII
                imoGradeIII
            }
            iceClassNotation
            grades
            nitrogenPlant
            bestOperator
            gainCompany {
                value
                partType
                name
            }
            eniNumber
            fleetTypeIds
            isTbn
        }
    }
`;

export const VESSEL_POSITION_QUERY = gql`
    query VesselPositions($imo: Int!) {
        vesselPositions(imo: $imo) {
            firstSeen
            draft
            speed
            distanceToPort
            nearestPort
        }
    }
`;
