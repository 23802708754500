const rateIndices = [
    {
        value: 'BCI',
        label: 'BCI',
        datasetIds: [14],
    },
    {
        value: 'BPI82',
        label: 'BPI 82',
        datasetIds: [14],
    },
    {
        value: 'BPI74',
        label: 'BPI 74',
        datasetIds: [14],
    },
    {
        value: 'BEPASIA',
        label: 'BEP ASIA',
        datasetIds: [14],
    },
    {
        value: 'BSI63',
        label: 'BSI 63',
        datasetIds: [14],
    },
    {
        value: 'BSI58',
        label: 'BSI 58',
        datasetIds: [14],
    },
    {
        value: 'BSI52',
        label: 'BSI 52',
        datasetIds: [14],
    },
    {
        value: 'BESASIA',
        label: 'BES ASIA',
        datasetIds: [14],
    },
    {
        value: 'BHI38',
        label: 'BHI 38',
        datasetIds: [14],
    },
    {
        value: 'BHI28',
        label: 'BHI 28',
        datasetIds: [14],
    },
    {
        value: 'TC1',
        label: 'TC1',
        datasetIds: [13, 15],
    },
    {
        value: 'TC2_37',
        label: 'TC2_37',
        datasetIds: [13, 15],
    },
    {
        value: 'TC5',
        label: 'TC5',
        datasetIds: [13, 15],
    },
    {
        value: 'TC6',
        label: 'TC6',
        datasetIds: [13, 15],
    },
    {
        value: 'TC7',
        label: 'TC7',
        datasetIds: [13, 15],
    },
    {
        value: 'TC8',
        label: 'TC8',
        datasetIds: [13, 15],
    },
    {
        value: 'TC9',
        label: 'TC9',
        datasetIds: [13, 15],
    },
    {
        value: 'TC10',
        label: 'TC10',
        datasetIds: [13, 15],
    },
    {
        value: 'TC11',
        label: 'TC11',
        datasetIds: [13, 15],
    },
    {
        value: 'TC12',
        label: 'TC12',
        datasetIds: [13, 15],
    },
    {
        value: 'TC14',
        label: 'TC14',
        datasetIds: [13, 15],
    },
    {
        value: 'TC15',
        label: 'TC15',
        datasetIds: [13, 15],
    },
    {
        value: 'TC16',
        label: 'TC16',
        datasetIds: [13, 15],
    },
    {
        value: 'TC17',
        label: 'TC17',
        datasetIds: [13, 15],
    },
    {
        value: 'TC18',
        label: 'TC18',
        datasetIds: [13, 15],
    },
    {
        value: 'TC19',
        label: 'TC19',
        datasetIds: [13, 15],
    },
    {
        value: 'TD1',
        label: 'TD1',
        datasetIds: [13, 15],
    },
    {
        value: 'TD2',
        label: 'TD2',
        datasetIds: [13, 15],
    },
    {
        value: 'TD3C',
        label: 'TD3C',
        datasetIds: [13, 15],
    },
    {
        value: 'TD6',
        label: 'TD6',
        datasetIds: [13, 15],
    },
    {
        value: 'TD7',
        label: 'TD7',
        datasetIds: [13, 15],
    },
    {
        value: 'TD8',
        label: 'TD8',
        datasetIds: [13, 15],
    },
    {
        value: 'TD9',
        label: 'TD9',
        datasetIds: [13, 15],
    },
    {
        value: 'TD14',
        label: 'TD14',
        datasetIds: [13, 15],
    },
    {
        value: 'TD15',
        label: 'TD15',
        datasetIds: [13, 15],
    },
    {
        value: 'TD17',
        label: 'TD17',
        datasetIds: [13, 15],
    },
    {
        value: 'TD18',
        label: 'TD18',
        datasetIds: [13, 15],
    },
    {
        value: 'TD19',
        label: 'TD19',
        datasetIds: [13, 15],
    },
    {
        value: 'TD20',
        label: 'TD20',
        datasetIds: [13, 15],
    },
    {
        value: 'TD21',
        label: 'TD21',
        datasetIds: [13, 15],
    },
    {
        value: 'TD22',
        label: 'TD22',
        datasetIds: [13, 15],
    },
    {
        value: 'TD23',
        label: 'TD23',
        datasetIds: [13, 15],
    },
    {
        value: 'TD24',
        label: 'TD24',
        datasetIds: [13, 15],
    },
    {
        value: 'TD25',
        label: 'TD25',
        datasetIds: [13, 15],
    },
    {
        value: 'TD26',
        label: 'TD26',
        datasetIds: [13, 15],
    },
];

const rateIndicesWithId = rateIndices.map((value, index) => {
    return { ...value, id: index + 1 };
});

export const getRateIndicesByDatasetId = (datasetId) => {
    return rateIndicesWithId.filter((index) =>
        index.datasetIds.includes(datasetId)
    );
};

export const getIndexByValue = (value) => {
    return rateIndicesWithId.find((index) => index.value === value);
};
