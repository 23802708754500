import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import FortCellEditor from '../../../shared/columns/editors/FortCellEditor';
import CargoQuantityInput from '../inputs/CargoQuantityInput';
import MultipleSelect from '../../../shared/columns/inputs/MultipleSelect';
import {
    normalizeParts,
    trimTrailingSeparators,
    SEPARATOR_FORWARD_SLASH,
} from '../../../../models/common/EntityPart';
import { createSeparatorQuantityPart } from '../models/CargoQuantityPart';
import PopupWindow from '../../../shared/columns/popupWindows/PopupWindow';
import {
    ENTITY_PART_TYPE_QUANTITY,
    ENTIYT_PART_TYPE_STEMSIZE,
} from '../../../../models/common/EntityPart';
import { quantityFormatterInstance } from '../formatters/quantityFormatter';

const CargoQuantityEditor = forwardRef((props, ref) => {
    const selectRef = useRef();
    const focus = () => {
        selectRef.current.focus();
    };
    const handleOnTab = () => props.api.tabToNextCell();
    const handleOnTabBack = () => props.api.tabToPreviousCell();
    const handleOnEnter = () => props.stopEditing();

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                if (selectRef.current.formattedValue().length > 0) {
                    var parts = normalizeParts(
                        selectRef.current.formattedValue().map((part) => {
                            return {
                                quantityValue: part.quantityValue,
                                stemSizeValue: part.stemSizeValue,
                                separatorValue: part.separatorValue,
                                partType: part.partType,
                            };
                        }),
                        createSeparatorQuantityPart,
                        SEPARATOR_FORWARD_SLASH
                    );
                    return trimTrailingSeparators(parts);
                } else {
                    return null;
                }
            },

            isPopup() {
                return true;
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    return (
        <FortCellEditor blockNavigation={false} {...props}>
            <PopupWindow
                ref={selectRef}
                popupWindowStyles={{ maxWidth: '400px' }}
                context={props.context}
                value={props.value}
                onTab={handleOnTab}
                onTabBack={handleOnTabBack}
                onEnter={handleOnEnter}
                shouldSelectItemOnTab={true}
                inputClass="ag-react-container"
                datasetId={props.context.datasetId}
                createSeparatorPart={createSeparatorQuantityPart}
                entityPartTypesOfValuePart={[
                    ENTITY_PART_TYPE_QUANTITY,
                    ENTIYT_PART_TYPE_STEMSIZE,
                ]}
                partsFormatterInstance={quantityFormatterInstance}
                multipleSelect={<MultipleSelect />}
                input={<CargoQuantityInput initialChar={props.charPress} />}
            ></PopupWindow>
        </FortCellEditor>
    );
});

export default CargoQuantityEditor;
