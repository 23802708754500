import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    typography: {
        fontFamily: 'Cera GR Medium !important',
        fontSize: '13px',
    },
}));

const EmptyFiltersMenu = () => {
    const classes = useStyles();

    return (
        <Box p={2}>
            <Typography className={classes.typography}>
                There are no filters applied at the moment
            </Typography>
        </Box>
    );
};

export default EmptyFiltersMenu;
