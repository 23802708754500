import { createTheme } from '@mui/material/styles';
import { grey, teal } from '@mui/material/colors';

const appFonts = {
    CeraGR: ['Cera GR', 'Roboto', 'sans-serif'].join(','),
    CeraGRMedium: ['Cera GR Medium', 'Roboto', 'sans-serif'].join(','),
    CeraGRBold: ['Cera GR Bold', 'Roboto', 'Cera GR Light', 'sans-serif'].join(
        ','
    ),
    NotoSans: [
        'Noto Sans',
        'Cera GR',
        'Roboto',
        'Cera GR Light',
        'sans-serif',
    ].join(','),
};

const appTheme = createTheme({
    palette: {
        primary: {
            main: '#203171',
        },
        secondary: {
            main: teal['A400'],
        },
        info: {
            main: grey[700],
        },
    },
    typography: {
        fontFamily: appFonts.CeraGRMedium,
        h1: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
        },
        h2: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
        },
        h3: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
        },
        h4: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
            fontSize: '16px',
        },
        h5: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
            fontSize: '14px',
        },
        h6: {
            fontFamily: appFonts.CeraGRBold,
            fontWeight: 400,
            fontSize: '12px',
        },
    },
    components: {
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    '&, *': {
                        userSelect: 'none',
                        fontFamily: appFonts.NotoSans,
                    },
                },
            },
        },
    },
});

export default appTheme;
