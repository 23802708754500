class QuantityParseResult {
    constructor(inObj) {
        this.parsedValue = '';
        this.isSuccess = false;
        this.from = NaN;
        this.to = NaN;
        this.tolerance = NaN;

        if (inObj) {
            Object.assign(this, inObj);
        }
    }
}

class QuantityParser {
    getFailingParseResult(inputString) {
        return new QuantityParseResult({
            parsedValue: inputString,
        });
    }

    parsers = [
        {
            name: 'range parser',
            isMatch: (value) =>
                /^[0-9,\\.]+-[0-9,\\.]+(?:[/]([1-9]|10))?$/.test(value),
            isGreedy: true,
            formatValue: this.parseRangeValue.bind(this),
        },
        {
            name: 'exact number parser',
            isMatch: (value) => /^[0-9,\\.]+(?:[/]([1-9]|10))?$/.test(value),
            isGreedy: false,
            formatValue: this.parseExactValue.bind(this),
        },
    ];

    parseExactValue(numberString) {
        const splitedStrings = numberString.split('/');

        const amount = this.parseNumberString(splitedStrings[0]);
        let tolerance = NaN;

        if (splitedStrings.length === 2) {
            tolerance = this.parseNumberString(splitedStrings[1]);
        }

        return new QuantityParseResult({
            parsedValue: numberString,
            isSuccess: !isNaN(amount),
            from: amount,
            to: amount,
            tolerance,
        });
    }

    parseIntegerString(value) {
        const cleanValue = value.replace(/[\s,]/g, '');
        const intValue = +parseInt(cleanValue);

        const parsedValue = isNaN(intValue) ? null : intValue;

        return new QuantityParseResult({
            parsedValue: parsedValue,
            isSuccess: !isNaN(intValue),
            from: parsedValue,
            to: parsedValue,
            tolerance: null,
        });
    }

    parseNumberString(value) {
        const cleanValue = value.replace(/[\s,]/g, '');
        const floatValue = +parseFloat(cleanValue).toFixed(2);
        return floatValue;
    }

    parseRangeValue(value) {
        const splitedStrings = value.split(/[-/]/g);

        const from = this.parseNumberString(splitedStrings[0]);
        const to = this.parseNumberString(splitedStrings[1]);
        let tolerance = NaN;

        if (splitedStrings.length === 3) {
            tolerance = this.parseNumberString(splitedStrings[2]);
        }

        return new QuantityParseResult({
            parsedValue: value,
            isSuccess: true,
            from,
            to,
            tolerance,
        });
    }

    parse(inputString) {
        for (const parser of this.parsers) {
            if (parser.isMatch(inputString)) {
                const parseResult = parser.formatValue(inputString);
                if (parseResult.isSuccess) {
                    return parseResult;
                } else if (parser.isGreedy) {
                    return parseResult;
                }
            }
        }

        return this.getFailingParseResult(inputString);
    }

    clean(inputString, inputAllowCharsRegEx) {
        if (!inputString) {
            return inputString;
        }

        const allowCharsRegEx = inputAllowCharsRegEx ?? /[^0-9/,.-]*/gi;
        const cleanValue = inputString.replace(allowCharsRegEx, '');
        return cleanValue;
    }

    cleanInteger(inputString) {
        const allowCharsRegEx = /[^0-9/-]*/gi;

        return this.clean(inputString, allowCharsRegEx);
    }

    checkIsInteger(inputString) {
        const cleanValue = inputString.toString().replace(/[\s,]/g, '');

        if (isNaN(cleanValue)) {
            return false;
        }

        const integerRegEx = /^[0-9]+$/gi;
        return integerRegEx.test(cleanValue);
    }
}
export { QuantityParseResult, QuantityParser };
