const VesselDwtFormatter = (params) => {
    const { value } = params;

    if (value) {
        const formatter = new Intl.NumberFormat('en-GB');

        return formatter.format(value);
    } else {
        return '';
    }
};
export default VesselDwtFormatter;
