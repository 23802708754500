import { parseSalePrice } from '_legacy/services/SalePriceFormatterService';

export const SalePriceComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }
    const parsedValueA = parseSalePrice(valueA);
    const parsedValueB = parseSalePrice(valueB);
    return parsedValueA - parsedValueB;
};
