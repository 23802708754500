import ExcelJS, { Alignment, Borders, FillPattern } from 'exceljs';
import { saveAs } from 'file-saver';
import { createDataForExport } from './DataPreparationService';
import { numberToExcelColumnLetters } from '../tools/excelTools';
import {
    getGroupHeadingStyleSettings,
    getColumnHeaderStyleSettings,
    getDataColumnStyleSettings,
    getHeaderStyleSettings,
} from './StyleSettingsService';
import { excelFileSettings } from '../constants/excelFileSettings';
import { getFormattedValue } from '_legacy/services/FormattedGridValueService';
import getHeadingTitle from '../../services/headingsServices/getHeadingTitle';
import { FIXTURE_GRID_TYPE } from '_legacy/constants/gridTypes';
import { Column, RowNode } from '@ag-grid-enterprise/all-modules';
import moment from 'moment';
import { colors } from '../constants/styles';

interface IExportToExcelParams {
    context: any;
    nodes: RowNode[];
    columns: Column[];
    shouldExportGroupHeadings: boolean;
    newThemeEnabled: boolean;
    datasetType: string;
    datasetName: string;
    layoutName: string;
}

interface IExcelNode extends RowNode {
    dataRowsCount?: number;
    childNodes?: IExcelNode[];
}

export const exportToExcel = async (params: IExportToExcelParams) => {
    const {
        context,
        nodes,
        columns,
        shouldExportGroupHeadings,
        newThemeEnabled,
        datasetType,
        datasetName,
        layoutName,
    } = params;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(getWorksheetName(datasetName), {
        pageSetup: { paperSize: excelFileSettings.paperSize },
    });

    if (newThemeEnabled) {
        const res = await fetch('/ClarksonsLogo.png');
        const resBuffer = await res.arrayBuffer();
        const logoId = workbook.addImage({
            buffer: resBuffer,
            extension: 'png',
        });

        generateNew(
            shouldExportGroupHeadings,
            nodes,
            worksheet,
            context,
            columns,
            logoId,
            datasetType,
            datasetName,
            layoutName
        );
    } else {
        generateLegacy(
            shouldExportGroupHeadings,
            nodes,
            worksheet,
            context,
            columns
        );
    }

    saveAsExcel(workbook, getFileName(params.context.gridId, newThemeEnabled));
};

const generateLegacy = (
    shouldExportGroupHeadings: boolean,
    nodes: RowNode[],
    worksheet: ExcelJS.Worksheet,
    context: any,
    columns: Column[]
) => {
    if (shouldExportGroupHeadings) {
        const treeStructuredNodes = createDataForExport(nodes);
        const rootNode = treeStructuredNodes[0];
        insertRows(
            worksheet,
            context,
            rootNode.childNodes,
            columns,
            true,
            rootNode.levelsCount
        );
    } else {
        insertColumnsRow(worksheet, columns, context, undefined, true);
        insertRows(worksheet, context, nodes, columns, true);
    }
};

const generateNew = (
    shouldExportGroupHeadings: boolean,
    nodes: IExcelNode[],
    worksheet: ExcelJS.Worksheet,
    context: any,
    columns: Column[],
    logoId: number,
    datasetType: string,
    datasetName: string | undefined,
    layoutName: string | undefined
) => {
    let rootNode: any = undefined;
    if (shouldExportGroupHeadings) {
        const treeStructuredNodes = createDataForExport(nodes);
        rootNode = treeStructuredNodes[0];
    }
    insertHeader(
        worksheet,
        logoId,
        datasetName ?? '',
        layoutName ?? '',
        columns.length,
        datasetType,
        rootNode?.levelsCount
    );
    insertColumnsRow(worksheet, columns, context, rootNode?.levelsCount, false);
    insertRows(
        worksheet,
        context,
        rootNode ? rootNode.childNodes : nodes,
        columns,
        false,
        rootNode?.levelsCount
    );

    adjustColumnWidth(worksheet, rootNode?.levelsCount);
};

const insertHeader = (
    worksheet: ExcelJS.Worksheet,
    logoId: number,
    datasetName: string,
    layoutName: string,
    columnsNumber: number,
    datasetType: string,
    levelsCount: number = 0
) => {
    worksheet.addImage(logoId, {
        tl: { col: 0.5, row: 0.5 },
        ext: { width: 342, height: 62 },
    });
    for (let index = 1; index < 6; index++) {
        worksheet.getCell(index, columnsNumber).value = '';
    }
    const firstRow = 4;
    const lastRow = 6;
    const layoutFirstCol = levelsCount + 1;
    const layoutLastCol = columnsNumber + levelsCount;

    worksheet.getCell(lastRow, layoutFirstCol).value = layoutName;

    worksheet.getCell(firstRow, layoutLastCol).value = datasetType;
    worksheet.getCell(lastRow, layoutLastCol).value =
        moment().format('DD/MM/YYYY');
    worksheet.eachRow(getHeaderStyleSettings);
};

const insertRows = (
    worksheet: ExcelJS.Worksheet,
    context,
    nodes: IExcelNode[],
    columns: Column[],
    isLegacyExport: boolean,
    levelsCount = 0
) => {
    for (const node of nodes) {
        if (node.group && node.childNodes) {
            insertGroupHeadingRow(
                worksheet,
                columns,
                node,
                isLegacyExport,
                isLegacyExport ? 0 : levelsCount
            );

            // If we inserting the last group node of data tree then we should print column headers.
            if (isLegacyExport && node.level === levelsCount - 1) {
                insertColumnsRow(
                    worksheet,
                    columns,
                    context,
                    undefined,
                    isLegacyExport
                );
            }

            insertRows(
                worksheet,
                context,
                node.childNodes,
                columns,
                isLegacyExport,
                levelsCount
            );
        } else
            insertDataRow(
                worksheet,
                context,
                columns,
                node,
                isLegacyExport ? 0 : levelsCount,
                isLegacyExport
            );
    }
};

const insertGroupHeadingRow = (
    worksheet: ExcelJS.Worksheet,
    columns: Column[],
    node: IExcelNode,
    isLegacyExport: boolean,
    levelsCount: number = 0
) => {
    const row = worksheet.addRow({});

    // ExcelJS allows us to merge cells just with the following interface: (A1:AA1).
    // Thats why we calculating last excel column name depending on how much columns we have.
    const lastExcelColumn = numberToExcelColumnLetters(
        columns.length + levelsCount - 1
    );
    const firstExcelColumn = isLegacyExport
        ? 'A'
        : numberToExcelColumnLetters(node.level);
    worksheet.mergeCells(
        `${firstExcelColumn}${row.number}:${lastExcelColumn}${row.number}`
    );

    const cell = worksheet.getCell(`${firstExcelColumn}${row.number}`);
    const styleSettings = getGroupHeadingStyleSettings(node, isLegacyExport);

    row.height = styleSettings.rowHeight;
    cell.font = styleSettings.font;
    cell.border = styleSettings.border as Partial<Borders>;
    cell.alignment = styleSettings.alignment as Partial<Alignment>;
    cell.fill = styleSettings.fill as FillPattern;
    cell.value = `${node.key} (${node.dataRowsCount})`;

    for (let i = 0; i < levelsCount; i++) {
        const cell = row.getCell(i + 1);
        cell.fill = styleSettings.fill as FillPattern;
        cell.border = {
            ...styleSettings.border,
            right: { style: 'thin', color: { argb: colors.white } },
            top: { style: 'thin', color: { argb: colors.grey2 } },
            bottom: { style: 'thin', color: { argb: colors.grey2 } },
        } as Partial<Borders>;
    }
};

const insertColumnsRow = (
    worksheet: ExcelJS.Worksheet,
    columns: Column[],
    context,
    levelsCount: number = 0,
    isLegacyExport: boolean
) => {
    const isFixtureGridType = context.gridId === FIXTURE_GRID_TYPE;
    const row = worksheet.addRow({});
    const styleSettings = getColumnHeaderStyleSettings(isLegacyExport);
    row.height = styleSettings.rowHeight;

    for (let i = 0; i < levelsCount; i++) {
        const cell = row.getCell(i + 1);
        const column = worksheet.getColumn(i + 1);
        column.width = 4;
        cell.fill = styleSettings.fill as FillPattern;
    }

    for (let i = 0; i < columns.length; i++) {
        const columnWidth = isLegacyExport
            ? columns[i].getActualWidth() / 8
            : 16;
        const columnName = getHeadingTitle(
            context.datasetId,
            columns[i].getColId(),
            isFixtureGridType
        );
        const cell = row.getCell(i + levelsCount + 1);
        const column = worksheet.getColumn(i + levelsCount + 1);

        column.width = columnWidth;
        cell.font = styleSettings.font;
        cell.fill = styleSettings.fill as FillPattern;
        cell.value = columnName;
    }
};

const insertDataRow = (
    worksheet: ExcelJS.Worksheet,
    context,
    columns: Column[],
    node: IExcelNode,
    levelsCount: number = 0,
    isLegacyExport: boolean
) => {
    const row = worksheet.addRow({});
    row.height = 18.75;

    for (let i = 0; i < columns.length; i++) {
        const colDef = columns[i].getColDef();
        const cell = row.getCell(i + levelsCount + 1);
        const styleSettings = getDataColumnStyleSettings(
            colDef,
            node,
            context,
            isLegacyExport
        );

        if (styleSettings.fill.fgColor)
            cell.fill = styleSettings.fill as FillPattern;
        if (styleSettings.alignment) cell.alignment = styleSettings.alignment;

        cell.border = styleSettings.border as Partial<Borders>;
        cell.font = styleSettings.font;
        cell.value = getFormattedValue(colDef, node, context);
    }

    const firstFormattedCell = row.getCell(levelsCount + 1);
    for (let i = 0; i < levelsCount; i++) {
        const cell = row.getCell(i + 1);
        cell.fill = firstFormattedCell.fill;
    }
};

const saveAsExcel = (workbook, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            fileName
        );
    });
};

const getFileName = (gridName: string, newThemeEnabled: boolean) => {
    const fileName = newThemeEnabled
        ? gridName === 'orders'
            ? 'Cargo List'
            : 'Fixture List'
        : excelFileSettings.fileName;

    return `${fileName}.${excelFileSettings.fileExtension}`;
};

const getWorksheetName = (datasetName: string) => {
    return datasetName.replace(/[*?:/\\[\]]/g, ' ');
};

const adjustColumnWidth = (worksheet, levelsCount: number | undefined) => {
    const extraLength = 2;
    const skipCols = levelsCount ?? 0;

    for (let i = skipCols; i < worksheet.columns.length; i++) {
        const col = worksheet.columns[i];
        const lengths = col.values.map((v) => v.toString().length);
        const maxLength = Math.max(
            ...lengths.filter((v) => typeof v === 'number')
        );
        col.width = maxLength + extraLength;
    }
};
