import { isEditableForTbnRow } from '../tools/headingTools';
import { commonRowAndCellClassRules } from '../../../../components/grid/classRules/classRules';
import BlanksFilterRenderer from '../../../../components/grid/renderers/BlanksFilterRenderer';
import naturalSortComparator from '../../../../components/grid/comparators/NaturalSortComparator';
import NamePropertyComparator from '../../../../components/grid/comparators/NamePropertyComparator';
import defaultObjEquals from '../../../../components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '../../../../components/grid/columnDefaults/defaultSetFilterParams';
import SelectEditor from '../../../shared/columns/editors/SelectEditor';

export const vesselIceClassNotationHeading = {
    colId: 'iceClassNotation',
    headerName: 'ICE class',
    field: 'vessel.iceClassNotation',
    editable: isEditableForTbnRow,
    valueGetter: (value) => value.data.vessel?.iceClassNotation ?? '',
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        values: ['', '1AS', '1A', '1B', '1C'],
    },
    comparator: NamePropertyComparator,
    equals: defaultObjEquals,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    hide: true,
    width: 120,
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
};
