import CompanyEditor from './CompanyEditor';
import { CharterersApi } from '../../../api/charterersApi';

class ChartererEditor extends CompanyEditor {
    render() {
        return super.render(CharterersApi.search);
    }
}

export default ChartererEditor;
