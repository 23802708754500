export const compareColumnPropertyState = (
    columnId,
    previousColumns,
    newColumns,
    columnProperty
) => {
    const getColumnState = (columns, colId) =>
        Array.isArray(columns)
            ? columns.find((item) => item.colId === colId)
            : undefined;

    const previousColumnState = getColumnState(previousColumns, columnId);
    const newColumnState = getColumnState(newColumns, columnId);

    if (!previousColumnState || !newColumnState) {
        return { hasChanged: false, newValue: undefined };
    }

    const hasChanged =
        previousColumnState[columnProperty] !== newColumnState[columnProperty];

    return { hasChanged, newValue: newColumnState[columnProperty] };
};
