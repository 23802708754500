import React from 'react';
import CompanyInput from './CompanyInput';
import MultiSelectItem from './MultiSelectItem';
import MultiSelectSeparator from './MultiSelectSeparator';
import cloneDeep from 'lodash/cloneDeep';
import { KEY_TAB } from '../../../constants/keyboardCodes';
import { BrokersApi } from '../../../api/brokersApi';

const TYPE_BROKER = 'broker';
const TYPE_SEPARATOR = 'separator';
const SEPARATOR_PLUS = '+';

class MultipleBrokerSelect extends React.Component {
    constructor(props) {
        super(props);
        this.companySelected = this.companySelected.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.separatorEntered = this.separatorEntered.bind(this);
        this.companyInputRef = React.createRef();
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.reset = this.reset.bind(this);

        const parts = [];

        if (this.props.value && this.props.value.length > 0) {
            for (var i = 0; i < this.props.value.length; i++) {
                const part = this.props.value[i];
                this.addPart(parts, part);
            }
        }

        this.state = { parts, initialValue: this.props.initialChar };
    }

    companySelected(part, keyCode, shift) {
        this.reset();

        this.setState(
            (prevState) => {
                const parts = cloneDeep(prevState.parts);
                const newParts = this.addPart(parts, part);
                return { parts: newParts, initialValue: null };
            },
            () => {
                this.focus();
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;
                    default:
                }
            }
        );
    }

    addPart(parts, part) {
        if (part.separator) {
            if (this.canAddSeparator(parts)) {
                parts.push({ type: TYPE_SEPARATOR, value: part });
            }
        } else {
            if (this.isEmpty(parts)) {
                parts.push({ type: this.getTypeFromPart(part), value: part });
            } else {
                this.addDefaultSeparatorIfRequired(parts);
                parts.push({ type: this.getTypeFromPart(part), value: part });
            }
        }

        return parts;
    }

    canAddSeparator(parts) {
        return !this.isEmpty(parts) && !this.isLastElementSeparator(parts);
    }

    getTypeFromPart(part) {
        if (part.name) {
            return TYPE_BROKER;
        } else {
            return TYPE_SEPARATOR;
        }
    }

    addDefaultSeparatorIfRequired(parts) {
        if (!this.isLastElementSeparator(parts)) {
            parts.push({
                type: TYPE_SEPARATOR,
                value: { separator: '+' },
            });
        }
    }

    handleOnTab() {
        if (this.props.onTab) {
            this.props.onTab();
        }
    }

    handleOnTabBack() {
        if (this.props.onTabBack) {
            this.props.onTabBack();
        }
    }

    handleOnEnter() {
        this.props.onEnter();
    }

    isEmpty(parts) {
        return parts.length === 0;
    }

    isLastElementSeparator(parts) {
        const lastElement = parts.slice(-1)[0];
        if (lastElement) {
            return lastElement.type === TYPE_SEPARATOR;
        }
        return false;
    }

    separatorEntered(character) {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            this.addPart(parts, {
                separator: character,
            });
            return { parts };
        });
    }

    handleDelete() {
        this.state.parts.pop();

        if (this.isLastElementSeparator(this.state.parts)) {
            this.state.parts.pop();
        }

        this.setState({ parts: this.state.parts });
    }

    get formattedValue() {
        const brokers = this.state.parts.filter((part) => {
            return part.type === TYPE_BROKER;
        });

        const result = brokers.map((part) => {
            const partValue = part.value;
            return {
                id: partValue.id,
                name: partValue.name,
            };
        });

        return result;
    }

    focus() {
        this.companyInputRef.current.focus();
    }

    hasFocus() {
        return this.companyInputRef.current.hasFocus();
    }

    reset() {
        if (this.companyInputRef.current) {
            this.companyInputRef.current.reset();
        }
    }

    render() {
        return (
            <div className="multiSelect">
                <div className="multiSelect-items">
                    {this.state.parts.map((part, index) => {
                        switch (part.type) {
                            case TYPE_BROKER:
                                return (
                                    <MultiSelectItem
                                        key={index}
                                        value={part.value.name}
                                    />
                                );
                            case TYPE_SEPARATOR:
                                return (
                                    <MultiSelectSeparator
                                        key={index}
                                        value={part.value.separator}
                                    />
                                );
                            default:
                                throw new Error(
                                    `Unknown part type specified: ${part.type}`
                                );
                        }
                    })}
                </div>
                <div className="multiSelect-input-container">
                    <div className="multiSelect-input">
                        <CompanyInput
                            ref={this.companyInputRef}
                            onCompanySelected={this.companySelected}
                            seperatorCharacters={[SEPARATOR_PLUS]}
                            blockedCharacters={['/', '?']}
                            onSeparatorEntered={this.separatorEntered}
                            onDelete={this.handleDelete}
                            inputClass={this.props.inputClass}
                            shouldSelectItemOnTab={true}
                            onTab={this.handleOnTab}
                            onTabBack={this.handleOnTabBack}
                            onEnter={this.handleOnEnter}
                            initialChar={this.state.initialValue}
                            searchApi={BrokersApi.search}
                            onInputCleared={this.reset}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default MultipleBrokerSelect;
