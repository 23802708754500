import React from 'react';
import Select from 'react-select';
import { useRateStore } from '../store/RateStore';
import types from '../models/RateTypes';

const RateType = (props) => {
    const store = useRateStore();

    const handleTypeChange = (event) => {
        const type = event.value ? event : null;
        store.setType(type);
        store.resetRateValueInputs();

        if (props.amountInputRef.current) {
            setTimeout(() => {
                props.amountInputRef.current.focus();
            }, 0);
        }
    };

    return (
        <div className="first-inline-item">
            <div className="inline-title">Rate type</div>
            <div
                className="inline"
                style={{
                    width: '130px',
                }}
            >
                <Select
                    className="basic-single"
                    classNamePrefix="list"
                    isSearchable={false}
                    options={types}
                    onChange={handleTypeChange}
                    value={store.type}
                    openMenuOnFocus={true}
                    menuPlacement="auto"
                    placeholder="Rate type"
                />
            </div>
        </div>
    );
};

export default RateType;
