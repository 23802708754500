import React from 'react';
import LocationSelect from './LocationSelect';
import MultiSelectSeparator from './MultiSelectSeparator';
import DraggableMultiSelectItem from './DraggableMultiSelectItem';
import DroppableMultiSelectSpace from './DroppableMultiSelectSpace';
import LocationApi from '../../../api/locations/locationApi';
import cloneDeep from 'lodash/cloneDeep';
import { KEY_TAB, KEY_ENTER } from '../../../constants/keyboardCodes';
import {
    ENTITY_PART_TYPE_KNOWN,
    ENTITY_PART_TYPE_COUNTRY,
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_SEPARATOR,
    ENTITY_PART_TYPE_PLACEHOLDER,
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
    SEPARATOR_PLUS_FORWARD_SLASH,
    DIRECTION_FORWARD,
    DIRECTION_BACKWARD,
    addPart,
    deleteItemAtPosition,
    normalizeParts,
    isKnownOrCountry,
    dragAndDropPart,
} from '../../../models/common/EntityPart';
import {
    createPlaceholderLocationPart,
    createSeparatorLocationPart,
    LOCATIONS_ENTITY_SEPARATOR_CHARACTERS,
} from '../../../models/LocationPart';
import { getDataset } from '../../../models/Datasets';

class MultipleLocationSelect extends React.Component {
    constructor(props) {
        super(props);
        this.locationSelected = this.locationSelected.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.separatorEntered = this.separatorEntered.bind(this);
        this.placeholderEntered = this.placeholderEntered.bind(this);
        this.locationSelect = React.createRef();
        this.handleOnTab = this.handleOnTab.bind(this);
        this.handleOnTabBack = this.handleOnTabBack.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.handleRemovedItem = this.handleRemovedItem.bind(this);
        this.moveCursorLeft = this.moveCursorLeft.bind(this);
        this.moveCursorRight = this.moveCursorRight.bind(this);
        this.switchSeparator = this.switchSeparator.bind(this);
        this.onSetCursorPosition = this.onSetCursorPosition.bind(this);
        this.onDraggedItem = this.onDraggedItem.bind(this);
        this.defaultSeperatorCharacter =
            this.props.defaultSeperatorCharacter ||
            LOCATIONS_ENTITY_SEPARATOR_CHARACTERS[0];

        this.locationsAndCountriesObservable = null;

        this.state = {
            parts: [],
            initialValue: this.props.initialChar,
            cursorPos: 0,
            haveLocationsBeenLoaded: false,
        };
    }

    async componentDidMount() {
        if (this.props.value && this.props.value.length > 0) {
            this.prepareLocationParts();
        } else {
            // if existing value is empty we don't need to create a lozenge (no needs to send the request) hence we can use formattedValue which will be empty
            this.setState({
                haveLocationsBeenLoaded: true,
            });
        }
    }

    //Function used for loading additional data (zone, area) to location parts for resolving related data.
    prepareLocationParts = () => {
        const { datasetId } = this.props.context;

        const knownLocationIds = this.props.value
            .filter((p) => p.partType === ENTITY_PART_TYPE_KNOWN)
            .map((p) => p.value);
        const countryIds = this.props.value
            .filter((p) => p.partType === ENTITY_PART_TYPE_COUNTRY)
            .map((p) => p.value);

        if (knownLocationIds.length > 0 || countryIds.length > 0) {
            this.locationsAndCountriesObservable =
                LocationApi.getLocationsAndCountriesByIds(
                    knownLocationIds,
                    countryIds,
                    datasetId,
                    this.props.field,
                    getDataset(datasetId).allowSearchCountries
                ).subscribe({
                    next: ({ data }) => {
                        const { countries, locations } =
                            data.locationsAndCountriesByIds;
                        this.processLocationsAndCountries(locations, countries);
                    },
                });
        } else {
            this.processLocationsAndCountries();
        }
    };

    processLocationsAndCountries = (knownLocations, countries) => {
        let parts = [];

        for (const part of this.props.value) {
            if (part.partType === ENTITY_PART_TYPE_KNOWN) {
                const location = knownLocations.find(
                    (l) => l.id === part.value
                );
                part.deskZone = location.deskZone;
                part.deskArea = location.deskArea;
                part.deskAreaGeared = location.deskAreaGeared;
            }

            if (part.partType === ENTITY_PART_TYPE_COUNTRY) {
                const country = countries.find((l) => l.id === part.value);
                part.deskZone = country.zone;
                part.deskArea = country.area;
                part.deskAreaGeared = country.areaGeared;
            }

            const result = addPart(
                parts,
                part,
                undefined,
                createSeparatorLocationPart
            );
            parts = result.parts;
        }

        this.setState({
            parts: parts,
            cursorPos: parts.length,
            haveLocationsBeenLoaded: true,
        });
    };

    componentWillUnmount() {
        this.locationsAndCountriesObservable &&
            this.locationsAndCountriesObservable.unsubscribe();
    }

    locationSelected(location, keyCode, shift, createLocationPart) {
        this.setState(
            (prevState) => {
                const parts = cloneDeep(prevState.parts);
                const part = createLocationPart(location);
                const { parts: newParts, cursorPos: newPosition } = addPart(
                    parts,
                    part,
                    prevState.cursorPos,
                    createSeparatorLocationPart
                );
                return {
                    parts: newParts,
                    cursorPos: newPosition,
                    initialValue: null,
                };
            },
            () => {
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;

                    case KEY_ENTER:
                        this.handleOnEnter();
                        break;
                    default:
                }
                this.focus();
            }
        );
    }

    separatorEntered(character) {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            const part = createSeparatorLocationPart(character);
            const newState = addPart(
                parts,
                part,
                prevState.cursorPos,
                createSeparatorLocationPart
            );
            return newState;
        }, this.focus);
    }

    placeholderEntered(character) {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            const part = createPlaceholderLocationPart(character);
            const newState = addPart(
                parts,
                part,
                prevState.cursorPos,
                createSeparatorLocationPart
            );
            return newState;
        }, this.focus);
    }

    handleOnTab() {
        if (this.props.onTab) {
            this.setState((prevState) => {
                const parts = cloneDeep(prevState.parts);
                return {
                    parts: normalizeParts(
                        parts,
                        createSeparatorLocationPart,
                        this.defaultSeperatorCharacter
                    ),
                };
            }, this.props.onTab);
        }
    }

    handleOnTabBack() {
        if (this.props.onTabBack) {
            this.setState((prevState) => {
                const parts = cloneDeep(prevState.parts);
                return {
                    parts: normalizeParts(
                        parts,
                        createSeparatorLocationPart,
                        this.defaultSeperatorCharacter
                    ),
                };
            }, this.props.onTabBack);
        }
    }

    handleOnEnter() {
        this.setState((prevState) => {
            const parts = cloneDeep(prevState.parts);
            return {
                parts: normalizeParts(
                    parts,
                    createSeparatorLocationPart,
                    this.defaultSeperatorCharacter
                ),
            };
        }, this.props.onEnter);
    }

    deleteAtPosition(parts, position, direction) {
        let nextPosition =
            direction === DIRECTION_BACKWARD ? position - 1 : position;

        ({ parts } = deleteItemAtPosition(parts, nextPosition, direction));

        nextPosition =
            direction === DIRECTION_BACKWARD
                ? Math.max(position - 1, 0)
                : position;

        return { parts, cursorPos: nextPosition };
    }

    moveCursorLeft() {
        const newPosition = this.state.cursorPos - 1;
        const nextPosition = newPosition >= 0 ? newPosition : 0;
        this.setState({ cursorPos: nextPosition }, this.focus);
    }

    moveCursorRight() {
        const newPosition =
            this.state.cursorPos === 0 ? 1 : this.state.cursorPos + 1;
        const nextPosition =
            newPosition <= this.state.parts.length
                ? newPosition
                : this.state.parts.length;
        this.setState({ cursorPos: nextPosition }, this.focus);
    }

    handleDelete(direction) {
        let parts = cloneDeep(this.state.parts);
        let position = this.state.cursorPos;

        const newState = this.deleteAtPosition(parts, position, direction);

        this.setState(
            {
                parts: newState.parts,
                cursorPos: newState.cursorPos,
            },
            this.focus
        );
    }

    handleRemovedItem(index) {
        let parts = cloneDeep(this.state.parts);
        let position = index;

        const newState = this.deleteAtPosition(
            parts,
            position,
            DIRECTION_FORWARD
        );

        this.setState(
            {
                parts: newState.parts,
                cursorPos: newState.cursorPos,
            },
            this.focus
        );
    }

    get formattedValue() {
        const parts = this.state.haveLocationsBeenLoaded
            ? cloneDeep(this.state.parts)
            : cloneDeep(this.props.value);

        const locationParts = parts.map((part) => {
            return {
                value: part.value,
                name: part.name,
                displayName: part.displayName,
                partType: part.partType,
                deskArea: part.deskArea,
                deskAreaGeared: part.deskAreaGeared,
                deskZone: part.deskZone,
                globalZone: part.globalZone,
                isSTS: part.isSTS,
            };
        });

        return locationParts;
    }

    focus() {
        if (this.locationSelect.current != null) {
            this.locationSelect.current.focus();
        }
    }

    hasFocus() {
        return this.locationSelect.current.hasFocus();
    }

    switchSeparator(index) {
        this.setState((prevState) => {
            let parts = cloneDeep(prevState.parts);
            const separator = parts[index];

            const newSeparator =
                separator.value === SEPARATOR_FORWARD_SLASH
                    ? createSeparatorLocationPart(SEPARATOR_PLUS)
                    : separator.value === SEPARATOR_PLUS
                    ? createSeparatorLocationPart(SEPARATOR_PLUS_FORWARD_SLASH)
                    : createSeparatorLocationPart(SEPARATOR_FORWARD_SLASH);

            parts.splice(index, 1, newSeparator);

            return { parts };
        });
    }

    onSetCursorPosition(index) {
        this.setState({ cursorPos: index }, this.focus);
    }

    onDraggedItem(sourceIndex, targetIndex) {
        this.setState((prevState) => {
            const newParts = dragAndDropPart(
                prevState.parts,
                sourceIndex,
                targetIndex
            );

            const normalized = normalizeParts(
                newParts,
                createSeparatorLocationPart,
                this.defaultSeperatorCharacter
            );

            return { parts: normalized, cursorPos: normalized.length };
        });
    }

    render() {
        const { className } = this.props;
        const isLastPosition = this.state.parts.length === this.state.cursorPos;

        const trailingSpace = (index) => (
            <DroppableMultiSelectSpace
                index={index}
                key="trailingSpace"
                onSetCursorPosition={this.onSetCursorPosition}
                onDraggedItem={this.onDraggedItem}
            />
        );

        const items = this.state.parts.map((part, index) => {
            const locationName = isKnownOrCountry(part.partType)
                ? part.isSTS
                    ? `STS ${part.name}`
                    : part.name
                : part.value;

            const draggableMultiSelectItem = (
                <DraggableMultiSelectItem
                    key={index}
                    index={index}
                    value={locationName}
                    onRemovedItem={this.handleRemovedItem}
                    onSetCursorPosition={this.onSetCursorPosition}
                    onDraggedItem={this.onDraggedItem}
                    pill
                    tabIndex="-1"
                />
            );

            switch (part.partType) {
                case ENTITY_PART_TYPE_COUNTRY:
                case ENTITY_PART_TYPE_KNOWN:
                case ENTITY_PART_TYPE_CUSTOM:
                    return draggableMultiSelectItem;
                case ENTITY_PART_TYPE_SEPARATOR:
                    return (
                        <MultiSelectSeparator
                            key={index}
                            index={index}
                            value={locationName}
                            onClick={() => this.switchSeparator(index)}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            onRemovedItem={this.handleRemovedItem}
                            pill
                        />
                    );
                case ENTITY_PART_TYPE_PLACEHOLDER:
                    return (
                        <MultiSelectSeparator
                            key={index}
                            index={index}
                            value={locationName}
                            onSetCursorPosition={this.onSetCursorPosition}
                            onDraggedItem={this.onDraggedItem}
                            onRemovedItem={this.handleRemovedItem}
                            pill
                        />
                    );
                default:
                    throw new Error(
                        `Unknown part type specified: ${part.partType}`
                    );
            }
        });

        const cursor = (
            <div className="multipleLocationSelect-input-container" key="input">
                <div className="multipleLocationSelect-input">
                    <LocationSelect
                        ref={this.locationSelect}
                        context={this.props.context}
                        field={this.props.field}
                        onLocationSelected={this.locationSelected}
                        seperatorCharacters={
                            LOCATIONS_ENTITY_SEPARATOR_CHARACTERS
                        }
                        blockedCharacters={this.props.blockedCharacters}
                        placeholderTerms={[
                            '1',
                            '2',
                            '3',
                            '4',
                            '5',
                            'OPTS',
                            'TBC',
                            'TRANSATLANTIC',
                            'UNKNOWN',
                            'EX YARD',
                            'EX DD',
                            'D/C',
                        ]}
                        onSeparatorEntered={this.separatorEntered}
                        onPlaceholderEntered={this.placeholderEntered}
                        onChange={this.props.onChange}
                        onDelete={this.handleDelete}
                        inputClass={this.props.inputClass}
                        onTab={this.handleOnTab}
                        onTabBack={this.handleOnTabBack}
                        onEnter={this.handleOnEnter}
                        initialChar={this.state.initialValue}
                        cursorPos={this.state.cursorPos}
                        onMoveLeft={this.moveCursorLeft}
                        onMoveRight={this.moveCursorRight}
                        isLastPosition={isLastPosition}
                    />
                </div>
            </div>
        );

        items.splice(this.state.cursorPos, 0, cursor);
        if (this.state.cursorPos !== this.state.parts.length) {
            items.push(trailingSpace(this.state.parts.length));
        }

        return (
            <div className={`multiple-location-select ${className}`}>
                <div className="multiple-location-select-items">{items}</div>
            </div>
        );
    }
}

export default MultipleLocationSelect;
