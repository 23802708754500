import { blankKeyText } from '../../../../constants/gridText';
import { VesselFormatter } from '../formatters/VesselFormatter';

const VesselKeyCreator = ({ value }) => {
    if (!value || !value.name) {
        return blankKeyText;
    }

    return VesselFormatter({ value });
};

export default VesselKeyCreator;
