import React, { Fragment } from 'react';
import CloseIcon from '../../../../icons/CloseIcon';
import ErrorMessage from '../../ErrorMessage';

/**
 * Action was a read that was not successful for an unknown reason.
 */
const ReadErrorUnknownCauseMessage = ({
    resultDescription,
    resolutionToTryBeforeContacting,
    onDismiss,
}) => (
    <ErrorMessage>
        <div style={{ display: 'flex' }}>
            <span style={{ width: '100%' }}>
                <section>
                    <h1>An error occurred</h1>
                    <span>
                        {resultDescription || (
                            <Fragment>
                                Reading information from the server failed due
                                to an error.
                            </Fragment>
                        )}
                    </span>
                    <span className="resolution">
                        {' '}
                        {resolutionToTryBeforeContacting || (
                            <Fragment>
                                Please check your connection and try again. Also
                                try refreshing the page.
                            </Fragment>
                        )}{' '}
                        Contact support if the problem persists.
                    </span>
                </section>
            </span>
            <CloseIcon
                onClick={onDismiss}
                style={{ cursor: 'default', marginRight: '1em' }}
            />
        </div>
    </ErrorMessage>
);

export default ReadErrorUnknownCauseMessage;
