import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocationsNodeTree from '../../../tools/LocationsNodeTree';
import {
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_KNOWN,
    ENTITY_PART_TYPE_SEPARATOR,
    ENTITY_PART_TYPE_PLACEHOLDER,
    ENTITY_PART_TYPE_COUNTRY,
} from '../../../models/common/EntityPart';
import FilterTreeView from './FilterTreeView';
import LocationApi from '../../../api/locations/locationApi';
import CountryApi from '../../../api/countryApi';
import { blankKeyText } from '../../../constants/gridText';
import { FilterGenerator } from './FilterGenerator';
import { KEY_ENTER } from '../../../../_legacy/constants/keyboardCodes';
import { getDataset } from '../../../models/Datasets';

class LocationsFilter extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.isSearchMatched = this.isSearchMatched.bind(this);
        this.handleBlanksChange = this.handleBlanksChange.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleAllLocationsChange =
            this.handleAllLocationsChange.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.handleNodeClicked = this.handleNodeClicked.bind(this);
        this.handleNodeToggled = this.handleNodeToggled.bind(this);
        this.collapseTree = this.collapseTree.bind(this);
        this.refreshFilterTree = this.refreshFilterTree.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);

        this.tree = this.createTree();

        this.state = {
            showBlanks: true,
            visibleNodes: props.visibleNodes ? props.visibleNodes : {},
            filterState: this.tree.filterState,
            expanded: [],
            model: null,
            filterText: '',
        };
    }

    componentDidMount() {
        setTimeout(this.refreshFilterTree, 100);
        this.collapseTree();
    }

    afterGuiAttached() {
        this.refreshFilterTree();
    }

    createTree(
        customLocations,
        knownLocations,
        placeholderLocations,
        countries
    ) {
        return new LocationsNodeTree(
            customLocations,
            knownLocations,
            placeholderLocations,
            countries
        );
    }

    handleNodeToggled(nodes) {
        this.setState({ expanded: nodes });
    }

    collapseTree() {
        this.setState({ expanded: [] });
    }

    getLocationsIds(partType) {
        const locationsIds = [];
        this.props.api.forEachNode((node) => {
            if (node.data && this.props.doesRowPassOtherFilter(node)) {
                const locationParts = this.valueGetter(
                    node.data[this.props.column.colId]
                );
                if (locationParts) {
                    locationParts
                        .filter((lp) => lp.partType === partType)
                        .forEach((lp) => locationsIds.push(lp.value));
                }
            }
        });
        return [...new Set(locationsIds)];
    }

    isSearchMatched(location) {
        const text = this.state.filterText.toLowerCase();
        return (
            location.shortName.toLowerCase().includes(text) ||
            location.deskZone?.toLowerCase().includes(text) ||
            location.country?.displayName?.toLowerCase().includes(text)
        );
    }

    refreshLocations(locations, visibleNodes) {
        locations.filter(this.isSearchMatched).forEach((location) => {
            const hierarchy = this.tree.getNodeAncestors(location.id);
            if (hierarchy) {
                let nodes = this.state.expanded;
                const text = this.state.filterText.toLowerCase();
                if (text !== '') {
                    for (const node of hierarchy) {
                        if (!nodes.includes(node)) {
                            nodes.push(node);
                        }
                    }
                }
                hierarchy.forEach((i) => (visibleNodes[i] = true));
            }
        });
    }

    async refreshFilterTree() {
        const knownLocationsIds = this.getLocationsIds(ENTITY_PART_TYPE_KNOWN);
        const countryIds = this.getLocationsIds(ENTITY_PART_TYPE_COUNTRY);
        const { datasetId } = this.props.context;
        const allowSearchCountries = getDataset(datasetId).allowSearchCountries;

        const locations = await LocationApi.getLocationsByIds(
            knownLocationsIds,
            this.props.column.colId,
            datasetId,
            allowSearchCountries
        );

        const countries =
            countryIds.length > 0
                ? await CountryApi.getCountriesByIds(countryIds, datasetId)
                : [];

        const customLocationsIds = this.getLocationsIds(
            ENTITY_PART_TYPE_CUSTOM
        );
        const placeholderLocationsIds = this.getLocationsIds(
            ENTITY_PART_TYPE_PLACEHOLDER
        );
        const currentFilterState = this.state.filterState;

        this.tree = this.createTree(
            customLocationsIds,
            locations,
            placeholderLocationsIds,
            countries
        );
        this.tree.setFilterState(currentFilterState);
        const visibleNodes = {};

        const customLocations = customLocationsIds.map((id) => ({
            id: LocationsNodeTree.CustomLocationPrefix + id,
            shortName: id,
            deskZone: LocationsNodeTree.CustomLocationNode,
        }));

        const placeholderLocations = placeholderLocationsIds.map((id) => ({
            id: LocationsNodeTree.PlaceholderLocationPrefix + id,
            shortName: id,
            deskZone: LocationsNodeTree.PlaceholderLocationNode,
        }));

        const countryLocations = countries.map((country) => ({
            id: `${country.zone}-${country.displayName}`,
            shortName: country.displayName,
            deskZone: country.zone,
        }));

        const knownLocations = locations
            .filter((location) => location.id)
            .map((location) => ({
                id: `${location.deskZone}-${location.id}`,
                shortName: location.shortName,
                deskZone: location.deskZone,
                country: { displayName: location.country?.displayName },
            }));

        this.refreshLocations(
            [
                ...customLocations,
                ...knownLocations,
                ...placeholderLocations,
                ...countryLocations,
            ],
            visibleNodes
        );

        if (this.state.filterText.toLowerCase() === '') {
            this.collapseTree();
        }

        this.setState(
            {
                filterState: currentFilterState,
                visibleNodes: visibleNodes,
            },
            () => this.props.filterChangedCallback()
        );
    }

    handleBlanksChange(_, checked) {
        this.setState({ showBlanks: checked }, () =>
            this.props.filterChangedCallback()
        );
    }

    handleSearchChange(event) {
        this.setState({ filterText: event.target.value.trimStart() }, () => {
            this.refreshFilterTree();
            this.props.filterChangedCallback();
        });
    }

    handleKeyDown(event) {
        if (event.keyCode === KEY_ENTER) {
            this.filterGridByVisibleNodes();
        }
    }

    filterGridByVisibleNodes() {
        this.tree.filterByVisibleNodes(this.tree, this.state.visibleNodes);

        this.setState(
            {
                showBlanks: false,
                filterState: this.tree.filterState,
            },
            () => {
                this.props.filterChangedCallback();
            }
        );
    }

    handleAllLocationsChange(_, checked) {
        const state = checked
            ? LocationsNodeTree.States.CHECKED
            : LocationsNodeTree.States.UNCHECKED;
        this.tree.setCheckbox('_', state);

        this.setState({ filterState: this.tree.filterState }, () => {
            this.props.filterChangedCallback();
        });
    }

    handleNodeClicked(treeNode, checked) {
        let { showBlanks } = this.state;

        this.tree.setCheckbox(
            treeNode.key,
            checked
                ? LocationsNodeTree.States.CHECKED
                : LocationsNodeTree.States.UNCHECKED
        );

        this.setState({ showBlanks, filterState: this.tree.filterState }, () =>
            this.props.filterChangedCallback()
        );
    }

    clearFilter() {
        this.tree.clearFilter();
        this.setState(
            {
                showBlanks: true,
                filterState: this.tree.filterState,
                filterText: '',
            },
            () => {
                this.refreshFilterTree();
                this.props.filterChangedCallback();
            }
        );
    }

    valueGetter(rowValue) {
        return this.props.fieldName && rowValue
            ? rowValue[this.props.fieldName]
            : rowValue;
    }

    isShowingAllLocations = () => {
        return this.tree.filterState.entityFilterSet.includes(
            LocationsNodeTree.RootNodeKey
        );
    };

    isFilterActive() {
        return !this.state.showBlanks || !this.isShowingAllLocations();
    }

    doesFilterPass(params) {
        const locationParts = this.valueGetter(
            this.props.valueGetter(params.node)
        )?.filter((cp) => cp.partType !== ENTITY_PART_TYPE_SEPARATOR);

        if (
            (!locationParts || locationParts.length === 0) &&
            this.state.showBlanks
        ) {
            return true;
        }

        if (locationParts && locationParts.length > 0) {
            if (this.isShowingAllLocations()) {
                return true;
            }

            for (const part of locationParts) {
                const keyNode =
                    part.partType === ENTITY_PART_TYPE_CUSTOM
                        ? LocationsNodeTree.CustomLocationPrefix + part.value
                        : part.partType === ENTITY_PART_TYPE_PLACEHOLDER
                        ? LocationsNodeTree.PlaceholderLocationPrefix +
                          part.value
                        : part.partType === ENTITY_PART_TYPE_COUNTRY
                        ? `-${part.name}`
                        : `-${part.value}`;

                if (this.tree.isChecked(keyNode)) {
                    return true;
                }
            }
        }

        return false;
    }

    getModel() {
        const { filterState, showBlanks } = this.state;
        return this.isShowingAllLocations() && showBlanks
            ? null
            : { filterState, showBlanks };
    }

    setModel(model) {
        if (model) {
            this.tree.setFilterState(model.filterState);
            this.setState({
                filterState: model.filterState,
                showBlanks: model.showBlanks,
            });
        } else {
            this.clearFilter();
        }
    }

    render() {
        const elements = [
            <input
                ref={this.inputRef}
                style={{ marginBottom: '10px' }}
                className="ag-input-field-input ag-text-field-input"
                type="text"
                value={this.state.filterText}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleKeyDown}
                placeholder="Search..."
                autoFocus
            />,
            [
                {
                    type: 'checkbox',
                    checked: this.state.showBlanks,
                    onChange: this.handleBlanksChange,
                    label: blankKeyText,
                },
                {
                    type: 'checkbox',
                    checked: this.isShowingAllLocations(),
                    onChange: this.handleAllLocationsChange,
                    label: 'ALL LOCATIONS',
                },
            ],
            <FilterTreeView
                tree={this.tree}
                visibleNodes={this.state.visibleNodes}
                expanded={this.state.expanded}
                onNodeClicked={this.handleNodeClicked}
                onNodeToggle={this.handleNodeToggled}
            />,
        ];

        return <FilterGenerator elements={elements} clear={this.clearFilter} />;
    }
}

LocationsFilter.propTypes = {
    filterChangedCallback: PropTypes.func.isRequired,
};

export default LocationsFilter;
