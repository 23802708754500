import {
    getDurationUnitByValue,
    LEGS_VALUE,
} from '_legacy/modules/shared/columns/models/DurationUnits';
import { ENTITY_PART_TYPE_DURATION } from '../../../../models/common/EntityPart';

class DurationFormatter {
    numberFormat = new Intl.NumberFormat('en-GB');

    format(part, excludeUnit) {
        if (!part) {
            return '';
        }

        if (part.partType === ENTITY_PART_TYPE_DURATION) {
            if (
                part.durationValue.amount &&
                part.durationValue.amount.from &&
                part.durationValue.amount.to
            ) {
                const amountString = this.formatAmount(
                    part.durationValue.amount
                );
                const unitString = this.formatUnit(
                    part.durationValue.amount.to,
                    part.durationValue.unit
                );
                const isLegsUnit =
                    part.durationValue.unit ===
                    getDurationUnitByValue(LEGS_VALUE).value;
                const minMaxDurationString = this.formatMinMaxDuration(
                    part.durationValue
                );

                return excludeUnit && !isLegsUnit
                    ? `${amountString}`
                    : isLegsUnit
                    ? `${amountString}${unitString} ${minMaxDurationString}`
                    : `${amountString} ${unitString}`;
            }

            return part.durationValue.unit;
        } else {
            return part.separatorValue;
        }
    }

    formatAmount(amount) {
        if (!amount) return '';

        return amount.from === amount.to
            ? this.formatNumber(amount.from)
            : `${this.formatNumber(amount.from)}-${this.formatNumber(
                  amount.to
              )}`;
    }

    formatUnit(amountTo, unit) {
        const formatedUnit =
            +parseInt(amountTo) === 1 ? unit.slice(0, -1) : unit;

        return formatedUnit.toUpperCase();
    }

    formatMinMaxDuration(durationValue) {
        if (!durationValue.minMaxDuration) return '';

        const formatedMinMaxValues = this.formatMinMaxValues(
            durationValue.minMaxDuration
        );

        return `(${formatedMinMaxValues} ${durationValue.minMaxDuration.unit?.toUpperCase()})`;
    }

    formatMinMaxValues(minMaxDuration) {
        const minString = `MIN ${this.formatNumber(minMaxDuration.minValue)}`;
        const maxString = `MAX ${this.formatNumber(minMaxDuration.maxValue)}`;
        return minMaxDuration.minValue && minMaxDuration.maxValue
            ? `${minString} - ${maxString}`
            : !minMaxDuration.maxValue
            ? minString
            : maxString;
    }

    formatNumber(value) {
        if (isNaN(value)) {
            return '';
        }

        return this.numberFormat.format(value);
    }
}

const durationFormatterInstance = new DurationFormatter();

export { DurationFormatter, durationFormatterInstance };
