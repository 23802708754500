import React, { Component } from 'react';
import Downshift from 'downshift';
import DownshiftActivityIndicator from './DownshiftActivityIndicator';
import FortDownshiftInput from './FortDownshiftInput';
import axios from 'axios';
import {
    KEY_BACKSPACE,
    KEY_DELETE,
    KEY_TAB,
    KEY_ENTER,
    KEY_LEFT,
    KEY_RIGHT,
} from '../../../constants/keyboardCodes';
import LocationApi from '../../../api/locations/locationApi';
import CountryApi from '../../../api/countryApi';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { defaultDebounceMs } from '../../../constants/inputBehaviours';
import trim from 'lodash/trim';
import {
    DIRECTION_FORWARD,
    DIRECTION_BACKWARD,
    ENTITY_PART_TYPE_COUNTRY,
    ENTITY_PART_TYPE_CUSTOM,
    ENTITY_PART_TYPE_KNOWN,
    isKnownOrCountry,
} from '../../../models/common/EntityPart';
import { CYPRESS_DATA_ATTRIBUTES } from 'constant';
import {
    createKnownLocationPart,
    createCustomLocationPart,
    createCountryLocationPart,
    isRangesLocation,
} from '../../../models/LocationPart';
import { getDataset } from '../../../models/Datasets';
import LocationSearchResultRenderer from '../renderers/LocationSearchResulRenderer';

const { GRID_CELL_INPUT } = CYPRESS_DATA_ATTRIBUTES;

const CancelToken = axios.CancelToken;
const STS_FLAG = 'STS ';

class LocationSelect extends Component {
    constructor(props) {
        super(props);
        this.reset = this.reset.bind(this);
        this.state = this.initialState(props);
        this.downshiftRef = React.createRef();
        this.inputRef = React.createRef();
        this.stateReducer = this.stateReducer.bind(this);
        this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
        this.handleInputKeyUp = this.handleInputKeyUp.bind(this);
        this.hasFocus = this.hasFocus.bind(this);
        this.cancelSource = null;

        this.searchLocations = this.searchLocations.bind(this);
        this.searchLocations = debounce(
            this.searchLocations,
            defaultDebounceMs
        );

        this.cancelSearch = this.cancelSearch.bind(this);
        this.cancelSearchRequests = this.cancelSearchRequests.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.state.inputValue) {
            this.setState({ isLoadingLocations: true });
            this.cancelSearchRequests();
            this.searchLocations(this.state.inputValue);
        }
    }

    componentDidMount() {
        if (this.state.initialSeparator) {
            this.props.onSeparatorEntered(this.props.initialChar);
        }

        if (this.state.initialPlaceholder) {
            this.props.onPlaceholderEntered(this.props.initialChar);
        }
    }

    componentWillUnmount() {
        this.cancelSearch();
    }

    initialState = (props) => {
        let inputValue = '';
        let initialIsOpen = false;
        let initialSeparator = false;
        let initialPlaceholder = false;
        if (props.initialChar) {
            if (this.isSeparatorCharacter(props.initialChar)) {
                initialSeparator = true;
            } else if (this.isPlaceholderCharacter(props.initialChar)) {
                initialPlaceholder = true;
            } else {
                inputValue = props.initialChar;
                initialIsOpen = true;
            }
        }

        return {
            matchedLocations: [],
            error: null,
            isDeleting: false,
            isLoadingLocations: false,
            initialIsOpen,
            inputValue,
            initialSeparator,
            initialPlaceholder,
        };
    };

    itemToString = (location) => (location ? location.name : '');

    reset() {
        this.setState((state, props) => this.initialState(props));
    }

    isSeparatorCharacter(character) {
        if (this.props.seperatorCharacters) {
            return this.props.seperatorCharacters.indexOf(character) > -1;
        }

        return false;
    }

    isPlaceholderCharacter(character) {
        if (this.props.placeholderTerms) {
            return (
                this.props.placeholderTerms.indexOf(character.toUpperCase()) >
                -1
            );
        }

        return false;
    }

    parseRequestComponents(value) {
        const normalizedValue = value.toUpperCase();
        const isSTS = normalizedValue.startsWith(STS_FLAG);
        const parsedValue = trim(
            isSTS ? normalizedValue.replace(STS_FLAG, '') : normalizedValue
        );

        const result = {
            isSTS: isSTS,
            parsedValue: parsedValue,
        };

        return result;
    }

    async searchLocations(value) {
        const result = this.parseRequestComponents(value);

        try {
            const { locations, countries } = await this.getLocationsData(
                result.parsedValue
            );

            const searchedResults = this.initialisedSearchedResults(
                locations,
                countries,
                result.isSTS
            );

            const matchedResults = this.initialiseMatchedResults(
                searchedResults,
                result.parsedValue,
                value
            );

            this.setState({
                isLoadingLocations: false,
                matchedLocations: matchedResults,
                error: null,
            });
        } catch (error) {
            if (!axios.isCancel(error)) {
                this.setState({ error });
            }
        }
    }

    async getLocationsData(parsedValue) {
        const promises = [];
        const { datasetId } = this.props.context;
        const allowSearchCountries = getDataset(datasetId).allowSearchCountries;

        promises.push(
            LocationApi.search(
                parsedValue,
                this.props.field,
                datasetId,
                allowSearchCountries,
                this.cancelSource.token
            )
        );

        if (allowSearchCountries) {
            promises.push(
                CountryApi.search(
                    parsedValue,
                    datasetId,
                    this.cancelSource.token
                )
            );
        }

        const data = await Promise.all(promises);
        return {
            locations: data[0],
            countries: allowSearchCountries ? data[1] : [],
        };
    }

    // Combined results from 2 separetes collections (count of records from 0 to 30)
    initialisedSearchedResults(locations, countries, isSTS) {
        const searchedResults = [];
        const allowSearchCountries = getDataset(
            this.props.context.datasetId
        ).allowSearchCountries;

        for (const location of locations) {
            const shouldShowCountry =
                allowSearchCountries &&
                !isRangesLocation(location.locationType);

            searchedResults.push({
                id: location.id,
                name: location.name,
                displayName: location.displayName,
                country: shouldShowCountry
                    ? location.country?.displayName
                    : undefined,
                countryCode: shouldShowCountry
                    ? location.country?.countryCode
                    : undefined,
                deskArea: location.deskArea,
                deskAreaGeared: location.deskAreaGeared,
                deskZone: location.deskZone,
                globalZone: location.globalZone,
                type: ENTITY_PART_TYPE_KNOWN,
                typeDisplayValue: location.locationTypeDisplayValue,
                isSTS: isSTS,
                aliases: location.aliases,
            });
        }

        for (const country of countries) {
            searchedResults.push({
                id: country.id,
                name: country.displayName,
                deskArea: country.area,
                deskAreaGeared: country.areaGeared,
                deskZone: country.zone,
                type: ENTITY_PART_TYPE_COUNTRY,
                typeDisplayValue: ENTITY_PART_TYPE_COUNTRY,
                countryCode: country.countryCode,
            });
        }
        return searchedResults;
    }

    initialiseMatchedResults(searchedResults, searchTerm, value) {
        // results with exact match should go first
        const matchedResults = searchedResults.filter(
            (r) =>
                r.name.toUpperCase() === searchTerm ||
                (r.displayName && r.displayName.toUpperCase() === searchTerm)
        );

        const otherResults = searchedResults.filter(
            (sr) => !matchedResults.find((mr) => mr.id === sr.id)
        );

        const { datasetId } = this.props.context;
        const { maxLocationsSearchResult, allowFreeTextLocation } =
            getDataset(datasetId);

        // should show maxLocationsSearchResult results max for each dataset
        while (
            matchedResults.length !== maxLocationsSearchResult &&
            otherResults.length !== 0
        ) {
            matchedResults.push(otherResults[0]);
            otherResults.shift();
        }

        if (allowFreeTextLocation) {
            this.addCustomResultToMatchedResults(
                matchedResults,
                value,
                searchTerm
            );
        }

        return matchedResults;
    }

    addCustomResultToMatchedResults(matchedResults, value, parsedValue) {
        if (
            matchedResults.filter(
                (ul) =>
                    ul.name.toLowerCase() === parsedValue.toLowerCase() ||
                    (ul.displayName &&
                        ul.displayName.toLowerCase() ===
                            parsedValue.toLowerCase())
            ).length < 1
        ) {
            const formattedValue = value.trim().toUpperCase();
            matchedResults.push({
                id: 'custom',
                name: formattedValue,
                type: ENTITY_PART_TYPE_CUSTOM,
            });
        }
    }

    cancelSearchRequests() {
        if (this.cancelSource) {
            this.cancelSource.cancel();
        }

        this.cancelSource = CancelToken.source();
    }

    cancelSearch() {
        if (this.searchLocations.cancel) {
            this.searchLocations.cancel();
        }

        this.cancelSearchRequests();
    }

    trimStart(character, string) {
        let startIndex = 0;

        while (string[startIndex] === character) {
            startIndex++;
        }

        return string.substr(startIndex);
    }

    stateReducer(state, changes) {
        switch (changes.type) {
            case Downshift.stateChangeTypes.changeInput:
                const originalValue = changes.inputValue.toUpperCase();
                let valueToSearch = changes.inputValue.trim();

                if (this.props.onChange) {
                    this.props.onChange(valueToSearch.toUpperCase());
                }

                this.setState({ inputValue: changes.inputValue, error: null });

                const valueToDisplay = this.trimStart(' ', changes.inputValue);

                if (valueToSearch === '') {
                    this.cancelSearch();
                    changes.isOpen = false;
                    changes.inputValue = '';
                } else if (
                    originalValue.startsWith(STS_FLAG) &&
                    originalValue.substring(STS_FLAG.length).trim() === ''
                ) {
                    this.cancelSearch();
                    changes.isOpen = false;
                } else if (this.isSeparatorCharacter(valueToSearch)) {
                    this.cancelSearch();
                    changes.isOpen = false;
                    changes.inputValue = '';

                    this.props.onSeparatorEntered(valueToSearch.toUpperCase());

                    this.reset();
                } else if (this.isPlaceholderCharacter(valueToSearch)) {
                    this.cancelSearch();
                    changes.isOpen = false;
                    changes.inputValue = '';

                    this.props.onPlaceholderEntered(
                        valueToSearch.toUpperCase()
                    );

                    this.reset();
                } else {
                    this.setState({ isLoadingLocations: true });

                    this.cancelSearchRequests();

                    this.searchLocations(changes.inputValue);

                    changes.isOpen = true;
                    changes.inputValue = valueToDisplay;
                }

                return {
                    ...changes,
                };
            case Downshift.stateChangeTypes.keyDownEnter:
            case Downshift.stateChangeTypes.clickItem:
                this.handleItemSelected(changes.selectedItem);
                this.reset();

                changes.inputValue = '';
                changes.isOpen = false;

                return {
                    ...changes,
                };
            case Downshift.stateChangeTypes.blurInput:
                if (this.state.matchedLocations.length > 0) {
                    this.handleItemSelected(
                        this.state.matchedLocations[state.highlightedIndex]
                    );
                    this.reset();
                }

                changes.inputValue = '';
                changes.isOpen = false;

                return {
                    ...changes,
                };
            default:
                return changes;
        }
    }

    handleItemSelected(selectedLocation, keyCode, shift) {
        const location = this.convertRetrievedLocation(selectedLocation);
        const createPart =
            location.type === ENTITY_PART_TYPE_KNOWN
                ? createKnownLocationPart
                : location.type === ENTITY_PART_TYPE_COUNTRY
                ? createCountryLocationPart
                : createCustomLocationPart;

        this.props.onLocationSelected(location, keyCode, shift, createPart);
    }

    convertRetrievedLocation(retrievedLocation) {
        return {
            id: retrievedLocation.id,
            name: this.sanitizeName(retrievedLocation.name),
            displayName: this.sanitizeName(retrievedLocation.displayName),
            country: retrievedLocation.country,
            deskArea: retrievedLocation.deskArea,
            deskAreaGeared: retrievedLocation.deskAreaGeared,
            deskZone: retrievedLocation.deskZone,
            globalZone: retrievedLocation.globalZone,
            type: retrievedLocation.type,
            isSTS: retrievedLocation.isSTS,
        };
    }

    handleInputKeyDown(e) {
        let isDeleting, inputValue;

        switch (e.keyCode) {
            case KEY_BACKSPACE:
                ({ isDeleting, inputValue } = this.state);

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onDelete) {
                        this.props.onDelete(DIRECTION_BACKWARD);
                    }
                }

                this.setState({ isDeleting: true });
                break;
            case KEY_DELETE:
                ({ isDeleting, inputValue } = this.state);

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onDelete) {
                        this.props.onDelete(DIRECTION_FORWARD);
                    }
                }

                this.setState({ isDeleting: true });
                break;
            case KEY_TAB:
                if (this.state.isLoadingLocations) {
                    e.preventDefault();
                    e.stopPropagation();
                    return true;
                }

                if (!e.altKey && !e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();

                    if (
                        this.downshiftRef.current.items &&
                        this.downshiftRef.current.items.length > 0
                    ) {
                        const selectedItem =
                            this.downshiftRef.current.items[
                                this.downshiftRef.current.state.highlightedIndex
                            ];

                        this.handleItemSelected(
                            selectedItem,
                            e.keyCode,
                            e.shiftKey
                        );
                    } else {
                        if (!e.shiftKey) {
                            this.props.onTab();
                        } else {
                            this.props.onTabBack();
                        }
                    }
                }
                break;
            case KEY_ENTER:
                if (this.state.isLoadingLocations) {
                    e.preventDefault();
                    e.stopPropagation();
                    return true;
                }

                if (
                    this.downshiftRef.current.items &&
                    this.downshiftRef.current.items.length === 0
                ) {
                    this.props.onEnter();
                }
                break;
            case KEY_LEFT:
                ({ isDeleting, inputValue } = this.state);

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onMoveLeft) {
                        this.props.onMoveLeft();
                    }
                }
                break;
            case KEY_RIGHT:
                ({ isDeleting, inputValue } = this.state);

                if (isDeleting === false && inputValue === '') {
                    if (this.props.onMoveRight) {
                        this.props.onMoveRight();
                    }
                }
                break;
            default:
                if (this.isBlockedCharacter(e.key)) {
                    e.preventDefault();
                    e.stopPropagation();
                }
        }
    }

    isBlockedCharacter(key) {
        const result =
            key === '?' ||
            (this.props.blockedCharacters &&
                this.props.blockedCharacters.indexOf(key) > -1);
        return result;
    }

    handleInputKeyUp(e) {
        if (e.keyCode === KEY_BACKSPACE || e.keyCode === KEY_DELETE) {
            this.setState({ isDeleting: false });
        }
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
            if (!this.props.initialChar) {
                this.inputRef.current.select();
            }
        }
    }

    getDisplayName(location) {
        return location.displayName;
    }

    renderLocations(getItemProps, highlightedIndex, valueToSearch) {
        return this.state.matchedLocations.length === 0 ? (
            <div className="downshift-menu-item" style={{ padding: '4%' }}>
                Unable to find any matching locations
            </div>
        ) : (
            <table
                className="ui compact table"
                style={{ width: '100%' }}
                border="0"
            >
                <tbody>
                    {this.state.matchedLocations.map((item, index) => {
                        const sanitizedName = this.sanitizeName(item.name);
                        const { datasetId } = this.props.context;
                        const { allowSearchCountries } = getDataset(datasetId);

                        return (
                            <tr
                                {...getItemProps({
                                    item,
                                    key: item.id + item.deskZone,
                                    className:
                                        FortDownshiftInput.getStandardClassForFortDownshiftItems(),
                                    style: {
                                        backgroundColor:
                                            highlightedIndex === index
                                                ? 'rgba(0,0,0,0.05)'
                                                : 'white',
                                        marginTop: '20px',
                                        paddingBottom: '5px',
                                    },
                                })}
                            >
                                {isKnownOrCountry(item.type) ? (
                                    <td>
                                        <LocationSearchResultRenderer
                                            item={item}
                                            shouldShowCountries={
                                                allowSearchCountries
                                            }
                                            shouldBeBoldText={valueToSearch}
                                        />
                                    </td>
                                ) : (
                                    <td
                                        style={{
                                            color: 'rgb(33, 150, 243)',
                                            paddingTop: '20px',
                                            paddingBottom: '20px',
                                        }}
                                    >
                                        + ADD "{sanitizedName}"
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    sanitizeName(name) {
        if (name) {
            return name.replace(/\?/, '');
        } else {
            return null;
        }
    }

    hasFocus() {
        return this.inputRef.current === document.activeElement;
    }

    render() {
        const placeholder = this.props.isLastPosition
            ? 'Location or separator'
            : '';
        const { max } = Math;

        return (
            <Downshift
                ref={this.downshiftRef}
                itemToString={this.itemToString}
                stateReducer={this.stateReducer}
                defaultHighlightedIndex={0}
                initialInputValue={this.state.inputValue}
                initialIsOpen={this.state.initialIsOpen}
            >
                {({
                    inputValue,
                    getInputProps,
                    getItemProps,
                    isOpen,
                    highlightedIndex,
                }) => (
                    <div className="downshift-container">
                        <div className={this.props.inputClass}>
                            <input
                                data-cy={GRID_CELL_INPUT}
                                style={{
                                    width: `${max(
                                        1,
                                        max(
                                            inputValue.length,
                                            placeholder.length
                                        )
                                    )}ch`,
                                }}
                                type="text"
                                {...getInputProps({
                                    tabIndex: this.props.index,
                                    ref: this.inputRef,
                                    onKeyDown: this.handleInputKeyDown,
                                    onKeyUp: this.handleInputKeyUp,
                                    className: 'downshift-input',
                                    disabled: this.props.disabled,
                                    placeholder,
                                })}
                            />
                        </div>
                        {isOpen && (
                            <div
                                className="downshift-content"
                                style={{
                                    ...FortDownshiftInput.getCssPositionPropInsideScrollAncestor(
                                        this.inputRef.current
                                    ),
                                    position: 'absolute',
                                    maxHeight: 'none',
                                    minWidth: '420px',
                                }}
                            >
                                {this.state.error ? (
                                    <div
                                        className="downshift-menu-item"
                                        style={{ color: 'red' }}
                                    >
                                        Could not search
                                    </div>
                                ) : null}
                                {!this.state.error ? (
                                    <div
                                        className="downshift-menu"
                                        style={
                                            this.state.isLoadingLocations
                                                ? undefined
                                                : FortDownshiftInput.getCssPositionPropInsideScrollAncestor(
                                                      this.inputRef.current
                                                  )
                                        }
                                    >
                                        {this.state.isLoadingLocations ? (
                                            <DownshiftActivityIndicator />
                                        ) : (
                                            this.renderLocations(
                                                getItemProps,
                                                highlightedIndex,
                                                this.state.inputValue
                                            )
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                )}
            </Downshift>
        );
    }
}

LocationSelect.propTypes = {
    onLocationSelected: PropTypes.func.isRequired,
    onPlaceholderEntered: PropTypes.func.isRequired,
    onSeparatorEntered: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    inputClass: PropTypes.string,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    initialChar: PropTypes.string,
};

export default LocationSelect;
