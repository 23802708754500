import { getDirectionService } from '_legacy/services/directions/DirectionServiceFactory';
import { DATASET_DRY_CARGO } from './Datasets';

export const groupSortingByDirection = (datasetId, directionLogic) => {
    const directionService = getDirectionService(directionLogic);

    switch (datasetId) {
        case DATASET_DRY_CARGO:
            return directionService.getAllDirections();
        default:
            throw new Error('Direction is not supported for that dataset');
    }
};
