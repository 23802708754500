import React, { Component } from 'react';
import Header from '../Header';

export default class StatusBarSheetSelectors extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
        };
    }

    setVisible(visible) {
        this.setState({ visible });
    }

    isVisible() {
        return this.state.visible;
    }

    render() {
        if (this.state.visible) {
            return (
                <Header
                    showAdmin={this.props.isAdmin}
                    onTabSelect={this.props.onTabSelect}
                />
            );
        }

        return null;
    }
}
