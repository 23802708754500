export const formatSalePrice = (value, forEdit = false) => {
    if (value === null || value === undefined || value === '') return '';
    const num = typeof value === 'string' ? parseSalePrice(value) : value;
    if (isNaN(num)) return '';

    const formatWithCommas = (
        n,
        minimumFractionDigits = 0,
        maximumFractionDigits = 2
    ) => {
        return n.toLocaleString('en-GB', {
            minimumFractionDigits,
            maximumFractionDigits,
        });
    };

    const removeTrailingZeros = (formattedNum) => {
        return formattedNum
            .replace(/(\.\d*?[1-9])0+$/, '$1')
            .replace(/\.$/, '');
    };

    if (forEdit) {
        return formatWithCommas(num);
    }

    if (num >= 100000) {
        const millions = num / 1000000;
        return `$${removeTrailingZeros(formatWithCommas(millions, 0, 2))}M`;
    }

    if (num >= 1000) {
        const thousands = num / 1000;
        return `$${removeTrailingZeros(formatWithCommas(thousands, 0, 1))}K`;
    }

    return `$${removeTrailingZeros(formatWithCommas(num, 0, 2))}`;
};

export const parseSalePrice = (value) => {
    if (typeof value !== 'string') return value;
    const cleanValue = value.replace(/[$,]/g, '').toLowerCase();
    if (cleanValue.endsWith('m')) return parseFloat(cleanValue) * 1000000;
    if (cleanValue.endsWith('k')) return parseFloat(cleanValue) * 1000;
    return parseFloat(cleanValue);
};
