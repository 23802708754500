import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { userDismissedNotificationMessage } from '../actions/userActions';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

class NotificationMessages extends Component {
    render() {
        if (!this.props.notificationMessages.length) return null;

        return (
            <div className={`notification-messages ${this.props.className}`}>
                {/*TransitionGroup creates an extra div, but this is desired. Having the extra `content` div enables this whole control to be styled to be removed from the normal document flow and hover relative to the insertion point (using position: 'absolute' on the content)*/}
                <TransitionGroup className="content">
                    {this.props.notificationMessages.map((messageViewModel) => (
                        <CSSTransition
                            timeout={{ enter: 500, exit: 200 }}
                            key={messageViewModel.id}
                            classNames="transition-fade-500in-200out"
                        >
                            <ScrollIntoViewIfNeeded className="notification-messages-item">
                                {messageViewModel.message.getMessageRichContent(
                                    {
                                        onDismiss: () => {
                                            this.props.actions.userDismissedNotificationMessage(
                                                messageViewModel
                                            );
                                        },
                                    }
                                )}
                            </ScrollIntoViewIfNeeded>
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        notificationMessages: state.notificationMessages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(
            {
                userDismissedNotificationMessage,
            },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationMessages);
