import * as types from './actionTypes';
import groupHeadingDefaults from '../components/orders/OrderHeadings';

export const groupSet = (group) => {
    const defaults = groupHeadingDefaults.getGroupHeadingDefaults();
    return {
        type: types.USER_GROUP_SET,
        group,
        headingDefaults: defaults[group.id] || defaults[1],
    };
};

export const dataSizeSet = (dataSize) => {
    return {
        type: types.USER_DATASIZE_SET,
        dataSize,
    };
};

export const userLoggedOn = (
    username,
    userId,
    groups,
    permissions,
    isAdmin,
    userTimezone,
    themeSettings
) => {
    return {
        type: types.USER_LOGGED_ON,
        username,
        userId,
        groups,
        permissions,
        isAdmin,
        userTimezone,
        themeSettings,
    };
};

export const userChangedGridOptions = (
    groupId,
    filterOptions,
    sortOptions,
    columnOptions,
    gridType
) => {
    return {
        type: types.USER_CHANGED_GRID_OPTIONS,
        filters: filterOptions,
        sortState: sortOptions,
        columnState: columnOptions,
        groupId,
        gridType,
    };
};

export const userCreationIndexSet = (index, gridType) => {
    return {
        type: types.USER_CREATION_INDEX_SET,
        gridType,
        index,
    };
};

export const userDismissedNotificationMessage = (message) => {
    return {
        type: types.USER_DISMISSED_NOTIFICATION_MESSAGE,
        message,
    };
};

export const reportTitlesSet = (titles) => {
    return {
        type: types.USER_REPORT_TITLES_SET,
        reportTitles: titles,
    };
};

export const toggleNewUI = () => ({
    type: types.TOGGLE_NEW_UI,
});

export const toggleDensityView = () => ({
    type: types.TOGGLE_DENSITY_VIEW,
});

export const toggleCondensedView = () => ({
    type: types.TOGGLE_CONDENSED_VIEW,
});
