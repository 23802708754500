import MultipleCargoFormatter from '../formatters/MultipleCargoFormatter';

const CargoPartsComparator = (valueA, valueB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }

    const formattedValueA = MultipleCargoFormatter({
        value: valueA,
    }).toLowerCase();
    const formattedValueB = MultipleCargoFormatter({
        value: valueB,
    }).toLowerCase();

    if (formattedValueA < formattedValueB) {
        return -1;
    }
    if (formattedValueA > formattedValueB) {
        return 1;
    }

    return 0;
};

export default CargoPartsComparator;
