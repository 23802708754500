export module StringFormatter {
    const DEFAULT_MAX_LENGTH = 25;

    export function trimTitle(
        title: string,
        maxLength: number = DEFAULT_MAX_LENGTH
    ): string {
        title ??= '';
        return title.length > maxLength
            ? title.substring(0, maxLength) + '...'
            : title;
    }
}
