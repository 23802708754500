import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { getConfig } from '_legacy/config/index';
import {
    configureDefaultGroupsAndGrid,
    configureGridGroupStorageWatch,
} from '_legacy/localStorageSetup';
import Routes from '_legacy/routes';
import AuthenticateAndAuthorise from '_legacy/components/AuthenticateAndAuthorise';
import ApolloProviderLazyLoader from '_legacy/components/ApolloProviderLazyLoader';
import Loader from '_legacy/components/Loader';
import ErrorBoundary from '_legacy/components/ErrorBoundary';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Logger from '_legacy/diagnostics/logging/logger';
import store from 'store';
import { ThemeProvider } from '@mui/material';
import appTheme from 'appTheme';

var configPromise = getConfig();

configPromise.then((config) => {
    Logger.initialize(config.applicationInsights.instrumentationKey);
});

// This is a temporary measure just to move the PR across the line to remove
// testing blockers.
const onLogonCallback = (groups) => {
    configureDefaultGroupsAndGrid(groups, store.dispatch);
    configureGridGroupStorageWatch(store);
};

function App() {
    return (
        <ThemeProvider theme={appTheme}>
            <DndProvider backend={HTML5Backend}>
                <Loader promise={configPromise}>
                    <ErrorBoundary>
                        <Provider store={store}>
                            <AuthenticateAndAuthorise
                                runOnUserSuccessfullyAuthorised={
                                    onLogonCallback
                                }
                            >
                                <ApolloProviderLazyLoader>
                                    <Router>
                                        <Routes />
                                    </Router>
                                </ApolloProviderLazyLoader>
                            </AuthenticateAndAuthorise>
                        </Provider>
                    </ErrorBoundary>
                </Loader>
            </DndProvider>
        </ThemeProvider>
    );
}

export default App;
