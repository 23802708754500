import React from 'react';
import { useRateStore } from '../store/RateStore';
import { getDataset } from '../../../../models/Datasets';
import { QuantityParser } from '../../../shared/columns/tools/quantityParser';
import {
    SEPARATOR_PLUS,
    SEPARATOR_FORWARD_SLASH,
} from '../../../../models/common/EntityPart';
import { flatRateValue, balticIndexValue } from '../models/RateTypes';

const Amount = (props) => {
    const store = useRateStore();

    const parser = new QuantityParser();

    const checkIsFlatRateMode = () => {
        return store.type.value === flatRateValue;
    };

    const checkIsBalticIndexMode = () => {
        return store.type.value === balticIndexValue;
    };

    const handleAmountChange = (event) => {
        store.setSubmitted(false);

        if (event.target) {
            const parsedAmountObj = amountValidation(event.target.value);
            if (parsedAmountObj) {
                if (
                    parsedAmountObj.parsedValue &&
                    parsedAmountObj.parsedValue !== ''
                ) {
                    const newUnit = getDataset(
                        props.context.datasetId
                    ).resolveRateUnit(parsedAmountObj.from);

                    store.onChangeUnit(newUnit);
                    store.setAmountObj(parsedAmountObj);
                    store.setAmount(parsedAmountObj.parsedValue);
                    store.setIsAmountValid(true);
                    store.setIsBoundValid(
                        boundValidation(parsedAmountObj.parsedValue)
                    );
                } else {
                    store.setAmount('');
                    store.setIsAmountValid(false);
                    store.setIsBoundValid(true);
                }
            }
        }
    };

    const boundValidation = (amount) => {
        return amount ? parser.checkIsInteger(amount) : true;
    };

    const amountValidation = (value) => {
        if (value === '') {
            return { parsedValue: '' };
        }

        if (value === SEPARATOR_PLUS || value === SEPARATOR_FORWARD_SLASH) {
            return null;
        }

        if (store.bound) {
            return parser.parseIntegerString(value);
        }

        const cleanValue = parser.clean(value);
        return parser.parse(cleanValue);
    };

    return (
        <div className="inline icon-inside">
            <input
                ref={props.amountInputRef}
                type="text"
                className="amount-input wrapper"
                onChange={handleAmountChange}
                value={store.amount}
                disabled={store.isAmountDisabled}
                placeholder={checkIsFlatRateMode() ? 'Amount or separator' : ''}
                style={
                    !store.isAmountValid && store.submitted
                        ? {
                              boxShadow: '0 0 0 1px red',
                              color: 'red',
                          }
                        : {}
                }
            />
            {checkIsBalticIndexMode() && <i aria-hidden="true">%</i>}
            {!store.isAmountValid && store.submitted && (
                <p style={{ color: 'red' }}>Amount is invalid</p>
            )}
        </div>
    );
};

export default Amount;
