export const isWithinMenu = (parentNode) => {
    if (
        parentNode &&
        parentNode.classList &&
        parentNode.classList.contains('menu')
    ) {
        return true;
    } else {
        return (
            parentNode &&
            parentNode.parentNode &&
            isWithinMenu(parentNode.parentNode)
        );
    }
};
