import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import * as DateTimeFormatUtils from '../../common/DateTimeFormatUtils';
import { connect } from 'react-redux';

/*
 * The tooltip process involves two components and a callback. The GroupNameRenderer contains the properties required
 * by the ReactTooltip (data-tip and data-for) to work correctly. It passes the index of the row it belongs to as the data-tip value.
 * Rather than render this value directly, the AuditTooltip will call its own getRowDataCallback callback, passing the rowIndex value.
 * This callback is currently implemented in the BasicGrid component. It uses its own gridApi to get the underlying node and
 * passes it back to the AuditTooltip, which then extracts the relevant data and renders the HTML.
 *
 * This isn't a particularly good way of doing it as there are dependencies between the GroupNameRenderer, BasicGrid and the
 * AuditTooltip itself. However, I hope it's clear how it behaves.
 */
class AuditTooltip extends Component {
    formatDate(date) {
        return DateTimeFormatUtils.formatDefaultDateTime(
            date,
            this.props.userTimezone
        );
    }

    generateAuditTooltip(data) {
        if (!data) {
            return null;
        }

        const createdDateTime = data.createdDateTime;
        const updatedDateTime = data.lastUpdatedDateTimeByUser;

        let createdTimeString = this.formatDate(createdDateTime);
        let updatedTimeString = this.formatDate(updatedDateTime);

        const createdFullName =
            (data.createdBy && data.createdBy.fullName) || '';
        const updatedFullName =
            (data.lastUpdatedByUser && data.lastUpdatedByUser.fullName) || '';

        let createdAlias = (data.createdBy && data.createdBy.username) || '';

        if (createdAlias) {
            createdAlias = `(${createdAlias})`;
        }

        let updatedAlias =
            (data.lastUpdatedByUser && data.lastUpdatedByUser.username) || '';

        if (updatedAlias) {
            updatedAlias = `(${updatedAlias})`;
        }

        let tooltipHtml = `
    <table>
      <tr>
        <th>Created By:</th>
        <td>${createdFullName} ${createdAlias}</td>
      </tr>
      <tr>
        <th>Created On:</th>
        <td>${createdTimeString}</td>
      </tr>
      <tr>
      <td />
      <td />
      </tr>
      <tr>
        <th>Updated By:</th>
        <td>${updatedFullName} ${updatedAlias}</td>
      </tr>
      <tr>
        <th>Updated On:</th>
        <td>${updatedTimeString}</td>
      </tr>
    </table>
  `;
        return tooltipHtml;
    }

    render() {
        return (
            <ReactTooltip
                id={this.props.context.gridId}
                className="fort-grid-tooltip"
                border={true}
                place="right"
                type="light"
                html={true}
                getContent={(rowIndex) => {
                    if (this.props.getRowDataCallback) {
                        const rowNode = this.props.getRowDataCallback(
                            Number(rowIndex)
                        );

                        if (rowNode) {
                            return this.generateAuditTooltip(rowNode.data);
                        }
                    }
                    return null;
                }}
            />
        );
    }
}

const mapStateToProps = (state, props) => {
    const { userTimezone } = state.user;
    return {
        ...props,
        userTimezone,
    };
};

export default connect(mapStateToProps)(AuditTooltip);
