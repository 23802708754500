import { gql } from '@apollo/client';

export const SEARCH_COUNTRIES = gql`
    query Countries($search: String!, $datasetId: Int!) {
        countries(searchTerm: $search, datasetId: $datasetId) {
            id
            displayName
            zone
            area
            areaGeared
            countryCode
        }
    }
`;

export const GET_COUNTRIES_BY_IDS = gql`
    query CountriesByIds($ids: [String]!, $datasetId: Int!) {
        countriesByIds(ids: $ids, datasetId: $datasetId) {
            id
            displayName
            zone
        }
    }
`;
