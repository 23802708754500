import React from 'react';
import Header from '../Header';
import GroupColumns from './GroupColumns';
import GroupColumnsSorting from './GroupColumnsSorting';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';

const GroupingToolPanel = (props) => {
    const [isRowGroupsOpen, setIsRowGroupsOpen] = React.useState(true);
    const [isRowGroupsSortingOpen, setIsRowGroupsSortingOpen] =
        React.useState(true);

    const handleRowGroupsClick = () => {
        setIsRowGroupsOpen(!isRowGroupsOpen);
    };

    const handleRowGroupsSortingClick = () => {
        setIsRowGroupsSortingOpen(!isRowGroupsSortingOpen);
    };

    return (
        <div className="ag-unselectable ag-column-drop ag-column-drop-vertical ag-last-column-drop">
            <ListItem button onClick={handleRowGroupsClick}>
                <Header
                    name="Row Groups"
                    icon="ag-icon-columns"
                    isOpen={isRowGroupsOpen}
                />
            </ListItem>
            <Collapse in={isRowGroupsOpen} timeout="auto" unmountOnExit>
                <GroupColumns {...props} />
            </Collapse>
            <Divider variant="middle" />
            <ListItem button onClick={handleRowGroupsSortingClick}>
                <Header
                    name="Row Group Ordering"
                    icon="ag-icon-group"
                    isOpen={isRowGroupsSortingOpen}
                />
            </ListItem>
            <Collapse in={isRowGroupsSortingOpen} timeout="auto" unmountOnExit>
                <GroupColumnsSorting {...props} />
            </Collapse>
        </div>
    );
};

export default GroupingToolPanel;
