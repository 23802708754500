import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';
import { RATE_PART_FRAGMENT } from './ratePartFragment';

const FRAGMENT = loader('./ratesFragment.gql');

export const RATES_TYPE_NAME = 'Rates';
export const RATES_FRAGMENT_NAME = 'RatesFragment';
export const RATES_FRAGMENT = gql`
    ${FRAGMENT}
    ${RATE_PART_FRAGMENT}
`;
