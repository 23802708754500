import GraphQLClient from '../../../api/graphQlClient';
import assign from 'lodash/assign';
import { UpperValueFormatter } from '../formatters/UpperFormatter';
import React, { Fragment } from 'react';
import VError from 'verror';
import { equalCellsValue } from '../../../services/gridCellComparers/GridCellCompareService';

const createGridCellValueSetter = ({
    username,
    userId,
    groupId,
    createMutation,
}) => {
    return (params) => {
        const {
            oldValue,
            newValue,
            data,
            colDef,
            api,
            node,
            colDef: { valueParser },
            context: { cellErrors },
        } = params;

        let parsedNewValue = newValue;
        let parsedOldValue = oldValue;
        if (valueParser) {
            parsedNewValue = valueParser(parsedNewValue);
            parsedOldValue = valueParser(parsedOldValue);
        }
        const transformedValue = UpperValueFormatter(parsedNewValue);

        const {
            variables,
            mutation,
            gridValue,
            customUpdate,
            filtersToBeRefreshed,
        } = createMutation(
            data,
            data.id,
            colDef.field,
            transformedValue,
            username,
            userId,
            groupId
        );
        const mutationName =
            mutation.definitions[0].selectionSet.selections[0].name.value;

        let sanitizedGridValue = gridValue;
        // Treat empty strings as null
        //
        if (sanitizedGridValue === '') {
            sanitizedGridValue = null;
        }

        const fieldChanged = !equalCellsValue(
            colDef.field,
            parsedOldValue,
            sanitizedGridValue
        );

        if (fieldChanged) {
            //gridValue is the value updated with apollo properties (__typename)
            //it represents the value that is expected to be returned from the server
            data[colDef.field] = sanitizedGridValue;

            if (customUpdate) {
                customUpdate(data, parsedOldValue, newValue);

                api.refreshCells({
                    rowNodes: [node],
                });
            }

            GraphQLClient()
                .mutate({
                    mutation: mutation,
                    variables: variables,
                    context: {
                        errorHandling: {
                            rejectedResult: {
                                resultDescription: () => (
                                    <Fragment>
                                        The highlighted field(s) was unable to
                                        reach the server.
                                    </Fragment>
                                ),
                                resolution: {
                                    tryBeforeContacting: () => (
                                        <Fragment>
                                            Please re-enter the value or check
                                            the connection and try refreshing.
                                        </Fragment>
                                    ),
                                },
                            },
                            unknownResult: {
                                resultDescription: () => (
                                    <Fragment>
                                        The highlighted field(s) was unable to
                                        reach the server.
                                        {/* Same wording as for 'rejectedResult' for now, but once we properly classify rejections (GQL validation errors/connection issues), we could put something more helpful for troubleshooting here. See https://clarksonscloud.visualstudio.com/Fort/_workitems/edit/11069 */}
                                    </Fragment>
                                ),
                                resolution: {
                                    isSafeToRetry: true,
                                    tryBeforeContacting: () => (
                                        <Fragment>
                                            Please re-enter the value or try
                                            refreshing.
                                        </Fragment>
                                    ),
                                },
                            },
                        },
                    },
                    fetchPolicy: 'no-cache',
                })
                .then((result) => {
                    //update grid data
                    const updatedEntry = result.data[mutationName];
                    if (updatedEntry == null)
                        throw new VError(
                            {
                                info: {
                                    serverResponse: updatedEntry,
                                    clientExpectedMember: mutationName,
                                },
                            },
                            'Could not find expected data in response. Check server response and member name.'
                        );

                    assign(data, updatedEntry);

                    api.refreshCells({
                        rowNodes: [node],
                    });

                    api.refreshHeader();

                    if (filtersToBeRefreshed) {
                        filtersToBeRefreshed.forEach((filterKey) => {
                            const filter = api.getFilterInstance(filterKey);
                            if (filter) filter.refreshFilterValues();
                        });
                    }
                })
                .catch((error) => {
                    cellErrors.setError({
                        rowId: data.id,
                        colId: colDef.field,
                        error,
                    });
                    data[colDef.field] = parsedOldValue;
                    api.refreshCells({
                        rowNodes: [node],
                    });
                });
        }

        return fieldChanged;
    };
};

export default createGridCellValueSetter;
