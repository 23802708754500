import client from '../graphQlClient';
import {
    SEARCH_LOCATIONS,
    GET_LOCATIONS_BY_IDS,
    GET_LOCATIONS_AND_COUNTRIES_BY_IDS,
} from '../queries/LocationQueries';

class LocationApi {
    static search = (
        search,
        field,
        datasetId,
        allowSearchCountries,
        cancelToken
    ) => {
        return new Promise((resolve, reject) => {
            client()
                .query({
                    query: SEARCH_LOCATIONS,
                    fetchPolicy: 'no-cache',
                    variables: {
                        search,
                        field,
                        datasetId,
                        allowSearchCountries,
                    },
                })
                .then((result) => {
                    resolve(result.data.locations);
                });

            cancelToken.promise.then((reason) => {
                reject(reason);
            });
        });
    };

    static getLocationsByIds = (ids, field, datasetId, allowSearchCountries) =>
        client()
            .query({
                query: GET_LOCATIONS_BY_IDS,
                fetchPolicy: 'no-cache',
                variables: {
                    ids,
                    field,
                    datasetId,
                    allowSearchCountries,
                },
            })
            .then((result) => result.data.locationsByIds);

    static getLocationsAndCountriesByIds = (
        locationIds,
        countryIds,
        datasetId,
        field,
        allowSearchCountries
    ) => {
        return client().watchQuery({
            query: GET_LOCATIONS_AND_COUNTRIES_BY_IDS,
            fetchPolicy: 'no-cache',
            variables: {
                locationIds,
                countryIds,
                datasetId,
                field,
                allowSearchCountries,
            },
        });
    };
}

export default LocationApi;
