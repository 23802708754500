import defaultObjEquals from '_legacy/components/grid/columnDefaults/defaultObjEquals';
import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';
import BlanksFilterRenderer from '_legacy/components/grid/renderers/BlanksFilterRenderer';

import UpdatedByRenderer from '../renderers/UpdatedByRenderer';
import CloudUserFormatter from '../../../shared/columns/formatters/CloudUserFormatter';
import CloudUserComparator from '../../../shared/columns/comparators/CloudUserComparator';

export const updatedByHeading = {
    colId: 'updatedBy',
    headerName: 'Updated By',
    field: 'lastUpdatedByUser',
    valueFormatter: CloudUserFormatter,
    cellRendererFramework: UpdatedByRenderer,
    comparator: CloudUserComparator,
    equals: defaultObjEquals,
    keyCreator: CloudUserFormatter,
    editable: false,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: naturalSortComparator,
    },
    width: 120,
    hide: true,
};
