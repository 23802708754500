import {
    ENTITY_PART_TYPE_DURATION,
    ENTITY_PART_TYPE_SEPARATOR,
} from '../../../../models/common/EntityPart';

export const createDurationPart = (
    unit,
    amountFrom,
    amountTo,
    minMaxDuration
) => {
    const durationValue = createDurationValue(
        unit,
        amountFrom,
        amountTo,
        minMaxDuration
    );
    return createPart(durationValue, null, ENTITY_PART_TYPE_DURATION);
};

export const createSeparatorDurationPart = (character) => {
    return createPart(null, character, ENTITY_PART_TYPE_SEPARATOR);
};

const createDurationValue = (unit, amountFrom, amountTo, minMaxDuration) => {
    const amount = amountFrom && {
        from: amountFrom,
        to: amountTo,
    };
    return {
        unit,
        amount,
        minMaxDuration,
    };
};

const createPart = (durationValue, separatorValue, partType) => {
    return {
        partType,
        durationValue,
        separatorValue,
    };
};
