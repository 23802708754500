import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./clarksonsUserFragment.gql');

export const CLARKSONS_USER_TYPE_NAME = 'ClarksonsUser';
export const CLARKSONS_USER_FRAGMENT_NAME = 'ClarksonsUserFragment';
export const CLARKSONS_USER_FRAGMENT = gql`
    ${FRAGMENT}
`;
