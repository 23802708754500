import CloudUserFormatter from './CloudUserFormatter';

const MultipleCloudUserFormatter = ({ value }) => {
    if (!value || value.length === 0) {
        return '';
    }

    return value.map((v) => CloudUserFormatter({ value: v })).join(' + ');
};

export default MultipleCloudUserFormatter;
