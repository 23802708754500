import React from 'react';
import FilterLabels from './FilterLabels';
import WithFilters from './HOCs/WithFilters';

import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
    formLabel: {
        marginTop: '10px',
        marginBottom: '5px',
        color: '#0000008A !important',
        fontFamily: 'Cera GR Medium',
        fontSize: '13px',
    },
    formGroup: {
        marginLeft: '12px',
    },
    button: {
        marginTop: '10px',
    },
}));

const UnsavedFiltersForm = ({
    unsavedFilters,
    setUnsavedFilters,
    handleFilterChange,
    handleClose,
    clearUnsavedFilters,
}) => {
    const classes = useStyles();

    const handleUnsavedFilterChange = (event) =>
        handleFilterChange(event, unsavedFilters, setUnsavedFilters);

    const handleClearUnsavedFilters = () => {
        clearUnsavedFilters(unsavedFilters);
        handleClose();
    };

    return unsavedFilters.length > 0 ? (
        <>
            <FormLabel component="legend" className={classes.formLabel}>
                UNSAVED FILTERS
            </FormLabel>
            <FormGroup className={classes.formGroup}>
                <FilterLabels
                    filters={unsavedFilters}
                    handleFilterChange={handleUnsavedFilterChange}
                />
            </FormGroup>
            <Divider />
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleClearUnsavedFilters}
            >
                CLEAR UNSAVED FILTERS
            </Button>
        </>
    ) : null;
};

export default WithFilters(UnsavedFiltersForm);
