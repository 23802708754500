import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

import { LAYCAN_FRAGMENT } from './laycanFragment';
import { CARGO_QTY_FRAGMENT } from './cargoQuantityPartFragment';
import { LOCATION_PART_FRAGMENT } from './locationPartFragment';
import { CARGO_PART_FRAGMENT } from './cargoPartFragment';
import { FREIGHT_PORT_BASIS_FRAGMENT } from './freightPortBasisFragment';
import { COMPANY_FRAGMENT } from './companyFragment';
import { THIRD_PARTY_COMPANY_PART_FRAGMENT } from './thirdPartyCompanyPartFragment';
import { CLARKSONS_USER_FRAGMENT } from './clarksonsUserFragment';
import { COMMISSION_FRAGMENT } from './commissionFragment';
import { DURATION_PART_FRAGMENT } from './durationPartFragment';
import { RATES_FRAGMENT } from './ratesFragment';
import { ORDER_VESSEL_FRAGMENT } from './vesselFragment';

const FRAGMENT = loader('./orderFragment.gql');

export const ORDER_FRAGMENT = gql`
    ${CARGO_PART_FRAGMENT}
    ${FRAGMENT}
    ${COMPANY_FRAGMENT}
    ${CARGO_QTY_FRAGMENT}
    ${LAYCAN_FRAGMENT}
    ${LOCATION_PART_FRAGMENT}
    ${FREIGHT_PORT_BASIS_FRAGMENT}
    ${THIRD_PARTY_COMPANY_PART_FRAGMENT}
    ${CLARKSONS_USER_FRAGMENT}
    ${COMMISSION_FRAGMENT}
    ${DURATION_PART_FRAGMENT}
    ${ORDER_VESSEL_FRAGMENT}
    ${RATES_FRAGMENT}
`;
