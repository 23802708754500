import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./thirdPartyCompanyPartFragment.gql');

export const THIRD_PARTY_COMPANY_PART_TYPE_NAME = 'ThirdPartyCompanyPart';
export const THIRD_PARTY_COMPANY_PART_FRAGMENT_NAME =
    'ThirdPartyCompanyPartFragment';
export const THIRD_PARTY_COMPANY_PART_FRAGMENT = gql`
    ${FRAGMENT}
`;
