import React, { Component } from 'react';
import Svg from './empty_filter.svg';

export default class EmptyFilterIcon extends Component {
    render = () => {
        return (
            <img
                src={Svg}
                {...this.props}
                style={{ opacity: '0.7', marginLeft: '5px' }}
                alt="Empty Filter Icon"
            />
        );
    };
}
