import { UserManager } from 'oidc-client';
import * as Oidc from 'oidc-client';
import { seaIdentityUrl, seaClientId, applicationUrl } from '../config';
import Logger from '../diagnostics/logging/logger';

class AuthenticationService {
    userManager;
    currentUser = null;

    async isAuthenticated() {
        let user = await this.getUser();

        if (!user || user.expired) {
            try {
                user = await this.silentRefresh();
            } catch {
                await this.redirectToLogin();
                return false;
            }
        }

        this.setCurrentUser(user);
        return true;
    }

    async getUser() {
        return await this.userManager.getUser();
    }

    getAccessToken() {
        if (this.currentUser) {
            return this.currentUser.access_token;
        }

        throw new Error('Current user is not initialized!');
    }

    redirectToLogin() {
        return this.userManager.signinRedirect({
            state: window.location.href,
        });
    }

    async silentRefresh() {
        return await this.userManager.signinSilent();
    }

    signOut() {
        return this.userManager.signoutRedirect({
            state: window.location.href,
        });
    }

    setCurrentUser(user) {
        this.currentUser = user;
    }

    async initializeUserManager() {
        const userManagerSettings = {
            authority: seaIdentityUrl,
            client_id: seaClientId,
            redirect_uri: `${applicationUrl}/signin-callback.html`,
            silent_redirect_uri: `${applicationUrl}/silent-refresh.html`,
            post_logout_redirect_uri: `${applicationUrl}/signout-callback.html`,
            response_type: 'code',
            scope: 'openid profile',
            loadUserInfo: true,
            automaticSilentRenew: true,
            revokeAccessTokenOnSignout: true,
            filterProtocolClaims: false,
            monitorSession: true,
            checkSessionInterval: 5000,
        };

        Oidc.Log.logger = console;
        this.userManager = new UserManager(userManagerSettings);

        this.userManager.events.addUserLoaded((user) => {
            this.setCurrentUser(user);
        });

        this.userManager.events.addUserUnloaded(() => {
            this.setCurrentUser(null);
        });

        this.userManager.events.addAccessTokenExpiring(() => {
            Logger.log('[addAccessTokenExpiring]: expiring...');
        });

        this.userManager.events.addSilentRenewError((err) => {
            Logger.warn('[addSilentRenewError]: ' + err.message);
            this.redirectToLogin();
        });

        this.userManager.events.addAccessTokenExpired(() => {
            Logger.warn(
                '[addAccessTokenExpired]: Access token has expired. Redirecting to login.'
            );
            this.redirectToLogin();
        });

        this.userManager.events.addUserSignedOut(async () => {
            Logger.log(
                '[addUserSignedOut]: User signed out from IdentityServer'
            );

            await this.userManager.removeUser();
            await this.redirectToLogin();
        });
    }
}

export const authenticationService = new AuthenticationService();
