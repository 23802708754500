const rateTypes = [
    {
        id: 1,
        value: 'FlatRate',
        label: 'Flat rate',
    },
    {
        id: 2,
        value: 'BalticIndex',
        label: 'Baltic index',
    },
];

export const flatRateValue = rateTypes[0].value;
export const balticIndexValue = rateTypes[1].value;

export default rateTypes;
