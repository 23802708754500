import client from './graphQlClient';
import { SEARCH_BROKER } from './queries/CompanyQueries';
export class BrokersApi {
    static search = (name, cancelToken) => {
        return new Promise((resolve, reject) => {
            client()
                .query({
                    query: SEARCH_BROKER,
                    fetchPolicy: 'no-cache',
                    variables: {
                        search: name,
                    },
                })
                .then((result) => {
                    resolve(result.data.brokers);
                });
            cancelToken.promise.then((reason) => {
                reject(reason);
            });
        });
    };
}
