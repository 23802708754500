import cloneDeep from 'lodash/cloneDeep';
import {
    commonCompareStrategy,
    laycanCompareStrategy,
    freightPortBasisCompareStrategy,
    locationCompareStrategy,
    vesselFieldCompareStrategy,
    cargoPartsCompareStrategy,
} from './GridCellsCompareStrategies';

export const equalCellsValue = (columnName, oldValue, newValue) => {
    const cleanCopyOldValue = cloneDeep(oldValue);
    const cleanCopyNewValue = cloneDeep(newValue);

    cleanUpValue(cleanCopyOldValue);
    cleanUpValue(cleanCopyNewValue);

    return compareCells(columnName, cleanCopyOldValue, cleanCopyNewValue);
};

const compareCells = (columnName, oldValue, newValue) =>
    strategySelector(columnName)(oldValue, newValue);

const strategySelector = (columnName) => {
    switch (columnName) {
        case 'loadLocationParts':
        case 'dischargeLocationParts':
        case 'deliveryLocationParts':
        case 'redeliveryLocationParts':
        case 'lastOpenLocationParts':
        case 'actualDischarge':
        case 'actualLoad':
            return locationCompareStrategy;
        case 'freightPortBasis':
            return freightPortBasisCompareStrategy;
        case 'laycan':
            return laycanCompareStrategy;
        case 'vessel.design':
        case 'vessel.yard':
        case 'vessel.dwt':
        case 'vessel.yob':
        case 'vessel.scrubber':
            return vesselFieldCompareStrategy;
        case 'cargoParts':
            return cargoPartsCompareStrategy;
        default:
            return commonCompareStrategy;
    }
};

const cleanUpValue = (value) => {
    for (const prop in value) {
        if (prop === '__typename') delete value[prop];
        else if (typeof value[prop] === 'object') cleanUpValue(value[prop]);
    }
};
