import isString from 'lodash/isString';

export const VesselFormatter = ({ value }) => {
    if (isString(value)) {
        return value;
    }

    if (!value || !value.name) {
        return '';
    }

    return value.name;
};
