import { isKnownOrCountry } from '../../../models/common/EntityPart';

const LocationFormatter = ({ node, value }) => {
    if (node && node.group) {
        return '';
    }

    if (!value) {
        return '';
    }

    // Custom locationParts will have the string in the value.
    return isKnownOrCountry(value.partType)
        ? value.isSTS
            ? `STS ${value.displayName}`
            : value.displayName ?? value.name
        : value.value;
};

export default LocationFormatter;
