import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    SEPARATOR_FORWARD_SLASH,
    SEPARATOR_PLUS,
    DIRECTION_BACKWARD,
    DIRECTION_FORWARD,
    addPart,
    deleteItemAtPosition,
    ENTITY_PART_TYPE_SEPARATOR,
    dragAndDropPart,
} from '../../../../models/common/EntityPart';
import cloneDeep from 'lodash/cloneDeep';

class PopupWindow extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = this.initialState(props);

        this.focus = this.focus.bind(this);
    }

    initialState = (props) => {
        const parts = props.value ?? [];

        return {
            parts,
            selectedValue: null,
            isEditMode: false,
            selectedIndex: null,
            selectedSeparatorIndex: null,
            isSeparatorException: false,
        };
    };

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    handleOnAddValue = (valuePart, tabOutAction) => {
        const parts = cloneDeep(this.state.parts);

        if (this.state.isEditMode && !isNaN(this.state.selectedIndex)) {
            parts.splice(this.state.selectedIndex, 1, valuePart);

            this.setState(
                () => {
                    const newState = {
                        isEditMode: false,
                        selectedvalue: null,
                        selectedIndex: null,
                        parts: parts,
                    };
                    return newState;
                },
                tabOutAction ? tabOutAction : this.focus
            );
        } else {
            parts.push(valuePart);

            this.setState(
                () => {
                    const newState = {
                        parts: [],
                    };
                    newState.parts = this.normalize(parts);
                    return newState;
                },
                tabOutAction ? tabOutAction : this.focus
            );
        }
    };

    handleOnAddSeparator = (separatorPart) => {
        const parts = cloneDeep(this.state.parts);

        parts.push(separatorPart);

        this.setState(() => {
            const newState = {
                parts: [],
                isEditMode: false,
                selectedvalue: null,
                selectedIndex: null,
                selectedSeparatorIndex: null,
                isSeparatorException: false,
            };
            newState.parts = this.normalize(parts);
            return newState;
        }, this.focus);
    };

    handleOnValueEdit = (index) => {
        if (this.state.selectedIndex === index) {
            this.setState({
                selectedValue: null,
                isEditMode: false,
                selectedIndex: null,
                selectedSeparatorIndex: null,
            });
        } else {
            const selectedValue = this.state.parts[index];

            this.setState({
                selectedValue: selectedValue,
                isEditMode: true,
                selectedIndex: index,
                selectedSeparatorIndex: null,
            });
        }
    };

    handleOnPartRemove = (index) => {
        const part = this.state.parts[index];
        const isSeparatorValid =
            part.partType === ENTITY_PART_TYPE_SEPARATOR
                ? this.separatorsValidation(index)
                : true;

        if (isSeparatorValid) {
            let parts = cloneDeep(this.state.parts);
            let position = index;

            const newState = this.deleteAtPosition(
                parts,
                position,
                DIRECTION_FORWARD
            );
            const normalized = this.normalize(newState.parts);

            this.setState(
                {
                    parts: normalized,
                    selectedValue: null,
                    isEditMode: false,
                    selectedIndex: null,
                    selectedSeparatorIndex: null,
                    isSeparatorException: false,
                },
                this.focus
            );
        } else {
            this.setState({
                isSeparatorException: true,
                selectedSeparatorIndex: index,
            });
        }
    };

    resetSeparatorValidation = () => {
        this.setState({ isSeparatorException: false });
    };

    deleteAtPosition(parts, position, direction) {
        let nextPosition =
            direction === DIRECTION_BACKWARD ? position - 1 : position;

        ({ parts } = deleteItemAtPosition(parts, nextPosition, direction));

        return { parts };
    }

    handleSwitchSeparator = (index) => {
        const canSeparatorBeSwitched = this.separatorsValidation(index);

        if (canSeparatorBeSwitched) {
            this.setState((prevState) => {
                let parts = cloneDeep(prevState.parts);
                const separator = parts[index];

                const newSeparator =
                    separator.separatorValue === SEPARATOR_FORWARD_SLASH
                        ? this.props.createSeparatorPart(SEPARATOR_PLUS)
                        : this.props.createSeparatorPart(
                              SEPARATOR_FORWARD_SLASH
                          );

                parts.splice(index, 1, newSeparator);

                return {
                    parts,
                    isSeparatorException: false,
                    selectedSeparatorIndex: null,
                };
            });
        } else {
            this.setState({
                isSeparatorException: true,
                selectedSeparatorIndex: index,
            });
        }
    };

    separatorsValidation = (selectedIndex) => {
        const { parts } = this.state;

        return this.props.separatorsValidation
            ? this.props.separatorsValidation({
                  parts: parts,
                  partType: ENTITY_PART_TYPE_SEPARATOR,
                  selectedIndex: selectedIndex,
              })
            : true;
    };

    handleOnDraggedItem = (sourceIndex, targetIndex) => {
        this.setState((prevState) => {
            const newParts = dragAndDropPart(
                prevState.parts,
                sourceIndex,
                targetIndex
            );

            const normalized = this.normalize(newParts);

            return { parts: normalized, isSeparatorException: false };
        });
    };

    normalize = (parts) => {
        let newParts = [];
        for (const part of parts) {
            const result = addPart(
                newParts,
                part,
                undefined,
                this.props.createSeparatorPart,
                this.props.defaultSeparator || SEPARATOR_FORWARD_SLASH
            );
            newParts = result.parts;
        }

        return newParts;
    };

    formattedValue = () => {
        return this.state.parts;
    };

    checkIsSeparatorException = (index) => {
        return (
            this.state.isSeparatorException &&
            this.state.selectedSeparatorIndex === index
        );
    };

    render() {
        return (
            <div
                className="ag-react-container"
                style={this.props.popupWindowStyles}
            >
                {React.cloneElement(this.props.multipleSelect, {
                    parts: this.state.parts,
                    selectedIndex: this.state.selectedIndex,
                    className: 'location-editor',
                    entityPartTypesOfValuePart:
                        this.props.entityPartTypesOfValuePart,
                    partsFormatterInstance: this.props.partsFormatterInstance,
                    checkIsSeparatorException: this.checkIsSeparatorException,
                    onPartRemove: this.handleOnPartRemove,
                    onValueEdit: this.handleOnValueEdit,
                    onSwitchSeparator: this.handleSwitchSeparator,
                    onDraggedItem: this.handleOnDraggedItem,
                    styles: this.props.multiselectStyles,
                })}
                {React.cloneElement(this.props.input, {
                    context: this.props.context,
                    data: this.props.data,
                    selectedValue: this.state.selectedValue,
                    selectedIndex: this.state.selectedIndex,
                    isEditMode: this.state.isEditMode,
                    ref: this.inputRef,
                    onAddValue: this.handleOnAddValue,
                    onAddSeparator: this.handleOnAddSeparator,
                    onTab: this.props.onTab,
                    onTabBack: this.props.onTabBack,
                    shouldSelectItemOnTab: true,
                    datasetId: this.props.datasetId,
                    parts: this.state.parts,
                    defaultSeparator: this.props.defaultSeparator,
                    partsValidation: this.props.partsValidation,
                    isSeparatorException: this.state.isSeparatorException,
                    resetSeparatorValidation: this.resetSeparatorValidation,
                })}
            </div>
        );
    }
}

PopupWindow.propTypes = {
    input: PropTypes.element.isRequired,
    multipleSelect: PropTypes.element.isRequired,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    parts: PropTypes.array,
    entityPartTypesOfValuePart: PropTypes.array.isRequired,
    partsFormatterInstance: PropTypes.object.isRequired,
    createSeparatorPart: PropTypes.func.isRequired,
};

export default PopupWindow;
