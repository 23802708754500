import isString from 'lodash/isString';

export const TrackingFormatter = ({ value, node }) => {
    if (node && node.group) {
        return '';
    }

    if (!node && !value) return 'N';

    //the formatter is called for the filter values as well (created by the key creator)
    //but node is undefined in this case
    if (node && isString(value)) {
        return value.replace('N', '');
    }
};

export const TrackingFormatterSelectDisplayValue = (value) => {
    if (!value) {
        return '';
    }
    return value;
};
