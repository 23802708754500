import isEqual from 'lodash/isEqual';

const defaultObjEquals = (value1, value2) => {
    if (value1 === undefined && value2 === undefined) {
        return true;
    }

    return isEqual(value1, value2);
};

export default defaultObjEquals;
