import {
    ENTITY_PART_TYPE_RATE,
    ENTITY_PART_TYPE_SEPARATOR,
} from '_legacy/models/common/EntityPart';
import { balticIndexValue, flatRateValue } from './RateTypes';

const START_VALUE_FOR_ROUNDING = 1000;
export const NET_RATE_FIXED_OPTION_NAME = 'NET';

export const createFlatRatePart = (params) => {
    const {
        currency,
        unit,
        amountFrom,
        amountTo,
        bound,
        durationAmountFrom,
        durationAmountTo,
        durationUnit,
    } = params;

    const flatRate = createFlatRate(
        currency,
        unit,
        amountFrom,
        amountTo,
        bound
    );
    const duration = createDuration(
        durationAmountFrom,
        durationAmountTo,
        durationUnit
    );
    const rateValue = createRateValue(flatRateValue, flatRate, null, duration);

    return createPart(rateValue, null, ENTITY_PART_TYPE_RATE);
};

export const createBalticIndexPart = (params) => {
    const {
        amountFrom,
        amountTo,
        index,
        durationAmountFrom,
        durationAmountTo,
        durationUnit,
    } = params;

    const balticIndex = createBalticIndex(amountFrom, amountTo, index);
    const duration = createDuration(
        durationAmountFrom,
        durationAmountTo,
        durationUnit
    );
    const rateValue = createRateValue(
        balticIndexValue,
        null,
        balticIndex,
        duration
    );

    return createPart(rateValue, null, ENTITY_PART_TYPE_RATE);
};

export const createSeparatorRatePart = (character) => {
    return createPart(null, character, ENTITY_PART_TYPE_SEPARATOR);
};

const createFlatRate = (currency, unit, amountFrom, amountTo, bound) => {
    const amount = amountFrom && {
        from: RoundAmount(amountFrom),
        to: RoundAmount(amountTo),
    };
    return {
        currency: currency,
        unit: unit,
        amount: amount,
        bound: bound,
    };
};

const createBalticIndex = (amountFrom, amountTo, index) => {
    const amount = amountFrom && {
        from: amountFrom,
        to: amountTo,
    };
    return {
        amount: amount,
        index: index,
    };
};

const createDuration = (amountFrom, amountTo, unit) => {
    return !amountFrom
        ? null
        : {
              amount: { from: amountFrom, to: amountTo },
              unit: unit,
          };
};

const createRateValue = (type, flatRate, balticIndex, duration) => {
    return {
        type: type,
        flatRate: flatRate,
        balticIndex: balticIndex,
        duration: duration,
    };
};

const RoundAmount = (amount) =>
    amount > START_VALUE_FOR_ROUNDING ? Math.round(amount) : amount;

const createPart = (rateValue, separatorValue, partType) => {
    return {
        partType,
        rateValue,
        separatorValue,
    };
};
