import moment from 'moment';

const LaycanToFormatter = ({ node, value }) => {
    if (node && node.group) {
        return '';
    }

    const laycan = value;

    if (!laycan || !laycan.startDate || !laycan.endDate) {
        return '';
    }

    if (laycan.isUncertain) {
        return '?';
    } else if (laycan.startDate === laycan.endDate) {
        return '';
    } else {
        const date = moment(laycan.endDate);
        return date.isValid() ? date.format('DD MMM YY').toUpperCase() : '';
    }
};

export default LaycanToFormatter;
