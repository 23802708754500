import EntityNodeTree from './EntityNodeTree';
import {
    isRangesLocation,
    isWorldwideZoneLocation,
} from '_legacy/models/LocationPart';

class LocationsNodeTree extends EntityNodeTree {
    initializeNodes(
        customLocations,
        knownLocations = [],
        placeholderLocations,
        countries = []
    ) {
        super.initializeNodes(
            customLocations,
            knownLocations,
            placeholderLocations
        );
        this.initializeLocationNodes(
            customLocations,
            CUSTOM_LOCATION_NODE,
            LocationsNodeTree.CustomLocationPrefix
        );
        this.initializeKnownLocationNodes(knownLocations, countries);
        this.initializeLocationNodes(
            placeholderLocations,
            PLACEHOLDER_LOCATION_NODE,
            LocationsNodeTree.PlaceholderLocationPrefix
        );
    }

    initializeKnownLocationNodes(locations, countries) {
        // We are pushing countries into known locations for sorting them together
        for (const country of countries) {
            locations.push({
                deskZone: country.zone,
                country: {
                    displayName: country.displayName,
                },
                shortName: '',
            });
        }

        this.sortLocations(locations);

        for (const location of locations) {
            if (!location.deskZone) {
                location.deskZone = CUSTOM_LOCATION_NODE;
            }
            if (isWorldwideZoneLocation(location.locationType)) {
                location.deskZone = 'WORLDWIDE';
            }
            if (!this.getNode(location.deskZone)) {
                this.pushParentNode(location.deskZone);
            }
            if (
                location.country?.displayName &&
                !this.getNode(
                    `${location.deskZone}-${location.country.displayName}`
                )
            ) {
                const parent = this.getNode(location.deskZone);
                this.pushLeafNode(
                    `${location.deskZone}-${location.country.displayName}`,
                    location.country.displayName,
                    parent
                );
            }
            if (location.id) {
                const parent = this.getNode(
                    location.country?.displayName
                        ? `${location.deskZone}-${location.country.displayName}`
                        : location.deskZone
                );

                this.pushLeafNode(
                    `${location.deskZone}-${location.id}`,
                    location.shortName,
                    parent
                );
            }
        }
    }

    sortLocations(locations) {
        locations.sort(function (a, b) {
            const getLocationFullName = (location) => {
                let fullName = location.deskZone + getOrderingWeight(location);

                if (location.country?.displayName) {
                    fullName += location.country.displayName;
                }

                if (location.shortName && location.shortName !== '') {
                    fullName += location.shortName;
                }

                return fullName;
            };

            // Locations inside zones hierarhy should have the next ordering:
            // 1) Worldwide location
            // 2) Country locations
            // 3) Zone locations
            // 4) Range locations
            const getOrderingWeight = (location) => {
                if (isWorldwideZoneLocation(location.locationType)) {
                    return '0';
                }

                if (location.country?.displayName) {
                    return '1';
                }

                if (isRangesLocation(location.locationType)) {
                    return '3';
                }

                return '2';
            };

            return getLocationFullName(a) > getLocationFullName(b) ? 1 : -1;
        });
    }

    initializeLocationNodes(locations, nodeName, prefix) {
        if (locations) {
            this.pushParentNode(nodeName);

            const uniqueLocations = [...new Set(locations)].sort();
            const parent = this.getNode(nodeName);

            uniqueLocations.forEach((location) =>
                this.pushLeafNode(prefix + location, location, parent)
            );
        }
    }

    isChecked(id) {
        for (const node of this.getNodesMap().entries()) {
            if (
                ((node[0].includes(id) &&
                    !node[0].includes(
                        LocationsNodeTree.CustomLocationPrefix
                    )) ||
                    node[0] === id) &&
                node[1].state === LocationsNodeTree.States.CHECKED
            ) {
                return true;
            }
        }

        return false;
    }
}

const CUSTOM_LOCATION_NODE = 'UNKNOWN';
const PLACEHOLDER_LOCATION_NODE = 'PLACEHOLDER';

LocationsNodeTree.CustomLocationNode = CUSTOM_LOCATION_NODE;
LocationsNodeTree.PlaceholderLocationNode = PLACEHOLDER_LOCATION_NODE;
LocationsNodeTree.States = EntityNodeTree.States;
LocationsNodeTree.RootNodeKey = EntityNodeTree.RootNodeKey;
LocationsNodeTree.CustomLocationPrefix = EntityNodeTree.CustomEntityPrefix;
LocationsNodeTree.PlaceholderLocationPrefix =
    EntityNodeTree.PlaceholderEntityPrefix;

export default LocationsNodeTree;
