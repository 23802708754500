import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';
import FortCellEditor from '../../../shared/columns/editors/FortCellEditor';

const DwtEditor = forwardRef((props, ref) => {
    const defaultMaxInpunLength = 6;
    const inputRef = useRef();

    const [inputValue, setInputValue] = useState(
        props.charPress ?? props.value ?? ''
    );
    const [shouldSelect, setShouldSelect] = useState(!props.charPress);

    const validationRegex = new RegExp(
        `^((?!(0))[0-9]{0,${defaultMaxInpunLength}})$`
    );

    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();

            if (shouldSelect) {
                inputRef.current.select();
            }
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        const isConsistError = !validationRegex.test(value);

        setInputValue(isConsistError ? inputValue : value);
        setShouldSelect(false);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                if (inputValue === '') {
                    return null;
                }

                return Number(inputValue);
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    return (
        <FortCellEditor focus={focus} {...props}>
            <>
                <input
                    type="text"
                    noValidate
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleChange}
                />
            </>
        </FortCellEditor>
    );
});

export default DwtEditor;
