import React from 'react';
import ReactTooltip from 'react-tooltip';
import './ClarksonsBrokerTooltip.scss';

const ClarksonsBrokerTooltip = (props) => {
    const generateTooltip = (data) => {
        if (
            !data ||
            !data.clarksonsBrokers ||
            data.clarksonsBrokers.length < 1
        ) {
            return null;
        }

        return `
    <table>
      <tr>
        <th>Full Name</th>
        <th>Email Address</th>
      </tr>
      ${generateTableRows(data.clarksonsBrokers)}
    </table>
  `;
    };

    const generateTableRows = (brokers) => {
        return brokers
            .map((b) => {
                return `
          <tr>
            <td>${b.firstName + ' ' + b.lastName}</td>
            <td>${b.emailAddress}</td>
          </tr>
        `;
            })
            .join('');
    };

    return (
        <ReactTooltip
            id={`${props.context.gridId}-clarksons-broker`}
            className="fort-grid-tooltip"
            border={true}
            place="right"
            type="light"
            multiline={true}
            html={true}
            getContent={(rowIndex) => {
                if (props.getRowDataCallback) {
                    const rowNode = props.getRowDataCallback(Number(rowIndex));

                    if (rowNode) {
                        return generateTooltip(rowNode.data);
                    }
                }
                return null;
            }}
        />
    );
};

export default ClarksonsBrokerTooltip;
