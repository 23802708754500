import { blankKeyText } from '../../../constants/gridText';
import LaycanFormatter from '../formatters/LaycanFormatter';

const LaycanKeyCreator = ({ value }) => {
    if (!value) {
        return blankKeyText;
    }

    return LaycanFormatter({ value });
};

export default LaycanKeyCreator;
