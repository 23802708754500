import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';

import group from '../_legacy/reducers/groupReducer';
import status from '../_legacy/reducers/statusReducer';
import notificationMessages from '../_legacy/reducers/notificationMessagesReducer';
import version from '../_legacy/reducers/versionReducer';
import initialState from '../_legacy/reducers/initialState';
import crashReporter from '../_legacy/store/crashReporter';
import layoutsSlice from './feature/layoutsSlice';
import userSlice from './feature/userSlice';
import configureMixpanelEvents from './listener/mixpanelEvents';
import configureSessionPersistenceEvents from './listener/sessionPersistenceEvents';

const listener = createListenerMiddleware();
configureMixpanelEvents(listener as any);
configureSessionPersistenceEvents(listener as any);

const store = configureStore({
    reducer: {
        user: userSlice,
        group,
        status,
        notificationMessages,
        version,
        layouts: layoutsSlice,
    },
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .prepend(listener.middleware)
            .concat(crashReporter),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
