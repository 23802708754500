import initialState from './initialState';
import * as types from '../actions/actionTypes';
import Logger from '../diagnostics/logging/logger';

const userReducer = (state = initialState.user, action) => {
    switch (action.type) {
        case types.USER_GROUP_SET:
            Logger.setGroup(action.group.id, action.group.datasetId);
            return {
                ...state,
                group: action.group,
                headingDefaults: action.headingDefaults,
            };
        case types.USER_CHANGED_GRID_OPTIONS: {
            const newState = {
                ...state,
                gridOptions: {
                    ...state.gridOptions,
                },
            };

            const newGridType = {
                ...state.gridOptions[action.gridType],
            };

            newGridType[action.groupId] = {
                filters: action.filters,
                sortState: action.sortState,
                columnState: action.columnState,
            };

            newState.gridOptions[action.gridType] = newGridType;

            return newState;
        }
        case types.USER_CREATION_INDEX_SET: {
            const newCreationIndex = { ...state.creationIndex };
            newCreationIndex[action.gridType] = action.index;
            return { ...state, creationIndex: newCreationIndex };
        }
        case types.USER_DATASIZE_SET:
            Logger.setDataSize(action.dataSize.value);
            return {
                ...state,
                dataSize: action.dataSize,
            };
        case types.USER_REPORT_TITLES_SET:
            return {
                ...state,
                reportTitles: action.reportTitles,
            };
        case types.TOGGLE_NEW_UI:
            return {
                ...state,
                themeSettings: {
                    ...state.themeSettings,
                    newThemeEnabled: !state.themeSettings.newThemeEnabled,
                },
            };
        case types.TOGGLE_DENSITY_VIEW:
            return {
                ...state,
                themeSettings: {
                    ...state.themeSettings,
                    useCompactDensityView:
                        !state.themeSettings.useCompactDensityView,
                },
            };
        case types.TOGGLE_CONDENSED_VIEW:
            return {
                ...state,
                themeSettings: {
                    ...state.themeSettings,
                    useCondensedView: !state.themeSettings.useCondensedView,
                },
            };
        default:
            return state;
    }
};

export default userReducer;
