import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from '../actions/userActions';
import * as layoutActions from '../actions/layoutActions';
import { authorise } from '../auth/AuthorisationService';
import { bindActionCreators } from 'redux';
import StripedLoader from './common/StripedLoader';
import Logger from '../diagnostics/logging/logger';
import ErrorPage from './ErrorPage';
import graphQlClient from '../api/graphQlClient';
import {
    SAVEDLAYOUT_DELETED_SUBSCRIPTION,
    SAVEDLAYOUT_ADDED_SUBSCRIPTION,
    SAVEDLAYOUT_RENAMED_SUBSCRIPTION,
} from '../api/subscriptions/savedLayoutSubscriptions';
import { authenticationService } from '_legacy/auth/AuthenticationService';
import { refreshUserInfo, userSignedIn } from 'store/feature/userSlice';

export class UnAuthorised extends Component {
    render() {
        return (
            <div>
                Could not load FORT. Either you do not have Cloud permission to
                access FORT or your Cloud account is misconfigured. Please
                contact{' '}
                <a href="mailto:support@clarksons.com">support@clarksons.com</a>
            </div>
        );
    }
}

export class AuthenticateAndAuthorise extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authPending: true,
            isAuthenticated: null,
            error: false,
        };
    }

    reloadPage = () => {
        window.location.reload(true);
    };

    async componentDidMount() {
        try {
            Logger.log('Authenticating the user...');

            await authenticationService.initializeUserManager();

            if (!(await authenticationService.isAuthenticated())) {
                this.setState({ isAuthenticated: false });
                return;
            }

            const authenticationResult = await authorise();

            const isPending =
                authenticationResult && authenticationResult.pending;
            if (isPending) {
                this.setState({ authPending: true });
                return;
            }

            const isAuthenticated =
                authenticationResult && authenticationResult.isAuthorised;

            Logger.log(`Authentication result: ${isAuthenticated}`);

            if (!isAuthenticated) {
                this.setState({ isAuthenticated: false });
                return;
            }

            const adName = authenticationResult.user.ClarksonsADSamAccountName;
            const emailAddress = authenticationResult.user.EmailAddress;

            // Users from outside clarksons domain won't have an adName.
            const username = adName || emailAddress;

            this.setupSubscriptions();

            this.props.dispatch(
                userSignedIn({
                    username,
                    isAdmin: authenticationResult.isAdmin,
                    userTimezone: authenticationResult.user.Timezone,
                })
            );

            const user = await this.props.dispatch(refreshUserInfo()).unwrap();

            Logger.setAuthenticatedUserContext(
                username,
                user.userId.toString()
            );

            Logger.log(`User successfully authenticated`);

            if (this.props.runOnUserSuccessfullyAuthorised) {
                this.props.runOnUserSuccessfullyAuthorised(user.groups);
            }

            this.setState({ isAuthenticated: true });
        } catch (err) {
            Logger.exception(err);
            this.setState({ error: true, isAuthenticated: false });
            return;
        }
    }

    setupSubscriptions = () => {
        this.removeSubscriptions();

        const selectedLayoutId = this.props.selectedLayout?.selectedLayoutId;

        if (selectedLayoutId) {
            this.savedLayoutDeletedSubscription = graphQlClient()
                .subscribe({
                    query: SAVEDLAYOUT_DELETED_SUBSCRIPTION,
                    variables: {
                        layoutId: selectedLayoutId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleSavedLayoutDeleted,
                    error(err) {
                        Logger.warn(
                            'SUBSCRIPTION SAVED LAYOUT DELETED ' + err.message
                        );
                    },
                });

            this.savedLayoutAddedSubscription = graphQlClient()
                .subscribe({
                    query: SAVEDLAYOUT_ADDED_SUBSCRIPTION,
                    variables: {
                        layoutId: selectedLayoutId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleSavedLayoutAdded,
                    error(err) {
                        Logger.warn(
                            'SUBSCRIPTION SAVED LAYOUT ADDED' + err.message
                        );
                    },
                });

            this.savedLayoutUpdatedSubscription = graphQlClient()
                .subscribe({
                    query: SAVEDLAYOUT_RENAMED_SUBSCRIPTION,
                    variables: {
                        layoutId: selectedLayoutId,
                    },
                    fetchPolicy: 'no-cache',
                })
                .subscribe({
                    next: this.handleSavedLayoutUpdated,
                    error(err) {
                        Logger.warn(
                            'SUBSCRIPTION SAVED LAYOUT UPDATED' + err.message
                        );
                    },
                });
        }
    };

    handleSavedLayoutDeleted = () => {
        this.handleSavedLayoutChange();
    };

    handleSavedLayoutAdded = () => {
        this.handleSavedLayoutChange();
    };

    handleSavedLayoutUpdated = () => {
        this.handleSavedLayoutChange();
    };

    handleSavedLayoutChange = () => {
        // If there is a change on a layout then we'll just refresh the Fort app on user's other open tabs
        // and pull the latest data from CosmosDB database to reflect most recent updates.
        // In future we may implement a smarter way to handle this scenario
        this.reloadPage();
    };

    removeSubscriptions = () => {
        if (this.savedLayoutDeletedSubscription) {
            this.savedLayoutDeletedSubscription.unsubscribe();
        }
    };

    render() {
        const { isAuthenticated, error } = this.state;

        if (isAuthenticated === null) {
            return (
                <div style={{ marginTop: '270px' }}>
                    <StripedLoader />
                </div>
            );
        } else if (isAuthenticated) {
            return this.props.children;
        } else if (error) {
            return <ErrorPage />;
        } else {
            return <UnAuthorised />;
        }
    }
}

const mapStateToProps = (state) => {
    const props = {
        selectedLayout: state.layouts.selectedLayout,
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        actions: bindActionCreators(
            { ...userActions, ...layoutActions },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthenticateAndAuthorise);
