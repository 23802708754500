import React, { Component } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';

export class CompanyConfirmation extends Component {
    constructor(props) {
        super(props);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.state = { confirmed: false };
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeydown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    }

    handleKeydown(e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === 'Enter') {
            this.props.onClose();
        }
    }

    handleOnChange(e, data) {
        this.setState({ confirmed: data.checked });
    }

    render() {
        return (
            <div
                id="company-confirmation-modal"
                className="company-confirmation"
            >
                <h3>Are you sure?</h3>
                <div>
                    You are about to permanently add this value to the database:
                </div>
                <div>
                    <b>{this.props.companyName}</b>
                </div>
                <div>
                    If you think this is a mistake or that the value already
                    exists, please select `Cancel`
                </div>
                <Checkbox
                    checked={this.state.confirmed}
                    onChange={this.handleOnChange}
                    label="'Tick' this to permanently add this value."
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button basic onClick={this.props.onClose}>
                        CANCEL
                    </Button>
                    {this.state.confirmed && (
                        <Button primary onClick={this.props.onConfirm}>
                            SAVE
                        </Button>
                    )}
                    {!this.state.confirmed && (
                        <Button primary disabled>
                            SAVE
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

export default CompanyConfirmation;
