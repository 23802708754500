import PencilIcon from '_legacy/components/icons/PencilIcon';
import { useState } from 'react';
import { SavedLayoutModel } from 'store/feature/layoutsSlice/models';
import LayoutManagerItemInput from './LayoutManagerItemInput';
import './LayoutManagerPanel.scss';

const LayoutManagerItemLabel: React.FC<{
    model: SavedLayoutModel;
    showControls: boolean;
}> = ({ model, showControls }) => {
    const [showEdit, setShowEdit] = useState(false);

    return (
        <div className="layoutManager-table-cell">
            {showEdit ? (
                <LayoutManagerItemInput
                    model={model}
                    onClose={() => setShowEdit(false)}
                />
            ) : (
                <>
                    <div
                        className="layoutManager-layout-name"
                        data-cy="layout-name"
                    >
                        {model.name}
                    </div>
                    {showControls && (
                        <div
                            data-cy="layout-name-edit-button"
                            className="layoutManager-pencil"
                            onClick={() => setShowEdit(true)}
                        >
                            <PencilIcon />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default LayoutManagerItemLabel;
