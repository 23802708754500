import Logger from '../../../diagnostics/logging/logger';
import { cloneDeep } from 'lodash';

export const createRumouredMenuItem = (props, node, gridId) => {
    const { group, data } = node;

    if (!group && data) {
        const value = data['rumoured'];

        const title = value ? 'Un-Rumoured Row' : 'Rumoured Row';
        const tooltip = value
            ? 'Mark this row as un-rumoured'
            : 'Mark this row as rumoured';

        return {
            name: title,
            action: () => {
                if (!group && data) {
                    const clonedData = cloneDeep(data);
                    if (value) {
                        Logger.trackEvent(
                            'grid.context.makeUnRumoured',
                            gridId
                        );

                        props.onMakeUnRumoured(clonedData);
                    } else {
                        Logger.trackEvent('grid.context.makeRumoured', gridId);

                        props.onMakeRumoured(clonedData);
                    }
                }
            },
            tooltip: tooltip,
        };
    } else {
        return null;
    }
};
