import React from 'react';

const Lightbulb = (props) => (
    <svg
        width="14px"
        height="20px"
        viewBox="0 0 14 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
        {...props}
    >
        <defs>
            <path
                d="M14.8502,13.0996 L13.9992,13.7006 L13.9992,15.9996 L9.9992,15.9996 L9.9992,13.7006 L9.1502,13.0996 C7.8002,12.1606 6.9992,10.6296 6.9992,8.9996 C6.9992,6.2406 9.2402,3.9996 11.9992,3.9996 C14.7602,3.9996 16.9992,6.2406 16.9992,8.9996 C16.9992,10.6296 16.2002,12.1606 14.8502,13.0996 Z M11.9992,1.9996 C8.1402,1.9996 4.9992,5.1396 4.9992,8.9996 C4.9992,11.3796 6.1902,13.4696 7.9992,14.7406 L7.9992,16.9996 C7.9992,17.5496 8.4502,17.9996 8.9992,17.9996 L14.9992,17.9996 C15.5502,17.9996 15.9992,17.5496 15.9992,16.9996 L15.9992,14.7406 C17.8102,13.4696 18.9992,11.3796 18.9992,8.9996 C18.9992,5.1396 15.8602,1.9996 11.9992,1.9996 Z M8.9992,20.9996 C8.9992,21.5496 9.4502,21.9996 9.9992,21.9996 L13.9992,21.9996 C14.5502,21.9996 14.9992,21.5496 14.9992,20.9996 L14.9992,19.9996 L8.9992,19.9996 L8.9992,20.9996 Z"
                id="path-1"
            />
        </defs>
        <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Multi-view-V.2-Copy-8"
                transform="translate(-1372.000000, -336.000000)"
            >
                <g
                    id="Icons/bulb-outline"
                    transform="translate(1367.000000, 334.000000)"
                >
                    <g
                        id="z_-Padding/Icons/Icon_Padding-_Null"
                        transform="translate(-1.000000, -1.000000)"
                    />
                    <polygon
                        id="Clip-2"
                        points="5 2 18.9992 2 18.9992 21.9996 5 21.9996"
                    />
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1" />
                    </mask>
                    <use
                        id="Mask"
                        fill="#FFFFFF"
                        fillRule="evenodd"
                        xlinkHref="#path-1"
                    />
                    <g
                        id="Colours/0_White"
                        mask="url(#mask-2)"
                        fill="#FFFFFF"
                        fillRule="evenodd"
                    >
                        <rect
                            id="Rectangle"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Lightbulb;
