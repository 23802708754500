import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import FortCellEditor from '../../../shared/columns/editors/FortCellEditor';
import DurationInput from '../inputs/DurationInput';
import MultipleSelect from '../../../shared/columns/inputs/MultipleSelect';
import PopupWindow from '../../../shared/columns/popupWindows/PopupWindow';
import {
    normalizeParts,
    trimTrailingSeparators,
    SEPARATOR_PLUS,
} from '../../../../models/common/EntityPart';
import { createSeparatorDurationPart } from '../models/DurationPart';
import { ENTITY_PART_TYPE_DURATION } from '../../../../models/common/EntityPart';
import { durationFormatterInstance } from '../formatters/DurationFormatter';

const DurationEditor = forwardRef((props, ref) => {
    const selectRef = useRef();

    const focus = () => {
        selectRef.current.focus();
    };

    const handleOnTab = () => props.api.tabToNextCell();
    const handleOnTabBack = () => props.api.tabToPreviousCell();
    const handleOnEnter = () => props.stopEditing();

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                if (selectRef.current.formattedValue().length > 0) {
                    var parts = normalizeParts(
                        selectRef.current.formattedValue().map((part) => {
                            return {
                                durationValue: part.durationValue,
                                separatorValue: part.separatorValue,
                                partType: part.partType,
                            };
                        }),
                        createSeparatorDurationPart,
                        SEPARATOR_PLUS
                    );
                    return trimTrailingSeparators(parts);
                } else {
                    return null;
                }
            },

            isPopup() {
                return true;
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    return (
        <FortCellEditor blockNavigation={false} {...props}>
            <PopupWindow
                ref={selectRef}
                context={props.context}
                value={props.value}
                onTab={handleOnTab}
                onTabBack={handleOnTabBack}
                onEnter={handleOnEnter}
                shouldSelectItemOnTab={true}
                inputClass="ag-react-container"
                createSeparatorPart={createSeparatorDurationPart}
                entityPartTypesOfValuePart={[ENTITY_PART_TYPE_DURATION]}
                partsFormatterInstance={durationFormatterInstance}
                defaultSeparator={SEPARATOR_PLUS}
                multipleSelect={<MultipleSelect />}
                input={<DurationInput initialChar={props.charPress} />}
            ></PopupWindow>
        </FortCellEditor>
    );
});

export default DurationEditor;
