import isString from 'lodash/isString';
import { quantityFormatterInstance } from '../formatters/quantityFormatter';
import { DEFAULT_QTY_UNIT } from '_legacy/constants/default.values';

export const MultipleCargoQuantityFormatter = (params, hideDefaultQtyUnit) => {
    const { value, context } = params;
    if (!value || value.length === 0) {
        return '';
    }

    if (isString(value)) {
        //formatted already
        return value;
    }

    const text = [];

    if (!Array.isArray(value)) {
        text.push(
            quantityFormatterInstance.format(value, context?.quantityFormat)
        );
    } else {
        value.forEach((part) => {
            text.push(
                quantityFormatterInstance.format(part, context?.quantityFormat)
            );
        });
    }

    if (hideDefaultQtyUnit) {
        return text
            .join(' ')
            .replace(new RegExp(`\\s${DEFAULT_QTY_UNIT}`, 'g'), '');
    }

    return text.join(' ');
};
