import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';
import { VESSEL_POSITION_QUERY } from '../../api/queries/VesselQueries';
import VoyageActivityHistory from './VoyageActivityHistory';

export class VoyageActivity extends Component {
    render() {
        const selectedEntities = this.props.selectedFixtures;

        let selectedEntity = null;

        if (selectedEntities.length === 1) {
            selectedEntity = selectedEntities[0];
        } else {
            return <span>You must select a single Fixture</span>;
        }

        const hasImo =
            selectedEntity.data.vessel != null &&
            selectedEntity.data.vessel.imo != null;

        return (
            <div>
                {hasImo && (
                    <Query
                        query={VESSEL_POSITION_QUERY}
                        variables={{
                            imo: selectedEntity.data.vessel.imo,
                        }}
                        fetchPolicy="no-cache"
                    >
                        {({ loading, error, data }) => {
                            if (loading) {
                                return <span>Loading voyage activity...</span>;
                            } else if (error) {
                                return (
                                    <span>
                                        An error occurred loading voyage
                                        activity{' '}
                                    </span>
                                );
                            } else {
                                return (
                                    <div>
                                        <h4>Fixture ID: {selectedEntity.id}</h4>
                                        <VoyageActivityHistory
                                            items={data.vesselPositions}
                                        />
                                    </div>
                                );
                            }
                        }}
                    </Query>
                )}
            </div>
        );
    }
}

export default VoyageActivity;
