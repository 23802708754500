class ShortHandQuantityFormatService {
    formatQuantityAccordingToFormat = (quatityPart) => {
        return {
            ...quatityPart,
            quantityValue: {
                ...quatityPart.quantityValue,
                amount: {
                    from: this.formatNumericalValue(
                        quatityPart.quantityValue.amount.from
                    ),
                    to: this.formatNumericalValue(
                        quatityPart.quantityValue.amount.to
                    ),
                },
            },
        };
    };

    formatNumericalValue = (value) => {
        if (value && Number(value)) {
            const number = Number(value);
            const numberForFormatting = 1000;
            return number > numberForFormatting
                ? Math.round(number / numberForFormatting)
                : Number((number / numberForFormatting).toFixed(1));
        }
        return value;
    };
}

export default ShortHandQuantityFormatService;
