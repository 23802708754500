import React, { Component } from 'react';
import Menu from './ui/Menu';
import NavigationMenuItem from './ui/NavigationMenuItem';
import './ui/Menu.scss';

class Header extends Component {
    render() {
        const { showAdmin } = this.props;
        return (
            <div style={{ height: '100%', minHeight: '36px' }}>
                <Menu>
                    <NavigationMenuItem
                        to="/orders"
                        isDefaultMenuItem={true}
                        onTabSelect={this.props.onTabSelect}
                    >
                        Orders
                    </NavigationMenuItem>
                    <NavigationMenuItem
                        to="/fixtures"
                        onTabSelect={this.props.onTabSelect}
                    >
                        Fixtures
                    </NavigationMenuItem>
                    {showAdmin && (
                        <NavigationMenuItem
                            id="admin-page-nav"
                            to="/admin/groups"
                            onTabSelect={this.props.onTabSelect}
                        >
                            Admin
                        </NavigationMenuItem>
                    )}
                </Menu>
            </div>
        );
    }
}

export default Header;
