import React, { Component } from 'react';
import moment from 'moment';
import FilterCheckbox from './FilterCheckbox';
import { blankKeyText } from '../../../constants/gridText';

class LaycanMonthFilter extends Component {
    constructor(props) {
        super(props);

        this.handleBlanksChange = this.handleBlanksChange.bind(this);
        this.handleAllDatesChange = this.handleAllDatesChange.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);

        this.updateMonthOptions = this.updateMonthOptions.bind(this);

        this.clearFilter = this.clearFilter.bind(this);

        const months = this.generateFilterOptions();

        this.state = { showBlanks: true, showAllDates: true, months };
    }

    generateFilterOptions() {
        var now = moment.utc();

        const options = [];

        let currentDate = now.add(-9, 'M');

        for (var i = 0; i < 12; i++) {
            currentDate.startOf('month');
            const startDate = currentDate.utc().toDate();

            currentDate.endOf('month');
            const endDate = currentDate.utc().toDate();

            options.push({
                key: i,
                label: currentDate.format('MMM YY'),
                startDate,
                endDate,
                checked: false,
            });

            currentDate = currentDate.add(1, 'M');
        }

        return options;
    }

    isFilterActive() {
        return !this.state.showBlanks || !this.state.showAllDates;
    }

    doesFilterPass(params) {
        const laycan = this.props.valueGetter(params.node);

        if (!laycan && this.state.showBlanks) {
            return true;
        }

        if (laycan && this.state.showAllDates) {
            return true;
        }

        if (laycan) {
            const laycanStartDate = moment.utc(
                laycan.startDate,
                'YYYY-MM-DD',
                true
            );

            for (let i = 0; i < this.state.months.length; i++) {
                const m = this.state.months[i];

                if (m.checked) {
                    const insideMonth = laycanStartDate.isBetween(
                        m.startDate,
                        m.endDate,
                        null,
                        '[]'
                    );

                    if (insideMonth) {
                        return true;
                    }
                }
            }

            return false;
        }

        return false;
    }

    getModel() {
        if (this.isFilterActive()) {
            const activeMonths = [];

            this.state.months.forEach((m) => {
                if (m.checked) {
                    activeMonths.push(m.key);
                }
            });

            if (this.state.showAllDates) {
                activeMonths.push(-1);
            }

            if (this.state.showBlanks) {
                activeMonths.push(-2);
            }

            return JSON.stringify(activeMonths);
        } else {
            return null;
        }
    }

    setModel(model) {
        if (model) {
            const activeMonths = JSON.parse(model);

            const months = this.state.months.slice();

            // Assume all dates are inactive!
            //
            months.forEach((m) => (m.checked = false));

            let showAllDates = false;
            let showBlanks = false;

            activeMonths.forEach((m) => {
                if (m === -1) {
                    showAllDates = true;
                } else if (m === -2) {
                    showBlanks = true;
                } else {
                    months[m].checked = true;
                }
            });

            this.setState({ months, showAllDates, showBlanks }, () =>
                this.props.filterChangedCallback()
            );
        } else {
            this.clearFilter();
        }
    }

    clearFilter() {
        const months = this.state.months.slice();
        months.forEach((m) => (m.checked = false));
        this.setState({ showBlanks: true, showAllDates: true, months }, () =>
            this.props.filterChangedCallback()
        );
    }

    handleOnChange(key, checked) {
        const months = this.state.months.slice();
        months[key].checked = checked;

        const checkedOptions = months.filter((m) => m.checked);

        // If there are any checked options, unchecked SHOW ALL DATES
        //
        this.setState(
            {
                months,
                showBlanks: checkedOptions.length === 0,
                showAllDates: checkedOptions.length === 0,
            },
            () => this.props.filterChangedCallback()
        );
    }

    handleBlanksChange(key, checked) {
        this.setState({ showBlanks: checked }, () =>
            this.props.filterChangedCallback()
        );
    }

    updateMonthOptions() {
        const months = this.state.months.slice();
        months.forEach((m) => (m.checked = false));
        this.setState({ months }, () => this.props.filterChangedCallback());
    }

    handleAllDatesChange(key, checked) {
        this.setState({ showAllDates: checked }, () => {
            this.updateMonthOptions();
        });
    }

    render() {
        const options = this.state.months.map((m) => (
            <FilterCheckbox
                key={m.key}
                index={m.key}
                checked={m.checked}
                onChange={this.handleOnChange}
                label={m.label}
            />
        ));

        const heightMeasure = options.length * 22;
        const height = `${heightMeasure}px`;

        return (
            <div className="ag-filter">
                <div className="ag-filter-body-wrapper">
                    <div>
                        <div
                            className="ag-filter-header-container"
                            style={{
                                borderBottom: '1px solid #e0e0e0',
                                height: '50px',
                                position: 'relative',
                            }}
                        >
                            <FilterCheckbox
                                index={0}
                                checked={this.state.showBlanks}
                                onChange={this.handleBlanksChange}
                                label={blankKeyText}
                            />

                            <FilterCheckbox
                                index={1}
                                checked={this.state.showAllDates}
                                onChange={this.handleAllDatesChange}
                                label="ALL DATES"
                            />
                        </div>
                        <div id="richList" className="ag-set-filter-list">
                            <div className="ag-virtual-list-viewport">
                                <div
                                    className="ag-virtual-list-container"
                                    style={{ height: height }}
                                >
                                    {options}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ag-filter-apply-panel" id="applyPanel">
                        <button
                            type="button"
                            id="clearButton"
                            onClick={this.clearFilter}
                        >
                            Clear
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default LaycanMonthFilter;
