export const isEditableRow = (params) => {
    const isEditable =
        params &&
        params.data &&
        params.data.state !== 'deleted' &&
        params.data.state !== 'Deleted' &&
        params.data.state !== 'Converted' &&
        params.data.state !== 'Withdrawn' &&
        params.context.canEdit;

    return isEditable;
};
