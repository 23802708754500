import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import '../styles/rateInputStyles.css';

const NetRate = (props) => {
    const handleKeyDown = (e) => {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            e.preventDefault();
        }
    };

    const handleNetToggleChange = (e) => {
        props.handleNetRate(e.target.checked);
    };

    return (
        <div
            style={{
                display: 'inline-flex',
                float: 'right',
            }}
        >
            <div>Net Rate</div>
            <Toggle
                defaultChecked={props.isNetRate}
                icons={false}
                onChange={handleNetToggleChange}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
};

export default NetRate;
