import React from 'react';
import WarningIconWhite from '../../icons/WarningIconWhite';

const ErrorMessage = (props) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',
            minHeight: '3em',
        }}
        className="error-message"
        {...props}
    >
        <WarningIconWhite
            style={{
                marginRight: '1em',
            }}
        />
        <span style={{ width: '100%' }}>{props.children}</span>
    </div>
);

export default ErrorMessage;
