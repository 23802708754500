import initialState from './initialState';
import * as types from '../actions/actionTypes';

const statusReducer = (state = initialState.status, action) => {
    switch (action.type) {
        case types.SOCKET_DISCONNECTED:
            return {
                ...state,
                hasSocketDisconnected: true,
                hasSocketReconnected: false,
            };
        case types.SOCKET_RECONNECTED:
            return {
                ...state,
                hasSocketReconnected: true,
                hasSocketDisconnected: false,
            };
        case types.SOCKET_CONNECTED:
            return {
                ...state,
                hasSocketReconnected: false,
                hasSocketDisconnected: false,
            };
        default:
            return state;
    }
};

export default statusReducer;
