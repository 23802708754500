import { CSSTransition } from 'react-transition-group';
import { Table } from 'semantic-ui-react';
import cn from 'classnames';
import './LayoutManagerPanel.scss';
import { useAppSelector } from 'store/hooks';
import { layoutsSelectors } from 'store/feature/layoutsSlice';
import LayoutManagerItem from './LayoutManagerItem';
import styles from './LayoutManagerPanel.module.scss';
import { createPortal } from 'react-dom';

export interface LayoutManagerPanelProps {
    show: boolean;
    onClose: () => void;
}

const LayoutManagerPanel: React.FC<LayoutManagerPanelProps> = ({
    show,
    onClose,
}) => {
    const layouts = useAppSelector(layoutsSelectors.selectedDatasetLayouts);

    return (
        <>
            {createPortal(
                <CSSTransition
                    classNames="activity"
                    in={show}
                    timeout={300}
                    unmountOnExit
                >
                    <div
                        id="layoutManager"
                        className={cn(
                            styles.layoutManager,
                            'layoutManager',
                            'layoutManager-compact'
                        )}
                    >
                        <div
                            className={'layoutManager-topbar'}
                            id="layoutManager-menu"
                            onClick={onClose}
                        >
                            <div className="layoutManager-topbar-chevron" />
                            <div className="layoutManager-topbar-title">
                                Layout Manager
                            </div>
                        </div>
                        <div className="layoutManager-content">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th style={{ minWidth: 205 }}>Name</th>
                                        <th>Last saved</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {layouts.map((model) => (
                                        <LayoutManagerItem model={model} />
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </CSSTransition>,
                document.body
            )}
        </>
    );
};

export default LayoutManagerPanel;
