import { isEmpty } from 'lodash';
import React, {
    useRef,
    forwardRef,
    useImperativeHandle,
    useState,
    useEffect,
} from 'react';
import CommissionFormatter from '../formatters/CommissionFormatter';
import FortCellEditor from '_legacy/modules/shared/columns/editors/FortCellEditor';

const CommissionEditor = forwardRef((props, ref) => {
    const inputRef = useRef();

    const [inputValue, setInputValue] = useState(props.value ?? '');

    useEffect(() => {
        const initialEvent = {
            target: {
                value: getFormatedCellValue(inputValue) + props.charPress,
            },
        };
        handleChange(initialEvent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validationRegex = new RegExp('^((\\d{0,2}(\\.\\d{0,2})?)|(100))$');

    const focus = () => {
        inputRef.current.focus();
    };

    const validateInputValue = (value) => {
        const commissions = value.toString().split('/');
        if (commissions.length === 2) {
            return (
                validationRegex.test(commissions[0]) &&
                validationRegex.test(commissions[1]) &&
                Number(commissions[0]) + Number(commissions[1]) <= 100
            );
        }
        return validationRegex.test(value);
    };

    const getValidatedNumber = (value) => {
        return !value || value === 0 || Number.isNaN(value)
            ? null
            : Number(value);
    };

    const handleChange = (event) => {
        const value = event.target.value;
        const isValid = validateInputValue(value);

        setInputValue(isValid ? value : inputValue);
    };

    const getFormatedCellValue = (value) => {
        const formatedValue = CommissionFormatter({ value });
        return !isEmpty(formatedValue) ? formatedValue : value;
    };

    const getCommissionObject = (value) => {
        if (value.commissionValue) {
            return value;
        }

        if (value && Number(value) !== 0) {
            const commissions = value.toString().split('/');
            if (
                commissions.length === 2 &&
                Number(commissions[0]) === 0 &&
                !getValidatedNumber(commissions[1])
            ) {
                return null;
            }

            return {
                commissionValue: getValidatedNumber(commissions[0]),
                brokersCommissionValue: getValidatedNumber(commissions[1]),
            };
        }

        return null;
    };

    useImperativeHandle(ref, () => {
        return {
            getValue() {
                return getCommissionObject(inputValue);
            },

            afterGuiAttached() {
                focus();
            },
        };
    });

    return (
        <FortCellEditor focus={focus} {...props}>
            <>
                <input
                    type="text"
                    noValidate
                    ref={inputRef}
                    value={getFormatedCellValue(inputValue)}
                    onChange={handleChange}
                />
            </>
        </FortCellEditor>
    );
});

export default CommissionEditor;
