import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { CloseIcon } from 'common';
import GridTypeContext from 'hooks/common/GridTypeContext';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import {
    createTemplateLayout,
    createUserLayout,
    layoutsSelectors,
} from 'store/feature/layoutsSlice';
import { userSelectors } from 'store/feature/userSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export interface LayoutSaveAsForm {
    layoutName: string;
    isTemplate: boolean;
}

export interface CreateLayoutDialogProps {
    open: boolean;
    onClose: () => void;
}

const CreateLayoutDialogForm: React.FC<
    CreateLayoutDialogProps & { onSave: (data: LayoutSaveAsForm) => void }
> = ({ open, onClose, onSave }) => {
    const layouts = useAppSelector(layoutsSelectors.selectedDatasetLayouts);
    const isAdmin = useAppSelector(userSelectors.isAdmin);

    const [layoutName, setLayoutName] = useState('');
    const [isTemplate, setIsTemplate] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Name is required.');

    const handleLayoutNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.value) {
            setErrorMessage('Name is required.');
        } else if (moment(e.target.value, moment.ISO_8601, true).isValid()) {
            setErrorMessage('Name is invalid.');
        } else if (
            layouts.find(
                (x) =>
                    x.name.toLowerCase() === e.target.value.toLowerCase().trim()
            )
        ) {
            setErrorMessage('There is a layout with this name already.');
        } else {
            setErrorMessage('');
        }

        setLayoutName(e.target.value);
    };

    const hasErrors = errorMessage.length > 0;

    const handleSave = () => {
        if (hasErrors) {
            return;
        }

        if (isTemplate && !isAdmin) {
            setErrorMessage('You do not have permission to save as template.');
            return;
        }

        onSave({ layoutName, isTemplate });
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            handleSave();
        } else if (e.key === 'Escape') {
            onClose();
        }
    };
    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClose();
    };

    return (
        <Dialog
            onClick={(e) => e.stopPropagation()}
            onClose={onClose}
            aria-labelledby="layout-save-as-dialog-title"
            open={open}
            PaperProps={{ sx: { width: '40%' } }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="layout-save-as-dialog-title">
                Save As...
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={(theme) => ({
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: theme.palette.grey[500],
                })}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                <Stack spacing={1}>
                    <TextField
                        autoFocus
                        fullWidth
                        label="Layout Name"
                        onChange={handleLayoutNameChange}
                        onKeyDown={handleKeyDown}
                        value={layoutName}
                        error={hasErrors}
                        helperText={errorMessage}
                    />
                    {isAdmin && (
                        <>
                            <FormControl>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                value={isTemplate}
                                                onChange={(_, checked) =>
                                                    setIsTemplate(checked)
                                                }
                                            />
                                        }
                                        label="Save as template"
                                    />
                                </FormGroup>
                            </FormControl>
                            {isTemplate && (
                                <Alert severity="warning">
                                    <AlertTitle>Warning</AlertTitle>
                                    Creating a new template layout will affect
                                    <Typography
                                        component="span"
                                        fontWeight={'bold'}
                                    >
                                        {' all users '}
                                    </Typography>
                                    in this dataset.
                                </Alert>
                            )}
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    autoFocus
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                    disabled={hasErrors}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const CreateLayoutDialog: React.FC<CreateLayoutDialogProps> = ({
    open,
    onClose,
}) => {
    const dispatch = useAppDispatch();
    const gridType = useContext(GridTypeContext);

    const handleSave = ({ layoutName, isTemplate }: LayoutSaveAsForm) => {
        let task: Promise<any>;
        if (isTemplate) {
            task = dispatch(
                createTemplateLayout({ name: layoutName, gridType })
            );
        } else {
            task = dispatch(createUserLayout({ name: layoutName, gridType }));
        }
        task.then(onClose);
    };

    return (
        <CreateLayoutDialogForm
            open={open}
            onClose={onClose}
            onSave={handleSave}
        />
    );
};

export default CreateLayoutDialog;
