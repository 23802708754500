export const KEY_BACKSPACE = 8;
export const KEY_TAB = 9;
export const KEY_NEW_LINE = 10;
export const KEY_ENTER = 13;
export const KEY_SHIFT = 16;
export const KEY_ESCAPE = 27;
export const KEY_SPACE = 32;
export const KEY_LEFT = 37;
export const KEY_UP = 38;
export const KEY_RIGHT = 39;
export const KEY_DOWN = 40;
export const KEY_DELETE = 46;
export const KEY_A = 65;
export const KEY_C = 67;
export const KEY_V = 86;
export const KEY_D = 68;
export const KEY_X = 88;
export const KEY_F2 = 113;
export const KEY_PAGE_UP = 33;
export const KEY_PAGE_DOWN = 34;
export const KEY_PAGE_HOME = 36;
export const KEY_PAGE_END = 35;
export const KEY_PLUS = 43;
export const KEY_FORWARD_SLASH = 191;
export const KEY_EQUAL = 187;
