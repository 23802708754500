import React from 'react';

const WithFilters = (Component) => {
    const WithFiltersComponent = (props) => {
        const handleFilterChange = (event, stateFilters, setFilters) => {
            const filterName = event.target.name;
            const filters = [...stateFilters];
            const filterIndex = filters.findIndex((f) => f.key === filterName);
            const filter = {
                ...filters[filterIndex],
                checked: event.target.checked,
            };
            filters[filterIndex] = filter;

            updateLayoutsState(event, filter);
            setFilters(filters);
        };

        const updateLayoutsState = (event, filter) => {
            let newFilters = { ...props.currentFilters };

            if (event.target.checked) {
                newFilters[filter.key] = filter.value;
            } else {
                delete newFilters[filter.key];
            }

            const newLayoutOptions = {
                ...props.currentOptions,
                filterOptions: newFilters,
            };

            props.onGridOptionsChanged(newLayoutOptions);
        };

        const clearUnsavedFilters = (unsavedFilters) => {
            let newFilters = { ...props.currentFilters };

            for (const filter of unsavedFilters) {
                delete newFilters[filter.key];
            }

            const currentLayoutOptions = {
                ...props.currentOptions,
                filterOptions: newFilters,
            };

            props.onGridOptionsChanged(currentLayoutOptions);
        };

        return (
            <Component
                {...props}
                handleFilterChange={handleFilterChange}
                clearUnsavedFilters={clearUnsavedFilters}
            />
        );
    };

    return WithFiltersComponent;
};

export default WithFilters;
