export const numberToExcelColumnLetters = (number) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let excelLetters = '';

    while (number >= 0) {
        excelLetters = chars[number % chars.length] + excelLetters;
        number = Math.floor(number / chars.length) - 1;
    }

    return excelLetters;
};
