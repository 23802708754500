import naturalSortComparator from '../../../../components/grid/comparators/NaturalSortComparator';

// Ensures numbers with comma's are correctly sorted.
const CargoQuantityPartFilterComparator = (a, b) => {
    a = a || '';
    b = b || '';
    return naturalSortComparator(a.replace(/,/g, ''), b.replace(/,/g, ''));
};

export default CargoQuantityPartFilterComparator;
