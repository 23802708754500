import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import { getAvailableMinMaxUnits } from '_legacy/modules/shared/columns/models/DurationUnits';
import {
    clearInput,
    validateSingleValue,
} from '../tools/MinMaxDurationValidator';

const MinMaxDuration = (props) => {
    const units = getAvailableMinMaxUnits();
    const didMountRef = useRef(true);

    const initialState = {
        unit: units[0],
        minValue: '',
        maxValue: '',
        isEditMode: true,
        selectedIndex: null,
        isSubmitted: false,
        isMinMaxValid: true,
        isMinValueValid: true,
        isMaxValueValid: true,
    };

    const selectedMinMaxDuration =
        props.selectedValue?.durationValue.minMaxDuration;

    const [state, setState] = useState(initialState);

    const checkIfChangesAreFromUI = (
        isEditMode,
        selectedIndex,
        isSubmitted,
        isMinMaxValid
    ) => {
        return (
            isEditMode === state.isEditMode &&
            selectedIndex === state.selectedIndex &&
            isSubmitted === state.isSubmitted &&
            isMinMaxValid === state.isMinMaxValid
        );
    };

    useEffect(() => {
        if (didMountRef.current) {
            const { isEditMode, selectedIndex, isSubmitted, isMinMaxValid } =
                props;
            if (
                checkIfChangesAreFromUI(
                    isEditMode,
                    selectedIndex,
                    isSubmitted,
                    isMinMaxValid
                )
            ) {
                return;
            }
            if (isEditMode === true && selectedIndex !== state.selectedIndex) {
                updateSelectedDuration();
            }
            if (
                isSubmitted !== state.isSubmitted &&
                isMinMaxValid !== state.isMinMaxValid
            ) {
                onSubmit();
            }
        } else {
            didMountRef.current = true;
            setState(initialState);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const handleUnitChange = (event) => {
        setState({ ...state, unit: event });
        handleChange(event, state.minValue, state.maxValue);
    };

    const handleMinValueChange = (event) => {
        const cleanedValue = clearInput(event.target.value);
        setState({ ...state, minValue: cleanedValue });
        handleChange(state.unit, cleanedValue, state.maxValue);
    };

    const handleMaxValueChange = (event) => {
        const cleanedValue = clearInput(event.target.value);
        setState({ ...state, maxValue: cleanedValue });
        handleChange(state.unit, state.minValue, cleanedValue);
    };

    const handleChange = (unit, minValue, maxValue) => {
        if (minValue || maxValue) {
            const minMaxDuration = {
                unit: unit?.value,
                minValue: parseInput(minValue),
                maxValue: parseInput(maxValue),
            };
            props.handleMinMaxChange(minMaxDuration);
        }
    };

    const onSubmit = () => {
        const isMinValueValid = validateSingleValue(state.minValue, state.unit);
        const isMaxValueValid = validateSingleValue(state.maxValue, state.unit);
        const isInvalidRange =
            isMinValueValid && isMaxValueValid && !props.isMinMaxValid;

        setState({
            ...state,
            isSubmitted: props.isSubmitted,
            isMinMaxValid: props.isMinMaxValid,
            isMinValueValid: isMinValueValid && !isInvalidRange,
            isMaxValueValid: isMaxValueValid && !isInvalidRange,
        });
    };

    const updateSelectedDuration = () => {
        setState({
            unit:
                units.find(
                    (unit) => unit.value === selectedMinMaxDuration?.unit
                ) ?? initialState.unit,
            minValue: selectedMinMaxDuration?.minValue ?? initialState.minValue,
            maxValue: selectedMinMaxDuration?.maxValue ?? initialState.maxValue,
            isEditMode: props.isEditMode,
            selectedIndex: props.selectedIndex,
            isSubmitted: props.isSubmitted,
            isMinMaxValid: props.isMinMaxValid,
            isMinValueValid: initialState.isMinValueValid,
            isMaxValueValid: initialState.isMaxValueValid,
        });
    };

    const parseInput = (inputString) => {
        if (!inputString || isNaN(inputString)) {
            return null;
        }

        return Number(inputString);
    };

    const getValidationMessage = () => {
        return !state.isMinValueValid && !state.isMaxValueValid
            ? 'Min Max Duration is invalid'
            : !state.isMinValueValid
            ? 'Min Duration is invalid'
            : 'Max Duration is invalid';
    };

    return (
        <div
            className="inline"
            style={{
                bottom: '5px',
                position: 'relative',
                height: '55px',
                width: '330px',
            }}
        >
            <div
                className="inline vertical-divider"
                style={{ bottom: '5px', marginRight: '5px', height: '45px' }}
            ></div>

            <div className="inline">
                <input
                    type="text"
                    className="duration-amount-input"
                    onChange={handleMinValueChange}
                    value={state.minValue}
                    placeholder="Minimum"
                    style={
                        state.isSubmitted &&
                        !state.isMinMaxValid &&
                        !state.isMinValueValid
                            ? {
                                  boxShadow: '0 0 0 1px red',
                                  color: 'red',
                              }
                            : {}
                    }
                />
            </div>
            <div className="inline">
                <input
                    type="text"
                    className="duration-amount-input"
                    onChange={handleMaxValueChange}
                    value={state.maxValue}
                    placeholder="Maximum"
                    style={
                        state.isSubmitted &&
                        !state.isMinMaxValid &&
                        !state.isMaxValueValid
                            ? {
                                  boxShadow: '0 0 0 1px red',
                                  color: 'red',
                              }
                            : {}
                    }
                />
            </div>
            <div className="inline" style={{ width: '120px' }}>
                <Select
                    className="basic-single"
                    classNamePrefix="list"
                    isSearchable={false}
                    options={getAvailableMinMaxUnits()}
                    onChange={handleUnitChange}
                    value={state.unit}
                    openMenuOnFocus={true}
                    menuPlacement="auto"
                />
            </div>
            <div
                style={{
                    marginLeft: '30px',
                    paddingTop: '0px',
                    marginTop: '0px',
                }}
            >
                {state.isSubmitted && !state.isMinMaxValid && (
                    <p style={{ color: 'red' }}>{getValidationMessage()}</p>
                )}
            </div>
        </div>
    );
};

export default MinMaxDuration;
