import React from 'react';

const Header = ({ name, icon, isOpen }) => {
    const arrowIcon = isOpen ? 'ag-icon-small-up' : 'ag-icon-small-down';

    return (
        <div className="ag-column-drop-title-bar ag-column-drop-vertical-title-bar">
            <span
                className={`ag-icon ${icon} ag-column-drop-icon ag-column-drop-vertical-icon`}
                unselectable="on"
                role="presentation"
            ></span>
            <span className="ag-column-drop-title ag-column-drop-vertical-title">
                {name}
            </span>
            <span
                className={`ag-icon ${arrowIcon} ag-column-drop-icon ag-column-drop-vertical-icon`}
                style={{ fontSize: '20px' }}
                unselectable="on"
                role="presentation"
            ></span>
        </div>
    );
};

export default Header;
