import React, { Component } from 'react';
import VesselSelect from '../inputs/VesselSelect';
import FortCellEditor from '../../../shared/columns/editors/FortCellEditor';
import { KEY_TAB, KEY_ENTER } from '../../../../constants/keyboardCodes';

class VesselEditor extends Component {
    constructor(props) {
        super(props);

        this.vessel = this.props.value;
        this.vesselInputRef = React.createRef();

        if (this.props.value) {
            this.state = {
                selectedVessel: { ...this.props.value },
            };
        } else {
            this.state = {};
        }
    }

    afterGuiAttached() {
        this.focus();
    }

    focus = () => {
        if (this.vesselInputRef.current) {
            this.vesselInputRef.current.focus();
        }
    };

    getValue = () => {
        if (!this.state.selectedVessel) {
            return null;
        }

        return this.state.selectedVessel;
    };

    handleOnTab = () => {
        this.props.api.tabToNextCell();
    };

    handleOnTabBack = () => {
        this.props.api.tabToPreviousCell();
    };

    handleOnEnter = () => {
        this.props.stopEditing();
    };

    isPopup() {
        return true;
    }

    onVesselSelected = (vessel, keyCode, shift) => {
        this.setState(
            {
                selectedVessel: vessel,
            },
            () => {
                switch (keyCode) {
                    case KEY_TAB:
                        if (!shift) {
                            this.handleOnTab();
                        } else {
                            this.handleOnTabBack();
                        }
                        break;

                    case KEY_ENTER:
                    default:
                        //assume treated as enter, such as mouse click selection
                        this.handleOnEnter();
                        break;
                }
            }
        );
    };

    handleInputCleared = () => {
        this.setState({ selectedVessel: null });
    };

    render() {
        return (
            <FortCellEditor blockNavigation={false} {...this.props}>
                <VesselSelect
                    data={this.props.data}
                    onVesselSelected={this.onVesselSelected}
                    context={this.props.context}
                    ref={this.vesselInputRef}
                    value={this.state.selectedVessel}
                    onTab={this.handleOnTab}
                    onTabBack={this.handleOnTabBack}
                    onEnter={this.handleOnEnter}
                    shouldSelectItemOnTab={true}
                    inputClass="ag-react-container"
                    initialChar={this.props.charPress}
                    onInputCleared={this.handleInputCleared}
                />
            </FortCellEditor>
        );
    }
}

export default VesselEditor;
