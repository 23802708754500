import { createAuditTrailMenuItem } from '../menuItems/AuditTrailMenuItem';
import { createCellPrivacyMenuItem } from '../menuItems/CellPrivacyMenuItem';
import { createCellRumouredMenuItem } from '../menuItems/CellRumouredMenuItem';
import { createCheckSelectedRowsMenuItem } from '../menuItems/CheckSelectedRowsMenuItem';
import { createCloneOrderMenuItem } from '../menuItems/CloneOrderMenuItem';
import { createConvertToFixtureMenuItem } from '../menuItems/ConvertToFixtureMenuItem';
import { createCopyEntityToClipboardMenuItem } from '../menuItems/CopyEntityToClipboardMenuItem';
import { createDeleteMenuItem } from '../menuItems/DeleteMenuItem';
import { createMarkActiveMenuItem } from '../menuItems/MarkActiveMenuItem';
import { createMarkNewMenuItem } from '../menuItems/MarkNewMenuItem';
import { createPrivacyMenuItem } from '../menuItems/PrivacyMenuItem';
import { createReinstateMenuItem } from '../menuItems/ReinstateMenuItem';
import { createRumouredMenuItem } from '../menuItems/RumouredMenuItem';
import { createShareMenuItem } from '../menuItems/ShareMenuItem';
import { createVoyageActivityMenuItem } from '../menuItems/VoyageActivityMenuItem';
import { createWithdrawnMenuItem } from '../menuItems/WithdrawnMenuItem';
import { createCopyFixtureMenuItem } from '../menuItems/CopyFixtureMenuItem';
import { createCopyFixtureWithCargoInfoMenuItem } from '../menuItems/CopyFixtureWithCargoInfoMenuItem';
import {
    ACTION_CONVERT_TO_FIXTURE,
    ACTION_CLONE_ORDER,
    ACTION_OPEN_AUDIT,
    ACTION_REINSTATE,
    ACTION_MAKE_NEW,
    ACTION_MAKE_ACTIVE,
    ACTION_WITHDRAWN,
    ACTION_COPY_FIXTURE_WITH_POS_INFO,
    ACTION_COPY_FIXTURE_WITH_CARGO_INFO,
    ACTION_VOYAGE_ACTIVITY,
} from '../../../components/grid/BasicGrid';

export const getContextMenuItems = (props, params) => {
    const gridApi = params.api;

    if (gridApi) {
        gridApi.deselectAll(); // Ensure we do not apply context menu to multiple selected rows
    }

    if (!params.node || params.node.group || !params.node.data) {
        return [];
    }

    const isGroupOnly = params.node.data['groupOnly'];
    const isIndividual = params.node.data['isIndividual'];
    const isShared = !isGroupOnly && !isIndividual;
    const gridId = params.context.gridId;
    const state = params.node.data['state'] || '';
    const isItemDeleted = state.toLowerCase() === 'deleted';
    const { canEdit } = props;

    if (
        canEdit &&
        !isItemDeleted &&
        params.column &&
        params.column.colId === 'privacy' &&
        (isGroupOnly || isIndividual)
    ) {
        return createShareMenuItem(props, params.node, gridId);
    }

    let { defaultItems: items } = params;

    if (!items) {
        items = [];
    }

    const isItemTracked = params.node.data['tracking'] === 'Y';
    const isItemConverted = state.toLowerCase() === 'converted';
    const isItemWithdrawn = state.toLowerCase() === 'withdrawn';
    const isItemActive = state.toLowerCase() === 'active';

    // Strip out the items we don't want
    const excludedItems = ['export', 'separator', 'paste'];

    items = items.filter((i) => excludedItems.indexOf(i) === -1);

    const checkSelectedRowsItem = createCheckSelectedRowsMenuItem(gridApi);
    items.unshift(checkSelectedRowsItem);

    const copyEntityToClipboardItem = createCopyEntityToClipboardMenuItem(
        params.node,
        params.context,
        gridId
    );

    items.push(copyEntityToClipboardItem);

    // If item is not deleted and not converted, show all allowed operations
    if (!isItemDeleted && !isItemConverted && !isItemWithdrawn) {
        if (canEdit) {
            let lastPrivacyItemIndex = 0;

            if (isShared) {
                const privacyMenuItem = createPrivacyMenuItem(
                    props,
                    params.node,
                    gridId
                );
                items.splice(0, 0, privacyMenuItem);
                lastPrivacyItemIndex = 1;

                const cellPrivacyMenuItem = createCellPrivacyMenuItem(
                    props,
                    params,
                    gridId
                );

                if (cellPrivacyMenuItem) {
                    items.splice(1, 0, cellPrivacyMenuItem);
                    lastPrivacyItemIndex++;
                }
            }

            const rumouredMenuItem = createRumouredMenuItem(
                props,
                params.node,
                gridId
            );
            items.splice(
                lastPrivacyItemIndex,
                0,
                rumouredMenuItem,
                'separator'
            );

            const cellRumouredMenuItem = createCellRumouredMenuItem(
                props,
                params,
                gridId
            );
            items.splice(lastPrivacyItemIndex + 1, 0, cellRumouredMenuItem);

            if (lastPrivacyItemIndex !== 0) {
                items.splice(lastPrivacyItemIndex, 0, 'separator');
            }

            const canMakeNew = props.canPerformAction(
                ACTION_MAKE_NEW,
                params.node.data
            );

            if (canMakeNew) {
                const makeNewMenuItem = createMarkNewMenuItem(
                    props,
                    params.node,
                    gridId
                );
                items.splice(
                    lastPrivacyItemIndex + 3,
                    0,
                    'separator',
                    makeNewMenuItem
                );
            }

            const canMakeActive = props.canPerformAction(
                ACTION_MAKE_ACTIVE,
                params.node.data
            );

            if (canMakeActive) {
                const makeActiveMenuItem = createMarkActiveMenuItem(
                    props,
                    params.node,
                    gridId,
                    isItemActive
                );
                items.splice(
                    lastPrivacyItemIndex + 3,
                    0,
                    'separator',
                    makeActiveMenuItem
                );
            }
        }

        const canCopyWithPositionInfo = props.canPerformAction(
            ACTION_COPY_FIXTURE_WITH_POS_INFO
        );
        if (canCopyWithPositionInfo) {
            items.push('separator');
            items.push(createCopyFixtureMenuItem(props, params.node, gridId));
        }

        const canCopyWithCargoInfo = props.canPerformAction(
            ACTION_COPY_FIXTURE_WITH_CARGO_INFO
        );
        if (canCopyWithCargoInfo) {
            items.push(
                createCopyFixtureWithCargoInfoMenuItem(
                    props,
                    params.node,
                    gridId
                )
            );
        }
    }

    if (canEdit) {
        if (!isItemDeleted && !isItemConverted && !isItemWithdrawn) {
            const canConvert = props.canPerformAction(
                ACTION_CONVERT_TO_FIXTURE
            );

            if (canConvert) {
                const convertMenuItem = createConvertToFixtureMenuItem(
                    props,
                    params.node,
                    gridId
                );

                items.push('separator');
                items.push(convertMenuItem);
            }
        }

        const canClone = props.canPerformAction(ACTION_CLONE_ORDER);

        if (canClone) {
            const cloneMenuItem = createCloneOrderMenuItem(
                props,
                params.node,
                gridId
            );

            items.push(cloneMenuItem);
        }
    }

    const canOpenAudit = props.canPerformAction(ACTION_OPEN_AUDIT);

    if (canOpenAudit) {
        const openAuditItem = createAuditTrailMenuItem(
            props,
            gridApi,
            params.node,
            gridId
        );

        items.push('separator');
        items.push(openAuditItem);
    }

    const canOpenVoyageActivity =
        isItemTracked && props.canPerformAction(ACTION_VOYAGE_ACTIVITY);

    if (canOpenVoyageActivity) {
        const openVoyageActivityItem = createVoyageActivityMenuItem(
            props,
            gridApi,
            params.node,
            gridId
        );

        items.push('separator');
        items.push(openVoyageActivityItem);
    }

    // Add delete option
    // If item is deleted and can be inreinstated, show it.
    if (canEdit) {
        if (isItemDeleted || isItemWithdrawn) {
            const canReinstate = props.canPerformAction(ACTION_REINSTATE);

            if (canReinstate) {
                const reinstateMenuItem = createReinstateMenuItem(
                    props,
                    params.node,
                    gridId
                );

                items.push('separator');
                items.push(reinstateMenuItem);
            }
        } else {
            // converted orders can't have Delete action
            if (!isItemConverted) {
                const deleteMenuItem = createDeleteMenuItem(
                    props,
                    params.node,
                    gridId
                );
                const canWithdrawn = props.canPerformAction(ACTION_WITHDRAWN);

                items.push('separator');

                if (canWithdrawn) {
                    const withdrawnMenuItem = createWithdrawnMenuItem(
                        props,
                        params.node,
                        gridId
                    );
                    items.push(withdrawnMenuItem);
                }
                items.push(deleteMenuItem);
            }
        }
    }

    return items;
};

export const getMainMenuItems = (params) => {
    const menuItems = [];
    const itemsToExclude = ['resetColumns'];

    params.defaultItems.forEach(function (item) {
        if (itemsToExclude.indexOf(item) < 0) {
            menuItems.push(item);
        }
    });

    return menuItems;
};
