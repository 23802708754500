export const allDirections = [
    'Atlantic - BH',
    'Atlantic - FH',
    'Atlantic - TA',
    'Brazil/W.Afr',
    'E.Aus',
    'India',
    'Nopac',
    'Pacific',
    'S.Afr/E.Afr',
    'W.Aus',
    'Wcsam',
];

export const indonesiaCountryId = '74a1cfc2-f962-483e-aabc-eb7f96c41eef';

export const indonesiaSpecificDirectionMapping = 'India';

export const indonesiaSpecificDischargeZones = ['BENGAL', 'WCIND'];

export const specificZoneCapeDirectionMapping = [
    {
        zone: 'WCNA',
        loadDirections: ['Nopac'],
        dischargeDirections: [],
    },
    {
        zone: 'WCSAM',
        loadDirections: ['Wcsam'],
        dischargeDirections: [],
    },
    {
        zone: 'SCHINA',
        loadDirections: [],
        dischargeDirections: ['Nopac', 'Wcsam'],
    },
    {
        zone: 'CCHINA',
        loadDirections: [],
        dischargeDirections: ['Nopac', 'Wcsam'],
    },
    {
        zone: 'NCHINA',
        loadDirections: [],
        dischargeDirections: ['Nopac', 'Wcsam'],
    },
    {
        zone: 'NASIA',
        loadDirections: [],
        dischargeDirections: ['Nopac', 'Wcsam'],
    },
];

export const zoneCapeDirectionMapping = [
    {
        zone: 'NCSA',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'CARIBS',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'USG',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'USEC',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'LAKES',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'UKCONT',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'BALT',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'CANARY',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'WMED',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'EMED',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'BSEA',
        loadDirections: ['Atlantic - TA', 'Atlantic - FH'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'NBRAZ',
        loadDirections: ['Atlantic - BH', 'Brazil/W.Afr'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'SBRAZ',
        loadDirections: ['Atlantic - BH', 'Brazil/W.Afr'],
        dischargeDirections: ['Atlantic - TA', 'Atlantic - BH'],
    },
    {
        zone: 'PLATE',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Atlantic - TA',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
            'Pacific',
        ],
    },
    {
        zone: 'WAFR',
        loadDirections: ['Atlantic - BH', 'Brazil/W.Afr'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - TA',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'WAUS',
        loadDirections: ['Atlantic - BH', 'W.Aus'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'SAFR',
        loadDirections: ['Atlantic - BH', 'S.Afr/E.Afr'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'EAFR',
        loadDirections: ['Atlantic - BH', 'S.Afr/E.Afr'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'EAUS',
        loadDirections: ['E.Aus', 'Atlantic - BH'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'RSEA',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'MEG',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'WCIND',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'BENGAL',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'SEASIA',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'SCHINA',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'CCHINA',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'NCHINA',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'NASIA',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'WCCA',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'WCNA',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
    {
        zone: 'WCSAM',
        loadDirections: ['Atlantic - BH', 'Pacific'],
        dischargeDirections: [
            'Pacific',
            'Atlantic - FH',
            'W.Aus',
            'E.Aus',
            'Brazil/W.Afr',
            'S.Afr/E.Afr',
        ],
    },
];
