import React, { Component } from 'react';
import moment from 'moment';

export class VoyageActivityHistory extends Component {
    render() {
        if (!this.props.items || this.props.items.length === 0) {
            return (
                <div className="ui info message">
                    <span>
                        There is no voyage history for this item in the last 24
                        hours
                    </span>
                </div>
            );
        }

        const tableRows = this.props.items.map((g) => {
            const firstSeen = moment(g.firstSeen).format('DD MMM YY HH:mm');
            const distance = parseFloat(g.distanceToPort).toFixed(2);
            const draft = g.draft === null ? 'N/A' : g.draft;

            return (
                <tr key={g.id}>
                    <td style={{ whiteSpace: 'nowrap' }}>{firstSeen}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>{draft}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>{g.speed}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>{distance}</td>
                    <td style={{ whiteSpace: 'nowrap' }}>{g.nearestPort}</td>
                </tr>
            );
        });

        return (
            <div>
                <table className="ui table" style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            <th>First Seen</th>
                            <th>Draft</th>
                            <th>Speed</th>
                            <th>Distance To Port (nm)</th>
                            <th>Nearest Port</th>
                        </tr>
                    </thead>
                    <tbody>{tableRows}</tbody>
                </table>
            </div>
        );
    }
}

export default VoyageActivityHistory;
