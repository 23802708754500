import * as keyboardCodes from '../../../constants/keyboardCodes';

export default function allowUpAndDownOnEdit() {
    return function (event) {
        if (event.editing) {
            return (
                event.event.keyCode === keyboardCodes.KEY_DOWN ||
                event.event.keyCode === keyboardCodes.KEY_UP
            );
        } else {
            return false;
        }
    };
}
