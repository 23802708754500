import TooltipRenderer from '_legacy/components/grid/renderers/TooltipRenderer';
import BlanksFilterRenderer from '_legacy/components/grid/renderers/BlanksFilterRenderer';
import suppressOnEdit from '_legacy/components/grid/columnDefaults/suppressOnEdit';
import defaultObjEquals from '_legacy/components/grid/columnDefaults/defaultObjEquals';
import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';

import RatePartFilterComparator from '../comparators/RatePartFilterComparator';
import RatesComparator from '../comparators/RatesComparator';
import RateEditor from '../editors/RateEditor';
import { MultipleRateFormatter } from '../formatters/MultipleRateFormatter';
import RatesKeyCreator from '../keyCreators/RatesKeyCreator';
import { isEditableRow } from '../../../shared/columns/tools/headingTools';

export const ratesHeading = {
    colId: 'rates',
    headerName: 'Rate',
    field: 'rates',
    editable: isEditableRow,
    valueFormatter: MultipleRateFormatter,
    cellEditorFramework: RateEditor,
    cellRenderer: (rate) => TooltipRenderer({ value: rate.valueFormatted }),
    suppressKeyboardEvent: suppressOnEdit(),
    equals: defaultObjEquals,
    comparator: RatesComparator,
    keyCreator: RatesKeyCreator,
    width: 120,
    type: ['privatable', 'rumoured'],
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        cellRenderer: BlanksFilterRenderer,
        comparator: RatePartFilterComparator,
    },
};
