import CompanyFormatter from '_legacy/modules/shared/columns/formatters/CompanyFormatter';
import isString from 'lodash/isString';

const MultipleCompanyFormatter = ({ value }) => {
    const text = [];

    if (!value) {
        return null;
    }

    if (isString(value)) {
        return value;
    }

    value.forEach((part, index) => {
        const formattedName = CompanyFormatter({ value: part });
        text.push(formattedName);
        text.push('+');
    });

    // Drop the last +
    text.pop();

    return text.join(' ');
};

export default MultipleCompanyFormatter;
