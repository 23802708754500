class HotkeyService {
    setup() {
        this.uid = 0;
        this.sequence = [];
        this.tree = { children: {} };

        document.addEventListener('keydown', this.onKeyEvent(this.onKeyDown));
        document.addEventListener('keyup', this.onKeyEvent(this.onKeyUp));
    }

    onKeyEvent = (next) => (e) => {
        const { keyCode, repeat } = e;

        if (repeat) return;

        next(keyCode, e);
    };

    onKeyDown = (keyCode, e) => {
        this.sequence.push({ keyCode, isExecuted: false });

        this.execute(e);
    };

    onKeyUp = (keyCode) => {
        this.sequence = this.sequence.slice(
            0,
            Math.max(
                0,
                this.sequence.findIndex((el) => el.keyCode === keyCode)
            )
        );
    };

    subscribe = (...shortcuts) => {
        shortcuts = [].concat(...shortcuts);

        const subscriberStorageRef = {};

        shortcuts.forEach((shortcut) => {
            const { keys, sub } = shortcut;
            let node = this.tree;

            keys.forEach((keyCode, i) => {
                const { children } = node;

                if (!children[keyCode]) {
                    children[keyCode] = {
                        children: {},
                        subs: {},
                    };
                }

                node = children[keyCode];

                if (i === keys.length - 1) {
                    const uid = this.uid++;
                    const subs = node.subs;

                    subscriberStorageRef[uid] = node;

                    subs[uid] = sub;
                }
            });
        });

        const unsubscribe = () => {
            Object.entries(subscriberStorageRef).forEach(([uid, node]) => {
                const { [uid]: removed, ...subs } = node.subs;

                node.subs = subs;
            });
        };

        return unsubscribe;
    };

    execute = (e) => {
        let node = this.tree;

        for (const el of this.sequence) {
            node = node.children[el.keyCode];

            if (!node) break;

            const { subs } = node;

            if (!el.isExecuted && subs) {
                el.isExecuted = true;

                Object.values(subs).forEach((sub) => sub(e));
            }
        }
    };
}

export const hotkeyService = new HotkeyService();

window.hotkeyService = hotkeyService;
