import client from './graphQlClient';
import { SEARCH_VESSELS } from './queries/VesselQueries';

export class VesselsApi {
    static searchVessel = (
        name,
        datasetId,
        minDwt,
        maxDwt,
        searchVesselsWithImoOnly,
        cancelToken
    ) => {
        return new Promise((resolve, reject) => {
            client()
                .query({
                    query: SEARCH_VESSELS,
                    fetchPolicy: 'no-cache',
                    variables: {
                        searchTerm: name,
                        datasetId,
                        minDwt,
                        maxDwt,
                        searchVesselsWithImoOnly,
                    },
                })
                .then((result) => {
                    resolve(result.data.vessels);
                });
            cancelToken.promise.then((reason) => {
                reject(reason);
            });
        });
    };
}
