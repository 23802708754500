import { gql } from '@apollo/client';

export const AUDIT_HISTORY_QUERY = gql`
    query AuditHistory($id: ID!, $entityType: String!) {
        auditHistory(id: $id, entityType: $entityType) {
            id
            action
            field
            before
            after
            actionDateTime
            actionBy {
                username
                fullName
            }
        }
    }
`;

export const LOG_EXPORT_HISTORY_MUTATION = gql`
    mutation LogExportHistory(
        $groupId: ID!
        $actionType: String!
        $actionData: String!
    ) {
        logExportHistory(
            groupId: $groupId
            actionType: $actionType
            actionData: $actionData
        )
    }
`;
