import { blankKeyText } from '../../../constants/gridText';
import LocationsFormatter from '../formatters/LocationsFormatter';

const LocationPartsKeyCreator = ({ value }) => {
    if (!value || value.length === 0) {
        return blankKeyText;
    }
    return LocationsFormatter({ value });
};

export default LocationPartsKeyCreator;
