import React from 'react';
import { Radio } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as layoutActions from '../../../actions/layoutActions';
import {
    LONG_HAND_QUANTITY_FORMAT,
    SHORT_HAND_QUANTITY_FORMAT,
} from '_legacy/modules/columns/quantity';

const panelContainerStyle = () => {
    return {
        textAlign: 'left',
        display: 'inline-grid',
        lineHeight: '1.5',
        marginTop: '5px',
        marginLeft: '50px',
    };
};

const labels = {
    LONG_HAND_QUANTITY_FORMAT: 'Long Hand',
    SHORT_HAND_QUANTITY_FORMAT: 'Short Hand',
};

const QuantityFormatItems = (props) => {
    const handleOnChangeQuantityFormat = (quantityFormat) => {
        updateLayout(quantityFormat);
        props.api.refreshClientSideRowModel();
    };

    const isChecked = (value) => {
        return value === props.currentQuantityFormat;
    };

    const updateLayout = (quantityFormat) => {
        props.actions.onQuantityFormatChanged(quantityFormat);
    };

    return (
        <div style={panelContainerStyle()}>
            <Radio
                checked={isChecked(LONG_HAND_QUANTITY_FORMAT)}
                onChange={() =>
                    handleOnChangeQuantityFormat(LONG_HAND_QUANTITY_FORMAT)
                }
                label={labels.LONG_HAND_QUANTITY_FORMAT}
            />
            <Radio
                checked={isChecked(SHORT_HAND_QUANTITY_FORMAT)}
                onChange={() =>
                    handleOnChangeQuantityFormat(SHORT_HAND_QUANTITY_FORMAT)
                }
                label={labels.SHORT_HAND_QUANTITY_FORMAT}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    const props = {
        currentQuantityFormat:
            state.layouts.selectedLayout.commonSettings.quantityFormat
                .currentState,
    };

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators({ ...layoutActions }, dispatch),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuantityFormatItems);
