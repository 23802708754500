import isString from 'lodash/isString';
import LocationFormatter from './LocationFormatter';

const LocationsFormatter = ({ node, value }) => {
    if (node && node.group) {
        return '';
    }

    if (!value || value.length === 0) {
        return '';
    }

    const text = [];

    if (isString(value)) {
        //formatted already
        return value;
    }

    if (!Array.isArray(value)) {
        text.push(LocationFormatter({ value: value }));
    } else {
        value.forEach((locationPart) => {
            text.push(LocationFormatter({ value: locationPart }));
        });
    }

    return text.join(' ');
};

export default LocationsFormatter;
