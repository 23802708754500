import Logger from '../../../diagnostics/logging/logger';
import { cloneDeep } from 'lodash';

export const createCloneOrderMenuItem = (props, node, gridId) => {
    const { group, data } = node;
    const title = 'Clone Order';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.cloneOrder', gridId);

                const clonedData = cloneDeep(data);
                props.onCloneOrder(clonedData);
            }
        },
        tooltip: title,
    };
};
