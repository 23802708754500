import React, { Component } from 'react';
import Downshift from 'downshift';
import debounce from 'lodash/debounce';
import { graphql } from '@apollo/client/react/hoc';
import './UserSelect.scss';
import PropTypes from 'prop-types';
import { SEARCH_USERS } from '../../../api/queries/UserQueries';
import StripedLoader from '../../common/StripedLoader';

class UserSelect extends Component {
    constructor(props) {
        super(props);
        this.state = { currentValue: '', isSelectOpen: false };

        this.onStateChange = this.onStateChange.bind(this);
        this.onStateChange = debounce(this.onStateChange, 200);
    }

    componentWillUnmount() {
        //cancel any debounce action that might try to execute after the component has unmounted
        if (this.onStateChange.cancel) {
            this.onStateChange.cancel();
        }
    }

    onStateChange = (changes) => {
        if (changes.hasOwnProperty('inputValue')) {
            const { inputValue, isOpen } = changes;
            if (inputValue.length > 2) {
                this.setState({
                    currentValue: inputValue,
                    isSelectOpen: isOpen ?? true,
                });
            } else {
                this.setState({
                    currentValue: '',
                    isSelectOpen: false,
                });
            }
        }
    };

    itemToString = (user) => (user ? `${user.firstName} ${user.lastName}` : '');

    render() {
        const { currentValue, isSelectOpen } = this.state;

        return (
            <Downshift
                onStateChange={this.onStateChange}
                onChange={(selection) => {
                    this.props.onUserSelected(
                        selection?.userId,
                        selection?.username
                    );
                    this.setState({ isSelectOpen: false });
                }}
                itemToString={this.itemToString}
                initialIsOpen={false}
                isOpen={isSelectOpen}
            >
                {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    selectedItem,
                    highlightedIndex,
                    clearSelection,
                }) => {
                    return (
                        <div>
                            <input
                                className="ui input"
                                {...getInputProps({
                                    onChange: (e) => {
                                        if (e.target.value === '') {
                                            clearSelection();
                                        }
                                    },
                                    'data-test': 'user-select-input',
                                })}
                            />
                            {isOpen ? (
                                <div>
                                    <ApolloAutocompleteMenuWithData
                                        {...{
                                            searchTerm: currentValue,
                                            selectedItem,
                                            highlightedIndex,
                                            getItemProps,
                                            currentUserIds:
                                                this.props.currentUserIds,
                                            currentDatasetUserIds:
                                                this.props
                                                    .currentDatasetUserIds,
                                        }}
                                    />
                                </div>
                            ) : null}
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}

function ApolloAutocompleteMenu({
    data: { allUsers, loading, error },
    selectedItem,
    highlightedIndex,
    getItemProps,
    currentUserIds,
    currentDatasetUserIds,
}) {
    if (loading) {
        return <StripedLoader size={'small'} />;
    }
    if (error) {
        return <div>Error!</div>;
    }
    return (
        <div className="user-menu" data-test="user-menu">
            {(allUsers || []).map((item, index) => {
                const disabled =
                    currentUserIds &&
                    currentUserIds.filter((id) => id === item.userId).length >
                        0;

                return (
                    <div
                        className="user-menu-item"
                        {...getItemProps({
                            item,
                            index,
                            key: item.id,
                            style: {
                                backgroundColor:
                                    highlightedIndex === index
                                        ? 'gray'
                                        : 'white',
                                fontWeight:
                                    selectedItem === item ? 'bold' : 'normal',
                            },
                            disabled: disabled,
                            'data-test': `user-item-${item.id}`,
                        })}
                    >
                        {item.firstName} {item.lastName} ({item.username})
                        {disabled && '- already added to group'}
                    </div>
                );
            })}
        </div>
    );
}

const ApolloAutocompleteMenuWithData = graphql(SEARCH_USERS, {
    options: (props) => ({
        fetchPolicy: 'network-only',
        variables: {
            searchTerm: props.searchTerm,
        },
    }),
})(ApolloAutocompleteMenu);

UserSelect.propTypes = {
    onUserSelected: PropTypes.func.isRequired,
};

export default UserSelect;
