import {
    blankKeyText,
    isDefaultOrBlankValue,
} from '../../../constants/gridText';

const BlanksFilterRenderer = function (params) {
    return isDefaultOrBlankValue(params.value) ? blankKeyText : params.value;
};

export default BlanksFilterRenderer;
