import {
    ENTITY_PART_TYPE_QUANTITY,
    ENTIYT_PART_TYPE_STEMSIZE,
    ENTITY_PART_TYPE_SEPARATOR,
} from '../../../../models/common/EntityPart';

export const createQuantityPart = (unit, amountFrom, amountTo, tolerance) => {
    const quantityValue = createQuantityValue(
        unit,
        amountFrom,
        amountTo,
        tolerance
    );
    return createPart(quantityValue, null, null, ENTITY_PART_TYPE_QUANTITY);
};

export const createStemSizePart = (stemSize) => {
    return createPart(null, stemSize.value, null, ENTIYT_PART_TYPE_STEMSIZE);
};

export const createSeparatorQuantityPart = (character) => {
    return createPart(null, null, character, ENTITY_PART_TYPE_SEPARATOR);
};

const createQuantityValue = (unit, amountFrom, amountTo, tolerance) => {
    return {
        unit,
        amount: {
            from: amountFrom,
            to: amountTo,
        },
        tolerance,
    };
};

const createPart = (quantityValue, stemSizeValue, separatorValue, partType) => {
    return {
        quantityValue,
        stemSizeValue,
        separatorValue,
        partType,
    };
};
