import { KEY_LEFT, KEY_RIGHT } from '../constants/keyboardCodes';

export const changeFocusOnKeyDown = (containerId, event) => {
    if (event.shiftKey || event.ctrlKey) {
        return;
    }

    const focusableElements = getFocusableElements(containerId);
    const nextElementIndex = getNextFocusableElementIndex(
        focusableElements,
        event
    );
    const nextFocusableElement = focusableElements[nextElementIndex];

    nextFocusableElement.focus();
    event.preventDefault();
};

const getFocusableElements = (containerId) => {
    const elements = document.querySelector(`#${containerId}`);

    return Array.from(
        elements.querySelectorAll(
            'button:not([disabled]), input:not([disabled])'
        )
    );
};

const getNextFocusableElementIndex = (focusableElements, event) => {
    const currentElementIndex = focusableElements.findIndex(
        (c) => c === document.activeElement
    );

    switch (event.keyCode) {
        case KEY_LEFT:
            return currentElementIndex === 0
                ? focusableElements.length - 1
                : currentElementIndex - 1;
        case KEY_RIGHT:
            return currentElementIndex === focusableElements.length - 1
                ? 0
                : currentElementIndex + 1;
        default:
            return currentElementIndex;
    }
};
