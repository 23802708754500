import LocationsFormatter from '../formatters/LocationsFormatter';

const LocationPartsComparator = (valueA, valueB, nodeA, nodeB) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }

    const fValueA = LocationsFormatter({ value: valueA });
    const fValueB = LocationsFormatter({ value: valueB });

    if (fValueA < fValueB) {
        return -1;
    }
    if (fValueA > fValueB) {
        return 1;
    }

    return 0;
};

export default LocationPartsComparator;
