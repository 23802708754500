import React from 'react';
import DroppableMultiSelectSpace from './DroppableMultiSelectSpace';

const MultiSelectSeparator = ({
    value,
    index,
    onClick,
    onSetCursorPosition,
    onDraggedItem,
    onRemovedItem,
    pill,
    checkIsSeparatorException,
}) => {
    function onClosing(e) {
        e.stopPropagation();
        if (typeof onRemovedItem === 'function') {
            onRemovedItem(index);
        }
    }
    return pill ? (
        <div className="multi-select-item-pill">
            <DroppableMultiSelectSpace
                index={index}
                onSetCursorPosition={onSetCursorPosition}
                onDraggedItem={onDraggedItem}
            />
            <div
                className="multi-select-item-pill-separator"
                style={
                    checkIsSeparatorException &&
                    checkIsSeparatorException(index)
                        ? {
                              boxShadow: '0 0 0 1px red',
                              color: 'red',
                          }
                        : {}
                }
                onClick={onClick}
            >
                <span className="ui image label">
                    {value}
                    <i className="delete icon" onClick={onClosing} />
                </span>
            </div>
        </div>
    ) : (
        <span className="multi-select-item-separator">{value}</span>
    );
};

export default MultiSelectSeparator;
