import Logger from '../../../diagnostics/logging/logger';
import { cloneDeep } from 'lodash';

export const createMarkNewMenuItem = (props, node, gridId) => {
    const { group, data } = node;
    const title = 'Mark New';
    const tooltip = 'Change Order State back to New';

    return {
        name: title,
        action: () => {
            if (!group && data) {
                Logger.trackEvent('grid.context.makeNew', gridId);

                const clonedData = cloneDeep(data);
                props.onMakeNew(clonedData);
            }
        },
        tooltip: tooltip,
    };
};
