import 'scripts';
import ReactDOM from 'react-dom';
import '_legacy/misc.scss';
import 'modules/themes/default.scss';
import './index.scss';

import { setup } from 'setup';
import App from 'App';

setup();

ReactDOM.render(<App />, document.getElementById('root'));
