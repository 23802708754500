import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import {
    parseSalePrice,
    formatSalePrice,
} from '_legacy/services/SalePriceFormatterService';

class SalePriceFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            from: '',
            to: '',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.updateGrid = debounce(this.updateGrid, 1000);
        this.clearFilter = this.clearFilter.bind(this);
    }

    doesFilterPass(params) {
        const value = this.valueGetter(params.node);
        const from = parseSalePrice(this.state.from);
        const to = parseSalePrice(this.state.to);
        return (!from || value >= from) && (!to || value <= to);
    }

    isFilterActive() {
        return this.state.from !== '' || this.state.to !== '';
    }

    getModel() {
        return this.isFilterActive()
            ? { to: this.state.to, from: this.state.from }
            : null;
    }

    setModel(model) {
        if (model) {
            this.setState(
                {
                    to: model.to,
                    from: model.from,
                },
                () => this.props.filterChangedCallback()
            );
        } else {
            this.clearFilter();
        }
    }

    valueGetter(node) {
        const rowValue = this.props.valueGetter(node);
        return this.props.fieldName && rowValue
            ? parseSalePrice(rowValue[this.props.fieldName])
            : parseSalePrice(rowValue);
    }

    afterGuiAttached() {
        const multiFilter = this.props.api.getFilterInstance('salePrice');
        const setFilter = multiFilter.getChildFilterInstance(1);

        const clearButton = setFilter.eGui.querySelector(
            '.ag-filter-apply-panel-button'
        );

        clearButton.addEventListener('click', () => {
            this.clearFilter();
            setTimeout(() => {
                this.refreshValues();
            }, 2000);
        });
    }

    refreshValues() {
        const multiFilter = this.props.api.getFilterInstance('salePrice');
        const setFilter = multiFilter.getChildFilterInstance(1);
        setFilter.resetFilterValues();
    }

    handleInputChange(event, fieldName) {
        const value = event.target.value;
        if (value.match(/^\d*\.?\d{0,2}[kKmM]?$/)) {
            this.setState({ [fieldName]: value }, () => {
                this.updateGrid();
            });
        }
    }

    updateGrid() {
        this.props.filterChangedCallback();
    }

    clearFilter() {
        this.setState({ from: '', to: '' }, () => {
            this.props.filterChangedCallback();
        });
    }

    render() {
        return (
            <div style={{ width: '100%' }}>
                <input
                    type="text"
                    className="dwt-filter-input"
                    value={this.state.from}
                    onChange={(e) => this.handleInputChange(e, 'from')}
                    placeholder="From"
                />
                <input
                    type="text"
                    className="dwt-filter-input"
                    value={this.state.to}
                    onChange={(e) => this.handleInputChange(e, 'to')}
                    placeholder="To"
                />
            </div>
        );
    }
}

export default SalePriceFilter;
