import { gql } from '@apollo/client';

export const SEARCH_CHARTERERS = gql`
    query Charterers($search: String!) {
        charterers(searchTerm: $search) {
            id
            name
        }
    }
`;

export const SEARCH_OWNER = gql`
    query Owners($search: String!) {
        owners(searchTerm: $search) {
            id
            name
        }
    }
`;

export const SEARCH_BROKER = gql`
    query Brokers($search: String!) {
        brokers(searchTerm: $search) {
            id
            name
        }
    }
`;
