import React from 'react';
import ReactTooltip from 'react-tooltip';

export class GroupNameRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.elRef = React.createRef();
    }

    tooltipDelay = 400;

    clearTooltipTimer = () => {
        if (!this.tooltipTimer) {
            return;
        }
        window.clearTimeout(this.tooltipTimer);
        this.tooltipTimer = undefined;
    };

    render() {
        let displayValue = this.props.data.groupName;

        if (displayValue) {
            displayValue = displayValue.toUpperCase();
        }

        const rowIndex = `${this.props.rowIndex}`;

        return (
            <div
                ref={this.elRef}
                style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
                data-tip={rowIndex}
                data-for={this.props.context.gridId}
                onMouseEnter={() => {
                    if (this.tooltipTimer) {
                        return; // prevent double triggering problem on some browsers
                    }

                    this.tooltipTimer = setTimeout(() => {
                        ReactTooltip.show(this.elRef.current);
                        this.clearTooltipTimer();
                    }, this.tooltipDelay);
                }}
                onMouseLeave={() => {
                    this.clearTooltipTimer();
                    ReactTooltip.hide(this.elRef.current);
                }}
            >
                {displayValue}
            </div>
        );
    }
}

export default GroupNameRenderer;
