import defaultSetFilterParams from '_legacy/components/grid/columnDefaults/defaultSetFilterParams';
import { commonRowAndCellClassRules } from '_legacy/components/grid/classRules/classRules';
import naturalSortComparator from '_legacy/components/grid/comparators/NaturalSortComparator';
import { isEditableRow } from '../../../shared/columns/tools/headingTools';
import SelectEditor from '_legacy/modules/shared/columns/editors/SelectEditor';
import allowUpAndDownOnEdit from '_legacy/components/grid/columnDefaults/allowUpAndDownOnEdit';

const allowedTypes = ['TCT', 'PERIOD', 'UNKNOWN'];

export const sharedDeliveryTermHeading = {
    colId: 'deliveryTerm',
    headerName: 'Delivery Term',
    field: 'deliveryTerm',
    editable: (params) => {
        return isEditableRow(params) && allowedTypes.includes(params.data.type);
    },
    cellEditorFramework: SelectEditor,
    cellEditorParams: {
        values: [
            '',
            'APS',
            'AFSPS',
            'DOP',
            'OPL',
            'PASSING',
            'RETRO',
            'WWR',
            'In DC',
        ],
    },
    suppressKeyboardEvent: allowUpAndDownOnEdit(),
    width: 120,
    cellClassRules: {
        ...commonRowAndCellClassRules,
    },
    filterParams: {
        ...defaultSetFilterParams,
        comparator: naturalSortComparator,
    },
    tooltipValueGetter: (params) => {
        if (params.colDef.editable(params)) {
            return '';
        }

        return 'Delivery terms are applicable to Period or TCT only.';
    },
};
