import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./rateValueFragment.gql');

export const RATE_VALUE_TYPE_NAME = 'RateValue';
export const RATE_VALUE_FRAGMENT_NAME = 'RateValueFragment';
export const RATE_VALUE_FRAGMENT = gql`
    ${FRAGMENT}
`;
