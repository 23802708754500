import React from 'react';
import Select from 'react-select';
import { useRateStore } from '../store/RateStore';
import currencies from '../models/Currencies';

const Currency = () => {
    const store = useRateStore();

    const handleCurrencyChange = (event) => {
        store.setCurrency(event);
    };

    return (
        <div
            className="inline"
            style={{
                width: '100px',
            }}
        >
            <Select
                className="basic-single"
                classNamePrefix="list"
                isSearchable={false}
                options={currencies}
                onChange={handleCurrencyChange}
                isDisabled={store.isCurrencyDisabled}
                menuPlacement="auto"
                value={store.currency}
                openMenuOnFocus={true}
            />
        </div>
    );
};

export default Currency;
