import { gql } from '@apollo/client';
import { loader } from 'graphql.macro';

const FRAGMENT = loader('./laycanFragment.gql');

export const LAYCAN_TYPE_NAME = 'Laycan';
export const LAYCAN_FRAGMENT_NAME = 'LaycanFragment';
export const LAYCAN_FRAGMENT = gql`
    ${FRAGMENT}
`;
