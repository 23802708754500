import isString from 'lodash/isString';

const MultipleFreightPortBasisFormatter = ({ node, value }) => {
    if ((node && node.group) || !value || !value.parts) {
        return '';
    }

    if (isString(value)) {
        return value;
    }

    const text = [];

    value.parts.forEach((part) => {
        text.push(FormatPart(part));
        text.push('/');
    });

    text.pop();

    return text.join(' ');
};

const FormatPart = (part) => {
    return `${part.loadCount}:${part.dischargeCount}`;
};

export default MultipleFreightPortBasisFormatter;
