import React from 'react';
import Lightbulb from '../../icons/Lightbulb';

function reloadPage() {
    window.location.reload(true);
}

const VersionUpdatedMessage = (props) => (
    <div id="version-updated-message">
        <div>
            <div className="message-header" />
            <div className="info-message" {...props}>
                <Lightbulb />
                <span>
                    There has been a new update. Please refresh your browser.
                </span>
            </div>
            <div className="message-content">Or 'Click' below:</div>
            <button onClick={reloadPage}>REFRESH BROWSER</button>
            <div className="message-footer">FORT Version {props.version}</div>
        </div>
    </div>
);

export default VersionUpdatedMessage;
