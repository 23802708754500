import React from 'react';
import DroppableMultiSelectSpace from './DroppableMultiSelectSpace';

const MultiSelectItem = React.forwardRef(
    (
        {
            index,
            value,
            onRemovedItem,
            onSetCursorPosition,
            onDraggedItem,
            onClickedItem,
            pill,
            isSelected,
        },
        ref
    ) => {
        function onClosing(e) {
            e.stopPropagation();
            if (typeof onRemovedItem === 'function') {
                onRemovedItem(index);
            }
        }

        return pill ? (
            <div
                ref={ref}
                className="multi-select-item-pill"
                onClick={onClickedItem}
            >
                <DroppableMultiSelectSpace
                    index={index}
                    on
                    onSetCursorPosition={onSetCursorPosition}
                    onDraggedItem={onDraggedItem}
                />
                <span
                    className={
                        isSelected
                            ? 'ui image label multi-select-item-selected-pill'
                            : 'ui image label'
                    }
                >
                    <img src="/gripDots.png" alt="" />
                    {value}
                    <i className="delete icon" onClick={onClosing} />
                </span>
            </div>
        ) : (
            <div className="multi-select-item">{value}</div>
        );
    }
);

export default MultiSelectItem;
