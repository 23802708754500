import moment from 'moment';
import LaycanValueGetter from './LaycanValueGetter';

const LaycanYearValueGetter = (params) => {
    var laycan = LaycanValueGetter(params);
    var result =
        laycan && moment(laycan.startDate).isValid()
            ? moment(laycan.startDate).format('YYYY')
            : null;
    return result;
};

export default LaycanYearValueGetter;
