import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Query, Mutation } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import UserSelect from './UserSelect';
import { Checkbox, Loader } from 'semantic-ui-react';
import './EditGroupPage.scss';

export class EditGroupPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userIdToAdd: '',
            usernameToAdd: '',
            userIdError: undefined,
            usernameError: undefined,
            groupOnlyExpiryPeriod: 0,
        };
    }

    backToGroupsLink = () => {
        return (
            <Link id="admin-back-to-groups" to="/admin/groups">
                back to groups
            </Link>
        );
    };

    handleGroupOnlyExpiryPeriodChange = (e) => {
        this.setState({ groupOnlyExpiryPeriod: e.target.value });
    };

    handleGroupOnlyExpiryPeriodKeyDown = (e) => {
        const ignoredKeys = ['.', 'e', 'E', '-', '+'];

        if (ignoredKeys.find((key) => key === e.key)) {
            e.preventDefault();
        }
    };

    handleAddUserInputChange = (e) => {
        this.setState({ userIdToAdd: e.target.value });
    };

    handleAddUsernameInputChange = (e) => {
        this.setState({ usernameToAdd: e.target.value });
    };

    validateUserId = () => {
        if (this.state.userIdToAdd === '') {
            this.setState({
                userIdError: 'User Id is required',
            });
            return false;
        } else if (!Number.isInteger(parseFloat(this.state.userIdToAdd, 10))) {
            this.setState({
                userIdError: 'User Id must be a integer',
            });
            return false;
        } else {
            this.setState({
                userIdError: undefined,
            });
            return true;
        }
    };

    validateUsername = () => {
        if (this.state.usernameToAdd === '') {
            this.setState({
                usernameError: 'Username is required',
            });
            return false;
        } else {
            this.setState({
                usernameError: undefined,
            });
            return true;
        }
    };

    handleGroupOnlyExpiryPeriodUpdated = () => {
        this.props.onGroupsChanged && this.props.onGroupsChanged();
        this.props.history.push(`/admin/groups`);
    };

    render() {
        const { groupId } = this.props;
        const variables = { id: groupId };

        return (
            <Query
                fetchPolicy="no-cache"
                query={gql`
                    query Group($id: ID!) {
                        group(id: $id) {
                            id
                            name
                            dataset {
                                id
                                users
                            }
                            createdBy {
                                username
                            }
                            createdDate
                            users {
                                id
                                userId
                                username
                                fullName
                                canWrite
                            }
                            groupOnlyExpiryPeriod
                        }
                    }
                `}
                variables={variables}
            >
                {({ loading, error, data }) => {
                    if (loading) {
                        return (
                            <div>
                                {this.backToGroupsLink()}
                                <div className="ui info message">
                                    Loading group...
                                </div>
                            </div>
                        );
                    }

                    if (error) {
                        return (
                            <div>
                                {this.backToGroupsLink()}
                                <div className="ui negative message">
                                    {error.message}
                                </div>
                            </div>
                        );
                    }

                    const { group } = data;

                    var groupOnlyExpiryPeriodDiv = (
                        <div className="field">
                            <label htmlFor="groupOnlyExpiryPeriodInput">
                                Group Only Expiry Period
                                <input
                                    id="groupOnlyExpiryPeriodInput"
                                    className="group-only-expiry-period-input"
                                    type="number"
                                    min="0"
                                    onKeyDown={
                                        this.handleGroupOnlyExpiryPeriodKeyDown
                                    }
                                    onChange={
                                        this.handleGroupOnlyExpiryPeriodChange
                                    }
                                    defaultValue={group.groupOnlyExpiryPeriod}
                                />
                                hours
                            </label>
                            <Mutation
                                mutation={gql`
                                    mutation UpdateGroupOnlyExpiryPeriod(
                                        $id: ID!
                                        $groupOnlyExpiryPeriod: Int!
                                    ) {
                                        updateGroupOnlyExpiryPeriod(
                                            id: $id
                                            groupOnlyExpiryPeriod: $groupOnlyExpiryPeriod
                                        ) {
                                            id
                                            groupOnlyExpiryPeriod
                                        }
                                    }
                                `}
                                context={{
                                    errorHandling: {
                                        unknownResult: {
                                            resolution: { isSafeToRetry: true },
                                        },
                                    },
                                }}
                                onCompleted={(data) => {
                                    if (data.updateGroupOnlyExpiryPeriod) {
                                        this.handleGroupOnlyExpiryPeriodUpdated();
                                    }
                                }}
                            >
                                {(mutate, { loading }) => {
                                    return (
                                        <button
                                            className={classNames(
                                                'ui primary button save',
                                                {
                                                    loading: loading,
                                                }
                                            )}
                                            disabled={
                                                !this.state
                                                    .groupOnlyExpiryPeriod
                                            }
                                            onClick={() => {
                                                mutate({
                                                    variables: {
                                                        id: group.id,
                                                        groupOnlyExpiryPeriod:
                                                            this.state
                                                                .groupOnlyExpiryPeriod,
                                                    },
                                                });
                                            }}
                                        >
                                            Save
                                        </button>
                                    );
                                }}
                            </Mutation>
                        </div>
                    );

                    let tableRows;

                    if (group.users.length === 0) {
                        tableRows = (
                            <tr>
                                <td colSpan="3">
                                    There are no users assigned to this group
                                </td>
                            </tr>
                        );
                    } else {
                        tableRows = group.users.map((u) => (
                            <tr key={u.id}>
                                <td>{u.fullName}</td>
                                <td>{u.username}</td>
                                <td style={{ textAlign: 'center' }}>
                                    <Mutation
                                        mutation={gql`
                                            mutation UpdateUserGroupPermission(
                                                $id: ID!
                                                $userId: Int!
                                                $canWrite: Boolean!
                                            ) {
                                                updateUserGroupPermission(
                                                    id: $id
                                                    userId: $userId
                                                    canWrite: $canWrite
                                                ) {
                                                    groupId
                                                    userId
                                                    canWrite
                                                }
                                            }
                                        `}
                                        context={{
                                            errorHandling: {
                                                unknownResult: {
                                                    resolution: {
                                                        isSafeToRetry: true,
                                                    },
                                                },
                                            },
                                        }}
                                        onCompleted={() =>
                                            this.props.onGroupsChanged &&
                                            this.props.onGroupsChanged()
                                        }
                                    >
                                        {(mutate, { loading, error, data }) => {
                                            if (loading) {
                                                return (
                                                    <Loader
                                                        size="tiny"
                                                        active
                                                        inline
                                                    />
                                                );
                                            }

                                            let isChecked = data
                                                ? data.updateUserGroupPermission
                                                      .canWrite
                                                : u.canWrite;

                                            return (
                                                <Checkbox
                                                    checked={isChecked}
                                                    onChange={(e, args) => {
                                                        mutate({
                                                            variables: {
                                                                id: group.id,
                                                                userId: u.userId,
                                                                canWrite:
                                                                    !isChecked,
                                                            },
                                                        });
                                                    }}
                                                />
                                            );
                                        }}
                                    </Mutation>
                                </td>
                                <td>
                                    <Mutation
                                        mutation={gql`
                                            mutation RemoveUserFromGroup(
                                                $id: ID!
                                                $userId: ID!
                                            ) {
                                                removeUserFromGroup(
                                                    id: $id
                                                    userId: $userId
                                                ) {
                                                    id
                                                    name
                                                    createdBy {
                                                        username
                                                    }
                                                    createdDate
                                                    users {
                                                        id
                                                        userId
                                                        username
                                                        canWrite
                                                    }
                                                }
                                            }
                                        `}
                                        context={{
                                            errorHandling: {
                                                unknownResult: {
                                                    resolution: {
                                                        isSafeToRetry: true,
                                                    },
                                                },
                                            },
                                        }}
                                        onCompleted={() =>
                                            this.props.onGroupsChanged &&
                                            this.props.onGroupsChanged()
                                        }
                                    >
                                        {(mutate, { loading, error, data }) => {
                                            return (
                                                <button
                                                    className={classNames(
                                                        'ui negative button',
                                                        {
                                                            loading: loading,
                                                        }
                                                    )}
                                                    onClick={() => {
                                                        mutate({
                                                            variables: {
                                                                id: group.id,
                                                                userId: u.userId,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    Delete
                                                </button>
                                            );
                                        }}
                                    </Mutation>
                                </td>
                            </tr>
                        ));
                    }

                    return (
                        <div>
                            <h2>{group.name}</h2>
                            {this.backToGroupsLink()}
                            {groupOnlyExpiryPeriodDiv}
                            <table
                                data-cy="group-user-list"
                                className="ui celled table"
                            >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Username</th>
                                        <th style={{ width: '10px' }}>Write</th>
                                        <th style={{ width: '10px' }} />
                                    </tr>
                                </thead>
                                <tbody>{tableRows}</tbody>
                            </table>

                            <Mutation
                                mutation={gql`
                                    mutation AddUserToGroup(
                                        $id: ID!
                                        $userId: ID!
                                        $username: String!
                                    ) {
                                        addUserToGroup(
                                            id: $id
                                            userId: $userId
                                            username: $username
                                        ) {
                                            id
                                            name
                                            createdBy {
                                                username
                                            }
                                            createdDate
                                            users {
                                                id
                                                userId
                                                username
                                                canWrite
                                            }
                                        }
                                    }
                                `}
                                context={{
                                    errorHandling: {
                                        unknownResult: {
                                            resolution: { isSafeToRetry: true },
                                        },
                                    },
                                }}
                                onCompleted={() =>
                                    this.props.onGroupsChanged &&
                                    this.props.onGroupsChanged()
                                }
                            >
                                {(addUserToGroup, { loading, error }) => {
                                    if (loading) {
                                        return (
                                            <div className="ui info message">
                                                Adding user to group...
                                            </div>
                                        );
                                    }

                                    return (
                                        <form
                                            id="addUserToGroupForm"
                                            className="ui form"
                                        >
                                            <div className="field">
                                                <label htmlFor="userIdInput">
                                                    Add User
                                                </label>
                                            </div>
                                            <div className="one fields">
                                                <div className="sixteen wide field">
                                                    <UserSelect
                                                        currentUserIds={group.users.map(
                                                            (u) => u.userId
                                                        )}
                                                        currentDatasetUserIds={
                                                            group.dataset.users
                                                        }
                                                        onUserSelected={(
                                                            userId,
                                                            username
                                                        ) => {
                                                            addUserToGroup({
                                                                variables: {
                                                                    id: group.id,
                                                                    userId: userId,
                                                                    username:
                                                                        username,
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {error && (
                                                <div className="ui negative message">
                                                    {error.message}
                                                </div>
                                            )}
                                        </form>
                                    );
                                }}
                            </Mutation>
                        </div>
                    );
                }}
            </Query>
        );
    }
}

export default withRouter(EditGroupPage);
