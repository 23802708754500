import { gql } from '@apollo/client';
import client from './graphQlClient';
import { getDataset } from '../models/Datasets';
import { FIXTURE_GRID_TYPE, ORDER_GRID_TYPE } from '../constants/gridTypes';
import { cloneDeep, cloneDeepWith, groupBy } from 'lodash';
import { PANAMAX_DIRECTION_LOGIC } from '../services/directions/DirectionServiceFactory';

class layoutsApi {
    insertSavedLayout(name, fixtures, orders, commonSettings) {
        let variables = {
            name,
            fixtures,
            orders,
            commonSettings,
        };

        //remove any __typename properties that may be in the value else GraphQL will reject the mutation as having an extra field in the input properties (more because of CommonSettingsInput)
        variables = this.omitDeep(variables, ['__typename']);

        return client()
            .query({
                query: gql`
                    mutation insertSavedLayout(
                        $name: String!
                        $fixtures: String
                        $orders: String
                        $commonSettings: CommonSettingsInput
                    ) {
                        insertSavedLayout(
                            name: $name
                            fixtures: $fixtures
                            orders: $orders
                            commonSettings: $commonSettings
                        ) {
                            id
                            name
                            fixtures
                            orders
                            isPreferred
                            commonSettings {
                                directionLogic
                                quantityFormat
                                defaultType
                            }
                            updatedDateTime
                            createdDateTime
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables,
            })
            .then((result) => {
                return { ...result.data.insertSavedLayout };
            });
    }

    updateSavedLayout(id, name, fixtures, orders, commonSettings) {
        let variables = {
            id,
            name,
            fixtures,
            orders,
            commonSettings,
        };

        //remove any __typename properties that may be in the value else GraphQL will reject the mutation as having an extra field in the input properties (more because of CommonSettingsInput)
        variables = this.omitDeep(variables, ['__typename']);

        return client()
            .query({
                query: gql`
                    mutation updateSavedLayout(
                        $id: ID!
                        $name: String!
                        $fixtures: String
                        $orders: String
                        $commonSettings: CommonSettingsInput
                    ) {
                        updateSavedLayout(
                            id: $id
                            name: $name
                            fixtures: $fixtures
                            orders: $orders
                            commonSettings: $commonSettings
                        ) {
                            id
                            name
                            fixtures
                            orders
                            isPreferred
                            commonSettings {
                                directionLogic
                                quantityFormat
                                defaultType
                            }
                            updatedDateTime
                            createdDateTime
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables,
            })
            .then((result) => {
                return { ...result.data.updateSavedLayout };
            });
    }

    renameSavedLayout(id, name) {
        return client()
            .query({
                query: gql`
                    mutation renameSavedLayout($id: ID!, $name: String!) {
                        renameSavedLayout(id: $id, name: $name) {
                            id
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    id,
                    name,
                },
            })
            .then((result) => {
                return { ...result.data.renameSavedLayout };
            });
    }

    setPreferredLayout(id) {
        return client()
            .query({
                query: gql`
                    mutation setPreferredLayout($id: ID!) {
                        setPreferredLayout(id: $id) {
                            id
                            name
                            fixtures
                            orders
                            isPreferred
                            updatedDateTime
                            createdDateTime
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    id,
                },
            })
            .then((result) => {
                return result.data.setPreferredLayout;
            });
    }

    deleteSavedLayout(id) {
        return client()
            .query({
                query: gql`
                    mutation deleteSavedLayout($id: ID!) {
                        deleteSavedLayout(id: $id) {
                            id
                            name
                            fixtures
                            orders
                            isPreferred
                            updatedDateTime
                            createdDateTime
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
                variables: {
                    id,
                },
            })
            .then((result) => {
                return result.data.deleteSavedLayout;
            });
    }

    getSavedLayouts() {
        return client()
            .query({
                query: gql`
                    {
                        savedLayouts {
                            id
                            name
                            fixtures
                            orders
                            isPreferred
                            commonSettings {
                                directionLogic
                                quantityFormat
                                defaultType
                            }
                            updatedDateTime
                            createdDateTime
                        }
                    }
                `,
                fetchPolicy: 'no-cache',
            })
            .then((result) => {
                return result.data.savedLayouts;
            });
    }

    async persistFixturesLayout(
        datasetId,
        groups,
        userLayouts,
        selectedLayoutId,
        fixturesLayout,
        onUpdateUserLayouts,
        onSelectLayout
    ) {
        const dataset = getDataset(datasetId);
        const shouldInsertDefaultLayout = this.shouldInsertDefaultLayout(
            dataset,
            groups,
            selectedLayoutId,
            userLayouts,
            FIXTURE_GRID_TYPE
        );
        //applying specific set of columns for default layout for dataset if user has access only to that dataset
        if (shouldInsertDefaultLayout) {
            fixturesLayout.columnState = this.getDatasetDefaultColumns(
                fixturesLayout,
                dataset.defaultFixtureColumnsToShow
            );
            fixturesLayout.vesselOptions = dataset.vesselOptions;
        }

        let newFixturesLayout = JSON.stringify(fixturesLayout);

        //check if user has no user layouts and insert the first one
        if (!selectedLayoutId) {
            const defaultCommonSettings =
                this.getDefaultCommonSettings(dataset);
            this.insertSavedLayout(
                'My Layout',
                newFixturesLayout,
                null,
                defaultCommonSettings
            ).then((newLayout) => {
                //update user layouts in the context
                onSelectLayout([newLayout], newLayout.id, datasetId);
            });
        } else {
            //update the existing layout
            const index = userLayouts.findIndex(
                (l) => l.id === selectedLayoutId
            );
            const currentLayout = userLayouts[index];

            this.updateSavedLayout(
                currentLayout.id,
                currentLayout.name,
                newFixturesLayout,
                currentLayout.orders,
                currentLayout.commonSettings
            ).then((newLayout) => {
                //update user layouts in the context
                const newLayouts = [...userLayouts];
                newLayouts[index] = newLayout;
                if (shouldInsertDefaultLayout)
                    onSelectLayout([newLayout], newLayout.id, datasetId);
                else onUpdateUserLayouts(newLayouts);
            });
        }
    }

    async persistOrdersLayout(
        datasetId,
        groups,
        userLayouts,
        selectedLayoutId,
        ordersLayout,
        onUpdateUserLayouts,
        onSelectLayout
    ) {
        const dataset = getDataset(datasetId);
        const shouldInsertDefaultLayout = this.shouldInsertDefaultLayout(
            dataset,
            groups,
            selectedLayoutId,
            userLayouts,
            ORDER_GRID_TYPE
        );
        //applying specific set of columns for default layout for dataset if user has access only to that dataset
        if (shouldInsertDefaultLayout) {
            ordersLayout.columnState = this.getDatasetDefaultColumns(
                ordersLayout,
                dataset.defaultOrderColumnsToShow
            );
            ordersLayout.shouldHighlightNewOrders =
                dataset.shouldHighlightNewOrders;
            ordersLayout.shouldShowConvertedOrders =
                dataset.shouldShowConvertedOrders;
        }

        let newOrdersLayout = JSON.stringify(ordersLayout);

        //check if user has no user layouts and insert the first one
        if (!selectedLayoutId) {
            const defaultCommonSettings =
                this.getDefaultCommonSettings(dataset);
            this.insertSavedLayout(
                'My Layout',
                null,
                newOrdersLayout,
                defaultCommonSettings
            ).then((newLayout) => {
                //update user layouts in the context
                onSelectLayout([newLayout], newLayout.id, datasetId);
            });
        } else {
            //update the existing layout
            const index = userLayouts.findIndex(
                (l) => l.id === selectedLayoutId
            );
            const currentLayout = userLayouts[index];

            this.updateSavedLayout(
                currentLayout.id,
                currentLayout.name,
                currentLayout.fixtures,
                newOrdersLayout,
                currentLayout.commonSettings
            ).then((newLayout) => {
                //update user layouts in the context
                const newLayouts = [...userLayouts];
                newLayouts[index] = newLayout;
                if (shouldInsertDefaultLayout)
                    onSelectLayout([newLayout], newLayout.id, datasetId);
                else onUpdateUserLayouts(newLayouts);
            });
        }
    }

    async persistLayout(
        datasetId,
        userLayouts,
        selectedLayoutId,
        ordersLayout,
        fixturesLayout,
        commonSettings,
        onUpdateUserLayouts
    ) {
        const index = userLayouts.findIndex((l) => l.id === selectedLayoutId);
        const currentLayout = userLayouts[index];

        let newOrdersLayout = JSON.stringify(ordersLayout);
        let newFixturesLayout = JSON.stringify(fixturesLayout);

        this.updateSavedLayout(
            currentLayout.id,
            currentLayout.name,
            newFixturesLayout,
            newOrdersLayout,
            commonSettings
        ).then((newLayout) => {
            const newLayouts = [...userLayouts];
            newLayouts[index] = newLayout;
            onUpdateUserLayouts(newLayouts);
        });
    }

    getLayoutForGridType(userLayouts, selectedLayoutId, gridType) {
        if (selectedLayoutId) {
            const selectedLayout = userLayouts.find(
                (l) => l.id === selectedLayoutId
            );
            const parsedLayoutObject = JSON.parse(selectedLayout[gridType]);
            if (parsedLayoutObject) {
                return parsedLayoutObject;
            }
        }
        return {};
    }

    getSelectedLayout(userLayouts, selectedLayoutId) {
        return userLayouts?.find((l) => l.id === selectedLayoutId);
    }

    shouldInsertDefaultLayout(
        dataset,
        groups,
        selectedLayoutId,
        userLayouts,
        gridType
    ) {
        const currentLayout = !selectedLayoutId
            ? null
            : userLayouts.find((l) => l.id === selectedLayoutId);
        const layoutItems =
            gridType === FIXTURE_GRID_TYPE
                ? currentLayout?.fixtures
                : currentLayout?.orders;
        const datasetsCount = this.getDatasetsCount(groups);
        return (
            datasetsCount === 1 &&
            !layoutItems &&
            dataset.defaultOrderColumnsToShow?.length > 0
        );
    }

    getDatasetDefaultColumns(layout, defaultColIds) {
        const defaultColumns = [];
        for (const field of defaultColIds) {
            const column = layout.columnState.find(
                (column) => field.localeCompare(column.colId) === 0
            );
            column.hide = false;
            column.sort = null;
            defaultColumns.push(column);
        }
        for (const column of layout.columnState) {
            if (!defaultColIds.includes(column.colId)) {
                column.hide = true;
                defaultColumns.push(column);
            }
        }
        return defaultColumns;
    }

    getDatasetsCount(groups) {
        const groupedDatasets = groupBy(groups, (g) => g.datasetId);
        return Object.keys(groupedDatasets).length;
    }

    omitDeep = (collection, excludeKeys) => {
        const deepCopy = cloneDeep(collection);

        function omitFn(value) {
            if (value && typeof value === 'object') {
                excludeKeys.forEach((key) => {
                    delete value[key];
                });
            }
        }

        return cloneDeepWith(deepCopy, omitFn);
    };

    getDefaultCommonSettings = (dataset) => {
        return {
            directionLogic: PANAMAX_DIRECTION_LOGIC,
            quantityFormat: dataset.defaultQuantityFormat,
            defaultType: dataset.defaultType,
        };
    };
}

export default new layoutsApi();
