export const LaycanComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
    if (!valueA && !valueB) {
        return 0;
    }

    if (!valueA) {
        return -1;
    }

    if (!valueB) {
        return 1;
    }

    const startCompareResult = compareDates(valueA.startDate, valueB.startDate);
    if (startCompareResult !== 0) {
        return startCompareResult;
    }

    if (valueA.isUncertain && !valueB.isUncertain) {
        return -1;
    }
    if (!valueA.isUncertain && valueB.isUncertain) {
        return 1;
    }

    if (valueA.shorthand && !valueB.shorthand) {
        return 1;
    }
    if (!valueA.shorthand && valueB.shorthand) {
        return -1;
    }

    //start dates equal, need to compare end dates
    return compareDates(valueA.endDate, valueB.endDate);
};

export const LaycanFromComparator = (valueA, valueB) =>
    compareDates(valueA?.startDate, valueB?.startDate);

export const LaycanToComparator = (valueA, valueB) =>
    compareDates(valueA?.endDate, valueB?.endDate);

const compareDates = (left, right) => {
    if (!left && !right) {
        return 0;
    }

    if (!left) {
        return -1;
    }

    if (!right) {
        return 1;
    }

    if (left > right) {
        return 1;
    } else if (left < right) {
        return -1;
    } else {
        return 0;
    }
};
