export const colors = {
    grey1: '00808080',
    grey2: '00a6a6a6',
    grey3: '00d3d3d3',
    grey4: '00eeeeee',
    grey5: '00B0B0B0',
    grey6: 'c0c0c0',
    red: '00ff1744',
    red2: '7a0000',
    black: '00000000',
    green: '0000a65a',
    white: '00ffffff',
    burgundy: '00ae2f5a',
    blue: '002196F3',
    lightBlue: '0000A1D8',
    pink2: '00f7cdd3',
    pink3: '00ee95a2',
    pink4: 'dd8aa5',
    darkblue: '4d5a8d',
};

export const fonts = { default: 'Arial', defaultSize: 8 };
