import React from 'react';
import { FIXTURE_GRID_TYPE } from '_legacy/constants/gridTypes';
import getHeadingTitle from '_legacy/services/headingsServices/getHeadingTitle';
import GroupColumnSorting from './GroupColumnSorting';

const GroupColumnsSorting = (props) => {
    const renderGroupColumnsSorting = () => {
        const { rowGroups, rowGroupsSorting } = props.currentOptions;
        const isFixtureGridType = props.gridType === FIXTURE_GRID_TYPE;

        const columnsToShow = rowGroups.filter(
            (rg) =>
                rg.checked &&
                rowGroupsSorting.filter((rgs) => rgs.colId === rg.colId)
                    .length > 0
        );

        return columnsToShow.map((c) => (
            <GroupColumnSorting
                key={c.colId}
                {...props}
                colId={c.colId}
                header={getHeadingTitle(
                    props.datasetId,
                    c.colId,
                    isFixtureGridType
                )}
            />
        ));
    };

    return <div style={{ margin: '10px' }}>{renderGroupColumnsSorting()}</div>;
};

export default GroupColumnsSorting;
