import React, { Component } from 'react';
import logger from '../../diagnostics/logging/logger';
import * as pbi from 'powerbi-client';
import axios from 'axios';
import { sessionId } from '../../api/session';
import { apiBaseUrl } from '../../config';
import { FixtureApi } from '../../api/fixtureApi';
import { authenticationService } from '_legacy/auth/AuthenticationService';

export function encodeDataSetId(datasetId) {
    // Hide the datasetId.
    //
    const data = `DATASET=${datasetId}=STUFF`;
    return btoa(data);
}

export function decodeDataSetToken(token) {
    const data = atob(token);
    const parts = data.split('=');
    return parts[1];
}

class AdvancedAnalyticsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            advancedAnalyticsMaintainanceMessage: null,
        };
    }

    componentDidMount() {
        FixtureApi.getMaintainanceMessage('Fixture Analytics Maintenance').then(
            (results) => {
                if (results) {
                    this.setState({
                        advancedAnalyticsMaintainanceMessage: results,
                    });
                } else {
                    this.renderAnalytics();
                }
            }
        );
    }

    renderAnalytics() {
        const datasetIdValue = decodeDataSetToken(
            this.props.match.params.token
        );
        const datasetId = parseInt(datasetIdValue, 10);

        axios
            .get(
                `https://${apiBaseUrl}/EmbeddedPowerBI/EmbedToken?type=dashboard`,
                {
                    headers: {
                        authorization: encodeURIComponent(
                            authenticationService.getAccessToken()
                        ),
                        fortSessionId: sessionId,
                    },
                }
            )
            .then((response) => {
                let powerbi = new pbi.service.Service(
                    pbi.factories.hpmFactory,
                    pbi.factories.wpmpFactory,
                    pbi.factories.routerFactory
                );
                let embedConfig = {
                    type: 'report',
                    id: response.data.ReportId,
                    embedUrl: response.data.EmbedUrl,
                    tokenType: pbi.models.TokenType.Embed,
                    accessToken: response.data.EmbedToken.token,
                    settings: {
                        filterPaneEnabled: false,
                        navContentPaneEnabled: false,
                    },
                    filters: [
                        {
                            $schema: 'http://powerbi.com/product/schema#basic',
                            target: {
                                table: 'Dataset',
                                column: 'DatasetId',
                            },
                            operator: 'In',
                            values: [datasetId],
                            filterType: pbi.models.FilterType.BasicFilter,
                        },
                    ],
                };
                document.getElementsByClassName(
                    'fixture-count-placeholder'
                )[0] &&
                    powerbi.embed(
                        document.getElementsByClassName(
                            'fixture-count-placeholder'
                        )[0],
                        embedConfig
                    );
            });
    }

    render() {
        logger.trackPageView('Analytics');
        return (
            <div
                style={{ height: '100%', width: '100%' }}
                className="fixture-count-placeholder"
            >
                {this.state.advancedAnalyticsMaintainanceMessage}
            </div>
        );
    }
}

export default AdvancedAnalyticsPage;
