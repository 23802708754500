import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FortDownshiftInput from '_legacy/components/grid/inputs/FortDownshiftInput';
import ThirdPartyCompanyApi from '_legacy/api/thirdPartyCompanyApi';
import { directoryClientCheckUrl } from '_legacy/config';
import CountryImage from '_legacy/components/common/CountryImage';
import {
    ENTITY_PART_TYPE_PLACEHOLDER,
    ENTITY_PART_TYPE_CUSTOM,
} from '_legacy/models/common/EntityPart';

class ThirdPartyCompanyInput extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();

        this.state = { initialChar: props.initialChar };
    }

    itemToString = (company) => (company ? company.name : '');

    handleItemSelected = (selectedCompany, keyCode, shift) => {
        if (this.props.onCompanySelected) {
            const company = this.convertRetrievedCompany(selectedCompany);
            this.props.onCompanySelected(company, keyCode, shift);
        }
    };

    handleDelete = () => {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    };

    convertRetrievedCompany(company) {
        return {
            value: company.id.toString(),
            partType:
                // this check is to keep basic logic of custom value in FortDownshiftInput
                // (show in the list without capturing immediately like we lusualy do for placeholders) but to DB it should go like 'Placeholder'
                company.type === ENTITY_PART_TYPE_CUSTOM
                    ? ENTITY_PART_TYPE_PLACEHOLDER
                    : company.type,
            name: company.name,
        };
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    reset() {
        if (this.inputRef.current) {
            this.inputRef.current.reset();
        }
    }

    renderCompany(getItemProps, highlightedIndex, item, index) {
        function getShortName(name) {
            return name && name.length > 30 ? name.substr(0, 30) + '...' : name;
        }

        return (
            <tr
                {...getItemProps({
                    item,
                    key: item.id || item.key,
                    style: {
                        backgroundColor:
                            highlightedIndex === index
                                ? 'rgba(0,0,0,0.05)'
                                : 'white',
                    },
                })}
            >
                <td title={item.name}>{getShortName(item.name)}</td>
                <td title={item.parentName}>{getShortName(item.parentName)}</td>
                <td>
                    <CountryImage countryCode={item.countryCode} />
                </td>
            </tr>
        );
    }

    render() {
        const toClientCheck = (
            <tr>
                <td colSpan="3">
                    {' '}
                    <a
                        href={directoryClientCheckUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Not found? Click here to proceed to client check
                    </a>{' '}
                </td>
            </tr>
        );

        return (
            <FortDownshiftInput
                {...this.props}
                initialChar={this.state.initialChar}
                ref={this.inputRef}
                itemToString={this.itemToString}
                searchFunc={ThirdPartyCompanyApi.search}
                renderItem={this.renderCompany}
                placeholder="Company name"
                customItemName={this.props.placeholderName}
                minCharectersForSearch={2}
                toClientCheck={toClientCheck}
                onItemSelected={this.handleItemSelected}
                onDelete={this.handleDelete}
                onInputCleared={this.props.onInputCleared}
                onTab={this.props.onTab}
                onTabBack={this.props.onTabBack}
                blockedCharacters={this.props.blockedCharacters}
                downshiftContentClassname="third-party-company-downshift-content"
            />
        );
    }
}

ThirdPartyCompanyInput.propTypes = {
    onCompanySelected: PropTypes.func.isRequired,
    shouldSelectItemOnTab: PropTypes.bool.isRequired,
    inputClass: PropTypes.string,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    initialChar: PropTypes.string,
    onInputCleared: PropTypes.func.isRequired,
};

export default ThirdPartyCompanyInput;
