import { gql } from '@apollo/client';

export const SEARCH_CLARKSONS_USERS = gql`
    query ClarksonsUsers($search: String!, $datasetId: Int!) {
        clarksonsUsers(searchTerm: $search, datasetId: $datasetId) {
            userId
            username
            firstName
            lastName
            emailAddress
        }
    }
`;
