import React, { Component } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import WarningIconOrange from '../icons/WarningIconOrange';
import * as DateTimeFormatUtils from '../common/DateTimeFormatUtils';

export class ShareConfirmation extends Component {
    state = { confirmed: false };

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeydown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    }

    handleKeydown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.key === 'Enter' || e.key === 'Esc') {
            this.props.onClose();
        }
    };

    confirm = (e, data) => {
        this.setState({ confirmed: data.checked });
    };

    formatDate(date) {
        return DateTimeFormatUtils.formatDefaultDateTime(
            date,
            this.props.userTimezone
        );
    }

    render() {
        const text =
            this.props.groupOnlyExpiryDate && !this.props.isShareToGroupOnly
                ? `This row will automatically be made visible to all groups by ${this.formatDate(
                      this.props.groupOnlyExpiryDate
                  )} if you decide to Cancel`
                : undefined;

        const message = this.props.isShareToGroupOnly
            ? `You are about to make this row visible to group only`
            : `You are about to make this row visible to all groups`;

        return (
            <div
                id="share-confirmation-modal"
                className="share-confirmation"
                style={{ minWidth: 400 }}
            >
                <h3>Change Visibility - Are you sure?</h3>

                <div>{message}</div>

                <div>
                    <Checkbox
                        checked={this.state.confirmed}
                        onChange={this.confirm}
                        label="'Tick' this to change the visibility."
                    />
                </div>

                <div>
                    <WarningIconOrange width={15} />{' '}
                    <b>This action cannot be undone</b>
                </div>

                <div>{text}</div>

                <br />

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button basic onClick={this.props.onClose}>
                        CANCEL
                    </Button>
                    {this.state.confirmed && (
                        <Button primary onClick={this.props.onConfirm}>
                            CONTINUE
                        </Button>
                    )}
                    {!this.state.confirmed && (
                        <Button primary disabled>
                            CONTINUE
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}

ShareConfirmation.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ShareConfirmation;
