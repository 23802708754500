const basicStemSizes = [
    { id: 1, value: 'HANDY', minDwt: 25000, maxDwt: 39999 },
    { id: 2, value: 'HMAX', minDwt: 40000, maxDwt: 49999 },
    { id: 3, value: 'SMAX', minDwt: 50000, maxDwt: 59999 },
    { id: 4, value: 'UMAX', minDwt: 60000, maxDwt: 69999 },
    { id: 5, value: 'PMAX', minDwt: 70000, maxDwt: 79999 },
    { id: 6, value: 'KMX', minDwt: 80000, maxDwt: 89999 },
    { id: 7, value: 'PPMX', minDwt: 90000, maxDwt: 99999 },
    { id: 8, value: 'BBCAPE', minDwt: 100000, maxDwt: 120000 },
    { id: 9, value: 'CAPE', minDwt: 160000, maxDwt: 179999 },
    { id: 10, value: 'NMAX', minDwt: 180000, maxDwt: 200000 },
];

export const getStemSizesCombinations = () => {
    const basicStemSizesLength = basicStemSizes.length;
    const result = basicStemSizes.flatMap((v, i) => [
        v,
        ...basicStemSizes.slice(i + 1).map((w, index) => {
            return {
                id: v.id * basicStemSizesLength + index + 1,
                value: v.value + '-' + w.value,
                minDwt: v.minDwt,
                maxDwt: w.maxDwt,
            };
        }),
    ]);

    return result;
};
