import React, { Component } from 'react';
import generateId from '../../../tools/idGenerator';
import PropTypes from 'prop-types';
import FortDownshiftInput from './FortDownshiftInput';
import { loadZones, dischargeZones } from '../../../models/Location';
import { getDataset } from '../../../models/Datasets';

class ZoneInput extends Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.handleItemSelected = this.handleItemSelected.bind(this);
        this.zoneSearch = this.zoneSearch.bind(this);
    }

    itemToString = (zone) => (zone ? zone.name : '');

    createCustomItem(value) {
        return {
            name: value.toUpperCase(),
            key: generateId(),
        };
    }

    handleItemSelected(selectedZone, keyCode, shift) {
        if (this.props.onZoneSelected) {
            this.props.onZoneSelected(selectedZone, keyCode, shift);
        }
    }

    get availableZones() {
        if (this.props.field === 'loadZone') {
            return loadZones(this.props.context.datasetId);
        } else if (this.props.field === 'dischargeZone') {
            return dischargeZones(this.props.context.datasetId);
        } else {
            console.error(`Field ${this.props.field} has no available zones`);

            return [];
        }
    }

    zoneSearch(text) {
        var promise = new Promise(
            function (resolve) {
                const upperText = text.toUpperCase();

                var matches = this.availableZones.filter(
                    (z) => z.toUpperCase().indexOf(upperText) > -1
                );

                matches = matches.map((c) => {
                    return {
                        key: c,
                        name: c,
                        known: true,
                        index: c.toUpperCase().indexOf(upperText),
                    };
                });

                matches = matches.sort(
                    (a, b) => a.index - b.index || a.name.localeCompare(b.name)
                );

                resolve(matches);
            }.bind(this)
        );

        return promise;
    }

    focus() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    renderZone(getItemProps, highlightedIndex, item, index) {
        return (
            <tr
                {...getItemProps({
                    item,
                    key: item.key,
                    style: {
                        backgroundColor:
                            highlightedIndex === index
                                ? 'rgba(0,0,0,0.05)'
                                : 'white',
                    },
                })}
            >
                <td>{item.known ? item.name : <b>ADD "{item.name}"</b>}</td>
            </tr>
        );
    }

    render() {
        return (
            <FortDownshiftInput
                {...this.props}
                ref={this.inputRef}
                itemToString={this.itemToString}
                searchFunc={this.zoneSearch}
                renderItem={this.renderZone}
                placeholder={this.props.headerName}
                createCustomItem={
                    getDataset(this.props.context.datasetId).allowFreeTextZone
                        ? this.createCustomItem
                        : undefined
                }
                onItemSelected={this.handleItemSelected}
                initialChar={this.props.initialChar}
            />
        );
    }
}

ZoneInput.propTypes = {
    onZoneSelected: PropTypes.func.isRequired,
    shouldSelectItemOnTab: PropTypes.bool.isRequired,
    inputClass: PropTypes.string,
    onTab: PropTypes.func.isRequired,
    onTabBack: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    initialChar: PropTypes.string,
    onInputCleared: PropTypes.func.isRequired,
};

export default ZoneInput;
