import types, { flatRateValue } from '../models/RateTypes';
import { rateFormatterInstance } from '../formatters/RateFormatter';
import currencies from '../models/Currencies';
import { getAvailableBounds } from '../models/RateBounds';
import { getRateIndicesByDatasetId } from '../models/RateIndices';
import { durationUnits } from '../../../shared/columns/models/DurationUnits';
import { rateUnits, getAvailableUnits } from '../models/RateUnits';
import { validateBound } from '../services/ValidationService';

export const convertRateToInputStateModel = (
    selectedRate,
    fixtureType,
    datasetId,
    selectedIndex
) => {
    const { flatRate, balticIndex, duration } = selectedRate.rateValue;
    const typeObj = types.find(
        (type) => type.value === selectedRate.rateValue.type
    );

    const amount =
        typeObj.value === flatRateValue ? flatRate.amount : balticIndex.amount;
    const amountString = rateFormatterInstance.formatAmount(amount, true);
    const amountObj = amount && {
        from: amount.from,
        to: amount.to,
    };

    const currencyObj =
        flatRate && currencies.find((ccy) => ccy.value === flatRate.currency);

    const unitObj =
        flatRate &&
        getAvailableUnits(fixtureType).find(
            (unit) => unit.value === flatRate.unit
        );

    let boundObj =
        flatRate &&
        getAvailableBounds(fixtureType).find(
            (bound) => bound.value === flatRate.bound
        );
    if (boundObj && !boundObj.value) boundObj = null;

    const indexObj =
        balticIndex &&
        getRateIndicesByDatasetId(datasetId).find(
            (index) => index.value === balticIndex.index
        );

    const durationAmountString = duration
        ? rateFormatterInstance.formatAmount(duration.amount, true)
        : '';
    const durationAmountObj = duration
        ? {
              isSuccess: true,
              parsedValue: durationAmountString,
              from: duration.amount.from,
              to: duration.amount.to,
          }
        : null;

    const durationUnitObj = duration
        ? durationUnits.find((unit) => unit.value === duration.unit)
        : getDefaultDurationUnit();

    return {
        selectedIndex: selectedIndex,
        isEditMode: true,
        bound: boundObj,
        currency: currencyObj === undefined ? null : currencyObj,
        unit: unitObj ?? rateUnits[1],
        amount: amountString,
        amountObj: amountObj,
        isAmountValid: true,
        isAmountDisabled: unitObj ? unitObj.shouldDisableAmount : false,
        isCurrencyDisabled: unitObj ? unitObj.shouldDisableCurrency : false,
        isBoundDisabled: unitObj ? unitObj.shouldDisableBound : false,
        isBoundValid:
            typeObj.value === flatRateValue
                ? validateBound(amountString)
                : true,
        type: typeObj,
        index: indexObj,
        isIndexValid: indexObj ? true : false,
        durationAmount: durationAmountString,
        durationAmountObj: durationAmountObj,
        durationUnit: durationUnitObj ?? getDefaultDurationUnit(),
        isDurationAmountValid: true,
        arePartsValid: true,
        isDurationDisabled: unitObj ? unitObj.shouldDisableDuration : false,
    };
};

const getDefaultDurationUnit = () => {
    return durationUnits.find((unit) => unit.isDefaultRateUnit);
};
